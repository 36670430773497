import { toast } from "react-toastify"
import BASE_URL from "./apiConfig"
import axios from "axios"
import { getToken } from "helpers/helper"

// Modified GetToDownloadAPI function to handle blob responses
const GetToDownloadAPI = async (url, headersObj = {}) => {
  url = `${BASE_URL}/api/${url}`

  // Ensure token is not null or undefined
  let tokenid = getToken()

  // Add Authorization header if token exists
  const headers = tokenid
    ? { ...headersObj, Authorization: `Bearer ${tokenid}` }
    : headersObj

  try {
    const response = await axios.get(url, {
      headers: headers,
      responseType: "blob", // Ensure the response is a blob (PDF)
    })

    if (response.status !== 200) {
      const errorData = await response.json()
      throw new Error(errorData.message || "An unknown error occurred")
    }

    return response
  } catch (error) {
    toast.error(error.message || "An unknown error occurred")
    return { error: error.message || "An unknown error occurred" }
  }
}
export default { GetToDownloadAPI }
