import React from "react"
import { Outlet, useLocation } from "react-router-dom"
import Breadcrumb from "./Header/Breadcrumb"

const RequestPage = () => {
  const location = useLocation()
  const locationPath = location.pathname
  const pathname = locationPath.split("/").filter(Boolean).pop()
  const pathArray = [
    "TestCaseRequestView"
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        {!pathArray.includes(pathname) && (
          <Breadcrumb
            isImport={false}
            isDownload={false}
            isExport={false}
            isInfo={false}
            isProject={false}
          />
        )}
        <Outlet />
      </div>
    </React.Fragment>
  )
}

export default RequestPage
