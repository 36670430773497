import { createSlice } from "@reduxjs/toolkit"

const DashboardSlice = createSlice({
  name: "Dashboard",
  initialState: {
    ProjDashboardStats: [],
    GlobalDashboardPriority:[],
    GlobalDashboardSeverity:[],
    GlobalDashboardReported:[],
    GlobalDashboardPartitionUser:[],
    GlobalDashboardProjectBreakdown:[],
    GlobalDashboardRequirementBreakDown:[],
    GlobalDashboardDefectsSummary:[],
    GlobalDashboardDefectStatusAPI:[],
    GlobalDashboardDefectsPriority:[],
    GlobalDashboardDefectsType:[],
    ProjectDashboardSeverityDef:[],
    ProjectDashboardStatusDef:[],
    ProjectDashboardTestCaseStatus:[],
    ProjectDashboardTestCaseExecuted:[],
    ProjectDashboardTestCaseFail:[],
    ProjectDashboardDefectReopen:[],
    ProjectDashboardDefectAging:[],
    ProjectDashboardActiveDefect:[],
    ProjectDashboardDefectClosure:[], 
    ProjectDashboardDefectWeekWise:[],
    ProjectDashboardDefectTypeCount:[],
    ProjectdashboardMilestoneTracking:[],
    ProjectdashboardUserList:[],
    ProjectdashboardUserList:[],
    ProjectdashboardDefectStatics:[]
  },

  reducers: {
    setProjDashboardStats: (state, action) => ({
      ...state,
      ProjDashboardStats: action.payload,
    }),
    setGlobalDashboardSeverity: (state, action) => ({
      ...state,
      GlobalDashboardSeverity: action.payload,
    }),
    setGlobalDashboardPriority: (state, action) => ({
      ...state,
      GlobalDashboardPriority: action.payload,
    }),
    setGlobalDashboardReported: (state, action) => ({
        ...state,
        GlobalDashboardReported: action.payload,
      }),
      setGlobalDashboardPartitionUser: (state, action) => ({
        ...state,
        GlobalDashboardPartitionUser: action.payload,
      }),
      setGlobalDashboardProjectBreakdown: (state, action) => ({
        ...state,
        GlobalDashboardProjectBreakdown: action.payload,
      }),
      setGlobalDashboardRequirementBreakDown: (state, action) => ({
        ...state,
        GlobalDashboardRequirementBreakDown: action.payload,
      }),
      setGlobalDashboardDefectsType: (state, action) => ({
        ...state,
        GlobalDashboardDefectsType: action.payload,
      }),
      setGlobalDashboardDefectsPriority: (state, action) => ({
        ...state,
        GlobalDashboardDefectsPriority: action.payload,
      }),
      setGlobalDashboardDefectsSummary: (state, action) => ({
        ...state,
        GlobalDashboardDefectsSummary: action.payload,
      }),
      setGlobalDashboardDefectStatusAPI: (state, action) => ({
        ...state,
        GlobalDashboardDefectStatusAPI: action.payload,
      }),
      setProjectDashboardSeverityDef: (state, action) => ({
        ...state,
        ProjectDashboardSeverityDef: action.payload,
      }),
      setProjectDashboardStatusDef: (state, action) => ({
        ...state,
        ProjectDashboardStatusDef: action.payload,
      }),
      setProjectDashboardTestCaseStatus: (state, action) => ({
        ...state,
        ProjectDashboardTestCaseStatus: action.payload,
      }),
      setProjectDashboardTestCaseExecuted: (state, action) => ({
        ...state,
        ProjectDashboardTestCaseExecuted: action.payload,
      }),
      setProjectDashboardTestCaseFail: (state, action) => ({
        ...state,
        ProjectDashboardTestCaseFail: action.payload,
      }),
      setProjectDashboardDefectReopen: (state, action) => ({
        ...state,
        ProjectDashboardDefectReopen: action.payload,
      }),
      setProjectDashboardDefectAging: (state, action) => ({
        ...state,
        ProjectDashboardDefectAging: action.payload,
      }),
      setProjectDashboardActiveDefect: (state, action) => ({
        ...state,
        ProjectDashboardActiveDefect: action.payload,
      }),
      setProjectDashboardDefectClosure: (state, action) => ({
        ...state,
        ProjectDashboardDefectClosure: action.payload,
      }), 
      setProjectDashboardDefectWeekWise: (state, action) => ({
        ...state,
        ProjectDashboardDefectWeekWise: action.payload,
      }),
      setProjectdashboardMilestoneTracking: (state, action) => ({
        ...state,
        ProjectdashboardMilestoneTracking: action.payload,
      }),
      setProjectDashboardDefectTypeCount: (state, action) => ({
        ...state,
        ProjectDashboardDefectTypeCount: action.payload,
      }),
      setProjectdashboardUserList: (state, action) => ({
        ...state,
        ProjectdashboardUserList: action.payload,
      }),
      setProjectdashboardDefectStatics: (state, action) => ({
        ...state,
        ProjectdashboardDefectStatics: action.payload,
      }),
  },
})

export const { setProjDashboardStats ,setGlobalDashboardSeverity ,setGlobalDashboardPriority ,setGlobalDashboardReported , 
    setGlobalDashboardPartitionUser ,setGlobalDashboardProjectBreakdown ,setProjectDashboardSeverityDef ,setProjectDashboardStatusDef,
    setProjectDashboardTestCaseStatus , setProjectDashboardTestCaseExecuted , setProjectDashboardTestCaseFail ,
    setGlobalDashboardRequirementBreakDown ,setProjectDashboardDefectReopen ,setProjectDashboardDefectAging ,
    setProjectDashboardActiveDefect, setProjectDashboardDefectClosure ,setGlobalDashboardDefectsPriority,setGlobalDashboardDefectStatusAPI,
    setGlobalDashboardDefectsType, setProjectDashboardDefectWeekWise , setGlobalDashboardDefectsSummary, 
    setProjectdashboardMilestoneTracking ,setProjectDashboardDefectTypeCount,setProjectdashboardUserList,setProjectdashboardDefectStatics } = DashboardSlice.actions

export default DashboardSlice.reducer
