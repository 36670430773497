import PropTypes from "prop-types"
import React from "react"
import { Container } from "reactstrap"
import Breadcrumb from "./Header/Breadcrumb"
import { Outlet, useLocation } from "react-router-dom"
//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";

const Settings = props => {
  const location = useLocation()
  const locationPath = location.pathname
  const pathname = locationPath.split("/").filter(Boolean).pop()
  const pathArray = [
    "CreateEditUsers",
    "CreateEditRoles",
    "CreateTag",
    "EditTag",
    "CreateIntegration",
    "EditIntegration"
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        {!pathArray.includes(pathname) && (
          <Breadcrumb
            isImport={false}
            isDownload={false}
            isExport={false}
            isInfo={false}
            isProject={false}
          />
        )}
        <Outlet />
      </div>
    </React.Fragment>
  )
}

export default Settings
