import Breadcrumb from "components/Common/Breadcrumb"
import moment from "moment"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"

const ViewTestData = () => {
  const navigate = useNavigate()
  const breadcrumbItems = [
    { label: "Test Data", path: "/TestAutomation/TestData" },
    { label: "Test Data Details", path: "/" },
  ]
  const viewData = useSelector(state => state?.TestData?.singleTestData)
  useEffect(() => {
    if (!viewData) {
      navigate("/TestAutomation/TestData")
    }
  }, [viewData, navigate])

  const renderKeyValueRows = keyValueData => {
    const cardStyle = {
      border: "1px solid #ddd",
      borderRadius: "0.25rem",
      marginBottom: "1rem",
      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      padding: "1rem",
      transition: "box-shadow 0.3s ease",
    }

    const cardBodyStyle = {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }

    const titleStyle = {
      fontWeight: "550",
      marginBottom: "0.5rem",
    }

    const textStyle = {
      color: "#6c757d",
      wordBreak: "break-word",
    }

    const cardHoverStyle = {
      ...cardStyle,
      boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
      cursor: "pointer",
    }

    return Array.isArray(keyValueData)
      ? keyValueData.map((data, i) => (
          <div
            key={i}
            style={cardStyle}
            onMouseOver={e =>
              (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
            }
            onMouseOut={e =>
              (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
            }
          >
            <div style={cardBodyStyle}>
              <Row style={{ width: "100%" }}>
                <Col sm={6}>
                  <h6 style={titleStyle}>Key</h6>
                  <p style={textStyle}>{data.key || "-"}</p>
                </Col>
                <Col sm={6}>
                  <h6 style={titleStyle}>Value</h6>
                  <p style={textStyle}>
                    {typeof data.value === "object"
                      ? JSON.stringify(data.value)
                      : data.value || "-"}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        ))
      : typeof keyValueData === "object" &&
          Object.entries(keyValueData).map(([key, value], index) => (
            <div
              key={index}
              style={cardStyle}
              onMouseOver={e =>
                (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
              }
              onMouseOut={e =>
                (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
              }
            >
              <div style={cardBodyStyle}>
                <Row style={{ width: "100%" }}>
                  <Col sm={6}>
                    <h6 style={titleStyle}>Key</h6>
                    <p style={textStyle}>{key || "-"}</p>
                  </Col>
                  <Col sm={6}>
                    <h6 style={titleStyle}>Value</h6>
                    <p style={textStyle}>
                      {typeof value === "object"
                        ? JSON.stringify(value)
                        : value || "-"}
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          ))
  }

  const onClickBack = () => {
    navigate("/TestAutomation/TestData")
  }

  return (
    // <>
    //   <Breadcrumb title="Test Data Details" breadcrumbItems={breadcrumbItems} />

    //   <div className="modal-body">
    //     <Card>
    //       <CardBody>
    //         <SimpleBar style={{ maxHeight: "310px" }}>
    //           <Table bordered>
    //             <tbody>
    //               <tr className="text-dark">
    //                 <td
    //                   className="text-dark font-weight-bold"
    //                   style={{ width: "200px", fontWeight: "bold" }}
    //                 >
    //                   File Name
    //                 </td>
    //                 <td>{viewData?.file_name ? viewData?.file_name : "-"}</td>
    //               </tr>
    //               <tr>
    //                 <td
    //                   className="font-weight-bold"
    //                   style={{ width: "200px", fontWeight: "bold" }}
    //                 >
    //                   Created By
    //                 </td>
    //                 <td>
    //                   {" "}
    //                   {viewData?.created_by_name
    //                     ? viewData?.created_by_name?.name
    //                     : "-"}
    //                 </td>
    //               </tr>
    //               <tr>
    //                 <td
    //                   className="font-weight-bold"
    //                   style={{ width: "200px", fontWeight: "bold" }}
    //                 >
    //                   Created Date
    //                 </td>
    //                 <td>
    //                   {viewData?.created_at
    //                     ? moment(viewData.created_at).format("DD-MM-YYYY")
    //                     : "-"}
    //                 </td>
    //               </tr>
    //               {viewData?.key_value_data &&
    //               Array.isArray(viewData.key_value_data)
    //                 ? viewData.key_value_data.map((data, i) => (
    //                     <tr key={i}>
    //                       <td
    //                         className="topic-testData"
    //                         style={{
    //                           width: "25%",
    //                           fontWeight: "bold",
    //                           wordWrap: "break-word",
    //                         }}
    //                       >
    //                         Key
    //                       </td>
    //                       <td
    //                         style={{
    //                           width: "25%",
    //                           wordWrap: "break-word",
    //                         }}
    //                       >
    //                         {data.key || "-"}
    //                       </td>
    //                       <td
    //                         className="topic-testData"
    //                         style={{
    //                           width: "25%",
    //                           fontWeight: "bold",
    //                           wordWrap: "break-word",
    //                         }}
    //                       >
    //                         Value
    //                       </td>
    //                       <td
    //                         style={{
    //                           width: "25%",
    //                           wordWrap: "break-word",
    //                         }}
    //                       >
    //                         {typeof data.value === "object"
    //                           ? JSON.stringify(data.value)
    //                           : data.value || "-"}
    //                       </td>
    //                     </tr>
    //                   ))
    //                 : viewData?.key_value_data &&
    //                   typeof viewData.key_value_data === "object" &&
    //                   Object.entries(viewData.key_value_data).map(
    //                     ([key, value], index) => (
    //                       <tr key={index}>
    //                         <td
    //                           className="topic-testData"
    //                           style={{
    //                             width: "25%",
    //                             fontWeight: "bold",
    //                             wordWrap: "break-word",
    //                           }}
    //                         >
    //                           Key
    //                         </td>
    //                         <td
    //                           style={{
    //                             width: "25%",
    //                             wordWrap: "break-word",
    //                           }}
    //                         >
    //                           {key || "-"}
    //                         </td>
    //                         <td
    //                           className="topic-testData"
    //                           style={{
    //                             width: "25%",
    //                             fontWeight: "bold",
    //                             wordWrap: "break-word",
    //                           }}
    //                         >
    //                           Value
    //                         </td>
    //                         <td
    //                           style={{
    //                             width: "25%",
    //                             wordWrap: "break-word",
    //                           }}
    //                         >
    //                           {typeof value === "object"
    //                             ? JSON.stringify(value)
    //                             : value || "-"}
    //                         </td>
    //                       </tr>
    //                     )
    //                   )}
    //             </tbody>
    //           </Table>
    //         </SimpleBar>
    //       </CardBody>
    //     </Card>
    //   </div>
    // </>
    <div className="">
      <Container fluid>
        <Breadcrumb
          title="Test Data Details"
          breadcrumbItems={breadcrumbItems}
        />
        <div className="d-flex justify-content-end mb-2">
          <Button
            type="button"
            color="primary"
            className="btn-label"
            onClick={onClickBack}
          >
            <i className="bx bx-arrow-back label-icon"></i> Go Back
          </Button>
        </div>
        <Card style={{ minHeight: "400px" }}>
          <CardBody>
            <div className="d-flex justify-content-between mb-4">
              <div className="d-flex align-items-center">
                <div className="avatar-xs me-4">
                  <span
                    className="avatar-title bg-primary"
                    style={{ fontSize: "20px", borderRadius: "8px" }}
                  >
                    {viewData?.file_name?.slice(0, 2).toUpperCase()}
                  </span>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <h5 className="font-size-15 d-flex align-items-center">
                    <span
                      style={{
                        maxWidth: "135px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Link className="text-dark">
                        {viewData?.file_name || "-"}
                      </Link>
                    </span>
                    {/* {viewData?.id && (
                      <span className="ms-2">({viewData?.id})</span>
                    )} */}
                  </h5>
                </div>
              </div>
            </div>

            {/* <div className="mb-4">
              <h6 style={{fontWeight:"550"}}>Test Data Scenario Description</h6>
              <p
                className="text-muted mb-4"
                dangerouslySetInnerHTML={{
                  __html: viewData?.description ? viewData?.description : "-",
                }}
              ></p>
            </div> */}
            <div>
              <Row>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Created By</h6>
                  <p className="text-muted ">
                    {viewData?.created_by_name
                      ? viewData?.created_by_name?.name
                      : "-"}
                  </p>
                </Col>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Created Date</h6>
                  <p className="text-muted ">
                    {viewData?.created_at
                      ? moment(viewData.created_at).format("DD-MM-YYYY")
                      : "-"}
                  </p>
                </Col>
              </Row>

              <div>
                <h6 className="card-title">Key-Value Pairs</h6>
                {viewData?.key_value_data &&
                  renderKeyValueRows(viewData.key_value_data)}
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default ViewTestData
