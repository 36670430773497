import { createSlice } from "@reduxjs/toolkit"

const TestCycleSlice = createSlice({
  name: "TestCycle",
  initialState: {
    singleTestCycle: null,
    editTestData: {
      isEdit: false,
      data: null,
      TestData:[],
    },
    nextTestCycleId: null,
    viewTestCycle: {
      viewCycle: false,
      data: null,
    },
    bulkExecute: {
      data: [],
    },
    executePage:[],
    testCycleChart: [],
    testCycleList: [],
    editTestCycle: {
      isEdit: false,
      data: null,
    },
  },

  reducers: {
    setSingleTestCycle: (state, action) => ({
      ...state,
      singleTestCycle: action.payload,
    }),

    setEditTestData: (state, action) => ({
      ...state,
      editTestData: action.payload,
    }),
    setViewTestCycle: (state, action) => ({
      ...state,
      viewTestCycle: action.payload,
    }),
    setTestData: (state, action) => ({
      ...state,
      TestData: action.payload,
    }),
    setBulkExecute: (state, action) => ({
      ...state,
      bulkExecute: action.payload,
    }),
    setExecutePage: (state, action) => ({
      ...state,
      executePage: action.payload,
    }),
    setTestCycleChart: (state, action) => ({
      ...state,
      testCycleChart: action.payload,
    }),
    setTestCycleList: (state, action) => ({
      ...state,
      testCycleList: action.payload,
    }),
    setCreateEditTestCycle: (state, action) => ({
      ...state,
      editTestCycle: action.payload,
    }),
    setNextCycleId: (state, action) => ({
      ...state,
      nextTestCycleId: action.payload,
    }),
  },
})

export const { setSingleTestCycle, setEditTestData ,setTestData,setViewTestCycle,setBulkExecute,setExecutePage,
  setTestCycleChart,setCreateEditTestCycle,setNextCycleId,setTestCycleList
} = TestCycleSlice.actions

export default TestCycleSlice.reducer
