import React from "react"
import { useSelector } from "react-redux"
import Select from "react-select"

import { Card, CardBody } from "reactstrap"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"

const CycleComparisonGraph = ({
  data,
  selectStyles,
  TestCycleOptionValue,
  search,
  handleCycleSearch,
}) => {
  // Data mapping for the BarChart
  const processedData = data.map(item => ({
    test_cycle_name: item.test_cycle_name,
    total_test_cases: item.total_test_cases,
    passed_test_cases: item.passed_test_cases,
    failed_test_cases: item.failed_test_cases,
    not_run_test_cases: item.not_run_test_cases,
  }))

  // Custom Tooltip Component with Styles
  const CustomTooltip = ({ payload, label, active }) => {
    if (active && payload && payload.length) {
      const {
        total_test_cases,
        passed_test_cases,
        failed_test_cases,
        not_run_test_cases,
      } = payload[0].payload
      return (
        <div style={tooltipStyle}>
          <h6>Cycle Name: {label}</h6>
          <h6>Total Test Cases: {total_test_cases}</h6>
          <h6>Passed: {passed_test_cases}</h6>
          <h6>Failed: {failed_test_cases}</h6>
          <h6>Not Run: {not_run_test_cases}</h6>
        </div>
      )
    }

    return null
  }

  const layoutModeType = useSelector(state => state.Layout.layoutModeType)
  // Tooltip styles
  const tooltipStyle = {
    backgroundColor: layoutModeType === "dark" ? "#000" : "#fff",
    color: "#000",
    borderRadius: "8px",
    padding: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
    fontSize: "13px",
    maxWidth: "350px",
    textAlign: "left",
  }

  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888",
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    multiValue: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#3a3f51" : "#e0e0e0",
    }),
    multiValueLabel: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    multiValueRemove: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
      ":hover": {
        backgroundColor: layoutModeType === "dark" ? "#ff4949" : "#e0e0e0",
        color: "#fff",
      },
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150, // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }

  return (
    <Card className="pt-2 pb-4">
      <CardBody>
        <div
          style={{ width: "100%", height: 300, marginTop: "1em" }}
          className="table-container"
        >
          <div className="d-flex justify-content-between w-100">
            <h4>Cycle Comparison Report</h4>
            <div>
              <Select
                isClearable
                className="select2-selection"
                name="test_cycle_id"
                options={TestCycleOptionValue}
                closeMenuOnSelect={false}
                onChange={handleCycleSearch}
                isMulti
                styles={customStyles}
                placeholder="Select 5 cycles per comparison"
                isOptionDisabled={() => search.length >= 5}
              />
            </div>
          </div>
          {processedData.length === 0 ? (
            <div className="text-center mt-4">
              <h5>No Data Found</h5>
            </div>
          ) : (
            <ResponsiveContainer>
              <BarChart
                data={processedData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="test_cycle_name" />
                <YAxis
                  domain={[0, "auto"]}
                  tickFormatter={value => value}
                  tick={{ fill: "#666" }}
                  axisLine={{ stroke: "#ccc" }}
                  tickLine={{ stroke: "#ccc" }}
                  label={{
                    value: "No. of Test Cases",
                    angle: -90,
                    position: "insideLeft",
                    offset: 10,
                    fill: "#666",
                    style: { textAnchor: "middle", fontSize: "14px" },
                  }}
                  interval={0}
                />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <Bar
                  dataKey="passed_test_cases"
                  stackId="a"
                  fill="#82ca9d"
                  name="Passed Test Cases"
                />
                <Bar
                  dataKey="failed_test_cases"
                  stackId="a"
                  fill="#8884d8"
                  name="Failed Test Cases"
                />
                <Bar
                  dataKey="not_run_test_cases"
                  stackId="a"
                  fill="#ffc658"
                  name="Not Run Test Cases"
                />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </CardBody>
    </Card>
  )
}

export default CycleComparisonGraph
