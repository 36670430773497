import React, { Fragment, useEffect, useState } from "react"
import {
  Row,
  Table,
  Button,
  Input,
  InputGroupText,
  InputGroup,
  Col,
  Spinner,
} from "reactstrap"
import { Link } from "react-router-dom"

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table"

import { rankItem } from "@tanstack/match-sorter-utils"
import NoData from "components/Common/NoData"

// Column Filter
const Filter = ({ column }) => {
  const columnFilterValue = column.getFilterValue()

  return (
    <>
      <DebouncedInput
        type="text"
        value={columnFilterValue ?? ""}
        onChange={value => column.setFilterValue(value)}
        placeholder="Search..."
        className="w-36 border shadow rounded"
        list={column.id + "list"}
      />
      <div className="h-1" />
    </>
  )
}

// Global Filter
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [debounce, onChange, value])

  return (
    <React.Fragment>
      <Col sm={4}>
        <InputGroup className="w-80" style={{ height: "36px" }}>
          <InputGroupText style={{ height: "100%" }}>
            <i className="bx bx-search-alt search-icon"></i>
          </InputGroupText>
          <Input
            {...props}
            value={value}
            onChange={e => setValue(e.target.value)}
          />
        </InputGroup>
      </Col>
    </React.Fragment>
  )
}

const TableContainer = ({
  columns,
  data,
  tableClass,
  theadClass,
  divClassName,
  isBordered,
  isPagination,
  globalFilter,
  setGlobalFilter,
  isGlobalFilter,
  paginationWrapper,
  SearchPlaceholder,
  pagination,
  buttonClass,
  buttonName,
  isAddButton,
  isAddDelete,
  isAddEdit,
  isAddFilter,
  isCustomPageSize,
  handleUserClick,
  handleDeleteClick,
  handleAddClick,
  handleEditClick,
  handleFilterClick,
  isJobListGlobalFilter,
  totalCount,
  pageIndex,
  setPageIndex,
  pageSize,
  setPageSize,
}) => {
  const [columnFilters, setColumnFilters] = useState([])
  // const [globalFilter, setGlobalFilter] = useState("")
  const [loading, setLoading] = useState(true);
  const fuzzyFilter = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value)
    addMeta({
      itemRank,
    })
    return itemRank.passed
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);  
      }, 1000);
    };
    fetchData();
  }, []);

  const table = useReactTable({
    columns,
    data,
    filterFns: { fuzzy: fuzzyFilter },
    state: { columnFilters, globalFilter, pagination: { pageIndex, pageSize } },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(totalCount / pageSize),
  })

  const {
    getHeaderGroups,
    getCanPreviousPage,
    getCanNextPage,
    getPageOptions,
    nextPage,
    previousPage,
    getState,
  } = table

  const handlePageSizeChange = e => {
    setPageIndex(0)
    setPageSize(Number(e.target.value))
  }

  // Helper function to get the visible page numbers
  const getVisiblePageNumbers = (currentPage, totalPageCount) => {
    const maxVisiblePages = 3 // Set the maximum number of pages to display
    let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 0)
    let endPage = startPage + maxVisiblePages - 1

    if (endPage >= totalPageCount) {
      endPage = totalPageCount - 1
      startPage = Math.max(endPage - maxVisiblePages + 1, 0)
    }

    return { startPage, endPage }
  }

  const { startPage, endPage } = getVisiblePageNumbers(
    getState().pagination.pageIndex,
    getPageOptions().length
  )

  return (
    <Fragment>
      {loading ? (
        <div className="text-center mt-5">
          <Spinner color="primary" />
          <p className="mt-2">Loading data...</p>
        </div>
      ) : (
        <> 
      <Row className="mb-2">
        <div className="d-flex justify-content-between">
          {isGlobalFilter && (
            <DebouncedInput
              value={globalFilter ?? ""}
              onChange={value => setGlobalFilter(String(value))}
              className="form-control search-box me-2 mb-2 d-inline-block"
              placeholder={SearchPlaceholder}
            />
          )}

          <div className="d-flex">
            {isAddButton && (
              <div className="text-sm-end">
                <Button
                  type="button"
                  color="success"
                  className="btn-label"
                  onClick={handleAddClick}
                >
                  <i className="mdi mdi-plus label-icon"></i> Create
                </Button>
              </div>
            )}

            {isAddEdit && (
              <div className="text-sm-end " style={{ marginLeft: "7px" }}>
                <Button
                  type="button"
                  color="primary"
                  className="btn-label"
                  onClick={handleEditClick}
                >
                  <i className="mdi mdi-pencil label-icon"></i> Edit
                </Button>
              </div>
            )}

            {isAddDelete && (
              <div className="text-sm-end" style={{ marginLeft: "7px" }}>
                <Button
                  type="button"
                  color="danger"
                  className="btn-label"
                  onClick={handleDeleteClick}
                >
                  <i className="mdi mdi-delete label-icon"></i> Delete
                </Button>
              </div>
            )}

            {isAddFilter && (
              <div className="text-sm-end " style={{ marginLeft: "7px" }}>
                <Button
                  type="button"
                  color="light"
                  className="btn-label"
                  onClick={handleFilterClick}
                >
                  <i className="mdi mdi-filter label-icon"></i> Filter
                </Button>
              </div>
            )}
          </div>
        </div>
      </Row>

      {data?.length > 0 ? (
        <>
          <div
            className={divClassName ? divClassName : "table-responsive"}
            style={{ minHeight: "350px" , borderRadius:"6px"}}
          >
            <Table hover className={tableClass} bordered={isBordered}>
              <thead className={theadClass}>
                {getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          className={`${header.column.columnDef.enableSorting
                              ? "sorting"
                              : ""
                            }`}
                        >
                          {header.isPlaceholder ? null : (
                            <React.Fragment>
                              <div
                                style={{ cursor: "pointer" }}
                                {...{
                                  className: header.column.getCanSort()
                                    ? "cursor-pointer select-none"
                                    : "",
                                  onClick:
                                    header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {header.column.getIsSorted() === "asc" ? (
                                  <span className="sort-icon mx-3">▲</span>
                                ) : header.column.getIsSorted() === "desc" ? (
                                  <span className="sort-icon mx-3">▼</span>
                                ) : null}
                              </div>
                              {header.column.getCanFilter() ? (
                                <div>
                                  <Filter
                                    column={header.column}
                                    table={table}
                                  />
                                </div>
                              ) : null}
                            </React.Fragment>
                          )}
                        </th>
                      )
                    })}
                  </tr>
                ))}
              </thead>

              <tbody>
                {table.getCoreRowModel().rows?.map(row => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells()?.map(cell => {
                        return (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>

          {isPagination && (
            <Row className="mt-2"  style={{marginBottom:"-12px"}}>
              <Col sm={12} md={5} className="d-flex">
                {isCustomPageSize && (
                  <div style={{ width: "125px" }}>
                    <select
                      className="form-select pageSize mb-2"
                      value={table.getState().pagination.pageSize}
                      onChange={handlePageSizeChange}
                    >
                      {[10, 20, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </div>
                )} 
              </Col>
              <Col sm={12} md={7}>
                <div className={paginationWrapper}>
                  <ul className={pagination}>
                    <li
                      className={`paginate_button page-item previous ${!getCanPreviousPage() ? "disabled" : ""
                        }`}
                    >
                      <Link
                        to="#"
                        className="page-link"
                        onClick={() => {
                          if (getCanPreviousPage()) {
                            setPageIndex(getState().pagination.pageIndex - 1)
                          }
                        }}
                      >
                        <i className="mdi mdi-chevron-left"></i>
                      </Link>
                    </li>

                    {startPage > 0 && (
                      <>
                        <li className="paginate_button page-item">
                          <Link
                            to="#"
                            className="page-link"
                            onClick={() => setPageIndex(0)}
                          >
                            1
                          </Link>
                        </li>
                        {startPage > 1 && (
                          <li className="paginate_button page-item disabled">
                            <span className="page-link">...</span>
                          </li>
                        )}
                      </>
                    )}

                    {getPageOptions()
                      .slice(startPage, endPage + 1)
                      .map((item, key) => (
                        <li
                          key={key}
                          className={`paginate_button page-item ${getState().pagination.pageIndex === item
                              ? "active"
                              : ""
                            }`}
                        >
                          <Link
                            to="#"
                            className="page-link"
                            onClick={() => setPageIndex(item)}
                          >
                            {item + 1}
                          </Link>
                        </li>
                      ))}

                    {endPage < getPageOptions().length - 1 && (
                      <>
                        {endPage < getPageOptions().length - 2 && (
                          <li className="paginate_button page-item disabled">
                            <span className="page-link">...</span>
                          </li>
                        )}
                        <li className="paginate_button page-item">
                          <Link
                            to="#"
                            className="page-link"
                            onClick={() =>
                              setPageIndex(getPageOptions().length - 1)
                            }
                          >
                            {getPageOptions().length}
                          </Link>
                        </li>
                      </>
                    )}

                    <li
                      className={`paginate_button page-item next ${!getCanNextPage() ? "disabled" : ""
                        }`}
                    >
                      <Link
                        to="#"
                        className="page-link"
                        onClick={() => {
                          if (getCanNextPage()) {
                            setPageIndex(getState().pagination.pageIndex + 1)
                          }
                        }}
                      >
                        <i className="mdi mdi-chevron-right"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          )}
        </>
      ) : (
        <NoData />
      )}
      </>
      )}
    </Fragment>
  )
}

export default TableContainer
