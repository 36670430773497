import React from "react";
import { Card, CardBody, Table } from "reactstrap";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { useSelector } from "react-redux";
import moment from "moment"; // Import moment for date formatting
import NoProject from "components/Common/NoProject";

const MyProjects = () => {
  const data = useSelector(
    state => state?.Dashboard?.GlobalDashboardPartitionUser?.project_details || {}
  );

  const projects = useSelector(state => state?.Project?.allProjectList);

  // Helper function to format date
  const formatDate = date => {
    return date ? moment(date, "DD-MM-YYYY").format("MMM DD") : "-";
  };

  return (
    <div>
      <Card className="overflow-hidden" style={{ height: "300px" }}>
        <h5 className="mx-4 mt-3">My Projects ({projects.length})</h5>
        <CardBody className="pt-2">
          <div style={{ maxHeight: "220px", overflowY: "auto" }}>
            {/* Check if there are any projects */}
            {Object.keys(data).length > 0 ? (
              <Table
                striped
                className="project-list-table align-middle  dt-responsive nowrap w-100 dataTable"
              >
                <thead
                  style={{
                    position: "sticky",
                    top: 0,
                  }}
                >
                  <tr>
                    <th>Project Name</th>
                    <th>Project Head</th>
                    <th>Team Members</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(data).map(
                    ([projectName, projectData], index) => {
                      const filteredUsers = projectData.users.filter(Boolean); // filter out null values
                      const usersToShow = filteredUsers.slice(0, 3); // first 3 users
                      const remainingCount =
                        filteredUsers.length - usersToShow.length; // remaining users count

                      return (
                        <tr key={index}>
                          <td>
                            <div>{projectName}</div>
                            <div
                              style={{
                                fontSize: "0.85rem",
                                color: "#6c757d",
                              }}
                            >
                              {formatDate(projectData.planned_start_date)} -{" "}
                              {projectData.actual_end_date
                                ? formatDate(projectData.actual_end_date)
                                : "Ongoing"}
                            </div>
                          </td>
                          <td className="text-center">
                            {projectData.project_head || "-"}
                          </td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {usersToShow.map((user, userIndex) => {
                                const id = `member-${projectName.replace(
                                  /[^a-zA-Z0-9]/g,
                                  ""
                                )}-${userIndex}`;
                                return (
                                  <div
                                    key={userIndex}
                                    style={{
                                      display: "inline-block",
                                      marginRight: "-8px",
                                    }}
                                  >
                                    <div className="avatar-group">
                                      <div className="avatar-group-item">
                                        <Link
                                          to="#"
                                          className="d-inline-block"
                                          id={id}
                                        >
                                          <div className="avatar-xxs">
                                            <span
                                              className="avatar-title rounded-circle bg-primary text-light font-size-14"
                                            >
                                              {user.charAt(0).toUpperCase()}
                                            </span>
                                          </div>
                                        </Link>
                                      </div>
                                    </div>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={id}
                                    >
                                      {user}
                                    </UncontrolledTooltip>
                                  </div>
                                );
                              })}
                              {remainingCount > 0 && (
                                <div className="avatar-group">
                                  <div className="avatar-group-item">
                                    <Link
                                      to="#"
                                      className="d-inline-block"
                                      id={`member-${projectName.replace(
                                        /[^a-zA-Z0-9]/g,
                                        ""
                                      )}-more`}
                                    >
                                      <div className="avatar-xxs">
                                        <span
                                          className="avatar-title rounded-circle bg-primary text-light font-size-14"
                                        >
                                          {remainingCount}+
                                        </span>
                                      </div>
                                    </Link>
                                  </div>
                                  <UncontrolledTooltip
                                    placement="top"
                                    target={`member-${projectName.replace(
                                      /[^a-zA-Z0-9]/g,
                                      ""
                                    )}-more`}
                                  >
                                    {remainingCount} more
                                  </UncontrolledTooltip>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </Table>
            ) : (
              <div className="text-center my-5">
                {/* <h5>No projects found</h5> */}
                <NoProject />
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default MyProjects;
