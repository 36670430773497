import { toast } from "react-toastify"
import httpInjectorService from "services/http-Injector.Service"
import { setLoading } from "store/Slices/LoadingSlice"
import { setSingleTestData, setTestData } from "store/Slices/TestDataSlice"

export const fetchAllTestDataList = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.getAllTestDataAPI(queryString)
      const data = response
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const fetchTestDataList = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.getAllTestDataAPI(bodyoption)
      const data = response
      dispatch(setTestData(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const GetTestDataByIdAPI = id => {
  return async dispatch => {
    dispatch(setLoading(true))

    try {
      const response = await httpInjectorService.GetTestDataById(id)
      const data = response
      dispatch(setSingleTestData(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const createTestDataAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.createTestData(formData)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Test Data created successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      } else {
        toast.warn(response.message, {
          position: "top-right",
          id: "Test Data",
          autoClose: 5000,
        })
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const uploadTestDataTemplateAPI = bodyOption => {
  return async dispatch => {
    dispatch(setLoading(true))

    try {
      const response = await httpInjectorService.uploadTestDataTemplate(
        bodyOption
      )

      return response
    } catch (error) {
      console.error("Upload failed:", error.message || error)
      toast.error("Upload failed. Please try again later.", {
        position: "top-right",
        id: "Test",
        autoClose: 3000,
      })
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const EditTestDataAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.EditTestData(
        formData,
        bodyoption?.id
      )
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Test TestData updated successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const deleteTestDataAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))

    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.deleteTestData(bodyoption)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Test Data Deleted successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const BulkDeleteTestDataAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (bodyoption[key] !== undefined && bodyoption[key] !== null) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.BulkDeleteTestData(formData)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Test Data Deleted successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
