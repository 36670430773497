import React, { useEffect, useState } from "react"
import {
  Alert,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap"
import Select from "react-select"
import { fetchAllBuildsList } from "store/actions/BuildsActions"
import { fetchAllModulesList } from "store/actions/ModuleActions"
import { fetchAllRequirementsList } from "store/actions/RequirementActions"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { getTagsStautusAPI } from "store/actions/TagsAction"
import {
  fetchAllTeamsDetailsUserAssigneeList,
  fetchUsersList,
} from "store/actions/UserActions"
import { fetchAllTestScenarioList } from "store/actions/TestScenarioActions"
import { createOptions } from "helpers/helper"
import { toast } from "react-toastify"

const BulkEditTestCase = ({
  isOpen,
  toggle,
  setSelectedRows,
  handleBulkEdit,
}) => {
  const dispatch = useDispatch()

  const [selectedBuild, setSelectedBuild] = useState(null)
  const [selectedModule, setSelectedModule] = useState(null)
  const [selectedRequirement, setSelectedRequirement] = useState(null)
  const [selectedTestScenario, setSelectedTestScenario] = useState(null)
  const [selectedTestingType, setSelectedTestingType] = useState(null)
  const [selectedExecutionType, setSelectedExecutionType] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [selectedPriority, setSelectedPriority] = useState(null)
  const [selectedAssignTo, setSelectedAssignTo] = useState(null)
  const [selectedApprovalFrom, setSelectedApprovalFrom] = useState(null)

  const [buildOptionValue, setBuildOptionValues] = useState([])
  const [moduleOptionValue, setModuleOptionValues] = useState([])
  const [requirementOptionValue, setrequirementOptionValues] = useState([])
  const [testScenariosIDsValue, setTestScenariosIDsValue] = useState([])
  const [approvalRequestOptionsValue, setApprovalRequestOptionsValue] =
    useState([])
  const [assignToOptionsValue, setAssignToOptionsValue] = useState([])

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const layoutModeType = useSelector(state => state.Layout.layoutModeType)

  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888",
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Ensure input text is visible
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150, // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }
  const handleClearFilters = () => {
    setSelectedBuild(null)
    setSelectedModule(null)
    setSelectedRequirement(null)
    setSelectedTestingType(null)
    setSelectedExecutionType(null)
    setSelectedPriority(null)
    setSelectedAssignTo(null)
    setSelectedApprovalFrom(null)
    setSelectedStatus(null)
    setSelectedTestScenario(null)
  }
  const handleApplyBulkEdit = () => {
    if (selectedStatus?.value === "Pending Approval" && !selectedApprovalFrom) {
      toast.warn(
        "'Request Approval From' is required if status is set to Pending Approval"
      )
      return
    }
    let data = {
      build_id: selectedBuild?.value,
      module_id: selectedModule?.value,
      requirement_id: selectedRequirement?.value,
      testing_type: selectedTestingType?.value,
      execution_type: selectedExecutionType?.value,
      priority: selectedPriority?.value,
      assign_to: selectedAssignTo?.value,
      approval_from: selectedApprovalFrom?.value,
      test_scenario_id: selectedTestScenario?.value,
      test_status: selectedApprovalFrom?.value
        ? "Pending Approval"
        : selectedStatus?.value,
      project_id: globalProject?.value,
    }
    handleBulkEdit(data)
    setTimeout(() => {
      handleClearFilters()
    }, 2000)
  }

  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))

  useEffect(() => {
    let bodyOption = {
      project_id: globalProject?.value || selectedProject?.value,
    }
    const fetchOptions = async () => {
      try {
        const responses = await Promise.all([
          dispatch(fetchAllBuildsList(bodyOption)),
          dispatch(fetchAllModulesList(bodyOption)),
          dispatch(fetchAllRequirementsList(bodyOption)),
          dispatch(fetchAllTestScenarioList(bodyOption)),
          // dispatch(
          //   fetchUsersList({
          //     project_id: globalProject?.value || selectedProject?.value,
          //   })
          // ),
          dispatch(
            fetchAllTeamsDetailsUserAssigneeList({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
        ])

        const [
          buildResponse,
          moduleResponse,
          reqResponse,
          testScenarioIdsResponse,
          userListResponse,
        ] = responses

        const buildOptions = buildResponse?.map(entry => ({
          value: Math.round(entry.id),
          label: entry.build_id,
        }))

        const moduleOptions = moduleResponse?.map(entry => ({
          value: entry.id,
          label: entry.module_name,
        }))

        const reqOptions = reqResponse?.map(entry => ({
          value: entry.id,
          label: entry.requirement_title,
        }))
        const testScenariosIDsOptions = testScenarioIdsResponse?.map(entry => ({
          value: entry.id,
          label: entry.test_scenario_id,
        }))
        const approvalRequestOptions = userListResponse?.results?.map(
          entry => ({
            value: entry.id,
            label: entry.user_name,
          })
        )
        const assignToOptions = userListResponse?.results?.map(entry => ({
          value: entry.id,
          label: entry.user_name,
        }))
        // userTeamDetailsResponse?.forEach(entry => {
        //   approvalRequestOptions.push({
        //     value: entry.id,
        //     label: entry.user_name,
        //   })
        //   assignToOptions.push({
        //     value: entry.id,
        //     label: entry.user_name,
        //   })
        // })
        setBuildOptionValues(buildOptions)
        setModuleOptionValues(moduleOptions)
        setrequirementOptionValues(reqOptions)
        setTestScenariosIDsValue(testScenariosIDsOptions)
        setApprovalRequestOptionsValue(approvalRequestOptions)
        setAssignToOptionsValue(assignToOptions)
      } catch (error) {
        console.error(error)
      }
    }

    fetchOptions()
  }, [isOpen])

  const handleClose = () => {
    toggle(),
      setSelectedRows([]),
      setTimeout(() => {
        handleClearFilters()
      }, 2000)
  }

  const [testingTypeOptions, setTestingTypeOptions] = useState([])
  const [testStatusOptions, setTestStatusOptions] = useState([])
  const [executionOptions, setExecutionOptions] = useState([])
  const [priorityOptions, setPriorityOptions] = useState([])

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const bodyOption = { project_id: globalProject?.value }
        const metaDataResponse = await dispatch(getTagsStautusAPI(bodyOption))

        const { Testing_Type, Test_Status, Execution_Type, Priority } =
          metaDataResponse?.data || {}

        setTestStatusOptions(createOptions(Test_Status || []))
        setExecutionOptions(createOptions(Execution_Type || []))
        setTestingTypeOptions(createOptions(Testing_Type || []))
        setPriorityOptions(createOptions(Priority || []))
      } catch (error) {
        console.log(error)
      }
    }

    fetchOptions()
  }, [globalProject, isOpen])
  return (
    <Offcanvas isOpen={isOpen} direction="end" toggle={handleClose}>
      <OffcanvasHeader toggle={handleClose}>
        Bulk Edit Test Case
      </OffcanvasHeader>
      <div className=" mx-3">
        <Alert color="info" role="alert">
          By Updating all the previous associations for selected records will be
          reset.
        </Alert>
      </div>
      <OffcanvasBody>
        <div className="mb-3">
          <Label>Build</Label>
          <Select
            value={selectedBuild}
            onChange={setSelectedBuild}
            options={buildOptionValue}
            styles={customStyles}
            maxMenuHeight={200}
            className="select2-selection"
          />
        </div>
        <div className="mb-3">
          <Label>Module</Label>
          <Select
            value={selectedModule}
            onChange={setSelectedModule}
            options={moduleOptionValue}
            styles={customStyles}
            maxMenuHeight={200}
            className="select2-selection"
          />
        </div>
        <div className="mb-3">
          <Label>Requirement</Label>
          <Select
            value={selectedRequirement}
            onChange={setSelectedRequirement}
            options={requirementOptionValue}
            styles={customStyles}
            maxMenuHeight={200}
            className="select2-selection"
          />
        </div>
        <div className="mb-3">
          <Label>Test Scenario ID</Label>
          <Select
            value={selectedTestScenario}
            onChange={setSelectedTestScenario}
            options={testScenariosIDsValue}
            styles={customStyles}
            maxMenuHeight={200}
            className="select2-selection"
          />
        </div>
        <div className="mb-3">
          <Label>Testing Type</Label>
          <Select
            value={selectedTestingType}
            onChange={setSelectedTestingType}
            options={testingTypeOptions}
            styles={customStyles}
            maxMenuHeight={200}
            className="select2-selection"
          />
        </div>
        <div className="mb-3">
          <Label>Execution Type</Label>
          <Select
            value={selectedExecutionType}
            onChange={setSelectedExecutionType}
            options={executionOptions}
            styles={customStyles}
            maxMenuHeight={200}
            className="select2-selection"
          />
        </div>
        <div className="mb-3">
          <Label>Status</Label>
          <Select
            value={selectedStatus}
            onChange={setSelectedStatus}
            options={testStatusOptions}
            styles={customStyles}
            maxMenuHeight={200}
            className="select2-selection"
          />
        </div>
        <div className="mb-3">
          <Label>Priority</Label>
          <Select
            value={selectedPriority}
            onChange={setSelectedPriority}
            options={priorityOptions}
            styles={customStyles}
            maxMenuHeight={200}
            className="select2-selection"
          />
        </div>
        <div className="mb-3">
          <Label>Assign To</Label>
          <Select
            value={selectedAssignTo}
            onChange={setSelectedAssignTo}
            options={assignToOptionsValue}
            styles={customStyles}
            maxMenuHeight={200}
            className="select2-selection"
          />
        </div>
        <div className="mb-3">
          <Label>Request Approval From{selectedStatus?.value === "Pending Approval" && <span className="text-danger">*</span>}</Label>
          <Select
            value={selectedApprovalFrom}
            onChange={setSelectedApprovalFrom}
            options={approvalRequestOptionsValue}
            styles={customStyles}
            maxMenuHeight={200}
            className="select2-selection"
          />
        </div>
        <div className="d-flex justify-content-between mt-5">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleApplyBulkEdit}
          >
            Apply
          </button>
        </div>
      </OffcanvasBody>
    </Offcanvas>
  )
}

export default BulkEditTestCase
