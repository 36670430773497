import axios from "axios"
import BASE_URL from "./apiConfig"
import { getToken } from "helpers/helper"

const PutAPI = async (url, paramsObj, headersObj = {}) => {
  url = `${BASE_URL}/api/${url}`

  // Retrieve and parse the token
  const tokenid =getToken()

  // Add Authorization header if token exists
  const headers = tokenid
    ? { ...headersObj, Authorization: `Bearer ${tokenid}` }
    : headersObj

  try {
    const response = await axios.put(url, paramsObj, { headers })
    return response.data
  } catch (error) {
    // Handle error appropriately
    return error.response
      ? error.response.data
      : { error: "An unknown error occurred" }
  }
}

export default { PutAPI }
