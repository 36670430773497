import React, { useEffect, useMemo, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Container,
  Form,
  InputGroup,
  UncontrolledTooltip,
} from "reactstrap"
import { useFormik } from "formik"
import "flatpickr/dist/themes/material_blue.css"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import { createOptionsForReports } from "../../../helpers/helper"
import { fetchAllBuildsList } from "store/actions/BuildsActions"
import { fetchAllRequirementsList } from "store/actions/RequirementActions"
import { fetchAllTeamsDetailsUserAssigneeList } from "store/actions/UserActions"
import { getTagsStautusAPI } from "store/actions/TagsAction"
import Flatpickr from "react-flatpickr"
import { Link } from "react-router-dom"
import { setSelectedTestExecutionformValues } from "store/Slices/ReportsSlice"
import GenerateReport from "./GenerateReport"
import SaveAsTemplate from "../common/SaveAsTemplate"
import { reports } from "../helper/config"
import {
  fetchAllReportsTemplateAPI,
  GetSingleReportTemplateAPI,
  saveAsTemplateAPI,
} from "store/actions/ReportsAction"
import { fetchTestCycleDetailsAPI } from "store/actions/TestCycleActions"
import * as Yup from "yup"
import moment from "moment"
import NoPermission from "components/Common/NoPermission"

const TestCaseExecutionReport = () => {
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))
  const dispatch = useDispatch()
  const [selectedTemplate, setSelectedTemplate] = useState("")
  const [selectedTemplateOption, setselectedTemplateOption] = useState(null)
  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const validationSchema = Yup.object({
    filter_test_case_created_from: Yup.date()
      .nullable()
      .test(
        "fromDate-test",
        "From Date is required when To Date is selected",
        function (value) {
          const { filter_test_case_created_to } = this.parent // Access the value of filter_test_case_created_to
          if (filter_test_case_created_to && !value) {
            return false // From Date is required if To Date is selected
          }
          return true // If To Date is not selected, allow fromDate to be null
        }
      ),

    filter_test_case_created_to: Yup.date()
      .nullable()
      .test(
        "filter_test_case_created_to-test",
        "To Date must be after From Date",
        function (value) {
          const { filter_test_case_created_from } = this.parent // Access the value of filter_test_case_created_from
          if (filter_test_case_created_from && value) {
            return new Date(value) > new Date(filter_test_case_created_from) // Validate that filter_test_case_created_to is after fromDate
          }
          return true // If fromDate is not selected, allow filter_test_case_created_to to be null
        }
      ),
    filter_test_case_execution_from: Yup.date()
      .nullable()
      .test(
        "fromDate-test",
        "From Date is required when To Date is selected",
        function (value) {
          const { filter_test_case_execution_to } = this.parent // Access the value of filter_test_case_execution_to
          if (filter_test_case_execution_to && !value) {
            return false // From Date is required if To Date is selected
          }
          return true // If To Date is not selected, allow fromDate to be null
        }
      ),

    filter_test_case_execution_to: Yup.date()
      .nullable()
      .test(
        "filter_test_case_execution_to-test",
        "To Date must be after From Date",
        function (value) {
          const { filter_test_case_execution_from } = this.parent // Access the value of filter_test_case_execution_from
          if (filter_test_case_execution_from && value) {
            return new Date(value) > new Date(filter_test_case_execution_from) // Validate that filter_test_case_execution_to is after fromDate
          }
          return true // If fromDate is not selected, allow filter_test_case_execution_to to be null
        }
      ),
  })
  const formik = useFormik({
    initialValues: {
      filter_build_id: null,
      filter_cycle_name: null,
      filter_tested_by: null,
      filter_execution_status: null,
      filter_execution_type: null,
      filter_requirement: null,
      filter_assigned_to: null,
      filter_test_case_created_from: "",
      filter_test_case_created_to: "",
      filter_test_case_execution_from: "",
      filter_test_case_execution_to: "",
      selectedFields: {
        testCaseName: true,
        testCaseSummary: false,
        Priority: false,
        ExecutionType: false,
        PreConditions: false,
        PostCondition: false,
        Steps: false,
        TestScenarioName: false,
        BuildName: false,
        CreatedAt: false,
        Cycle: false,
        CycleStatus: false,
        ExecutedStatus: false,
        ExpectedResult: false,
        AssociatedDefects: false,
        TestCaseExecutedBy: false,
        Comments: false,
        TestCaseExecutionDate: false,
        RequirementTitle: false,
        Attachment: false,
        AssignedTo: false,
        EstimatedTime: false,
        ExecutedTime: false,
      },
    },
    validationSchema,
    onSubmit: values => {
      dispatch(setSelectedTestExecutionformValues(values))
    },
  })

  //select all functionality
  const [selectAll, setSelectAll] = useState(false)

  const handleSelectAll = () => {
    const updatedFields = Object.keys(formik.values.selectedFields).reduce(
      (acc, field) => {
        // Ensure 'DefectId' remains checked and can't be unchecked
        acc[field] = field === "testCaseName" ? true : !selectAll
        return acc
      },
      {}
    )

    formik.setFieldValue("selectedFields", updatedFields)
    setSelectAll(!selectAll)
  }

  useEffect(() => {
    // Check if all checkboxes are selected (except testCaseName)
    const allSelected = Object.keys(formik.values.selectedFields).every(
      field => field === "testCaseName" || formik.values.selectedFields[field]
    )

    setSelectAll(allSelected) // Automatically set the selectAll checkbox
  }, [formik.values.selectedFields])

  const [optionValues, setOptionValues] = useState({
    filter_build_id: [],
    filter_cycle_name: [],
    filter_tested_by: [],
    filter_execution_status: [],
    filter_execution_type: [],
    filter_requirement: [],
    filter_assigned_to: [],
  })

  const [options, setOptions] = useState({
    Execution_Type: [],
    Execution_Status: [],
  })

  const bodyOption = useMemo(
    () => ({
      project_id: globalProject?.value || selectedProject?.value,
    }),
    [globalProject, selectedProject]
  )

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const responses = await Promise.all([
          dispatch(fetchAllBuildsList(bodyOption)),
          dispatch(fetchAllRequirementsList(bodyOption)),
          dispatch(fetchAllTeamsDetailsUserAssigneeList(bodyOption)),
          dispatch(getTagsStautusAPI(bodyOption)),
          dispatch(fetchTestCycleDetailsAPI(bodyOption)),
        ])

        const [
          buildResponse,
          reqResponse,
          userListResponse,
          metaDataResponse,
          testCycleResponse,
        ] = responses

        const buildOptions = [
          { value: "", label: "All" },
          ...(Array.isArray(buildResponse)
            ? buildResponse.map(entry => ({
                value: entry.id,
                label: entry.build_id,
              }))
            : []),
        ]

        const reqOptions = [
          { value: "", label: "All" },
          ...(Array.isArray(reqResponse)
            ? reqResponse.map(entry => ({
                value: entry.id,
                label: entry.requirement_title,
              }))
            : []),
        ]
        const testCycleOptions = [
          { value: "", label: "All" },
          ...(Array.isArray(testCycleResponse)
            ? testCycleResponse.map(entry => ({
                value: entry.id,
                label: entry.test_cycle_id,
              }))
            : []),
        ]
        const assignToOptions = [
          { value: "", label: "All" },
          ...(Array.isArray(userListResponse?.results)
            ? userListResponse.results.map(entry => ({
                value: entry.id,
                label: entry.user_name,
              }))
            : []),
        ]

        setOptions({
          Execution_Status: metaDataResponse?.data?.Execution_Status || [],
          Execution_Type: metaDataResponse?.data?.Execution_Type || [],
        })

        setOptionValues(prev => ({
          ...prev,
          filter_build_id: buildOptions,
          filter_requirement: reqOptions,
          filter_assigned_to: assignToOptions,
          filter_tested_by: assignToOptions,
          filter_cycle_name: testCycleOptions,
        }))
      } catch (error) {
        console.log(error)
      }
    }

    fetchOptions()
  }, [])

  useEffect(() => {
    setOptionValues(prev => ({
      ...prev,
      filter_execution_type: createOptionsForReports(options.Execution_Type),
      filter_execution_status: createOptionsForReports(
        options.Execution_Status
      ),
    }))
  }, [options.Execution_Type, options.Execution_Status])

  const selectedFormValues = useSelector(
    state => state?.Reports?.selectedTestExecutionFormValues
  )

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSaveTemplate, setIsSaveTemplate] = useState(false)

  const handleGenerateReport = () => {
    formik.validateForm().then(() => {
      if (formik.isValid) {
        setIsModalOpen(!isModalOpen)
      } else {
        console.log("Form has errors:", formik.errors)
      }
    })
  }

  const handleSaveAsTemplate = () => {
    formik.validateForm().then(() => {
      if (formik.isValid) {
        setIsSaveTemplate(!isSaveTemplate)
      } else {
        console.log("Form has errors:", formik.errors)
      }
    })
  }
  const fetchTableData = async () => {
    let bodyoption = {
      project_id: globalProject?.value || selectedProject?.value,
      report_type: reports?.report_type?.testExecution,
    }
    try {
      const data = await dispatch(fetchAllReportsTemplateAPI(bodyoption))
      setTemplates(data)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }
  // Mapping of formState keys to payload keys
  const fieldMappings = {
    testCaseName: "test_case_name",
    testCaseSummary: "test_case_summary",
    Priority: "priority",
    ExecutionType: "execution_type",
    PreConditions: "pre_condition",
    PostCondition: "post_condition",
    Steps: "steps",
    TestScenarioName: "test_scenario_name",
    BuildName: "build_name",
    CreatedAt: "created_at",
    Cycle: "cycle_name",
    CycleStatus: "cycle_status",
    ExecutedStatus: "executed_status",
    ExpectedResult: "expected_result",
    AssociatedDefects: "associated_defects",
    TestCaseExecutedBy: "executed_by",
    Comments: "comments",
    TestCaseExecutionDate: "test_case_execution_date",
    RequirementTitle: "requirement",
    Attachment: "attachment",
    AssignedTo: "assigned_to",
    EstimatedTime: "estimated_time",
    ExecutedTime: "executed_time",
  }

  const selectedFields = selectedFormValues?.selectedFields || {}

  // Create an array of selected field keys based on the mapping and values being true
  const selectedFieldKeys = Object.keys(selectedFields)
    .filter(key => selectedFields[key])
    .map(key => fieldMappings[key])
    .filter(Boolean) // Remove undefined entries

  const SaveTemplate = templateName => {
    const bodyOptions = {
      template_name: templateName,
      filters_data: [
        {
          key: "build",
          value: selectedFormValues?.filter_build_id || null,
        },
        {
          key: "cycle",
          value: selectedFormValues?.filter_cycle_name || null,
        },
        {
          key: "Execution_type",
          value: selectedFormValues?.filter_execution_type || null,
        },
        {
          key: "Executed_status",
          value: selectedFormValues?.filter_execution_status || null,
        },
        {
          key: "tested_by",
          value: selectedFormValues?.filter_tested_by || null,
        },
        {
          key: "requirement",
          value: selectedFormValues?.filter_requirement || null,
        },
        {
          key: "assignto",
          value: selectedFormValues?.filter_assigned_to || null,
        },
        {
          key: "to_date_created",
          value: selectedFormValues?.filter_test_case_created_from || null,
        },
        {
          key: "from_date_created",
          value: selectedFormValues?.filter_test_case_created_to || null,
        },
        {
          key: "to_date_execution",
          value: selectedFormValues?.filter_test_case_execution_from || null,
        },
        {
          key: "from_date_execution",
          value: selectedFormValues?.filter_test_case_execution_to || null,
        },
      ],

      columns_data: selectedFieldKeys,
      project_id: globalProject?.value,
      report_type: reports?.report_type?.testExecution,
    }

    try {
      const res = dispatch(saveAsTemplateAPI(bodyOptions))
      fetchTableData()
      setIsSaveTemplate(false)
      if (res) {
        fetchTableData()
        setselectedTemplateOption("")
        dispatch(setSelectedTestExecutionformValues(null))
      }
      formik.resetForm()
    } catch (error) {}
  }

  const [templates, setTemplates] = useState([])
  const templateListOption = Array.isArray(templates)
    ? templates.map(item => ({
        label: item?.template_name,
        value: item?.id,
      }))
    : []

  useEffect(() => {
    fetchTableData()
  }, [globalProject?.value])

  const handleSavedTemplateChange = async selectedOption => {
    if (!selectedOption) {
      setSelectedTemplate(null)
      formik.resetForm()
      return
    }

    setSelectedTemplate(selectedOption)

    try {
      const response = await dispatch(
        GetSingleReportTemplateAPI(selectedOption.value)
      )
      setselectedTemplateOption(response)
    } catch (error) {
      console.error("Error fetching template data:", error)
    }
  }
  useEffect(() => {
    fetchTableData()
  }, [isSaveTemplate])
  useEffect(() => {
    if (selectedTemplateOption) {
      const updatedValues = { ...formik.initialValues }

      selectedTemplateOption.filters_data.forEach(filter => {
        switch (filter.key) {
          case "build":
            updatedValues.filter_build_id = filter.value
            break
          case "Execution_type":
            updatedValues.filter_execution_type = filter.value
            break
          case "Executed_status":
            updatedValues.filter_execution_status = filter.value
            break
          case "tested_by":
            updatedValues.filter_tested_by = filter.value
            break
          case "cycle":
            updatedValues.filter_cycle_name = filter.value
            break
          case "requirement":
            updatedValues.filter_requirement = filter.value
            break
          case "assignto":
            updatedValues.filter_assigned_to = filter.value
            break
          case "to_date_created":
            updatedValues.filter_test_case_created_to = filter.value
            break
          case "from_date_created":
            updatedValues.filter_test_case_created_from = filter.value
            break
          case "to_date_execution":
            updatedValues.filter_test_case_execution_to = filter.value
            break
          case "from_date_execution":
            updatedValues.filter_test_case_execution_from = filter.value
            break
          default:
            break
        }
      })

      const columns = selectedTemplateOption.columns_data?.split(",") || []
      const updatedSelectedFields = { ...formik.initialValues.selectedFields }

      const fieldMapping = {
        testCaseName: "test_case_name",
        testCaseSummary: "test_case_summary",
        Priority: "priority",
        ExecutionType: "execution_type",
        PreConditions: "pre_condition",
        PostCondition: "post_condition",
        Steps: "steps",
        TestScenarioName: "test_scenario_name",
        BuildName: "build_name",
        CreatedAt: "created_at",
        Cycle: "cycle_name",
        CycleStatus: "cycle_status",
        ExecutedStatus: "executed_status",
        ExpectedResult: "expected_result",
        AssociatedDefects: "associated_defects",
        TestCaseExecutedBy: "executed_by",
        Comments: "comments",
        TestCaseExecutionDate: "test_case_execution_date",
        RequirementTitle: "requirement",
        Attachment: "attachment",
        AssignedTo: "assigned_to",
        EstimatedTime: "estimated_time",
        ExecutedTime: "executed_time",
      }

      Object.keys(updatedSelectedFields).forEach(key => {
        const columnName = fieldMapping[key]
        updatedSelectedFields[key] = columns.includes(columnName)
      })

      updatedValues.selectedFields = updatedSelectedFields

      formik.setValues(updatedValues)
    }
  }, [selectedTemplateOption])
  const resetFilter = () => {
    dispatch(setSelectedTestExecutionformValues(null))
    setIsModalOpen(false)
    setSelectedTemplate(null)

    formik.resetForm()
  }

  const layoutModeType = useSelector(state => state.Layout.layoutModeType)
  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888",
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Ensure input text is visible
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150, // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }

  const permission = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission = permission?.permissions?.[0]?.menu_item?.Reports
  return (
    <React.Fragment>
      {rolePermission?.testexecutionreports?.create ? (
        <>
          {" "}
          <Container fluid>
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg={7}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg={6}>
                          {" "}
                          <div className="mb-3">
                            <Label className="form-label">Build</Label>
                            <Select
                              isClearable
                              styles={customStyles}
                              className="select2-selection"
                              placeholder="All"
                              name="filter_build_id"
                              options={optionValues?.filter_build_id}
                              onChange={option =>
                                formik.setFieldValue(
                                  "filter_build_id",
                                  option?.value
                                )
                              }
                              onBlur={() =>
                                formik.setFieldTouched("filter_build_id")
                              }
                              value={optionValues?.filter_build_id?.find(
                                option =>
                                  option.value === formik.values.filter_build_id
                              )}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          {" "}
                          <div className="mb-3">
                            <Label className="form-label">
                              Requirement Title
                            </Label>
                            <Select
                              isClearable
                              styles={customStyles}
                              className="select2-selection"
                              placeholder="All"
                              name="filter_requirement"
                              options={optionValues?.filter_requirement}
                              onChange={option =>
                                formik.setFieldValue(
                                  "filter_requirement",
                                  option?.value
                                )
                              }
                              onBlur={() =>
                                formik.setFieldTouched("filter_requirement")
                              }
                              value={optionValues?.filter_requirement?.find(
                                option =>
                                  option.value ===
                                  formik.values.filter_requirement
                              )}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          {" "}
                          <div className="mb-3">
                            <Label className="form-label">Cycle</Label>
                            <Select
                              isClearable
                              styles={customStyles}
                              className="select2-selection"
                              placeholder="All"
                              name="filter_cycle_name"
                              options={optionValues?.filter_cycle_name}
                              onChange={option =>
                                formik.setFieldValue(
                                  "filter_cycle_name",
                                  option?.value
                                )
                              }
                              onBlur={() =>
                                formik.setFieldTouched("filter_cycle_name")
                              }
                              value={optionValues?.filter_cycle_name?.find(
                                option =>
                                  option.value ===
                                  formik.values.filter_cycle_name
                              )}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          {" "}
                          <div className="mb-3">
                            <Label className="form-label">
                              Executed Status
                            </Label>
                            <Select
                              isClearable
                              styles={customStyles}
                              className="select2-selection"
                              placeholder="All"
                              name="filter_execution_status"
                              options={optionValues?.filter_execution_status}
                              onChange={option =>
                                formik.setFieldValue(
                                  "filter_execution_status",
                                  option?.value
                                )
                              }
                              onBlur={() =>
                                formik.setFieldTouched(
                                  "filter_execution_status"
                                )
                              }
                              value={optionValues?.filter_execution_status?.find(
                                option =>
                                  option.value ===
                                  formik.values.filter_execution_status
                              )}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          {" "}
                          <div className="mb-3">
                            <Label className="form-label">Execution Type</Label>
                            <Select
                              isClearable
                              styles={customStyles}
                              className="select2-selection"
                              placeholder="All"
                              name="filter_execution_type"
                              options={optionValues?.filter_execution_type}
                              onChange={option =>
                                formik.setFieldValue(
                                  "filter_execution_type",
                                  option?.value
                                )
                              }
                              onBlur={() =>
                                formik.setFieldTouched("filter_execution_type")
                              }
                              value={optionValues?.filter_execution_type?.find(
                                option =>
                                  option.value ===
                                  formik.values.filter_execution_type
                              )}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          {" "}
                          <div className="mb-3">
                            <Label className="form-label">Assigned To</Label>
                            <Select
                              isClearable
                              styles={customStyles}
                              className="select2-selection"
                              placeholder="All"
                              name="filter_assigned_to"
                              options={optionValues?.filter_assigned_to}
                              onChange={option =>
                                formik.setFieldValue(
                                  "filter_assigned_to",
                                  option?.value
                                )
                              }
                              onBlur={() =>
                                formik.setFieldTouched("filter_assigned_to")
                              }
                              value={optionValues?.filter_assigned_to?.find(
                                option =>
                                  option.value ===
                                  formik.values.filter_assigned_to
                              )}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label className="form-label">Tested By</Label>
                            <Select
                              isClearable
                              styles={customStyles}
                              className="select2-selection"
                              placeholder="All"
                              name="filter_tested_by"
                              options={optionValues?.filter_tested_by}
                              onChange={option =>
                                formik.setFieldValue(
                                  "filter_tested_by",
                                  option?.value
                                )
                              }
                              onBlur={() =>
                                formik.setFieldTouched("filter_tested_by")
                              }
                              value={optionValues?.filter_tested_by?.find(
                                option =>
                                  option.value ===
                                  formik.values.filter_tested_by
                              )}
                            />
                          </div>
                        </Col>
                        <Col lg={6}></Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          {" "}
                          <div className="mb-3">
                            <Label className="form-label">
                              From Date(Test Case Created)
                            </Label>
                            <InputGroup>
                              <Flatpickr
                                className="form-control d-block "
                                placeholder="dd/mm/yyyy"
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "Y-m-d",
                                }}
                                value={
                                  formik.values.filter_test_case_created_from
                                }
                                onChange={date => {
                                  const formattedDate = moment(date[0]).format(
                                    "YYYY-MM-DD"
                                  )
                                  formik.setFieldValue(
                                    "filter_test_case_created_from",
                                    formattedDate
                                  )
                                }}
                              />
                            </InputGroup>
                            {formik.touched.filter_test_case_created_from &&
                              formik.errors.filter_test_case_created_from && (
                                <div className="error">
                                  {formik.errors.filter_test_case_created_from}
                                </div>
                              )}
                          </div>
                        </Col>
                        <Col lg={6}>
                          {" "}
                          <div className="mb-3">
                            <Label className="form-label">
                              To Date (Test Case Created)
                            </Label>
                            <InputGroup>
                              <Flatpickr
                                className="form-control d-block"
                                placeholder="dd/mm/yyyy"
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "Y-m-d",
                                }}
                                value={
                                  formik.values.filter_test_case_created_to
                                }
                                onChange={date => {
                                  const formattedDate = moment(date[0]).format(
                                    "YYYY-MM-DD"
                                  )
                                  formik.setFieldValue(
                                    "filter_test_case_created_to",
                                    formattedDate
                                  )
                                }}
                              />
                            </InputGroup>
                            {formik.touched.filter_test_case_created_to &&
                              formik.errors.filter_test_case_created_to && (
                                <div className="error">
                                  {formik.errors.filter_test_case_created_to}
                                </div>
                              )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          {" "}
                          <div className="mb-3">
                            <Label className="form-label">
                              From Date(Test Case Executed)
                            </Label>
                            <InputGroup>
                              <Flatpickr
                                className="form-control d-block "
                                placeholder="dd/mm/yyyy"
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "Y-m-d",
                                }}
                                value={
                                  formik.values.filter_test_case_execution_from
                                }
                                onChange={date => {
                                  const formattedDate = moment(date[0]).format(
                                    "YYYY-MM-DD"
                                  )
                                  formik.setFieldValue(
                                    "filter_test_case_execution_from",
                                    formattedDate
                                  )
                                }}
                              />
                            </InputGroup>
                            {formik.touched.filter_test_case_execution_from &&
                              formik.errors.filter_test_case_execution_from && (
                                <div className="error">
                                  {
                                    formik.errors
                                      .filter_test_case_execution_from
                                  }
                                </div>
                              )}
                          </div>
                        </Col>
                        <Col lg={6}>
                          {" "}
                          <div className="mb-3">
                            <Label className="form-label">
                              To Date (Test Case Executed)
                            </Label>
                            <InputGroup>
                              <Flatpickr
                                className="form-control d-block"
                                placeholder="dd/mm/yyyy"
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "Y-m-d",
                                }}
                                value={
                                  formik.values.filter_test_case_execution_to
                                }
                                onChange={date => {
                                  const formattedDate = moment(date[0]).format(
                                    "YYYY-MM-DD"
                                  )
                                  formik.setFieldValue(
                                    "filter_test_case_execution_to",
                                    formattedDate
                                  )
                                }}
                              />
                            </InputGroup>
                            {formik.touched.filter_test_case_execution_to &&
                              formik.errors.filter_test_case_execution_to && (
                                <div className="error">
                                  {formik.errors.filter_test_case_execution_to}
                                </div>
                              )}
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={5}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md={12}>
                          <div className="form-check form-checkbox-outline form-check-success">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="selectAllCheckbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="selectAllCheckbox"
                            >
                              <h5 className="TitleText">Extended Search</h5>
                            </label>
                          </div>
                        </Col>

                        {Object.keys(formik.values.selectedFields).map(
                          field => (
                            <Col md={6} key={field}>
                              <div className="form-check form-checkbox-outline form-check-success mb-2">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customCheck-outlinecolor2"
                                  name={field}
                                  checked={formik.values.selectedFields[field]}
                                  onChange={() =>
                                    formik.setFieldValue("selectedFields", {
                                      ...formik.values.selectedFields,
                                      [field]:
                                        !formik.values.selectedFields[field],
                                    })
                                  }
                                  disabled={field === "testCaseName"}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="customCheck-outlinecolor2"
                                >
                                  {field.charAt(0).toUpperCase() +
                                    field.slice(1).replace(/([A-Z])/g, " $1")}
                                </label>
                              </div>
                            </Col>
                          )
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      {/* Buttons Row */}
                      <div className="formRow buttons mb-3 mt-2">
                        <Row
                          className="d-flex justify-content-between"
                          style={{ gap: "2px" }}
                        >
                          <Col className="col-auto p-0">
                            <Button
                              type="submit"
                              id="GenerateReport"
                              color="success"
                              className="btn btn-success waves-effect waves-light"
                              onClick={handleGenerateReport}
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "12px",
                                marginRight: "2px",
                              }} // Small gap
                            >
                              Generate Report
                            </Button>
                            <UncontrolledTooltip
                              placement="top"
                              target="GenerateReport"
                            >
                              You can generate a test execution report by
                              applying various filters according to your
                              requirement.
                            </UncontrolledTooltip>
                          </Col>
                          <Col className="col-auto p-0">
                            <Button
                              type="submit"
                              id="SaveAsTemplate"
                              color="secondary"
                              className="btn btn-secondary waves-effect waves-light"
                              onClick={handleSaveAsTemplate}
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "12px",
                                marginRight: "2px",
                              }} // Small gap
                            >
                              Save As Template
                            </Button>
                            <UncontrolledTooltip
                              placement="top"
                              target="SaveAsTemplate"
                            >
                              You can save applied filters as a template for
                              later use.
                            </UncontrolledTooltip>
                          </Col>

                          <Col className="col-auto p-0">
                            <Link to="/Reports/TestExecution/SavedTemplates">
                              <Button
                                type="button"
                                id="SavedTemplate"
                                style={{
                                  whiteSpace: "nowrap",
                                  fontSize: "12px",
                                }}
                                color="secondary"
                                className="btn btn-secondary waves-effect waves-light"
                              >
                                Saved Template
                              </Button>
                            </Link>
                            <UncontrolledTooltip
                              placement="top"
                              target="SavedTemplate"
                            >
                              You can view the list of saved templates here.
                            </UncontrolledTooltip>
                          </Col>
                        </Row>
                      </div>

                      {/* Saved Templates Section */}
                      <div className="mb-3">
                        <h5 className="TitleText">Saved Templates</h5>
                        <Label
                          style={{
                            width: "100%",
                            marginTop: "5px",
                            marginRight: "10px",
                          }}
                        >
                          <Select
                            name="savedTemplate"
                            placeholder="Select Template"
                            isClearable
                            styles={customStyles}
                            className="select2-selection"
                            value={selectedTemplate} // Ensure this is updated correctly based on state
                            options={templateListOption}
                            onChange={handleSavedTemplateChange} // Pass the handler directly
                          />
                        </Label>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
            {isModalOpen && (
              <GenerateReport
                isModalOpen={isModalOpen}
                toggle={handleGenerateReport}
                resetFilter={resetFilter}
              />
            )}
            <SaveAsTemplate
              show={isSaveTemplate}
              handleClose={handleSaveAsTemplate}
              handleSave={SaveTemplate}
            />
          </Container>
        </>
      ) : (
        <NoPermission />
      )}
    </React.Fragment>
  )
}

export default TestCaseExecutionReport
