import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  globalDashboardDefectSeverity,
  globalDashboardDefectStatus,
} from "store/actions/GlobalDashboardAction"
import SeverityDefectsChart from "../Charts/SeverityDefectsChart"
import { Button, Card, CardBody, Spinner } from "reactstrap"
import DefectReportTable from "./DefectReportTable"
import DefectSummaryTable from "./DefectSummaryTable"
import DefectTable from "./DefectTable"
import DefectReported from "./DefectRepored"
import DefectReports from "../Charts/DefectReportedChart"
import Select  from "react-select"
import {
  GlobalDashboardDefectsPriority,
  GlobalDashboardDefectsSummary,
  GlobalDashboardDefectStatusAPI,
  GlobalDashboardDefectsType,
  GlobalDashboardDefSeverity,
} from "store/actions/DashboardAction"
import './Dashboard.scss';

const GlobalDashboardDetails = ({ back }) => {
  const [severityDefects, setSeverityDefects] = useState([])
  const [loadingSeverity, setLoadingSeverity] = useState(true)
  const [loadingStatus, setLoadingStatus] = useState(true)
  const [loadingReports, setLoadingReports] = useState(true)
  const [loadingDefectTable, setLoadingDefectTable] = useState(true)
  const [loadingSummary, setLoadingSummary] = useState(true)
  const [buttonLoading, setButtonLoading] = useState(false)
  const dispatch = useDispatch()

  const GlobalDashboardDefectsTypeData = useSelector(
    state => state?.Dashboard?.GlobalDashboardDefectsType?.data
  )

  // const GlobalDashboardDefectsSummaryData = useSelector(
  //   state => state?.Dashboard?.GlobalDashboardDefectsSummary?.data
  // )

  const GlobalDashboardDefectsPriorityData = useSelector(
    state => state?.Dashboard?.GlobalDashboardDefectsPriority?.data?.data
  )

  const GlobalDashboardDefectStatusAPIData = useSelector(
    state => state?.Dashboard?.GlobalDashboardDefectStatusAPI?.data?.data
  )

  const projectList = useSelector(state => state?.Project?.ProjectList?.data)

  useEffect(() => {
    const fetchData = async () => {
      setLoadingStatus(true)
      setLoadingSeverity(true)
      const bodyoptions = { project_id: "" }

      await dispatch(globalDashboardDefectStatus(bodyoptions))
      setLoadingStatus(false)

      const data1 = await dispatch(globalDashboardDefectSeverity(bodyoptions))
      setSeverityDefects(data1)
      setLoadingSeverity(false)
    }

    fetchData()
  }, [dispatch])

  useEffect(() => {
    // Simulate loading for each section's data
    const timeout = setTimeout(() => {
      setLoadingReports(false)
      setLoadingDefectTable(false)
      setLoadingSummary(false)
    }, 500) // Adjust timing as necessary

    return () => clearTimeout(timeout)
  }, [])

  const [selectedProjects, setSelectedProjects] = useState([])

  // Convert projectList to react-select's format
  const options = projectList.map(project => ({
    value: project.id,
    label: project.project_name,
  }))

  const handleChange = selectedOptions => {
    setSelectedProjects(selectedOptions) // Set the selected options
  }

  const onClickSearch = async () => {
    setButtonLoading(true)
    // Set loading states to true to show spinners
    setLoadingStatus(true)
    setLoadingSeverity(true)
    setLoadingReports(true)
    setLoadingDefectTable(true)
    setLoadingSummary(true)

    // Extract project IDs from selected projects
    const projId = selectedProjects?.map(item => item.value)

    const data = { project_id: projId }

    // Dispatch actions and wait for them to complete
    await dispatch(GlobalDashboardDefectsPriority(data))
    // await dispatch(GlobalDashboardDefectsSummary(data))
    await dispatch(GlobalDashboardDefectStatusAPI(data))
    await dispatch(GlobalDashboardDefectsType(data))
    const severityData = await dispatch(globalDashboardDefectSeverity(data))

    // Update state for severity defects
    setSeverityDefects(severityData)

    // After all the API calls have been completed, stop showing the spinners
    setLoadingStatus(false)
    setLoadingSeverity(false)
    setLoadingReports(false)
    setLoadingDefectTable(false)
    setLoadingSummary(false)
    setTimeout(() => {
      setButtonLoading(false) // Reset button loading state
    }, 1000)
  }

  const onClickBack = () => {
    back()
  }

  const layoutModeType = useSelector(state => state.Layout.layoutModeType)
  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
      width: 300, // Set fixed width for the dropdown
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888",
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    multiValue: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#3a3f51" : "#e0e0e0",
      maxWidth: "100%", // Ensure selected items stay within the container
    }),
    multiValueLabel: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    multiValueRemove: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
      ":hover": {
        backgroundColor: layoutModeType === "dark" ? "#ff4949" : "#e0e0e0",
        color: "#fff",
      },
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150,
      overflowY: "auto",
      zIndex: 1000,
    }),
    // Customize multiValueContainer to handle overflow when too many items are selected
    valueContainer: provided => ({
      ...provided,
      maxHeight: 50, // Set maximum height for the selected items container
      overflowY: "auto", // Add a scroll bar when the selected items overflow
    }),
  }

  const allTotalDefectsZero = severityDefects?.data?.every(
    project => project.total_defects === 0
  )

 

  return (
    <div>
      <Card>
        <CardBody className="p-2 d-flex justify-content-between flex-wrap align-items-center">
          <Button
            type="button"
            color="primary"
            className="btn-label "
            onClick={onClickBack}
          >
            <i className="bx bx-arrow-back label-icon"></i> Go Back
          </Button>

          <div
            className="d-flex justify-content-end gap-2 flex-grow-1 mt align-items-center"
            style={{ flexWrap: "wrap", justifyContent: "between" }}
          >
            <Select
              options={options}
              isMulti
              onChange={handleChange}
              value={selectedProjects}
              placeholder="Select projects"
              className="select2-selection"
              styles={customStyles} 
              closeMenuOnSelect={false} 
            />

            <Button
              type="button"
              color="primary"
              onClick={onClickSearch}
              disabled={buttonLoading}
              style={{height:"36px"}}

            >
              {buttonLoading ? "Searching..." : "Search"}
            </Button>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          {loadingReports ? (
            <div className="text-center mt-5">
              <Spinner color="primary" />
              <p className="mt-2">Loading Defect Reports...</p>
            </div>
          ) : (
            <div>
              <h4>Defects Reported</h4>
              {allTotalDefectsZero ? (
                <p>No data found</p>
              ) : (
                <DefectReports data={GlobalDashboardDefectsPriorityData} />
              )}
            </div>
          )}
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          {loadingSeverity ? (
            <div className="text-center mt-5">
              <Spinner color="primary" />
              <p className="mt-2">Loading Defects by Severity...</p>
            </div>
          ) : (
            <div>
              <h4>Defects Reported by Severity</h4>
              {GlobalDashboardDefectsPriorityData?.every(
                project => project.total_test_defects_priority === 0
              ) ? (
                <p>No data found</p>
              ) : (
                <SeverityDefectsChart severityDefects={severityDefects} />
              )}
            </div>
          )}
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          {loadingStatus ? (
            <div className="text-center mt-5">
              <Spinner color="primary" />
              <p className="mt-2">Loading Defect Status...</p>
            </div>
          ) : (
            <DefectReported data={GlobalDashboardDefectStatusAPIData} />
          )}
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          {loadingDefectTable ? (
            <div className="text-center mt-5">
              <Spinner color="primary" />
              <p className="mt-2">Loading Defect Report Table...</p>
            </div>
          ) : (
            <DefectReportTable defectData={GlobalDashboardDefectsTypeData} />
          )}
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          {loadingDefectTable ? (
            <div className="text-center mt-5">
              <Spinner color="primary" />
              <p className="mt-2">Loading Defect Table...</p>
            </div>
          ) : (
            <DefectTable data={GlobalDashboardDefectsPriorityData} />
          )}
        </CardBody>
      </Card>
      {/* <Card>
        <CardBody>
          {loadingSummary ? (
            <div className="text-center mt-5">
              <Spinner color="primary" />
              <p className="mt-2">Loading Summary Table...</p>
            </div>
          ) : (
            <DefectSummaryTable data={GlobalDashboardDefectsSummaryData} />
          )}
        </CardBody>
      </Card> */}
    </div>
  )
}

export default GlobalDashboardDetails
