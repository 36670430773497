import { createSlice } from "@reduxjs/toolkit"

const RolesSlice = createSlice({
  name: "Roles",
  initialState: {
    allRoles: "",

    singleRolesData: null,

    editRoles: {
      isEdit: false,
      data: null,
    },
  },

  reducers: {
    setAllRoles: (state, action) => ({
      ...state,
      allRoles: action.payload,
    }),
    setSingleRolesData: (state, action) => ({
      ...state,
      singleRolesData: action.payload,
    }),
    setEditRoles: (state, action) => ({
      ...state,
      editRoles: action.payload,
    }),
  },
})

export const { setAllRoles, setSingleRolesData, setEditRoles } =
  RolesSlice.actions

export default RolesSlice.reducer
