import { toast } from "react-toastify"
import httpInjectorService from "services/http-Injector.Service"
import { setLoading } from "store/Slices/LoadingSlice"
import { setAllModules, setEditModulesDetails, setModulesDetails, setNextModuleId } from "store/Slices/ModuleSlice"

export const fetchAllModulesList = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.getAllModulessAPI(queryString)
      dispatch(setAllModules(response?.data))
      dispatch(setLoading(false))
      return response
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const fetchModuleDetails = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.ProjectModuleDetails(
        queryString
      )
      const data = response
      dispatch(setModulesDetails(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}


export const deleteModuleAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.DeleteModule(bodyoption)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Module Deleted successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}


export const EditModuleAPI = (id ,bodyoption) => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null 
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.UpdateModule(id , formData)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Module updated successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }else{
        toast.warning(response.message, {
          position: "top-right", 
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }
      return response;
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const GenerateModuleId = id => {
  return async dispatch => {
    dispatch(setLoading(true)) 
    try {
      const response = await httpInjectorService.GenerateModuleId(id)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          autoClose: 3000,
        })
        dispatch(setNextModuleId(response))
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}


export const BulkDeleteModule = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (bodyoption[key] !== undefined && bodyoption[key] !== null) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.BulkDeleteModule(
        formData
      )
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Module Deleted successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const createModuleAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.CreateModule(formData)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Module created successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }else{
        toast.warning(response.message, {
          position: "top-right", 
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }
      return response;
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const setProjEditModuleData = data => { 
  return async (dispatch) => {
    dispatch( setEditModulesDetails(data))
  }
}