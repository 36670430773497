import React, { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

const PageNavigation = props => {
  const {
    handleTestCaseClick,
    handleTestPlanClick,
    activeButton,
  } = props
  const navigate = useNavigate()

  const location = useLocation()
  const locationPath = location.pathname
  const pathname = locationPath.split("/").filter(Boolean).pop()

  const handleTestPlan = () => {
    navigate("TestPlanRequest")
    handleTestPlanClick()
  }
  const handleTestCase = () => {
    navigate("/RequestPage")
    handleTestCaseClick()
  }


  useEffect(() => {
    if (pathname == "RequestPage") {
      handleTestCase()
    } else if (pathname == "TestPlanRequest") {
      handleTestPlan()
    }
  }, [pathname])
  return (
    <>
      <div className="">
        <div
          className="btn-group"
          role="group"
          style={{ whiteSpace: "nowrap" }}
        >
          <input
            type="radio"
            className="btn-check"
            id="btnradio1"
            name="btnradio"
            autoComplete="off"
            checked={activeButton === "TestCase"} // Sync with active state
          />
          <label
            className="btn btn-outline-primary"
            htmlFor="btnradio1"
            onClick={handleTestCase}
          >
            Test Case
          </label>

          <input
            type="radio"
            className="btn-check"
            id="btnradio2"
            name="btnradio"
            autoComplete="off"
            checked={activeButton === "TestPlan"} // Sync with active state
          />
          <label
            className="btn btn-outline-primary"
            htmlFor="btnradio2"
            onClick={handleTestPlan}
          >
            Test Plan
          </label>
        </div>
      </div>
    </>
  )
}

export default PageNavigation
