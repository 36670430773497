import { toast } from "react-toastify"
import httpInjectorService from "services/http-Injector.Service"
import {
  setAllBuilds,
  setBuildDetails,
  setEditBuildDetails,
  setNextBuildId,
} from "store/Slices/BuildSlice"
import { setLoading } from "store/Slices/LoadingSlice"

export const fetchAllBuildsList = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.getAllBuildsAPI(queryString)
      const data = response
      dispatch(setAllBuilds(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const fetchBuildDetails = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.ProjectBuildDetails(
        queryString
      )
      const data = response
      dispatch(setBuildDetails(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const deleteBuildAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.DeleteBuild(bodyoption)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Module Deleted successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const EditBuildAPI = (id, bodyoption) => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.UpdateBuild(id, formData)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      } else {
        dispatch(setLoading(false))
        toast.warning(response.message, {
          position: "top-right",
          autoClose: 3000,
        })
      }
      return response
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const GenerateBuildId = id => {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.GenerateBuildId(id)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          autoClose: 3000,
        })
        dispatch(setNextBuildId(response))
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const BulkDeleteBuild = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (bodyoption[key] !== undefined && bodyoption[key] !== null) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.BulkDeleteBuild(formData)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Module Deleted successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const createBuildAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.CreateBuild(formData)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      } else {
        dispatch(setLoading(false))
        toast.warning(response.message, {
          position: "top-right",
          autoClose: 3000,
        })
      }
      return response
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const setEditBuildData = data => {
  return async dispatch => {
    dispatch(setEditBuildDetails(data))
  }
}
