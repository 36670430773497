import { toast } from "react-toastify"
import httpInjectorService from "services/http-Injector.Service"
import { setAllActionScriptList, setEditAutomationScript, setNextAutomationScriptId, setSingleAutomationScript } from "store/Slices/AutomationScriptSlice"
import { setLoading } from "store/Slices/LoadingSlice"



export const AutomationScriptDetails = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      let queryString = "?"
      Object.entries(bodyoption).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (queryString.charAt(queryString.length - 1) === "&") {
        queryString = queryString.slice(0, -1)
      }
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.AutomationScriptDetails(
          queryString
        )
        const data = response
        dispatch(setAllActionScriptList(data))
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }


export const DeleteAutomationScript = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.DeleteAutomationScript(bodyoption)
        if (response?.status) {
          toast.success(response.message, {
            position: "top-right",
            id: "Module Deleted successfully",
            autoClose: 3000,
          })
          dispatch(setLoading(false))
        }
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }
  
  
  export const UpdateAutomationScript = (id, bodyoption) => {
    return async (dispatch) => {
      dispatch(setLoading(true));
      const formData = new FormData();
  
      for (const key in bodyoption) {
        if (
          bodyoption[key] !== undefined &&
          bodyoption[key] !== null  
        ) {
          formData.append(key, bodyoption[key]);
        }
      }
  
      // Ensure that file is handled properly
      if (bodyoption.file && typeof bodyoption.file === "string") {
        const scriptFile = new Blob([bodyoption.file], { type: "text/plain" });
        formData.set("file", scriptFile);
      }
  
      try {
        const response = await httpInjectorService.UpdateAutomationScript(id, formData);
        if (response?.status) {
          toast.success(response.message, {
            position: "top-right",
            autoClose: 3000,
          });
          dispatch(setLoading(false));
        } else {
          toast.warning(response.message, {
            position: "top-right",
            autoClose: 3000,
          });
        }
        return response;
      } catch (error) {
        dispatch(setLoading(false));
        throw new Error(error.message);
      }
    };
  };
  
  
  export const GenerateAutomationScriptId = id => {
    return async dispatch => {
      dispatch(setLoading(true)) 
      try {
        const response = await httpInjectorService.GenerateAutomationScriptId(id)
        if (response?.status) {
          toast.success(response.message, {
            position: "top-right",
            autoClose: 3000,
          })
          dispatch(setNextAutomationScriptId(response))
          dispatch(setLoading(false))
        }
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }
  
  
  export const BulkDeleteAutomationScript = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      const formData = new FormData()
      for (const key in bodyoption) {
        if (bodyoption[key] !== undefined && bodyoption[key] !== null) {
          formData.append(key, bodyoption[key])
        }
      }
      try {
        const response = await httpInjectorService.BulkDeleteAutomationScript(
          formData
        )
        if (response?.status) {
          toast.success(response.message, {
            position: "top-right",
            id: "Module Deleted successfully",
            autoClose: 3000,
          })
          dispatch(setLoading(false))
        }
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }
  
  export const CreateAutomationScrit = (bodyoption) => {
    return async (dispatch) => {
      dispatch(setLoading(true));
      const formData = new FormData();
  
      // Add form data if not null
      for (const key in bodyoption) {
        if (
          bodyoption[key] !== undefined &&
          bodyoption[key] !== null &&
          bodyoption[key] !== ""
        ) {
          formData.append(key, bodyoption[key]);
        }
      }
  
      if (bodyoption.file && typeof bodyoption.file === "string") {
        const scriptFile = new Blob([bodyoption.file], { type: "text/plain" });
        formData.set("file", scriptFile);
      }
  
      try {
        const response = await httpInjectorService.CreateAutomationScript(formData);
  
        if (response?.status) {
          toast.success(response.message, {
            position: "top-right",
            autoClose: 3000,
          });
        } else {
          // Display error message when status is false
          const errorMessage = response.message || response.error || "An error occurred.";
          toast.warning(errorMessage, {
            position: "top-right",
            autoClose: 3000,
          });
        }
  
        dispatch(setLoading(false));
        return response;
      } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.message, {
          position: "top-right",
          autoClose: 3000,
        });
        throw new Error(error.message);
      }
    };
  };
  

  export const GetAutomationScriptView = id => {
    return async dispatch => {
      dispatch(setLoading(true))
  
      try {
        const response = await httpInjectorService.GetAutomationScriptView(id)
        const data = response
        dispatch(setSingleAutomationScript(data))
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }
  
  
  export const setEditAutomationScriptData = data => { 
    return async (dispatch) => {
      dispatch( setEditAutomationScript(data))
    }
  }

  
export const WebActionDetails = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      let queryString = "?"
      Object.entries(bodyoption).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (queryString.charAt(queryString.length - 1) === "&") {
        queryString = queryString.slice(0, -1)
      }
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.WebActionDetails(
          queryString
        )
        const data = response
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }


  
export const DbConnectionDetails = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      let queryString = "?"
      Object.entries(bodyoption).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (queryString.charAt(queryString.length - 1) === "&") {
        queryString = queryString.slice(0, -1)
      }
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.DbConnectionDetails(
          queryString
        )
        const data = response
        dispatch(setAllActionScriptList(data))
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }

  export const DbConnectionAutoscript = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      const formData = new FormData()
      // Add form data if not null
      for (const key in bodyoption) {
        if (
          bodyoption[key] !== undefined &&
          bodyoption[key] !== null &&
          bodyoption[key] !== ""
        ) {
          formData.append(key, bodyoption[key])
        }
      }
      try {
        const response = await httpInjectorService.DbConnectionAutoscript(formData)
        if (response?.status) {
          toast.success(response.message, {
            position: "top-right",
            autoClose: 3000,
          })
          dispatch(setLoading(false))
        }else{
          toast.warning(response.message, {
            position: "top-right", 
            autoClose: 3000,
          })
        }
        return response;
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }