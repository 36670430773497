import React, { useState, useMemo, useEffect } from "react"
import { useSelector } from "react-redux"
import {
  Input,
  Table,
  Container,
  InputGroup,
  InputGroupText,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Modal,
  UncontrolledTooltip,
} from "reactstrap"
import classnames from "classnames"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import { teamDetails, fetchTeamsAvailable } from "store/actions/ProjectActions"
import { Link, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import httpInjectorService from "services/http-Injector.Service"
import { toast } from "react-toastify"
import TableContainer from "components/Common/TableContainer"
import DeleteModal2 from "components/Common/DeleteModal2"
import { Search } from "ace-builds/src-noconflict/ace"

const AddUser = () => {
  const dispatch = useDispatch()
  const userData = useSelector(state => state.Project.teamsUserList?.Data)
  const teamsAvailableList = useSelector(
    state => state?.Project?.teamsAllUserList?.Data
  )
  const [verticalActiveTab, setVerticalActiveTab] = useState("1")
  const [searchQuery, setSearchQuery] = useState("")
  const [searchAddQuery, setSearchAddQuery] = useState("")
  const [deleteModal, setDeleteModal] = useState(false)
  const [globalFilter, setGlobalFilter] = useState("")
  const [id, setId] = useState(null)
  const [pageIndex, setPageIndex] = useState(0)
  const [TeamDetPageIndex, setTeamDetPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [TeampageSize, setTeamPageSize] = useState(10)
  const [totalCount, setTotalCount] = useState(null)
  const [totalCountUsers, setTotalCountUsers] = useState(null)
  const location = useLocation()
  const segments = location.pathname.split("/")
  const projId = segments[segments.length - 1]

  useEffect(() => {
    fetchTeamDetails(TeamDetPageIndex, TeampageSize, searchQuery)
    fetchTeamsAvailabel(pageIndex, pageSize, searchAddQuery)
  }, [
    pageIndex,
    pageSize,
    TeampageSize,
    TeamDetPageIndex,
    searchAddQuery,
    searchQuery,
  ])

  const fetchTeamDetails = async (page, size, searchQuery) => {
    let bodyoption = {
      page: page + 1,
      page_size: size,
      project_id: projId,
      search: searchQuery,
    }
    try {
      const data = await dispatch(teamDetails(bodyoption))
      setTotalCount(data.TotalCount)
      console.log(data)
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  const fetchTeamsAvailabel = async (page, size, searchAddQuery) => {
    let bodyoption = {
      page: page + 1,
      page_size: size,
      project_id: projId,
      search: searchAddQuery,
    }
    try {
      const data = await dispatch(fetchTeamsAvailable(bodyoption))
      setTotalCountUsers(data.TotalCount)
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  const breadcrumbItems = [
    { label: "Projects", path: "/ProjectManagement" },
    { label: "Add User", path: "/requirements" },
  ]

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setVerticalActiveTab(tab)
    }
  }

  const handleSearchChange = e => {
    setSearchQuery(e.target.value)
  }

  const handleSearchAddMember = e => {
    setSearchAddQuery(e.target.value)
  }

  const handleDeleteuser = () => {
    if (id) {
      deleteuser(id)
      setDeleteModal(false)
    }
  }

  const deleteuser = async id => {
    const formData = new FormData()
    formData.append("project_id", projId)
    try {
      const response = await httpInjectorService.userDelete(id, formData)
      console.log("response", response)
      if (response.status) {
        fetchTeamDetails(TeamDetPageIndex, TeampageSize, searchQuery)
        const errorMessage =
          typeof response.message === "string"
            ? response.message
            : response.message.join(", ")
        toast.success(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
        localStorage.removeItem("selectedProject")
        window.location.reload()
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
      }
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      })
    }
  }

  const onClickDelete = userId => {
    console.log("userId", userId)
    if (
      userId.role_name === "Super Admin" ||
      userId.role_name === "super admin"
    ) {
      toast.warn("You don't have permission to delete the Super Admin.", {
        position: "top-right",
        id: "bulk delete test",
        autoClose: 3000,
      })
    } else {
      setDeleteModal(true)
      setId(userId.id)
    }
  }

  const onAddUser = async id => {
    const formData = new FormData()
    formData.append("project_id", projId)
    formData.append("user_team_id", id)
    try {
      const response = await httpInjectorService.addUser(formData)
      if (response) {
        toast.success(response.message, {
          position: "top-right",
          id: "bulk delete test",
          autoClose: 3000,
        })
        fetchTeamDetails(TeamDetPageIndex, TeampageSize, searchQuery)
        fetchTeamsAvailabel(pageIndex, pageSize, searchAddQuery)
      } else {
        toast.warn(response, {
          position: "top-right",
          id: "bulk delete test",
          autoClose: 3000,
        })
      }
    } catch (error) {
      toast.warn(error?.message || "An error occurred. Please try again.", {
        position: "top-right",
        id: "bulk delete test",
        autoClose: 3000,
      })
    }
  }

  const userList = useMemo(
    () => [
      {
        header: "",
        accessorKey: "checkbox",
        cell: ({ row }) => (
          <i className="mdi mdi-account-circle text-primary h1" />
        ),
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "  ",
        accessorKey: "user_name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {cell.getValue()}
                </Link>
              </h5>
              <p className="text-muted mb-0">{cell.row.original.email}</p>
            </>
          )
        },
      },
      {
        header: " ",
        accessorKey: "role_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "",
        accessorKey: " ",
        cell: ({ cell }) => (
          <button
            type="button"
            className="btn btn-soft-success waves-effect waves-light btn btn-secondary"
            onClick={() => onAddUser(cell.row.original.id)}
          >
            ADD
          </button>
        ),
        enableColumnFilter: false,
        enableSorting: false,
      },
    ],
    [userData]
  )

  const columns = useMemo(
    () => [
      {
        header: "",
        accessorKey: "user",
        cell: ({ row }) => (
          <i className="mdi mdi-account-circle text-primary h1" />
        ),
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "User",
        accessorKey: "user_name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {cell.getValue()}
                </Link>
              </h5>
              <p className="text-muted mb-0">{cell.row.original.email}</p>
            </>
          )
        },
      },
      {
        header: "Role",
        accessorKey: "role_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          const userId = row.original

          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => onClickDelete(userId)}
                  id={`deletetooltip-${userId.id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`deletetooltip-${userId.id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    [userData]
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Projects" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col lg="12"></Col>
            <Card>
              <CardBody>
                <Row>
                  <Col md="3" style={{ borderRight: "1px solid lightgray" }}>
                    <Nav pills className="flex-column">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            "mb-2": true,
                            active: verticalActiveTab === "1",
                          })}
                          onClick={() => {
                            toggleVertical("1")
                          }}
                        >
                          Add Team Members
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            "mb-2": true,
                            active: verticalActiveTab === "2",
                          })}
                          onClick={() => {
                            toggleVertical("2")
                          }}
                        >
                          All Team Members
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                  <Col md="9">
                    <TabContent
                      activeTab={verticalActiveTab}
                      className="mt-4 mt-md-0"
                    >
                      <TabPane tabId="1">
                        <div className="d-flex justify-content-between ">
                          <div>
                            <h4 className="mt-2">Add Team Member</h4>
                          </div>
                          <div>
                            <div>
                              <InputGroup
                                className=""
                                style={{ height: "38px" }}
                              >
                                <InputGroupText style={{ height: "100%" }}>
                                  <i className="bx bx-search-alt search-icon"></i>
                                </InputGroupText>
                                <Input
                                  type="search"
                                  placeholder="Search..."
                                  aria-label="Search"
                                  value={searchAddQuery}
                                  onChange={handleSearchAddMember}
                                />
                              </InputGroup>
                            </div>
                          </div>
                        </div>
                        <TableContainer
                          columns={userList}
                          data={teamsAvailableList || []}
                          isCustomPageSize={true}
                          pageIndex={pageIndex}
                          pageSize={pageSize}
                          totalCount={totalCountUsers}
                          setPageIndex={setPageIndex}
                          setPageSize={setPageSize}
                          setGlobalFilter={setGlobalFilter}
                          isGlobalFilter={false}
                          isJobListGlobalFilter={false}
                          isPagination={true}
                          isAddDelete={false}
                          isAddEdit={false}
                          isAddFilter={false}
                          SearchPlaceholder="Search..."
                          tableClass="align-middle "
                          pagination="pagination"
                          paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                        />
                      </TabPane>
                      <TabPane tabId="2">
                        <div className="d-flex justify-content-between ">
                          <div>
                            <h4 className="mt-2">All Team Members</h4>
                          </div>
                          <div>
                            <div>
                              <InputGroup
                                className=""
                                style={{ height: "38px" }}
                              >
                                <InputGroupText style={{ height: "100%" }}>
                                  <i className="bx bx-search-alt search-icon"></i>
                                </InputGroupText>
                                <Input
                                  type="search"
                                  placeholder="Search..."
                                  aria-label="Search"
                                  value={searchQuery}
                                  onChange={handleSearchChange}
                                />
                              </InputGroup>
                            </div>
                          </div>
                        </div>
                        <div className="table-responsive mt-2">
                          <TableContainer
                            columns={columns}
                            data={userData || []}
                            pageIndex={TeamDetPageIndex}
                            pageSize={TeampageSize}
                            totalCount={totalCount}
                            setPageIndex={setTeamDetPageIndex}
                            setPageSize={setTeamPageSize}
                            setGlobalFilter={setGlobalFilter}
                            isCustomPageSize={true}
                            isGlobalFilter={false}
                            isJobListGlobalFilter={false}
                            isPagination={true}
                            isAddDelete={false}
                            isAddEdit={false}
                            isAddFilter={false}
                            SearchPlaceholder="Search..."
                            tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline mt-4 border-top"
                            pagination="pagination"
                            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                          />
                        </div>
                      </TabPane>
                    </TabContent>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>

        <DeleteModal2
          show={deleteModal}
          onDeleteClick={handleDeleteuser}
          onCloseClick={() => setDeleteModal(false)}
          message={"User"}
        />
      </div>
    </React.Fragment>
  )
}

export default AddUser
