import { createSlice } from "@reduxjs/toolkit"

const TestCaseSlice = createSlice({
  name: "TestCase",
  initialState: {
    testCaseList: [],
    testCaseChart: [],
    preConditonList: [],
    singleTestCaseData: null,
    auditTestCaseData: null,
    nextTestCaseId: null,
    testCaseFilterFrom: {
      filter_build: null,
      filter_requirement: null,
      filter_module: null,
      filter_test_scenario_id: null,
      filter_test_status: null,
      filter_testing_type: null,
      filter_execution_type: null,
      filter_created_by: null,
      filter_assign_to: null,
      filter_approval_from: null,
    },
    editTestCase: {
      isEdit: false,
      data: null,
    },
    editPreCondition: {
      isEdit: false,
      data: null,
    },
  },

  reducers: {
    setTestCaseList: (state, action) => ({
      ...state,
      testCaseList: action.payload,
    }),
    setTestCaseChart: (state, action) => ({
      ...state,
      testCaseChart: action.payload,
    }),
    setSingleTestCaseData: (state, action) => ({
      ...state,
      singleTestCaseData: action.payload,
    }),
    setAuditTestCaseData: (state, action) => ({
      ...state,
      auditTestCaseData: action.payload,
    }),
    setNextTestCaseId: (state, action) => ({
      ...state,
      nextTestCaseId: action.payload,
    }),
    setEditTestCase: (state, action) => ({
      ...state,
      editTestCase: action.payload,
    }),
    setEditPreCondition: (state, action) => ({
      ...state,
      editPreCondition: action.payload,
    }),
    setPreConditionList: (state, action) => ({
      ...state,
      preConditonList: action.payload,
    }),
    setTestCaseFilterFrom: (state, action) => {
      state.testCaseFilterFrom = {
        ...state.testCaseFilterFrom,
        ...action.payload,
      }
    },
    clearFilters: state => {
      state.testCaseFilterFrom = {
        filter_build: null,
        filter_requirement: null,
        filter_module: null,
        filter_test_scenario_id: null,
        filter_test_status: null,
        filter_testing_type: null,
        filter_execution_type: null,
        filter_created_by: null,
        filter_assign_to: null,
        filter_approval_from: null,
      }
    },
  },
})

export const {
  setTestCaseList,
  setSingleTestCaseData,
  setNextTestCaseId,
  setTestCaseFilterFrom,
  clearFilters,setTestCaseChart,setEditTestCase,setPreConditionList,setEditPreCondition,setAuditTestCaseData
} = TestCaseSlice.actions

export default TestCaseSlice.reducer
