import React from "react"
import { Table } from "reactstrap"
import { FaFilePdf } from "react-icons/fa"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { toast } from "react-toastify"

const DefectReportTable = ({ defectData }) => {
  if (!defectData || defectData.length === 0) {
    return <div>No data available</div>
  }

  // Extract headers dynamically based on the first project item
  const firstProject = defectData[0]
  const defectHeaders = Object.keys(firstProject.Defect)
  const headers = ["Project Name", "Total Test Defects", ...defectHeaders]

  const handleDownloadPDF = () => {
    const doc = new jsPDF()

    // Define the header for the table
    const tableColumn = ["Key", "Value"]
    const tableRows = []

    // Iterate over defectData directly
    defectData.forEach((item, index) => {
      if (index > 0) {
        // Add an empty row for spacing
        tableRows.push(["", ""])
        tableRows.push(["--------------------", ""]) // Separator line
        tableRows.push(["Next Project will start", ""]) // Separator text
        tableRows.push(["--------------------", ""]) // Separator line
        tableRows.push(["", ""]) // Add another empty row for spacing
      }

      // Add project-level information
      tableRows.push(["Project ID", item.project_id])
      tableRows.push(["Project Name", item.project_name])
      tableRows.push(["Total Test Defects", item.total_test_defects])

      // Add defect types
      Object.keys(item.Defect).forEach(key => {
        tableRows.push([key, item.Defect[key]])
      })
    })

    // Generate the PDF table
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: {
        fontSize: 10,
        cellPadding: 3,
      },
      headStyles: {
        fillColor: [0, 0, 128],
        textColor: [255, 255, 255],
      },
      footStyles: {
        fillColor: [0, 0, 128],
        textColor: [255, 255, 255],
      },
      theme: "grid",
      showHead: "everyPage",
      showFoot: "lastPage",
      didParseCell: function (data) {
        if (data.cell.raw === "--------------------") {
          data.cell.styles.fillColor = [211, 211, 211] // Light gray color for separator
        } else if (data.cell.raw === "Next Project will start") {
          data.cell.styles.fontStyle = "bold"
          data.cell.styles.textColor = [255, 0, 0] // Red color for the separator text
        }
      },
    })

    doc.save("Defect Reported By Type.pdf")
  }
  const projectListData = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission = projectListData?.permissions?.[0]?.menu_item?.Dashboard
  return (
    <div>
      <div className="d-flex justify-content-between mb-2">
        <h4 className="Defect-Title">Defect Reported By Type</h4>
        <div
          onClick={() => {
            if (rolePermission?.GlobalDashboard?.download) {
              handleDownloadPDF()
            } else {
              toast.warn("You Don't Have Permission To Download", {
                position: "top-right",
                autoClose: 3000,
              })
            }
          }}
          style={{ cursor: "pointer" }}
        >
          <FaFilePdf
            className="export-icon pdf hide-content"
            style={{
              color: "#D13438",
              height: "1.5rem",
              width: "1.5rem",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
      {/* Adding scrollable container */}
      <div style={{ overflow: "auto", maxHeight: "250px" }}>
        <Table hover striped responsive className="text-center">
          <thead
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "#ffffff",
              zIndex: 1,
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <tr>
              {headers.map(header => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {defectData.map(project => (
              <tr key={project.project_id}>
                <td>{project.project_name}</td>
                <td>{project.total_test_defects}</td>
                {defectHeaders.map(header => (
                  <td key={header}>{project.Defect[header] || 0}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default DefectReportTable
