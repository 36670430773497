 
import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import Highcharts3D from "highcharts/highcharts-3d";
import { useSelector } from "react-redux";

// Initialize 3D module
Highcharts3D(Highcharts);

const PieChart3D = ({ data, labels }) => {
  const chartRef = useRef(null);
  const layoutModeType = useSelector((state) => state.Layout.layoutModeType);

  useEffect(() => {
    if (!data || !labels) {
      return;
    }

    // Dynamic data from props
    let chartData = data.map((value, index) => ({
      name: labels[index],
      y: parseFloat(value),
      color: Highcharts.getOptions().colors[index % Highcharts.getOptions().colors.length],
    }));

    // Filter out data points with 0 value
    chartData = chartData.filter(point => point.y > 0);

    // Dark mode settings
    const isDarkMode = layoutModeType === "dark";

    // Create the 3D Pie Chart
    chartRef.current = Highcharts.chart("pie-chart-3d", {
      chart: {
        type: "pie",
        backgroundColor: isDarkMode ? "#2a3042" : "white",
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0,
        },
      },
      title: {
        text: " ",
        align: "left",
        style: {
          fontSize: "20px",
          fontWeight: "bold",
          color: isDarkMode ? "#ffffff" : "#000000", // Dynamic title color
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormat: "<b>{point.percentage:.0f}%</b>",
        backgroundColor: isDarkMode ? "#2a3042" : "white",
        style: {
          color: isDarkMode ? "#ffffff" : "#000000", // Dynamic tooltip text color
        },
      },
      plotOptions: {
        pie: {
          innerSize: "0%", // Full pie chart
          depth: 45, // Set depth for 3D effect
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "{point.percentage:.0f} %",
            style: {
              color: isDarkMode ? "#ffffff" : "black",
              textOutline: isDarkMode ? "none" : "1px white", // Text outline for better readability
            },
          },
          showInLegend: true,
        },
      },
      legend: {
        itemStyle: {
          color: isDarkMode ? "#ffffff" : "#000000", // Dynamic legend text color
        },
      },
      series: [
        {
          name: "Defects",
          colorByPoint: true,
          data: chartData,
        },
      ],
    });

    return () => {
      // Cleanup chart instance on component unmount
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [data, labels, layoutModeType]); // Dependencies

  return (
    <div
      id="pie-chart-3d"
      style={{
        height: "25rem",
        borderRadius: "10px",
      }}
    ></div>
  );
};

export default PieChart3D;
