import React, { useEffect, useState } from "react";
import AceEditor from "react-ace";
import beautify from "js-beautify";
import styles from "./editor.module.scss";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import { useDispatch } from "react-redux";
 
let defaultCode = {
  defaultcode: `
const Util = require('../config/dataHandler.js');

describe('<enter scenario name>', () => {

    it('<Enter test case details>', async () => {

        //Please paste the recorded script over here

    });
});
`,
};

function Editor1(props) {
  const dispatch = useDispatch();
  const { editorCode } = props;
 console.log(props)
  const [code, setCode] = useState(editorCode || defaultCode?.defaultcode);

  const handleFormatCode = () => {
    const formattedCode = beautify(code, {
      indent_size: 2,
      space_in_empty_paren: true,
      end_with_newline: true,
      indent_inner_html: true,
      preserve_newlines: true,  
      jslint_happy: true,  
      wrap_line_length: 0,
    });
    setCode(formattedCode);
  }; 
  return (
    <div className={styles.EditorContainer}>
      <AceEditor
        mode="javascript"
        theme="monokai"
        onChange={setCode}
        value={code}
        name="editor"
        width="100%"
        height="100%"
        editorProps={{ $blockScrolling: true }}
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
          useWorker: false,
          printMargin: false,
        }}
      /> 
      <div className={styles.EditorActionButtons}>
        <div className={styles.Formate} onClick={handleFormatCode}>
          Formate
        </div>
      </div>
    </div>
  );
}

export default Editor1;
