import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
} from "reactstrap"
import {
  // fetchProjectDetails1,
  fetchProjectForSelectProject,
} from "store/actions"
import { setSelectetProject } from "store/Slices/GlobalSlice"

const GlobalSelectProject = ({}) => {
  const layoutModeType = useSelector(state => state.Layout.layoutModeType)
  const dispatch = useDispatch()
  const [menu, setMenu] = useState(false)
  const [searchQuery, setSearchQuery] = useState("") // State for search query
  const location = useLocation()
  const locationPath = location.pathname
  const pathnameSegments = locationPath.split("/").filter(Boolean)
  const pathArray = [
    // Array of valid path segments
    "ProjectManagement",
    "CreateTestScenario",
    "EditTestScenario",
    "ViewTestScenario",
    "CreateTestData",
    "EditTestData",
    "ViewTestData",
    "CreateTestPlan",
    "ViewTestPlan",
    "EditTestPlan",
    "CreateEditDefect",
    "EditDefect",
    "ViewDefect",
    "CreateEditRoles",
    "Roles",
    "Users",
    "Integration",
    "Tags",
    "EditTag",
    "CreateEditUsers",
    "CreateScheduleReport",
    "CreateDbIntegration",
    "CreateTag",
    "CreateIntegration",
    "EditIntegration",
    "SavedTemplates",
    "CreateEditTestCase",
    "ViewTestCase",
    "CreateEditPreCondition",
    "CreateEditTestCycle",
    "ViewTestCycleAutomation",
    "ViewTestCycleManual",
    "CreateProject",
    "CreateAgent",
    "Create-Build",
    "Create-Module",
    "New-Requirement",
    "profile",
    "CreateTestCase",
    "CreateDefect",
    "CreateUsers",
    "ExecuteTestSuite",
    "ExecuteTestCase",
    "TestPlanSummary",
    "dashboard",
  ]

  const isPathInArray = pathnameSegments.some(segment =>
    pathArray.includes(segment)
  )

  const globalProject = useSelector(
    state => state.globalProjectSelect.selectetProject
  )

  const projectList = useSelector(
    state => state.globalProjectSelect.projectListForSelect
  )

  const projectName = Array.isArray(projectList)
    ? projectList
        .map(item => ({
          value: item.id,
          label: item.project_name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    : []

  const filteredProjects = projectName.filter(project =>
    project.label.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const handleOptionClick = item => {
    if (globalProject?.label !== item.label) {
      dispatch(setSelectetProject(item))
      localStorage.setItem("selectedProject", JSON.stringify(item))
      window.location.reload()
    }
  }

  useEffect(() => {
    const selectedProject = localStorage.getItem("selectedProject")
    if (selectedProject) {
      const parsedSelectedProject = JSON.parse(selectedProject)
      if (parsedSelectedProject.label !== globalProject?.label) {
        dispatch(setSelectetProject(parsedSelectedProject))
      }
    } else if (projectName.length > 0) {
      const firstProject = projectName[0]
      if (firstProject.label !== globalProject?.label) {
        dispatch(setSelectetProject(firstProject))
        localStorage.setItem("selectedProject", JSON.stringify(firstProject))
      }
    }
  }, [projectName, globalProject?.label, dispatch])

  useEffect(() => {
    dispatch(fetchProjectForSelectProject())
  }, [dispatch])

  const dropdownStyle =
    layoutModeType === "dark"
      ? {
          backgroundColor: "#333", // Dark mode background
          color: "#fff", // Dark mode text color
          borderColor: "#555", // Dark mode border
        }
      : {
          backgroundColor: "#fff", // Light mode background
          color: "#000", // Light mode text color
          borderColor: "#ddd", // Light mode border
        }

  const dropdownItemStyle = item => ({
    backgroundColor:
      globalProject?.label === item.label
        ? "rgba(47, 72, 148, 1)"
        : layoutModeType === "dark"
        ? "#333"
        : "#fff",
    color:
      globalProject?.label === item.label
        ? "#fff"
        : layoutModeType === "dark"
        ? "#fff"
        : "black",
  })

  return (
    <>
      {!isPathInArray && (
        <Dropdown
          className="d-flex align-items-center"
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          id="project"
        >
          <DropdownToggle
            style={{
              backgroundColor: "transparent",
              color: "inherit",
            }}
            className="d-flex align-items-center"
          >
            <span className="d-inline-block ms-2 me-1 global-selector">
              {filteredProjects.length == 0
                ? "Select Project"
                : globalProject?.label || "Select Project"}
            </span>
            {/* <UncontrolledTooltip placement="top" target="project">
              {globalProject?.label ? globalProject?.label : "Select Project"}
            </UncontrolledTooltip> */}
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>

          <DropdownMenu
            style={{
              ...dropdownStyle,
              maxHeight: "300px",
              width: "250px",
              padding: 0,
            }}
          >
            <div className="p-2" style={{ borderBottom: "1px solid #ddd" }}>
              <input
                type="text"
                placeholder="Search..."
                className="form-control"
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
              />
            </div>
            <div style={{ maxHeight: "250px", overflowY: "auto" }}>
              {filteredProjects.length > 0 ? (
                filteredProjects.map(item => (
                  <DropdownItem
                    key={item.value}
                    onClick={() => handleOptionClick(item)}
                    style={dropdownItemStyle(item)}
                  >
                    {item.label}
                  </DropdownItem>
                ))
              ) : (
                <DropdownItem disabled>No projects found</DropdownItem>
              )}
            </div>
          </DropdownMenu>
        </Dropdown>
      )}
    </>
  )
}

export default GlobalSelectProject
