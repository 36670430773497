import React, { useEffect, useMemo, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Container,
  Form,
  InputGroup,
  UncontrolledTooltip,
} from "reactstrap"
import { useFormik } from "formik"
import "flatpickr/dist/themes/material_blue.css"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import { createOptionsForReports } from "../../../helpers/helper"
import { fetchAllBuildsList } from "store/actions/BuildsActions"
import { fetchAllModulesList } from "store/actions/ModuleActions"
import { fetchAllRequirementsList } from "store/actions/RequirementActions"
import { fetchAllTeamsDetailsUserAssigneeList } from "store/actions/UserActions"
import { getTagsStautusAPI } from "store/actions/TagsAction"
import Flatpickr from "react-flatpickr"
import { Link } from "react-router-dom"
import { setSelectedTestCaseFormValues } from "store/Slices/ReportsSlice"
import GenerateReport from "./GenerateReport"
import SaveAsTemplate from "../common/SaveAsTemplate"
import { reports } from "../helper/config"
import {
  fetchAllReportsTemplateAPI,
  GetSingleReportTemplateAPI,
  saveAsTemplateAPI,
} from "store/actions/ReportsAction"
import * as Yup from "yup"
import moment from "moment"
import NoPermission from "components/Common/NoPermission"

const TestCaseReport = () => {
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))
  const dispatch = useDispatch()
  const [selectedTemplate, setSelectedTemplate] = useState("")
  const [selectedTemplateOption, setselectedTemplateOption] = useState(null)
  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const validationSchema = Yup.object({
    fromDate: Yup.date()
      .nullable()
      .test(
        "fromDate-test",
        "From Date is required when To Date is selected",
        function (value) {
          const { toDate } = this.parent // Access the value of toDate
          if (toDate && !value) {
            return false // From Date is required if To Date is selected
          }
          return true // If To Date is not selected, allow fromDate to be null
        }
      ),

    toDate: Yup.date()
      .nullable()
      .test("toDate-test", "To Date must be after From Date", function (value) {
        const { fromDate } = this.parent // Access the value of fromDate
        if (fromDate && value) {
          return new Date(value) > new Date(fromDate) // Validate that toDate is after fromDate
        }
        return true // If fromDate is not selected, allow toDate to be null
      }),
  })
  const formik = useFormik({
    initialValues: {
      build: null,
      module: null,
      TestCaseStatus: null,
      TestCaseType: null,
      requirement: null,
      assignedTo: null,
      fromDate: "",
      toDate: "",
      selectedFields: {
        testCaseId: true,
        testCaseName: false,
        Summary: false,
        Priority: false,
        TestingType: false,
        PreCondition: false,
        PostCondition: false,
        DetailSteps: false,
        TestScenarioDetails: false,
        Build: false,
        Module: false,
        CreatedAt: false,
        TestCaseStatus: false,
        ExecutionType: false,
        ExpectedResults: false,
        // Attachement: false,
        RequirementTitle: false,
        AssignTo: false,
        EstimatedTime: false,
      },
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      dispatch(setSelectedTestCaseFormValues(values))
    },
  })
  //select all functionality
  const [selectAll, setSelectAll] = useState(false)

  const handleSelectAll = () => {
    const updatedFields = Object.keys(formik.values.selectedFields).reduce(
      (acc, field) => {
        // Ensure 'DefectId' remains checked and can't be unchecked
        acc[field] = field === "testCaseId" ? true : !selectAll
        return acc
      },
      {}
    )

    formik.setFieldValue("selectedFields", updatedFields)
    setSelectAll(!selectAll)
  }

  useEffect(() => {
    // Check if all checkboxes are selected (except testCaseId)
    const allSelected = Object.keys(formik.values.selectedFields).every(
      field => field === "testCaseId" || formik.values.selectedFields[field]
    )

    setSelectAll(allSelected) // Automatically set the selectAll checkbox
  }, [formik.values.selectedFields])

  const [optionValues, setOptionValues] = useState({
    build: [],
    module: [],
    requirement: [],
    assignTo: [],
    testing_type: [],
    test_case_status: [],
  })

  const [options, setOptions] = useState({
    testing_type: [],
    test_case_status: [],
  })

  const bodyOption = useMemo(
    () => ({
      project_id: globalProject?.value || selectedProject?.value,
    }),
    [globalProject, selectedProject]
  )

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const responses = await Promise.all([
          dispatch(fetchAllBuildsList(bodyOption)),
          dispatch(fetchAllModulesList(bodyOption)),
          dispatch(fetchAllRequirementsList(bodyOption)),
          dispatch(fetchAllTeamsDetailsUserAssigneeList(bodyOption)),
          dispatch(getTagsStautusAPI(bodyOption)),
        ])

        const [
          buildResponse,
          moduleResponse,
          reqResponse,
          userListResponse,
          metaDataResponse,
        ] = responses

        const buildOptions = [
          { value: "", label: "All" },
          ...(Array.isArray(buildResponse)
            ? buildResponse.map(entry => ({
                value: entry.id,
                label: entry.build_id,
              }))
            : []),
        ]
        const moduleOptions = [
          { value: "", label: "All" },
          ...(Array.isArray(moduleResponse)
            ? moduleResponse.map(entry => ({
                value: entry.id,
                label: entry.module_name,
              }))
            : []),
        ]
        const reqOptions = [
          { value: "", label: "All" },
          ...(Array.isArray(reqResponse)
            ? reqResponse.map(entry => ({
                value: entry.id,
                label: entry.requirement_title,
              }))
            : []),
        ]
        const assignToOptions = [
          { value: "", label: "All" },
          ...(Array.isArray(userListResponse?.results)
            ? userListResponse.results.map(entry => ({
                value: entry.id,
                label: entry.user_name,
              }))
            : []),
        ]
        setOptions({
          testing_type: metaDataResponse?.data?.Testing_Type || [],
          test_case_status: metaDataResponse?.data?.Test_Status || [],
        })

        setOptionValues(prev => ({
          ...prev,
          build: buildOptions,
          module: moduleOptions,
          requirement: reqOptions,
          assignTo: assignToOptions,
        }))
      } catch (error) {
        console.log(error)
      }
    }

    fetchOptions()
  }, [])

  useEffect(() => {
    setOptionValues(prev => ({
      ...prev,
      test_case_status: createOptionsForReports(options.test_case_status),
      testing_type: createOptionsForReports(options.testing_type),
    }))
  }, [options.test_case_status, options.testing_type])
  const selectedFormValues = useSelector(
    state => state?.Reports?.selectedTestCaseFormValues
  )

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSaveTemplate, setIsSaveTemplate] = useState(false)

  const handleGenerateReport = () => {
    formik.validateForm().then(() => {
      if (formik.isValid) {
        // Only dispatch when there are no errors
        dispatch(setSelectedTestCaseFormValues(formik.values))
        setIsModalOpen(!isModalOpen)
      } else {
        console.log("Form has errors:", formik.errors)
      }
    })
  }
  const handleSaveAsTemplate = () => {
    formik.validateForm().then(() => {
      if (formik.isValid) {
        setIsSaveTemplate(!isSaveTemplate)
      } else {
        console.log("Form has errors:", formik.errors)
      }
    })
  }
  const fetchTableData = async () => {
    let bodyoption = {
      project_id: globalProject?.value || selectedProject?.value,
      report_type: reports?.report_type?.testCase,
    }
    try {
      const data = await dispatch(fetchAllReportsTemplateAPI(bodyoption))
      setTemplates(data)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }
  // Mapping of formState keys to payload keys
  const fieldMapping = {
    AssignTo: "assign_to",
    // Attachement: "attachment",
    Build: "build_id",
    CreatedAt: "created_at",
    EstimatedTime: "estimated_time",
    ExecutionType: "execution_type",
    ExpectedResults: "expected_result",
    Module: "module_id",
    PostCondition: "post_condition",
    PreCondition: "pre_conditions_id",
    Priority: "priority",
    RequirementTitle: "requirement_id",
    DetailSteps: "detail_steps",
    Summary: "summary",
    TestCaseStatus: "test_status",
    TestingType: "testing_type",
    TestScenarioDetails: "test_scenario_id",
    testCaseName: "test_case_name",
    testCaseId: "test_case_id",
  }

  const selectedFields = selectedFormValues?.selectedFields || {}

  // Create an array of selected field keys based on the mapping and values being true
  const selectedFieldKeys = Object.keys(selectedFields)
    .filter(key => selectedFields[key])
    .map(key => fieldMapping[key])
    .filter(Boolean) // Remove undefined entries

  const SaveTemplate = templateName => {
    const bodyOptions = {
      template_name: templateName,
      filters_data: [
        { key: "build_id", value: selectedFormValues?.build || null },
        { key: "module_id", value: selectedFormValues?.module || null },
        {
          key: "test_case_status",
          value: selectedFormValues?.test_case_status || null,
        },
        {
          key: "testing_type",
          value: selectedFormValues?.testing_type || null,
        },
        {
          key: "requirement_id",
          value: selectedFormValues?.requirement || null,
        },
        {
          key: "assignto",
          value: selectedFormValues?.assignedTo || null,
        },
        { key: "to_date", value: selectedFormValues?.toDate || null },
        { key: "from_date", value: selectedFormValues?.fromDate || null },
      ],
      columns_data: selectedFieldKeys,
      project_id: globalProject?.value,
      report_type: reports?.report_type?.testCase,
    }

    try {
      const res = dispatch(saveAsTemplateAPI(bodyOptions))
      setIsSaveTemplate(false)
      if (res) {
        setselectedTemplateOption("")
        fetchTableData()
        dispatch(setSelectedTestCaseFormValues(null))
      }
      formik.resetForm()
    } catch (error) {}
  }

  const [templates, setTemplates] = useState([])
  // Create options array from data
  const templateListOption = templates?.map(item => ({
    label: item?.template_name,
    value: item?.id,
  }))

  useEffect(() => {
    fetchTableData()
  }, [globalProject?.value])

  const handleSavedTemplateChange = async selectedOption => {
    if (!selectedOption) {
      setSelectedTemplate(null)
      formik.resetForm()
      return
    }

    setSelectedTemplate(selectedOption)

    try {
      const response = await dispatch(
        GetSingleReportTemplateAPI(selectedOption.value)
      )
      setselectedTemplateOption(response)
    } catch (error) {
      console.error("Error fetching template data:", error)
    }
  }

  useEffect(() => {
    if (selectedTemplateOption) {
      const updatedValues = { ...formik.initialValues }

      selectedTemplateOption.filters_data.forEach(filter => {
        switch (filter.key) {
          case "build_id":
            updatedValues.build = filter.value
            break
          case "module_id":
            updatedValues.module = filter.value
            break
          case "test_case_status":
            updatedValues.TestCaseStatus = filter.value
            break
          case "testing_type":
            updatedValues.TestCaseType = filter.value
            break
          case "requirement_id":
            updatedValues.requirement = filter.value
            break
          case "assignto":
            updatedValues.assignedTo = filter.value
            break
          case "to_date":
            updatedValues.toDate = filter.value
            break
          case "from_date":
            updatedValues.fromDate = filter.value
            break
          default:
            break
        }
      })

      const columns = selectedTemplateOption.columns_data?.split(",") || []
      const updatedSelectedFields = { ...formik.initialValues.selectedFields }

      const fieldMapping = {
        AssignTo: "assign_to",
        // Attachement: "attachment",
        Build: "build_id",
        CreatedAt: "created_at",
        EstimatedTime: "estimated_time",
        ExecutionType: "execution_type",
        ExpectedResults: "expected_result",
        Module: "module_id",
        PostCondition: "post_condition",
        PreCondition: "pre_conditions_id",
        Priority: "priority",
        RequirementTitle: "requirement_id",
        DetailSteps: "detail_steps",
        Summary: "summary",
        TestCaseStatus: "test_status",
        TestingType: "testing_type",
        TestScenarioDetails: "test_scenario_id",
        testCaseName: "test_case_name",
        testCaseId: "test_case_id",
      }
      Object.keys(updatedSelectedFields).forEach(key => {
        const columnName = fieldMapping[key]
        updatedSelectedFields[key] = columns.includes(columnName)
      })

      updatedValues.selectedFields = updatedSelectedFields

      formik.setValues(updatedValues)
    }
  }, [selectedTemplateOption])
  useEffect(() => {
    fetchTableData()
  }, [isSaveTemplate])

  const resetFilter = () => {
    dispatch(setSelectedTestCaseFormValues(null))
    setIsModalOpen(false)
    setSelectedTemplate(null)

    formik.resetForm()
  }
  const layoutModeType = useSelector(state => state.Layout.layoutModeType)

  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888",
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Ensure input text is visible
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150, // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }

  const permission = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission = permission?.permissions?.[0]?.menu_item?.Reports
  return (
    <React.Fragment>
      {rolePermission?.testcasereports?.create ? (
        <Container fluid>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={7}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label">Build</Label>
                          <Select
                            isClearable
                            styles={customStyles}
                            className="select2-selection"
                            placeholder="All"
                            name="build"
                            options={optionValues?.build}
                            onChange={option =>
                              formik.setFieldValue("build", option?.value)
                            }
                            onBlur={() => formik.setFieldTouched("build")}
                            value={optionValues?.build?.find(
                              option => option.value === formik.values.build
                            )}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label">Module</Label>
                          <Select
                            isClearable
                            styles={customStyles}
                            className="select2-selection"
                            placeholder="All"
                            name="module"
                            options={optionValues?.module}
                            onChange={option =>
                              formik.setFieldValue("module", option?.value)
                            }
                            onBlur={() => formik.setFieldTouched("module")}
                            value={optionValues?.module?.find(
                              option => option.value === formik.values.module
                            )}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Requirement Title
                          </Label>
                          <Select
                            isClearable
                            styles={customStyles}
                            className="select2-selection"
                            placeholder="All"
                            name="requirement"
                            options={optionValues?.requirement}
                            onChange={option =>
                              formik.setFieldValue("requirement", option?.value)
                            }
                            onBlur={() => formik.setFieldTouched("requirement")}
                            value={optionValues?.requirement?.find(
                              option =>
                                option.value === formik.values.requirement
                            )}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        {" "}
                        <div className="mb-3">
                          <Label className="form-label">Test Case Status</Label>
                          <Select
                            isClearable
                            styles={customStyles}
                            className="select2-selection"
                            placeholder="All"
                            name="TestCaseStatus"
                            options={optionValues?.test_case_status}
                            onChange={option =>
                              formik.setFieldValue(
                                "TestCaseStatus",
                                option?.value
                              )
                            }
                            onBlur={() =>
                              formik.setFieldTouched("TestCaseStatus")
                            }
                            value={optionValues?.test_case_status?.find(
                              option =>
                                option.value === formik.values.TestCaseStatus
                            )}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        {" "}
                        <div className="mb-3">
                          <Label className="form-label">Test Case Type</Label>
                          <Select
                            isClearable
                            styles={customStyles}
                            className="select2-selection"
                            placeholder="All"
                            name="TestCaseType"
                            options={optionValues?.testing_type}
                            onChange={option =>
                              formik.setFieldValue(
                                "TestCaseType",
                                option?.value
                              )
                            }
                            onBlur={() =>
                              formik.setFieldTouched("TestCaseType")
                            }
                            value={optionValues?.testing_type?.find(
                              option =>
                                option.value === formik.values.TestCaseType
                            )}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        {" "}
                        <div className="mb-3">
                          <Label className="form-label">Assigned To</Label>
                          <Select
                            isClearable
                            styles={customStyles}
                            className="select2-selection"
                            placeholder="All"
                            name="assignedTo"
                            options={optionValues?.assignTo}
                            onChange={option =>
                              formik.setFieldValue("assignedTo", option?.value)
                            }
                            onBlur={() => formik.setFieldTouched("assignedTo")}
                            value={optionValues?.assignTo?.find(
                              option =>
                                option.value === formik.values.assignedTo
                            )}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        {" "}
                        <div className="mb-3">
                          <Label className="form-label">From Date</Label>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block "
                              placeholder="dd/mm/yyyy"
                              options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                              }}
                              value={formik.values.fromDate}
                              onChange={date => {
                                const formattedDate = moment(date[0]).format(
                                  "YYYY-MM-DD"
                                )
                                formik.setFieldValue("fromDate", formattedDate)
                              }}
                            />
                          </InputGroup>
                          {formik.touched.fromDate &&
                            formik.errors.fromDate && (
                              <div className="error">
                                {formik.errors.fromDate}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col lg={6}>
                        {" "}
                        <div className="mb-3">
                          <Label className="form-label">To Date</Label>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="dd/mm/yyyy"
                              options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                              }}
                              value={formik.values.toDate}
                              onChange={date => {
                                const formattedDate = moment(date[0]).format(
                                  "YYYY-MM-DD"
                                )
                                formik.setFieldValue("toDate", formattedDate)
                              }}
                            />
                          </InputGroup>
                          {formik.touched.toDate && formik.errors.toDate && (
                            <div className="error">{formik.errors.toDate}</div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={5}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <div className="form-check form-checkbox-outline form-check-success">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="selectAllCheckbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="selectAllCheckbox"
                          >
                            <h5 className="TitleText">Extended Search</h5>
                          </label>
                        </div>
                      </Col>

                      {Object.keys(formik.values.selectedFields).map(field => (
                        <Col md={6} key={field}>
                          <div className="form-check form-checkbox-outline form-check-success mb-2">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheck-outlinecolor2"
                              name={field}
                              checked={formik.values.selectedFields[field]}
                              onChange={() =>
                                formik.setFieldValue("selectedFields", {
                                  ...formik.values.selectedFields,
                                  [field]: !formik.values.selectedFields[field],
                                })
                              }
                              disabled={field === "testCaseId"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheck-outlinecolor2"
                            >
                              {field.charAt(0).toUpperCase() +
                                field.slice(1).replace(/([A-Z])/g, " $1")}
                            </label>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    {/* Buttons Row */}
                    <div className="formRow buttons mb-3 mt-2">
                      <Row
                        className="d-flex justify-content-between"
                        style={{ gap: "2px" }}
                      >
                        <Col className="col-auto p-0">
                          <Button
                            type="submit"
                            id="GenerateReport"
                            color="success"
                            className="btn btn-success waves-effect waves-light"
                            onClick={handleGenerateReport}
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "12px",
                              marginRight: "2px",
                            }} // Small gap
                          >
                            Generate Report
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target="GenerateReport"
                          >
                            You can generate a defect report by applying various
                            filters according to your requirement.
                          </UncontrolledTooltip>
                        </Col>
                        <Col className="col-auto p-0">
                          <Button
                            type="submit"
                            id="SaveAsTemplate"
                            color="secondary"
                            className="btn btn-secondary waves-effect waves-light"
                            onClick={handleSaveAsTemplate}
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "12px",
                              marginRight: "2px",
                            }} // Small gap
                          >
                            Save As Template
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target="SaveAsTemplate"
                          >
                            You can save applied filters as a template for later
                            use.
                          </UncontrolledTooltip>
                        </Col>

                        <Col className="col-auto p-0">
                          <Link to="/Reports/TestCase/SavedTemplates">
                            <Button
                              type="button"
                              id="SavedTemplate"
                              style={{ whiteSpace: "nowrap", fontSize: "12px" }}
                              color="secondary"
                              className="btn btn-secondary waves-effect waves-light"
                            >
                              Saved Template
                            </Button>
                          </Link>
                          <UncontrolledTooltip
                            placement="top"
                            target="SavedTemplate"
                          >
                            You can view the list of saved templates here.
                          </UncontrolledTooltip>
                        </Col>
                      </Row>
                    </div>

                    {/* Saved Templates Section */}
                    <div className="mb-3">
                      <h5 className="TitleText">Saved Templates</h5>
                      <Label
                        style={{
                          width: "100%",
                          marginTop: "5px",
                          marginRight: "10px",
                        }}
                      >
                        <Select
                          name="savedTemplate"
                          placeholder="Select Template"
                          isClearable
                          styles={customStyles}
                          className="select2-selection"
                          value={selectedTemplate} // Ensure this is updated correctly based on state
                          options={templateListOption}
                          onChange={handleSavedTemplateChange} // Pass the handler directly
                        />
                      </Label>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>

          {isModalOpen && (
            <GenerateReport
              isModalOpen={isModalOpen}
              toggle={handleGenerateReport}
              resetFilter={resetFilter}
            />
          )}
          <SaveAsTemplate
            show={isSaveTemplate}
            handleClose={handleSaveAsTemplate}
            handleSave={SaveTemplate}
          />
        </Container>
      ) : (
        <NoPermission />
      )}
    </React.Fragment>
  )
}

export default TestCaseReport
