import React, { useMemo } from "react"
import { Table } from "reactstrap"
import { FaFilePdf } from "react-icons/fa"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { toast } from "react-toastify"

const DefectTable = ({ data }) => {
  // Dynamically extract the columns from the data
  const columns = useMemo(() => {
    const defaultColumns = ["Project Name", "Total Test Defects"] // Always present columns
    const statusKeys = new Set()

    // Loop through data to collect all possible status keys
    data?.forEach(project => {
      if (project?.status_counts) {
        Object?.keys(project?.status_counts)?.forEach(key =>
          statusKeys?.add(key)
        )
      }
    })

    // Sort status keys if needed (optional)
    const sortedStatusKeys = [...statusKeys].sort()

    // Combine default and status columns
    return [...defaultColumns, ...sortedStatusKeys]
  }, [data])

  // Dynamically generate table rows based on the extracted columns
  const tableRows = useMemo(() => {
    return data?.map(project => {
      const rowData = [
        project?.project_name,
        project?.total_test_defects_priority || 0,
      ]

      // For each dynamic status key, add its value to the row (or 0 if missing)
      columns?.slice(2)?.forEach(key => {
        rowData?.push(project?.status_counts?.[key] || 0)
      })

      return rowData
    })
  }, [data, columns])
  const projectListData = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission = projectListData?.permissions?.[0]?.menu_item?.Dashboard

  // Function to download the PDF
  const handleDownloadPDF = () => {
    const doc = new jsPDF()

    // Title for the PDF
    doc.text("Defect Reported By Priority", 14, 16)

    // Prepare the rows for the PDF
    const pdfRows = data?.map(project => {
      const row = [
        project?.project_name,
        project?.total_test_defects_priority || 0,
      ]

      // Add status counts for each column
      columns?.slice(2)?.forEach(key => {
        row?.push(project?.status_counts?.[key] || 0)
      })

      return row
    })

    // Add the table to the PDF
    doc?.autoTable({
      head: [columns],
      body: pdfRows,
      startY: 22,
      theme: "striped",
    })

    // Save the PDF
    doc?.save("Defect_Report_By_Priority.pdf")
  }

  return (
    <div>
      <div className="d-flex justify-content-between mb-2">
        <h4 className="Defect-Title">Defect Reported By Priority</h4>
        <div
          onClick={() => {
            if (rolePermission?.GlobalDashboard?.download) {
              handleDownloadPDF()
            } else {
              toast.warn("You Don't Have Permission To Download", {
                position: "top-right",
                autoClose: 3000,
              })
            }
          }}
          style={{ cursor: "pointer" }}
        >
          <FaFilePdf
            className="export-icon pdf hide-content"
            style={{
              color: "#D13438",
              height: "1.5rem",
              width: "1.5rem",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
      <div style={{ overflow: "auto", maxHeight: "250px" }}>
        <Table hover striped responsive className="text-center">
          <thead
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "#ffffff",
              zIndex: 1,
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <tr>
              {columns?.map(col => (
                <th key={col}>{col}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableRows?.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row?.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default DefectTable
