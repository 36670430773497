import React from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import profileImg from "assets/images/profile-img.png"
import avatar1 from "assets/icons/GenericUserProfile.svg"
import { useSelector } from "react-redux"

const WelcomeComp = () => {
  const userData = JSON.parse(localStorage.getItem("authUser"))
  const projects = useSelector(state => state?.Project?.allProjectList)


  return (
    <React.Fragment>
      <Card className="overflow-hidden" style={{minHeight:"300px"}}>
        <div className="bg-primary-subtle">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back !</h5>
                {/* <p>Skote Dashboard</p> */}
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row className="align-items-center">
            <Col sm="6">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={avatar1}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
              <h5 className="font-size-15 text-truncate">{userData.user_name}</h5>
              <p className="text-muted mb-0 ">{userData.role_name}</p>
            </Col>

            <Col sm="3">
              <div className="pt-5 mx-2"> 
                    <h5 className="font-size-15">{projects.length}</h5>
                    <p className="text-muted mb-0">Projects</p>
                   
              </div>
            </Col>
            <Col sm="3">
            <div className="pt-4">
                  <Link
                    to="/profile"
                    className="btn btn-primary  btn-sm"
                  >
                    View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
