import React from "react"
import { Table } from "reactstrap"
import { FaFilePdf } from "react-icons/fa"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { toast } from "react-toastify"

const DefectReported = ({ data }) => {
  const handleDownloadPDF = () => {
    const doc = new jsPDF()

    // Add title to the PDF
    doc.text("Defect Reported", 14, 16)

    // Define table columns and data
    const tableColumn = [
      "Project Name",
      "Total",
      "Deferred",
      "In Progress",
      "To Do",
      "Done",
      "QA",
      "Fixed",
      "Close",
      "Reopen",
      "Not A Bug",
      "Resolved",
    ]
    const tableRows = []

    // Add each project data to the rows
    data.forEach(project => {
      const projectData = [
        project.project_name,
        project.total_test_defects || 0,
        project.status_counts?.Deferred || 0,
        project.status_counts?.In_Progress || 0,
        project.status_counts?.To_Do || 0,
        project.status_counts?.Done || 0,
        project.status_counts?.QA || 0,
        project.status_counts?.Fixed || 0,
        project.status_counts?.Close || 0,
        project.status_counts?.Reopen || 0,
        project.status_counts?.Not_A_Bug || 0,
        project.status_counts?.Resolved || 0,
      ]
      tableRows.push(projectData)
    })

    // Generate the PDF using autoTable
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 22, // Positioning after title
      theme: "striped",
    })

    // Save the PDF
    doc.save("Defect_Reported.pdf")
  }

  const projectListData = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission = projectListData?.permissions?.[0]?.menu_item?.Dashboard

  return (
    <div>
      <div className="d-flex justify-content-between mb-2">
        <h4 className="Defect-Title">Defect Reported By Status</h4>
        <div
          onClick={() => {
            if (rolePermission?.GlobalDashboard?.download) {
              handleDownloadPDF()
            } else {
              toast.warn("You Don't Have Permission To Download", {
                position: "top-right",
                autoClose: 3000,
              })
            }
          }}
          style={{ cursor: "pointer" }}
        >
          <FaFilePdf
            className="export-icon pdf hide-content"
            style={{
              color: "#D13438",
              height: "1.5rem",
              width: "1.5rem",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
      <div style={{ overflow: "auto", maxHeight: "250px" }}>
        <Table hover striped responsive className="text-center">
          <thead
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "#ffffff",
              zIndex: 1,
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <tr>
              <th>Project Name</th>
              <th>Total</th>
              <th>Deferred</th>
              <th>In Progress</th>
              <th>To Do</th>
              <th>Done</th>
              <th>QA</th>
              <th>Fixed</th>
              <th>Close</th>
              <th>Reopen</th>
              <th>Not A Bug</th>
              <th>Resolved</th>
            </tr>
          </thead>
          <tbody>
            {data?.map(project => (
              <tr key={project.project_id}>
                <td>{project.project_name}</td>
                <td>{project.total_test_defects || 0}</td>
                <td>{project.status_counts?.Deferred || 0}</td>
                <td>{project.status_counts?.In_Progress || 0}</td>
                <td>{project.status_counts?.To_Do || 0}</td>
                <td>{project.status_counts?.Done || 0}</td>
                <td>{project.status_counts?.QA || 0}</td>
                <td>{project.status_counts?.Fixed || 0}</td>
                <td>{project.status_counts?.Close || 0}</td>
                <td>{project.status_counts?.Reopen || 0}</td>
                <td>{project.status_counts?.Not_A_Bug || 0}</td>
                <td>{project.status_counts?.Resolved || 0}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default DefectReported
