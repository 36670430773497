import React, { useEffect, useRef } from "react"
import Highcharts from "highcharts"
import { useSelector } from "react-redux" // Assuming you're using Redux to get layoutModeType

const BarChart2 = ({ data }) => {
  const chartRef = useRef(null)
  const layoutModeType = useSelector(state => state.Layout.layoutModeType)
  const isDarkMode = layoutModeType === "dark"
  useEffect(() => {
    if (chartRef.current && data) {
      // Prepare the data for the chart
      const categories = data.map(
        testCycle => testCycle.test_cycle_name || "Unnamed Cycle"
      )
      const passedData = data.map(testCycle => testCycle.passed_test_cases || 0)
      const failedData = data.map(testCycle => testCycle.failed_test_cases || 0)
      const notRunData = data.map(
        testCycle => testCycle.not_run_test_cases || 0
      )

      // Ensure all series are included in the series data
      const seriesData = [
        { name: "Passed", data: passedData, color: "#54d297" },
        { name: "Failed", data: failedData, color: "#ff776e" },
        { name: "Not Run", data: notRunData, color: "#ffc476" }, // Updated color for "Not Run"
      ]

      const options = {
        chart: {
          type: "column",
          backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#FFFFFF", // Dark mode background
        },
        title: {
          text: " ",
          align: "left",
          style: {
            color: layoutModeType === "dark" ? "#FFFFFF" : "#000000", // Adjust title color
          },
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: categories,
          title: {
            text: null,
          },
          labels: {
            style: {
              color: layoutModeType === "dark" ? "#FFFFFF" : "#000000", // Adjust x-axis label color
            },
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "No. of Test Cases",
            align: "middle",
            style: {
              color: layoutModeType === "dark" ? "#FFFFFF" : "#000000", // Adjust y-axis title color
            },
          },
          labels: {
            overflow: "justify",
            formatter: function () {
              return Math.floor(this.value) // Ensure labels are whole numbers
            },
            style: {
              color: layoutModeType === "dark" ? "#FFFFFF" : "#000000", // Adjust y-axis label color
            },
          },
          allowDecimals: false, // Disallow decimals on the y-axis
        },
        legend: {
          enabled: true,
          align: "center",
          verticalAlign: "top",
          layout: "horizontal",
          symbolRadius: 6, // Make legend symbols circular
          itemStyle: {
            color: layoutModeType === "dark" ? "#FFFFFF" : "#000000", // Adjust legend text color
          },
        },
        tooltip: {
          valueSuffix: " cases",
          backgroundColor: isDarkMode ? "#000000" : "#ffffff",
          style: {
            color: isDarkMode ? "#f0f0f0" : "#000000",
          },
        },
        plotOptions: {
          column: {
            pointWidth: 20, // Adjust the width of the bars
            dataLabels: {
              enabled: true,
              formatter: function () {
                return this.y !== 0 ? this.y : "" // Show data label only if value is not 0
              },
              style: {
                color: layoutModeType === "dark" ? "#FFFFFF" : "black", // Adjust data label color
                textOutline: layoutModeType === "dark" ? "none" : "white",
              },
            },
          },
        },
        series: seriesData,
      }

      Highcharts.chart(chartRef.current, options)
    }
  }, [data, layoutModeType]) // Add layoutModeType as a dependency

  return (
    <div
      ref={chartRef}
      style={{
        width: "100%",
        height: "440px",
        borderRadius: "10px",
      }}
    />
  )
}

export default BarChart2
