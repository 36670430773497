import PropTypes from "prop-types"
import React from "react"
import { Modal, ModalBody } from "reactstrap"

const DeleteMapModal = ({ show, onDeleteClick, onCloseClick, message,product }) => {    
  return (
    <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-2 py-3 text-center">
          <button
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
          <div className="avatar-sm mb-4 mx-auto">
            <div className="avatar-title bg-danger text-danger bg-opacity-10 font-size-20 rounded-3">
              <i className="mdi mdi-trash-can-outline"></i>
            </div>
          </div>
          <p className="text-muted font-size-14 mb-0 ">
            Are you sure you want to delete this {message}?
          </p>
          <p className="text-muted font-size-14 mb-4">
            {/* This action cannot be undone {product==="GitHub" && "and will impact on Test Automation"} {(product==="Jira"||product==="Plane") && "and will impact on Defect Management"} */}
            This action will impact the {product==="GitHub" && "execution of automated Tests"}{(product==="Jira"||product==="Plane") && "defects created"} and may lead to stop responding.<br /> For further assistance reach out at <br /><a target="_blank" rel="noreferrer" href="https://frothtestops.com/Self-Service">https://frothtestops.com/Self-Service</a>
          </p>

          <div className="hstack gap-2 justify-content-center mb-0">
            <button
              type="button"
              className="btn btn-danger"
              onClick={onDeleteClick}
            >
              Delete Now
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onCloseClick}
            >
              Close
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

DeleteMapModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
  message: PropTypes.any,
}

export default DeleteMapModal
