import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Alert,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import { useFormik } from "formik";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import "flatpickr/dist/themes/material_blue.css";
import Select from 'react-select';
import { useSelector, useDispatch } from "react-redux";
import { createOptions } from "../../../../helpers/helper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BuildsDetails, TagStatus, ModuleDetails } from "../../../../store/ProjectManagement/actions";
import httpInjectorService from "services/http-Injector.Service";

const BulkEditRequirments = ({ isOpen, toggle ,onSubmitEdit}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const parts = currentUrl.split("/");
  const id = parts[parts.length - 1];
  const [buildValues, setBuildValues] = useState([]);
  const [moduleValues, setModuleValues] = useState([]);
  const [requirementType, setRequirementType] = useState([]);
  const [reqTypeOptions, setReqTypeOptions] = useState([]);
  const tagDetails = useSelector(state => state.Project?.tagStatus.data);
  const buildDetails = useSelector(state => state.Project?.buildDetails);
  const moduleDetails = useSelector(state => state.Project?.moduleDetails);
  const selectedRequirements = useSelector(state => state.Project?.selectedRequirements);

  useEffect(() => {
    setRequirementType(createOptions(reqTypeOptions));
  }, [reqTypeOptions]);

   


  useEffect(() => {
  
      const buldData = [];
      const moduleData = [];

      buildDetails.forEach((entry) => {
        buldData.push({
          value: Math.round(entry.id),
          label: entry.build_id,
        });
      });

      moduleDetails.forEach((entry) => {
        moduleData.push({
          value: entry.id,
          label: entry.module_name,
        });
      });

      setBuildValues(buldData);
      setModuleValues(moduleData);
      setReqTypeOptions(tagDetails?.Requirement_Type);
  }, [buildDetails, moduleDetails,tagDetails]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      build: '',
      module: '',
      RequirementType: '',
      requirementSource: '',
    },
  });

  const onClickApply = (e) => {
    e.preventDefault();
    const values = formik.values;

    if (!values.build && !values.module && !values.RequirementType && !values.requirementSource) {
      toast.warning("Please select at least one field before applying.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      return; // Exit the function if no fields are selected
    }

    const bodyoptions = {
      project_id: id,
      build_id: values.build,
      module_id: values.module,
      requirement_type: values.RequirementType,
      requirement_source: values.requirementSource,
      requirement_ids: selectedRequirements,
    };

    BulkEditRequirements(bodyoptions);
  }

  const BulkEditRequirements = async (bodyoption) => {
    const formData = new FormData();
    for (const key in bodyoption) {
      if (bodyoption[key] !== undefined && bodyoption[key] !== null) {
        formData.append(key, bodyoption[key]);
      }
    }
    try {
      const response = await httpInjectorService.BulkEditRequirement(formData);
      if (response?.status) {
        toast.success(response.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        formik.resetForm();  
        toggle(); 
        onSubmitEdit()
      } else {
        toast.warning(response.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      throw new Error(error.message); // Moved after the toast
    }
  };

  const onCancel = () => {
    formik.resetForm();  
    toggle();  
    onSubmitEdit()

  }

  
  const layoutModeType = useSelector((state) => state.Layout.layoutModeType);  

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: layoutModeType === 'dark' ? '#2a3042' : '#fff',
      color: layoutModeType === 'dark' ? '#fff' : '#000',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: layoutModeType === 'dark' ? '#2a3042' : '#fff',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: layoutModeType === 'dark' ? '#fff' : '#000',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: layoutModeType === 'dark' ? '#888' : '#888',
    }),
    input: (provided) => ({
      ...provided,
      color: layoutModeType === 'dark' ? '#fff' : '#000', // Ensure input text is visible
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150, // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }


  const renderSelect = (name, label, placeholder, options, isRequired = true, customProps = {}) => (
    <div className="mb-3">
      <Label className="form-label">
        {label} {isRequired && <span className="text-danger">*</span>}
      </Label>
      <Select
        name={name}
        placeholder={placeholder}
        onChange={(option) => formik.setFieldValue(name, option?.value)}
        onBlur={() => formik.setFieldTouched(name)}
        options={options}
        className={`select2-selection ${formik.touched[name] && formik.errors[name] ? 'is-invalid' : ''}`}
        {...customProps}
        maxMenuHeight={200}
        styles={customStyles}
      />
      {formik.touched[name] && formik.errors[name] && (
        <FormFeedback className="custom-error-message">{formik.errors[name]}</FormFeedback>
      )}
    </div>
  );

  const renderInput = (name, label, type = "text", placeholder = "", isRequired = true) => (
    <div className="mb-3">
      <Label className="form-label">
        {label} {isRequired && <span className="text-danger">*</span>}
      </Label>
      <Input
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values[name] || ""}
        invalid={formik.touched[name] && formik.errors[name]}
        className="custom-input"
      />
      {formik.touched[name] && formik.errors[name] && (
        <FormFeedback className="custom-error-message">{formik.errors[name]}</FormFeedback>
      )}
    </div>
  );

  return (
    <React.Fragment>
      <Offcanvas isOpen={isOpen} direction="end" toggle={toggle}>
        <OffcanvasHeader toggle={toggle}>Bulk Edit Requirement</OffcanvasHeader>
        <OffcanvasBody>
          <Card>
            <div className=" mx-3"> 
              <Alert color="info" role="alert">
                By Updating all the previous associations for selected records will be reset.
              </Alert>
            </div>
            <CardBody>
              <Form onSubmit={formik.handleSubmit}>
                {renderSelect("build", "Build", "Select Build", buildValues, false)}
                {renderSelect("module", "Module", "Select Module", moduleValues, false)}
                {renderSelect("RequirementType", "Requirement Type", "Select Requirement Type", requirementType, false)}
                {renderInput("requirementSource", "Requirement Source", "text", "Enter requirement source", false)}
                <div className="d-flex justify-content-between flex-wrap gap-2 mt-3">
                  <Button type="reset" color="secondary" onClick={onCancel}>Cancel</Button>
                  <Button type="submit" color="primary" onClick={onClickApply}>Apply</Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  );
};

export default BulkEditRequirments;
