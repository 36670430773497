import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
  Form,
  InputGroup,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import "flatpickr/dist/themes/material_blue.css"
import JoditEditor from "jodit-react"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import Breadcrumb from "components/Common/Breadcrumb"
import "./CreateTestPlanForm.scss"
import { fetchAllBuildsList } from "store/actions/BuildsActions"

import { createOptions, joditConfig } from "helpers/helper"
import Flatpickr from "react-flatpickr"
import {
  createTestPlanAPI,
  EditTestPlanAPI,
  fetchNextTestPlanIdAPI,
} from "store/actions/TestPlanActions"
import { getTagsStautusAPI } from "store/actions/TagsAction"
import {
  fetchAllTeamsDetailsUserAssigneeList,
  fetchUsersList,
} from "store/actions/UserActions"
import { fetchTestCycleDetailsAPI } from "store/actions/TestCycleActions"
import { setEditTestPlan } from "store/Slices/TestPlanSlice"
import moment from "moment"

const DefectReport = () => {
  const [buildOptionValue, setBuildOptionValues] = useState([])
  const [TestPlansValue, setTestPlanValue] = useState([])
  const [testPlansOptions, setTestPlanOptions] = useState([])
  const [approvalOptions, setApprovalOptions] = useState([])
  const [next_test_plan_id, setNextId] = useState("")
  const [assignToValue, setAssignToValue] = useState([])
  const [TestCycleOptionValue, setTestCycleOptionValues] = useState([])

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const editTestPlan = useSelector(state => state?.TestPlan?.editTestPlan)
  const loading = useSelector(state => state?.Loading?.isLoading)

  const isEditMode = editTestPlan?.isEdit
  const isEditModeData = editTestPlan?.data

  const [description, setDescription] = useState("")
  const [milestone, setMilestone] = useState("")
  const [deliverables, setDeliverables] = useState("")
  const [isDeliverablesInvalid, setIsDeliverablesInvalid] = useState(false)
  const layoutModeType = useSelector(state => state.Layout.layoutModeType)
  const [isMilestoneInvalid, setIsMilestoneInvalid] = useState(false)
  const [requestApprovalField, setRequestApprovalField] = useState(false)
  const breadcrumbItems = [
    { label: "Test Plan", path: "/TestPlan" },
    {
      label: isEditMode ? "Edit Test Plan " : "Create Test Plan",
      path: "/",
    },
  ]

  const validationSchema = Yup.object().shape({
    test_plan_name: Yup.string()
      .trim()
      .matches(/^.*\S.*$/, "Test Plan Name cannot contain only spaces")
      .required("Test Plan Name is required"),
    assign_to_id: Yup.array().min(1, "At least one Assign To must be selected"), // Change from Yup.string() to Yup.array()

    start_date: Yup.date()
      .nullable()
      .test(
        "fromDate-test",
        "Start Date is required when End Date is selected",
        function (value) {
          const { end_date } = this.parent // Access the value of end_date
          if (end_date && !value) {
            return false // From Date is required if To Date is selected
          }
          return true // If To Date is not selected, allow fromDate to be null
        }
      ),

    end_date: Yup.date()
      .nullable()
      .test(
        "toDate-test",
        "End Date must be after Start Date",
        function (value) {
          const { start_date } = this.parent // Access the value of start_date
          if (start_date && value) {
            return new Date(value) > new Date(start_date) // Validate that toDate is after fromDate
          }
          return true // If fromDate is not selected, allow toDate to be null
        }
      ),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      project_id: globalProject?.value || "",
      test_plan_id: isEditModeData
        ? isEditModeData?.test_plan_id || ""
        : next_test_plan_id,
      build_id: isEditModeData ? isEditModeData?.build_id || "" : "",
      test_plan_name: isEditModeData
        ? isEditModeData?.test_plan_name || ""
        : "",
      description: isEditModeData ? isEditModeData?.description || "" : "",
      test_cycle_id: isEditModeData ? isEditModeData?.test_cycle_id || [] : [],
      test_plan_status: isEditModeData
        ? isEditModeData?.test_plan_status || ""
        : "",
      approval_status: isEditModeData
        ? isEditModeData?.approval_status || "New"
        : "New",
      start_date: isEditModeData ? isEditModeData?.start_date || null : null,
      end_date: isEditModeData ? isEditModeData?.end_date || null : null,
      environment: isEditModeData ? isEditModeData?.environment || "" : "",
      milestone_description: isEditModeData
        ? isEditModeData?.milestone_description || ""
        : "",
      deliverables: isEditModeData ? isEditModeData?.deliverables || "" : "",
      assign_to_id: isEditModeData
        ? isEditModeData.assign_to_id.map(item => ({
            id: item.id,
            user_name: item.user_name,
          }))
        : [],
      approval_from: isEditMode ? isEditModeData?.approval_from || "" : "",
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (!milestone) {
        setIsMilestoneInvalid(true)
      } else if (!deliverables) {
        setIsDeliverablesInvalid(true)
      } else if (
        formik.values.approval_status === "Pending Approval" &&
        !formik.values.approval_from
      ) {
        setRequestApprovalField(true)
      } else {
        const formData = {
          ...values,
          description,
          approval_status: formik.values.approval_from
            ? "Pending Approval"
            : formik.values.approval_status
              ? formik.values.approval_status
              : "New",
          milestone_description: milestone,
          deliverables: deliverables,
          assign_to_id: values.assign_to_id.map(item => item.id),
        }
        createTestPlan(formData)
        resetForm()
      }
    },
  })

  const createTestPlan = async formData => {
    const editFromValues = {
      ...formData,
      id: isEditModeData?.id,
    }
    const CreateFromValues = {
      ...formData,
      id: isEditModeData?.id,
      test_cycle_id:
        formData?.test_cycle_id?.length === 0 ? "" : formData?.test_cycle_id,
    }

    console.log("editFromValues", editFromValues)
    console.log("CreateFromValues", CreateFromValues)
    if (!isEditModeData) {
      try {
        await dispatch(createTestPlanAPI(CreateFromValues))
        navigate(-1) // Navigate back or handle success
      } catch (error) {
        console.error("Error creating test scenario:", error)
        toast.error("An error occurred while creating Test Scenario", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
      }
    } else {
      try {
        await dispatch(EditTestPlanAPI(editFromValues))
        dispatch(setEditTestPlan({ isEdit: false, data: null }))
        navigate(-1) // Navigate back or handle success
      } catch (error) {
        console.error("Error updating test scenario:", error)
        toast.error("An error occurred while updating Test Scenario", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
      }
    }
  }

  const stripHtmlTags = html => {
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent || ""
  }

  const submitData = () => {
    if (!stripHtmlTags(milestone).trim()) {
      setIsMilestoneInvalid(true)
    }
    if (!stripHtmlTags(deliverables).trim()) {
      setIsDeliverablesInvalid(true)
    }
    if (
      formik.values.approval_status === "Pending Approval" &&
      !formik.values.approval_from
    ) {
      setRequestApprovalField(true)
    }
  }
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const responses = await Promise.all([
          dispatch(
            fetchAllBuildsList({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          dispatch(
            fetchNextTestPlanIdAPI({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          dispatch(
            getTagsStautusAPI({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          // dispatch(fetchUsersList({ project_id: globalProject?.value || selectedProject?.value })),
          dispatch(
            fetchAllTeamsDetailsUserAssigneeList({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          dispatch(
            fetchTestCycleDetailsAPI({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
        ])

        const [
          buildResponse,
          nextIdResponse,
          metaDataResponse,
          userListResponse,
          TestCycleResponse,
        ] = responses

        setTestPlanValue(metaDataResponse?.data?.Test_Status)
        setApprovalOptions(
          createOptions(metaDataResponse?.data?.Test_Status || [])
        )
        setBuildOptionValues(
          buildResponse?.map(entry => ({
            value: Math.round(entry.id),
            label: entry.build_id,
          }))
        )
        setAssignToValue(
          userListResponse?.results?.map(entry => ({
            value: entry.id,
            label: entry.user_name,
          }))
        )
        setTestCycleOptionValues(
          TestCycleResponse?.map(entry => ({
            value: Math.round(entry.id),
            label: entry.test_cycle_id,
          }))
        )

        await setNextId(nextIdResponse?.next_test_plan_id)
      } catch (error) {
        console.error("Failed to fetch options", error)
        toast.error("Failed to fetch options.")
      }
    }
    fetchOptions()
  }, [globalProject, dispatch])

  useEffect(() => {
    if (isEditModeData) {
      setDescription(isEditModeData.description || "")
      setMilestone(isEditModeData.milestone_description || "")
      setDeliverables(isEditModeData.deliverables || "")
    }
  }, [isEditModeData])
  useEffect(() => {
    // setTestPlanOptions(createTagsOptions(TestPlansValue))
    const projectStatusOption = [
      { value: "To Do", label: "To Do" },
      { value: "In Progress", label: "In Progress" },
      { value: "Achieved", label: "Achieved" },
      { value: "On Hold", label: "On Hold" },
    ]
    setTestPlanOptions(projectStatusOption)
  }, [TestPlansValue])

  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888",
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    // Customize multiValue (selected item background)
    multiValue: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#3a3f51" : "#e0e0e0", // Dark background for selected items
    }),
    // Customize multiValueLabel (selected item text color)
    multiValueLabel: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Adjust text color
    }),
    // Customize multiValueRemove (cross icon for removing selected items)
    multiValueRemove: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
      ":hover": {
        backgroundColor: layoutModeType === "dark" ? "#ff4949" : "#e0e0e0", // Hover effect for remove icon
        color: "#fff",
      },
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150,
      backgroundColor: "white", // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Breadcrumb title="Test Plan" breadcrumbItems={breadcrumbItems} />

        <div className="mt-2 mx-3 d-flex justify-content-between">
          <div>
            <h3>{!isEditMode ? "Create New Test Plan" : "Edit Test Plan"}</h3>
            <p className="text-muted">
              Fill the form to {!isEditMode ? "create" : "edit"} a test plan
            </p>
          </div>
          <div>
            <Button
              type="button"
              color="primary"
              className="btn-label"
              onClick={() => {
                navigate(-1)
                dispatch(setEditTestPlan({ isEdit: false, data: null }))
              }}
            >
              <i className="bx bx-arrow-back label-icon"></i> Go Back
            </Button>
          </div>
        </div>

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label">Project</Label>
                    <Input
                      name="project_id"
                      type="text"
                      placeholder="Project"
                      value={globalProject?.label}
                      className="custom-input"
                      readOnly
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Test Plan Id</Label>
                    <Input
                      name="test_plan_id"
                      type="text"
                      placeholder="Test Plan Id"
                      value={formik.values.test_plan_id}
                      className="custom-input"
                      readOnly
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">
                      Test Plan Name <span className="text-danger">*</span>
                    </Label>
                    <Input
                      name="test_plan_name"
                      type="text"
                      placeholder="Enter Test Plan Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.test_plan_name}
                      className="custom-input"
                    />
                    {formik.touched.test_plan_name &&
                      formik.errors.test_plan_name && (
                        <div className="text-danger">
                          {formik.errors.test_plan_name}
                        </div>
                      )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label">Build</Label>
                    <Select
                      isClearable
                      className="select2-selection"
                      styles={customStyles}
                      name="build_id"
                      options={buildOptionValue}
                      onChange={option =>
                        formik.setFieldValue(
                          "build_id",
                          option ? option?.value : ""
                        )
                      }
                      onBlur={() => formik.setFieldTouched("build_id")}
                      value={buildOptionValue.find(
                        option => option.value === formik.values.build_id
                      )}
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Select Cycle</Label>
                    <Select
                      isClearable
                      className="select2-selection"
                      name="test_cycle_id"
                      options={TestCycleOptionValue}
                      isMulti
                      styles={customStyles}
                      onChange={options =>
                        formik.setFieldValue(
                          "test_cycle_id",
                          options ? options.map(option => option.value) : [] // Convert selected options to an array of values
                        )
                      }
                      onBlur={() => formik.setFieldTouched("test_cycle_id")}
                      value={TestCycleOptionValue.filter(option =>
                        formik.values.test_cycle_id.includes(option.value)
                      )} // Set the selected options for the select field
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Environment</Label>
                    <Input
                      name="environment"
                      type="text"
                      placeholder="Environment"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.environment}
                      invalid={
                        formik.touched.environment &&
                        !!formik.errors.environment
                      }
                      className="custom-input"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  {" "}
                  <div className="mb-3">
                    <Label className="form-label">Test Plan Status</Label>
                    <Select
                      // isClearable
                      className="select2-selection"
                      styles={customStyles}
                      name="test_plan_status"
                      options={testPlansOptions}
                      onChange={option =>
                        formik.setFieldValue("test_plan_status", option?.value)
                      }
                      value={testPlansOptions.find(
                        option =>
                          option.value === formik.values.test_plan_status
                      )}
                      onBlur={() => formik.setFieldTouched("test_plan_status")}
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">
                      Assign To
                      <span className="text-danger">*</span>
                    </Label>
                    <Select
                      isClearable
                      className="select2-selection"
                      name="assign_to_id"
                      styles={customStyles}
                      options={assignToValue}
                      isMulti
                      onChange={options => {
                        // Convert selected options to the format needed by Formik
                        formik.setFieldValue(
                          "assign_to_id",
                          options
                            ? options.map(option => ({
                              id: option.value,
                              user_name: option.label,
                            }))
                            : []
                        )
                      }}
                      onBlur={() =>
                        formik.setFieldTouched("assign_to_id", true)
                      }
                      value={assignToValue.filter(option =>
                        formik.values.assign_to_id.some(
                          selected => selected.id === option.value
                        )
                      )}
                    />

                    {formik.touched.assign_to_id &&
                      formik.errors.assign_to_id ? (
                      <div className="text-danger">
                        {formik.errors.assign_to_id}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">
                      Approval From
                      {formik.values.approval_status === "Pending Approval" && (
                        <span className="text-danger">*</span>
                      )}
                    </Label>

                    <Select
                      isClearable
                      styles={customStyles}
                      className="select2-selection"
                      name="approval_from"
                      options={assignToValue}
                      onChange={option =>
                        formik.setFieldValue("approval_from", option?.value)
                      }
                      onBlur={() => formik.setFieldTouched("approval_from")}
                      value={assignToValue?.find(
                        option => option.value === formik.values.approval_from
                      )}
                    />
                    {formik.values.approval_status === "Pending Approval" &&
                      requestApprovalField && (
                        <div className="text-danger">
                          Approval From is required
                        </div>
                      )}
                    {/* {formik.touched.approval_from &&
                    formik.errors.approval_from ? (
                      <div className="text-danger">
                        {formik.errors.approval_from}
                      </div>
                    ) : null} */}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Approval Status</Label>
                    <Select
                      // isClearable={formik.values.approval_status && true}
                      className="select2-selection"
                      styles={customStyles}
                      name="approval_status"
                      options={approvalOptions}
                      onChange={option =>
                        formik.setFieldValue("approval_status", option?.value)
                      }
                      value={
                        formik.values.approval_status
                          ? {
                            value: formik.values.approval_status,
                            label: formik.values.approval_status,
                          }
                          : {
                            value: null,
                            label: (
                              <span className="text-muted mt-2">
                                Select..
                              </span>
                            ),
                          }
                      }
                      onBlur={() => formik.setFieldTouched("approval_status ")}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardBody>
                  {" "}
                  <div className="mb-3">
                    <Label>Start Date</Label>
                    <InputGroup>
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="dd-mm-yyyy"
                        options={{
                          altInput: true,
                          altFormat: "d/m/Y",
                          dateFormat: "Y-m-d", // Ensure the stored format is YYYY-MM-DD
                        }}
                        value={
                          formik.values.start_date
                            ? moment(formik.values.start_date).format(
                              "YYYY-MM-DD"
                            )
                            : ""
                        }
                        onChange={date => {
                          // Use moment to handle the date and convert it to the correct format
                          const formattedDate = moment(date[0]).format(
                            "YYYY-MM-DD"
                          )
                          formik.setFieldValue("start_date", formattedDate)
                        }}
                      />
                    </InputGroup>
                    {formik.touched.start_date && formik.errors.start_date ? (
                      <div className="text-danger">
                        {formik.errors.start_date}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label>End Date</Label>
                    <InputGroup>
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="dd-mm-yyyy"
                        options={{
                          altInput: true,
                          altFormat: "d/m/Y",
                          dateFormat: "Y-m-d", // Ensure the stored format is YYYY-MM-DD
                        }}
                        value={
                          formik.values.end_date
                            ? moment(formik.values.end_date).format(
                              "YYYY-MM-DD"
                            )
                            : ""
                        }
                        onChange={date => {
                          // Use moment to handle the date and convert it to the correct format
                          const formattedDate = moment(date[0]).format(
                            "YYYY-MM-DD"
                          )
                          formik.setFieldValue("end_date", formattedDate)
                        }}
                      />
                    </InputGroup>
                    {formik.touched.end_date && formik.errors.end_date ? (
                      <div className="text-danger">
                        {formik.errors.end_date}
                      </div>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Col md="12">
                    <Label>
                      Milestone <span className="text-danger">*</span>
                    </Label>
                    <JoditEditor
                      value={milestone}
                      config={joditConfig()}
                      onBlur={newContent => setMilestone(newContent)}
                      // onBlur={() => setIsMilestoneInvalid(!milestone)}
                    />
                    {isMilestoneInvalid && (
                      <div className="text-danger">Milestone is required</div>
                    )}
                  </Col>
                </CardBody>
              </Card>
            </Col>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Col md="12">
                    <Label>
                      Deliverables <span className="text-danger">*</span>
                    </Label>
                    <JoditEditor
                      value={deliverables}
                      config={joditConfig()}
                      onBlur={newContent => setDeliverables(newContent)}
                      // onBlur={() => setIsDeliverablesInvalid(!deliverables)}
                    />
                    {isDeliverablesInvalid && (
                      <div className="text-danger">
                        Deliverables is required
                      </div>
                    )}
                  </Col>
                </CardBody>
              </Card>
            </Col>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Col md="12">
                    <Label>Description</Label>
                    <JoditEditor
                      value={description}
                      config={joditConfig()}
                      onBlur={newContent => setDescription(newContent)}
                    />
                  </Col>
                </CardBody>
              </Card>
              <Row className="mb-3">
                <Col md="12" className="text-end mt-3">
                  <Button color="primary" type="submit" onClick={submitData} disabled={loading}>
                    {loading ? "Loading..." : "Submit"}
                  </Button>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => {
                      formik.resetForm()
                      navigate(-1)
                      dispatch(setEditTestPlan({ isEdit: false, data: null }))
                    }}
                    className="ms-2"
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </React.Fragment>
  )
}

export default DefectReport
