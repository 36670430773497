import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  FormGroup,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  UncontrolledAccordion,
  InputGroup,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import "flatpickr/dist/themes/material_blue.css"
import JoditEditor from "jodit-react"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import { createOptions, joditConfig } from "../../../../helpers/helper"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import Breadcrumb from "components/Common/Breadcrumb"
import "../../../Test Management/Test Scenario/Create Test Scenario/CreateTestScenarioForm.scss"
import { fetchAllBuildsList } from "store/actions/BuildsActions"
import {
  setCreateEditTestCycle,
  setViewTestCycle,
} from "store/Slices/TestCycleSlice"
import {
  CreateTestCycle,
  EditTestCycle,
  fetchNextCycleID,
  FetchOs,
  FetchWeb,
  GetTestCycleByIdAPI,
} from "store/actions/TestCycleActions"
import { AutomationSuiteDetails } from "store/actions/AutomationSuiteAction"
import Flatpickr from "react-flatpickr"
import { TestCaseDetails } from "store/actions/TestCaseAction"
import { getTagsStautusAPI } from "store/actions/TagsAction"
import {
  fetchAllTeamsDetailsUserAssigneeList,
  // fetchUsersList,
} from "store/actions/UserActions"
import { FetchAllAgentsCreation } from "store/actions/AgentCreationActions"
import { FetchAllUploadApp } from "store/actions/UploadAppActions"
import "./TestCycle.scss"
// import { getAllIntegrationListAPI } from "store/actions/IntegrationAction"

const CreateEditTestCycle = () => {
  const [buildOptionValue, setBuildOptionValues] = useState([])
  const [nextTestCycleId, setNextTestCycleId] = useState("")
  const [gitHub, setGitHub] = useState(false)
  const [browserStack, setBrowserStack] = useState(false)
  const [appUploadOptions, setAppUploadOptions] = useState([])
  const [loadings, setLoading] = useState(false)
  const loading = useSelector(state => state?.Loading?.isLoading)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currentUrl = window.location.href
  const parts = currentUrl.split("/")
  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const handleRadioChange = event => {
    setSelectedValue(event.target.value)
  }
  const layoutModeType = useSelector(state => state.Layout.layoutModeType)

  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
      borderColor: layoutModeType === "dark" ? "#444" : "#ddd",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#2a3042",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888", // Keep or customize based on dark/light mode
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Ensure text is visible while typing
    }),
    multiValue: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#3a3f51" : "#e0e0e0", // Dark background for selected items
    }),
    // Customize multiValueLabel (selected item text color)
    multiValueLabel: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Adjust text color
    }),
    // Customize multiValueRemove (cross icon for removing selected items)
    multiValueRemove: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
      ":hover": {
        backgroundColor: layoutModeType === "dark" ? "#ff4949" : "#e0e0e0", // Hover effect for remove icon
        color: "#fff",
      },
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150, // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }

  const editTestCycle = useSelector(state => state?.TestCycle?.editTestCycle)
  const mapProjectList = useSelector(state => state?.Integration?.mapList)
  const integrationList = useSelector(
    state => state?.Integration?.integrationList
  )

  // useEffect(() => {
  //   let payload = { project_id: null }
  //   dispatch(getAllIntegrationListAPI(payload))
  // }, [])
  useEffect(() => {
    const filteredProjectList = mapProjectList?.filter(
      project => project.product === "GitHub"
    )
    const filteredProject = integrationList?.some(
      project => project.product === "BrowserStack"
    )

    const isGitHub = filteredProjectList?.some(
      defect => defect?.frothtestops_project === globalProject?.label
    )

    setGitHub(isGitHub)
    setBrowserStack(filteredProject)
  }, [mapProjectList, integrationList])

  const isEditMode = editTestCycle?.isEdit
  const isEditModeData = editTestCycle?.data
  const [selectedValue, setSelectedValue] = useState(
    isEditMode ? isEditModeData?.cycle_type : "Manual"
  )

  const handleUploadApp = () => {
    navigate("/TestAutomation/UploadNewApp")
  }

  const [description, setDescription] = useState("")
  // const [isDescriptionInvalid, setIsDescriptionInvalid] = useState(false)
  const [SuiteIdValues, setSuiteIdValues] = useState([])
  const [SuiteOptionValue, setSuiteOptionValues] = useState([])
  const [CaseIdValues, setCaseIdValues] = useState([])
  const [CaseOptionValue, setCaseOptionValues] = useState([])
  const [statusOptionValue, setStatusOptionValues] = useState([])
  const [isLocal, setIsLocal] = useState(
    isEditModeData ? isEditModeData.browser_stack_local : false
  )
  const [applicationType, setApplicationType] = useState(
    isEditModeData ? isEditModeData?.testcycle_type : "Web"
  )
  const [assignToValue, setAssignToValue] = useState([])
  const [agentValue, setAgentValue] = useState([])

  const [browserName, setBrowserName] = useState(
    isEditModeData
      ? applicationType === "Web"
        ? isEditModeData?.app_browser
        : isEditModeData?.device_name
      : null
  )
  const [browserWeb, setBrowserWeb] = useState(
    isEditModeData ? isEditModeData?.version : null
  )

  const [browserApp, setBrowserApp] = useState(
    isEditModeData ? isEditModeData?.application_id : null
  )
  const [agent, setAgent] = useState(
    isEditModeData ? isEditModeData?.execution_agent : 1
  )

  const [deviceOsOptions, setDeviceOsOptions] = useState([])
  const [deviceVersionOptions, setDeviceVersionOptions] = useState([])
  const [webOptions, setwebOptions] = useState([])
  const [webOsOptions, setwebOsOptions] = useState([])
  const [startDate, setStartDate] = useState(
    isEditModeData ? isEditModeData?.start_date : null
  )
  const [endDate, setEndDate] = useState(
    isEditModeData ? isEditModeData?.end_date : null
  )

  const applicationTypeOptions = [
    { value: "Ios", label: "IOS" },
    { value: "Android", label: "Android" },
    { value: "Web", label: "Web" },
  ]

  const checkValidations = () => {
    const error = {}
    if (selectedValue !== "Manual") {
      if (!applicationType) error.applicationType = "Application Type Required"
      if (applicationType !== "Web") {
        if (!browserName)
          error.browserName = "Browserstack Device Name required"
        if (!browserWeb) error.browserWeb = "Browserstack Os version required"
        if (!browserApp) error.browserApp = "Browserstack Application required"
      }
    }

    if (startDate && endDate) {
      // Convert startDate and endDate to Date objects for proper comparison
      const start = new Date(startDate)
      const end = new Date(endDate)

      // Check if endDate is before or the same as startDate
      if (end <= start) {
        error.endDate = "End date should be after start date"
      } else {
        // Clear the error if dates are valid
        error.endDate = ""
      }
    }
    return error
  }

  const handleSuiteChange = selectedOptions => {
    const updatedSelectedOptions = (selectedOptions || [])?.map(option => {
      const matchingPreCondition = SuiteOptionValue?.find(
        item => item.value === option.value
      )

      return matchingPreCondition || option
    })
    setSuiteIdValues(updatedSelectedOptions)
  }

  const handleCaseChange = selectedOptions => {
    const updatedSelectedOptions = (selectedOptions || [])?.map(option => {
      const matchingPreCondition = CaseOptionValue?.find(
        item => item.value === option.value
      )

      return matchingPreCondition || option
    })
    setCaseIdValues(updatedSelectedOptions)
  }

  const shuffleTestCase = data => {
    const test_sequence_string = isEditModeData?.test_sequence

    const test_sequence = {}

    const pairs = test_sequence_string?.slice(1, -1).split(",")

    pairs?.forEach(pair => {
      const [key, value] = pair.split(":")?.map(str => str.trim())
      test_sequence[parseInt(key)] = parseInt(value)
    })

    const sortedExecutionData = [...data].sort((a, b) => {
      const sequenceA = test_sequence[a.id] || Number.POSITIVE_INFINITY
      const sequenceB = test_sequence[b.id] || Number.POSITIVE_INFINITY
      return sequenceA - sequenceB
    })
    return sortedExecutionData
  }

  const shuffleTestSuite = data => {
    const test_sequence_string = isEditModeData?.test_sequence

    const test_sequence = {}

    const pairs = test_sequence_string?.slice(1, -1).split(",")

    pairs?.forEach(pair => {
      const [key, value] = pair.split(":")?.map(str => str.trim())
      test_sequence[parseInt(key)] = parseInt(value)
    })

    const sortedExecutionData = [...data].sort((a, b) => {
      const sequenceA = test_sequence[a.id] || Number.POSITIVE_INFINITY
      const sequenceB = test_sequence[b.id] || Number.POSITIVE_INFINITY
      return sequenceA - sequenceB
    })
    return sortedExecutionData
  }

  useEffect(() => {
    if (selectedValue === "Manual") {
      if (isEditMode && isEditModeData?.test_case_id) {
        const selectedPreConditionIds = shuffleTestCase(
          isEditModeData.test_case_id
        )
        const selectedPreConditionValues = selectedPreConditionIds?.map(
          entry => {
            return {
              value: entry?.id,
              label: `${entry.test_case_id} - ${entry.test_case_name}`,
            }
          }
        )
        setCaseIdValues(selectedPreConditionValues)
      }
    } else {
      if (isEditMode && isEditModeData?.automation_suite_id) {
        const selectedPreConditionIds = shuffleTestSuite(
          isEditModeData.automation_suite_id
        )
        const selectedPreConditionValues = selectedPreConditionIds?.map(
          entry => {
            return {
              value: entry?.id,
              label: `${entry.automation_suite_id} - ${entry.automation_suite_name}`,
            }
          }
        )
        setSuiteIdValues(selectedPreConditionValues)
      }
    }
  }, [isEditModeData, selectedValue])

  const handleDeleteTestCase = id => {
    // Filter out the item to be deleted
    const updatedCaseIdValues = CaseIdValues.filter(item => item.value !== id)
    // Update the state with the new array
    setCaseIdValues(updatedCaseIdValues)
  }

  const handleDeleteTestSuite = id => {
    // Filter out the item to be deleted
    const updatedCaseIdValues = SuiteIdValues.filter(item => item.value !== id)
    // Update the state with the new array
    setSuiteIdValues(updatedCaseIdValues)
  }

  useEffect(() => {
    const fetchOptions = async () => {
      let payload = { id: null }
      try {
        const fetchDevice = await dispatch(FetchWeb(payload))
        const uniqueBrowserResponse = [
          ...new Set(fetchDevice?.web?.map(entry => entry?.browser)),
        ]?.map(value => ({
          value,
          label: value,
        }))

        const uniqueOsResponse = [
          ...new Set(fetchDevice?.web?.map(entry => entry?.browser_version)),
        ].map(value => ({
          value,
          label: value,
        }))

        setwebOptions(uniqueBrowserResponse)
        setwebOsOptions(uniqueOsResponse)
      } catch (error) {
        console.log(error)
      }
    }
    if (applicationType === "Web") {
      fetchOptions()
    }
  }, [applicationType, browserName])

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        let payload
        if (applicationType === "Android") {
          payload = {
            os_name: "android",
          }
        } else if (applicationType === "Ios") {
          payload = {
            os_name: "ios",
          }
        }
        const fetchDevice = await dispatch(FetchOs(payload))
        const fetchResponse = fetchDevice?.devices?.map(entry => ({
          value: entry?.device,
          label: entry?.device,
        }))
        const filterOsDevice = fetchDevice?.devices?.filter(
          device => device?.device === browserName
        )
        const fetchResponseVersion = filterOsDevice?.map(entry => ({
          value: entry?.os_version,
          label: entry?.os_version,
        }))
        setDeviceVersionOptions(fetchResponseVersion)
        setDeviceOsOptions(fetchResponse)
      } catch (error) {
        console.log(error)
      }
    }
    if (applicationType && applicationType !== "Web") {
      fetchOptions()
    }
  }, [applicationType, browserName])

  const breadcrumbItems = [
    { label: "Test Cycle", path: "/TestCycle" },
    {
      label: isEditMode ? "Edit Test Cycle " : "Create Test Cycle",
      path: "/",
    },
  ]

  const hanldeBrowserName = e => {
    setBrowserName(e ? e?.value : "")
    if (applicationType !== "Web") {
      setBrowserWeb(null)
    }
  }

  const [validationErrMsg, setValidationErrMsg] = useState("")

  const validationSchema = Yup.object().shape({
    cycle_name: Yup.string()
      .trim()
      .matches(/^.*\S.*$/, "Test Cycle Name cannot contain only spaces")
      .required("Test Cycle Name is required"),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      project_id: globalProject?.value,
      test_cycle_id: isEditModeData
        ? isEditModeData?.test_cycle_id
        : nextTestCycleId,
      build_id: isEditModeData ? isEditModeData?.build_id || null : null,
      cycle_status: isEditModeData
        ? isEditModeData?.cycle_status || "New"
        : "New",
      cycle_name: isEditModeData ? isEditModeData?.cycle_name || null : null,
      description: isEditModeData ? isEditModeData?.description || null : null,
      assign_to_id: isEditModeData
        ? isEditModeData.assign_to_id?.map(item => ({
            id: item.id,
            user_name: item.user_name,
          }))
        : null,
    },
    validationSchema,
    onSubmit: values => {
      if (startDate && endDate) {
        const validationErrorMsg = checkValidations()
        setValidationErrMsg(validationErrorMsg)
      }
      if (selectedValue === "Automation" && !applicationType) {
        const validationErrorMsg = checkValidations()
        setValidationErrMsg(validationErrorMsg)
      }
      if (
        applicationType &&
        applicationType !== "Web" &&
        (!browserName || !browserWeb || !browserApp)
      ) {
        const validationErrorMsg = checkValidations()
        setValidationErrMsg(validationErrorMsg)
      } else {
        let browserPayload
        if (applicationType === "Web") {
          browserPayload = "app_browser"
        } else {
          browserPayload = "device_name"
        }
        const formData = {
          ...values,
          description,
          start_date: startDate,
          end_date: endDate,
          cycle_type: selectedValue,
          assign_to_id:
            values.assign_to_id?.length !== 0
              ? values.assign_to_id?.map(item => item.id)
              : null,
          test_case_id:
            CaseIdValues?.length !== 0
              ? CaseIdValues?.filter(
                  id =>
                    id?.value !== null &&
                    id?.value !== undefined &&
                    id?.value !== ""
                )?.map(id => parseInt(id?.value))
              : null,
          automation_suite_id:
            SuiteIdValues?.length !== 0
              ? SuiteIdValues?.filter(
                  id =>
                    id?.value !== null &&
                    id?.value !== undefined &&
                    id?.value !== ""
                )?.map(id => parseInt(id?.value))
              : null,
          browser_stack_local: selectedValue === "Automation" ? isLocal : null,
          execution_agent: selectedValue === "Automation" ? agent : null,
          [browserPayload]: selectedValue === "Automation" ? browserName : null,
          version: selectedValue === "Automation" ? browserWeb : null,
          testcycle_type:
            selectedValue === "Automation" ? applicationType : null,
          browser_applications:
            selectedValue === "Automation" ? browserApp : null,
        }
        createTestScenario(formData)
      }
    },
  })

  const handleViewPage = async data => {
    if (
      data?.test_case_id?.length !== 0 ||
      data?.automation_suite_id?.length !== 0
    ) {
      const res = await dispatch(GetTestCycleByIdAPI(data?.id))
      localStorage.setItem("cycleId", res?.id)
      localStorage.setItem("TestCycleName", res?.test_cycle_id)
      dispatch(setViewTestCycle({ viewCycle: true, data: res }))
      if (data?.cycle_type === "Manual") {
        navigate("/TestCycle/ViewTestCycleManual")
      } else {
        navigate("/TestCycle/ViewTestCycleAutomation")
      }
    } else {
      navigate(-1)
    }
  }

  const handleAutomationCondition = () => {
    if (startDate && endDate) {
      const validationErrorMsg = checkValidations()
      setValidationErrMsg(validationErrorMsg)
    }
    if (selectedValue === "Automation") {
      if (!applicationType) {
        const validationErrorMsg = checkValidations()
        setValidationErrMsg(validationErrorMsg)
      } else if (
        applicationType !== "Web" &&
        (!browserName || !browserWeb || !browserApp)
      ) {
        const validationErrorMsg = checkValidations()
        setValidationErrMsg(validationErrorMsg)
      }
    }
  }

  const createTestScenario = async formData => {
    const editFromValues = {
      ...formData,
      id: isEditModeData?.id,
    }

    if (!isEditModeData) {
      try {
        setLoading(true)
        const data = await dispatch(CreateTestCycle(formData))
        if (data) {
          handleViewPage(data?.data)
        } else {
          navigate(-1)
        }
      } catch (error) {
        toast.error(
          error?.message || "An error occurred while creating Test Cycle",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          }
        )
      }
      setLoading(false)
    } else {
      try {
        setLoading(false)
        await dispatch(EditTestCycle(editFromValues))
        navigate(-1) // Navigate back or handle success
        dispatch(setCreateEditTestCycle({ isEdit: false, data: null }))
      } catch (error) {
        toast.error(
          error?.message || "An error occurred while updating Test Cycle",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          }
        )
      }
      setLoading(false)
    }
  }
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const responses = await Promise.all([
          dispatch(
            fetchAllBuildsList({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          dispatch(
            fetchNextCycleID({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          dispatch(
            TestCaseDetails({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          // dispatch(fetchUsersList({ project_id: globalProject?.value || selectedProject?.value })),
          dispatch(
            fetchAllTeamsDetailsUserAssigneeList({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          dispatch(
            FetchAllAgentsCreation({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
        ])

        const [
          buildResponse,
          nextIdResponse,
          testCaseResponse,
          userListResponse,
          agentResponse,
        ] = responses

        setBuildOptionValues(
          buildResponse?.map(entry => ({
            value: Math.round(entry.id),
            label: entry.build_id,
          }))
        )

        await setNextTestCycleId(nextIdResponse?.next_test_cycle_id)

        setAssignToValue(
          userListResponse?.results?.map(entry => ({
            value: entry.id,
            label: entry.user_name,
          }))
        )

        setCaseOptionValues(
          testCaseResponse
            ?.filter(entry => entry.test_status === "Approved")
            ?.map(entry => ({
              value: entry.id,
              label: `${entry.test_case_id} - ${entry.test_case_name}`,
            }))
        )

        const agentOptionsValue = [
          { value: 1, label: "Default" }, // Static option
          ...(agentResponse
            ? agentResponse.map(entry => ({
                value: entry.id,
                label: entry.agent_name,
              }))
            : []),
        ]
        setAgentValue(agentOptionsValue)
      } catch (error) {
        console.error("Failed to fetch options", error)
      }
    }
    fetchOptions()
  }, [globalProject, dispatch])

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await dispatch(
          AutomationSuiteDetails({ project_id: globalProject?.value })
        )

        // Check if the response is an array
        const responses = Array.isArray(res) ? res : res?.data || [] // Adjust based on how the data is returned

        // Filter and map the responses
        const fetchValue = responses
          ?.filter(entry => entry?.automation_suit_type === applicationType)
          ?.map(entry => ({
            value: entry.id,
            label: `${entry.automation_suite_id} - ${entry.automation_suite_name}`,
          }))

        setSuiteOptionValues(fetchValue || []) // Ensure you pass an array
      } catch (error) {
        console.error("Error fetching automation suite details:", error)
      }
    }

    fetch()
  }, [globalProject, dispatch, applicationType])

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const bodyOption = { project_id: globalProject?.value }
        const metaDataResponse = await dispatch(getTagsStautusAPI(bodyOption))

        const { cycle_status } = metaDataResponse?.data || {}
        setStatusOptionValues(createOptions(cycle_status || []))
      } catch (error) {
        console.log(error)
      }
    }

    fetchOptions()
  }, [])

  useEffect(() => {
    if (isEditModeData) {
      setDescription(isEditModeData.description || "")
    }
  }, [isEditModeData])

  useEffect(() => {
    const fetchValue = async () => {
      const uploadAppResponse = await dispatch(
        FetchAllUploadApp({
          project_id: globalProject?.value || selectedProject?.value,
        })
      )

      const fetchData = uploadAppResponse
        ?.filter(entry => entry?.app_type === applicationType)
        ?.map(entry => ({
          value: entry.id,
          label: entry?.application_name,
        }))
      setAppUploadOptions(fetchData)
    }
    if (applicationType !== "Web") {
      fetchValue()
    }
  }, [globalProject, dispatch, applicationType])

  const handleApplicationType = e => {
    setApplicationType(e ? e?.value : null)
    setBrowserName(null)
    setBrowserWeb(null)
  }

  const formatDate = date => {
    if (!date) return ""
    const [year, month, day] = [
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate(),
    ]
    return `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Breadcrumb title="Test Cycle" breadcrumbItems={breadcrumbItems} />

        <div className="mt-2 ms-1 d-flex justify-content-between">
          <div>
            <h3>{!isEditMode ? "Create New Test Cycle" : "Edit Test Cycle"}</h3>
            <p className="text-muted">
              Fill the form to {!isEditMode ? "create" : "edit"} a test cycle
            </p>
          </div>
          <div>
            <Button
              type="button"
              color="primary"
              className="btn-label"
              onClick={() => {
                navigate(-1)
                dispatch(setCreateEditTestCycle({ isEdit: false, data: null }))
              }}
            >
              <i className="bx bx-arrow-back label-icon"></i> Go Back
            </Button>
          </div>
        </div>

        <Col>
          <Card>
            <CardBody className={layoutModeType === "dark" ? "dark-mode" : ""}>
              <div className="d-flex mb-0 gap-2">
                <h5 className="font-size-14">
                  Cycle Type <span className="text-danger">*</span>
                </h5>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    value="Manual"
                    defaultChecked={selectedValue === "Manual"}
                    disabled={isEditMode}
                    onChange={handleRadioChange}
                  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    Manual
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios2"
                    disabled={isEditMode}
                    value="Automation"
                    defaultChecked={selectedValue === "Automation"}
                    onChange={handleRadioChange}
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    Automation
                  </label>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Form onSubmit={formik.handleSubmit}>
          {selectedValue === "Manual" ? (
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <div className="mb-3">
                      <Label className="form-label">Project</Label>
                      <Input
                        name="project_id"
                        type="text"
                        placeholder="Project"
                        value={globalProject?.label}
                        className="custom-input"
                        readOnly
                        disabled
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Test Cycle Id</Label>
                      <Input
                        name="test_cycle_id"
                        type="text"
                        placeholder="Test Cycle Id"
                        value={formik.values.test_cycle_id}
                        className="custom-input"
                        readOnly
                        disabled
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">
                        Test Cycle Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="cycle_name"
                        type="text"
                        placeholder="Test Cycle Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.cycle_name}
                        invalid={
                          formik.touched.cycle_name &&
                          !!formik.errors.cycle_name
                        }
                        className="custom-input"
                      />
                      {formik.touched.cycle_name &&
                        formik.errors.cycle_name && (
                          <FormFeedback>
                            {formik.errors.cycle_name}
                          </FormFeedback>
                        )}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Status</Label>
                      <Select
                        styles={customStyles}
                        // isClearable={true}
                        name="cycle_status"
                        className="select2-selection"
                        options={statusOptionValue}
                        onChange={option =>
                          formik.setFieldValue(
                            "cycle_status",
                            option?.value || "New"
                          )
                        }
                        onBlur={() => formik.setFieldTouched("cycle_status")}
                        value={statusOptionValue?.find(
                          option => option.value === formik.values.cycle_status
                        )}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <div className="mb-3">
                      <Label className="form-label">Build</Label>
                      <Select
                        styles={customStyles}
                        name="build_id"
                        isClearable={true}
                        className="select2-selection"
                        options={buildOptionValue}
                        onChange={option =>
                          formik.setFieldValue("build_id", option?.value || "")
                        }
                        onBlur={() => formik.setFieldTouched("build_id")}
                        value={buildOptionValue?.find(
                          option => option.value === formik.values.build_id
                        )}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Start Date</Label>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="dd/mm/yyyy"
                          options={{
                            altInput: true,
                            altFormat: "d/m/Y",
                            dateFormat: "Y-m-d",
                          }}
                          value={startDate}
                          onChange={date => {
                            const formattedDate = formatDate(date[0])
                            setStartDate(formattedDate)
                          }}
                        />
                      </InputGroup>
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">End Date</Label>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="dd/mm/yyyy"
                          options={{
                            altInput: true,
                            altFormat: "d/m/Y",
                            dateFormat: "Y-m-d",
                          }}
                          value={endDate}
                          onChange={date => {
                            const formattedDate = formatDate(date[0])
                            setEndDate(formattedDate)
                          }}
                        />
                      </InputGroup>
                      {validationErrMsg.endDate && (
                        <span className="text-danger required-field">
                          {validationErrMsg.endDate}
                        </span>
                      )}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Assign To</Label>
                      <Select
                        styles={customStyles}
                        name="assign_to_id"
                        options={assignToValue}
                        isMulti
                        closeMenuOnSelect={false}
                        onChange={options => {
                          // Convert selected options to the format needed by Formik
                          formik.setFieldValue(
                            "assign_to_id",
                            options
                              ? options?.map(option => ({
                                  id: option.value,
                                  user_name: option.label,
                                }))
                              : []
                          )
                        }}
                        onBlur={() =>
                          formik.setFieldTouched("assign_to_id", true)
                        }
                        value={assignToValue?.filter(option =>
                          formik.values.assign_to_id?.some(
                            selected => selected.id === option.value
                          )
                        )}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12} className="mb-3">
                <Card>
                  <CardBody>
                    <Col md="12">
                      <Label>Description</Label>
                      <JoditEditor
                        value={description}
                        config={joditConfig()}
                        onBlur={newContent => setDescription(newContent)}
                      // onBlur={() => setIsDescriptionInvalid(!description)}
                      />
                      {/* {isDescriptionInvalid && (
                        <FormFeedback className="d-block">
                          Description is required
                        </FormFeedback>
                      )} */}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
              <UncontrolledAccordion defaultOpen="1">
                <AccordionItem>
                  <AccordionHeader targetId="1">
                    <strong>ADD TEST CASES</strong>
                  </AccordionHeader>
                  <AccordionBody accordionId="1" targetId="1">
                    <CardBody>
                      <div
                        className="mb-3 col-12 col-md-5"
                        title="Follow sequential order"
                      >
                        <Label className="form-label">Test Case ID</Label>
                        <Select
                          styles={customStyles}
                          value={CaseIdValues}
                          isMulti
                          closeMenuOnSelect={false}
                          label="Multi select"
                          onChange={handleCaseChange}
                          options={CaseOptionValue}
                        />
                      </div>

                      {CaseIdValues && CaseIdValues.length > 0 ? (
                        <ul className="list-unstyled">
                          {CaseIdValues.map(item => (
                            <li
                              className="d-flex justify-content-between align-items-center mb-2"
                              key={item.value}
                            >
                              <Label className="mb-0">{item?.label}</Label>
                              <div
                                className="ms-3 text-danger"
                                style={{ cursor: "pointer" }}
                                title="Remove"
                                onClick={() => handleDeleteTestCase(item.value)}
                              >
                                <i className="mdi mdi-delete-outline" />
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </CardBody>
                  </AccordionBody>
                </AccordionItem>
              </UncontrolledAccordion>
            </Row>
          ) : (
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <div className="mb-3">
                      <Label className="form-label">Project</Label>
                      <Input
                        name="project_id"
                        type="text"
                        placeholder="Project"
                        value={globalProject?.label}
                        className="custom-input"
                        readOnly
                        disabled
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Test Cycle Id</Label>
                      <Input
                        name="test_cycle_id"
                        type="text"
                        placeholder="Test Cycle Id"
                        value={formik.values.test_cycle_id}
                        className="custom-input"
                        readOnly
                        disabled
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">
                        Test Cycle Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="cycle_name"
                        type="text"
                        placeholder="Test Cycle Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.cycle_name}
                        invalid={
                          formik.touched.cycle_name &&
                          !!formik.errors.cycle_name
                        }
                        className="custom-input"
                      />
                      {formik.touched.cycle_name &&
                        formik.errors.cycle_name && (
                          <FormFeedback>
                            {formik.errors.cycle_name}
                          </FormFeedback>
                        )}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Status</Label>
                      <Select
                        styles={customStyles}
                        // isClearable={true}
                        name="cycle_status"
                        className="select2-selection"
                        options={statusOptionValue}
                        onChange={option =>
                          formik.setFieldValue("cycle_status", option?.value)
                        }
                        onBlur={() => formik.setFieldTouched("cycle_status")}
                        value={statusOptionValue?.find(
                          option => option.value === formik.values.cycle_status
                        )}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Build</Label>
                      <Select
                        styles={customStyles}
                        isClearable={true}
                        name="build_id"
                        className="select2-selection"
                        options={buildOptionValue}
                        onChange={option =>
                          formik.setFieldValue("build_id", option?.value || "")
                        }
                        onBlur={() => formik.setFieldTouched("build_id")}
                        value={buildOptionValue?.find(
                          option => option.value === formik.values.build_id
                        )}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Start Date</Label>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="dd/mm/yyyy"
                          options={{
                            altInput: true,
                            altFormat: "d/m/Y",
                            dateFormat: "Y-m-d",
                          }}
                          value={startDate}
                          onChange={date => {
                            const formattedDate = formatDate(date[0])
                            setStartDate(formattedDate)
                          }}
                        />
                      </InputGroup>
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">End Date</Label>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="dd/mm/yyyy"
                          options={{
                            altInput: true,
                            altFormat: "d/m/Y",
                            dateFormat: "Y-m-d",
                          }}
                          value={endDate}
                          onChange={date => {
                            const formattedDate = formatDate(date[0])
                            setEndDate(formattedDate)
                          }}
                        />
                      </InputGroup>
                      {validationErrMsg.endDate && (
                        <span className="text-danger required-field">
                          {validationErrMsg.endDate}
                        </span>
                      )}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Assign To</Label>
                      <Select
                        styles={customStyles}
                        name="assign_to_id"
                        options={assignToValue}
                        isMulti
                        closeMenuOnSelect={false}
                        onChange={options => {
                          // Convert selected options to the format needed by Formik
                          formik.setFieldValue(
                            "assign_to_id",
                            options
                              ? options?.map(option => ({
                                  id: option.value,
                                  user_name: option.label,
                                }))
                              : []
                          )
                        }}
                        onBlur={() =>
                          formik.setFieldTouched("assign_to_id", true)
                        }
                        value={assignToValue?.filter(option =>
                          formik.values.assign_to_id?.some(
                            selected => selected.id === option.value
                          )
                        )}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <div className="mb-3">
                      <Label className="form-label">
                        Application Type <span className="text-danger">*</span>
                      </Label>
                      <Select
                        styles={customStyles}
                        name="testcycle_type"
                        className="select2-selection"
                        isDisabled={isEditMode ? true : false}
                        options={applicationTypeOptions}
                        value={
                          applicationType
                            ? { label: applicationType, value: applicationType }
                            : ""
                        }
                        onChange={e => handleApplicationType(e)}
                      />
                      {validationErrMsg.applicationType && (
                        <span className="text-danger required-field">
                          {validationErrMsg.applicationType}
                        </span>
                      )}
                    </div>
                    {!browserStack && (
                      <>
                        <label className="text-primary">
                          Make sure you configure the cloud device farm{" "}
                        </label>
                        <label className="text-danger">*</label>
                      </>
                    )}
                    {browserStack && (
                      <>
                        {browserStack &&
                          applicationType &&
                          applicationType !== "Web" && (
                            <div className="mb-3 d-flex flex-column">
                              <Label className="form-label">
                                Application Name{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Select
                                styles={customStyles}
                                name="build_id"
                                className="select2-selection"
                                options={appUploadOptions}
                                value={
                                  appUploadOptions?.find(
                                    option => option.value === browserApp
                                  ) || null
                                }
                                onChange={e =>
                                  setBrowserApp(e ? e?.value : null)
                                }
                              />
                              {validationErrMsg.browserApp && (
                                <span className="text-danger required-field">
                                  {validationErrMsg.browserApp}
                                </span>
                              )}
                              <Label
                                className="form-label mt-2 text-primary"
                                style={{ cursor: "pointer" }}
                                title="Click here to upload app"
                                onClick={handleUploadApp}
                              >
                                + Upload App
                              </Label>
                            </div>
                          )}

                        {applicationType && applicationType !== "Web" ? (
                          <div className="mb-3">
                            <Label className="form-label">
                              Browserstack Device Name{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              styles={customStyles}
                              name="build_id"
                              className="select2-selection"
                              options={deviceOsOptions}
                              value={
                                browserName
                                  ? { label: browserName, value: browserName }
                                  : null
                              }
                              onChange={e => hanldeBrowserName(e)}
                            />
                            {validationErrMsg.browserName && (
                              <span className="text-danger required-field">
                                {validationErrMsg.browserName}
                              </span>
                            )}
                          </div>
                        ) : (
                          <div className="mb-3">
                            <Label className="form-label">
                              Browserstack Browser
                            </Label>
                            <Select
                              isClearable={true}
                              styles={customStyles}
                              name="build_id"
                              className="select2-selection"
                              options={webOptions}
                              value={
                                browserName
                                  ? { label: browserName, value: browserName }
                                  : null
                              }
                              onChange={e => hanldeBrowserName(e)}
                            />
                          </div>
                        )}
                        {applicationType && applicationType !== "Web" ? (
                          <div className="mb-3">
                            <Label className="form-label">
                              Browserstack Os Version{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              styles={customStyles}
                              name="build_id"
                              className="select2-selection"
                              options={deviceVersionOptions}
                              value={
                                browserWeb
                                  ? { label: browserWeb, value: browserWeb }
                                  : null
                              }
                              onChange={e => setBrowserWeb(e ? e?.value : null)}
                            />
                            {validationErrMsg.browserWeb && (
                              <span className="text-danger required-field">
                                {validationErrMsg.browserWeb}
                              </span>
                            )}
                          </div>
                        ) : (
                          <div className="mb-3">
                            <Label className="form-label">
                              Browserstack Browser Version
                            </Label>
                            <Select
                              styles={customStyles}
                              isClearable={true}
                              name="build_id"
                              className="select2-selection"
                              options={webOsOptions}
                              value={
                                browserWeb
                                  ? { label: browserWeb, value: browserWeb }
                                  : null
                              }
                              onChange={e => setBrowserWeb(e ? e?.value : "")}
                            />
                          </div>
                        )}
                      </>
                    )}

                    {browserStack && gitHub && (
                      <div className="mb-1">
                        <Label className="form-label">
                          Browser Stack Local{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <div className="square-switch">
                          <input
                            type="checkbox"
                            id="square-switch3"
                            className="switch switch-bool"
                            defaultChecked={isLocal}
                            onChange={() => setIsLocal(!isLocal)}
                          />
                          <label
                            htmlFor="square-switch3"
                            data-on-label="Yes"
                            data-off-label="No"
                          />
                        </div>
                      </div>
                    )}

                    {(browserStack || gitHub) && (
                      <div className="mb-3">
                        <Label className="form-label">
                          Execution Agent <span className="text-danger">*</span>
                        </Label>
                        <Select
                          styles={customStyles}
                          name="build_id"
                          className="select2-selection"
                          options={agentValue}
                          value={agentValue?.find(
                            option => option.value === agent
                          )}
                          onChange={e => setAgent(e ? e?.value : null)}
                        />
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12} className="mb-1">
                <Card>
                  <CardBody>
                    <Col md="12">
                      <Label>Description</Label>
                      <JoditEditor
                        value={description}
                        config={joditConfig()}
                        onBlur={newContent => setDescription(newContent)}
                      // onBlur={() => setIsDescriptionInvalid(!description)}
                      />
                      {/* {isDescriptionInvalid && (
                        <FormFeedback className="d-block">
                          Description is required
                        </FormFeedback>
                      )} */}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
              <UncontrolledAccordion defaultOpen="1">
                <AccordionItem>
                  <AccordionHeader targetId="1">
                    <strong>ADD SUITES</strong>
                  </AccordionHeader>
                  <AccordionBody accordionId="1" targetId="1">
                    <CardBody>
                      <div className="mb-3 col-5">
                        <Label className="form-label">Suite ID</Label>
                        <Select
                          styles={customStyles}
                          name="assign_to_id"
                          value={SuiteIdValues}
                          isMulti
                          closeMenuOnSelect={false}
                          label="Multi select"
                          onChange={handleSuiteChange}
                          options={SuiteOptionValue}
                        />
                      </div>
                      {SuiteIdValues && SuiteIdValues.length > 0 ? (
                        <ul>
                          {SuiteIdValues?.map(item => (
                            <Col
                              md="12"
                              className="mb-2 d-flex"
                              key={item.value}
                            >
                              <Label>
                                <li>{item?.label}</li>
                              </Label>
                              <div
                                className="ms-3 text-danger"
                                style={{ cursor: "pointer" }}
                                title="Remove"
                                onClick={() =>
                                  handleDeleteTestSuite(item.value)
                                }
                              >
                                <i className="mdi mdi-delete-outline" />
                              </div>
                            </Col>
                          ))}
                        </ul>
                      ) : null}
                    </CardBody>
                  </AccordionBody>
                </AccordionItem>
              </UncontrolledAccordion>
            </Row>
          )}
          <Row>
            <Col md="12" className="text-end mt-3">
              <Button
                color="primary"
                type="submit"
                onClick={handleAutomationCondition}
                disabled={loading}
              >
                {loading ? "Loading..." : "Submit"}
              </Button>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                  formik.resetForm()
                  navigate(-1)
                  dispatch(
                    setCreateEditTestCycle({ isEdit: false, data: null })
                  )
                }}
                className="ms-2"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </React.Fragment>
  )
}

export default CreateEditTestCycle
