import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import NoDataChart from '../../../components/Common/NoDataChart';

const RequirementBreakdownChart = () => {
    const globalDashboardRequirementBreakdownData = useSelector(
        state => state?.Dashboard?.GlobalDashboardRequirementBreakDown?.requirement_details || []
    );

    const [loading, setLoading] = useState(true);
    const [chartData, setChartData] = useState([]);
    const [maxYValue, setMaxYValue] = useState(10); // Default max y-axis value
    const [xCategories, setXCategories] = useState([]); // X-axis categories

    // Effect to handle data loading
    useEffect(() => {
        if (globalDashboardRequirementBreakdownData.length > 0) {
            const updatedChartData = globalDashboardRequirementBreakdownData.map((item, index) => {
                const [key, value] = Object.entries(item)[0]; // Get the key (e.g., 'Functional') and its array
                const name = key === 'Undefined' ? 'Other' : key; // Rename "Undefined" to "Other"
                
                return {
                    name, // Use the renamed key as the series name
                    data: [{ x: index + 1, y: value.length, z: value.length }] // Assign unique x value (index + 1)
                };
            });

            // Extract the requirement names for custom x-axis labels
            const categories = updatedChartData.map(data => data.name);

            // Only update chartData if it has changed to avoid re-renders
            if (JSON.stringify(chartData) !== JSON.stringify(updatedChartData)) {
                setChartData(updatedChartData);
                setXCategories(categories); // Update x-axis categories (requirement names)
                // Dynamically set the max y-axis value with some buffer for better bubble visibility
                const maxY = Math.max(...updatedChartData.map(data => data.data[0].y)) + 5;
                setMaxYValue(maxY);
            }
            setLoading(false);
        } else {
            setLoading(false);
        }
    }, [globalDashboardRequirementBreakdownData, chartData]);

    const chartOptions = {
        chart: {
            height: 350,
            type: 'bubble',
            toolbar: {
                show: false // Disable the toolbar with zoom, pan, and download
            },
            zoom: {
                enabled: false // Disable zoom
            }
        },
        dataLabels: {
            enabled: false
        },
        title: {
            text: ' ',
        },
        xaxis: {
            categories: xCategories, // Use custom categories for x-axis (requirement names)
            labels: {
                show: false, // Show the x-axis labels
                rotate: -45, // Rotate labels for better readability
                style: {
                    fontSize: '12px',
                    fontWeight: 'bold',
                    colors: '#333'
                }
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            title: {
                text: 'Requirement Types', // Set the x-axis label
                style: {
                    fontSize: '12px',  // Customize the font size
                    fontWeight: 'bold', // Make the label bold
                    color: '#333'       // Customize the label color
                }
            },
            // Add padding on both ends of the x-axis
            min: 0,  // Set a minimum to create padding on the left
            max: xCategories.length + 1, // Extend max to create padding on the right
        },
        yaxis: {
            labels: {
                show: false // Hide the y-axis labels
            },
            title: {
                show: false // Hide the y-axis title
            },
            axisBorder: {
                show: false // Hide the y-axis border
            },
            axisTicks: {
                show: false // Hide the y-axis ticks
            },
            max: maxYValue, // Dynamically set the max y value based on data with buffer
        },
        grid: {
            yaxis: {
                lines: {
                    show: false // Hide the y-axis grid lines
                }
            }
        },
        tooltip: {
            enabled: true,
            custom: function({ series, seriesIndex, dataPointIndex }) {
                const label = chartData[seriesIndex].name;  // Get the series name
                const value = chartData[seriesIndex].data[dataPointIndex].y;  // Get the corresponding value
                const color = chartOptions.colors[seriesIndex];  // Get the color for the series

                return `
                    <div style="display: flex; align-items: center; margin: 5px;">
                        <div style="width: 10px; height: 10px; background-color: ${color}; border-radius: 50%; margin-right: 5px;"></div>
                        <div>${label}: ${value}</div>
                    </div>
                `;
            }
        },
        plotOptions: {
            bubble: {
                minBubbleRadius: 10, // Minimum bubble radius
                maxBubbleRadius: 70, // Maximum bubble radius based on z value (count)
            }
        },
        colors: ['#FF4560', '#00E396', '#008FFB', '#FEB019'], // Colors for bubbles
        legend: {
            position: 'top',
            horizontalAlign: 'center'
        }
    };

    if (loading) {
        return <div>Loading chart data...</div>;
    }

    if (globalDashboardRequirementBreakdownData.length === 0) {
        return <NoDataChart />;
    }

    return (
        <div>
            <Chart 
                options={chartOptions} 
                series={chartData} 
                type="bubble" 
                height={350} 
            />
        </div>
    );  
};

export default RequirementBreakdownChart;
