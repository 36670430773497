import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
  Form,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import Breadcrumb from "components/Common/Breadcrumb"
import "../../Roles/Create Roles/CreateTestScenarioForm.scss"
// import { Link } from "react-router-dom";
// import PermissionComponent from "./PermissionComponent";
import Select from "react-select"
import { setEditIntegration, setViewIntegrationforCustom } from "store/Slices/IntegrationSlice"
import { deleteMapAPI, editIntegrationApi, getAllMapProject } from "store/actions/IntegrationAction"
import { LuLink, LuUpload } from "react-icons/lu";
import { IoEyeOutline } from "react-icons/io5";
import { RiDeleteBinLine } from "react-icons/ri";
import { FiEdit2 } from "react-icons/fi";
import MapProject from "../Map Project/MapProject"
// import DeleteModal from "components/Common/DeleteModal"
import ViewBasicMapProject from "../Map Project/ViewBasicMapProject"
import ViewCustomMapProject from "../Map Project/ViewCustomMapProject"
import DeleteMapModal from "components/Common/DeleteMapModal"


const EditIntegration = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const editRef = useRef(null);
  const loading = useSelector(state => state?.Loading?.isLoading)

  const editUsers = useSelector(state => state?.Integration?.editIntegration)

  const [validationErrMsg, setValidationErrMsg] = useState("");
  const [mapProjectList, setMapProjectList] = useState([]);

  const isEditMode = editUsers?.isEdit
  const isEditModeData = editUsers?.data

  const fetchMapData = async () => {
    let bodyoption = {
      intergration_id: isEditModeData?.id,
    }
    try {
      const data = await dispatch(getAllMapProject(bodyoption))
      setMapProjectList(data)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  useEffect(() => {
    fetchMapData();
    if (!isEditModeData) {
      navigate(-1)
    }
  }, [])

  const [product, setProduct] = useState(
    isEditMode ? isEditModeData?.product || "" : ""
  )
  const [jiraEmail, setJiraEmail] = useState(
    isEditMode ? product === "BrowserStack" ? isEditModeData?.username : isEditModeData?.email : ""
  );
  const [jiratoken, setJiraToken] = useState(
    isEditMode ? isEditModeData?.token : ""
  );
  const [jiraUrl, setJiraUrl] = useState(
    isEditMode ? isEditModeData?.url : ""
  );

  const productOptions = [
    { value: "Jira", label: "Jira" },
    { value: "BrowserStack", label: "Browser Stack" },
    { value: "GitHub", label: "GitHub" },
    { value: "Plane", label: "Plane" },
    // { value: "GitLab", label: "GitLab" },
  ];

  const breadcrumbItems = [
    { label: "Settings", path: "/Settings/Integration" },
    {
      label: isEditMode ? "Edit Integration " : "Create Integration",
      path: "/",
    },
  ]

  const checkValidations = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (product === "Jira") {
      if (!jiraEmail) {
        errors.jiraEmail = "Jira Email required";
      } else if (!emailRegex.test(jiraEmail)) {
        errors.jiraEmail = "Valid Jira Email required";
      }
      if (!jiratoken) errors.jiratoken = "Jira Token required";
      if (!jiraUrl) {
        errors.jiraUrl = "Jira URL required";
      }
      else if (!/^https?:\/\/|^$/.test(jiraUrl)) {
        errors.jiraUrl = "Valid Jira Email required";
      }
    } else if (product === "Plane") {
      if (jiraEmail?.length < 1) {
        errors.jiraEmail = "Plane Email required";
      } else if (!emailRegex.test(jiraEmail)) {
        errors.jiraEmail = "Valid Plane Email required";
      }
      if (jiratoken?.length < 1) errors.jiratoken = "Plane Token required";
      if (jiraUrl?.length < 1) {
        errors.jiraUrl = "Plane URL required";
      } else if (!/^https?:\/\/|^$/.test(jiraUrl)) {
        errors.jiraUrl = "Valid Plane URL required";
      }
    } else if (product === "GitHub") {
      if (jiratoken?.length < 1) errors.jiratoken = "GitHub Access token is required";
    } else if (product === "BrowserStack") {
      if (!jiraEmail) { errors.jiraEmail = "Browser Stack Username is required"; }
      else if (emailRegex.test(jiraEmail)) {
        errors.jiraEmail = "Browser Stack Username is required";
      }
      if (!jiratoken) errors.jiratoken = "Browser Stack Access key is required";
    }
    return errors;
  };

  const validationSchema = Yup.object().shape({
    product: Yup.string().required("Product is required"),
  })


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      product: isEditModeData ? isEditModeData?.product || "" : ""
    },
    validationSchema,
  })

  const [jiraMapList, setJiraMapList] = useState([]);
  const [gitHubMapList, setGitHubMapList] = useState([]);
  const [planeMapList, setPlaneMapList] = useState([]);

  useEffect(() => {
    const jiraList = mapProjectList?.filter(
      (project) => project.product === "Jira"
    );
    setJiraMapList(jiraList);

    const gitList = mapProjectList?.filter(
      (project) => project.product === "GitHub"
    );
    setGitHubMapList(gitList);

    const planeList = mapProjectList?.filter(
      (project) => project.product === "Plane"
    );
    setPlaneMapList(planeList);
  }, [mapProjectList]);


  const handleSubmit = () => {

    let bodyoptions;
    const values = formik.values;

    if (product === "Jira") {
      bodyoptions = {
        ...values,
        id: isEditModeData?.id,
        email:
          jiraEmail !== isEditModeData?.email
            ? jiraEmail
            : undefined,
        token:
          jiratoken !== isEditModeData?.token
            ? jiratoken
            : undefined,
        url:
          jiraUrl !== isEditModeData?.url ? jiraUrl : undefined,
        is_delete: isEditModeData?.is_delete,
        created_at: isEditModeData?.created_at,
      };
    } else if (product === "GitHub") {
      bodyoptions = {
        ...values,
        id: isEditModeData?.id,
        token:
          jiratoken !== isEditModeData?.token
            ? jiratoken
            : undefined,
        url:
          jiraUrl !== isEditModeData?.url ? jiraUrl : undefined,
        is_delete: isEditModeData?.is_delete,
        created_at: isEditModeData?.created_at,
      };
    } else if (product === "Plane") {
      bodyoptions = {
        ...values,
        id: isEditModeData?.id,
        email:
          jiraEmail !== isEditModeData?.email
            ? jiraEmail
            : undefined,
        token:
          jiratoken !== isEditModeData?.token
            ? jiratoken
            : undefined,
        url:
          jiraUrl !== isEditModeData?.url ? jiraUrl : undefined,
        is_delete: isEditModeData?.is_delete,
        created_at: isEditModeData?.created_at,
      };
    } else if (product === "BrowserStack") {
      bodyoptions = {
        ...values,
        id: isEditModeData?.id,
        username:
          jiraEmail !== isEditModeData?.email
            ? jiraEmail
            : undefined,
        token:
          jiratoken !== isEditModeData?.token
            ? jiratoken
            : undefined,
        url:
          jiraUrl !== isEditModeData?.url ? jiraUrl : undefined,
        is_delete: isEditModeData?.is_delete,
        created_at: isEditModeData?.created_at,
      };
    }
    createEditRoles(bodyoptions)
  }


  const handleUploadApp = () => {
    navigate("/TestAutomation/UploadNewApp")
  }

  const createEditRoles = async formData => {

    const validationErrorMsg = checkValidations();
    setValidationErrMsg(validationErrorMsg);

    if (Object.keys(validationErrorMsg)?.length === 0) {
      try {
        await dispatch(editIntegrationApi(formData))
        navigate(-1)
      } catch (error) {
        console.log(error);

        toast.error(error?.message || "An error occurred while creating ", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
      }
    }
  }


  const handleProductChange = (option) => {
    formik.setFieldValue("product", option?.value);
    setProduct(option?.value);
  };

  const [isFilterFormOpen, setIsFilterFormOpen] = useState(false)
  const [isViewFormOpen, setIsViewFormOpen] = useState(false)
  const [isEditFormOpen, setIsEditFormOpen] = useState(false)


  const handleFilterClick = () => {
    setIsFilterFormOpen(!isFilterFormOpen)
  }

  const handleViewClick = () => {
    setIsViewFormOpen(!isViewFormOpen)
  }

  const handleEditClick = (data) => {
    dispatch(
      setViewIntegrationforCustom({
        clicked: true,
        editmode: true,
        data: data
      })
    );
    setIsEditFormOpen(!isEditFormOpen)
  }

  const [deleteModal, setDeleteModal] = useState(false)
  const [isDeleteId, setIsDeleteId] = useState("")

  const onClickDelete = userData => {
    setIsDeleteId(userData?.id)
    setDeleteModal(true)
  }

  const handleDelete = async () => {
    try {
      await dispatch(deleteMapAPI(isDeleteId))
      setDeleteModal(false)
      fetchMapData()
      setIsDeleteId("")
    } catch (error) {
      toast.error(error?.message || "An error occurred while deleting ", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      })
    }
  }

  const handleClose = () => {
    setIsFilterFormOpen(false)
  }
  const handleCloseView = () => {
    setIsViewFormOpen(false)
  }
  const handleCloseEdit = () => {
    setIsEditFormOpen(false)
  }

  const layoutModeType = useSelector(state => state.Layout.layoutModeType)

  const customStyles = {
    control: provided => ({
      ...provided,
      maxHeight: "150px",
      overflowY: "auto",
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#f8f8fb",
      color: layoutModeType === "dark" ? "#f8f8fb" : "#000",
      borderColor: layoutModeType === "dark" ? "#444" : "#ddd",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#f8f8fb",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#f8f8fb" : "#2a3042",
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150, // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }

  return (
    <React.Fragment>
      <DeleteMapModal
        show={deleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
        message="mapped Project"
        product={product}
      />
      <MapProject
        isFilterFormOpen={isFilterFormOpen}
        toggle={handleFilterClick}
        product={product}
        mapId={isEditModeData?.id}
        handleClose={handleClose}
        fetchMapData={fetchMapData}
      />
      <ViewBasicMapProject
        isFilterFormOpen={isViewFormOpen}
        toggle={handleViewClick}
        product={product}
        handleClose={handleCloseView}
      />
      <ViewCustomMapProject
        isEditFormOpen={isEditFormOpen}
        toggle={handleEditClick}
        product={product}
        handleClose={handleCloseEdit}
        fetchMapData={fetchMapData}
      />
      <Container fluid>
        <Breadcrumb title="Roles" breadcrumbItems={breadcrumbItems} />

        <div className="mt-2 mx-1 col-12 d-flex justify-content-between">
          <div>
            <h3>{!isEditMode ? "Create Integration" : "Edit Integration"}</h3>
            <p className="text-muted">
              Fill the form to {!isEditMode ? "create" : "edit"} a integration
            </p>
          </div>
          <div>
            <Button
              type="button"
              color="primary"
              onClick={() => {
                navigate("/Settings/Integration")
                dispatch(setEditIntegration({ isEdit: false, data: null }))
              }}
            >
              Go Back
            </Button>
          </div>
        </div>
        <Form>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {product === "GitHub" && <div>
                    <Label>Make sure your GitHub Access Token has the following permissions<span className="text-danger">*</span></Label>
                <ol>
                    <li>admin:org</li>
                    <li>admin:repo_hook</li>
                    <li>repo</li>
                    <li>workflow</li>
                </ol></div>}
                  <div className="mb-3 col-12 d-flex flex-column flex-md-row gap-3">
                    <div className="col-12 col-md-5">
                      <Label className="form-label">
                        Product<span className="text-danger">*</span>
                      </Label>
                      <Select
                        isClearable={true}
                        name="product"
                        isDisabled={true}
                        options={productOptions}
                        onChange={option => handleProductChange(option)}
                        onBlur={() => formik.setFieldTouched("product")}
                        value={productOptions.find(
                          option => option.value === formik.values.product
                        )}
                        className="select2-selection"
                        styles={customStyles}
                      />
                      {formik.touched.product && formik.errors.product && (
                        <div className="text-danger required-field">{formik.errors.product}</div>
                      )}
                    </div>

                    {product === "Jira" && (
                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          Jira Email<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          placeholder="Jira Email"
                          onChange={(e) => setJiraEmail(e.target.value)}
                          onBlur={(e) => setJiraEmail(e.target.value)}
                          value={jiraEmail}
                          className="custom-input"
                        />
                        {validationErrMsg?.jiraEmail && (
                          <span className="text-danger required-field">{validationErrMsg?.jiraEmail}</span>
                        )}
                      </div>
                    )}

                    {product === "BrowserStack" && (
                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          Browser Stack Username<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          placeholder="Browser Stack Username"
                          onChange={(e) => setJiraEmail(e.target.value)}
                          onBlur={(e) => setJiraEmail(e.target.value)}
                          value={jiraEmail}
                          className="custom-input"
                        />
                        {validationErrMsg?.jiraEmail && (
                          <span className="text-danger required-field">{validationErrMsg?.jiraEmail}</span>
                        )}
                      </div>
                    )}

                    {product === "GitHub" && (
                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          GitHub Access Token<span className="text-danger">*</span>

                        </Label>
                        <Input
                          type="password"
                          placeholder="GitHub Access Token"
                          onChange={(e) => setJiraToken(e.target.value)}
                          onBlur={(e) => setJiraToken(e.target.value)}
                          value={jiratoken}
                          className="custom-input"
                        />
                        {validationErrMsg?.jiratoken && (
                          <span className="text-danger required-field">{validationErrMsg?.jiratoken}</span>
                        )}
                      </div>
                    )}

                    {product === "Plane" && (
                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          Plane Email<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          placeholder="Plane Email"
                          onChange={(e) => setJiraEmail(e.target.value)}
                          onBlur={(e) => setJiraEmail(e.target.value)}
                          value={jiraEmail}
                          className="custom-input"
                        />
                        {validationErrMsg?.jiraEmail && (
                          <span className="text-danger required-field">{validationErrMsg?.jiraEmail}</span>
                        )}
                      </div>
                    )}
                  </div>

                  {product === "Jira" && (
                    <div className="mb-3 col-12 d-flex flex-column flex-md-row gap-3">
                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          Jira Token<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="password"
                          placeholder="Jira Token"
                          onChange={(e) => setJiraToken(e.target.value)}
                          onBlur={(e) => setJiraToken(e.target.value)}
                          value={jiratoken}
                          className="custom-input"
                        />
                        {validationErrMsg?.jiratoken && (
                          <span className="text-danger required-field">{validationErrMsg?.jiratoken}</span>
                        )}
                      </div>

                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          Jira URL<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          placeholder="Jira URL"
                          disabled
                          onChange={(e) => setJiraUrl(e.target.value)}
                          onBlur={(e) => setJiraUrl(e.target.value)}
                          value={jiraUrl}
                          className="custom-input"
                        />
                        {validationErrMsg?.jiraUrl && (
                          <span className="text-danger required-field">{validationErrMsg?.jiraUrl}</span>
                        )}
                      </div>
                    </div>
                  )}

                  {product === "Plane" && (
                    <div className="mb-3 col-12 d-flex flex-column flex-md-row gap-3">
                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          Plane Token<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="password"
                          placeholder="Plane Token"
                          onChange={(e) => setJiraToken(e.target.value)}
                          onBlur={(e) => setJiraToken(e.target.value)}
                          value={jiratoken}
                          className="custom-input"
                        />
                        {validationErrMsg?.jiratoken && (
                          <span className="text-danger required-field">{validationErrMsg?.jiratoken}</span>
                        )}
                      </div>

                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          Plane URL{" "}<i
                            className="bx bx-info-circle font-size-12 text-muted"
                            title={`Ensure that your Plane URL follows this format:\n<planeurl>/api/v1/workspaces/<workspacename>/projects/`}
                          ></i>
                          {" "}<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          disabled
                          placeholder="Plane URL"
                          onChange={(e) => setJiraUrl(e.target.value)}
                          onBlur={(e) => setJiraUrl(e.target.value)}
                          value={jiraUrl}
                          className="custom-input"
                        />
                        {validationErrMsg?.jiraUrl && (
                          <span className="text-danger required-field">{validationErrMsg?.jiraUrl}</span>
                        )}
                      </div>
                    </div>
                  )}

                  {product === "BrowserStack" && (
                    <div className="mb-3 col-12 d-flex flex-column flex-md-row gap-3">
                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          Browser Stack Access Key<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="password"
                          placeholder="Browser Stack Access Key"
                          onChange={(e) => setJiraToken(e.target.value)}
                          onBlur={(e) => setJiraToken(e.target.value)}
                          value={jiratoken}
                          className="custom-input"
                        />
                        {validationErrMsg?.jiratoken && (
                          <span className="text-danger required-field">{validationErrMsg?.jiratoken}</span>
                        )}
                      </div>

                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          Browser Stack URL<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          placeholder="Browser Stack URL"
                          disabled
                          onChange={(e) => setJiraUrl(e.target.value)}
                          onBlur={(e) => setJiraUrl(e.target.value)}
                          value={jiraUrl}
                          className="custom-input"
                        />
                      </div>
                    </div>
                  )}

                  {product === "GitHub" && (
                    <div className="mb-3 col-12 d-flex flex-column flex-md-row gap-3">
                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          GitHub URL<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          disabled
                          placeholder="GitHub URL"
                          onChange={(e) => setJiraUrl(e.target.value)}
                          onBlur={(e) => setJiraUrl(e.target.value)}
                          value={jiraUrl}
                          className="custom-input"
                        />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
        {product !== "BrowserStack" ?
          <div className="col-12 d-flex justify-content-center">
            <Button color="primary mb-3" type="button" onClick={handleFilterClick}>
              Map Project <LuLink />
            </Button>
          </div> :
          <div className="col-12 d-flex justify-content-center" >
            <Button color="primary mb-3" type="button" title="Click here to upload app" onClick={handleUploadApp}>
              Upload App <LuUpload />
            </Button>
          </div>
        }

        {product !== "BrowserStack" &&
          <Form>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="table-responsive" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                      <table className="table">
                        <thead>
                          {product === "Jira" &&
                            <tr>
                              <th>Jira Project</th>
                              <th></th>
                              <th>TestOps Project</th>
                              <th>Integration Type</th>
                              <th>Synchronization</th>
                              <th>Module</th>
                              <th>Action</th>
                            </tr>
                          }
                          {product === "Plane" &&
                            <tr>
                              <th>Plane Project</th>
                              <th></th>
                              <th>TestOps Project</th>
                              <th>Integration Type</th>
                              <th>Synchronization</th>
                              <th>Module</th>
                              <th>Action</th>
                            </tr>
                          }
                          {product === "GitHub" &&
                            <tr>
                              <th>GitHub Repository</th>
                              <th></th>
                              <th>TestOps Project</th>
                              <th>Integration Type</th>
                              <th>Organization</th>
                              <th>Module</th>
                              <th>Action</th>
                            </tr>
                          }
                        </thead>
                        <tbody>
                          {jiraMapList && product === "Jira" && jiraMapList?.map((dataItem, index) => (
                            <tr key={index}>
                              <td>
                                <Input
                                  type="text"
                                  placeholder="Project"
                                  className="custom-input form-control"
                                  style={{ minWidth: '150px' }}
                                  value={dataItem.jira_project}
                                  disabled
                                />
                              </td>
                              <td className="text-center">
                                <LuLink size={15} className="mt-2" color="#cbbaba" />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  placeholder="Project"
                                  className="custom-input form-control"
                                  style={{ minWidth: '150px' }}
                                  value={dataItem.frothtestops_project}
                                  disabled
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  placeholder="Type"
                                  className="custom-input form-control"
                                  style={{ minWidth: '150px' }}
                                  value={dataItem.intergration_type}
                                  disabled
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  placeholder="Synchronization"
                                  className="custom-input form-control"
                                  style={{ minWidth: '150px' }}
                                  value={dataItem.Syncronization}
                                  disabled
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  placeholder="Module"
                                  className="custom-input form-control"
                                  style={{ minWidth: '150px' }}
                                  value={dataItem.module}
                                  disabled
                                />
                              </td>
                              <td className="text-center">
                                <div className="d-flex gap-2 mt-2">
                                  {dataItem?.intergration_type === "Basic" ? (
                                    <div className="View-Icon" onClick={handleViewClick} title="View">
                                      <IoEyeOutline color="#667085" size={17} />
                                    </div>
                                  ) : (
                                    <div className="View-Icon" onClick={() => handleEditClick(dataItem)} title="Edit">
                                      <FiEdit2 color="#667085" size={15} />
                                    </div>
                                  )}
                                  <div
                                    className="Delete-Icon"
                                    onClick={() => onClickDelete(dataItem)}
                                    title="Delete"
                                  >
                                    <RiDeleteBinLine color="#667085" size={15} />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                          {/* Repeat for planeMapList and gitHubMapList */}
                          {planeMapList && product === "Plane" && planeMapList?.map((dataItem, index) => (
                            <tr key={index}>
                              <td>
                                <Input
                                  type="text"
                                  placeholder="Project"
                                  className="custom-input form-control"
                                  style={{ minWidth: '150px' }}
                                  value={dataItem.plane_project}
                                  disabled
                                />
                              </td>
                              <td className="text-center">
                                <LuLink size={15} className="mt-2" color="#cbbaba" />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  placeholder="Project"
                                  className="custom-input form-control"
                                  style={{ minWidth: '150px' }}
                                  value={dataItem.frothtestops_project}
                                  disabled
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  placeholder="Type"
                                  className="custom-input form-control"
                                  style={{ minWidth: '150px' }}
                                  value={dataItem.intergration_type}
                                  disabled
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  placeholder="Synchronization"
                                  className="custom-input form-control"
                                  style={{ minWidth: '150px' }}
                                  value={dataItem.Syncronization}
                                  disabled
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  placeholder="Module"
                                  className="custom-input form-control"
                                  style={{ minWidth: '150px' }}
                                  value={dataItem.module}
                                  disabled
                                />
                              </td>
                              <td className="text-center">
                                <div className="d-flex gap-2 mt-2">
                                  <div className="View-Icon" onClick={handleViewClick} title="View">
                                    <IoEyeOutline color="#667085" size={17} />
                                  </div>
                                  <div
                                    className="Delete-Icon"
                                    onClick={() => onClickDelete(dataItem)}
                                    title="Delete"
                                  >
                                    <RiDeleteBinLine color="#667085" size={15} />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                          {gitHubMapList && product === "GitHub" && gitHubMapList?.map((dataItem, index) => (
                            <tr key={index}>
                              <td>
                                <Input
                                  type="text"
                                  placeholder="Project"
                                  className="custom-input form-control"
                                  style={{ minWidth: '150px' }}
                                  value={dataItem.GitHub_Repository}
                                  disabled
                                />
                              </td>
                              <td className="text-center">
                                <LuLink size={15} className="mt-2" color="#cbbaba" />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  placeholder="Project"
                                  className="custom-input form-control"
                                  style={{ minWidth: '150px' }}
                                  value={dataItem.frothtestops_project}
                                  disabled
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  placeholder="Type"
                                  className="custom-input form-control"
                                  style={{ minWidth: '150px' }}
                                  value={dataItem.intergration_type}
                                  disabled
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  placeholder="Organization"
                                  className="custom-input form-control"
                                  style={{ minWidth: '150px' }}
                                  value={dataItem.organizations || "-"}
                                  disabled
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  placeholder="Module"
                                  className="custom-input form-control"
                                  style={{ minWidth: '150px' }}
                                  value={dataItem.module}
                                  disabled
                                />
                              </td>
                              <td className="text-center">
                                <div className="d-flex gap-2 mt-2">
                                  <div
                                    className="Delete-Icon"
                                    onClick={() => onClickDelete(dataItem)}
                                    title="Delete"
                                  >
                                    <RiDeleteBinLine color="#667085" size={15} />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>

                </Card>
              </Col>
            </Row>
          </Form>
        }
        <Col lg={12}>
          <Row>
            <Col md="12" className="text-end mt-3 mb-2">
              <Button color="primary" type="button" onClick={handleSubmit} disabled={loading}>
                {loading ? "Loading..." : "Submit"}
              </Button>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                  formik.resetForm()
                  navigate("/Settings/Integration")
                  dispatch(setEditIntegration({ isEdit: false, data: null }))
                }}
                className="ms-2"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Col>
      </Container>
    </React.Fragment>
  )
}

export default EditIntegration
