import React, { useState } from "react"
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import csvIcon from "../../assets/icons/btn.CSV.svg"
import xlsIcon from "../../assets/icons/btn.XLS.svg"
import xlsxIcon from "../../assets/icons/btn.XLSX.svg"
import { useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { downloadTestScenarioTemplateAPI } from "store/actions/TestScenarioActions"
import { downloadRequirementTemplateAPI } from "store/actions/RequirementActions"
import { downloadDefectTemplateAPI } from "store/actions/DefectsActions"
import { downloadTestCaseTemplateAPI } from "store/actions/TestCaseAction"
const DownloadTemplate = () => {
  const dispatch = useDispatch()
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const location = useLocation()
  const locationPath = location.pathname
  const pathname = locationPath.split("/")
  const toggleDropdown = () => setDropdownOpen(prevState => !prevState)

  const handleFormatSelect = format => {
    const bodyOptions = {
      file_type: format,
    }

    if (pathname.includes("TestScenario")) {
      dispatch(downloadTestScenarioTemplateAPI(bodyOptions))
    } else if (pathname.includes("Requirement")) {
      dispatch(downloadRequirementTemplateAPI(bodyOptions))
    } else if (pathname.includes("DefectManagement")) {
      dispatch(downloadDefectTemplateAPI(bodyOptions))
    } else if (pathname.includes("TestCase")) {
      dispatch(downloadTestCaseTemplateAPI(bodyOptions))
    }
  }

  return (
    <div>
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle
          caret
          color="secondary"
          outline
          className="d-flex align-items-center download-template"
        >
          <i className="bx bx-download font-size-16"></i>
          <span className="mx-2 hide-content">Download template</span>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() => handleFormatSelect("xls")}
            className="d-flex align-items-center"
          >
            <img src={xlsIcon} alt="xls" height="20px" className="mr-2" />
            <span className="mx-2">Download.xls</span>
          </DropdownItem>
          <DropdownItem
            onClick={() => handleFormatSelect("xlsx")}
            className="d-flex align-items-center"
          >
            <img src={xlsxIcon} alt="xlsx" height="20px" className="mr-2" />
            <span className="mx-2">Download.xlsx</span>
          </DropdownItem>
          <DropdownItem
            onClick={() => handleFormatSelect("csv")}
            className="d-flex align-items-center"
          >
            <img src={csvIcon} alt="csv" height="20px" className="mr-2" />
            <span className="mx-2">Download.csv</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default DownloadTemplate
