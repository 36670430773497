import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "flatpickr/dist/themes/material_blue.css"
import JoditEditor from "jodit-react"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import { joditConfig } from "../../../helpers/helper"
import { useNavigate } from "react-router-dom"
import { BuildsDetails } from "../../../store/ProjectManagement/actions"
import {
  createModuleAPI,
  EditModuleAPI,
  GenerateModuleId,
} from "store/actions/ModuleActions"

const CreateModule = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let config = joditConfig()
  const currentUrl = window.location.href
  const parts = currentUrl.split("/")
  const pathname = location.pathname.split("/").filter(Boolean).pop()
  const [description, setDescription] = useState("")
  const [buildValues, setBuildValues] = useState([])
  const buildDetails = useSelector(state => state.Project?.buildDetails)
  const layoutModeType = useSelector((state) => state.Layout.layoutModeType);  
  const moduleId = useSelector(
    state => state?.Module?.nextModuleId?.next_module_id
  )
  const selectedProject = JSON.parse(
    localStorage.getItem("selectedProjectData")
  )
  const selectedModuleData = useSelector(
    state => state?.Module?.editModuleDetails
  )
  const [id, setId] = useState("")
  const GlobalSelectedProject = JSON.parse(
    localStorage.getItem("selectedProject")
  )
  const [isdescription, setIsDescription] = useState(false)
  const isCreateMode = currentUrl.includes("Create-Module")
  const isEditMode = currentUrl.includes("Edit-Module")
  const [selProject, setselProject] = useState("")
  const loading = useSelector(state => state?.Loading?.isLoading)
  const stripHtmlTags = html => {
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent || ""
  }

  const breadcrumbItems = [
    { label: selProject, path: "/ProjectManagement" },
    { label: "Module", path: `/ProjectManagement/Module/${id}` },
    { label: isCreateMode ? "Create Module" : "Edit Module", path: "/" },
  ]

  const validationSchema = Yup.object().shape({
    projectName: Yup.string().required("Project name is required"),
    module: Yup.string()
    .trim()
    .matches(/^.*\S.*$/, "Module name Cannot contain only spaces")
      .required("Module is required")
      .max(250, "Module name cannot exceed 250 characters"), // Set character limit to 250
  })

  useEffect(() => {
    if (parts.includes("ProjectManagement")) {
      setId(parts[parts.length - 1])
      setselProject(selectedProject.project_name)
    } else {
      setId(GlobalSelectedProject.value)
      setselProject(GlobalSelectedProject.label)
    } 
  }, [dispatch, GlobalSelectedProject])

  useEffect(() => {
    if (id) {
      const pId = `project_id=${id}`;
      const projId = `?project_id=${id}`;   
      dispatch(BuildsDetails(projId))
      dispatch(GenerateModuleId(pId))
    }
  }, [id, dispatch]);

  useEffect(() => {
    const buldData = []
    if (Array.isArray(buildDetails)) {
      buildDetails.forEach(entry => {
        buldData.push({
          value: Math.round(entry.id),
          label: entry.build_id,
        })
      })
    }
    setBuildValues(buldData)
  }, [buildDetails])

  useEffect(() => {
    if (isEditMode && selectedModuleData) {
      formik.setValues({
        projectName: isCreateMode
          ? GlobalSelectedProject.label
          : selectedModuleData.project_name,
        moduleId: selectedModuleData.module_id,
        module: selectedModuleData.module_name,
        build: selectedModuleData.build_id,
        description: selectedModuleData.module_description,
      })
      setDescription(selectedModuleData.module_description)
    } else   {
      formik.setFieldValue("moduleId", moduleId) // Set moduleId when it's ready
    }
  }, [isEditMode, isCreateMode, selectedModuleData, moduleId])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      projectName: isEditMode ? selectedModuleData?.project_name : selProject,
      moduleId: isEditMode ? selectedModuleData?.module_id : moduleId,
      module: isEditMode ? selectedModuleData?.module_name : "",
      build: isEditMode ? selectedModuleData?.build_id : "",
      description: isEditMode ? selectedModuleData?.module_description : "",
    },
    validationSchema,
    onSubmit: async values => { 
      const cleanDescription = stripHtmlTags(description).trim()
    if (!cleanDescription) {
      setIsDescription(true)
    }
      if (cleanDescription) {
        if (isEditMode && selectedModuleData) {
          try {
            const bodyoptions = {
              project_id: id,
              build_id: values.build,
              module_name: values.module,
              module_description: description,
            }
            const response = await dispatch(
              EditModuleAPI(selectedModuleData?.id, bodyoptions)
            )
            if (response?.status) { 
                navigate(`/ProjectManagement/Module/${id}`)
              
            }
          } catch (error) {
            console.error("Failed to submit form:", error.message)
          }
        } else {
          try {
            const bodyoptions = {
              project_id: id,
              build_id: values.build,
              module_name: values.module,
              module_description: description,
            }
            const response = await dispatch(createModuleAPI(bodyoptions))
            if (response?.status) {
              if (parts.includes("ProjectManagement")) { 
                navigate(`/ProjectManagement/Module/${id}`)
              } else {
                navigate("/ProjectManagement")          
              }
            }
          } catch (error) {
            console.error("Failed to submit form:", error.message)
          }
        }
      }
    },
  })

  const submitData = () => {
    const cleanDescription = stripHtmlTags(description).trim()
    if (!cleanDescription) {
      setIsDescription(true)
    }
  }

  const onCancel = () => {
    formik.handleReset()
    setIsDescription(false)
    navigate(`/ProjectManagement/Module/${id}`)
  }

  
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: layoutModeType === 'dark' ? '#2a3042' : '#fff',
      color: layoutModeType === 'dark' ? '#fff' : '#000',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: layoutModeType === 'dark' ? '#2a3042' : '#fff',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: layoutModeType === 'dark' ? '#fff' : '#000',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: layoutModeType === 'dark' ? '#888' : '#888',
    }),
    input: (provided) => ({
      ...provided,
      color: layoutModeType === 'dark' ? '#fff' : '#000', // Ensure input text is visible
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150, // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  };

  const renderSelect = (
    name,
    label,
    placeholder,
    options,
    isRequired = true,
    customProps = {}
  ) => (
    <div className="mb-3">
      <Label className="form-label">
        {label} {isRequired && <span className="text-danger">*</span>}
      </Label>
      <Select
        name={name}
        placeholder={placeholder}
        onChange={option => formik.setFieldValue(name, option?.value || "")}
        onBlur={() => formik.setFieldTouched(name)}
        options={options}
        className={`select2-selection ${
          formik.touched[name] && formik.errors[name] ? "is-invalid" : ""
        }`}
        {...customProps}
        maxMenuHeight={200}
        value={
          options.find(option => option.value === formik.values[name]) || null
        } // Adjusted to handle default value correctly
        isClearable={true}
        styles={customStyles}

      />
      {formik.touched[name] && formik.errors[name] && (
        <FormFeedback className="custom-error-message">
          {formik.errors[name]}
        </FormFeedback>
      )}
    </div>
  )

  const renderInput = (
    name,
    label,
    type = "text",
    placeholder = "",
    isRequired = true,
    disabled = false
  ) => (
    <div className="mb-3">
      <Label className="form-label">
        {label} {isRequired && <span className="text-danger">*</span>}
      </Label>
      <Input
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values[name] || ""}
        invalid={formik.touched[name] && formik.errors[name]}
        className="custom-input"
        disabled={disabled}
      />
      {formik.touched[name] && formik.errors[name] && (
        <FormFeedback className="custom-error-message">
          {formik.errors[name]}
        </FormFeedback>
      )}
    </div>
  )

  const onGoBack = () =>{
    if(pathname === "Create-Module"){
      navigate("/ProjectManagement")
    }else{
      navigate(`/ProjectManagement/Module/${id}`) 
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={isCreateMode ? "Create Module" : "Edit Module"}
            breadcrumbItems={breadcrumbItems}
          />

          <div className="mt-2 mx-1 col-12 d-flex justify-content-between">
            <div>
              <h3>{!isEditMode ? "Create Module" : "Edit Module"}</h3>
              <p className="text-muted">
                Fill the form to {!isEditMode ? "create" : "edit"} a module
              </p>
            </div>
            <div>
            <Button
                type="button"
                color="primary"
                className="btn-label"
                onClick={onGoBack}
              >
                <i className="bx bx-arrow-back label-icon"></i> Go Back
              </Button>
            </div>
          </div>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    {renderInput(
                      "projectName",
                      "Project Name",
                      "text",
                      "Enter project name",
                      true,
                      true
                    )}
                    {renderInput(
                      "module",
                      "Module",
                      "text",
                      "Enter Module",
                      true,
                      false
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    {renderInput(
                      "moduleId",
                      "Module Id",
                      "text",
                      "Enter module Id",
                      false,
                      true
                    )}
                    {renderSelect(
                      "build",
                      "Build",
                      "Select Build",
                      buildValues,
                      false
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Label>
                      Module Description <span className="text-danger">*</span>
                    </Label>
                    <JoditEditor
                      value={description}
                      config={config}
                      onBlur={newContent => setDescription(newContent)}
                    />
                    {isdescription && (
                      <div className="mt-1 font-size-10 text-danger">
                        Module description is required
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={12}>
                <div className="d-flex justify-content-end gap-2 mb-2 mx-2">
                  <Button color="primary" type="submit" onClick={submitData} disabled={loading}>
                  {loading?"Loading...":"Submit"}
                  </Button>
                  <Button color="secondary" onClick={onCancel}>
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreateModule
