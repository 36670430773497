import React from "react"
import { Container } from "reactstrap"
import Breadcrumb from "./Header/Breadcrumb"
import { Outlet, useLocation } from "react-router-dom"
import NoData from "components/Common/NoData"

const TestPlan = () => {
  const location = useLocation()
  const locationPath = location.pathname
  const pathname = locationPath.split("/").filter(Boolean).pop()
  const pathArray = [
    "CreateTestPlan",
    "EditTestPlan",
    "ViewTestPlan",
    "TestPlanSummary",
  ]
  const globalProjectValue = JSON.parse(localStorage.getItem("selectedProject"))

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {globalProjectValue ? (
            <>
              {!pathArray.includes(pathname) && <Breadcrumb />}
              <Outlet />
            </>
          ) : (
            <>
              <NoData />
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TestPlan
