import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector } from "react-redux";

const ProjectDefectStatistics = () => {
  const data = useSelector(
    (state) => state?.Dashboard?.ProjectdashboardDefectStatics
  );
  const layoutModeType = useSelector((state) => state.Layout.layoutModeType);
  const isDarkMode = layoutModeType === "dark";

  // Function to format dates to DD-MM-YYYY
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Create a new array with formatted categories dates
  const formattedCategories = data?.categories?.map((category) =>
    formatDate(category)
  );

  // Deep copy of series data to avoid immutability issues
  const deepCopySeries = data?.series?.map((series) => ({
    ...series,
    data: series.data ? [...series.data] : [],
  }));

  // Filter out categories and corresponding series data where all values are 0
  const filteredCategories = [];
  const filteredSeries = deepCopySeries?.map((series) => ({
    ...series,
    data: [],
  }));

  // Filter logic: Keep only categories and series data where at least one series value is greater than 0
  formattedCategories?.forEach((category, index) => {
    const hasNonZeroData = deepCopySeries.some((series) => series.data[index] > 0);
    
    if (hasNonZeroData) {
      filteredCategories.push(category); // Keep this category
      deepCopySeries.forEach((series, seriesIndex) => {
        filteredSeries[seriesIndex].data.push(series.data[index]); // Keep corresponding data
      });
    }
  });

  // Check if there is data available to render the chart
  const hasData = filteredSeries?.some((series) =>
    series.data.some((value) => value > 0)
  );

  const options = {
    chart: {
      type: "column",
      backgroundColor: isDarkMode ? "#2a3042" : "#FFFFFF", // Dark mode background
    },
    title: {
      text: " ",
      align: "left",
      style: {
        color: isDarkMode ? "#FFFFFF" : "#000000", // Adjust title color
      },
    },
    xAxis: {
      categories: filteredCategories,
      crosshair: true,
      labels: {
        style: {
          color: isDarkMode ? "#FFFFFF" : "#000000", // Adjust x-axis label color
        },
      },
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Number of Defects",
        style: {
          color: isDarkMode ? "#FFFFFF" : "#000000", // Adjust y-axis title color
        },
      },
      labels: {
        style: {
          color: isDarkMode ? "#FFFFFF" : "#000000", // Adjust y-axis label color
        },
      },
    },
    tooltip: {
      backgroundColor: isDarkMode ? "#000000" : "#ffffff", // Adjust tooltip background color
      style: {
        color: isDarkMode ? "#f0f0f0" : "#000000", // Adjust tooltip text color
      },
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          style: {
            color: isDarkMode ? "#FFFFFF" : "#000000", // Adjust data label color
          },
        },
      },
    },
    legend: {
      itemStyle: {
        color: isDarkMode ? "#FFFFFF" : "#000000", // Adjust legend text color
      },
    },
    series: filteredSeries,
  };

  // Conditionally render the chart only if there's data
  return hasData ? (
    <HighchartsReact highcharts={Highcharts} options={options} />
  ) : (
    <p>No defect data available.</p>
  );
};

export default ProjectDefectStatistics;
