import React, { useEffect, useState, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import DeleteModal from "components/Common/DeleteModal"
import { useSelector, useDispatch } from "react-redux"
import { toast, ToastContainer } from "react-toastify"
import {
  BulkDeleteTestScenarioAPI,
  BulkEditTestScenarioAPI,
  deleteTestScenarioAPI,
  fetchAllTestScenarioList,
  fetchNextScenarioID,
  GetTestScenarioByIdAPI,
} from "store/actions/TestScenarioActions"
import BulkDeleteModal from "components/Common/BulkDeleteModal"
import FilterTestScenario from "./Filter Test Scenario/FilterTestScenario"
import BulkEditTestScenario from "./Edit Test Scenario/BulkEditTestScenario"
import {
  clearFilters,
  setEditTestScenario,
} from "store/Slices/TestScenarioSlice"
import TableContainer from "components/Common/helper/TableContainer"
import moment from "moment"
import NoPermission from "components/Common/NoPermission"

const TestScenario = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const [data, setData] = useState([])

  const permission = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission = permission?.permissions?.[0]?.menu_item?.TestManagement

  const handleSingleEditClick = async id => {
    if (rolePermission?.testscenario?.edit) {
      try {
        const res = await dispatch(GetTestScenarioByIdAPI(id))
        dispatch(setEditTestScenario({ isEdit: true, data: res }))
        navigate("/TestManagement/EditTestScenario")
      } catch (error) {
        console.error("Error fetching data", error)
      }
    } else {
      toast.warn("You don't have the permission to edit Test Scenario", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }
  const [viewTestScenarioData, setViewTestScenarioData] = useState(null)

  const handleViewTestScenario = async id => {
    if (rolePermission?.testscenario?.view) {
      try {
        const res = await dispatch(GetTestScenarioByIdAPI(id))
        setViewTestScenarioData(res)
      } catch (error) {
        console.error("Error fetching data", error)
      }
    } else {
      toast.warn(
        "You don't have the permission to view Details of Test Scenario",
        {
          position: "top-right",
          id: " Error ",
          autoClose: 3000,
        }
      )
    }
  }
  useEffect(() => {
    if (viewTestScenarioData) {
      navigate("/TestManagement/ViewTestScenario")
    }
  }, [viewTestScenarioData])

  const bodyOption = {
    project_id: globalProject?.value || selectedProject?.value,
  }

  const handleCreateClick = () => {
    if (rolePermission?.testscenario?.create) {
      dispatch(fetchNextScenarioID(bodyOption))
      navigate("/TestManagement/CreateTestScenario")
    } else {
      toast.warn("You don't have the permission to create new Test Scenario", {
        position: "top-right",
        id: "Project create Error ",
        autoClose: 3000,
      })
    }
  }

  const [deleteModal, setDeleteModal] = useState(false)
  const [bulkDeleteModal, setBulkDeleteModal] = useState(false)
  const [isDeleteId, setIsDeleteId] = useState("")

  const onClickDelete = testscenario => {
    if (rolePermission?.testscenario?.delete) {
      setIsDeleteId(testscenario?.id)
      setDeleteModal(true)
    } else {
      toast.warn("You don't have the permission to delete test scenario", {
        position: "top-right",
        id: "Project create Error ",
        autoClose: 3000,
      })
    }
  }

  const [selectedRows, setSelectedRows] = useState([])
  const handleCheckboxChange = test_scenario_id => {
    setSelectedRows(prevValues =>
      prevValues.includes(test_scenario_id)
        ? prevValues.filter(val => val !== test_scenario_id)
        : [...prevValues, test_scenario_id]
    )
  }

  const handleSelectAll = e => {
    const isChecked = e.target.checked
    if (isChecked) {
      const newSelectedRows = data.map(item => item.test_scenario_id)
      setSelectedRows(newSelectedRows)
    } else {
      setSelectedRows([])
    }
  }

  const columns = useMemo(
    () => [
      {
        header: (
          <input
            type="checkbox"
            onChange={handleSelectAll}
            style={{ cursor: "pointer" }}
            title="Select All"
            checked={
              Array.isArray(selectedRows) &&
              selectedRows.length === data?.length &&
              data?.length > 0
            }
          />
        ),
        accessorKey: "checkbox",
        cell: cellProps => {
          const jobData = cellProps.row.original.test_scenario_id

          const isChecked = () => {
            if (!Array.isArray(selectedRows)) {
              console.error("selectedRows is not an array")
              return false
            }
            return selectedRows.includes(jobData)
          }

          const handleCheckboxClick = () => {
            try {
              if (!Array.isArray(selectedRows)) {
                throw new Error("selectedRows is not an array")
              }
              handleCheckboxChange(jobData)
            } catch (error) {
              console.error(error.message)
            }
          }

          return (
            <input
              type="checkbox"
              style={{ cursor: "pointer" }}
              checked={isChecked()}
              onChange={handleCheckboxClick}
              onClick={handleCheckboxClick}
            />
          )
        },
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Test Scenario Id",
        accessorKey: "test_scenario_id",
        cell: cell => <div>{cell.getValue()}</div>,
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return <>{cell.getValue()}</>
        },
      },
      {
        header: "Test Scenario Name",
        accessorKey: "test_scenario_name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const test_scenario_name = cell.getValue()
          return (
            <>
              <p
                className="font-size-14 mb-1"
                style={{
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  whiteSpace: "normal",
                  maxWidth: "300px", // Set the max-width to control the wrapping
                }}
              >
                {test_scenario_name ? test_scenario_name : "-"}
              </p>
            </>
          )
        },
      },
      {
        header: "Build",
        accessorKey: "build",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const build = cell.getValue()
          return (
            <p
              className="font-size-14 mb-1"
              style={{
                // wordWrap: "break-word",
                // overflowWrap: "break-word",
                // whiteSpace: "normal",
                maxWidth: "400px", // Set the max-width to control the wrapping
              }}
            >
              {build ? build : "-"}
            </p>
          )
        },
      },
      {
        header: "Module",
        accessorKey: "module_name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const module_name = cell.getValue()
          return (
            <p
              className="font-size-14 mb-1"
              style={{
                wordWrap: "break-word",
                overflowWrap: "break-word",
                whiteSpace: "normal",
                maxWidth: "300px", // Set the max-width to control the wrapping
              }}
            >
              {module_name ? module_name : "-"}
            </p>
          )
        },
      },
      {
        header: "Requirement Title",
        accessorKey: "requirement_title",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const requirement_title = cell.getValue()
          return (
            <p
              className="font-size-14 mb-1"
              style={{
                wordWrap: "break-word",
                overflowWrap: "break-word",
                whiteSpace: "normal",
                maxWidth: "300px", // Set the max-width to control the wrapping
              }}
            >
              {requirement_title ? requirement_title : "-"}
            </p>
          )
        },
      },
      {
        header: "Created At",
        accessorKey: "created_at",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return <>{moment(cell.getValue()).format("DD-MM-YYYY")}</>
        },
      },
      {
        header: "Action",
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top">
                <span
                  // to="/"
                  className="btn btn-sm btn-soft-primary"
                  onClick={() => {
                    const TestScenarioData = cellProps.row.original
                    handleViewTestScenario(TestScenarioData?.id)
                  }}
                  id={`viewtooltip-${cellProps.row.original.test_scenario_id}`}
                >
                  <i className="mdi mdi-eye-outline" />
                </span>
              </li>
              <UncontrolledTooltip
                placement="top"
                target={`viewtooltip-${cellProps.row.original.test_scenario_id}`}
              >
                View
              </UncontrolledTooltip>

              <li>
                <span
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    const TestScenarioData = cellProps.row.original
                    handleSingleEditClick(TestScenarioData?.id)
                  }}
                  id={`edittooltip-${cellProps.row.original.test_scenario_id}`}
                >
                  <i className="mdi mdi-pencil-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edittooltip-${cellProps.row.original.test_scenario_id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </span>
              </li>

              <li>
                <span
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const userData = cellProps.row.original
                    onClickDelete(userData)
                  }}
                  id={`deletetooltip-${cellProps.row.original.test_scenario_id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`deletetooltip-${cellProps.row.original.test_scenario_id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </span>
              </li>
            </ul>
          )
        },
      },
    ],
    [selectedRows, data]
  )

  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [globalFilter, setGlobalFilter] = useState("")
  const [totalCount, setTotalCount] = useState(null)
  const [isFilterFormOpen, setIsFilterFormOpen] = useState(false)
  const [isEditTestScenarioFormOpen, setIsEditTestScenarioFormOpen] =
    useState(false)
  const { testScenarioFilterFrom } = useSelector(state => state.TestScenario)
  const fetchTableData = async (page, size, search) => {
    let bodyoption = {
      page: page + 1,
      page_size: size,
      search: search,
      project_id: globalProject?.value || selectedProject?.value,
      filter_build_id: testScenarioFilterFrom?.build_id,
      filter_requirement_id: testScenarioFilterFrom?.requirement_id,
      filter_module_id: testScenarioFilterFrom?.module_id,
      created_by: testScenarioFilterFrom?.created_by,
    }
    try {
      const data = await dispatch(fetchAllTestScenarioList(bodyoption))
      setData(data.Data)
      setTotalCount(data.TotalCount)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  useEffect(() => {
    fetchTableData(pageIndex, pageSize, globalFilter)
  }, [
    pageIndex,
    pageSize,
    globalFilter,
    globalProject?.value,
    testScenarioFilterFrom,
  ])

  const handleFilterSubmit = async values => {
    setPageIndex(0)
    let bodyoption = {
      page: 0 + 1,
      page_size: pageSize,
      search: globalFilter,
      project_id: globalProject?.value || selectedProject?.value,
      filter_build_id: testScenarioFilterFrom?.build_id,
      filter_requirement_id: testScenarioFilterFrom?.test_scenario_id,
      filter_module_id: testScenarioFilterFrom?.module_id,
      created_by: testScenarioFilterFrom?.created_by,
    }
    try {
      const data = await dispatch(fetchAllTestScenarioList(bodyoption))
      setData(data.Data)
      setTotalCount(data.TotalCount)
      setIsFilterFormOpen(false)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  const handleDelete = async () => {
    try {
      await dispatch(deleteTestScenarioAPI(isDeleteId))
      setDeleteModal(false)
      await fetchTableData(pageIndex, pageSize, globalFilter)
      setIsDeleteId("")
    } catch (error) {
      console.error("Error deleting test scenario:", error)
    }
  }

  const handleFilterClick = () => {
    setIsFilterFormOpen(!isFilterFormOpen)
  }

  const handleEditClick = () => {
    if (rolePermission?.testscenario?.edit) {
      try {
        if (selectedRows?.length > 0) {
          setIsEditTestScenarioFormOpen(!isEditTestScenarioFormOpen)
        } else {
          toast.warn(
            "Please select a test scenario before attempting to bulk edit.",
            {
              position: "top-right",
              id: "selectBeforeDelete",
              autoClose: 3000,
            }
          )
        }
      } catch (error) {
        console.error("Error deleting test scenario:", error)
      }
    } else {
      toast.warn("You don't have the permission to edit test scenario's", {
        position: "top-right",
        id: "Project create Error ",
        autoClose: 3000,
      })
    }
  }
  const handleDeleteClick = async () => {
    if (rolePermission?.testscenario?.delete) {
      try {
        if (selectedRows?.length > 0) {
          setBulkDeleteModal(true)
        } else {
          toast.warn(
            "Please select a test scenario before attempting to bulk delete.",
            {
              position: "top-right",
              id: "selectBeforeDelete",
              autoClose: 3000,
            }
          )
        }
      } catch (error) {
        console.error("Error deleting test scenario:", error)
      }
    } else {
      toast.warn("You don't have the permission to delete test scenario's", {
        position: "top-right",
        id: "Project create Error ",
        autoClose: 3000,
      })
    }
  }
  const handleBulkDelete = async () => {
    const bodyoption = {
      project_id: globalProject?.value || selectedProject?.value,
      test_scenario_id: selectedRows,
    }
    try {
      await dispatch(BulkDeleteTestScenarioAPI(bodyoption))
      setSelectedRows([])
      setBulkDeleteModal(false)
      await fetchTableData(pageIndex, pageSize, globalFilter)
    } catch (error) {
      console.error("Error deleting test scenario:", error)
    }
  }
  const handleBulkEdit = async data => {
    const bodyOption = {
      ...data,
      project_id: globalProject?.value,
      test_scenario_id: selectedRows,
    }
    try {
      await dispatch(BulkEditTestScenarioAPI(bodyOption))
      setSelectedRows([])
      setIsEditTestScenarioFormOpen(false)
      await fetchTableData(pageIndex, pageSize, globalFilter)
    } catch (error) {
      console.error("Error updating test scenario:", error)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(clearFilters())
    }
  }, [])
  return (
    <React.Fragment>
      {rolePermission?.testscenario?.view ? <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
        message="Test Scenario"
      />
      <BulkDeleteModal
        show={bulkDeleteModal}
        onDeleteClick={handleBulkDelete}
        onCloseClick={() => {
          setBulkDeleteModal(false)
          setSelectedRows([])
        }}
        message="Test Scenario's"
      />

      <FilterTestScenario
        isFilterFormOpen={isFilterFormOpen}
        toggle={handleFilterClick}
        onSubmitFilterFrom={handleFilterSubmit}
      />
      <BulkEditTestScenario
        isOpen={isEditTestScenarioFormOpen}
        toggle={handleEditClick}
        handleBulkEdit={handleBulkEdit}
        setSelectedRows={setSelectedRows}
      />
      <Container fluid>
        <Row>
          <Col lg="12">
            <Card
              style={{
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
              }}
            >
              <CardBody className="pt-2">
                <div className="table-container">
                  <TableContainer
                    columns={columns}
                    data={data}
                    pageIndex={pageIndex}
                    setPageIndex={setPageIndex}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    totalCount={totalCount}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    isGlobalFilter={true}
                    isPagination={true}
                    isCustomPageSize={true}
                    isAddButton={true}
                    isAddDelete={true}
                    isAddEdit={true}
                    isAddFilter={true}
                    pagination="pagination"
                    SearchPlaceholder="Search..."
                    handleAddClick={handleCreateClick}
                    handleDeleteClick={handleDeleteClick}
                    handleEditClick={handleEditClick}
                    handleFilterClick={handleFilterClick}
                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                    theadClass="table-light"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                  />
                </div>
              </CardBody>
              {/* {rolePermission?.testscenario?.view ? (
               
              ) : (
                <>
                  <DontHavePermission title="Test Scenario List" />
                </>
              )} */}
            </Card>
          </Col>
        </Row>
      </Container>

      <ToastContainer /> 
      </>:
      <NoPermission />
      }
    </React.Fragment>
  )
}

export default withRouter(TestScenario)
