import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/GlobalDashboard/GlobalDashboard"
import ProjectDashboard from "pages/Dashboard/ProjectDashboard/ProjectDashboard"
import ProjectManagement from "pages/ProjectManagement/ProjectManagement"
import Settings from "pages/SettingsData/Settings"
import QuickAction from "pages/QuickActions/QuickAction"
import TestManagement from "pages/Test Management/TestManagement"
import Project from "pages/ProjectManagement/Project"
import CreateProject from "components/QuickActions/CreateProject/CreateProject"
import TestCycle from "pages/TestLab/Test Cycle/TestCycle"
import TestPlan from "pages/TestLab/Test Plan/TestPlan"
import AddUser from "pages/ProjectManagement/AddUser"
import CreateBuild from "components/QuickActions/CreateBuild/CreateBuild"
import CreateModule from "components/QuickActions/CreateModule/CreateModule"
import NewReqirement from "components/QuickActions/NewRequirement/NewRequirement"

import RequirementView from "pages/ProjectManagement/Requirement/view/RequirementView"
import ViewProject from "pages/ProjectManagement/ViewProject"
import Roles from "pages/SettingsData/Roles/Roles"
import Users from "pages/SettingsData/Users/Users"
import Integration from "pages/SettingsData/Integration/Integration"
import Tags from "pages/SettingsData/Tags/Tags"

//Test Scenario
import TestScenario from "pages/Test Management/Test Scenario/TestScenario"
import CreateTestScenarioForm from "pages/Test Management/Test Scenario/Create Test Scenario/CreateTestScenarioForm"
import ViewTestScenario from "pages/Test Management/Test Scenario/View Test Scenario/ViewTestScenario"

//Test Case
import TestCase from "pages/Test Management/Test Case/TestCase"

//Test Plan
import TestPlanMain from "pages/TestLab/Test Plan/components/TestPlanMain"
import CreateTestPlanForm from "pages/TestLab/Test Plan/components/Create Test Plan/CreateTestPlanForm"
import ViewTestPlan from "pages/TestLab/Test Plan/components/View Test Plan/ViewTestPlan"

//Test Data
import TestData from "pages/TestLab/TestAutomation/Test Data/TestData"
import CreateTestDataForm from "pages/TestLab/TestAutomation/Test Data/Create Test Data/CreateTestDataForm"
import ViewTestData from "pages/TestLab/TestAutomation/Test Data/View Test Data/ViewTestData"

import TestAutomation from "pages/TestLab/TestAutomation"
import DBIntegration from "pages/TestLab/TestAutomation/DB Integration/DBIntegration"
import AutomationSuite from "pages/TestLab/TestAutomation/Automation Suite/AutomationSuite"
import AutomationScript from "pages/TestLab/TestAutomation/Automation Script/AutomationScript"

//Defect  Management
import DefectManagement from "pages/DefectManagement/DefectManagement"
import Defects from "pages/DefectManagement/Defects"
import CreateDefectFrom from "pages/DefectManagement/Create Defects/CreateDefectFrom"

import CreateRoles from "pages/SettingsData/Roles/Create Roles/CreateRoles"
import ViewDefect from "pages/DefectManagement/View Defect/ViewDefect"

//Reports
import Reports from "pages/ReportsData/Reports"
import DefectReport from "pages/ReportsData/Defect Report/DefectReport"
import TestCaseReport from "pages/ReportsData/Test Case Report/TestCaseReport"
import TestCaseExecutionReport from "pages/ReportsData/Test Case Execution/TestCaseExecutionReport"
import RequirementTraceabilityReport from "pages/ReportsData/Requirement Traceability Report/RequirementTraceabilityReport"
import ScheduleReport from "pages/ReportsData/Schedule Report/ScheduleReport"

import DefectSavedTemplateTable from "pages/ReportsData/Defect Report/DefectSavedTemplateTable"
import TestExecutionSavedTemplateTable from "pages/ReportsData/Test Case Execution/TestExecutionSavedTemplateTable"
import RequirementTraceabilitySavedTemplateTable from "pages/ReportsData/Requirement Traceability Report/RequirementTraceabilitySavedTemplateTable"

//coming Soon
// import PagesComingsoon from "components/Common/PagesComingsoon"
import TestCaseSavedTemplateTable from "pages/ReportsData/Test Case Report/TestCaseSavedTemplateTable"
import ViewAutomationSuite from "pages/TestLab/TestAutomation/Automation Suite/view/ViewAutomationSuite"
import CreateAutomationSuite from "pages/TestLab/TestAutomation/Automation Suite/Create/CreateAutomationSuite"
// import { element } from "prop-types"
import CreateEditUsers from "pages/SettingsData/Users/CreateEditUsers/CreateEditUsers"
import ViewAutomationScript from "pages/TestLab/TestAutomation/Automation Script/view/ViewAutomationScript"
import CreateAutomationScript from "pages/TestLab/TestAutomation/Automation Script/Create/CreateAutomationScript"
import CreateIntegration from "pages/SettingsData/Integration/Create/CreateIntegration"
import CreateEditTag from "pages/SettingsData/Tags/CreateEditTag"
import ViewTestCase from "pages/Test Management/Test Case/View Test Case/ViewTestCase"
import EditIntegration from "pages/SettingsData/Integration/Edit/EditIntegration"
import CreateTestCaseForm from "pages/Test Management/Test Case/Create Edit Test Case/CreateTestCaseForm"
import CreateScheduleReport from "pages/ReportsData/Schedule Report/Create Schedule Report/CreateScheduleReport"
import CreateDbIntegration from "pages/TestLab/TestAutomation/DB Integration/Create DbConnection/CreateDbIntegration"
import PreConditon from "pages/Test Management/Test Case/Pre Condition/PreConditon"
import CreateEditPreCondition from "pages/Test Management/Test Case/Pre Condition/CreateEditPreConditon/CreateEditPreCondition"
import TestCyclePage from "pages/TestLab/Test Cycle/TestCyclePage"
import CreateEditTestCycle from "pages/TestLab/Test Cycle/CreateEditTestCycle/CreateEditTestCycle"
import ViewTestCycleAutomation from "pages/TestLab/Test Cycle/ViewTestCycle/ViewTestCycleAutomation"
import ViewTestCycleManual from "pages/TestLab/Test Cycle/ViewTestCycle/ViewTestCycleManual"
import ExecuteTestCase from "pages/TestLab/Test Cycle/ExecutePage/ExecuteTestCase"
import ExecuteTestSuite from "pages/TestLab/Test Cycle/ExecutePage/ExecuteTestSuite"
import CycleComparisonReport from "pages/ReportsData/Cycle Comparison Reports/CycleComparisonReport"
import AgentCreation from "pages/TestLab/TestAutomation/AgentCreation/AgentCreation"
import CreateAgent from "pages/TestLab/TestAutomation/AgentCreation/CreateAgent"
import UploadApp from "pages/TestLab/TestAutomation/UploadApp/UploadApp"
import UploadNewApp from "pages/TestLab/TestAutomation/UploadApp/UploadNewApp"
import GlobalDashboardDetails from "pages/Dashboard/GlobalDashboard/GlobalDashboardDetails"
import TestPlanSummary from "pages/TestLab/Generate Test Plan Summary/TestPlanSummary"
import RequestPage from "pages/RequestPage/RequestPage"
import TestPlanRequest from "pages/RequestPage/TestPlan/TestPlanRequest"
import TestCaseRequest from "pages/RequestPage/TestCase/TestCaseRequest"
import ViewTestCaseRequest from "pages/RequestPage/TestCase/ViewTestCaseRequest"
import ResetPassword from "pages/Authentication/ResetPassword"
import { element } from "prop-types"
import Pages404 from "components/Common/pages-404"

const authProtectedRoutes = [
  { path: "/dashboard", element: <Dashboard /> },
  {
    path: "/dashboard/GlobalDashboardDetails",
    element: <GlobalDashboardDetails />,
  },
  { path: "/ProjectDashboard", element: <ProjectDashboard /> },
  { path: "/ProjectManagement", element: <ProjectManagement /> },
  { path: "/ProjectManagement/:data/:id", element: <Project /> },
  { path: "/ProjectManagement/CreateProject", element: <CreateProject /> },
  { path: "/CreateProject", element: <CreateProject /> },
  { path: "/ProjectManagement/EditProject", element: <CreateProject /> },
  {
    path: "/ProjectManagement/View-Requirement/:id",
    element: <RequirementView />,
  },
  {
    path: "/ProjectManagement/Create-Requirement/:id",
    element: <NewReqirement />,
  },
  {
    path: "/ProjectManagement/Edit-Requirement/:id",
    element: <NewReqirement />,
  },
  {
    path: "/ProjectManagement/View-Requirement/:id",
    element: <RequirementView />,
  },
  {
    path: "/ProjectManagement/Create-Requirement/:id",
    element: <NewReqirement />,
  },
  {
    path: "/ProjectManagement/Edit-Requirement/:id",
    element: <NewReqirement />,
  },
  { path: "/ProjectManagement/AddUser/:id", element: <AddUser /> },
  { path: "/ProjectManagement/view", element: <ViewProject /> },

  { path: "/ProjectManagement/Create-Module/:id", element: <CreateModule /> },
  { path: "/Create-Module", element: <CreateModule /> },
  { path: "/ProjectManagement/Edit-Module/:id", element: <CreateModule /> },
  { path: "/ProjectManagement/Create-Build/:id", element: <CreateBuild /> },
  { path: "/Create_Build", element: <CreateBuild /> },
  { path: "/ProjectManagement/Edit-Build/:id", element: <CreateBuild /> },
  { path: "/ProjectManagement/Requirement/:id", element: <Project /> },

  {
    path: "/TestManagement",
    element: <TestManagement />,
    children: [
      { path: "TestScenario", element: <TestScenario /> },
      { path: "CreateTestScenario", element: <CreateTestScenarioForm /> },
      { path: "EditTestScenario", element: <CreateTestScenarioForm /> },
      { path: "ViewTestScenario", element: <ViewTestScenario /> },
      { path: "TestCase", element: <TestCase /> },
      { path: "CreateEditTestCase", element: <CreateTestCaseForm /> },
      { path: "ViewTestCase", element: <ViewTestCase /> },
      { path: "PreCondition", element: <PreConditon /> },
      { path: "CreateEditPreCondition", element: <CreateEditPreCondition /> },
    ],
  },

  {
    path: "/DefectManagement",
    element: <DefectManagement />,
    children: [
      { path: "", element: <Defects /> },
      { path: "CreateEditDefect", element: <CreateDefectFrom /> },
      { path: "EditDefect", element: <CreateDefectFrom /> },
      { path: "ViewDefect", element: <ViewDefect /> },
    ],
  },
  {
    path: "/Reports",
    element: <Reports />,
    children: [
      { path: "", element: <DefectReport /> },
      // { path: "", element: <PagesComingsoon /> },
      { path: "Defect/SavedTemplates", element: <DefectSavedTemplateTable /> },

      { path: "Testcase", element: <TestCaseReport /> },
      { path: "TestExecution", element: <TestCaseExecutionReport /> },
      {
        path: "RequirementTraceability",
        element: <RequirementTraceabilityReport />,
      },
      { path: "Schedule", element: <ScheduleReport /> },
      { path: "CycleComparison", element: <CycleComparisonReport /> },
      {
        path: "TestCase/SavedTemplates",
        element: <TestCaseSavedTemplateTable />,
      },
      {
        path: "TestExecution/SavedTemplates",
        element: <TestExecutionSavedTemplateTable />,
      },
      {
        path: "RequirementTraceability/SavedTemplates",
        element: <RequirementTraceabilitySavedTemplateTable />,
      },

      { path: "CreateScheduleReport", element: <CreateScheduleReport /> },
    ],
  },
  { path: "*", element: <Pages404 /> },

  {
    path: "/Settings",
    element: <Settings />,
    children: [
      { path: "Roles", element: <Roles /> },
      { path: "CreateEditRoles", element: <CreateRoles /> },
      { path: "Users", element: <Users /> },
      { path: "CreateEditUsers", element: <CreateEditUsers /> },
      { path: "Integration", element: <Integration /> },
      { path: "CreateIntegration", element: <CreateIntegration /> },
      { path: "EditIntegration", element: <EditIntegration /> },
      { path: "Tags", element: <Tags /> },
      { path: "CreateTag", element: <CreateEditTag /> },
      { path: "EditTag", element: <CreateEditTag /> },
    ],
  },

  { path: "/QuickActions", element: <QuickAction /> },
  {
    path: "/TestPlan",
    element: <TestPlan />,
    children: [
      { path: "", element: <TestPlanMain /> },
      { path: "CreateTestPlan", element: <CreateTestPlanForm /> },
      { path: "ViewTestPlan", element: <ViewTestPlan /> },
      { path: "EditTestPlan", element: <CreateTestPlanForm /> },
      { path: "TestPlanSummary", element: <TestPlanSummary /> },
    ],
  },

  {
    path: "/TestAutomation",
    element: <TestAutomation />,
    children: [
      { path: "TestData", element: <TestData /> },
      { path: "TestData/CreateTestData", element: <CreateTestDataForm /> },
      { path: "TestData/EditTestData", element: <CreateTestDataForm /> },
      { path: "TestData/ViewTestData", element: <ViewTestData /> },
      { path: "DbIntegration", element: <DBIntegration /> },
      // { path: "DbIntegration", element: <PagesComingsoon /> },
      {
        path: "DbIntegration/CreateDbIntegration",
        element: <CreateDbIntegration />,
      },
      {
        path: "DbIntegration/EditDbIntegration",
        element: <CreateDbIntegration />,
      },
      { path: "AutomationSuite", element: <AutomationSuite /> },
      {
        path: "AutomationSuite/ViewAutomationSuite",
        element: <ViewAutomationSuite />,
      },
      {
        path: "AutomationSuite/CreateAutomationSuite",
        element: <CreateAutomationSuite />,
      },
      {
        path: "AutomationSuite/EditAutomationSuite",
        element: <CreateAutomationSuite />,
      },
      { path: "AutomationScript", element: <AutomationScript /> },
      {
        path: "AutomationScript/ViewAutomationScript",
        element: <ViewAutomationScript />,
      },
      {
        path: "AutomationScript/CreateAutomationScript",
        element: <CreateAutomationScript />,
      },
      {
        path: "AutomationScript/EditAutomationScript",
        element: <CreateAutomationScript />,
      },
      {
        path: "AgentCreation",
        element: <AgentCreation />,
      },
      {
        path: "CreateAgent",
        element: <CreateAgent />,
      },
      {
        path: "UploadApp",
        element: <UploadApp />,
      },
      {
        path: "UploadNewApp",
        element: <UploadNewApp />,
      },
    ],
  },
  {
    path: "/TestCycle",
    element: <TestCycle />,
    children: [
      { path: "", element: <TestCyclePage /> },
      { path: "CreateEditTestCycle", element: <CreateEditTestCycle /> },
      { path: "ViewTestCycleAutomation", element: <ViewTestCycleAutomation /> },
      { path: "ViewTestCycleManual", element: <ViewTestCycleManual /> },
      { path: "ExecuteTestCase", element: <ExecuteTestCase /> },
      { path: "ExecuteTestSuite", element: <ExecuteTestSuite /> },
    ],
  },
  {
    path: "/RequestPage",
    element: <RequestPage />,
    children: [
      { path: "", element: <TestCaseRequest /> },
      { path: "TestPlanRequest", element: <TestPlanRequest /> },
      { path: "TestCaseRequestView", element: <ViewTestCaseRequest /> },
      // { path: "ViewTestCycleAutomation", element: <ViewTestCycleAutomation /> },
      // { path: "ViewTestCycleManual", element: <ViewTestCycleManual /> },
      // { path: "ExecuteTestCase", element: <ExecuteTestCase /> },
      // { path: "ExecuteTestSuite", element: <ExecuteTestSuite /> },
    ],
  },
  { path: "/CreateBuild", element: <CreateBuild /> },
  { path: "/CreateModule", element: <CreateModule /> },
  { path: "/New-Requirement", element: <NewReqirement /> },
  { path: "/profile", element: <UserProfile /> },
  { path: "/CreateTestScenario", element: <CreateTestScenarioForm /> },
  { path: "/CreateTestCase", element: <CreateTestCaseForm /> },
  { path: "/CreateDefect", element: <CreateDefectFrom /> },
  { path: "/CreateUsers", element: <CreateEditUsers /> },

  {
    path: "/",
    exact: true,
    element: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/login", element: <Login /> },
  { path: "/logout", element: <Logout /> },
  { path: "/forgot-password", element: <ForgetPwd /> },
  { path: "/ResetPassword/:id", element: <ResetPassword /> },
  { path: "/register", element: <Register /> },
]

export { authProtectedRoutes, publicRoutes }
