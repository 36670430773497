import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import "../../../components/Common/Breadcrum.scss"
import {
  Row,
  Col,
  BreadcrumbItem,
  Button,
  Container,
  Card,
  CardBody,
} from "reactstrap"
import PageNavigation from "../PageNavigation/PageNavigation"
import DownloadTemplate from "components/Download Template/DownloadTemplate"
import FileUploadModal from "components/Import/FileUpload"
import ExportModal from "components/Export/ExportModal"
// import { useSelector } from "react-redux"
import InfoTable from "components/Common/Info/Info"

const Breadcrumb = props => {
  const { isImport, isDownload, isExport, isInfo } = props
  // const [title, setTitle] = useState("Test Scenario")

  const initialBreadcrumbItems = [
    { label: "Request", path: "/RequestPage" },
    { label: "Test Case", path: "/" },
  ]

  const [breadcrumbItems, setBreadcrumbItems] = useState(initialBreadcrumbItems)
  const [activeButton, setActiveButton] = useState("")

  const handleTestPlanClick = () => {
    setActiveButton("TestPlan")
    // setTitle("Test Case")
    setBreadcrumbItems([
      { label: "Request", path: "TestPlanRequest" },
      { label: "Test Plan", path: "/" },
    ])
  }


  const handleTestCaseClick = () => {
    setActiveButton("TestCase")
    setBreadcrumbItems(initialBreadcrumbItems)
    // setTitle("Test Scenario")
  }

  const onBreadcrumbClick = () => {
    setActiveButton("TestCase")
    setBreadcrumbItems(initialBreadcrumbItems)
    // setTitle("Test Scenario")
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModal = () => setIsModalOpen(!isModalOpen)

  const [modalOpenFile, setModalOpenFile] = useState(false)
  const toggleFileModal = () => setModalOpenFile(!modalOpenFile)

  // const layoutModeType = useSelector(state => state.Layout.layoutModeType)

  return (
    <>
      <div style={{ marginTop: "-25px" }}>
        {/* <h4 className="font-size-18 mb-2">{title}</h4> */}
        <div className="page-title-left " style={{ marginLeft: "-10px" }}>
          <ol className="breadcrumb m-0">
            {breadcrumbItems?.map((item, index) => (
              <BreadcrumbItem
                key={index}
                active={index === breadcrumbItems.length - 1}
              >
                {index === breadcrumbItems.length - 1 ? (
                  item.label
                ) : (
                  <Link to={item.path} onClick={onBreadcrumbClick}>
                    {item.label}
                  </Link>
                )}
              </BreadcrumbItem>
            ))}
          </ol>
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col lg="12">
            <Card
              className="mb-0"
              style={{
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
              }}
            >
              <CardBody className="border-bottom pt-3 pb-0">
                <div className="d-flex justify-content-between ">
                  <PageNavigation
                    handleTestCaseClick={handleTestCaseClick}
                    handleTestPlanClick={handleTestPlanClick}
                    activeButton={activeButton}
                    onBreadcrumbClick={onBreadcrumbClick}
                  />

                  <div className="d-flex gap-2">
                    {isDownload && <DownloadTemplate />}
                    {isImport && (
                      <Button
                        color="secondary"
                        outline
                        className="d-flex align-items-center mx-2 mb-2"
                        onClick={toggleFileModal}
                        style={{ width: "95px" }}
                      >
                        {/* <i className="bx bx-import font-size-16"></i> */}
                        <i className="bx bx-import font-size-16"></i>

                        <span className="mx-2">Import</span>
                      </Button>
                    )}
                    {isExport && <ExportModal />}
                    {isInfo && (
                      <Button
                        color="secondary"
                        outline
                        className="d-flex align-items-center   mb-2"
                        onClick={toggleModal}
                      >
                        <i className="bx bx-info-circle font-size-16"></i>
                      </Button>
                    )}
                    <InfoTable isOpen={isModalOpen} toggle={toggleModal} />
                    <FileUploadModal
                      isOpen={modalOpenFile}
                      toggle={toggleFileModal}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

Breadcrumb.propTypes = {
  isImport: PropTypes.bool,
  isDownload: PropTypes.bool,
  isExport: PropTypes.bool,
  isInfo: PropTypes.bool,
}

export default Breadcrumb
