import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Alert } from "reactstrap"

const PageNavigation = props => {
  const {
    handleReqTraReportClick,
    handleCycleComPReportClick,
    handleDefectsClick,
    handleTestCaseClick,
    handleTestExecutionClick,
    handleTestScheduleClick,
    activeButton,
  } = props

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  const locationPath = location.pathname
  const pathname = locationPath.split("/").filter(Boolean).pop()
  const pathArray = ["Schedule", "SavedTemplates", "CycleComparison"]

  const handleDefectReport = () => {
    setDropdownOpen(false) // Close dropdown
    navigate("/Reports")
    handleDefectsClick()
  }

  const handleRequirementTraceabilityReports = () => {
    setDropdownOpen(false) // Close dropdown
    navigate("/Reports/RequirementTraceability")
    handleReqTraReportClick()
  }
  const handleCycleComparisonReports = () => {
    setDropdownOpen(false) // Close dropdown
    navigate("/Reports/CycleComparison")
    handleCycleComPReportClick()
  }

  const handleTestCaseReports = () => {
    navigate("/Reports/Testcase")
    handleTestCaseClick()
  }

  const handleTestExecutionReports = () => {
    navigate("/Reports/TestExecution")
    handleTestExecutionClick()
  }

  const handleScheduleReports = () => {
    setDropdownOpen(false) // Close dropdown
    navigate("/Reports/Schedule")
    handleTestScheduleClick()
  }

  // const toggleDropdown = ev => {
  //   ev.stopPropagation() // Prevent event bubbling

  // }

  useEffect(() => {
    if (pathname === "Reports") {
      handleDefectReport()
    } else if (pathname === "Schedule") {
      handleScheduleReports()
    } else if (pathname === "RequirementTraceability") {
      handleRequirementTraceabilityReports()
    } else if (pathname === "Testcase") {
      handleTestCaseClick()
    } else if (pathname === "TestExecution") {
      handleTestExecutionReports()
    } else if (pathname === "CycleComparison") {
      handleCycleComparisonReports()
    }
  }, [pathname])

  return (
    <>
      <div className="col-12">
        <div
          className="btn-group"
          role="group"
          style={{ whiteSpace: "nowrap" }}
        >
          <input
            type="radio"
            className="btn-check"
            id="btnradio1"
            name="btnradio"
            autoComplete="off"
            checked={activeButton === "DefectReports"}
          />
          <label
            className="btn btn-outline-primary"
            htmlFor="btnradio1"
            onClick={handleDefectReport}
          >
            Defect Reports
          </label>

          <input
            type="radio"
            className="btn-check"
            id="btnradio3"
            name="btnradio"
            autoComplete="off"
            checked={["TestCase", "TestExecution"].includes(activeButton)}
          />
          <label
            className="btn btn-outline-primary position-relative"
            htmlFor="btnradio3"
            onClick={() => {
              setDropdownOpen(prevState => !prevState)
            }}
          >
            Test Case Report
            {dropdownOpen && (
              <div
                className="dropdown-menu show position-absolute"
                style={{ top: "100%", left: 0 }}
              >
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={handleTestCaseReports}
                >
                  Test Case
                </button>
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={handleTestExecutionReports}
                >
                  Test Execution
                </button>
              </div>
            )}
          </label>

          <input
            type="radio"
            className="btn-check"
            id="btnradio2"
            name="btnradio"
            autoComplete="off"
            checked={activeButton === "RequirementTraceabilityReports"}
          />
          <label
            className="btn btn-outline-primary"
            htmlFor="btnradio2"
            onClick={handleRequirementTraceabilityReports}
          >
            Requirement Traceability Reports
          </label>
          <input
            type="radio"
            className="btn-check"
            id="btnradio2"
            name="btnradio"
            autoComplete="off"
            checked={activeButton === "CycleComparison"}
          />
          <label
            className="btn btn-outline-primary"
            htmlFor="btnradio2"
            onClick={handleCycleComparisonReports}
          >
            Cycles Comparison Reports
          </label>
          <input
            type="radio"
            className="btn-check"
            id="btnradio4"
            name="btnradio"
            autoComplete="off"
            checked={activeButton === "Schedule"}
          />
          <label
            className="btn btn-outline-primary"
            htmlFor="btnradio4"
            onClick={handleScheduleReports}
          >
            Schedule Reports
          </label>
        </div>
        {!pathArray.includes(pathname) && (
          <Alert color="info" role="alert">
            Select the desired filters and click the 'Generate Report' button to
            view the generated report.
          </Alert>
        )}
        <div className="col-12 mt-2"></div>
      </div>
    </>
  )
}

export default PageNavigation
