// import { createStore, applyMiddleware, compose } from "redux";
// import createSagaMiddleware from "redux-saga";

// import rootReducer from "./reducers";
// import rootSaga from "./sagas";
// import { thunk } from "redux-thunk";

// const sagaMiddleware = createSagaMiddleware();
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const store = createStore(
//   rootReducer,
//   composeEnhancers(applyMiddleware(thunk , sagaMiddleware))
// );
// sagaMiddleware.run(rootSaga);

// export default store;

import { configureStore } from "@reduxjs/toolkit"
import createSagaMiddleware from "redux-saga"
import rootSaga from "./sagas"
import rootReducer from "./reducers"

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== "production", // Enable Redux DevTools in development mode
})
sagaMiddleware.run(rootSaga)

export default store
