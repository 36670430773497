import GetApiService from "./http.Get.Service"
import PostToAPIQueryService from "./http-Post.Query.Service"
import PostApiService from "./http.Post.Service"
import {
  validate,
  projectManagement,
  meta,
  users,
  testManagement,
  testPlan,
  testCycle,
  defectManagement,
  roles,
  reports,
  integration,
  dashboard,
  notifications,
} from "./constant/api.constant"
import DeleteApiService from "./http-Delete.Service"
import PutApiService from "./http-Put.Service"
import PostToDownloadAPI from "./http-Post.service.download"
import PostToQueryDownloadAPI from "./http-Post.Query.Download"
import GetToDownloadAPI from "./http-GetToDownload"

// import PutApiService from "./http-Put.Service "

// Authentication
const login = data => {
  return PostApiService.PostToAPI(validate.login, data)
}

const forgotPassword = data => {
  return PostApiService.PostToAPI(validate.forgotPassword, data)
}

const resetPassword = (data , id) => {
  return PostApiService.PostToAPI(validate.changePassword + id + "/", data)
}
// Project Management
const projectDetails = () => {
  return GetApiService.GetAPI(projectManagement.requirement.projectList)
}

const createProject = data => {
  return PostApiService.PostToAPI(projectManagement.project.createProject, data)
}

const tagStatusEditData = data => {
  return PostApiService.PostToAPI(meta.getTags + data)
}

const tagStatus = data => {
  return PostApiService.PostToAPI(meta.getTags , data)
}

const deleteTag = id => {
  return DeleteApiService.deleteDataFromAPI(meta.delete + id + "/")
}
const GetTagById = id => {
  return GetApiService.GetAPI(meta.retrieve + id + "/")
}
const createTags = data => {
  return PostApiService.PostToAPI(meta.createnew, data)
}

const EditTags = (data, id) => {
  return PutApiService.PutAPI(meta.editmeta + id + "/", data)
}
//project requirement
const getRequirements = url => {
  return GetApiService.GetAPI(
    projectManagement.requirement.requirementData + url
  )
}

const deleteProject = id => {
  return DeleteApiService.deleteDataFromAPI(
    projectManagement.project.projectDelete + id + "/"
  )
}

const EditProject = (id, data) => {
  return PutApiService.PutAPI(
    projectManagement.project.editProject + id + "/",
    data
  )
}

const teamDetails = url => {
  return GetApiService.GetAPI(projectManagement.project.getTeamMember + url)
}

const teamsAvailable = url => {
  return GetApiService.GetAPI(
    projectManagement.project.getAvailableTeamMember + url
  )
}
const userDelete = (id, data) => {
  return DeleteApiService.deleteDataFromAPI(
    projectManagement.project.deleteMember + id + "/",
    data
  )
}
const getTagsStatus = url => {
  return PostToAPIQueryService.PostToAPIQuery(meta.getTags + url)
}
const getAllBuildsAPI = url => {
  return GetApiService.GetAPI(projectManagement?.builds.builds + url)
}
const getAllModulessAPI = url => {
  return GetApiService.GetAPI(projectManagement?.modules?.modules + url)
}

const getAllRequirementsAPI = url => {
  return GetApiService.GetAPI(
    projectManagement?.requirement?.requirementData + url
  )
}

const getAllTestPlanAPI = url => {
  return GetApiService.GetAPI(testPlan?.getALlTestData + url)
}
const GetTestPlanById = id => {
  return GetApiService.GetAPI(testPlan?.getPlanById + id + "/")
}
const deleteTestPlan = (id, data) => {
  return DeleteApiService.deleteDataFromAPI(testPlan?.delete + id + "/", data)
}
const getTestPlanStatus = data => {
  return PostApiService.PostToAPI(testPlan?.testPlanStatus, data)
}
const createTestPlan = data => {
  return PostApiService.PostToAPI(testPlan.create, data)
}
const EditTestPlan = (data, id) => {
  return PutApiService.PutAPI(testPlan?.update + id + "/", data)
}
const bulkExecute = data => {
  return PutApiService.PutAPI(testCycle?.bulkExecute, data)
}

const generateNextTestPlanID = url => {
  return GetApiService.GetAPI(testPlan?.generateTestPlanID + url)
}

const getAllTestDataAPI = url => {
  return GetApiService.GetAPI(testCycle.testData.getAllTestData + url)
}
const GetTestDataById = id => {
  return GetApiService.GetAPI(testCycle.testData.testDataById + id + "/")
}
const GetTestCycleById = id => {
  return GetApiService.GetAPI(testCycle.viewTestCycle + id + "/")
}
const createTestData = data => {
  return PostApiService.PostToAPI(testCycle.testData.create, data)
}
const EditTestData = (data, id) => {
  return PutApiService.PutAPI(testCycle.testData.edit + id + "/", data)
}

const deleteTestData = (id, data) => {
  return DeleteApiService.deleteDataFromAPI(
    testCycle.testData.delete + id + "/",
    data
  )
}
const BulkDeleteTestData = data => {
  return DeleteApiService.deleteDataFromAPI(testCycle.testData.bulkDelete, data)
}
const uploadTestDataTemplate = data => {
  return PostApiService.PostToAPI(testCycle.testData.importTestData, data)
}

const getAllTestScenarioAPI = url => {
  return GetApiService.GetAPI(
    testManagement.testScenario.getAllTestScenario + url
  )
}
const globalDashboardDefectStatusAPI = url => {
  return GetApiService.GetAPI(
    dashboard.globalDashboard.globaldashboardDefectsStatus + url
  )
}
const globalDashboardDefectSeverityAPI = url => {
  return GetApiService.GetAPI(
    dashboard.globalDashboard.globalDashboardSeverity + url
  )
}

const generateNextScenarioID = url => {
  return GetApiService.GetAPI(
    testManagement.testScenario.getNextTestScenarioId + url
  )
}

const deleteTestScenario = (id, data) => {
  return DeleteApiService.deleteDataFromAPI(
    testManagement.testScenario.deleteTestScenario + id + "/",
    data
  )
}
const BulkDeleteTestScenario = data => {
  return DeleteApiService.deleteDataFromAPI(
    testManagement.testScenario.bulkDeleteTestScenario,
    data
  )
}

const deleteCycle = (id, data) => {
  return DeleteApiService.deleteDataFromAPI(
    testCycle.deleteTestCycle + id + "/",
    data
  )
}
const BulkDeleteCycle = data => {
  return DeleteApiService.deleteDataFromAPI(testCycle.bulkDeleteTestCycle, data)
}

const createTestScenario = data => {
  return PostApiService.PostToAPI(
    testManagement.testScenario.createTestScenario,
    data
  )
}

const createTestCase = data => {
  return PostApiService.PostToAPI(testManagement.testCase.createTestCase, data)
}

const trigger = data => {
  return PostApiService.PostToAPI(testCycle.triggerWorkflow, data)
}

const createPreCondition = data => {
  return PostApiService.PostToAPI(
    testManagement.testCase.preCondition.createPrecondion,
    data
  )
}

const createDefect = data => {
  return PostApiService.PostToAPI(defectManagement.createDefect, data)
}

const syncPlane = data => {
  return PostApiService.PostToAPI(defectManagement.syncDefects + data)
}

const syncJira = data => {
  return PostApiService.PostToAPI(defectManagement.syncDefectsJira + data)
}

const fetchJiraUsers = data => {
  return PostApiService.PostToAPI(defectManagement.getJiraUser, data)
}
const downloadTestScenarioTemplate = data => {
  return PostToDownloadAPI.PostToDownloadAPI(
    testManagement.testScenario.downloadTemplateTestScenrio,
    data
  )
}
const exportTestScenarioData = data => {
  return PostToDownloadAPI.PostToDownloadAPI(
    testManagement.testScenario.exportTestScenario,
    data
  )
}
const uploadTestScenarioTemplate = data => {
  return PostApiService.PostToAPI(
    testManagement.testScenario.importTestScenario,
    data
  )
}
const BulkEditTestScenario = data => {
  return PutApiService.PutAPI(
    testManagement?.testScenario?.bulkEditTestScenario,
    data
  )
}
const EditTestScenario = (data, id) => {
  return PutApiService.PutAPI(
    testManagement?.testScenario?.editTestScenario + id + "/",
    data
  )
}
const editTestCase = (data, id) => {
  return PutApiService.PutAPI(
    testManagement?.testCase?.editTestCase + id + "/",
    data
  )
}
const editPrecondition = (data, id) => {
  return PutApiService.PutAPI(
    testManagement?.testCase.preCondition?.editPreCondition + id + "/",
    data
  )
}

const updateExecute = (data, id) => {
  return PutApiService.PutAPI(testCycle.updateExecute + id + "/", data)
}

const updateSuiteStatus = (data, id) => {
  return PutApiService.PutAPI(testCycle.scriptStatus + id + "/", data)
}
const editDefect = (data, id) => {
  return PutApiService.PutAPI(defectManagement.editDefect + id + "/", data)
}

const GetTestScenarioById = id => {
  return GetApiService.GetAPI(
    testManagement?.testScenario?.GetSingleTestScenario + id + "/"
  )
}
const addUser = data => {
  return PostApiService.PostToAPI(projectManagement.project.userCreate, data)
}

const ProjectDetailsView = id => {
  return GetApiService.GetAPI(projectManagement.project.viewProject + id + "/")
}
const getUserDetailsListAPI = id => {
  return GetApiService.GetAPI(users?.userDetailsList + id)
}
const getTeamsDetailsUserAssigneeAPI = id => {
  return GetApiService.GetAPI(users?.teamsDetailsUserassignee + id)
}

const getUserListAPI = url => {
  return GetApiService.GetAPI(users?.users + url)
}
const getUserTeamListAPI = url => {
  return GetApiService.GetAPI(users?.usersTeamList + url)
}
const getTeamDetailsUserAPI = url => {
  return GetApiService.GetAPI(users?.teamDetailsUser + url)
}

const BuildsDetails = id => {
  return GetApiService.GetAPI(projectManagement.builds.builds + id)
}

const TeamsDetails = id => {
  return GetApiService.GetAPI(projectManagement.project.getTeamMember + id)
}

const ModuleDetails = id => {
  return GetApiService.GetAPI(projectManagement.modules.modules + id)
}

//Requirements
const RequirementDetails = id => {
  return GetApiService.GetAPI(
    projectManagement.requirement.requirementData + id
  )
}

const TagStatus = (id, data) => {
  return PostApiService.PostToAPI(meta.getTags + id, data)
}
const TagsDetails = data => {
  return GetApiService.GetAPI(meta.meta + data)
}

const deleteRequirement = id => {
  return DeleteApiService.deleteDataFromAPI(
    projectManagement.requirement.deleteRequirement + id + "/"
  )
}

const deleteBulkRequirement = data => {
  return DeleteApiService.deleteDataFromAPI(
    projectManagement.requirement.bulkDelete,
    data
  )
}

const generateRequirement = id => {
  return GetApiService.GetAPI(
    projectManagement.requirement.getRequirementId + id
  )
}

const ProjectList = id => {
  return GetApiService.GetAPI(validate.defaultProject)
}

const CreateRequirement = data => {
  return PostApiService.PostToAPI(
    projectManagement.requirement.createRequirement,
    data
  )
}

const BulkEditRequirement = data => {
  return PutApiService.PutAPI(
    projectManagement.requirement.bulkEditRequirement,
    data
  )
}

const EditRequirement = (id, data) => {
  return PutApiService.PutAPI(
    projectManagement.requirement.editRequirement + id + "/",
    data
  )
}

const RequirementView = id => {
  return GetApiService.GetAPI(
    projectManagement.requirement.viewRequirement + id + "/"
  )
}
const getTestCycleDetails = url => {
  return GetApiService.GetAPI(testCycle.getAllTestCycleData + url)
}
const getExecutionDetails = url => {
  return GetApiService.GetAPI(testCycle.getAllExecutionData + url)
}
const getExecutionChart = url => {
  return GetApiService.GetAPI(testCycle.testCycleSummary + url)
}

const downloadRequirementTemplate = data => {
  return PostToDownloadAPI.PostToDownloadAPI(
    projectManagement.requirement.downloadTemplate,
    data
  )
}
const exportRequirementData = data => {
  return PostToDownloadAPI.PostToDownloadAPI(
    projectManagement.requirement.exportTemplate,
    data
  )
}
const uploadRequirementTemplate = data => {
  return PostApiService.PostToAPI(
    projectManagement.requirement.importRequirement,
    data
  )
}

const ExtendSession = data => {
  return PostApiService.PostToAPI(validate.refreshToken, data)
}

const getAllIntegrationList = url => {
  return GetApiService.GetAPI(integration.integration + url)
}

const getAllMapList = url => {
  return GetApiService.GetAPI(integration.mapProjectDetails + url)
}

const deleteIntegration = id => {
  return DeleteApiService.deleteDataFromAPI(integration.delete + id + "/")
}
const getIntegrationRetrieve = id => {
  return GetApiService.GetAPI(integration.integrationView + id + "/")
}
const getTestPlanDefectStatus = url => {
  return GetApiService.GetAPI(testPlan.testPlanDefectStatus + url)
}
const getTestPlanDefectList = url => {
  return GetApiService.GetAPI(testPlan.testPlanDefectList + url)
}
const getTestPlanSummaryReports = url => {
  return GetApiService.GetAPI(testPlan.testPlanSummaryReports + url)
}
const getTestPlanResponsibleResources = url => {
  return GetApiService.GetAPI(testPlan.testPlanResponsibleResources + url)
}
const testPlanSignoff = url => {
  return GetApiService.GetAPI(testPlan.testPlanSignoff + url)
}
const testPlanResultChart = url => {
  return GetApiService.GetAPI(testPlan.testPlanResultChart + url)
}
const testPlanSummaryReportsHistory = url => {
  return GetApiService.GetAPI(testPlan.testPlanSummaryReportsHistory + url)
}
const getTestPlanResult = url => {
  return GetApiService.GetAPI(testPlan.testPlanResult + url)
}
const testPlanSummaryReportsAdd = data => {
  return PostApiService.PostToAPI(testPlan.testPlanSummaryReportsAdd, data)
}
const testPlanSummaryReportsEdit = (data, id) => {
  return PutApiService.PutAPI(
    testPlan.testPlanSummaryReportsEdit + id + "/",
    data
  )
}
const testPlanPdf = url => {
  return GetToDownloadAPI.GetToDownloadAPI(testPlan.testPlanPdf + url)
}
const getAllDefectsList = url => {
  return GetApiService.GetAPI(defectManagement.defectDetails + url)
}

const getAllDefectsIdSList = url => {
  return GetApiService.GetAPI(defectManagement.defectDetails + url)
}
const deleteDefect = id => {
  return DeleteApiService.deleteDataFromAPI(
    defectManagement.deleteDefect + id + "/"
  )
}

const uploadDefectTemplate = data => {
  return PostApiService.PostToAPI(defectManagement.importDefect, data)
}
const GetDefectById = id => {
  return GetApiService.GetAPI(defectManagement.getDefectbyId + id + "/")
}
const generateDefectId = id => {
  return GetApiService.GetAPI(defectManagement.getDefectId + id)
}
const BulkDeleteDefect = data => {
  return DeleteApiService.deleteDataFromAPI(
    defectManagement.bulkDeleteDefects,
    data
  )
}

const BulkEditDefects = data => {
  return PutApiService.PutAPI(defectManagement.bulkEditDefects, data)
}
const exportDefectData = data => {
  return PostToDownloadAPI.PostToDownloadAPI(
    defectManagement.exportDefect,
    data
  )
}
const downloadDefectTemplate = data => {
  return PostToDownloadAPI.PostToDownloadAPI(
    defectManagement.downloadDefectTemplate,
    data
  )
}
const getAllRolesList = url => {
  return GetApiService.GetAPI(roles.roles + url)
}

const deleteRoles = (id, data) => {
  return DeleteApiService.deleteDataFromAPI(roles.delete + id + "/", data)
}

const createRoles = data => {
  return PostApiService.PostToAPI(roles.create, data)
}

const editRoles = (data, id) => {
  return PutApiService.PutAPI(roles.edit + id + "/", data)
}

const editApp = (data, id) => {
  return PutApiService.PutAPI(integration.browserStackUpdate + id + "/", data)
}
const createUsers = data => {
  return PostApiService.PostToAPI(users.create, data)
}
const createIntegration = data => {
  return PostApiService.PostToAPI(integration.create, data)
}

const editUsers = (data, id) => {
  return PutApiService.PutAPI(users.edit + id + "/", data)
}
const editIntegration = (data, id) => {
  return PutApiService.PutAPI(integration.update + id + "/", data)
}

//Module
const ProjectModuleDetails = id => {
  return GetApiService.GetAPI(projectManagement.modules.modules + id)
}

const DeleteModule = (id, data) => {
  return DeleteApiService.deleteDataFromAPI(
    projectManagement.modules.delete + id + "/",
    data
  )
}

const UpdateModule = (id, data) => {
  return PutApiService.PutAPI(projectManagement.modules.edit + id + "/", data)
}

const GenerateModuleId = id => {
  return GetApiService.GetAPI(projectManagement.modules.genNextModuleId + id)
}

const BulkDeleteModule = data => {
  return DeleteApiService.deleteDataFromAPI(
    projectManagement.modules.bulkModuleDelete,
    data
  )
}

const CreateModule = data => {
  return PostApiService.PostToAPI(projectManagement.modules.create, data)
}

//Build
const ProjectBuildDetails = id => {
  return GetApiService.GetAPI(projectManagement.builds.builds + id)
}

const DeleteBuild = (id, data) => {
  return DeleteApiService.deleteDataFromAPI(
    projectManagement.builds.delete + id + "/",
    data
  )
}

const UpdateBuild = (id, data) => {
  return PutApiService.PutAPI(projectManagement.builds.edit + id + "/", data)
}

const GenerateBuildId = id => {
  return GetApiService.GetAPI(projectManagement.builds.genNextBuildId + id)
}

const BulkDeleteBuild = data => {
  return DeleteApiService.deleteDataFromAPI(
    projectManagement.builds.bulkDelete,
    data
  )
}

const CreateBuild = data => {
  return PostApiService.PostToAPI(projectManagement.builds.create, data)
}

const getRolesRetrieve = id => {
  return GetApiService.GetAPI(roles?.retreive + id + "/")
}

const getUsersRetrieve = id => {
  return GetApiService.GetAPI(users?.retrieve + id + "/")
}
const BulkDeleteReportsTemplates = data => {
  return DeleteApiService.deleteDataFromAPI(reports.reports.bulkDelete, data)
}

const getAllReporstTemplate = url => {
  return GetApiService.GetAPI(reports.reports.getAllReportsTemplate + url)
}

const deleteSavedTemplate = id => {
  return DeleteApiService.deleteDataFromAPI(
    reports.reports.deleteReportTemplate + id + "/"
  )
}
const deleteScheduleReport = id => {
  return DeleteApiService.deleteDataFromAPI(
    reports.scheduleReport.delete + id + "/"
  )
}
const createScheduleReport = data => {
  return PostApiService.PostToAPI(reports.scheduleReport.create, data)
}
const GetSingleReportTemplate = id => {
  return GetApiService.GetAPI(
    reports.reports.GetSingleReportTemplate + id + "/"
  )
}
const saveAsTemplate = data => {
  return PostApiService.PostToAPI(reports.reports.saveTemplate, data)
}

const getDefectReportsData = url => {
  return GetApiService.GetAPI(reports.defectReports.generateDefectReports + url)
}

const getTestCaseData = url => {
  return GetApiService.GetAPI(
    reports.testCaseReport.generateTestCaseReports + url
  )
}
const getTestExecutionData = url => {
  return GetApiService.GetAPI(
    reports.testExecutionReport.generateTestExecutionReports + url
  )
}
const getRequirementTraceabilityData = url => {
  return GetApiService.GetAPI(
    reports.requirementTraceability.generateRequirementTraceabitlityReports +
      url
  )
}

const downloadDefectReports = data => {
  return PostToQueryDownloadAPI.PostToDownloadAPI(
    reports.defectReports.download,
    data
  )
}

const getAllScheduleReportAPI = url => {
  return GetApiService.GetAPI(reports.scheduleReport.get + url)
}
const ProjectdasboardCycleComparisonAPI = url => {
  return GetApiService.GetAPI(reports.Cycle.cycleComparison + url)
}

const CycleComparisonTestCaseResultAPI = url => {
  return GetApiService.GetAPI(reports.Cycle.testCycleResults + url)
}
//Automation suite
const AutomationSuiteDetails = id => {
  return GetApiService.GetAPI(testCycle.AutomationSuite.getAllAutoSuite + id)
}

const DeleteAutomationSuite = (id, data) => {
  return DeleteApiService.deleteDataFromAPI(
    testCycle.AutomationSuite.delteAutoSuite + id + "/",
    data
  )
}

const UpdateAutomationSuite = (id, data) => {
  return PutApiService.PutAPI(
    testCycle.AutomationSuite.updateAutoSuite + id + "/",
    data
  )
}

const GenerateAutomationSuiteId = id => {
  return GetApiService.GetAPI(testCycle.AutomationSuite.getAutoSuiteID + id)
}

const GetAutomationSuiteView = id => {
  return GetApiService.GetAPI(
    testCycle.AutomationSuite.getAutomationSuiteById + id + "/"
  )
}

const BulkDeleteAutomationSuite = data => {
  return DeleteApiService.deleteDataFromAPI(
    testCycle.AutomationSuite.bulkDelete,
    data
  )
}

const CreateAutomationSuite = data => {
  return PostApiService.PostToAPI(
    testCycle.AutomationSuite.createAutoSuite,
    data
  )
}

//Automation script
const AutomationScriptDetails = id => {
  return GetApiService.GetAPI(
    testCycle.AutomationScript.getAllAutomationScripts + id
  )
}

const DeleteAutomationScript = (id, data) => {
  return DeleteApiService.deleteDataFromAPI(
    testCycle.AutomationScript.deleteAutoScript + id + "/",
    data
  )
}

const UpdateAutomationScript = (id, data) => {
  return PutApiService.PutAPI(
    testCycle.AutomationScript.updateAutomationScript + id + "/",
    data
  )
}

const GenerateAutomationScriptId = id => {
  return GetApiService.GetAPI(
    testCycle.AutomationScript.getAutomationSciptId + id
  )
}

const GetAutomationScriptView = id => {
  return GetApiService.GetAPI(
    testCycle.AutomationScript.viewAutomationScript + id + "/"
  )
}

const BulkDeleteAutomationScript = data => {
  return DeleteApiService.deleteDataFromAPI(
    testCycle.AutomationScript.bulkDeleteAutoScript,
    data
  )
}

const CreateAutomationScript = data => {
  return PostApiService.PostToAPI(
    testCycle.AutomationScript.createAutomationScript,
    data
  )
}

const WebActionDetails = id => {
  return GetApiService.GetAPI(testCycle.AutomationScript.webActions + id)
}

const DbConnectionDetails = id => {
  return GetApiService.GetAPI(testCycle.dbConnections.getdbConnections + id)
}

const DbConnectionAutoscript = data => {
  return PostApiService.PostToAPI(
    testCycle.AutomationScript.dbConnections,
    data
  )
}

const downloadTestCaseReports = data => {
  return PostToQueryDownloadAPI.PostToDownloadAPI(
    reports.testCaseReport.download,
    data
  )
}
const downloadTestExecutionReports = data => {
  return PostToQueryDownloadAPI.PostToDownloadAPI(
    reports.testExecutionReport.download,
    data
  )
}
const downloadRequirementTraceabilityReports = data => {
  return PostToQueryDownloadAPI.PostToDownloadAPI(
    reports.requirementTraceability.download,
    data
  )
}

const deleteUser = (id, data) => {
  return DeleteApiService.deleteDataFromAPI(users.delete + id + "/")
}
const TestCaseDetails = id => {
  return GetApiService.GetAPI(testManagement.testCase.testCaseDashboard + id)
}
const PreCondition = id => {
  return GetApiService.GetAPI(
    testManagement.testCase.preCondition.getPreCondition + id
  )
}
const TestCaseChart = id => {
  return GetApiService.GetAPI(testManagement.testCase.testCaseSummary + id)
}
const TestSuiteChart = id => {
  return GetApiService.GetAPI(testCycle.scriptStatus + id)
}
const executeStatus = id => {
  return GetApiService.GetAPI(testCycle.execution_script_mappings + id)
}
const executeMappings = id => {
  return GetApiService.GetAPI(testCycle.executionMappings + id)
}
const deleteTestCase = id => {
  return DeleteApiService.deleteDataFromAPI(
    testManagement.testCase.deleteTestCase + id + "/"
  )
}
const deletePreCondition = id => {
  return DeleteApiService.deleteDataFromAPI(
    testManagement.testCase.preCondition.deletePreCondition + id + "/"
  )
}
const BulkDeleteTestCase = data => {
  return DeleteApiService.deleteDataFromAPI(
    testManagement.testCase.bulkDeleteTestCase,
    data
  )
}
const BulkEditTestCase = data => {
  return PutApiService.PutAPI(testManagement.testCase.bulkEditTestCase, data)
}
const generateNextTestCaseID = url => {
  return GetApiService.GetAPI(testManagement.testCase.getTestCaseId + url)
}
const GetTestCaseById = id => {
  return GetApiService.GetAPI(testManagement?.testCase.retrieve + id + "/")
}
const auditHis = id => {
  return GetApiService.GetAPI(testManagement?.testCase.auditTestCase + id)
}
const getPreConditionById = id => {
  return GetApiService.GetAPI(
    testManagement?.testCase.preCondition.viewPreCondition + id + "/"
  )
}
const uploadTestCaseTemplate = data => {
  return PostApiService.PostToAPI(testManagement.testCase.importTestCase, data)
}
const downloadTestCaseTemplate = data => {
  return PostToDownloadAPI.PostToDownloadAPI(
    testManagement.testCase.downloadTemplateTestCase,
    data
  )
}
const exportTestCaseData = data => {
  return PostToDownloadAPI.PostToDownloadAPI(
    testManagement.testCase.exportTestCase,
    data
  )
}
const GetPreConditionById = url => {
  return GetApiService.GetAPI(
    testManagement.testCase.preCondition.getPreCondition + url
  )
}
const jiraList = data => {
  return PostApiService.PostToAPI(integration.fetchJiraProjects, data)
}

const planeList = data => {
  return PostApiService.PostToAPI(integration.fetchPlaneProject, data)
}

const gitHubList = data => {
  return PostApiService.PostToAPI(integration.gitRepo, data)
}

const fetchList = data => {
  return PostApiService.PostToAPI(integration.fetchCustomField, data)
}
const deleteMap = id => {
  return DeleteApiService.deleteDataFromAPI(
    integration.mapProjectDelete + id + "/"
  )
}
const deleteAppUpload = id => {
  return DeleteApiService.deleteDataFromAPI(
    integration.browserStackDelete + id + "/"
  )
}
const createMap = data => {
  return PostApiService.PostToAPI(integration.mapProjectCreate, data)
}

const CreateRepo = data => {
  return PostApiService.PostToAPI(integration.createRepo, data)
}
const editCustomFields = (data, id) => {
  return PutApiService.PutAPI(integration.mapProjectUpdate + id + "/", data)
}

const editToggle = (data, id) => {
  return PutApiService.PutAPI(integration.browserStackUpdate + id + "/", data)
}

const getAllDbIntegrationAPI = url => {
  return GetApiService.GetAPI(testCycle.dbConnections.getdbConnections + url)
}
const deleteDbIntegration = (id, data) => {
  return DeleteApiService.deleteDataFromAPI(
    testCycle.dbConnections.deleteIntegrations + id + "/",
    data
  )
}
const BulkDeleteDbIntegration = data => {
  return DeleteApiService.deleteDataFromAPI(
    testCycle.dbConnections.BulkdeleteIntegrations,
    data
  )
}
const createDbIntegration = data => {
  return PostApiService.PostToAPI(
    testCycle.dbConnections.createDbIntegrations,
    data
  )
}
const EditDbIntegration = (data, id) => {
  return PutApiService.PutAPI(
    testCycle.dbConnections.editDbIntegrations + id + "/",
    data
  )
}
const GetDbIntegrationById = id => {
  return GetApiService.GetAPI(testCycle.dbConnections.retrieve + id + "/")
}
const mapStatus = data => {
  return PostApiService.PostToAPI(defectManagement.availableStatus, data)
}
const mapPriority = data => {
  return PostApiService.PostToAPI(defectManagement.availablePriority, data)
}

const userProfile = (data, id) => {
  return PostApiService.PostToAPI(validate.userProfile + id + "/", data)
}

const getUserProfile = ( id) => {
  return  GetApiService.GetAPI(validate.getUserProfile + id + "/")
}

const ProjectDashboardStats = id => {
  return GetApiService.GetAPI(dashboard.projectDashboard.dashboardSummary + id)
}

const GlobalDashboardDefSeverity = id => {
  return GetApiService.GetAPI(
    dashboard.globalDashboard.globalDashboardAllSeverity + id
  )
}

const GlobalDashboardDefPriority = id => {
  return GetApiService.GetAPI(
    dashboard.globalDashboard.getDefectsAllPriority + id
  )
}

const GlobalDashboardDefRepoerted = id => {
  return GetApiService.GetAPI(
    dashboard.globalDashboard.globaldashboardDefectsAllStatus + id
  )
}

const GlobalDashboardPartitionUser = id => {
  return GetApiService.GetAPI(
    dashboard.globalDashboard.globalDashboardPartitionUser + id
  )
}

const GlobalDashboardProjectBreakDown = id => {
  return GetApiService.GetAPI(
    dashboard.globalDashboard.globalDashboardProjectBreakDown + id
  )
}

const GlobalDashboardRequirementBreakDown = id => {
  return GetApiService.GetAPI(
    dashboard.globalDashboard.globalDashboardRequirementBreakDown + id
  )
}

const GlobalDashboardDefectsType = id => {
  return GetApiService.GetAPI(dashboard.globalDashboard.getDefectsDetails + id)
}

const GlobalDashboardDefectsPriority = id => {
  return GetApiService.GetAPI(dashboard.globalDashboard.getDefectsPriority + id)
}

const GlobalDashboardDefectsSummary = id => {
  return GetApiService.GetAPI(dashboard.globalDashboard.summery + id)
}

//Project dashboard
const ProjectDashboardSeverityDef = id => {
  return GetApiService.GetAPI(dashboard.projectDashboard.SeverityDefects + id)
}

const ProjectDashboardStatusDef = id => {
  return GetApiService.GetAPI(dashboard.projectDashboard.StatusDefects + id)
}

const ProjectDashboardTestCaseStatus = id => {
  return GetApiService.GetAPI(dashboard.projectDashboard.testCaseStatus + id)
}

const ProjectDashboardTestCaseExecuted = id => {
  return GetApiService.GetAPI(dashboard.projectDashboard.testCaseExecute + id)
}

const ProjectDashboardTestCaseFail = id => {
  return GetApiService.GetAPI(
    dashboard.projectDashboard.ProjectDashboardTestCaseFail + id
  )
}

const ProjectDashboardDefectReopen = id => {
  return GetApiService.GetAPI(
    dashboard.projectDashboard.ProjectdashboardDefectRepened + id
  )
}

const ProjectDashboardDefectAging = id => {
  return GetApiService.GetAPI(
    dashboard.projectDashboard.ProjectdashboardDefectAging + id
  )
}

const ProjectDashboardActiveDefect = id => {
  return GetApiService.GetAPI(
    dashboard.projectDashboard.ProjectdashboardActiveDefect + id
  )
}

const ProjectDashboardDefectClosure = id => {
  return GetApiService.GetAPI(
    dashboard.projectDashboard.ProjectdashboardDefectClosureEfficiency + id
  )
}

const ProjectDashboardDefectWeekWise = data => {
  return PostApiService.PostToAPI(
    dashboard.projectDashboard.ProjectdashboardDefectWeekWise,
    data
  )
}

const ProjectDashboardDefectTypeCount = id => {
  return GetApiService.GetAPI(
    dashboard.projectDashboard.ProjectdashboardDefectTypeCount + id
  )
}

const ProjectdashboardMilestoneTracking = id => {
  return GetApiService.GetAPI(
    dashboard.projectDashboard.ProjectdashboardMilestoneTracking + id
  )
}

const ProjectdashboardUserList = id => {
  return GetApiService.GetAPI(
    dashboard.projectDashboard.UsersListForProjectDashbord + id
  )
}

const ProjectdashboardDefectStatics = id => {
  return GetApiService.GetAPI(
    dashboard.projectDashboard.ProjectdashboardDefectStatistics + id
  )
}

const editCycle = (data, id) => {
  return PutApiService.PutAPI(testCycle.editTestCycle + id + "/", data)
}
const createCycle = data => {
  return PostApiService.PostToAPI(testCycle.createTestCycle, data)
}
const getNextCycleId = url => {
  return GetApiService.GetAPI(testCycle.getTestCycleId + url)
}

const agentCreationList = id => {
  return GetApiService.GetAPI(testCycle.agentCreation.getAgents + id)
}

const appUploadList = id => {
  return GetApiService.GetAPI(integration.getBrowserStack + id)
}
const osApi = data => {
  return PostApiService.PostToAPI(testCycle.fetchos, data)
}
const webApi = () => {
  return GetApiService.GetAPI(testCycle?.fetchweb)
}
const tokenFetch = data => {
  return PostApiService.PostToAPI(testCycle.agentCreation?.repoToken, data)
}
const createAgent = data => {
  return PostApiService.PostToAPI(testCycle.agentCreation?.createAgent, data)
}
const uploadApp = data => {
  return PostApiService.PostToAPI(integration.browserStackCreate, data)
}
const deleteAgent = (id, data) => {
  return DeleteApiService.deleteDataFromAPI(
    testCycle.agentCreation.deleteAgent + id + "/",
    data
  )
}
const GetTestCycldExecuteById = id => {
  return GetApiService.GetAPI(testCycle.executeRetrieve + id + "/")
}
const getRequestList = id => {
  return GetApiService.GetAPI(notifications?.requestDetails + id)
}
const getNotificationListTC = id => {
  return GetApiService.GetAPI(notifications?.notificationDetailsTC + id)
}
const getNotificationListTP = id => {
  return GetApiService.GetAPI(notifications?.notificationDetailsTP + id)
}

const getNotificationListViewTC = id => {
  return GetApiService.GetAPI(notifications?.notificationDetailsViewTC + id)
}
const getNotificationListViewTP = id => {
  return GetApiService.GetAPI(notifications?.notificationDetailsViewTP + id)
}

const updateAudit = (data, id) => {
  return PutApiService.PutAPI(notifications.editAuditHistory + id + "/", data)
}
const BulkUpdateAudit = (data) => {
  return PutApiService.PutAPI(notifications.bulkEditAuditHistory, data)
}

const bulkNotify = data => {
  return PostApiService.PostToAPI(notifications.notifyBulk, data)
}
export default {
  login,
  ExtendSession,
  projectDetails,
  tagStatus,
  deleteTag,
  GetTagById,
  createTags,
  EditTags,
  TagsDetails,
  getTagsStatus,
  createProject,
  getRequirements,
  deleteProject,
  teamDetails,
  teamsAvailable,
  userDelete,
  addUser,
  ProjectDetailsView,
  deleteRequirement,
  deleteBulkRequirement,
  createTestScenario,
  generateNextScenarioID,
  getAllTestScenarioAPI,
  globalDashboardDefectStatusAPI,
  globalDashboardDefectSeverityAPI,
  getAllTestDataAPI,
  GetTestDataById,
  createTestData,
  EditTestData,
  BulkDeleteTestData,
  uploadTestDataTemplate,
  deleteTestData,
  getAllTestPlanAPI,
  GetTestPlanById,
  deleteTestPlan,
  getTestPlanStatus,
  createTestPlan,
  EditTestPlan,
  generateNextTestPlanID,
  deleteTestScenario,
  BulkDeleteTestScenario,
  EditTestScenario,
  BulkEditTestScenario,
  downloadTestScenarioTemplate,
  exportTestScenarioData,
  uploadTestScenarioTemplate,
  GetTestScenarioById,
  TagStatus,
  RequirementDetails,
  executeMappings,
  ModuleDetails,
  TeamsDetails,
  BuildsDetails,
  generateRequirement,
  CreateRequirement,
  getAllBuildsAPI,
  getAllModulessAPI,
  getAllRequirementsAPI,
  getUserDetailsListAPI,
  getTeamsDetailsUserAssigneeAPI,
  getUserListAPI,
  getTeamDetailsUserAPI,
  BulkEditRequirement,
  EditRequirement,
  RequirementView,
  EditProject,
  getTestCycleDetails,
  downloadRequirementTemplate,
  exportRequirementData,
  uploadRequirementTemplate,
  forgotPassword,
  getAllIntegrationList,
  getAllMapList,
  deleteIntegration,
  getIntegrationRetrieve,
  getTestPlanDefectStatus,
  getTestPlanDefectList,
  getTestPlanSummaryReports,
  getTestPlanResponsibleResources,
  getTestPlanResult,
  testPlanSummaryReportsAdd,
  testPlanSummaryReportsEdit,
  testPlanPdf,
  testPlanSignoff,
  testPlanResultChart,
  testPlanSummaryReportsHistory,
  getAllDefectsList,
  deleteDefect,
  uploadDefectTemplate,
  GetDefectById,
  BulkDeleteDefect,
  BulkEditDefects,
  exportDefectData,
  downloadDefectTemplate,
  getAllDefectsIdSList,
  getAllRolesList,
  getRolesRetrieve,
  deleteRoles,
  ProjectModuleDetails,
  DeleteModule,
  UpdateModule,
  GenerateModuleId,
  BulkDeleteModule,
  CreateModule,
  BuildsDetails,
  BulkDeleteBuild,
  CreateBuild,
  GenerateBuildId,
  ProjectBuildDetails,
  UpdateBuild,
  DeleteBuild,
  createRoles,
  editRoles,
  BulkDeleteReportsTemplates,
  getAllReporstTemplate,
  deleteSavedTemplate,
  deleteScheduleReport,
  createScheduleReport,
  GetSingleReportTemplate,
  saveAsTemplate,
  getDefectReportsData,
  getTestCaseData,
  getTestExecutionData,
  getRequirementTraceabilityData,
  downloadDefectReports,
  getAllScheduleReportAPI,
  ProjectdasboardCycleComparisonAPI,
  CycleComparisonTestCaseResultAPI,
  AutomationSuiteDetails,
  downloadTestCaseReports,
  downloadTestExecutionReports,
  downloadRequirementTraceabilityReports,
  DeleteAutomationSuite,
  UpdateAutomationSuite,
  BulkDeleteAutomationSuite,
  CreateAutomationSuite,
  GenerateAutomationSuiteId,
  GetAutomationSuiteView,
  AutomationScriptDetails,
  CreateAutomationScript,
  BulkDeleteAutomationScript,
  GetAutomationScriptView,
  GenerateAutomationScriptId,
  UpdateAutomationScript,
  DeleteAutomationScript,
  WebActionDetails,
  DbConnectionDetails,
  DbConnectionAutoscript,
  GetAutomationSuiteView,
  deleteUser,
  getUsersRetrieve,
  createUsers,
  editUsers,
  AutomationScriptDetails,
  GetAutomationSuiteView,
  deleteUser,
  getUsersRetrieve,
  createUsers,
  editIntegration,
  TestCaseDetails,
  deleteTestCase,
  BulkDeleteTestCase,
  BulkEditTestCase,
  generateNextTestCaseID,
  GetTestCaseById,
  uploadTestCaseTemplate,
  downloadTestCaseTemplate,
  exportTestCaseData,
  GetPreConditionById,
  createIntegration,
  jiraList,
  createMap,
  deleteMap,
  planeList,
  gitHubList,
  fetchList,
  editCustomFields,
  getAllDbIntegrationAPI,
  deleteDbIntegration,
  BulkDeleteDbIntegration,
  createDbIntegration,
  EditDbIntegration,
  GetDbIntegrationById,
  jiraList,
  createMap,
  deleteMap,
  planeList,
  gitHubList,
  fetchList,
  editCustomFields,
  createDefect,
  editDefect,
  generateDefectId,
  mapStatus,
  mapPriority,
  syncPlane,
  syncJira,
  fetchJiraUsers,
  jiraList,
  createMap,
  deleteMap,
  planeList,
  gitHubList,
  fetchList,
  editCustomFields,
  createDefect,
  editDefect,
  generateDefectId,
  mapStatus,
  mapPriority,
  syncPlane,
  syncJira,
  fetchJiraUsers,
  TestCaseChart,
  PreCondition,
  createTestCase,
  createPreCondition,
  editTestCase,
  editPrecondition,
  deletePreCondition,
  getPreConditionById,
  deleteCycle,
  BulkDeleteCycle,
  GetTestCycleById,
  getExecutionDetails,
  getExecutionChart,
  userProfile,
  deleteCycle,
  BulkDeleteCycle,
  GetTestCycleById,
  getExecutionDetails,
  getExecutionChart,
  bulkExecute,
  updateExecute,
  TestSuiteChart,
  executeStatus,
  updateSuiteStatus,
  trigger,
  ProjectDashboardStats,
  jiraList,
  createMap,
  deleteMap,
  planeList,
  gitHubList,
  fetchList,
  editCustomFields,
  createDefect,
  editDefect,
  generateDefectId,
  mapStatus,
  mapPriority,
  syncPlane,
  syncJira,
  fetchJiraUsers,
  TestCaseChart,
  PreCondition,
  createTestCase,
  createPreCondition,
  editTestCase,
  editPrecondition,
  deletePreCondition,
  getPreConditionById,
  deleteCycle,
  BulkDeleteCycle,
  GetTestCycleById,
  getExecutionDetails,
  getExecutionChart,
  userProfile,
  deleteCycle,
  BulkDeleteCycle,
  GetTestCycleById,
  getExecutionDetails,
  getExecutionChart,
  bulkExecute,
  updateExecute,
  TestSuiteChart,
  executeStatus,
  updateSuiteStatus,
  trigger,
  GlobalDashboardDefSeverity,
  GlobalDashboardDefPriority,
  GlobalDashboardDefRepoerted,
  editCycle,
  createCycle,
  getNextCycleId,
  agentCreationList,
  osApi,
  webApi,
  ProjectDashboardSeverityDef,
  ProjectDashboardStatusDef,
  ProjectDashboardTestCaseStatus,
  ProjectDashboardTestCaseExecuted,
  webApi,
  deleteAgent,
  tokenFetch,
  createAgent,
  deleteAppUpload,
  editToggle,
  uploadApp,
  appUploadList,
  ProjectDashboardTestCaseFail,
  GlobalDashboardPartitionUser,
  GlobalDashboardProjectBreakDown,
  GlobalDashboardRequirementBreakDown,
  GlobalDashboardDefectsType,
  GlobalDashboardDefectsPriority,
  GlobalDashboardDefectsSummary,
  getNextCycleId,
  agentCreationList,
  osApi,
  webApi,
  ProjectDashboardSeverityDef,
  ProjectDashboardStatusDef,
  ProjectDashboardTestCaseStatus,
  ProjectDashboardTestCaseExecuted,
  ProjectDashboardDefectReopen,
  ProjectDashboardDefectAging,
  ProjectDashboardActiveDefect,
  ProjectDashboardDefectClosure,
  ProjectDashboardDefectWeekWise,
  ProjectdashboardMilestoneTracking,
  ProjectDashboardDefectTypeCount,
  ProjectdashboardUserList,
  ProjectdashboardDefectStatics,
  webApi,
  deleteAgent,
  tokenFetch,
  createAgent,
  deleteAppUpload,
  editToggle,
  uploadApp,
  appUploadList,
  editApp,
  CreateRepo,
  GetTestCycldExecuteById,
  ProjectList,
  getRequestList,
  getNotificationListTC,
  getNotificationListTP,
  updateAudit,
  BulkUpdateAudit,getNotificationListViewTC,getNotificationListViewTP,
  auditHis,
  resetPassword ,bulkNotify,
  auditHis,getUserProfile,tagStatusEditData,getUserTeamListAPI
}
