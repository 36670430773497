import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledTooltip,
} from "reactstrap"

// Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// i18n
import { withTranslation } from "react-i18next"
import DonutChart from "./DonutChart"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import {
  GlobalDashboardDefectsPriority,
  GlobalDashboardDefectsSummary,
  GlobalDashboardDefectStatusAPI,
  GlobalDashboardDefectsType,
  GlobalDashboardDefPriority,
  GlobalDashboardDefRepoerted,
  GlobalDashboardDefSeverity,
  GlobalDashboardPartitionUser,
  GlobalDashboardProjectBreakDown,
  GlobalDashboardRequirementBreakDown,
} from "store/actions/DashboardAction"
import WelcomeComp from "./WelcomeComp"
import MyProjects from "./MyProjects"
import ProjectBreakDown from "./ProjectBreakDown"
import Spinners from "components/Common/Spinner"
import BubbleChart from "../Charts/BubbleChart"
import GlobalDashboardDetails from "./GlobalDashboardDetails"
import NoProject from "components/Common/NoProject"
import { ProjectList } from "store/actions/ProjectActions"
import { toast } from "react-toastify"
import NoData from "assets/images/No_permission.png"
import NoPermission from "components/Common/NoPermission"

const Dashboard = props => {
  const layoutModeType = useSelector(state => state.Layout.layoutModeType)
  const [loading, setLoading] = useState(true)
  const [isProjectsPresent, setisProjectsPresent] = useState(false)
  const globalDashboardSeverityData = useSelector(
    state => state?.Dashboard?.GlobalDashboardSeverity?.data
  )
  const globalDashboardPriorityData = useSelector(
    state => state?.Dashboard?.GlobalDashboardPriority?.data
  )
  const globalDashboardReportedData = useSelector(
    state => state?.Dashboard?.GlobalDashboardReported?.data
  )
  const projects = useSelector(state => state?.Project?.allProjectList)
  const projectListData = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission =
    projectListData?.permissions?.[0]?.menu_item?.Dashboard.GlobalDashboard

  const [textColor, setTextColor] = useState("black")
  const disapacth = useDispatch()

  const [showGlobalDashboardDetails, setShowGlobalDashboardDetails] =
    useState(false) // State to toggle visibility

  const breadcrumbItems = [
    { label: "Dashboard", path: "" },
    { label: "Global Dashboard", path: "" },
  ]

  if (
    globalDashboardPriorityData &&
    globalDashboardSeverityData &&
    globalDashboardReportedData
  ) {
    var priorityTotalCount = globalDashboardPriorityData?.total_defects_priority
    var sevierityTotalCount =
      globalDashboardSeverityData?.total_defects_serverity
    var ReportedTotalCount = globalDashboardReportedData?.total_defects_status
    var severitydata = globalDashboardSeverityData?.severity_defects_counts
    var prioritydatas = globalDashboardPriorityData?.priority_defects_counts
    var ReportedData = globalDashboardReportedData?.status_defects_counts
    var priorityLabel = ["High", "Medium", "Low"]
    var sevierityLabel = ["Major", "Minor", "Blocker"]
    var ReportedLabel = ["Open", "To Do", "Close"]
    var prioritydata = [
      prioritydatas?.High,
      prioritydatas?.Medium,
      prioritydatas?.Low,
    ]
    var sevieritydata = [
      severitydata?.Major,
      severitydata?.Minor,
      severitydata?.Blocker,
    ]
    var Reporteddata = [
      ReportedData?.Open,
      ReportedData?.To_Do,
      ReportedData?.Close,
    ]
  }

  useEffect(() => {
    if (layoutModeType === "dark") {
      setTextColor("white")
    } else if (layoutModeType === "light") {
      setTextColor("black")
    }
  }, [layoutModeType])

  useEffect(() => {
    const checkProjectAndRunAPIs = () => {
      const GlobalSelectedProject = JSON.parse(
        localStorage.getItem("selectedProject")
      )
      if (
        GlobalSelectedProject?.value &&
        projectListData?.projects &&
        projectListData?.projects?.length > 0
      ) {
        const projIds = Array.isArray(projects)
          ? projects.map(item => item.id)
          : []

        const data = { project_id: "" }
        const body = ""
        const userFetch = { project_id: projIds }
        disapacth(GlobalDashboardDefSeverity(data))
        disapacth(GlobalDashboardDefPriority(data))
        disapacth(GlobalDashboardDefRepoerted(data))
        disapacth(GlobalDashboardProjectBreakDown(userFetch))
        disapacth(GlobalDashboardRequirementBreakDown(userFetch))
        disapacth(GlobalDashboardPartitionUser(userFetch))

        disapacth(GlobalDashboardDefectsType(data))
        disapacth(GlobalDashboardDefectsPriority(data))
        disapacth(GlobalDashboardDefectsSummary(data))
        disapacth(GlobalDashboardDefectStatusAPI(data))
        disapacth(ProjectList(body))

        setLoading(false)
        clearInterval(intervalId)
      } else if (
        projectListData?.projects &&
        projectListData?.projects.length === 0
      ) {
        // Stop loader and show NoProject if projectList is empty
        setLoading(false)
        setisProjectsPresent(true)
        clearInterval(intervalId)
      }
    }

    // Set an interval to check for GlobalSelectedProject every 500ms
    const intervalId = setInterval(checkProjectAndRunAPIs, 500)

    // Cleanup interval when the component unmounts
    return () => clearInterval(intervalId)
  }, [disapacth, projects])

  if (loading) {
    return <Spinners setLoading={() => {}} />
  }

  const handleSeeAllClick = () => {
    if (projectListData?.projects && projectListData?.projects?.length > 0) {
      setShowGlobalDashboardDetails(true)
    } else {
      toast.warn("There are no projects found..", {
        position: "top-right",
        autoClose: 3000,
      })
    }
  }

  const OnClickBack = () => {
    setShowGlobalDashboardDetails(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {rolePermission.view ? (
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title="Global Dashboard"
              breadcrumbItems={breadcrumbItems}
            />
            {showGlobalDashboardDetails ? (
              <GlobalDashboardDetails back={OnClickBack} />
            ) : (
              <div>
                <Row>
                  <Col xl="6">
                    <WelcomeComp />
                  </Col>
                  <Col xl="6">
                    <MyProjects />
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} sm={6}>
                    <Card
                      style={{
                        borderRadius: "15px",
                        border: "2px solid #d5d5d5",
                      }}
                    >
                      <CardBody>
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{
                            borderBottom: "2px solid #dfdfdf",
                            paddingBottom: "10px",
                          }}
                        >
                          <i
                            className="bx bx-time text-muted "
                            style={{ fontSize: "30px" }}
                          ></i>
                          <p
                            className="truncate-text mx-1"
                            style={{ paddingTop: "15px" }}
                          >
                            Defect Reported by Priority
                          </p>
                          <button
                            type="button"
                            className="btn btn-outline-dark"
                            style={{ width: "85px" }}
                            onClick={handleSeeAllClick}
                            id="DefeReported"
                          >
                            <span>See All</span>
                          </button>
                          <UncontrolledTooltip
                            placement="top"
                            target="DefeReported"
                          >
                            Click to see all the Defect Reported by Priority
                          </UncontrolledTooltip>

                          <div></div>
                        </div>
                        <DonutChart
                          dataColors='["#007bff", "#28a745", "#fd7e14"]'
                          textColor={textColor}
                          TotalCount={priorityTotalCount}
                          chartData={prioritydata}
                          label={priorityLabel}
                        />
                        <Row className="justify-content-center">
                          <Col sm={3}>
                            <div className="text-center">
                              <span className="dots dot-primary"></span>
                              <span className="text-truncate">High</span>
                            </div>
                          </Col>
                          <Col sm={5}>
                            <div className="text-center">
                              <span className="dots dot-success"></span>
                              <span className="text-truncate">Medium</span>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className="text-center">
                              <span className="dots dot-warning"></span>
                              <span className="text-truncate">Low</span>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl={4} sm={6}>
                    <Card
                      style={{
                        borderRadius: "15px",
                        border: "2px solid #d5d5d5",
                      }}
                    >
                      <CardBody>
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{
                            borderBottom: "2px solid #dfdfdf",
                            paddingBottom: "10px",
                          }}
                        >
                          <i
                            className="bx bx-time text-muted"
                            style={{ fontSize: "30px" }}
                          ></i>
                          <p
                            className="truncate-text mx-1"
                            style={{ paddingTop: "15px" }}
                          >
                            Defect Reported by Severity
                          </p>
                          <button
                            type="button"
                            className="btn btn-outline-dark "
                            style={{ width: "85px" }}
                            onClick={handleSeeAllClick}
                            id="DefeReportedPriority"
                          >
                            <span>See All</span>
                          </button>
                          <UncontrolledTooltip
                            placement="top"
                            target="DefeReportedPriority"
                          >
                            Click to see all the Defect Reported by Severity
                          </UncontrolledTooltip>
                          <div></div>
                        </div>
                        <DonutChart
                          dataColors='["#007bff", "#28a745", "#fd7e14"]'
                          textColor={textColor}
                          TotalCount={sevierityTotalCount}
                          chartData={sevieritydata}
                          label={sevierityLabel}
                        />
                        <Row className="justify-content-center">
                          <Col sm={4}>
                            <div className="text-center">
                              <span className="dots dot-primary"></span>
                              <span className="text-truncate">Major</span>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className="text-center">
                              <span className="dots dot-success"></span>
                              <span className="text-truncate">Minor</span>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className="text-center">
                              <span className="dots dot-warning"></span>
                              <span className="text-truncate">Blocker</span>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl={4} sm={6}>
                    <Card
                      style={{
                        borderRadius: "15px",
                        border: "2px solid #d5d5d5",
                      }}
                    >
                      <CardBody>
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{
                            borderBottom: "2px solid #dfdfdf",
                            paddingBottom: "10px",
                          }}
                        >
                          <i
                            className="bx bx-time text-muted"
                            style={{ fontSize: "30px" }}
                          ></i>
                          <p
                            className="truncate-text mx-1"
                            style={{ paddingTop: "15px" }}
                          >
                            Defect Reported By Status
                          </p>
                          <button
                            type="button"
                            className="btn btn-outline-dark "
                            style={{ width: "85px" }}
                            onClick={handleSeeAllClick}
                            id="DefeReportedSeverity"
                          >
                            <span>See All</span>
                          </button>
                          <UncontrolledTooltip
                            placement="top"
                            target="DefeReportedSeverity"
                          >
                            Click to see all the Defect Reported by Status
                          </UncontrolledTooltip>
                          <div></div>
                        </div>
                        <DonutChart
                          dataColors='["#007bff", "#28a745", "#fd7e14"]'
                          textColor={textColor}
                          TotalCount={ReportedTotalCount}
                          chartData={Reporteddata}
                          label={ReportedLabel}
                        />
                        <Row className="justify-content-center">
                          <Col sm={4}>
                            <div className="text-center">
                              <span className="dots dot-primary"></span>
                              <span className="text-truncate">Open</span>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className="text-center">
                              <span className="dots dot-success"></span>
                              <span className="text-truncate">To Do</span>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className="text-center">
                              <span className="dots dot-warning"></span>
                              <span className="text-truncate">Close</span>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col xl="6">
                    <Card>
                      <h5 className="mx-4 mt-3 mb-0">Project Breakdown</h5>
                      <CardBody>
                        <ProjectBreakDown />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl="6">
                    <Card>
                      <h5 className="mx-4 mt-3 mb-0">Requirements Breakdown</h5>
                      <CardBody>
                        <BubbleChart />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            )}
          </Container>
        ) : (
          // <NoProject />
          <NoPermission />
        )}
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
