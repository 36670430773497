import {
  FETCH_PROJECT_DETAILS, FETCH_PROJECT_DETAILS_FAILURE, FETCH_PROJECT_DETAILS_SUCCESS
  , TAG_STATUS, TAG_STATUS_DETAILS_FAILURE, TAG_STATUS_DETAILS_SUCCESS,
  CREATE_PROJECT, CREATE_PROJECT_FAILURE, CREATE_PROJECT_SUCCESS, SET_COUNTRIES,  
} from "./actionTypes"

const INIT_STATE = {
  projectDetails: [],
  isLoading: false, // Add this line to manage loading state
  error: null,
  tagStatus: [],
  createProject: [],
  countries: [], 
};

const ProjectManagement = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_PROJECT_DETAILS:
      return { ...state, isLoading: true };
    case FETCH_PROJECT_DETAILS_SUCCESS:
      return { ...state, projectDetails: action.payload, isLoading: false };
    case FETCH_PROJECT_DETAILS_FAILURE:
      return { ...state, error: action.payload, isLoading: false };
    case TAG_STATUS:
      return { ...state, isLoading: true };
    case TAG_STATUS_DETAILS_FAILURE:
      return { ...state, error: action.payload, isLoading: false };
    case TAG_STATUS_DETAILS_SUCCESS:
      return { ...state, tagStatus: action.payload, isLoading: false };
    case CREATE_PROJECT:
      return { ...state, isLoading: true };
    case CREATE_PROJECT_FAILURE:
      return { ...state, error: action.payload, isLoading: false };
    case CREATE_PROJECT_SUCCESS:
      return { ...state, createProject: action.payload, isLoading: false };
    case SET_COUNTRIES:
      return { ...state, countries: action.payload, isLoading: false };  
    default:
      return state;
  }
};

export default ProjectManagement
