import React, { useState } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { FaFileExcel, FaFileCsv, FaFilePdf } from "react-icons/fa"
import { useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { ExportTestScenarioAPI } from "store/actions/TestScenarioActions"
import { useSelector } from "react-redux"
import { ExportRequirementAPI } from "store/actions/RequirementActions"
import { ExportDefectAPI } from "store/actions/DefectsActions"
import { ExportTestCaseAPI } from "store/actions/TestCaseAction"

const ExportModal = () => {
  const dispatch = useDispatch()
  const { testScenarioFilterFrom } = useSelector(state => state.TestScenario)
  const { defectsFilterFrom } = useSelector(state => state.Defects)
  const { testCaseFilterFrom } = useSelector(state => state.TestCase)
  const selectedProject = JSON.parse(
    localStorage.getItem("selectedProjectData")
  )

  const selectedProjectGlobal = JSON.parse(
    localStorage.getItem("selectedProject")
  )

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const location = useLocation()
  const locationPath = location.pathname
  const pathname = locationPath.split("/")
  const toggleDropdown = () => setDropdownOpen(prevState => !prevState)

  const handleFormatSelect = format => {
    const bodyOptionsTestScenario = {
      file_type: format,
      project_name: globalProject?.label || selectedProjectGlobal?.label,
      build_id: testScenarioFilterFrom?.build_id,
      module_id: testScenarioFilterFrom?.module_id,
      requirement_id: testScenarioFilterFrom?.requirement_id,
      created_by: testScenarioFilterFrom?.created_by,
    }
    const bodyOptionsTestCase = {
      file_type: format,
      project_name: globalProject?.label || selectedProjectGlobal?.label,
      build_id: testCaseFilterFrom?.filter_build,
      module_id: testCaseFilterFrom?.filter_module,
      requirement_id: testCaseFilterFrom?.filter_requirement,
      created_by: testCaseFilterFrom?.filter_created_by,
      test_scenario_id: testCaseFilterFrom?.filter_test_scenario_id,
      test_status: testCaseFilterFrom?.filter_test_status,
      testing_type: testCaseFilterFrom?.filter_testing_type,
      execution_type: testCaseFilterFrom?.filter_execution_type,
      approval_requested: testCaseFilterFrom?.filter_approval_from,
      assign_to: testCaseFilterFrom?.filter_assign_to,
    }

    const bodyOptionsForRequirement = {
      file_type: format,
      project_name: selectedProject?.project_name,
    }
    const bodyOptionsDefect = {
      file_type: format,
      project_name: globalProject?.label || selectedProject?.label,
      build_id: defectsFilterFrom?.filter_build,
      module_id: defectsFilterFrom?.filter_module,
      requirement_id: defectsFilterFrom?.filter_requirement,
      created_by: defectsFilterFrom?.filter_created_by,
      defect_id: defectsFilterFrom?.filter_defect_id,
      browsers: defectsFilterFrom?.filter_browsers,
      os: defectsFilterFrom?.filter_os,
      devices: defectsFilterFrom?.filter_devices,
      severity: defectsFilterFrom?.filter_severity,
      assign_to: defectsFilterFrom?.filter_assign_to,
      defect_status: defectsFilterFrom?.filter_defect_status,
      defect_type: defectsFilterFrom?.filter_defect_type,
      defect_viewers: defectsFilterFrom?.filter_defect_viewers,
    }
    if (pathname.includes("TestScenario")) {
      dispatch(ExportTestScenarioAPI(bodyOptionsTestScenario))
    } else if (pathname.includes("Requirement")) {
      dispatch(ExportRequirementAPI(bodyOptionsForRequirement))
    } else if (pathname.includes("DefectManagement")) {
      dispatch(ExportDefectAPI(bodyOptionsDefect))
    } else if (pathname.includes("TestCase")) {
      dispatch(ExportTestCaseAPI(bodyOptionsTestCase))
    }
  }

  return (
    <div>
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle
          caret
          color="secondary"
          outline
          className="d-flex align-items-center mb-2  export-btn"
        >
          {/* <i className="bx bx-export font-size-16"></i> */}
          <i className="bx bx-export font-size-16"></i>

          <span className="mx-2 hide-content">Export</span>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() => handleFormatSelect("xlsx")}
            className="d-flex align-items-center"
          >
            <FaFileExcel
              className="export-icon xlsx"
              style={{ color: "#107C10", height: "1.5rem", width: "1.5rem" }}
            />
            <span className="mx-2">Export.xlsx</span>
          </DropdownItem>
          <DropdownItem
            onClick={() => handleFormatSelect("csv")}
            className="d-flex align-items-center"
          >
            <FaFileCsv
              className="export-icon csv"
              style={{ color: "#FF9900", height: "1.5rem", width: "1.5rem" }}
            />
            <span className="mx-2">Export.csv</span>
          </DropdownItem>
          <DropdownItem
            onClick={() => handleFormatSelect("pdf")}
            className="d-flex align-items-center"
          >
            <FaFilePdf
              className="export-icon pdf"
              style={{ color: "#D13438", height: "1.5rem", width: "1.5rem" }}
            />
            <span className="mx-2">Export.pdf</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default ExportModal
