import { toast } from "react-toastify"
import httpInjectorService from "services/http-Injector.Service"
import { setLoading } from "store/Slices/LoadingSlice"
import {
  setNextTestCaseId,
  setPreConditionList,
  setSingleTestCaseData,setAuditTestCaseData,
  setTestCaseChart,
  setTestCaseList,
} from "store/Slices/TestCaseSlice"

export const TestCaseDetails = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.TestCaseDetails(queryString)
      const data = response
      dispatch(setTestCaseList(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const deleteTestCaseAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.deleteTestCase(bodyoption)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Test Case Deleted successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const BulkDeleteTestCaseAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (bodyoption[key] !== undefined && bodyoption[key] !== null) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.BulkDeleteTestCase(formData)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Test Scenario Deleted successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const GetTestCaseByIdAPI = id => {
  return async dispatch => {
    dispatch(setLoading(true))

    try {
      const response = await httpInjectorService.GetTestCaseById(id)
      const data = response
      dispatch(setSingleTestCaseData(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const GetPreConditionByIdAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    try {
      const response = await httpInjectorService.GetPreConditionById(
        queryString
      )
      const data = response
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const BulkEditTestCaseAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.BulkEditTestCase(formData)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Test Case updated successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const fetchNextCaseID = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.generateNextTestCaseID(
        queryString
      )
      const data = response
      dispatch(setNextTestCaseId(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const uploadTestCaseTemplateAPI = bodyOption => {
  return async dispatch => {
    dispatch(setLoading(true))

    try {
      const response = await httpInjectorService.uploadTestCaseTemplate(
        bodyOption
      )

      return response
    } catch (error) {
      console.error("API Error:", error)
      throw error // Re-throw the error to be caught in `handleUpload`
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const ExportTestCaseAPI = bodyOption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyOption) {
      if (
        bodyOption[key] !== undefined &&
        bodyOption[key] !== null &&
        bodyOption[key] !== ""
      ) {
        formData.append(key, bodyOption[key])
      }
    }
    try {
      const response = await httpInjectorService.exportTestCaseData(formData)
      const blob = response.data
      const downloadLink = document.createElement("a")
      downloadLink.href = window.URL.createObjectURL(blob)
      downloadLink.download = "Test Case"
      document.body.appendChild(downloadLink)

      // Programmatically trigger the download
      downloadLink.click()

      // Clean up
      document.body.removeChild(downloadLink)
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      console.error("Download failed:", error.message || error)
    }
  }
}
export const downloadTestCaseTemplateAPI = bodyOption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyOption) {
      if (
        bodyOption[key] !== undefined &&
        bodyOption[key] !== null &&
        bodyOption[key] !== ""
      ) {
        formData.append(key, bodyOption[key])
      }
    }
    try {
      const response = await httpInjectorService.downloadTestCaseTemplate(
        formData
      )
      const blob = response.data
      const downloadLink = document.createElement("a")
      downloadLink.href = window.URL.createObjectURL(blob)
      downloadLink.download = "Test Case Template"
      document.body.appendChild(downloadLink)

      // Programmatically trigger the download
      downloadLink.click()

      // Clean up
      document.body.removeChild(downloadLink)
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      console.error("Download failed:", error.message || error)
    }
  }
}
export const TestCaseCharts = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.TestCaseChart(queryString)
      const data = response
      dispatch(setTestCaseChart(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const fetchAllPreConditon = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.PreCondition(queryString)
      const data = response
      dispatch(setPreConditionList(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const CreateTestCase = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.createTestCase(formData)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Test Scenario created successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      } else {
        throw new Error(response.errors?.test_case_name[0] || response.errors)
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const EditTestCase = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.editTestCase(
        formData,
        bodyoption?.id
      )
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Test Scenario updated successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      } else {
        throw new Error(JSON.stringify(response.errors))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const CreatePrecondition = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.createPreCondition(formData)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Test Scenario created successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      } else {
        throw new Error(response.error)
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const EditPrecondition = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.editPrecondition(
        formData,
        bodyoption?.id
      )
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Test Scenario updated successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      } else {
        throw new Error(response.error)
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const deletePreCondition = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.deletePreCondition(bodyoption)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Test Case Deleted successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const GetPreConditionById = id => {
  return async dispatch => {
    dispatch(setLoading(true))

    try {
      const response = await httpInjectorService.getPreConditionById(id)
      const data = response
      dispatch(setSingleTestCaseData(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const getAuditHistory = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }

    try {
      const response = await httpInjectorService.auditHis(queryString)
      const data = response
      dispatch(setAuditTestCaseData(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}