import { createSlice } from "@reduxjs/toolkit"

const UserSlice = createSlice({
  name: "User",
  initialState: {
    allUserDetailsList: "",
  },

  editUsers: {
    isEdit: false,
    data: null,
  },
  getUserProfile:[],
  singleUserData: null,
  reducers: {
    //for created by
    setAllUserDetailsList: (state, action) => ({
      ...state,
      allUserDetailsList: action.payload,
    }),
    setEditUsers: (state, action) => ({
      ...state,
      editUsers: action.payload,
    }),
    setSingleUserData: (state, action) => ({
      ...state,
      singleUserData: action.payload,
    }),
    setGetUserProfile: (state, action) => ({
      ...state,
      getUserProfile: action.payload,
    }),
  },
})

export const { setAllUserDetailsList,setEditUsers,setSingleUserData ,setGetUserProfile} = UserSlice.actions

export default UserSlice.reducer
