import PropTypes from 'prop-types'
import React, { useState } from "react"
import {Label, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap"
import Select from "react-select"
import { setBulkExecute } from 'store/Slices/TestCycleSlice'
import { useDispatch } from 'react-redux'


const BulkExecutePopup = ({ show, onCloseClick,onExecute }) => {

    const dispatch = useDispatch()
    const status = [
        { value: "NOTRUN", label: "NOT RUN" },
        { value: "PASSED", label: "PASSED" },
        { value: "FAILED", label: "FAILED" }
    ]

    const customStyles = {
        menu: provided => ({
            ...provided,
            backgroundColor: "white",
        }),
        menuList: provided => ({
            ...provided,
            maxHeight: 150,
            backgroundColor:"white", // Set max height for dropdown
            overflowY: "auto", // Enable vertical scroll
            zIndex: 1000, // Ensure dropdown appears above other elements
          }),
    }

    const [selectStatus, setSelectStatus] = useState(null);
    const [err, setErr] = useState(false);
    
    
    const handleExecute = () => {
        if (!selectStatus) {
            setErr(true)
        } else {
            setErr(false)
            onExecute()
            setSelectStatus(null)
        }
    }
    

    const handleClose = () => {
        setErr(false)
        setSelectStatus(null)
        onCloseClick()
    }

    const handleOption = (e) => {       
        setSelectStatus(e?.value)
        dispatch(setBulkExecute({ data: e?.value }))
    }

    return (
        <Offcanvas size="md" isOpen={show} direction="end" toggle={onCloseClick}>
            <OffcanvasHeader toggle={onCloseClick}>Bulk Execute</OffcanvasHeader>
                <OffcanvasBody className="px-4 text-left d-flex flex-column justify-content-between">
                    <div>
                    <Label>
                        Status <span className="text-danger">*</span>
                    </Label>
                    <Select
                        className='mb-2'
                        defaultValue={
                            selectStatus ? { label: selectStatus, value: selectStatus } : ""
                        }
                        label="Single select"
                        onChange={handleOption}
                        options={status}
                        styles={customStyles}
                    />
                    {err && (
                        <span className="text-danger mb-2">Select status to bulk execute</span>
                    )}
                    </div>
                    <div className="hstack mt-2 gap-2 justify-content-between mb-0">
                        <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                        <button type="button" className="btn btn-success" onClick={()=>{handleExecute()}}>Execute</button>
                    </div>
                </OffcanvasBody>
        </Offcanvas>
    )
}

BulkExecutePopup.propTypes = {
    onCloseClick: PropTypes.func,
    onExecute: PropTypes.func,
    show: PropTypes.any,
    message: PropTypes.any
}

export default BulkExecutePopup
