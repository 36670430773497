import axios from "axios"
import BASE_URL from "./apiConfig"
import { getToken } from "helpers/helper"

const PostToDownloadAPI = async (url, paramsObj, headersObj = {}) => {
  // Construct the full URL with query parameters
  const queryString = new URLSearchParams(paramsObj).toString()
  const fullUrl = `${BASE_URL}/api/${url}?${queryString}`

  // Retrieve and parse the token
  let tokenid = getToken()

  // Add Authorization header if token exists
  const headers = tokenid
    ? { ...headersObj, Authorization: `Bearer ${tokenid}` }
    : headersObj

  try {
    // Send the POST request with an empty body
    const response = await axios.post(fullUrl, null, {
      headers,
      responseType: "blob",
    })
    return response // Return the entire response, not just response.data
  } catch (error) {
    // Handle errors
    return error.response
      ? error.response
      : { error: "An unknown error occurred" }
  }
}

export default { PostToDownloadAPI }
