import React from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';

const PolarChart = ({ data }) => {
  const layoutModeType = useSelector((state) => state.Layout.layoutModeType);

  // Check if data is still loading
  const isLoading = !data || Object.keys(data).length === 0;

  // Default values in case data is undefined or not available
  const {
    approved_percentage = 0,
    rejected_percentage = 0,
    pending_percentage = 0,
    total_test_cases = 0,
    new_percentage = 0,  // New percentage value
  } = data || {};

  // Define colors for dark and light modes
  const colors = {
    approved: '#54d297',
    rejected: '#ff776e',
    pending: '#ffc476',
    new: '#1e90ff', // New color for the "New" category
  };

  // Set the chart colors based on layout mode
  const chartColors = {
    approved: layoutModeType === 'dark' ? '#54d297' : colors.approved,
    rejected: layoutModeType === 'dark' ? '#ff776e' : colors.rejected,
    pending: layoutModeType === 'dark' ? '#ffc476' : colors.pending,
    new: layoutModeType === 'dark' ? '#1e90ff' : colors.new,  // Chart color for "New"
  };

  const series = [
    parseFloat(approved_percentage),
    parseFloat(rejected_percentage),
    parseFloat(pending_percentage),
    parseFloat(new_percentage),  // Add new percentage to the series
  ];

  const options = {
    chart: {
      type: 'polarArea',
      background: layoutModeType === 'dark' ? '#2a3042' : '#ffffff',
    },
    title: {
      text: ' ',
      style: {
        color: layoutModeType === 'dark' ? '#ffffff' : '#000000',
      },
    },
    colors: [
      chartColors.approved, 
      chartColors.rejected, 
      chartColors.pending, 
      chartColors.new,  // Include new category color
    ],
    plotOptions: {
      polarArea: {
        dataLabels: {
          show: true,
        },
      },
    },
    legend: {
      show: false,  // Hide the legend
    },
    tooltip: {
      theme: layoutModeType === 'dark' ? 'dark' : 'light',
      custom: ({ seriesIndex }) => {
        const labels = ['Approved', 'Rejected', 'Pending', 'New'];  // Add "New" label
        const percentages = [
          approved_percentage, 
          rejected_percentage, 
          pending_percentage, 
          new_percentage,  // Include new percentage in the tooltip
        ];
        return `<div style="padding: 10px; background: ${layoutModeType === 'dark' ? '#333' : '#fff'}; color: ${layoutModeType === 'dark' ? '#fff' : '#000'}">
                  <b>${labels[seriesIndex]}</b><br/>
                  ${percentages[seriesIndex]}<br/>
                  Total Test Cases: ${total_test_cases}
                </div>`;
      },
    },
    xaxis: {
      categories: ['Approved', 'Rejected', 'Pending', 'New'],  // Add "New" to categories
      labels: {
        style: {
          colors: layoutModeType === 'dark' ? '#ffffff' : '#000000',
        },
      },
    },
  };

  // Conditionally render the chart only when data is available
  if (isLoading) {
    return <div>Loading data...</div>;
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <ReactApexChart
        type="polarArea"
        options={options}
        series={series}
        height={327}
      />
      <div style={{
        marginTop: '20px',
        fontSize: '14px',
        color: layoutModeType === 'dark' ? '#ffffff' : '#000000',
        display: 'flex',
        justifyContent: 'center',
        gap: '20px',
        flexWrap: 'wrap',
      }}>
        <div><span style={{ color: chartColors.approved, fontSize: '24px' }}>●</span> Approved</div>
        <div><span style={{ color: chartColors.rejected, fontSize: '24px' }}>●</span> Rejected</div>
        <div><span style={{ color: chartColors.pending, fontSize: '24px' }}>●</span> Pending</div>
        <div><span style={{ color: chartColors.new, fontSize: '24px' }}>●</span> New</div> {/* New label */}
      </div>
    </div>
  );
};

export default PolarChart;
