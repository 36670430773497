import React, { Fragment, useEffect, useState } from "react"
import {
  Row,
  Table,
  Button,
  Input,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap"
import { Link, useLocation } from "react-router-dom"

import {
  // Column,
  // Table as ReactTable,
  // ColumnFiltersState,
  // FilterFn,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table"

import { rankItem } from "@tanstack/match-sorter-utils"
import NoData from "components/Common/NoData"

// Column Filter
const Filter = ({ column }) => {
  const columnFilterValue = column.getFilterValue()

  const location = useLocation()
  const locationPath = location.pathname
  const pathname = locationPath.split("/").filter(Boolean).pop()
  console.log("pathname",pathname);
  

  return (
    <>
      <DebouncedInput
        type="text"
        value={columnFilterValue ?? ""}
        onChange={value => column.setFilterValue(value)}
        placeholder="Search..."
        className="w-36 border shadow rounded"
        list={column.id + "list"}
      />
      <div className="h-1" />
    </>
  )
}

// Global Filter
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [debounce, onChange, value])

  return (
    <React.Fragment>
      <Col sm={4} className="d-flex align-items-center">
        <InputGroup className="w-100" style={{ height: "36px" }}>
          <InputGroupText style={{ height: "100%", padding: "0 12px" }}>
            <i className="bx bx-search-alt search-icon"></i>
          </InputGroupText>
          <Input
            {...props}
            value={value}
            onChange={e => setValue(e.target.value)}
            style={{ height: "100%" }}
          />
        </InputGroup>
      </Col>
    </React.Fragment>
  )
}

const BulkExecute = ({
  setPageIndex,
  setPageSize,
  globalFilter,
  handleExecuteClick,
  hanldeBack,
  data
}) => {



  
  // Helper function to get the visible page numbers

  // Separate useEffect to reset pageIndex only when search term changes
  useEffect(() => {
    if (globalFilter) {
      setPageIndex(0) // Reset to the first page only if globalFilter changes
    }
  }, [globalFilter]) // Depend only on globalFilter


  return (
    <Fragment>
      <Row className="mt-2 mb-2">
      <div className={`d-flex justify-content-between`}>
              <div className="text-sm-end ms-2">
                <Button
                  type="button"
                  color="light"
                  className="btn-label"
                  onClick={hanldeBack}
                >
                  <i className="mdi mdi-arrow-left label-icon"></i>{data}
                </Button>
              </div>
              <div className="text-sm-end me-2">
                <Button
                  type="button"
                  color="primary"
                  className="btn-label"
                  onClick={handleExecuteClick}
                >
                  <i className="mdi mdi-play label-icon"></i> Bulk Execute
                </Button>
              </div>
        </div>
      </Row>
    </Fragment>
  )
}

export default BulkExecute
