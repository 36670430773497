import { createSlice } from "@reduxjs/toolkit"

const IntegrationSlice = createSlice({
  name: "Integration",
  initialState: {
    allUserDetailsList: "",
  },
  mapList : [],
  integrationList : [],

  editIntegration: {
    isEdit: false,
    data: null,
  },
  viewIntegrationforCustom: {
    clicked: false,
    editmode: false,
    data:null
  },
  singleIntegrationData: null,
  reducers: {

    setEditIntegration: (state, action) => ({
      ...state,
      editIntegration: action.payload,
    }),

    setSingleIntegrationData: (state, action) => ({
      ...state,
      singleIntegrationData: action.payload,
    }),
    setMapList: (state, action) => ({
      ...state,
      mapList: action.payload,
    }),
    setIntegrationList: (state, action) => ({
      ...state,
      integrationList: action.payload,
    }),
    setViewIntegrationforCustom: (state, action) => ({
      ...state,
      viewIntegrationforCustom: action.payload,
    }),
  },
})

export const { setAllUserDetailsList,setSingleIntegrationData,setEditIntegration,setViewIntegrationforCustom,setMapList,setIntegrationList} = IntegrationSlice.actions

export default IntegrationSlice.reducer
