import { toast } from "react-toastify"
import httpInjectorService from "services/http-Injector.Service"
import { setLoading } from "store/Slices/LoadingSlice"
import { setAllRoles, setSingleRolesData } from "store/Slices/RolesSlice"

export const fetchAllRolesList = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.getAllRolesList(queryString)
      const data = response
      dispatch(setAllRoles(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const deleteRoles = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))

    try {
      const response = await httpInjectorService.deleteRoles(bodyoption)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Roles Deleted successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const rolesRetrieveApi = id => {
  return async dispatch => {
    dispatch(setLoading(true))

    try {
      const response = await httpInjectorService.getRolesRetrieve(id)
      const data = response
      dispatch(setSingleRolesData(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const createRoles = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (bodyoption.hasOwnProperty(key)) {
        if (key === "menu_item" && bodyoption[key]) {
          formData.append("menu_item", JSON.stringify(bodyoption.menu_item))
        } else if (
          bodyoption[key] !== undefined &&
          bodyoption[key] !== null &&
          bodyoption[key] !== ""
        ) {
          formData.append(key, bodyoption[key])
        }
      }
    }
    try {
      const response = await httpInjectorService.createRoles(formData)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Role created successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
        return response;
      }else {
        const errorData = response;
        throw new Error(errorData.errors.non_field_errors || errorData.errors || 'Something went wrong');
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error)
    }
    dispatch(setLoading(false))
  }
}

export const EditRoles = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (bodyoption.hasOwnProperty(key)) {
        if (key === "menu_item" && bodyoption[key]) {
          formData.append("menu_item", JSON.stringify(bodyoption.menu_item))
        } else if (
          bodyoption[key] !== undefined &&
          bodyoption[key] !== null &&
          bodyoption[key] !== ""
        ) {
          formData.append(key, bodyoption[key])
        }
      }
    }
    try {
      const response = await httpInjectorService.editRoles(
        formData,
        bodyoption?.id
      )
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Role updated successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }else {
        const errorData = response;
        throw new Error(errorData.errors.non_field_errors || errorData.errors || 'Something went wrong');
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error)
    }
  }
}
