import React from "react"
import Highcharts from "highcharts"
import HighchartsMore from "highcharts/highcharts-more"
import HighchartsReact from "highcharts-react-official"
import moment from "moment"
import HighchartsXrange from "highcharts/modules/xrange" // Import xrange module
import { useSelector } from "react-redux" // Import useSelector for Redux

// Initialize Highcharts with additional modules
HighchartsMore(Highcharts)
HighchartsXrange(Highcharts) // Initialize the xrange module

const StackedBarChart = ({ data }) => {
  const layoutModeType = useSelector(state => state.Layout.layoutModeType) // Get layout mode type from Redux

  // Check if data is defined and has test_plans property
  const testPlans = data?.test_plans || []

  // Prepare the data for the Highcharts chart
  const chartData = testPlans
    .filter(plan => plan.start_date && plan.end_date) // Filter out invalid plans
    .map((plan, index) => {
      const startDate = moment(plan.start_date, "YYYY-MM-DD")
      const endDate = moment(plan.end_date, "YYYY-MM-DD")
      const totalDays = endDate.diff(startDate, "days") + 1 // Calculate total days (inclusive)

      return {
        name: plan.test_plan_name, // Keep name as test_plan_name
        x: startDate.valueOf(), // Convert start date to timestamp
        x2: endDate.valueOf(), // Convert end date to timestamp
        y: index, // Use index for Y position
        totalDays: totalDays, // Add total days to each data point
      }
    })

  const isDarkMode = layoutModeType === "dark" // Check if dark mode is enabled

  // Define the chart options
  const options = {
    chart: {
      type: "xrange", // Use xrange type for range chart
      height: 400,
      backgroundColor: isDarkMode ? "#2a3042" : "white", // Set background color based on mode
    },
    title: {
      text: " ",
      style: {
        color: isDarkMode ? "#ffffff" : "#000000", // Dynamic title color
      },
    },
    tooltip: {
      shared: true,
      formatter: function () {
        const point = this.points[0].point // Get the point data for tooltip
        const planId = testPlans[point.y].test_plan_name // Get the corresponding test_plan_name
        return `Test Plan : ${planId}<br/>Duration: ${moment(point.x).format(
          "DD MMM"
        )} - ${moment(point.x2).format("DD MMM")}<br/>Total Days: ${
          point.totalDays
        }`
      },
    },
    xAxis: {
      type: "datetime",
      title: {
        text: "Dates",
        style: {
          color: isDarkMode ? "#ffffff" : "#000000", // Dynamic title color
        },
      },
      labels: {
        formatter: function () {
          return moment(this.value).format("MMM-DD") // Format the date as 'Sep-10'
        },
        style: {
          color: isDarkMode ? "#ffffff" : "#000000", // Dynamic label color
        },
      },
      lineColor: isDarkMode ? "#ffffff" : "#000000", // X-axis line color
      tickColor: isDarkMode ? "#ffffff" : "#000000", // Tick marks color
      tickInterval: 24 * 3600 * 1000 * 5, // Set tick interval to 2 days (in milliseconds)
    },
    yAxis: {
      title: {
        text: "Test Plans", // Adjusted to just mention test plans
        style: {
          color: isDarkMode ? "#ffffff" : "#000000", // Y-axis title color 
        },
      },
      categories: testPlans.map(plan => plan.test_plan_name), // Only show test plan IDs
      min: 0,
      max: testPlans.length - 1, // Adjust to ensure all data is visible
      labels: {
        style: {
          color: isDarkMode ? "#ffffff" : "#000000", // Y-axis labels color
          width: "100px", // Set maximum width
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
      },
      lineColor: isDarkMode ? "#ffffff" : "#000000", // Y-axis line color
      tickColor: isDarkMode ? "#ffffff" : "#000000", // Tick marks color
    },
    series: [
      {
        name: "Test Plans",
        data: chartData || [], // Ensure data is an empty array if chartData is undefined
        dataLabels: {
          enabled: false,
          format: "{point.x:%e %b} - {point.x2:%e %b}", // Show date range in labels
        },
        showInLegend: false, // Hide the legend for this series
      },
    ],
    credits: {
      enabled: false,
    },
  }

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default StackedBarChart
