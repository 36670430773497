import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, useLocation } from "react-router-dom"
import "../../../../components/Common/Breadcrum.scss"
import {
  Row,
  Col,
  BreadcrumbItem,
  Button,
  Container,
  Card,
  CardBody,
} from "reactstrap"
import { useSelector } from "react-redux"
import PageNavigation from "../PageNavigation/PageNavigation"
import FileUploadModal from "components/Import/FileUpload"
import { toast } from "react-toastify"

const Breadcrumb = () => {
  const location = useLocation()
  const locationPath = location.pathname
  const pathname = locationPath.split("/").filter(Boolean).pop()
  const [title, setTitle] = useState("Test Data")

  const initialBreadcrumbItems = [
    { label: "Test Lab / Test Automation", path: "TestData" },
    { label: "Test Data", path: "/" },
  ]
  const [breadcrumbItems, setBreadcrumbItems] = useState(initialBreadcrumbItems)
  const [activeButton, setActiveButton] = useState("TestData")

  useEffect(()=>{
    if(pathname){
      setActiveButton(pathname)
    }
  },[pathname])

  const handleDbIntegrationClick = () => {
    setActiveButton("DbIntegration")
    setTitle("Db Integration")
    setBreadcrumbItems([
      { label: "Test Lab / Test Automation", path: "TestData" },
      { label: "DB Integration", path: "/" },
    ])
  }

  const handleTestDataClick = () => {
    setActiveButton("TestData")
    setBreadcrumbItems(initialBreadcrumbItems)
    setTitle("Test Data")
  }
  const handleAutomationClick = () => {
    setActiveButton("AutomationSuite")
    setBreadcrumbItems([
      { label: "Test Lab / Test Automation", path: "AutomationSuite" },
      { label: "Automation Suite", path: "/" },
    ])
    setTitle("Automation Suite")
  }

  const handleAutomationScript = () => {
    setActiveButton("AutomationScript")
    setBreadcrumbItems([
      { label: "Test Lab / Test Automation", path: "AutomationScript" },
      { label: "Automation Script", path: "/" },
    ])
    setTitle("Automation Script")
  }

  const handleAgentClick = () => {
    setActiveButton("AgentCreation")
    setBreadcrumbItems([
      { label: "Test Lab / Test Automation", path: "AgentCreation" },
      { label: "Agent Creation", path: "/" },
    ])
    setTitle("Agent Creation")
  }

  const handleUploadApp = () => {
    setActiveButton("UploadApp")
    setBreadcrumbItems([
      { label: "Test Lab / Test Automation", path: "UploadApp" },
      { label: "Upload App", path: "/" },
    ])
    setTitle("Upload App")
  }

  const onBreadcrumbClick = () => {
    setActiveButton("TestData")
    setBreadcrumbItems(initialBreadcrumbItems)
    setTitle("Test Data")
  }

  const [modalOpenFile, setModalOpenFile] = useState(false)
  const Permission = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission =
    Permission?.permissions[0]?.menu_item?.TestLab?.Testautomation

  const toggleFileModal = () => {    
    if (rolePermission.create) {      
      setModalOpenFile(!modalOpenFile)
      } else {
        toast.warn("You don't have the permission to import new test data", {
          position: "top-right",
          id: " Error ",
          autoClose: 3000,
        })
      }
  }

  const layoutModeType = useSelector(state => state.Layout.layoutModeType)

  return (
    <>
      <div
        className="page-title-right"
        style={{ marginTop: "-25px", marginLeft: "-10px" }}
      >
        <ol className="breadcrumb m-0">
          {breadcrumbItems?.map((item, index) => (
            <BreadcrumbItem
              key={index}
              active={index === breadcrumbItems.length - 1}
            >
              {index === breadcrumbItems.length - 1 ? (
                item.label
              ) : (
                <Link to={item.path} onClick={() => onBreadcrumbClick()}>
                  {item.label}
                </Link>
              )}
            </BreadcrumbItem>
          ))}
        </ol>
      </div>
      <Container fluid>
        <Row>
          <Col lg="12">
            <Card
              className="mb-0"
              style={{
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
              }}
            >
              <CardBody className="border-bottom pt-3 pb-0">
                <div className="d-flex flex-column flex-lg-row justify-content-between ">
                  <PageNavigation
                    handleDbIntegrationClick={handleDbIntegrationClick}
                    handleTestDataClick={handleTestDataClick}
                    activeButton={activeButton}
                    onBreadcrumbClick={onBreadcrumbClick}
                    handleAutomationClick={handleAutomationClick}
                    handleAgentClick={handleAgentClick}
                    handleUploadApp={handleUploadApp}
                    handleAutomationScript={handleAutomationScript}                    
                  />
                  <div className="d-flex flex-wrap align-items-end mt-auto flex-column">
                    <div className=" hide-content">
                      {pathname === "TestData" && rolePermission?.view ? (
                        <Button
                          color="secondary"
                          outline
                          className="d-flex align-items-center mb-2 import-btn "
                          onClick={toggleFileModal} 
                        >
                          <i className="bx bx-import font-size-16"></i>

                          <span className="mx-2 hide-content">Import</span>
                        </Button>
                      ) : (
                        <div style={{ padding: "18px", margin: "5px" }}> </div>
                      )}
                    </div>
                  </div>
                </div>
                <FileUploadModal
                  isOpen={modalOpenFile}
                  toggle={toggleFileModal}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

Breadcrumb.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
    })
  ).isRequired,
}

export default Breadcrumb
