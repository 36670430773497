import React, { useEffect, useState } from "react"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import { Card, CardBody, CardHeader, Col, Row, Button } from "reactstrap"
import { Formik, Form, Field } from "formik"
import { fetchAllTeamsDetailsUserAssigneeList } from "store/actions/UserActions"
import { testPlanSummaryReportsAddAPI } from "store/actions/TestPlanActions"

const TestPlanApprovedBy = ({ testPlanID, setRefresh, refresh }) => {
  const isDarkMode = useSelector(state => state.Layout.layoutModeType)

  const cardHeaderStyle = {
    backgroundColor: isDarkMode === "dark" ? "#32394e" : "#fff",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    color: isDarkMode === "dark" ? "#fff" : "#000",
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth:"200px",
      backgroundColor: layoutModeType === 'dark' ? '#2a3042' : '#fff',
      color: layoutModeType === 'dark' ? '#fff' : '#000',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: layoutModeType === 'dark' ? '#2a3042' : '#fff',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: layoutModeType === 'dark' ? '#fff' : '#000',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: layoutModeType === 'dark' ? '#888' : '#888',
    }),
    input: (provided) => ({
      ...provided,
      color: layoutModeType === 'dark' ? '#fff' : '#000', // Ensure input text is visible
    }),
  };
  const dispatch = useDispatch()
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const [usersOptionsValue, setUsersOptionsValue] = useState([])

  useEffect(() => {
    const bodyOption = {
      project_id: globalProject?.value || selectedProject?.value,
    }

    const fetchOptions = async () => {
      try {
        const userListResponse = await dispatch(
          fetchAllTeamsDetailsUserAssigneeList(bodyOption)
        )

        const usersListOptions = userListResponse?.results?.map(entry => ({
          value: entry.id,
          label: entry.user_name,
        }))

        setUsersOptionsValue(usersListOptions || [])
      } catch (error) {
        console.error("Failed to fetch users list:", error)
      }
    }

    if (bodyOption.project_id) {
      fetchOptions()
    }
  }, [dispatch, globalProject])

  const initialValues = {
    approvers: [],
  }

  const validate = values => {
    const errors = {}
    if (!values.approvers || values.approvers.length === 0) {
      errors.approvers = "At least one approver is required."
    }
    return errors
  }

  const handleSubmit = async (values, { resetForm }) => {
    const formData = {
      approvals_by: values.approvers.map(item => item.value),
      project_id: globalProject?.value || selectedProject?.value,
      testplan_id: testPlanID,
    }

    try {
      await dispatch(testPlanSummaryReportsAddAPI(formData))
      setRefresh(refresh + 1) // Await the dispatch
      resetForm() // Reset the form after successful submission
    } catch (error) {
      console.error("Failed to submit form:", error)
    }
  }

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader
            className="d-flex justify-content-between align-items-center"
            style={cardHeaderStyle}
          >
            <h5 className="mb-0">Approved By</h5>
          </CardHeader>
          <CardBody>
            <Formik
              initialValues={initialValues}
              validate={validate}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, resetForm, errors, touched }) => (
                <Form>
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-column mb-2">
                      <Field name="approvers">
                        {({ field }) => (
                          <Select
                            isClearable
                            name={field.name}
                            className="select2-selection"
                            options={usersOptionsValue}
                            closeMenuOnSelect={false}
                            onChange={option =>
                              setFieldValue("approvers", option)
                            }
                            isMulti
                            placeholder="Select approvers..."
                            value={values.approvers}
                            styles={{
                              container: provided => ({
                                ...provided,
                                width: "270px", // Adjust width here
                              }),
                              control: provided => ({
                                ...provided,
                                minHeight: "38px", // Adjust height if needed
                              }),
                              menu: provided => ({
                                ...provided,
                                maxHeight: 300, // Set max height for dropdown
                                overflowY: "auto", // Enable vertical scroll
                                zIndex: 1000, // Ensure dropdown appears above other elements
                              }),
                              menuList: provided => ({
                                ...provided,
                                maxHeight: 120, // Set max height for dropdown
                                overflowY: "auto", // Enable vertical scroll
                                zIndex: 1000, // Ensure dropdown appears above other elements
                              }),
                            }}
                          />
                        )}
                      </Field>
                      {errors.approvers && touched.approvers ? (
                        <p className="text-danger">{errors.approvers}</p>
                      ) : null}
                    </div>
                    <div className="d-flex justify-content-end gap-1">
                      <Button
                        color="secondary"
                        onClick={() => resetForm()}
                        className="mr-2"
                      >
                        Cancel
                      </Button>
                      <Button color="primary" type="submit">
                        Apply
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default TestPlanApprovedBy
