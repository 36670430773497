import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, useLocation } from "react-router-dom"
import "../../../components/Common/Breadcrum.scss"
import {
  Row,
  Col,
  BreadcrumbItem,
  Button,
  Container,
  Card,
  CardBody,
} from "reactstrap"
import PageNavigation from "../PageNavigation/PageNavigation"
import DownloadTemplate from "components/Download Template/DownloadTemplate"
import FileUploadModal from "components/Import/FileUpload"
import ExportModal from "components/Export/ExportModal"
// import { useSelector } from "react-redux"
import InfoTable from "components/Common/Info/Info"
import { toast } from "react-toastify"

const Breadcrumb = props => {
  const { isImport, isDownload, isExport, isInfo } = props
  // const [title, setTitle] = useState("Test Scenario")

  const initialBreadcrumbItems = [
    { label: "Test Management", path: "TestScenario" },
    { label: "Test Scenario", path: "/" },
  ]

  const permission = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission = permission?.permissions?.[0]?.menu_item?.TestManagement
  const location = useLocation()
  const pathname = location.pathname.split("/").filter(Boolean).pop()
  const [breadcrumbItems, setBreadcrumbItems] = useState(initialBreadcrumbItems)
  const [activeButton, setActiveButton] = useState("")

  const handleTestCaseClick = () => {
    setActiveButton("TestCase")
    // setTitle("Test Case")
    setBreadcrumbItems([
      { label: "Test Management", path: "TestScenario" },
      { label: "Test Case", path: "/" },
    ])
  }

  const hanldePreCondition = () => {
    setActiveButton("TestCase")
    // setTitle("Test Case")
    setBreadcrumbItems([
      { label: "Test Management", path: "TestScenario" },
      { label: "Test Case", path: "/TestManagement/TestCase" },
      { label: "Pre Condition", path: "/" },
    ])
  }

  const handleTestScenarioClick = () => {
    setActiveButton("TestScenario")
    setBreadcrumbItems(initialBreadcrumbItems)
    // setTitle("Test Scenario")
  }

  const onBreadcrumbClick = () => {
    setActiveButton("TestScenario")
    setBreadcrumbItems(initialBreadcrumbItems)
    // setTitle("Test Scenario")
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModal = () => setIsModalOpen(!isModalOpen)

  const [modalOpenFile, setModalOpenFile] = useState(false)
  const toggleFileModal = () => {
    if(pathname === "TestScenario"){
    if(rolePermission?.testscenario?.create){
      setModalOpenFile(!modalOpenFile)
    }else{
      toast.warn("You don't have the permission to import new Test Scenario", {
        position: "top-right",
        id: "Project create Error ",
        autoClose: 3000,
      })
    }
    }else{
      if(rolePermission?.testcase?.create){
        setModalOpenFile(!modalOpenFile)
      }else{
        toast.warn("You don't have the permission to import new Test Case", {
          position: "top-right",
          id: "Project create Error ",
          autoClose: 3000,
        })
      }
    }
  }

  // const layoutModeType = useSelector(state => state.Layout.layoutModeType)

  return (
    <>
      <div style={{ marginTop: "-25px" }}>
        {/* <h4 className="font-size-18 mb-2">{title}</h4> */}
        <div className="page-title-left " style={{ marginLeft: "-10px" }}>
          <ol className="breadcrumb m-0">
            {breadcrumbItems?.map((item, index) => (
              <BreadcrumbItem
                key={index}
                active={index === breadcrumbItems.length - 1}
              >
                {index === breadcrumbItems.length - 1 ? (
                  item.label
                ) : (
                  <Link to={item.path} onClick={onBreadcrumbClick}>
                    {item.label}
                  </Link>
                )}
              </BreadcrumbItem>
            ))}
          </ol>
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col lg="12">
            <Card
              className="mb-0"
              style={{
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
              }}
            >
              <CardBody className="border-bottom pt-3 pb-0">
                <div className="d-flex flex-column flex-md-row justify-content-between">
                  <PageNavigation
                    handleTestCaseClick={handleTestCaseClick}
                    hanldePreCondition={hanldePreCondition}
                    handleTestScenarioClick={handleTestScenarioClick}
                    activeButton={activeButton}
                    onBreadcrumbClick={onBreadcrumbClick}
                  />
                  <div className="hide-content ">
                    <div className="d-flex flex-wrap mt-3 mt-md-0 gap-2">
                      <div className="">
                        {isDownload && <DownloadTemplate />}
                      </div>
                      <div className="">
                        {isImport && (
                          <Button
                            color="secondary"
                            outline
                            className="d-flex align-items-center  import-btn"
                            onClick={toggleFileModal} // Set a fixed height
                          >
                            <i className="bx bx-import font-size-16"></i>
                            <span className="mx-2 hide-content">Import</span>
                          </Button>
                        )}
                      </div>
                      <div className="">{isExport && <ExportModal />}</div>
                      <div className="">
                        {isInfo && (
                          <Button
                            color="secondary"
                            outline
                            className="d-flex align-items-center mb-2"
                            onClick={toggleModal}
                          >
                            <i className="bx bx-info-circle font-size-16 info-btn"></i>
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>

                  <InfoTable isOpen={isModalOpen} toggle={toggleModal} />
                  <FileUploadModal
                    isOpen={modalOpenFile}
                    toggle={toggleFileModal}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

Breadcrumb.propTypes = {
  isImport: PropTypes.bool,
  isDownload: PropTypes.bool,
  isExport: PropTypes.bool,
  isInfo: PropTypes.bool,
}

export default Breadcrumb
