import React from "react";
import { Card, CardBody, Col, Container, Progress } from "reactstrap";

const ViewTestCycleChart = ({ chartData,type }) => {

  return (
    <Container fluid>
      <Card className="mb-0 rounded-0">
      <CardBody className="d-flex flex-column flex-md-row justify-content-between p-2 align-items-center border-bottom"> 
  <div className="col-12 col-md-6 d-flex justify-content-between">
    {/* Total Test Cases */}
    <Col className="border-end border-dark text-center p-1">
      <h6>TOTAL {type === "Manual" ? "TEST CASES" : "SUITES"}</h6>
      <h4>{chartData[0].total_test_cases}</h4>
    </Col>

    {/* Approved */}
    <Col className="border-end border-dark text-center p-1">
      <h6>PASSED</h6>
      <h4>{chartData[0].passed_percentage}%</h4>
    </Col>

    {/* Pending Approval */}
    <Col className="text-center p-1">
      <h6>NOT RUN</h6>
      <h4>{chartData[0].not_run_percentage}%</h4>
    </Col>
  </div>

  <div className="col-12 col-md-6 mt-2">
    <Col
      className="pt-2 mb-0"
      style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}
    >
      <Progress multi style={{ height: "0.8rem" }}>
        <Progress bar color="success" value={chartData[0].passed_percentage}>
          {chartData[0].passed_percentage}%
        </Progress>
        <Progress bar color="danger" value={chartData[0].failed_percentage}>
          {chartData[0].failed_percentage}%
        </Progress>
        <Progress bar color="warning" value={chartData[0].not_run_percentage}>
          {chartData[0].not_run_percentage}%
        </Progress>
      </Progress>

      <div className="d-flex justify-content-between mt-2">
        <div className="d-flex align-items-center">
          <div
            style={{
              backgroundColor: "rgb(52 195 143)",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          ></div>
          <span>PASSED</span>
        </div>
        <div className="d-flex align-items-center">
          <div
            style={{
              backgroundColor: "red",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              marginRight: "5px",
            }}
          ></div>
          <span>FAILED</span>
        </div>
        <div className="d-flex align-items-center">
          <div
            style={{
              backgroundColor: "orange",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              marginRight: "5px",
            }}
          ></div>
          <span>NOT RUN</span>
        </div>
      </div>
    </Col>
  </div>
</CardBody>

      </Card>
    </Container>
  );
};

export default ViewTestCycleChart;
