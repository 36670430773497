import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link, useLocation, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import logo from "../../assets/images/companies/forth-logo-half.png"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import profile from "../../assets/images/profile-img.png"
import httpInjectorService from "services/http-Injector.Service"
import { toast } from "react-toastify"
import { setSelectetProject } from "store/Slices/GlobalSlice"

const ResetPassword = props => {
  const dispatch = useDispatch()
  const location = useLocation()
  const pathname = location.pathname.split("/").filter(Boolean).pop()
 
  const [passwordShow, setPasswordShow] = useState({
    newPassword: false,
    reNewPassword: false,
  })

  const navigate = useNavigate()

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      newPassword: "",
      reNewPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(
          /[!@#$%^&*(),.?":{}|<>]/,
          "Password must contain at least one special character"
        )
        .required("Please Enter Your New Password"),
      reNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Please Re-enter Your New Password"),
    }),

    onSubmit: values => {
      const formData = new FormData()
      formData.append("new_password ", values.newPassword)
      formData.append("re_entered_password ", values.reNewPassword)
      formData.append("user_id  ", pathname)
      submitPasswordChange(formData)
      console.log(formData)
    },
  })

  const submitPasswordChange = async formData => {
    try {
      const response = await httpInjectorService.resetPassword(
        formData,
        pathname
      )
      if (response.status) {
        toast.success(response.message, {
          position: "top-right",
          autoClose: 3000,
        })
        localStorage.removeItem("authUser")
        localStorage.removeItem("selectedProject")
        // dispatch(setSelectetProject(null))
        localStorage.clear() // Clear local storage
        navigate("/login")
      } else {
        toast.warn(response.error, {
          position: "top-right",
          autoClose: 3000,
        })
      }
    } catch (error) {
      toast.warn(error?.message, {
        position: "top-right",
        autoClose: 3000,
      })
    }
  }

  const ForgotPasswordProperties = createSelector(
    state => state.ForgetPassword,
    forgetPassword => ({
      forgetError: forgetPassword.forgetError,
      forgetSuccessMsg: forgetPassword.forgetSuccessMsg,
    })
  )

  const { forgetError, forgetSuccessMsg } = useSelector(
    ForgotPasswordProperties
  )

  const renderPasswordInput = (name, label) => (
    <div className="mb-3">
      <Label className="form-label">{label}</Label>
      <div className="input-group auth-pass-inputgroup">
        <Input
          name={name}
          value={validation.values[name] || ""}
          type={passwordShow[name] ? "text" : "password"}
          placeholder={`Enter ${label}`}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          invalid={
            validation.touched[name] && validation.errors[name] ? true : false
          }
        />
        <button
          onClick={() =>
            setPasswordShow({ ...passwordShow, [name]: !passwordShow[name] })
          }
          className="btn btn-light"
          type="button"
          id={`password-addon-${name}`}
          style={{ border: "1px solid lightgray" }}
        >
          {passwordShow[name] ? (
            <i className="mdi mdi-eye-off-outline"></i>
          ) : (
            <i className="mdi mdi-eye-outline"></i>
          )}
        </button>

        {validation.touched[name] && validation.errors[name] && (
          <FormFeedback type="invalid">{validation.errors[name]}</FormFeedback>
        )}
      </div>
    </div>
  )

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs={8}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Reset Your Password</h5>
                      </div>
                    </Col>
                    <Col className="col-4 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="80"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {renderPasswordInput("newPassword", "New Password")}
                      {renderPasswordInput(
                        "reNewPassword",
                        "Re-enter New Password"
                      )}
                      <Row className="mb-3 mt-2">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                          >
                            Submit
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
                <div className=" text-center">
                  <p>
                    Go back to{" "}
                    <Link
                      to="/login"
                      className="font-weight-medium text-primary"
                    >
                      Login
                    </Link>
                  </p>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ResetPassword.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ResetPassword)
