import React from "react"
import { useSelector } from "react-redux"
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap"

const ResourceResponsibleForTesting = ({ testPlanResponsibleResourceData }) => {
  const isDarkMode = useSelector(state => state.Layout.layoutModeType)

  const cardHeaderStyle = {
    backgroundColor: isDarkMode === "dark" ? "#32394e" : "#fff",
    // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    color: isDarkMode === "dark" ? "#fff" : "#000",
  }

  const tableHeaderStyle = {
    position: "sticky",
    top: 0,
    backgroundColor: isDarkMode === "dark" ? "#444" : "#fff",
    color: isDarkMode === "dark" ? "#fff" : "#000",
    zIndex: 2,
    borderCollapse: "collapse",
  }

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader
            className="d-flex justify-content-between align-items-center"
            style={cardHeaderStyle}
          >
            <h5 className="mb-0">Resources Responsible for Testing</h5>
            <div id="resultDownload" className="cursor-pointer"></div>
          </CardHeader>
          <CardBody>
            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
              {testPlanResponsibleResourceData &&
              testPlanResponsibleResourceData.length > 0 ? (
                <Table hover striped>
                  <thead style={tableHeaderStyle}>
                    <tr>
                      <th>Name</th>
                      <th>Designation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testPlanResponsibleResourceData.map((item, i) => (
                      <tr key={i}>
                        <td>{item?.name || "-"}</td>
                        <td>{item?.designation || "-"}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <div className="text-center py-3">
                  <b>No records found.</b>
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default ResourceResponsibleForTesting
