import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector } from "react-redux"; // Ensure to import useSelector for layoutModeType

const TestCasesFailChart = ({ data }) => {
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  const layoutModeType = useSelector((state) => state.Layout.layoutModeType);

  // Transforming the input data to the format expected by Highcharts
  const chartData = {
    categories: data.map(
      (item) => `${formatDate(item.start_date)} - ${formatDate(item.end_date)}`
    ),
    data: data.map((item) => item.fail_percentage),
  };

  // Define chart colors based on layout mode
  const isDarkMode = layoutModeType === "dark";
  const chartBackgroundColor = isDarkMode ? "#2a3042" : "#ffffff";
  const textColor = isDarkMode ? "#f0f0f0" : "#000000";
  const gridLineColor = isDarkMode ? "#444444" : "#e6e6e6";

  const options = {
    chart: {
      backgroundColor: chartBackgroundColor,
    },
    title: {
      text: " ",
      align: "left",
      style: {
        color: textColor,
      },
    },
    xAxis: {
      categories: chartData.categories,
      labels: {
        style: {
          color: textColor, // Ensure x-axis label color is applied
        },
      },
      lineColor: textColor, // Set x-axis line color
      tickColor: textColor, // Set tick color for dark mode
      gridLineColor: gridLineColor,
    },
    yAxis: {
      title: {
        text: "Test Cases Fail %",
        style: {
          color: textColor,
        },
      },
      labels: {
        formatter: function () {
          return Math.round(this.value); // Display integer values
        },
        style: {
          color: textColor,
        },
      },
      gridLineColor: gridLineColor,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Test Cases Fail %",
        data: chartData.data,
        color: isDarkMode ? "#FF5733" : "#0071A7", // Custom series color
      },
    ],
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return Math.round(this.y); // Display integer values
          },
          style: {
            color: textColor,
          },
        },
        enableMouseTracking: true,
      },
    },
    tooltip: {
      backgroundColor: isDarkMode ? "#000000" : "#ffffff",
      style: {
        color: textColor,
      },
      pointFormatter: function () {
        return `<span style="color:${this.color}">\u25CF</span> ${
          this.series.name
        }: <b>${Math.round(this.y)}</b><br/>`; // Display integer values in tooltip
      },
    },
    legend: {
      enabled: false, // Disable the legend to remove the labels below the chart
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default TestCasesFailChart;
