import React, { useEffect, useState, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
    Badge,
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Progress,
    Row,
    UncontrolledTooltip,
} from "reactstrap"
import DeleteModal from "components/Common/DeleteModal"
import { useSelector, useDispatch } from "react-redux"
import { toast, ToastContainer } from "react-toastify"
import BulkDeleteModal from "components/Common/BulkDeleteModal"
import { GetTestDataByIdAPI } from "store/actions/TestDataActions"
import moment from "moment"
import { setEditTestData } from "store/Slices/TestDataSlice"
import TableContainer from "../TestCycleContainer/TableContainer"
import {
    BulkDeleteTestCycleAPI,
    BulkExecuteStatus,
    deleteTestCycleAPI,
    fetchExecutionChart,
    fetchExecutionDetails,
    fetchTestCycleDetailsAPI,
    GetTestCycleByIdAPI,
    GetTestCycleExecuteByIdAPI,
} from "store/actions/TestCycleActions"
import { getStatusBackGroundColor } from "helpers/helper"
import {
    setBulkExecute,
    setViewTestCycle,
} from "store/Slices/TestCycleSlice"
import ViewTestCycleChart from "./TestCycleChart"
import BulkExecute from "./BulkExecute"
import BulkExecutePopup from "./BulkExecutePopup"
import { GetTestCaseByIdAPI } from "store/actions/TestCaseAction"
import BASE_URL from "services/apiConfig"

const ViewTestCycleManual = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))

    const globalProject = useSelector(
        state => state?.globalProjectSelect?.selectetProject
    )

    const viewTestCycle = useSelector(state => state?.TestCycle?.viewTestCycle)

    const bulkExecute = useSelector(state => state?.TestCycle?.bulkExecute)

    const [data, setData] = useState([])
    const [chartData, setChartData] = useState([])

    const handleCreateClick = id => {
        navigate("/TestAutomation/TestData/CreateTestData")
    }

    const [deleteModal, setDeleteModal] = useState(false)
    const [bulkDeleteModal, setBulkDeleteModal] = useState(false)
    const [bulkExecuteModal, setBulkExecuteModal] = useState(false)
    const [isDeleteId, setIsDeleteId] = useState("")

    const [selectedRows, setSelectedRows] = useState([])
    const handleCheckboxChange = id => {
        setSelectedRows(prevValues =>
            prevValues.includes(id)
                ? prevValues.filter(val => val !== id)
                : [...prevValues, id]
        )
    }

    const [viewData, setViewData] = useState(null)

    useEffect(() => {
        if (viewData) {
            navigate("/TestCycle/ExecuteTestCase")
        }
    }, [viewData])

    const Permission = JSON.parse(localStorage.getItem("authUser"));
    const casePermission = Permission?.permissions[0]?.menu_item?.TestManagement
        ?.testcase
    const cyclePermission = Permission?.permissions[0]?.menu_item?.TestLab?.cycle

    const handleViewCase = async (id) => {
        if (casePermission?.view) {
            try {
                const res = await dispatch(GetTestCaseByIdAPI(id))
                if (res) {
                    navigate("/TestManagement/ViewTestCase")
                }
            } catch (error) {
                console.error("Error fetching data", error)
            }
        } else {
            toast.warn("You don't have permission to view test case", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            })
        }
    }

    const formatFilePath = (path) => {
        if (path?.includes("/media/media")) {
            return path.replace("media/media", "media");
        }
        return path;
    };

    const handleExecute = async data => {
        if (cyclePermission?.execution) {
            try {
                await dispatch(GetTestCaseByIdAPI(data?.test_case_id))
                const res = await dispatch(GetTestCycleExecuteByIdAPI(data?.id))
                setViewData(res)
            } catch (error) {
                console.error("Error fetching data", error)
            }
        } else {
            toast.warn("You don't have permission to execute", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            })
        }
    }
    useEffect(() => {
        if (viewData) {
            navigate("/TestCycle/ExecuteTestCase")
        }
    }, [viewData])

    const handleSelectAll = e => {
        const isChecked = e.target.checked
        if (isChecked) {
            const newSelectedRows = data.map(item => item?.id)
            setSelectedRows(newSelectedRows)
        } else {
            setSelectedRows([])
        }
    }
    const columns = useMemo(
        () => [
            {
                header: (
                    <input
                        type="checkbox"
                        onChange={handleSelectAll}
                        style={{ cursor: "pointer" }}
                        title="Select All"
                        checked={
                            Array.isArray(selectedRows) &&
                            selectedRows.length === data?.length &&
                            data?.length > 0
                        }
                    />
                ),
                accessorKey: "checkbox",
                cell: cellProps => {
                    const jobData = cellProps.row.original?.id

                    const isChecked = () => {
                        if (!Array.isArray(selectedRows)) {
                            console.error("selectedRows is not an array")
                            return false
                        }
                        return selectedRows.includes(jobData)
                    }

                    const handleCheckboxClick = () => {
                        try {
                            if (!Array.isArray(selectedRows)) {
                                throw new Error("selectedRows is not an array")
                            }
                            handleCheckboxChange(jobData)
                        } catch (error) {
                            console.error(error.message)
                        }
                    }

                    return (
                        <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            checked={isChecked()}
                            onChange={handleCheckboxClick}
                            onClick={handleCheckboxClick}
                        />
                    )
                },
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: "Execute",
                accessorKey: "id",
                enableColumnFilter: false,
                enableSorting: true,
                cell: cellProps => {
                    const data = cellProps.row.original
                    return <>
                        <i className="text-primary fs-3 me-2 mdi mdi-play label-icon" style={{ cursor: 'pointer' }} onClick={() => {
                            handleExecute(data)
                        }}></i>
                        {data?.attachment &&
                            <a
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                title="View File"
                                href={`${BASE_URL}${formatFilePath(data?.attachment)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="text-primary fs-4 mdi mdi-link-variant label-icon" style={{ cursor: 'pointer' }}></i>
                            </a>}
                    </>
                },
            },
            {
                header: "Test Case Id",
                accessorKey: "id",
                enableColumnFilter: false,
                enableSorting: true,
                cell: cellProps => {
                    const test_case_name = cellProps.row.original
                    return <div title="View Test Case Details" onClick={() => { handleViewCase(test_case_name?.test_case_id) }} style={{ cursor: 'pointer', fontWeight: '600', textDecoration: 'underline' }}>{test_case_name?.test_case_name[1] ? test_case_name?.test_case_name[1] : "-"}</div>
                },
            },
            {
                header: "Test Case Name",
                accessorKey: "test_case_name",
                enableColumnFilter: false,
                enableSorting: true,
                cell: cell => {
                    const test_case_name = cell.getValue()
                    return <div style={{
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                        whiteSpace: "normal",
                        maxWidth: "200px"
                    }}>{test_case_name[0] ? test_case_name[0] : "-"}</div>
                },
            },
            {
                header: "Status",
                accessorKey: "excution_status",
                enableColumnFilter: false,
                enableSorting: true,
                cell: cell => {
                    const excution_status = cell.getValue()
                    return (
                        <>
                            <Badge
                                className={
                                    "badge-soft-" +
                                    `${getStatusBackGroundColor(excution_status)}`
                                }
                                style={{
                                    fontSize: "11px", // Increase font size
                                    padding: "0.5em", // Increase padding
                                    borderRadius: "0.5em", // Adjust border radius
                                }}
                            >
                                {excution_status === "NOTRUN" ? "NOT RUN" : excution_status}
                            </Badge>
                        </>
                    )
                },
            },
            {
                header: "Executed Date",
                accessorKey: "executed_date",
                enableColumnFilter: false,
                enableSorting: true,
                cell: cell => {
                    return <>{cell.getValue() ? moment(cell.getValue()).format("DD-MM-YYYY") : "-"}</>
                },
            },
            {
                header: "Executed By",
                accessorKey: "executed_by_name",
                enableColumnFilter: false,
                enableSorting: true,
                cell: cell => {
                    const executed_by_name = cell.getValue()
                    return <>{executed_by_name ? executed_by_name : "-"}</>
                },
            },
            {
                header: "Test Case Order",
                accessorKey: "test_order", // Change this to a unique key
                enableColumnFilter: false,
                enableSorting: true,
                cell: cell => {
                    const test_order = cell.getValue()
                    return <>{test_order ? test_order : "-"}</>
                },
            }

        ],
        [selectedRows, data]
    )

    const [pageIndex, setPageIndex] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [globalFilter, setGlobalFilter] = useState("")
    const [totalCount, setTotalCount] = useState(null)

    const shuffleDataBasedOnTestSequence = (data) => {
        const sortedTests = [...data].sort((a, b) => a.test_order - b.test_order);
        return sortedTests;
    };   


    const fetchTableData = async (page, size, search) => {
        let bodyoption = {
            project_id: globalProject?.value || selectedProject?.value,
            page: page + 1,
            page_size: size,
            search: search,
            test_cycle_id: viewTestCycle?.data?.id || localStorage.getItem('cycleId')
        }
        let payload = {
            project_id: globalProject?.value || selectedProject?.value,
            test_cycle_id: viewTestCycle?.data?.id || localStorage.getItem('cycleId')
        }
        try {
            const data = await dispatch(fetchExecutionDetails(bodyoption))
            const chartData = await dispatch(fetchExecutionChart(payload))
            const shuffledData = shuffleDataBasedOnTestSequence(data.Data);
            setChartData(chartData)
            setData(shuffledData)
            setTotalCount(data.TotalCount)
            return data
        } catch (error) {
            console.error("Error fetching data", error)
        }
    }

    useEffect(() => {
        fetchTableData(pageIndex, pageSize, globalFilter)
    }, [globalProject?.value,pageIndex, pageSize, globalFilter])

    const handleDelete = async () => {
        try {
            await dispatch(deleteTestCycleAPI(isDeleteId))
            setDeleteModal(false)
            await fetchTableData(pageIndex, pageSize, globalFilter)
            setIsDeleteId("")
        } catch (error) {
            console.error("Error deleting test cycle:", error)
        }
    }

    const handleExecuteClickFunction = async () => {
        if (cyclePermission?.execution) {
            try {
                if (selectedRows?.length > 0) {
                    setBulkExecuteModal(true)
                } else {
                    toast.warn(
                        "Please select a test case before attempting to bulk execute.",
                        {
                            position: "top-right",
                            id: "selectBeforeDelete",
                            autoClose: 3000,
                        }
                    )
                }
            } catch (error) {
                console.error("Error deleting test cycle:", error)
            }
        } else {
            toast.warn("You don't have permission to execute", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            })
        }
    }
    const handleBulkDelete = async () => {
        const bodyoption = {
            project_id: globalProject?.value || selectedProject?.value,
            test_cycle_ids: selectedRows,
        }
        try {
            await dispatch(BulkDeleteTestCycleAPI(bodyoption))
            setSelectedRows([])
            setBulkExecuteModal(false)
            await fetchTableData(pageIndex, pageSize, globalFilter)
        } catch (error) {
            console.error("Error deleting test data:", error)
        }
    }

    const handleBulkExecute = async () => {
        const bodyoption = {
            testexcution_status: bulkExecute?.data,
            test_excution_ids: selectedRows,
        }

        if (bulkExecute?.data) {
            try {
                const res = await dispatch(BulkExecuteStatus(bodyoption))
                setSelectedRows([])
                setBulkExecuteModal(false)
                await fetchTableData(pageIndex, pageSize, globalFilter)
                if (res.status) {
                    dispatch(setBulkExecute({ data: null }))
                }
            } catch (error) {
                console.error("Error:", error)
            }
        } else {
            console.log("res", bulkExecute?.data)
        }
    }

    const hanldeBackButton = () => {
        navigate("/TestCycle")
    }


    // const [pageIndex, setPageIndex] = useState(0)
    // const [pageSize, setPageSize] = useState(10)
    // const [globalFilter, setGlobalFilter] = useState("")
    // const [totalCount, setTotalCount] = useState(null)

    // const shuffleDataBasedOnTestSequence = data => {
    //   const test_sequence_string = data[0]?.test_cycle_name?.test_sequence

    //   const test_sequence = {}

    //   const pairs = test_sequence_string?.slice(1, -1).split(",")

    //   pairs?.forEach(pair => {
    //     const [key, value] = pair.split(":").map(str => str.trim())
    //     test_sequence[parseInt(key)] = parseInt(value)
    //   })

    //   const sortedExecutionData = [...data].sort((a, b) => {
    //     const sequenceA =
    //       test_sequence[a.test_case_id] || Number.POSITIVE_INFINITY
    //     const sequenceB =
    //       test_sequence[b.test_case_id] || Number.POSITIVE_INFINITY
    //     return sequenceA - sequenceB
    //   })
    //   return sortedExecutionData
    // }

    // const fetchTableData = async (page, size, search) => {
    //   let bodyoption = {
    //     page: page + 1,
    //     page_size: size,
    //     search: search,
    //     project_id: globalProject?.value || selectedProject?.value,
    //     test_cycle_id: viewTestCycle?.data?.id || localStorage.getItem("cycleId"),
    //   }
    //   let payload = {
    //     project_id: globalProject?.value || selectedProject?.value,
    //     test_cycle_id: viewTestCycle?.data?.id || localStorage.getItem("cycleId"),
    //   }
    //   try {
    //     const data = await dispatch(fetchExecutionDetails(bodyoption))
    //     const chartData = await dispatch(fetchExecutionChart(payload))
    //     const shuffledData = shuffleDataBasedOnTestSequence(data.Data)
    //     setChartData(chartData)
    //     setData(shuffledData)
    //     setTotalCount(data.TotalCount)
    //     return data
    //   } catch (error) {
    //     console.error("Error fetching data", error)
    //   }
    // }

    // useEffect(() => {
    //   fetchTableData(pageIndex, pageSize, globalFilter)
    // }, [pageIndex, pageSize, globalFilter, globalProject?.value])

    // const handleDelete = async () => {
    //   try {
    //     await dispatch(deleteTestCycleAPI(isDeleteId))
    //     setDeleteModal(false)
    //     await fetchTableData(pageIndex, pageSize, globalFilter)
    //     setIsDeleteId("")
    //   } catch (error) {
    //     console.error("Error deleting test cycle:", error)
    //   }
    // }

    // const handleExecuteClickFunction = async () => {
    //   try {
    //     if (selectedRows?.length > 0) {
    //       setBulkExecuteModal(true)
    //     } else {
    //       toast.warn(
    //         "Please select a test case before attempting to bulk execute.",
    //         {
    //           position: "top-right",
    //           id: "selectBeforeDelete",
    //           autoClose: 3000,
    //         }
    //       )
    //     }
    //   } catch (error) {
    //     console.error("Error deleting test cycle:", error)
    //   }
    // }
    // const handleBulkDelete = async () => {
    //   const bodyoption = {
    //     project_id: globalProject?.value || selectedProject?.value,
    //     test_cycle_ids: selectedRows,
    //   }
    //   try {
    //     await dispatch(BulkDeleteTestCycleAPI(bodyoption))
    //     setSelectedRows([])
    //     setBulkExecuteModal(false)
    //     await fetchTableData(pageIndex, pageSize, globalFilter)
    //   } catch (error) {
    //     console.error("Error deleting test data:", error)
    //   }
    // }

    // const handleBulkExecute = async () => {
    //   const bodyoption = {
    //     testexcution_status: bulkExecute?.data,
    //     test_excution_ids: selectedRows,
    //   }
    //   if (bulkExecute?.data) {
    //     try {
    //       const res = await dispatch(BulkExecuteStatus(bodyoption))

    //       setSelectedRows([])
    //       setBulkExecuteModal(false)
    //       await fetchTableData(pageIndex, pageSize, globalFilter)
    //       if (res.status) {
    //         dispatch(setBulkExecute({ data: null }))
    //       }
    //     } catch (error) {
    //       console.error("Error deleting test data:", error)
    //     }
    //   } else {
    //     console.log("res", bulkExecute?.data)
    //   }
    // }

    // const hanldeBackButton = () => {
    //   navigate("/TestCycle")
    // }

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDelete}
                onCloseClick={() => setDeleteModal(false)}
                message="Test Cycle"
            />
            <BulkDeleteModal
                show={bulkDeleteModal}
                onDeleteClick={handleBulkDelete}
                onCloseClick={() => {
                    setBulkDeleteModal(false)
                    setSelectedRows([])
                }}
                message="Test Cycles"
            />
            <BulkExecutePopup
                show={bulkExecuteModal}
                onExecute={handleBulkExecute}
                onCloseClick={() => {
                    setBulkExecuteModal(false)
                    setSelectedRows([])
                }}
            />
            <BulkExecute
                data={localStorage.getItem("TestCycleName")}
                handleExecuteClick={handleExecuteClickFunction}
                hanldeExecute={handleBulkExecute}
                hanldeBack={hanldeBackButton}
            />
            {chartData?.length !== 0 && (
                <ViewTestCycleChart chartData={chartData?.data} type="Manual" />
            )}
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <Card
                            style={{
                                borderTopLeftRadius: "0px",
                                borderTopRightRadius: "0px",
                            }}
                        >
                            <CardBody className="pt-2">
                                <div className="table-container">
                                    <TableContainer
                                        columns={columns}
                                        data={data}
                                        pageIndex={pageIndex}
                                        pageSize={pageSize}
                                        totalCount={totalCount}
                                        setPageIndex={setPageIndex}
                                        setPageSize={setPageSize}
                                        globalFilter={globalFilter}
                                        setGlobalFilter={setGlobalFilter}
                                        isGlobalFilter={false}
                                        isPagination={true}
                                        SearchPlaceholder="Search..."
                                        isCustomPageSize={true}
                                        isAddButton={false}
                                        isAddDelete={false}
                                        isAddExecute={false}
                                        isAddEdit={false}
                                        isAddFilter={false}
                                        handleAddClick={handleCreateClick}
                                        buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal ms-2 mb-2"
                                        buttonName="Create"
                                        tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                        theadClass="table-light"
                                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                        pagination="pagination"
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <ToastContainer />
        </React.Fragment>
    )
}

export default withRouter(ViewTestCycleManual)
