import React, { useState } from "react" // Import useState for state management
import { useSelector } from "react-redux"
import { Card, CardBody, CardHeader, Col, Row, Button } from "reactstrap"
import JoditEditor from "jodit-react"
import { joditConfig } from "helpers/helper"
import { useDispatch } from "react-redux"
import { testPlanSummaryReportsEditAPI } from "store/actions/TestPlanActions"

const Comments = ({ testPlanID, refresh, setRefresh, pendingApprovalId }) => {
  const isDarkMode = useSelector(state => state.Layout.layoutModeType)

  const cardHeaderStyle = {
    backgroundColor: isDarkMode === "dark" ? "#32394e" : "#fff",
    // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    color: isDarkMode === "dark" ? "#fff" : "#000",
  }
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))

  const dispatch = useDispatch()
  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )
  const [description, setDescription] = useState("") // State for the JoditEditor content
  const handleApprove = async status => {
    const formData = {
      id: pendingApprovalId,
      project_id: globalProject?.value || selectedProject?.value,
      testplan_id: testPlanID,
      approver_comment: description,
      status: status,
    }

    try {
      await dispatch(testPlanSummaryReportsEditAPI(formData)) // Await the dispatch
      setRefresh(refresh + 1)
      resetForm() // Reset the form after successful submission
    } catch (error) {
      console.error("Failed to submit form:", error)
      // Optionally, show an error message to the user
    }
  }

  const handleCancel = () => {
    // Handle cancel logic here
    setDescription("") // Reset the editor content on cancel
  }

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader
            className="d-flex justify-content-between align-items-center"
            style={cardHeaderStyle}
          >
            <h5 className="mb-0">Comments</h5>
          </CardHeader>
          <CardBody>
            <div className="d-flex flex-column gap-2">
              <div>
                <JoditEditor
                  value={description} // Bind the value of the editor to the state
                  config={joditConfig()}
                  onBlur={newContent => setDescription(newContent)} // Update the state on change
                />
              </div>
              <div className="d-flex justify-content-end gap-1">
                <Button
                  color="secondary"
                  onClick={handleCancel}
                  className="mr-2"
                >
                  Cancel
                </Button>
                <Button
                  color="danger"
                  onClick={() => handleApprove("SignOff Rejected")}
                >
                  SignOff Rejected
                </Button>
                <Button
                  color="success"
                  onClick={() => handleApprove("SignOff Approved")}
                >
                  SignOff Approved
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default Comments
