import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import Breadcrumb from "components/Common/Breadcrumb"
import "../../Roles/Create Roles/CreateTestScenarioForm.scss"
// import { Link } from "react-router-dom";
// import PermissionComponent from "./PermissionComponent";
import Select from "react-select"
import { setEditIntegration } from "store/Slices/IntegrationSlice"
import { CreateIntegrationApi } from "store/actions/IntegrationAction"

const CommandStep = ({ label, command, onCopy }) => {
  const [copied, setCopied] = useState(false) // State to track if the command is copied
  const [hovered, setHovered] = useState(false)
  const handleCopy = () => {
    onCopy(command) // Call the copy function
    setCopied(true) // Set copied state to true

    // Reset the copied state after 3 seconds
    setTimeout(() => {
      setCopied(false)
    }, 3000)
  }
  return (
    <div className="command-step" style={styles.commandStep}>
      <label style={styles.label}>{label}</label>
      <code style={styles.code}>{command}</code>

      <button
        style={{
          ...styles.button,
          ...(hovered ? styles.buttonHovered : {}), // Apply hover style if hovered
        }}
        onClick={handleCopy}
        onMouseEnter={() => setHovered(true)} // Set hover state to true
        onMouseLeave={() => setHovered(false)} // Set hover state to false
        onMouseOver={e => {
          // Show "Copied!" tooltip when copied, otherwise show "Copy"
          e.target.setAttribute("title", copied ? "Copied!" : "Copy")
        }}
      >
        {/* Change icon based on copied state */}
        <i className={copied ? "mdi mdi-check" : "mdi mdi-content-copy"} />
      </button>
    </div>
  )
}
const copyToClipboard = (command) => {
  event.preventDefault();
  navigator.clipboard.writeText(command).then(() => {
  });
};
const CreateIntegration = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const editRef = useRef(null);
  const loading = useSelector(state => state?.Loading?.isLoading)

  const editUsers = useSelector(state => state?.Integration?.editIntegration)
  const [validationErrMsg, setValidationErrMsg] = useState("")

  const isEditMode = editUsers?.isEdit
  const isEditModeData = editUsers?.data

  const [projectOptions, setProjectOptions] = useState([])
  const selectedProject = localStorage.getItem("productIntegration")

  const [product, setProduct] = useState(
    selectedProject ? selectedProject : null
  )
  const [jiraEmail, setJiraEmail] = useState("")
  const [jiraUrl, setJiraUrl] = useState("")
  const [jiraToken, setJiraToken] = useState("")
  const [gitToken, setGitToken] = useState(null)
  const [gitUrl, setGitUrl] = useState("https://api.github.com/")
  const [gitLabToken, setGitLabToken] = useState(null)
  const [gitLabUrl, setGitLabUrl] = useState("https://api.gitlab.com/")
  const [browserStackName, setBrowserStackName] = useState(null)
  const [browserStackToken, setBrowserStackToken] = useState(null)
  const [browserStackUrl, setBrowserStackUrl] = useState(
    "https://api-cloud.browserstack.com"
  )
  const [planeEmail, setPlaneEmail] = useState(null)
  const [planeUrl, setPlaneUrl] = useState(null)
  const [planeToken, setPlaneToken] = useState(null)

  const productOptions = [
    { value: "Jira", label: "Jira" },
    { value: "BrowserStack", label: "BrowserStack" },
    { value: "GitHub", label: "GitHub" },
    { value: "Plane", label: "Plane" },
    // { value: "GitLab", label: "GitLab" },
  ]

  const ProjectDetails = useSelector(state => state?.Project?.allProjectList)

  useEffect(() => {
    const projectList = ProjectDetails?.map(entry => ({
      value: Math.round(entry?.id),
      label: entry.project_name,
    }))
    setProjectOptions(projectList)
  }, [ProjectDetails])

  useEffect(() => {
    const project = localStorage.getItem("productIntegration")
    setProduct(project)
  }, [selectedProject])

  const breadcrumbItems = [
    { label: "Settings", path: "/Settings/Integration" },
    {
      label: isEditMode ? "Edit Integration " : "Create Integration",
      path: "/",
    },
  ]

  const checkValidations = () => {
    const errors = {}
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (product === "Jira") {
      if (!jiraEmail || jiraEmail.trim() === "") {
        errors.jiraEmail = "Jira Email required"
      } else if (!emailRegex.test(jiraEmail)) {
        errors.jiraEmail = "Valid Jira Email required"
      }
      if (!jiraToken || jiraToken.trim() === "")
        errors.jiraToken = "Jira Token required"
      if (!jiraUrl || jiraUrl.trim() === "") {
        errors.jiraUrl = "Jira URL required"
      } else if (!/^https?:\/\/|^$/.test(jiraUrl)) {
        errors.jiraUrl = "Valid Jira URL required"
      }
    } else if (product === "GitHub") {
      if (!gitToken || gitToken.trim() === "")
        errors.gitToken = "GitHub Access token is required"
    } else if (product === "GitLab") {
      if (!gitLabToken || gitLabToken.trim() === "")
        errors.gitLabToken = "GitLab Access token is required"
    } else if (product === "BrowserStack") {
      if (!browserStackName || browserStackName.trim() === "") {
        errors.browserStackName = "Browser Stack Username is required"
      } else if (emailRegex.test(browserStackName)) {
        errors.browserStackName = "Browser Stack Username is required"
      }
      if (!browserStackToken || browserStackToken.trim() === "")
        errors.browserStackToken = "Browser Stack Access key is required"
    } else if (product === "Plane") {
      if (!planeEmail || planeEmail.trim() === "") {
        errors.planeEmail = "Plane email is required"
      } else if (!emailRegex.test(planeEmail)) {
        errors.planeEmail = "Valid Plane Email is required"
      }
      if (!planeToken || planeToken.trim() === "")
        errors.planeToken = "Plane Token is required"
      if (!planeUrl || planeUrl.trim() === "") {
        errors.planeUrl = "Plane URL is required"
      } else if (!/^https?:\/\/|^$/.test(planeUrl)) {
        errors.planeUrl = "Valid Plane URL is required"
      }
    }
    return errors
  }

  const validationSchema = Yup.object().shape({
    product: Yup.string().required("Product is required"),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      product: isEditModeData
        ? isEditModeData?.product || ""
        : selectedProject
          ? selectedProject
          : "",
    },
    validationSchema,
    onSubmit: values => {
      let bodyoptions

      if (product === "Jira") {
        bodyoptions = {
          ...values,
          email: jiraEmail,
          token: jiraToken,
          url: jiraUrl,
        }
      } else if (product === "GitHub") {
        bodyoptions = {
          ...values,
          token: gitToken,
          url: gitUrl,
        }
      } else if (product === "Plane") {
        bodyoptions = {
          ...values,
          token: planeToken,
          email: planeEmail,
          url: planeUrl,
        }
      } else if (product === "BrowserStack") {
        bodyoptions = {
          ...values,
          token: browserStackToken,
          username: browserStackName,
          url: browserStackUrl,
        }
      }
      createEditRoles(bodyoptions)
    },
  })

  const createEditRoles = async formData => {
    const validationErrorMsg = checkValidations()
    setValidationErrMsg(validationErrorMsg)

    if (Object.keys(validationErrorMsg)?.length === 0) {
      if (!isEditModeData) {
        try {
          await dispatch(CreateIntegrationApi(formData))
          localStorage.removeItem("productIntegration")
          navigate(-1)
        } catch (error) {
          console.log(error)

          toast.error(error?.message || "An error occurred while creating ", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          })
        }
      }
    }
  }

  const handleProductChange = option => {
    formik.setFieldValue("product", option?.value)
    setProduct(option?.value)
  }

  const layoutModeType = useSelector(state => state.Layout.layoutModeType)

  const customStyle = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888",
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Ensure input text is visible
    }),
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Breadcrumb title="Roles" breadcrumbItems={breadcrumbItems} />

        <div className="mt-2 mx-1 col-12 d-flex justify-content-between">
          <div>
            <h3>{!isEditMode ? "Create Integration" : "Edit Integration"}</h3>
            <p className="text-muted">
              Fill the form to {!isEditMode ? "create" : "edit"} a integration
            </p>
          </div>
          <div>
            <Button
              type="button"
              color="primary"
              className="btn-label"
              onClick={() => {
                navigate("/Settings/Integration")
                dispatch(setEditIntegration({ isEdit: false, data: null }))
                localStorage.removeItem("productIntegration")
              }}
            >
              <i className="bx bx-arrow-back label-icon"></i> Go Back
            </Button>
          </div>
        </div>

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {product === "GitHub" && (
                    <div>
                      <Label>
                        Make sure your GitHub Access Token has the following
                        permissions<span className="text-danger">*</span>
                      </Label>
                      <ol>
                        <li>admin:org</li>
                        <li>admin:repo_hook</li>
                        <li>repo</li>
                        <li>workflow</li>
                      </ol>
                    </div>
                  )}
                  {product === "Plane" && (
                    <CommandStep
                      label="Ensure that your Plane URL follows this format:"
                      command="<planeurl>/api/v1/workspaces/<workspacename>/projects/"
                      onCopy={copyToClipboard}
                    />
                  )}
                  <div className="mb-3 col-12 d-flex flex-column flex-md-row gap-3">
                    <div className="col-12 col-md-5">
                      <Label className="form-label">
                        Product<span className="text-danger">*</span>
                      </Label>
                      <Select
                        isClearable={true}
                        name="product"
                        options={productOptions}
                        onChange={option => handleProductChange(option)}
                        onBlur={() => formik.setFieldTouched("product")}
                        value={productOptions.find(
                          option => option.label === formik.values.product
                        )}
                        className="select2-selection"
                        styles={customStyle}
                      />
                      {formik.touched.product && formik.errors.product && (
                        <div className="text-danger required-field">
                          {formik.errors.product}
                        </div>
                      )}
                    </div>

                    {product === "Jira" && (
                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          Jira Email<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          placeholder="Jira Email"
                          onChange={e => setJiraEmail(e.target.value)}
                          onBlur={e => setJiraEmail(e.target.value)}
                          value={jiraEmail}
                          className="custom-input"
                        />
                        {validationErrMsg?.jiraEmail && (
                          <span className="text-danger required-field">
                            {validationErrMsg?.jiraEmail}
                          </span>
                        )}
                      </div>
                    )}

                    {product === "BrowserStack" && (
                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          Browser Stack Username
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          placeholder="Browser Stack Username"
                          onChange={e => setBrowserStackName(e.target.value)}
                          onBlur={e => setBrowserStackName(e.target.value)}
                          value={browserStackName}
                          className="custom-input"
                        />
                        {validationErrMsg?.browserStackName && (
                          <span className="text-danger required-field">
                            {validationErrMsg?.browserStackName}
                          </span>
                        )}
                      </div>
                    )}

                    {product === "GitHub" && (
                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          GitHub Access Token
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          placeholder="GitHub Access Token"
                          onChange={e => setGitToken(e.target.value)}
                          onBlur={e => setGitToken(e.target.value)}
                          value={gitToken}
                          className="custom-input"
                        />
                        {validationErrMsg?.gitToken && (
                          <span className="text-danger required-field">
                            {validationErrMsg?.gitToken}
                          </span>
                        )}
                      </div>
                    )}

                    {product === "Plane" && (
                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          Plane Email<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          placeholder="Plane Email"
                          onChange={e => setPlaneEmail(e.target.value)}
                          onBlur={e => setPlaneEmail(e.target.value)}
                          value={planeEmail}
                          className="custom-input"
                        />
                        {validationErrMsg?.planeEmail && (
                          <span className="text-danger required-field">
                            {validationErrMsg?.planeEmail}
                          </span>
                        )}
                      </div>
                    )}
                  </div>

                  {product === "Jira" && (
                    <div className="mb-3 col-12 d-flex flex-column flex-md-row gap-3">
                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          Jira Token<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          placeholder="Jira Token"
                          onChange={e => setJiraToken(e.target.value)}
                          onBlur={e => setJiraToken(e.target.value)}
                          value={jiraToken}
                          className="custom-input"
                        />
                        {validationErrMsg?.jiraToken && (
                          <span className="text-danger required-field">
                            {validationErrMsg?.jiraToken}
                          </span>
                        )}
                      </div>

                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          Jira URL<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          placeholder="Jira URL"
                          onChange={e => setJiraUrl(e.target.value)}
                          onBlur={e => setJiraUrl(e.target.value)}
                          value={jiraUrl}
                          className="custom-input"
                        />
                        {validationErrMsg?.jiraUrl && (
                          <span className="text-danger required-field">
                            {validationErrMsg?.jiraUrl}
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                  {product === "Plane" && (
                    <div className="mb-3 col-12 d-flex flex-column flex-md-row gap-3">
                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          Plane Token<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          placeholder="Plane Token"
                          onChange={e => setPlaneToken(e.target.value)}
                          onBlur={e => setPlaneToken(e.target.value)}
                          value={planeToken}
                          className="custom-input"
                        />
                        {validationErrMsg?.planeToken && (
                          <span className="text-danger required-field">
                            {validationErrMsg?.planeToken}
                          </span>
                        )}
                      </div>

                      <div className="col-12 col-md-5">
                        <Label className="form-label">Plane URL<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          placeholder="Plane URL"
                          onChange={e => setPlaneUrl(e.target.value)}
                          onBlur={e => setPlaneUrl(e.target.value)}
                          value={planeUrl}
                          className="custom-input"
                        />
                        {validationErrMsg?.planeUrl && (
                          <span className="text-danger required-field">
                            {validationErrMsg?.planeUrl}
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                  {product === "BrowserStack" && (
                    <div className="mb-3 col-12 d-flex flex-column flex-md-row gap-3">
                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          Browser Stack Access Key
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          placeholder="Browser Stack Access Key"
                          onChange={e => setBrowserStackToken(e.target.value)}
                          onBlur={e => setBrowserStackToken(e.target.value)}
                          value={browserStackToken}
                          className="custom-input"
                        />
                        {validationErrMsg?.browserStackToken && (
                          <span className="text-danger required-field">
                            {validationErrMsg?.browserStackToken}
                          </span>
                        )}
                      </div>

                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          Browser Stack URL
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          placeholder="Jira URL"
                          disabled
                          onChange={e => setBrowserStackUrl(e.target.value)}
                          onBlur={e => setBrowserStackUrl(e.target.value)}
                          value={browserStackUrl}
                          className="custom-input"
                        />
                      </div>
                    </div>
                  )}

                  {product === "GitHub" && (
                    <div className="mb-3 col-12 d-flex flex-column flex-md-row gap-3">
                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          GitHub URL<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          disabled
                          placeholder="GitHub URL"
                          onChange={e => gitUrl(e.target.value)}
                          onBlur={e => gitUrl(e.target.value)}
                          value={gitUrl}
                          className="custom-input"
                        />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col lg={12}>
              <Row>
                <Col md="12" className="text-end mt-3 mb-2">
                  <Button color="primary" type="submit" disabled={loading}>
                    {loading ? "Loading..." : "Submit"}
                  </Button>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => {
                      formik.resetForm()
                      navigate("/Settings/Integration")
                      dispatch(
                        setEditIntegration({ isEdit: false, data: null })
                      )
                      localStorage.removeItem("productIntegration")
                    }}
                    className="ms-2"
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </React.Fragment>
  )
}
const styles = {
  commandStep: {
    position: 'relative',
    marginBottom: '15px',
    padding: '0px',
    // backgroundColor: '#f4f4f4',
    borderRadius: '8px',
    fontFamily: 'monospace',
    width: '50%'
  },
  code: {
    display: 'block',
    backgroundColor: 'rgb(207 207 207 / 73%)',
    color: 'black',
    padding: '10px',
    borderRadius: '5px',
  },
  button: {
    position: 'absolute',
    right: '10px',
    top: '32px',
    backgroundColor: 'rgb(193 182 182 / 0%)', // Your updated background color
    color: 'black',                     // Button text color
    border: 'none',                     // No border
    padding: '5px 10px',                // Padding for the button
    borderRadius: '3px',                // Rounded corners
    cursor: 'pointer',                  // Pointer cursor on hover
    transition: 'background-color 0.3s ease', // Smooth background color transition on hover
  },
  container: {
    padding: '20px',
    backgroundColor: '#f4f4f4',
    borderRadius: '8px',
    fontFamily: 'monospace',
    marginTop: '1rem'
  },
  containerSub: {
    backgroundColor: '#f4f4f4',
    borderRadius: '8px',
    fontFamily: 'monospace',
  }
};
export default CreateIntegration
