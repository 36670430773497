import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Container,
  Form,
  Alert,
  UncontrolledTooltip,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import "flatpickr/dist/themes/material_blue.css"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import Breadcrumb from "components/Common/Breadcrumb"
import { fetchAllTeamsDetailsUserAssigneeList } from "store/actions/UserActions"
import { setEditTestPlan } from "store/Slices/TestPlanSlice"
import {
  createScheduleReportAPI,
  fetchAllReportsTemplateAPI,
} from "store/actions/ReportsAction"
import TimezoneSelect from "react-timezone-select"
import { CiCircleInfo } from "react-icons/ci"
const CreateScheduleReport = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )
  const loading = useSelector(state => state?.Loading?.isLoading)
  const breadcrumbItems = [
    { label: "Report", path: "/Reports/Schedule" },
    {
      label: "Schedule Report",
      path: "/",
    },
  ]

  const validationSchema = Yup.object().shape({
    report_type: Yup.string().required("Report Type is required"),
    report_template_id: Yup.string().required("Saved Template is required"),
    frequency: Yup.string().required("Frequency is required"),
    is_day: Yup.string()
      .test(
        "day-required",
        "Day is required for weekly and bi-weekly frequencies",
        function (value) {
          const { frequency } = this.parent
          if (frequency === "weekly" || frequency === "bi-weekly") {
            return value ? true : false
          }
          return true // Skip validation for other frequencies
        }
      )
      .nullable(),
    report_format: Yup.string().required("Report Format is required"),
    report_time: Yup.string().required("Time is required"),
    time_zone: Yup.string().required("Time Zone is required"),
    share_with: Yup.array().min(
      1,
      "At least one user must be selected to share with"
    ),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      project_id: globalProject?.value || "",
      report_template_id: null,
      report_type: null,
      frequency: null,
      is_day: null,
      report_format: null,
      report_time: null,
      time_zone: null,
      share_with: [],
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      const formData = {
        ...values,
        share_with: values.share_with.map(item => item.id),
      }
      create(formData)
      resetForm()
    },
  })

  const create = async formData => {
    const CreateFromValues = {
      ...formData,

      share_with:
        formData?.share_with?.length === 0 ? "" : formData?.share_with,
    }

    try {
      await dispatch(createScheduleReportAPI(CreateFromValues))
      navigate(-1) // Navigate back or handle success
    } catch (error) {
      toast.error(error || "An error occurred", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      })
    }
  }
  const getUniqueReportTypes = data => {
    return data.reduce((acc, current) => {
      if (!acc.some(item => item.report_type === current.report_type)) {
        acc.push(current)
      }
      return acc
    }, [])
  }
  const [shareWithValue, setShareWithValue] = useState([])
  const [reportTypeValue, setReportTypeValue] = useState([])
  const [savedTemplateOptionValue, setSavedTemplateOptionValues] = useState([])

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const responses = await Promise.all([
          dispatch(
            fetchAllReportsTemplateAPI({ project_id: globalProject?.value })
          ),
          dispatch(
            fetchAllTeamsDetailsUserAssigneeList({
              project_id: globalProject?.value,
            })
          ),
        ])

        const [savedTemplateResponse, userListResponse] = responses
        const uniqueReportTypes = getUniqueReportTypes(savedTemplateResponse)
        const reportTypesOptions = uniqueReportTypes
          .map(entry => ({
            value: entry.report_type,
            label: entry.report_type,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)) // Sort alphabetically

        const createdByOptions = [
          ...(userListResponse && Array.isArray(userListResponse.results)
            ? userListResponse.results.map(entry => ({
                value: entry.email,
                label: entry.user_name,
              }))
            : []),
        ]
        setReportTypeValue(reportTypesOptions)
        setShareWithValue(createdByOptions)
      } catch (error) {
        console.error("Failed to fetch options", error)
        toast.error("Failed to fetch options.")
      }
    }
    fetchOptions()
  }, [globalProject, dispatch])

  let bodyOptionBasedOnReportType = {
    project_id: globalProject?.value,
    report_type: formik.values.report_type,
  }
  useEffect(() => {
    if (!formik.values.report_type) return // Exit early if reportType is not available
    const fetchOptions = async () => {
      try {
        const savedTemplateResponse = await dispatch(
          fetchAllReportsTemplateAPI(bodyOptionBasedOnReportType)
        )

        const templatesOptions = savedTemplateResponse.map(entry => ({
          value: entry.id,
          label: entry.template_name,
        }))

        setSavedTemplateOptionValues(templatesOptions)
      } catch (error) {
        console.log(error)
      }
    }

    fetchOptions()
  }, [formik.values.report_type])
  const ReportFormatoptions = [
    { value: "csv", label: "CSV" },
    { value: "xlsx", label: "Excel" },
    { value: "doc", label: "Word" },
    { value: "pdf", label: "PDF" },
  ]
  const frequenecyOptions = [
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly" },
    { value: "bi-weekly", label: "Bi-weekly" },
    { value: "monthly", label: "Monthly" },
  ]
  const daysOptions = [
    { value: "mon", label: "Monday" },
    { value: "tue", label: "Tuesday" },
    { value: "wed", label: "Wednesday" },
    { value: "thu", label: "Thursday" },
    { value: "fri", label: "Friday" },
    { value: "sat", label: "Saturday" },
    { value: "sun", label: "Sunday" },
  ]
  useEffect(() => {
    if (formik?.values?.frequency) {
      switch (formik?.values.frequency) {
        case "monthly":
          toast.info(
            "Monthly Report Begins on the First Day of the Month. You will receive the report via email on the 1st day of each month.",
            {
              position: "top-right",
              autoClose: 5000,
            }
          )
          break
        case "weekly":
        case "bi-weekly":
          toast.info("Please Select a Day for the Report", {
            position: "top-right",
            autoClose: 5000,
          })
          break
        default:
          break
      }
    }
  }, [formik?.values?.frequency])

  useEffect(() => {
    if (formik?.values?.report_type && !formik?.values?.savedTemplate) {
      toast.info("Choose a Template for Your Report", {
        position: "top-right",
        autoClose: 5000,
      })
    }
  }, [formik?.values?.report_type])

  const layoutModeType = useSelector(state => state.Layout.layoutModeType)

  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
      borderColor: layoutModeType === "dark" ? "#444" : "#ddd",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#2a3042",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888", // Keep or customize based on dark/light mode
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Ensure text is visible while typing
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150, // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }

  const customStyle = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888",
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    // Customize multiValue (selected item background)
    multiValue: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#3a3f51" : "#e0e0e0", // Dark background for selected items
    }),
    // Customize multiValueLabel (selected item text color)
    multiValueLabel: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Adjust text color
    }),
    // Customize multiValueRemove (cross icon for removing selected items)
    multiValueRemove: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
      ":hover": {
        backgroundColor: layoutModeType === "dark" ? "#ff4949" : "#e0e0e0", // Hover effect for remove icon
        color: "#fff",
      },
    }),
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Breadcrumb title="Test Plan" breadcrumbItems={breadcrumbItems} />

        <div className="mt-2 mx-3 d-flex justify-content-between">
          <div>
            <h3>New Schedule Report</h3>
            <p className="text-muted">
              Fill the form to create Schedule Report
            </p>
          </div>
          <div>
            <Button
              type="button"
              color="primary"
              className="btn-label me-2"
              onClick={() => {
                navigate(-1)
                dispatch(setEditTestPlan({ isEdit: false, data: null }))
              }}
            >
              <i className="bx bx-arrow-back label-icon"></i> Go Back
            </Button>
          </div>
        </div>

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg={6}>
              <Card style={{ minHeight: "365px" }}>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label">
                      Report Type<span className="text-danger">*</span>
                    </Label>
                    <Select
                      styles={customStyle}
                      isClearable
                      className="select2-selection"
                      name="report_type"
                      options={reportTypeValue}
                      onChange={option =>
                        formik.setFieldValue("report_type", option?.value)
                      }
                      onBlur={() => formik.setFieldTouched("report_type")}
                      value={reportTypeValue.find(
                        option => option.value === formik.values.report_type
                      )}
                    />
                    {formik.touched.report_type && formik.errors.report_type ? (
                      <div className="text-danger">
                        {formik.errors.report_type}
                      </div>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">
                      Saved Template<span className="text-danger">*</span>{" "}
                      <CiCircleInfo size={16} id="test" />
                      <UncontrolledTooltip placement="top" target="test">
                        Select a Type to See Available Templates
                      </UncontrolledTooltip>
                    </Label>
                    <Select
                      isClearable
                      className="select2-selection"
                      name="report_template_id"
                      options={savedTemplateOptionValue}
                      onChange={option =>
                        formik.setFieldValue(
                          "report_template_id",
                          option?.value
                        )
                      }
                      onBlur={() =>
                        formik.setFieldTouched("report_template_id")
                      }
                      value={savedTemplateOptionValue.find(
                        option =>
                          option.value === formik.values.report_template_id
                      )}
                      isDisabled={
                        !formik.values.report_type ||
                        formik.values.report_type === ""
                      }
                      styles={customStyles}
                    />
                    {formik.touched.report_template_id &&
                    formik.errors.report_template_id ? (
                      <div className="text-danger">
                        {formik.errors.report_template_id}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">
                      Frequency<span className="text-danger">*</span>
                    </Label>
                    <Select
                      styles={customStyle}
                      isClearable
                      className="select2-selection"
                      name="frequency"
                      options={frequenecyOptions}
                      onChange={option =>
                        formik.setFieldValue("frequency", option?.value)
                      }
                      onBlur={() => formik.setFieldTouched("frequency")}
                      value={frequenecyOptions.find(
                        option => option.value === formik.values.frequency
                      )}
                    />
                    {formik.touched.frequency && formik.errors.frequency ? (
                      <div className="text-danger">
                        {formik.errors.frequency}
                      </div>
                    ) : null}
                  </div>
                  {/* <div className="mb-3">
                    <Label className="form-label">
                      Day<span className="text-danger">*</span>
                    </Label>
                    <Select
                    isClearable
                    className = "select2-selection"
                      name="is_day"
                      options={daysOptions}
                      onChange={option =>
                        formik.setFieldValue("is_day", option?.value)
                      }
                      onBlur={() => formik.setFieldTouched("is_day")}
                      value={daysOptions.find(
                        option => option.value === formik.values.is_day
                      )}
                    />
                    {formik.touched.is_day && formik.errors.is_day ? (
                      <div className="text-danger">{formik.errors.is_day}</div>
                    ) : null}
                  </div> */}
                  {(formik.values.frequency === "weekly" ||
                    formik.values.frequency === "bi-weekly") && (
                    <div className="mb-3">
                      <Label className="form-label">
                        Day<span className="text-danger">*</span>
                      </Label>
                      <Select
                        styles={customStyle}
                        isClearable
                        className="select2-selection"
                        name="is_day"
                        options={daysOptions}
                        onChange={option =>
                          formik.setFieldValue("is_day", option?.value)
                        }
                        onBlur={() => formik.setFieldTouched("is_day")}
                        value={daysOptions.find(
                          option => option.value === formik.values.is_day
                        )}
                      />
                      {formik.touched.is_day && formik.errors.is_day ? (
                        <div className="text-danger">
                          {formik.errors.is_day}
                        </div>
                      ) : null}
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label">
                      Report Format <span className="text-danger">*</span>
                    </Label>
                    <Select
                      styles={customStyle}
                      isClearable
                      className="select2-selection"
                      name="report_format"
                      options={ReportFormatoptions}
                      onChange={option =>
                        formik.setFieldValue("report_format", option?.value)
                      }
                      onBlur={() => formik.setFieldTouched("report_format")}
                      value={ReportFormatoptions.find(
                        option => option.value === formik.values.report_format
                      )}
                    />
                    {formik.touched.report_format &&
                    formik.errors.report_format ? (
                      <div className="text-danger">
                        {formik.errors.report_format}
                      </div>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <label className="form-label">
                      Time<span className="text-danger">*</span>
                    </label>
                    <input
                      aria-label="Time"
                      name="report_time"
                      className="form-control"
                      type="time"
                      onChange={event =>
                        formik.setFieldValue("report_time", event.target.value)
                      }
                      value={formik.values.report_time}
                      id="example-time-input"
                    />

                    {formik.touched.report_time && formik.errors.report_time ? (
                      <div className="text-danger">
                        {formik.errors.report_time}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">
                      Time Zone<span className="text-danger">*</span>
                    </Label>
                    <TimezoneSelect
                      value={formik.values.time_zone}
                      onChange={option =>
                        formik.setFieldValue("time_zone", option.value)
                      }
                    />

                    {formik.touched.time_zone && formik.errors.time_zone ? (
                      <div className="text-danger">
                        {formik.errors.time_zone}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">
                      Assign To
                      <span className="text-danger">*</span>
                    </Label>
                    <Select
                      styles={customStyle}
                      isClearable
                      className="select2-selection"
                      name="share_with"
                      options={shareWithValue}
                      isMulti
                      onChange={options => {
                        // Convert selected options to the format needed by Formik
                        formik.setFieldValue(
                          "share_with",
                          options
                            ? options.map(option => ({
                                id: option.value,
                                user_name: option.label,
                              }))
                            : []
                        )
                      }}
                      onBlur={() => formik.setFieldTouched("share_with", true)}
                      value={shareWithValue.filter(option =>
                        formik.values.share_with.some(
                          selected => selected.id === option.value
                        )
                      )}
                    />

                    {formik.touched.share_with && formik.errors.share_with ? (
                      <div className="text-danger">
                        {formik.errors.share_with}
                      </div>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={12}>
              <Row className="mb-3">
                <Col md="12" className="text-end mt-3">
                  <Button color="primary" type="submit" disabled={loading}>
                    {loading ? "Loading..." : "Submit"}
                  </Button>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => {
                      formik.resetForm()
                      navigate(-1)
                      dispatch(setEditTestPlan({ isEdit: false, data: null }))
                    }}
                    className="ms-2"
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </React.Fragment>
  )
}

export default CreateScheduleReport
