import React, { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

const PageNavigation = props => {
  const {
    handleTestCaseClick,
    handleTestScenarioClick,
    activeButton,
    hanldePreCondition,
    onBreadcrumbClick,
  } = props
  const navigate = useNavigate()

  const location = useLocation()
  const locationPath = location.pathname
  const pathname = locationPath.split("/").filter(Boolean).pop()

  const handleTestScenario = () => {
    navigate("TestScenario")
    handleTestScenarioClick()
  }
  const handleTestCase = () => {
    navigate("TestCase")
    handleTestCaseClick()
  }
  useEffect(() => {
   if(pathname == "PreCondition"){
    hanldePreCondition()
   }
  }, [pathname])

  useEffect(() => {
    if (pathname == "TestScenario" || locationPath == "/TestManagement") {
      handleTestScenario()
    } else if (pathname == "TestCase") {
      handleTestCase()
    }
  }, [pathname])
  return (
    <>
        <div className="">
          <div
            className="btn-group"
            role="group"
            style={{ whiteSpace: "nowrap" }}
          >
            <input
              type="radio"
              className="btn-check"
              id="btnradio1"
              name="btnradio"
              autoComplete="off"
              checked={activeButton === "TestScenario"} // Sync with active state
            />
            <label
              className="btn btn-outline-primary"
              htmlFor="btnradio1"
              onClick={handleTestScenario}
            >
              Test Scenario
            </label>

            <input
              type="radio"
              className="btn-check"
              id="btnradio2"
              name="btnradio"
              autoComplete="off"
              checked={activeButton === "TestCase"} // Sync with active state
            />
            <label
              className="btn btn-outline-primary"
              htmlFor="btnradio2"
              onClick={handleTestCase}
            >
              Test Case
            </label>
          </div>
        </div>
    </>
  )
}

export default PageNavigation
