import { toast } from "react-toastify"
import httpInjectorService from "services/http-Injector.Service"
import { setLoading } from "store/Slices/LoadingSlice"
import { setIntegrationList, setMapList, setSingleIntegrationData } from "store/Slices/IntegrationSlice"

export const getAllIntegrationListAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))

    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }

    try {
      const response = await httpInjectorService.getAllIntegrationList(
        queryString
      )
      const data = response
      dispatch(setIntegrationList(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const deleteIntegrationAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.deleteIntegration(bodyoption)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Defect Deleted successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }else{
        throw new Error(response.message)
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const IntegrationRetrieveApi = id => {
  return async dispatch => {
    dispatch(setLoading(true))

    try {
      const response = await httpInjectorService.getIntegrationRetrieve(id)
      const data = response
      dispatch(setSingleIntegrationData(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const CreateIntegrationApi = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.createIntegration(formData)
      
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Created successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
        return response;
      }else {
        throw new Error(response?.non_field_errors?response?.non_field_errors[0] : response?.token);
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error);
    }
  }
}
export const editIntegrationApi = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (bodyoption.hasOwnProperty(key)) {
        if (key === "menu_item" && bodyoption[key]) {
          formData.append("menu_item", JSON.stringify(bodyoption.menu_item))
        } else if (
          bodyoption[key] !== undefined &&
          bodyoption[key] !== null &&
          bodyoption[key] !== ""
        ) {
          formData.append(key, bodyoption[key])
        }
      }
    }
    try {
      const response = await httpInjectorService.editIntegration(
        formData,
        bodyoption?.id
      )
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Updated successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }else {
        throw new Error(response?.non_field_errors?response?.non_field_errors[0] : response?.token);
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error);
    }
  }
}
export const getAllMapProject = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))

    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }

    try {
      const response = await httpInjectorService.getAllMapList(
        queryString
      )
      const data = response
      dispatch(setMapList(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const getAllJiraList = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))

    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.jiraList(
        formData
      )
      const data = response
      
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const CreateMapProject = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.createMap(formData)
      
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Created successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
        return response;
      }else {
        throw new Error(response?.non_field_errors?response?.non_field_errors[0] : response?.token);
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error);
    }
  }
}
export const deleteMapAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.deleteMap(bodyoption)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Defect Deleted successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }else{
        throw new Error(response.message || response.error)
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const getAllPlaneList = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))

    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.planeList(
        formData
      )
      const data = response
      
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const getAllGitList = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))

    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.gitHubList(
        formData
      )
      const data = response
      
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const getCustomFields = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))

    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }    
    try {
      const response = await httpInjectorService.fetchList(
        formData
      )
      const data = response
      
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const customFieldUpdate = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (bodyoption.hasOwnProperty(key)) {
        if (key === "jira_map_fields" && bodyoption[key]) {
          formData.append("jira_map_fields", JSON.stringify(bodyoption.jira_map_fields))
        } else if (
          bodyoption[key] !== undefined &&
          bodyoption[key] !== null &&
          bodyoption[key] !== ""
        ) {
          formData.append(key, bodyoption[key])
        }
      }
    }
    try {
      const response = await httpInjectorService.editCustomFields(
        formData,
        bodyoption?.id
      )
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Updated successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }else {
        throw new Error(errorData?.non_field_errors[0] || errorData.errors|| "Something went wrong");
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error);
    }
  }
}
export const CreateNewRepo = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.CreateRepo(formData)
      
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Created successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
        return response;
      }else {
        throw new Error(response?.message || response?.errors);
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error?.message);
    }
  }
}