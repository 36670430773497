import React, { useEffect, useState } from "react"
import {
  Alert,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap"
import Select from "react-select"
import { fetchAllBuildsList } from "store/actions/BuildsActions"
import { fetchAllModulesList } from "store/actions/ModuleActions"
import { fetchAllRequirementsList } from "store/actions/RequirementActions"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

const BulkEditTestScenario = ({
  isOpen,
  toggle,
  setSelectedRows,
  handleBulkEdit,
}) => {
  const dispatch = useDispatch()

  const [selectedBuild, setSelectedBuild] = useState(null)
  const [selectedModule, setSelectedModule] = useState(null)
  const [selectedRequirement, setSelectedRequirement] = useState(null)

  const [buildOptionValue, setBuildOptionValues] = useState([])
  const [moduleOptionValue, setModuleOptionValues] = useState([])
  const [requirementOptionValue, setrequirementOptionValues] = useState([])

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )
  
  const layoutModeType = useSelector((state) => state.Layout.layoutModeType);  

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: layoutModeType === 'dark' ? '#2a3042' : '#fff',
      color: layoutModeType === 'dark' ? '#fff' : '#000',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: layoutModeType === 'dark' ? '#2a3042' : '#fff',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: layoutModeType === 'dark' ? '#fff' : '#000',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: layoutModeType === 'dark' ? '#888' : '#888',
    }),
    input: (provided) => ({
      ...provided,
      color: layoutModeType === 'dark' ? '#fff' : '#000', // Ensure input text is visible
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150, // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }
  const handleClearFilters = () => {
    setSelectedBuild(null)
    setSelectedModule(null)
    setSelectedRequirement(null)
  }
  const handleApplyBulkEdit = () => {
    let data = {
      build_id: selectedBuild?.value,
      module_id: selectedModule?.value,
      requirement_id: selectedRequirement?.value,
    }
    handleBulkEdit(data)
    setTimeout(() => {
      handleClearFilters()
    }, 2000)
  }

  useEffect(() => {
    let bodyOption = {
      project_id: globalProject?.value,
    }
    const fetchOptions = async () => {
      try {
        const responses = await Promise.all([
          dispatch(fetchAllBuildsList(bodyOption)),
          dispatch(fetchAllModulesList(bodyOption)),
          dispatch(fetchAllRequirementsList(bodyOption)),
        ])

        const [buildResponse, moduleResponse, reqResponse] = responses

        const buildOptions = buildResponse?.map(entry => ({
          value: Math.round(entry.id),
          label: entry.build_id,
        }))

        const moduleOptions = moduleResponse?.map(entry => ({
          value: entry.id,
          label: entry.module_name,
        }))

        const reqOptions = reqResponse?.map(entry => ({
          value: entry.id,
          label: entry.requirement_title,
        }))

        setBuildOptionValues(buildOptions)
        setModuleOptionValues(moduleOptions)
        setrequirementOptionValues(reqOptions)
      } catch (error) {
        console.error(error)
      }
    }

    fetchOptions()
  }, [isOpen])

  const handleClose = () => {
    toggle(),
      setSelectedRows([]),
      setTimeout(() => {
        handleClearFilters()
      }, 2000)
  }
  return (
    <Offcanvas isOpen={isOpen} direction="end" toggle={handleClose}>
      <OffcanvasHeader toggle={handleClose}>
        Bulk Edit Test Scenario
      </OffcanvasHeader>
      <div className=" mx-3">
        <Alert color="info" role="alert">
          By Updating all the previous associations for selected records will be
          reset.
        </Alert>
      </div>
      <OffcanvasBody>
        <div className="mb-3">
          <Label>Build</Label>
          <Select
            value={selectedBuild}
            onChange={setSelectedBuild}
            options={buildOptionValue}
            styles={customStyles}
            maxMenuHeight={200}
            className="select2-selection"
          />
        </div>
        <div className="mb-3">
          <Label>Module</Label>
          <Select
            value={selectedModule}
            onChange={setSelectedModule}
            options={moduleOptionValue}
            styles={customStyles}
            maxMenuHeight={200}
            className="select2-selection"
          />
        </div>
        <div className="mb-3">
          <Label>Requirement</Label>
          <Select
            value={selectedRequirement}
            onChange={setSelectedRequirement}
            options={requirementOptionValue}
            styles={customStyles}
            maxMenuHeight={200}
            className="select2-selection"
          />
        </div>
        <div className="d-flex justify-content-between mt-5">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleApplyBulkEdit}
          >
            Apply
          </button>
        </div>
      </OffcanvasBody>
    </Offcanvas>
  )
}

export default BulkEditTestScenario
