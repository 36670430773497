import React, { useEffect, useMemo, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Container,
  Form,
  InputGroup,
  UncontrolledTooltip,
} from "reactstrap"
import { useFormik } from "formik"
import "flatpickr/dist/themes/material_blue.css"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import { createOptionsForReports } from "../../../helpers/helper"
import { fetchAllBuildsList } from "store/actions/BuildsActions"
import { fetchAllModulesList } from "store/actions/ModuleActions"
import { fetchAllRequirementsList } from "store/actions/RequirementActions"
import { fetchAllTeamsDetailsUserAssigneeList } from "store/actions/UserActions"
import { getTagsStautusAPI } from "store/actions/TagsAction"
import Flatpickr from "react-flatpickr"
import { Link } from "react-router-dom"
import { setSelectedReqTraceabilityformValues } from "store/Slices/ReportsSlice"
import GenerateReport from "./GenerateReport"
import SaveAsTemplate from "../common/SaveAsTemplate"
import { reports } from "../helper/config"
import {
  fetchAllReportsTemplateAPI,
  GetSingleReportTemplateAPI,
  saveAsTemplateAPI,
} from "store/actions/ReportsAction"
import { fetchTestCycleDetailsAPI } from "store/actions/TestCycleActions"
import * as Yup from "yup"
import moment from "moment"
import NoPermission from "components/Common/NoPermission"

const RequirementTraceabilityReport = () => {
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))
  const dispatch = useDispatch()
  const [selectedTemplate, setSelectedTemplate] = useState("")
  const [selectedTemplateOption, setselectedTemplateOption] = useState(null)
  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )
  const validationSchema = Yup.object({
    from_date_execution: Yup.date()
      .nullable()
      .test(
        "fromDate-test",
        "From Date is required when To Date is selected",
        function (value) {
          const { to_date_execution } = this.parent // Access the value of to_date_execution
          if (to_date_execution && !value) {
            return false // From Date is required if To Date is selected
          }
          return true // If To Date is not selected, allow fromDate to be null
        }
      ),

    to_date_execution: Yup.date()
      .nullable()
      .test("toDate-test", "To Date must be after From Date", function (value) {
        const { from_date_execution } = this.parent // Access the value of from_date_execution
        if (from_date_execution && value) {
          return new Date(value) > new Date(from_date_execution) // Validate that toDate is after fromDate
        }
        return true // If fromDate is not selected, allow toDate to be null
      }),
  })
  const formik = useFormik({
    initialValues: {
      filter_build_id: null,
      filter_module_id: null,
      filter_requirement_id: null,
      filter_cycle: null,
      filter_executed_status: null,
      filter_execution_type: null,
      filter_tested_by: null,
      from_date_execution: "",
      to_date_execution: "",
      selectedFields: {
        RequirementTitle: true,
        RequirementId: false,
        RequirementDescription: false,
        TestCaseId: false,
        TestCaseName: true,
        TestCaseSummary: false,
        TestScenarioId: false,
        TestScenarioName: false,
        TestScenarioDescription: false,
        Priority: false,
        ExecutionType: false,
        PreCondition: false,
        PostCondition: false,
        BuildName: false,
        ModuleName: false,
        CreatedAt: false,
        Cycle: true,
        ExecutedStatus: false,
        ExpectedResult: false,
        AssociatedDefects: false,
        TestCaseExecutedBy: false,
        Comments: false,
        Attachment: false,
      },
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      dispatch(setSelectedReqTraceabilityformValues(values))
    },
  })
  //select all functionality
  const [selectAll, setSelectAll] = useState(false)

  const handleSelectAll = () => {
    const updatedFields = Object.keys(formik.values.selectedFields).reduce(
      (acc, field) => {
        // Ensure only TestCaseName, RequirementTitle, and Cycle are always true
        acc[field] =
          field === "TestCaseName" ||
          field === "RequirementTitle" ||
          field === "Cycle"
            ? true
            : !selectAll // Toggle the rest based on selectAll
        return acc
      },
      {}
    )

    formik.setFieldValue("selectedFields", updatedFields)
    setSelectAll(!selectAll) // Toggle the state of selectAll
  }

  useEffect(() => {
    // Check if all checkboxes are selected (except TestCaseName, RequirementTitle, and Cycle)
    const allSelected = Object.keys(formik.values.selectedFields).every(
      field =>
        field === "TestCaseName" ||
        field === "RequirementTitle" ||
        field === "Cycle" ||
        formik.values.selectedFields[field]
    )

    setSelectAll(allSelected) // Automatically set the selectAll checkbox if all are selected
  }, [formik.values.selectedFields])

  const [optionValues, setOptionValues] = useState({
    filter_build_id: [],
    filter_module_id: [],
    filter_requirement_id: [],
    filter_executed_status: [],
    filter_execution_type: [],
    filter_cycle: [],
    filter_tested_by: [],
  })

  const [options, setOptions] = useState({
    filter_executed_status: [],
    filter_execution_type: [],
  })

  const bodyOption = useMemo(
    () => ({
      project_id: globalProject?.value || selectedProject?.value,
    }),
    [globalProject, selectedProject]
  )

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const responses = await Promise.all([
          dispatch(fetchAllBuildsList(bodyOption)),
          dispatch(fetchAllModulesList(bodyOption)),
          dispatch(fetchAllRequirementsList(bodyOption)),
          dispatch(fetchAllTeamsDetailsUserAssigneeList(bodyOption)),
          dispatch(getTagsStautusAPI(bodyOption)),
          dispatch(fetchTestCycleDetailsAPI(bodyOption)),
        ])

        const [
          buildResponse,
          moduleResponse,
          reqResponse,
          userListResponse,
          metaDataResponse,
          testCycleResponse,
        ] = responses

        const buildOptions = [
          { value: "", label: "All" },
          ...(Array.isArray(buildResponse)
            ? buildResponse.map(entry => ({
                value: entry.id,
                label: entry.build_id,
              }))
            : []),
        ]
        const moduleOptions = [
          { value: "", label: "All" },
          ...(Array.isArray(moduleResponse)
            ? moduleResponse.map(entry => ({
                value: entry.id,
                label: entry.module_name,
              }))
            : []),
        ]
        const reqOptions = [
          { value: "", label: "All" },
          ...(Array.isArray(reqResponse)
            ? reqResponse.map(entry => ({
                value: entry.id,
                label: entry.requirement_title,
              }))
            : []),
        ]
        const testedByOptions = [
          { value: "", label: "All" },
          ...(Array.isArray(userListResponse?.results)
            ? userListResponse.results.map(entry => ({
                value: entry.email,
                label: entry.user_name,
              }))
            : []),
        ]
        const testCycleOptions = [
          { value: "", label: "All" },
          ...(Array.isArray(testCycleResponse)
            ? testCycleResponse.map(entry => ({
                value: entry.id,
                label: entry.test_cycle_id,
              }))
            : []),
        ]
        setOptions({
          filter_executed_status:
            metaDataResponse?.data?.Execution_Status || [],
          filter_execution_type: metaDataResponse?.data?.Execution_Type || [],
        })

        setOptionValues(prev => ({
          ...prev,
          filter_build_id: buildOptions,
          filter_module_id: moduleOptions,
          filter_requirement_id: reqOptions,
          filter_tested_by: testedByOptions,
          filter_cycle: testCycleOptions,
        }))
      } catch (error) {
        console.log(error)
      }
    }

    fetchOptions()
  }, [])

  useEffect(() => {
    setOptionValues(prev => ({
      ...prev,
      filter_executed_status: createOptionsForReports(
        options.filter_executed_status
      ),
      filter_execution_type: createOptionsForReports(
        options.filter_execution_type
      ),
    }))
  }, [options.filter_executed_status, options.filter_execution_type])
  const selectedFormValues = useSelector(
    state => state?.Reports?.selectedReqTraceabilityFormValues
  )

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSaveTemplate, setIsSaveTemplate] = useState(false)

  const handleGenerateReport = () => {
    formik.validateForm().then(() => {
      if (formik.isValid) {
        // Only dispatch when there are no errors
        dispatch(setSelectedReqTraceabilityformValues(formik.values))
        setIsModalOpen(!isModalOpen)
      } else {
        console.log("Form has errors:", formik.errors)
      }
    })
  }

  const handleSaveAsTemplate = () => {
    formik.validateForm().then(() => {
      if (formik.isValid) {
        setIsSaveTemplate(!isSaveTemplate)
      } else {
        console.log("Form has errors:", formik.errors)
      }
    })
  }
  const fetchTableData = async () => {
    let bodyoption = {
      project_id: globalProject?.value || selectedProject?.value,
      report_type: reports?.report_type?.requirement,
    }
    try {
      const data = await dispatch(fetchAllReportsTemplateAPI(bodyoption))
      setTemplates(data)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }
  // Mapping of formState keys to payload keys
  const fieldMappings = {
    RequirementTitle: "requirement_title",
    RequirementId: "requirement_id",
    RequirementDescription: "requirement_description",
    TestCaseId: "test_case_id",
    TestCaseName: "test_case_name",
    TestCaseSummary: "test_case_summary",
    TestScenarioId: "test_scenario_id",
    TestScenarioName: "test_scenario_name",
    TestScenarioDescription: "test_scenario_description",
    Priority: "priority",
    ExecutionType: "execution_type",
    PreCondition: "pre_condition",
    PostCondition: "post_condition",
    BuildName: "build_name",
    ModuleName: "module_name",
    CreatedAt: "created_at",
    Cycle: "cycle",
    ExecutedStatus: "executed_status",
    ExpectedResult: "expected_result",
    AssociatedDefects: "associated_defects",
    TestCaseExecutedBy: "test_case_executed_by",
    Comments: "comments",
    Attachment: "attachment",
  }

  const selectedFields = selectedFormValues?.selectedFields || {}

  // Create an array of selected field keys based on the mapping and values being true
  const selectedFieldKeys = Object.keys(selectedFields)
    .filter(key => selectedFields[key])
    .map(key => fieldMappings[key])
    .filter(Boolean) // Remove undefined entries

  const SaveTemplate = async templateName => {
    const bodyOptions = {
      template_name: templateName,
      filters_data: [
        { key: "build_id", value: selectedFormValues?.filter_build_id || null },
        {
          key: "module_id",
          value: selectedFormValues?.filter_module_id || null,
        },
        {
          key: "filter_cycle",
          value: selectedFormValues?.filter_cycle || null,
        },
        {
          key: "filter_executed_status",
          value: selectedFormValues?.filter_executed_status || null,
        },
        {
          key: "filter_execution_type",
          value: selectedFormValues?.filter_execution_type || null,
        },
        {
          key: "requirement_id",
          value: selectedFormValues?.filter_requirement_id || null,
        },
        {
          key: "assignto",
          value: selectedFormValues?.filter_tested_by || null,
        },
        { key: "severity", value: selectedFormValues?.severity || null },
        { key: "priority", value: selectedFormValues?.priority || null },
        {
          key: "to_date",
          value: selectedFormValues?.to_date_execution || null,
        },
        {
          key: "from_date",
          value: selectedFormValues?.from_date_execution || null,
        },
      ],
      columns_data: selectedFieldKeys,
      project_id: globalProject?.value,
      report_type: reports?.report_type?.requirement,
    }

    try {
      const res = await dispatch(saveAsTemplateAPI(bodyOptions))
      setIsSaveTemplate(false)
      setSelectedTemplate(null)
      fetchTableData()
      if (res) {
        formik.resetForm()
        setselectedTemplateOption("")
        dispatch(setSelectedReqTraceabilityformValues(null))
      }
      formik.resetForm()
    } catch (error) {}
  }

  const [templates, setTemplates] = useState([])
  const templateListOption = Array.isArray(templates)
    ? templates.map(item => ({
        label: item?.template_name,
        value: item?.id,
      }))
    : []

  useEffect(() => {
    fetchTableData()
  }, [globalProject?.value])

  const handleSavedTemplateChange = async selectedOption => {
    if (!selectedOption) {
      setSelectedTemplate(null)
      formik.resetForm()
      return
    }

    setSelectedTemplate(selectedOption)

    try {
      const response = await dispatch(
        GetSingleReportTemplateAPI(selectedOption.value)
      )
      setselectedTemplateOption(response)
    } catch (error) {
      console.error("Error fetching template data:", error)
    }
  }
  useEffect(() => {
    fetchTableData()
  }, [isSaveTemplate])
  useEffect(() => {
    if (selectedTemplateOption) {
      const updatedValues = { ...formik.initialValues }

      selectedTemplateOption.filters_data.forEach(filter => {
        switch (filter.key) {
          case "build_id":
            updatedValues.filter_build_id = filter.value
            break
          case "module_id":
            updatedValues.filter_module_id = filter.value
            break
          case "filter_cycle":
            updatedValues.filter_cycle = filter.value
            break
          case "filter_executed_status":
            updatedValues.filter_executed_status = filter.value
            break
          case "filter_execution_type":
            updatedValues.filter_execution_type = filter.value
            break
          case "requirement_id":
            updatedValues.filter_requirement_id = filter.value
            break
          case "assignto":
            updatedValues.filter_tested_by = filter.value
            break
          case "to_date":
            updatedValues.filter_test_case_execution_to = filter.value
            break
          case "from_date":
            updatedValues.filter_test_case_execution_from = filter.value
            break
          default:
            break
        }
      })

      const columns = selectedTemplateOption.columns_data?.split(",") || []
      const updatedSelectedFields = { ...formik.initialValues.selectedFields }

      const fieldMapping = {
        RequirementTitle: "requirement_title",
        RequirementId: "requirement_id",
        RequirementDescription: "requirement_description",
        TestCaseId: "test_case_id",
        TestCaseName: "test_case_name",
        TestCaseSummary: "test_case_summary",
        TestScenarioId: "test_scenario_id",
        TestScenarioName: "test_scenario_name",
        TestScenarioDescription: "test_scenario_description",
        Priority: "priority",
        ExecutionType: "execution_type",
        PreCondition: "pre_condition",
        PostCondition: "post_condition",
        BuildName: "build_name",
        ModuleName: "module_name",
        CreatedAt: "created_at",
        Cycle: "cycle",
        ExecutedStatus: "executed_status",
        ExpectedResult: "expected_result",
        AssociatedDefects: "associated_defects",
        TestCaseExecutedBy: "test_case_executed_by",
        Comments: "comments",
        Attachment: "attachment",
      }

      Object.keys(updatedSelectedFields).forEach(key => {
        const columnName = fieldMapping[key]
        updatedSelectedFields[key] = columns.includes(columnName)
      })

      updatedValues.selectedFields = updatedSelectedFields

      formik.setValues(updatedValues)
    }
  }, [selectedTemplateOption])
  const resetFilter = () => {
    dispatch(setSelectedReqTraceabilityformValues(null))
    setIsModalOpen(false)
    setSelectedTemplate(null)

    formik.resetForm()
  }

  const layoutModeType = useSelector(state => state.Layout.layoutModeType)
  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888",
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Ensure input text is visible
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150, // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }

  const permission = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission = permission?.permissions?.[0]?.menu_item?.Reports

  return (
    <React.Fragment>
      {rolePermission?.requirementtraceabilityreports?.create ? (
        <Container fluid>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={7}>
                <Card className="mt-3">
                  <CardBody>
                    <Row>
                      <Col lg={6}>
                        {" "}
                        <div className="mb-3">
                          <Label className="form-label">Build</Label>
                          <Select
                            styles={customStyles}
                            isClearable
                            className="select2-selection"
                            placeholder="All"
                            name="filter_build_id"
                            options={optionValues?.filter_build_id}
                            onChange={option =>
                              formik.setFieldValue(
                                "filter_build_id",
                                option?.value
                              )
                            }
                            onBlur={() =>
                              formik.setFieldTouched("filter_build_id")
                            }
                            value={optionValues?.filter_build_id?.find(
                              option =>
                                option.value === formik.values.filter_build_id
                            )}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        {" "}
                        <div className="mb-3">
                          <Label className="form-label">Module</Label>
                          <Select
                            styles={customStyles}
                            isClearable
                            className="select2-selection"
                            placeholder="All"
                            name="filter_module_id"
                            options={optionValues?.filter_module_id}
                            onChange={option =>
                              formik.setFieldValue(
                                "filter_module_id",
                                option?.value
                              )
                            }
                            onBlur={() =>
                              formik.setFieldTouched("filter_module_id")
                            }
                            value={optionValues?.filter_module_id?.find(
                              option =>
                                option.value === formik.values.filter_module_id
                            )}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        {" "}
                        <div className="mb-3">
                          <Label className="form-label">
                            Requirement Title
                          </Label>
                          <Select
                            styles={customStyles}
                            isClearable
                            className="select2-selection"
                            placeholder="All"
                            name="filter_requirement_id"
                            options={optionValues?.filter_requirement_id}
                            onChange={option =>
                              formik.setFieldValue(
                                "filter_requirement_id",
                                option?.value
                              )
                            }
                            onBlur={() =>
                              formik.setFieldTouched("filter_requirement_id")
                            }
                            value={optionValues?.filter_requirement_id?.find(
                              option =>
                                option.value ===
                                formik.values.filter_requirement_id
                            )}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        {" "}
                        <div className="mb-3">
                          <Label className="form-label">Cycle</Label>
                          <Select
                            styles={customStyles}
                            isClearable
                            className="select2-selection"
                            placeholder="All"
                            name="filter_cycle"
                            options={optionValues?.filter_cycle}
                            onChange={option =>
                              formik.setFieldValue(
                                "filter_cycle",
                                option?.value
                              )
                            }
                            onBlur={() =>
                              formik.setFieldTouched("filter_cycle")
                            }
                            value={optionValues?.filter_cycle?.find(
                              option =>
                                option.value === formik.values.filter_cycle
                            )}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        {" "}
                        <div className="mb-3">
                          <Label className="form-label">Executed Status</Label>
                          <Select
                            styles={customStyles}
                            isClearable
                            className="select2-selection"
                            placeholder="All"
                            name="filter_executed_status"
                            options={optionValues?.filter_executed_status}
                            onChange={option =>
                              formik.setFieldValue(
                                "filter_executed_status",
                                option?.value
                              )
                            }
                            onBlur={() =>
                              formik.setFieldTouched("filter_executed_status")
                            }
                            value={optionValues?.filter_executed_status?.find(
                              option =>
                                option.value ===
                                formik.values.filter_executed_status
                            )}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        {" "}
                        <div className="mb-3">
                          <Label className="form-label">Execution Type</Label>
                          <Select
                            styles={customStyles}
                            isClearable
                            className="select2-selection"
                            placeholder="All"
                            name="filter_execution_type"
                            options={optionValues?.filter_execution_type}
                            onChange={option =>
                              formik.setFieldValue(
                                "filter_execution_type",
                                option?.value
                              )
                            }
                            onBlur={() =>
                              formik.setFieldTouched("filter_execution_type")
                            }
                            value={optionValues?.filter_execution_type?.find(
                              option =>
                                option.value ===
                                formik.values.filter_execution_type
                            )}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        {" "}
                        <div className="mb-3">
                          <Label className="form-label">Executed By</Label>
                          <Select
                            styles={customStyles}
                            isClearable
                            className="select2-selection"
                            placeholder="All"
                            name="filter_tested_by"
                            options={optionValues?.filter_tested_by}
                            onChange={option =>
                              formik.setFieldValue(
                                "filter_tested_by",
                                option?.value
                              )
                            }
                            onBlur={() =>
                              formik.setFieldTouched("filter_tested_by")
                            }
                            value={optionValues?.filter_tested_by?.find(
                              option =>
                                option.value === formik.values.filter_tested_by
                            )}
                          />
                        </div>
                      </Col>
                      <Col lg={6}></Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        {" "}
                        <div className="mb-3">
                          <Label className="form-label">From Date</Label>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block "
                              placeholder="dd/mm/yyyy"
                              options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "Y-m-d",
                              }}
                              value={formik.values.from_date_execution}
                              onChange={date => {
                                const formattedDate = moment(date[0]).format(
                                  "YYYY-MM-DD"
                                )
                                formik.setFieldValue(
                                  "from_date_execution",
                                  formattedDate
                                )
                              }}
                            />
                          </InputGroup>
                          {formik.touched.from_date_execution &&
                            formik.errors.from_date_execution && (
                              <div className="error">
                                {formik.errors.from_date_execution}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col lg={6}>
                        {" "}
                        <div className="mb-3">
                          <Label className="form-label">To Date</Label>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="dd/mm/yyyy"
                              options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "Y-m-d",
                              }}
                              value={formik.values.to_date_execution}
                              onChange={date => {
                                const formattedDate = moment(date[0]).format(
                                  "YYYY-MM-DD"
                                )
                                formik.setFieldValue(
                                  "to_date_execution",
                                  formattedDate
                                )
                              }}
                            />
                          </InputGroup>
                          {formik.touched.to_date_execution &&
                            formik.errors.to_date_execution && (
                              <div className="error">
                                {formik.errors.to_date_execution}
                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={5}>
                <Card className="mt-3">
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <div className="form-check form-checkbox-outline form-check-success">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="selectAllCheckbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="selectAllCheckbox"
                          >
                            <h5 className="TitleText">Extended Search</h5>
                          </label>
                        </div>
                      </Col>

                      {Object.keys(formik.values.selectedFields).map(field => (
                        <Col md={6} key={field}>
                          <div className="form-check form-checkbox-outline form-check-success mb-2">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheck-outlinecolor2"
                              name={field}
                              checked={formik.values.selectedFields[field]}
                              onChange={() =>
                                formik.setFieldValue("selectedFields", {
                                  ...formik.values.selectedFields,
                                  [field]: !formik.values.selectedFields[field],
                                })
                              }
                              disabled={
                                field === "TestCaseName" ||
                                field === "RequirementTitle" ||
                                field === "Cycle"
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheck-outlinecolor2"
                            >
                              {field.charAt(0).toUpperCase() +
                                field.slice(1).replace(/([A-Z])/g, " $1")}
                            </label>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    {/* Buttons Row */}
                    <div className="formRow buttons mb-3 mt-2">
                      <Row
                        className="d-flex justify-content-between"
                        style={{ gap: "2px" }}
                      >
                        <Col className="col-auto p-0">
                          <Button
                            type="submit"
                            id="GenerateReport"
                            color="success"
                            className="btn btn-success waves-effect waves-light"
                            onClick={handleGenerateReport}
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "12px",
                              marginRight: "2px",
                            }} // Small gap
                          >
                            Generate Report
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target="GenerateReport"
                          >
                            You can generate a requirement traceability report
                            by applying various filters according to your
                            requirement.
                          </UncontrolledTooltip>
                        </Col>
                        <Col className="col-auto p-0">
                          <Button
                            type="submit"
                            id="SaveAsTemplate"
                            color="secondary"
                            className="btn btn-secondary waves-effect waves-light"
                            onClick={handleSaveAsTemplate}
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "12px",
                              marginRight: "2px",
                            }} // Small gap
                          >
                            Save As Template
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target="SaveAsTemplate"
                          >
                            You can save applied filters as a template for later
                            use.
                          </UncontrolledTooltip>
                        </Col>

                        <Col className="col-auto p-0">
                          <Link to="/Reports/RequirementTraceability/SavedTemplates">
                            <Button
                              type="button"
                              id="SavedTemplate"
                              style={{ whiteSpace: "nowrap", fontSize: "12px" }}
                              color="secondary"
                              className="btn btn-secondary waves-effect waves-light"
                            >
                              Saved Template
                            </Button>
                          </Link>
                          <UncontrolledTooltip
                            placement="top"
                            target="SavedTemplate"
                          >
                            You can view the list of saved templates here.
                          </UncontrolledTooltip>
                        </Col>
                      </Row>
                    </div>

                    {/* Saved Templates Section */}
                    <div className="mb-3">
                      <h5 className="TitleText">Saved Templates</h5>
                      <Label
                        style={{
                          width: "100%",
                          marginTop: "5px",
                          marginRight: "10px",
                        }}
                      >
                        <Select
                          styles={customStyles}
                          name="savedTemplate"
                          placeholder="Select Template"
                          isClearable
                          className="select2-selection"
                          value={selectedTemplate} // Ensure this is updated correctly based on state
                          options={templateListOption}
                          onChange={handleSavedTemplateChange} // Pass the handler directly
                        />
                      </Label>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
          {isModalOpen && (
            <GenerateReport
              isModalOpen={isModalOpen}
              toggle={handleGenerateReport}
              resetFilter={resetFilter}
            />
          )}
          <SaveAsTemplate
            show={isSaveTemplate}
            handleClose={handleSaveAsTemplate}
            handleSave={SaveTemplate}
          />
        </Container>
      ) : (
        <NoPermission />
      )}
    </React.Fragment>
  )
}

export default RequirementTraceabilityReport
