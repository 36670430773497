import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { Routes, Route } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import mainLoader from "./assets/icons/loader/ezgif-7-9c8c6836e6.gif"
// Import scss
import "./assets/scss/theme.scss"

//toaster
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

import fakeBackend from "./helpers/AuthType/fakeBackend"
// import {
//   getSessionExpiryTime,
//   getTimeRemaining,
//   handleSessionExpiration,
//   scheduleSessionWarning,
// } from "helpers/session/sessionHelpers"
// import SessionWarningModal from "helpers/session/SessionWarningModal"
import SessionTimeoutHandler from "helpers/session/SessionTimeoutHandler"
// import { Spinner } from "reactstrap"
// import Loading from "components/VerticalLayout/Loading"
// import { useDispatch } from "react-redux"

// Activating fake backend
fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

const getLayout = layoutType => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout
      break
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout
      break
    default:
      break
  }
  return Layout
}

const App = () => {
  // const dispatch = useDispatch()
  const selectLayoutState = state => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, layout => ({
    layoutType: layout.layoutType,
  }))

  const { layoutType } = useSelector(LayoutProperties)

  const Layout = getLayout(layoutType)
  // const loading = useSelector(state => state?.Login?.loading)

  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const delay = setTimeout(() => {
      setIsLoading(false)
    }, 3600)
    return () => clearTimeout(delay)
  }, [])

  document.title = "Testops"
  // const loading = useSelector(state => state.Loading.isLoading)

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh",background:'#ffffffc2'}}
        >
          <img src={mainLoader} alt="Loading . . ." style={{height:'12rem'}}/>
        </div>
      ) : (
        <>
          <ToastContainer />
          <SessionTimeoutHandler>
            {/* {loading && <Loading />} */}
            <Routes>
              {publicRoutes.map((route, idx) => (
                <Route
                  path={route.path}
                  element={<NonAuthLayout>{route.element}</NonAuthLayout>}
                  key={idx}
                />
              ))}

              {authProtectedRoutes.map((route, idx) => (
                <Route
                  path={route.path}
                  element={
                    <Authmiddleware>
                      <Layout>{route.element}</Layout>
                    </Authmiddleware>
                  }
                  key={idx}
                >
                  {route.children &&
                    route.children.map((child, idx) => (
                      <Route
                        key={idx}
                        path={child.path}
                        element={child.element}
                      />
                    ))}
                </Route>
              ))}
            </Routes>
          </SessionTimeoutHandler>
        </>
      )}
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
