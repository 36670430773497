import React, { useEffect, useState, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import DeleteModal from "components/Common/DeleteModal"
import { useSelector, useDispatch } from "react-redux"
import { toast, ToastContainer } from "react-toastify"
import { setEditIntegration } from "store/Slices/IntegrationSlice"
import TableContainer from "../Roles/TableContainer"
import {
  deleteIntegrationAPI,
  getAllIntegrationListAPI,
  IntegrationRetrieveApi,
} from "store/actions/IntegrationAction"
import NoPermission from "components/Common/NoPermission"

const Integration = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [data, setData] = useState([])

  const allTestScenario = useSelector(
    state => state?.TestScenario?.allTestScenario
  )

  const Permission = JSON.parse(localStorage.getItem("authUser"));
  const integrationPermission = Permission?.permissions[0]?.menu_item?.Settings?.integration

  useEffect(() => {
    if (allTestScenario && allTestScenario.Data) {
      setData(allTestScenario)
    }
  }, [allTestScenario])

  const handleSingleEditClick = async id => {
    if(integrationPermission?.edit){
    try {
      const res = await dispatch(IntegrationRetrieveApi(id))
      dispatch(setEditIntegration({ isEdit: true, data: res }))
      navigate("/Settings/EditIntegration")
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }else{
    toast.warn("You don't have permission to Edit Integration", {
      position: "top-right",
      autoClose: 3000,
    });
  }
  }

  const handleCreateClick = () => {
  if(integrationPermission?.create){
    navigate("/Settings/CreateIntegration")
    }else{
      toast.warn("You don't have permission to Create Integration", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  }

  const [deleteModal, setDeleteModal] = useState(false)
  const [isDeleteId, setIsDeleteId] = useState("")

  const onClickDelete = userData => {
    if(integrationPermission?.delete){
    setIsDeleteId(userData?.id)
    setDeleteModal(true)
    }else{
      toast.warn("You don't have permission to Delete Integration", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  }

  const columns = useMemo(
    () => [
      {
        header: "External Tool",
        accessorKey: "product",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const product = cell.getValue()
          return (
            <>
              <Link className="text-dark">{product ? product : "-"}</Link>
            </>
          )
        },
      },
      {
        header: "Username",
        accessorKey: "email",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const row = cell.row.original // Access the entire row data
          const email = cell.getValue() // Email value from accessorKey
          const username = row?.username // Assuming username is another property in your row data
          const product = row?.product // Assuming product is another property in your row data

          return (
            <>
              <Link className="text-dark">
                {product === "BrowserStack" ? username : email ? email : "-"}
              </Link>
            </>
          )
        },
      },
      {
        header: "URL",
        accessorKey: "url",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const url = cell.getValue()
          return (
            <>
              <Link className="text-dark">{url ? url : "-"}</Link>
            </>
          )
        },
      },
      {
        header: "Action",
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li>
                <span
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    const data = cellProps.row.original
                    handleSingleEditClick(data?.id)
                  }}
                  id={`edittooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-pencil-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edittooltip-${cellProps.row.original.id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </span>
              </li>

              <li>
                <span
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const userData = cellProps.row.original
                    onClickDelete(userData)
                  }}
                  id={`deletetooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`deletetooltip-${cellProps.row.original.id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </span>
              </li>
            </ul>
          )
        },
      },
    ],
    [data]
  )

  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [globalFilter, setGlobalFilter] = useState("")
  const [totalCount, setTotalCount] = useState(null)

  const fetchTableData = async (page, size, search) => {
    let bodyoption = {
      page: page + 1,
      page_size: size,
      search: search,
    }
    try {
      const data = await dispatch(getAllIntegrationListAPI(bodyoption))
      setData(data?.Data)
      setTotalCount(data?.TotalCount)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  useEffect(() => {
    fetchTableData(pageIndex, pageSize, globalFilter)
  }, [pageIndex, pageSize, globalFilter])

  const handleDelete = async () => {
    try {
      await dispatch(deleteIntegrationAPI(isDeleteId))
      setDeleteModal(false)
      await fetchTableData(pageIndex, pageSize, globalFilter)
      setIsDeleteId("")
    } catch (error) {
      setDeleteModal(false)
      toast.error(error?.message, {
        position: "top-right",
        autoClose: 3000,
      });
      console.error("Error deleting Integration", error)
    }
  }


  return (
    <React.Fragment>
      {integrationPermission?.view ? <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
        message="Integration"
      />
      <Container fluid>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <div className="table-container">
                  <TableContainer
                    columns={columns}
                    data={data}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    totalCount={totalCount}
                    setPageIndex={setPageIndex}
                    setPageSize={setPageSize}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    isGlobalFilter={true}
                    isPagination={true}
                    SearchPlaceholder="Search..."
                    isCustomPageSize={true}
                    isAddButton={true}
                    isAddDelete={false}
                    isAddEdit={false}
                    isAddFilter={false}
                    handleCreateClick={handleCreateClick}
                    buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal ms-2 mb-2"
                    buttonName="Create"
                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                    theadClass="table-light"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    pagination="pagination"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

        <ToastContainer /></> : <NoPermission />}
    </React.Fragment>
  )
}

export default withRouter(Integration)
