import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Container,
  Form,
  Input,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import "flatpickr/dist/themes/material_blue.css"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import Breadcrumb from "components/Common/Breadcrumb"
import { getTagsStautusAPI } from "store/actions/TagsAction"
import { createOptions } from "helpers/helper"
import {
  createDbIntegrationAPI,
  EditDbIntegrationAPI,
} from "store/actions/DBIntegrationActions"
import { setEditDbIntegration } from "store/Slices/DbIntegrationSlice"

const CreateDbIntegration = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const editDbIntegration = useSelector(
    state => state?.DbIntegration?.editDbIntegration
  )

  const isEditMode = editDbIntegration?.isEdit
  const isEditModeData = editDbIntegration?.data
  const loading = useSelector(state => state?.Loading?.isLoading)
  const breadcrumbItems = [
    { label: "Test Automation", path: "/TestAutomation/DbIntegration" },
    {
      label: isEditMode ? "Edit Db Integration" : "Create Db Integration",
      path: "/",
    },
  ]

  const validationSchema = Yup.object().shape({
    database_type: Yup.string().required("DataBase Type is required"),
    db_connection_name: Yup.string().required("DataBase Name is required"),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      project_id: globalProject?.value || "",
      db_connection_name: isEditModeData
        ? isEditModeData?.db_connection_name
        : null,
      database_type: isEditModeData ? isEditModeData?.database_type : null,
      host: isEditModeData ? isEditModeData?.host : null,
      username: isEditModeData ? isEditModeData?.username : null,
      password: isEditModeData ? isEditModeData?.password : null,
      schema_name: isEditModeData ? isEditModeData?.schema_name : null,
      port_number: isEditModeData ? isEditModeData?.port_number : null,
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      create(values)
      resetForm()
    },
  })

  const create = async formData => {
    const editFromValues = {
      ...formData,
      id: isEditModeData?.id,
    }
    if (!isEditModeData) {
      try {
        const res = await dispatch(createDbIntegrationAPI(formData))
        navigate(-1) // Navigate back or handle success
      } catch (error) {
        toast.error(error || "An error occurred", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
      }
    } else {
      try {
        await dispatch(EditDbIntegrationAPI(editFromValues))
        navigate(-1) // Navigate back or handle success
        dispatch(setEditDbIntegration({ isEdit: false, data: null }))
      } catch (error) {
        toast.error(error || "An error occurred", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
      }
    }
  }

  const [databasetype, setDatabaseType] = useState([])
  const [databasetypeOptions, setDatabaseTypeOptions] = useState([])

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const { data } = await dispatch(
          getTagsStautusAPI({
            project_id: globalProject?.value,
          })
        )
        setDatabaseType(data?.Database_Type)
      } catch (error) {
        console.error(error)
      }
    }

    fetchOptions()
  }, [globalProject])

  useEffect(() => {
    setDatabaseTypeOptions(createOptions(databasetype))
  }, [databasetype])

  return (
    <React.Fragment>
      <Container fluid>
        <Breadcrumb title="Test Plan" breadcrumbItems={breadcrumbItems} />

        <div className="mt-2 mx-3 d-flex justify-content-between">
          <div>
            <h3>
              {!isEditMode
                ? "Create New Db Integration"
                : "Edit Db Integration"}
            </h3>
            <p className="text-muted">
              Fill the form to {!isEditMode ? "create" : "edit"} Db Integration
            </p>
          </div>
          <div>
            <Button
              type="button"
              color="primary"
              onClick={() => {
                navigate(-1)
                dispatch(setEditDbIntegration({ isEdit: false, data: null }))
              }}
            >
              Go Back
            </Button>
          </div>
        </div>

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label">
                      DataBase Name<span className="text-danger">*</span>
                    </Label>
                    <Input
                      name="db_connection_name"
                      type="text"
                      placeholder="Enter DataBase Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.db_connection_name}
                      invalid={
                        formik.touched.db_connection_name &&
                        !!formik.errors.db_connection_name
                      }
                      className="custom-input"
                    />

                    {formik.touched.db_connection_name &&
                      formik.errors.db_connection_name ? (
                      <div className="text-danger">
                        {formik.errors.db_connection_name}
                      </div>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">
                      DataBase Type<span className="text-danger">*</span>
                    </Label>
                    <Select
                      name="database_type"
                      options={databasetypeOptions}
                      onChange={option =>
                        formik.setFieldValue("database_type", option?.value)
                      }
                      onBlur={() => formik.setFieldTouched("database_type")}
                      value={databasetypeOptions?.find(
                        option => option.value === formik.values.database_type
                      )}
                    />
                    {formik.touched.database_type &&
                      formik.errors.database_type ? (
                      <div className="text-danger">
                        {formik.errors.database_type}
                      </div>
                    ) : null}
                  </div>
                  <Label className="form-label">Host</Label>
                  <Input
                    name="host"
                    type="text"
                    placeholder="Enter Host Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.host}
                    invalid={formik.touched.host && !!formik.errors.host}
                    className="custom-input"
                  />
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    {" "}
                    <Label className="form-label">Username</Label>
                    <Input
                      name="username"
                      type="text"
                      placeholder="Enter Username Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.username}
                      invalid={
                        formik.touched.username && !!formik.errors.username
                      }
                      className="custom-input"
                    />
                  </div>
                  <div className="mb-3">
                    {" "}
                    <Label className="form-label">Password</Label>
                    <Input
                      name="password"
                      type="password"
                      placeholder="Enter Password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      invalid={
                        formik.touched.password && !!formik.errors.password
                      }
                      className="custom-input"
                    />
                  </div>
                  <div className="mb-3">
                    {" "}
                    <Label className="form-label">Schema Name</Label>
                    <Input
                      name="schema_name"
                      type="text"
                      placeholder="Enter Schema Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.schema_name}
                      invalid={
                        formik.touched.schema_name &&
                        !!formik.errors.schema_name
                      }
                      className="custom-input"
                    />
                  </div>
                  <div className="mb-3">
                    {" "}
                    <Label className="form-label">Port Number</Label>
                    <Input
                      name="port_number"
                      type="text"
                      placeholder="Enter Port Number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.port_number}
                      invalid={
                        formik.touched.port_number &&
                        !!formik.errors.port_number
                      }
                      className="custom-input"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={12}>
              <Row className="mb-3">
                <Col md="12" className="text-end mt-3">
                  <Button color="primary" type="submit" disabled={loading}>
                    {loading ? "Loading..." : "Submit"}
                  </Button>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => {
                      formik.resetForm()
                      navigate(-1)
                      dispatch(
                        setEditDbIntegration({ isEdit: false, data: null })
                      )
                    }}
                    className="ms-2"
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </React.Fragment>
  )
}

export default CreateDbIntegration
