import React, { useState, useEffect } from "react";
import { Button, Label, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import { useSelector,useDispatch } from "react-redux";
import './SyncDefect.css'; // Import the CSS file with spinner styles
import {  getAllDefectsListAPI, SyncDefectJira, SyncDefectPlane } from "store/actions/DefectsActions";
import { setReLoading } from "store/Slices/LoadingSlice";

const SyncDefect = ({ isOpen, toggle,onClose }) => {

    const dispatch = useDispatch();
    const mapProjectList = useSelector(state => state?.Integration?.mapList);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [plugin, setPlugin] = useState(null);
    const [error, setError] = useState(false);
    const globalProject = useSelector(state => state?.globalProjectSelect?.selectetProject);

    const customStyles = {
        menu: provided => ({
            ...provided,
            backgroundColor: "white",
        }),
        menuList: provided => ({
            ...provided,
            backgroundColor: "white",
            maxHeight: 150, // Set max height for dropdown
            overflowY: "auto", // Enable vertical scroll
            zIndex: 1000,
        }),
    };

    useEffect(() => {
        const syncOptions = mapProjectList?.filter(
            (project) =>
                project.project_id === globalProject?.value &&
                (project.product === "Jira" || project.product === "Plane")
        );

        if (syncOptions) {
            const productValue = syncOptions.map((entry) => ({
                value: entry.product,
                label: entry.product,
            }));
            setOptions(productValue);
        }
    }, [mapProjectList, globalProject?.value]);

    const handleCloseSync = () =>{
        onClose()
        setLoading(false);
        setError(false);
    }

    const handleSync = async() => {
        if (!plugin) {
            setError(true);
        } else {
            setError(false);
            setLoading(true);
            let bodyOptions = {
                project_id: globalProject?.value
            };
            let Payload = {
                page: 1,
                page_size: 10,
                project_id: globalProject?.value
            }
            try {
                dispatch(setReLoading(true))
                if(plugin.value === "Plane"){
                await dispatch(SyncDefectPlane(bodyOptions)) }
                else if(plugin.value === "Jira"){
                await dispatch(SyncDefectJira(bodyOptions)) }        

                  setLoading(false);
                  await dispatch(getAllDefectsListAPI(Payload))
                  handleCloseSync()
                  window.location.reload();
                setLoading(false);
            } catch (error) {
                setLoading(false);
                toast.warn(error?.message, {
                    position: "top-right",
                    autoClose: 3000,
                });
            }
            dispatch(setReLoading(false))
        }
    };
    const handleClose = () =>{
        setLoading(false);
        toggle()
        setPlugin(null)
        setError(false);
    }
    return (
        <Offcanvas isOpen={isOpen} direction="end" toggle={handleClose}>
            <OffcanvasHeader toggle={handleClose}>Sync Integration Tool</OffcanvasHeader>
            <OffcanvasBody className="px-4 text-left d-flex flex-column justify-content-between">
                <div>
                <Label className="form-label">Plugin Name<span className="text-danger">*</span></Label>
                <Select
                    styles={customStyles}
                    maxMenuHeight={200}
                    name="severity"
                    options={options}
                    value={plugin}
                    onChange={(selectedOption) => setPlugin(selectedOption)}
                />
                {error && (
                    <span className="text-danger">
                        Plugin Name required
                    </span>
                )}
                </div>
                <div className="d-flex justify-content-between">
                <Button color="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button color="primary" onClick={handleSync} disabled={loading}>
                    {loading ? (
                        <div className="d-flex align-items-center">
                            <div className="spinner-border-custom"></div>
                            <span className="ms-2">Syncing...</span>
                        </div>
                    ) : (
                        "Sync"
                    )}
                </Button>
            </div>
            </OffcanvasBody>
        </Offcanvas>
    );
};

export default SyncDefect;
