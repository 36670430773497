import NoData from "components/Common/NoData"
import Breadcrumb from "./Header/Breadcrumb"
import React, { useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { Container } from "reactstrap"

const TestCycle = () => {
  const breadcrumbItems = [
    { label: "Test Lab", path: "/TestCycle" },
    { label: "Test Cycle", path: "/" },
  ]
  const location = useLocation()
  const locationPath = location.pathname
  const pathname = locationPath.split("/").filter(Boolean).pop()
  const pathArray = ["CreateTestScenario", "CreateEditTestCycle"]
  const globalProjectValue = JSON.parse(localStorage.getItem("selectedProject"))

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {globalProjectValue ? (
            <>
              {!pathArray.includes(pathname) && (
                <Breadcrumb
                  title="Test Lab"
                  breadcrumbItems={breadcrumbItems}
                  isImport={true}
                  isDownload={true}
                  isExport={true}
                  isInfo={true}
                  isProject={true}
                />
              )}
              <Outlet />
            </>
          ) : (
            <>
              <NoData />
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TestCycle
