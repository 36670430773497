import React, { useEffect, useState } from "react"
import {
  FormFeedback,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap"
import { useSelector } from "react-redux"
import { joditConfig } from "helpers/helper"
import JoditEditor from "jodit-react"

const BulkApprovalTestPlan = ({
  isOpen,
  selectedRows,
  handleClose,
  handleBulkEdit,
  loading,
}) => {
  //   const dispatch = useDispatch()

  useEffect(() => {
    setDescription("")
  }, [])

  const bulkApprovalState = useSelector(
    state => state?.Request?.bulkApprovalReject
  )
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  // const handleApplyBulkEdit = () => {
  //   let data = {
  //     data: ''
  //   }
  //   handleBulkEdit(data)
  // }

  const [steps_to_reproduce, setDescription] = useState("")

  const [isDescriptionInvalid, setIsDescriptionInvalid] = useState(false)

  const stripHtmlTags = html => {
    const div = document?.createElement("div")
    div.innerHTML = html
    return div.textContent || div.innerText || ""
  }

  const submitData = () => {
    const cleanDescription = stripHtmlTags(steps_to_reproduce).trim()
    if (!cleanDescription) {
      setIsDescriptionInvalid(true)
    } else {
      setIsDescriptionInvalid(false)
      let data = {
        id: selectedRows,
        testplan_id: selectedRows,
        approver_comment: steps_to_reproduce,
        status: bulkApprovalState?.approve ? "Approved" : "Rejected",
        models_name: "test_plan",
        project_id: globalProject?.value || selectedProject?.value,
      }
      handleBulkEdit(data)
      setDescription("")
    }
  }

  return (
    <Offcanvas
      isOpen={isOpen}
      direction="end"
      toggle={() => {
        setDescription("")
        handleClose()
      }}
      style={{ width: "45%" }}
    >
      <OffcanvasHeader
        toggle={() => {
          setDescription("")
          handleClose()
        }}
      >
        {bulkApprovalState?.approve ? "Approve Test Plan" : "Reject Test Plan"}
      </OffcanvasHeader>
      <OffcanvasBody>
        <Label>
          Comments
          <span className="text-danger">*</span>
        </Label>
        <JoditEditor
          config={joditConfig()}
          value={steps_to_reproduce}
          onBlur={newContent => setDescription(newContent)}
          // onBlur={() => setIsDescriptionInvalid(!steps_to_reproduce)}
        />
        {isDescriptionInvalid && (
          <FormFeedback className="d-block">Comments is required</FormFeedback>
        )}
        <div className="d-flex justify-content-between mt-5">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              setDescription("")
              handleClose()
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={submitData}
          >
            {loading ? "Loading..." : "Submit"}
          </button>
        </div>
      </OffcanvasBody>
    </Offcanvas>
  )
}

export default BulkApprovalTestPlan
