import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
import { PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { msalConfig } from "./helpers/ADConfig/msalConfig"
import "./index.scss"
import store from "./store"

const instance = new PublicClientApplication(msalConfig)
const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <MsalProvider instance={instance}>
    <Provider store={store}>
      <React.Fragment>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.Fragment>
    </Provider>
  </MsalProvider>
)

serviceWorker.unregister()
