import PropTypes from "prop-types";
import React from "react";
import {
  Container
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

 

const QuickAction = props => { 
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={"Quick Action"}
            breadcrumbItem={"Quick Action"}
          />
        </Container> 
      </div>
    </React.Fragment>
  );
};

 

export default QuickAction;
