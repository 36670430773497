import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector } from "react-redux";

const DefectClosureEfficiency = ({ data }) => {
  const layoutModeType = useSelector((state) => state.Layout.layoutModeType);

  const chartData = data.map((item) => ({
    x: new Date(item.day).getTime(),
    y: item.total_defects,
    closed: item.closed_defects,
  }));

  const isDarkMode = layoutModeType === "dark";

  const options = {
    title: {
      text: " ",
      align: "left",
      style: {
        color: isDarkMode ? "#ffffff" : "#000000",
      },
    },
    xAxis: {
      type: "datetime",
      title: {
        text: "Day",
        style: {
          color: isDarkMode ? "#ffffff" : "#000000",
        },
      },
      labels: {
        style: {
          color: isDarkMode ? "#ffffff" : "#000000",
        },
      },
      lineColor: isDarkMode ? "#ffffff" : "#000000",
      tickColor: isDarkMode ? "#ffffff" : "#000000",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      shared: true, // Show both values in a single tooltip
      valueSuffix: " ",
      backgroundColor: isDarkMode ? "#000000" : "#ffffff",
      style: {
        color: isDarkMode ? "#f0f0f0" : "#000000",
      },
    },
    yAxis: {
      title: {
        text: "No Of Defects",
        style: {
          color: isDarkMode ? "#ffffff" : "#000000",
        },
      },
      labels: {
        style: {
          color: isDarkMode ? "#ffffff" : "#000000",
        },
      },
      lineColor: isDarkMode ? "#ffffff" : "#000000",
      tickColor: isDarkMode ? "#ffffff" : "#000000",
    },
    series: [
      {
        name: "Total Defects",
        type: "area",
        data: chartData.map((item) => [item.x, item.y]),
        color: isDarkMode ? "rgba(255, 99, 132, 0.5)" : "rgba(255, 99, 132, 0.5)",
        fillOpacity: 1,
      },
      {
        name: "Closed Defects",
        type: "area",
        data: chartData.map((item) => [item.x, item.closed]),
        color: "rgba(32, 128, 67, 0.5)", // Use a different color for closed defects
        fillOpacity: 0.5,
      },
    ],
    chart: {
      backgroundColor: isDarkMode ? "#2a3042" : "white",
    },
    legend: {
      enabled: true, // Enable the legend to differentiate between Total and Closed
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default DefectClosureEfficiency;
