import { createSlice } from "@reduxjs/toolkit"

const DefectsSlice = createSlice({
  name: "Defects",
  initialState: {
    defectsFilterFrom: {
      filter_defect_id: null,
      filter_build: null,
      filter_module: null,
      filter_requirement: null,
      filter_browsers: [], // Changed from null to []
      filter_os: [], // Changed from null to []
      filter_devices: [], // Changed from null to []
      filter_severity: [], // Changed from null to []
      filter_defect_status: [], // Changed from null to []
      filter_assign_to: [], // Changed from null to []
      filter_defect_type: [], // Changed from null to []
      filter_defect_viewers: [], // Changed from null to []
      filter_created_by: [], // Changed from null to []
      filter_from_date: null,
      filter_to_date: null,
    },
    singleDefectData: {},
    EditDefect: {
      isEdit: false,
      data: null,
    },
    syncPopup: {
      popupOpen: false,
      data: null,
    },
  },
  nextDefectId: null,
  reducers: {
    setDefectsFilterFrom: (state, action) => {
      state.defectsFilterFrom = {
        ...state.defectsFilterFrom,
        ...action.payload,
      }
    },
    setEditDefect: (state, action) => ({
      ...state,
      editDefect: action.payload,
    }),
    setSyncPopup: (state, action) => ({
      ...state,
      syncPopup: action.payload,
    }),
    setNextDefectId: (state, action) => ({
      ...state,
      nextDefectId: action.payload,
    }),
    setSingleDefectData: (state, action) => ({
      ...state,
      singleDefectData: action.payload,
    }),
    clearFilters: state => {
      state.defectsFilterFrom = {
        filter_defect_id: null,
        filter_build: null,
        filter_module: null,
        filter_requirement: null,
        filter_browsers: [], // Changed from null to []
        filter_os: [], // Changed from null to []
        filter_devices: [], // Changed from null to []
        filter_severity: [], // Changed from null to []
        filter_defect_status: [], // Changed from null to []
        filter_assign_to: [], // Changed from null to []
        filter_defect_type: [], // Changed from null to []
        filter_defect_viewers: [], // Changed from null to []
        filter_created_by: [], // Changed from null to []
        filter_from_date: null,
        filter_to_date: null,
      }
    },
  },
})

export const {
  setDefectsFilterFrom,
  clearFilters,
  setEditDefect,
  setSingleDefectData,setNextDefectId,setSyncPopup
} = DefectsSlice.actions

export default DefectsSlice.reducer
