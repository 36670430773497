import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment/moment";
import { useSelector } from "react-redux";

const ReopenedChart = ({ data }) => {
  const layoutModeType = useSelector((state) => state.Layout.layoutModeType); // Get layout mode type from Redux

  const categories = data.map(
    (item) =>
      `${moment(item.start_date).format("DD-MM-YYYY")} - ${moment(item.end_date).format("DD-MM-YYYY")}`
  );
  const percentages = data?.map((item) => item.Reopened_percentage);

  const isDarkMode = layoutModeType === "dark"; // Check if dark mode is enabled

  const options = {
    chart: {
      type: "line",
      backgroundColor: isDarkMode ? "#2a3042" : "white", // Set background color based on mode
    },
    title: {
      text: " ",
      align: "left",
      style: {
        color: isDarkMode ? "#ffffff" : "#000000", // Set title color based on mode
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false, // Disable the legend to hide "Reopened Percentage" label below the chart
    },
    xAxis: {
      categories: categories,
      title: {
        text: "Dates",
        style: {
          color: isDarkMode ? "#ffffff" : "#000000", // X-axis title color
        },
      },
      labels: {
        style: {
          color: isDarkMode ? "#ffffff" : "#000000", // X-axis labels color
        },
      },
      lineColor: isDarkMode ? "#ffffff" : "#000000", // X-axis line color
      tickColor: isDarkMode ? "#ffffff" : "#000000", // Tick marks color
    },
    yAxis: {
      title: {
        text: "Reopened Percentage %",
        style: {
          color: isDarkMode ? "#ffffff" : "#000000", // Y-axis title color
        },
      },
      labels: {
        style: {
          color: isDarkMode ? "#ffffff" : "#000000", // Y-axis labels color
        },
      },
      lineColor: isDarkMode ? "#ffffff" : "#000000", // Y-axis line color
      tickColor: isDarkMode ? "#ffffff" : "#000000", // Tick marks color
      allowDecimals: false, // Disable decimal values on yAxis
    },
    tooltip: {
      formatter: function () {
        return `<b>${this.series.name} : ${Math.round(this.y)}%</b><br/>${this.x}`;
      },
      backgroundColor: isDarkMode ? "#2a3042" : "white", // Tooltip background color
      style: {
        color: isDarkMode ? "#ffffff" : "#000000", // Tooltip text color
      },
    },
    series: [
      {
        name: "Reopened Percentage",
        data: percentages.map((p) => Math.round(p)), // Round data to nearest integer
        color: "orange",  
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default ReopenedChart;
