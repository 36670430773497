import React, { useEffect, useState, useMemo } from "react"
import withRouter from "components/Common/withRouter"
import { Offcanvas, OffcanvasBody } from "reactstrap"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { ToastContainer } from "react-toastify"
import {
  downloadRequirementTraceabilityReportsAPI,
  getRequirementTraceabilityReportsDataAPI,
} from "store/actions/ReportsAction"
import GeneratedContainer from "../common/GeneratedTable"
import BASE_URL from "services/apiConfig"

const GenerateReport = props => {
  const { isModalOpen, toggle, resetFilter } = props

  const dispatch = useDispatch()
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))
  const selectedFormValues = useSelector(
    state => state?.Reports?.selectedReqTraceabilityFormValues
  )

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  // Mapping of formState keys to payload keys
  const fieldMappings = {
    RequirementTitle: "requirement_title",
    RequirementId: "requirement_id",
    RequirementDescription: "requirement_description",
    TestCaseId: "test_case_id",
    TestCaseName: "test_case_name",
    TestCaseSummary: "test_case_summary",
    TestScenarioId: "test_scenario_id",
    TestScenarioName: "test_scenario_name",
    TestScenarioDescription: "test_scenario_description",
    Priority: "priority",
    ExecutionType: "execution_type",
    PreCondition: "pre_condition",
    PostCondition: "post_condition",
    BuildName: "build_name",
    ModuleName: "module_name",
    CreatedAt: "created_at",
    Cycle: "cycle",
    ExecutedStatus: "executed_status",
    ExpectedResult: "expected_result",
    AssociatedDefects: "associated_defects",
    TestCaseExecutedBy: "test_case_executed_by",
    Comments: "comments",
    Attachment: "attachment",
  }

  const selectedFields = selectedFormValues?.selectedFields || {}

  // Create an array of selected field keys based on the mapping and values being true
  const selectedFieldKeys = Object.keys(selectedFields)
    .filter(key => selectedFields[key])
    .map(key => fieldMappings[key])
    .filter(Boolean) // Remove undefined entries

  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [globalFilter, setGlobalFilter] = useState("")
  const [totalCount, setTotalCount] = useState(null)

  const fetchTableData = async (page, size, search) => {
    let bodyoption = {
      project_id: globalProject?.value || selectedProject?.value,
      page: page + 1,
      page_size: size,
      search: search,
      filter_build_id: selectedFormValues?.filter_build_id,
      filter_module_id: selectedFormValues?.filter_module_id,
      filter_requirement_id: selectedFormValues?.filter_requirement_id,
      filter_tested_by: selectedFormValues?.filter_tested_by,
      filter_cycle: selectedFormValues?.filter_cycle,
      filter_executed_status: selectedFormValues?.filter_executed_status,
      filter_execution_type: selectedFormValues?.filter_execution_type,
      from_date_execution: selectedFormValues?.from_date_execution,
      to_date_execution: selectedFormValues?.to_date_execution,
      selected_fields: selectedFieldKeys,
    }

    try {
      const response = await dispatch(
        getRequirementTraceabilityReportsDataAPI(bodyoption)
      )
      setData(response?.result?.data)
      setTotalCount(response?.result?.total_items)

      const dynamicColumns = Object.keys(response.result?.data[0] || {}).map(
        key => ({
          header: key.replace(/_/g, " ").toUpperCase(), // Generate readable header
          accessorKey: key,
          enableColumnFilter: false, // Set to true if filtering is needed
          enableSorting: true, // Sorting enabled
          cell: cell => {
            let value = cell.getValue()

            // Check for specific empty HTML patterns and replace with "-"
            const emptyHTMLPattern = /^<p><br><\/p>$/i // Case-insensitive match

            if (typeof value === "string" && emptyHTMLPattern.test(value)) {
              value = "-" // Replace empty HTML with "-"
            }

            // Special case for "attachment" key
            if (key === "attachment") {
              if (value) {
                const mediaCount = (value.match(/\/media/g) || []).length
                const filePath =
                  mediaCount > 1 ? value.replace("/media", "") : value
                const fileUrl = `${BASE_URL}${filePath}`

                return (
                  <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                    View Attachment
                  </a>
                )
              }
              return "-" // No attachment case
            }

            // Default case for other keys
            return (
              <p
                className="font-size-14 mb-1"
                style={{
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  whiteSpace: "normal",
                  minWidth: "150px",
                  maxWidth: "350px", // Controls text wrapping
                }}
                dangerouslySetInnerHTML={{
                  __html: value || "-", // Display default value if empty
                }}
              ></p>
            )
          },
        })
      )
      setColumns(dynamicColumns)

      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  useEffect(() => {
    fetchTableData(pageIndex, pageSize, globalFilter)
  }, [
    pageIndex,
    pageSize,
    globalFilter,
    globalProject?.value,
    selectedFormValues,
  ])

  const handleBack = () => {
    setGlobalFilter("")
    toggle()
  }
  const downloadReport = async filetype => {
    let bodyoption = {
      project_id: globalProject?.value || selectedProject?.value,
      filter_build_id: selectedFormValues?.filter_build_id,
      filter_module_id: selectedFormValues?.filter_module_id,
      filter_requirement_id: selectedFormValues?.filter_requirement_id,
      filter_tested_by: selectedFormValues?.filter_tested_by,
      filter_cycle: selectedFormValues?.filter_cycle,
      filter_executed_status: selectedFormValues?.filter_executed_status,
      filter_execution_type: selectedFormValues?.filter_execution_type,
      from_date_execution: selectedFormValues?.from_date_execution,
      to_date_execution: selectedFormValues?.to_date_execution,
      selected_fields: selectedFieldKeys,
      File_type: filetype,
    }

    try {
      dispatch(downloadRequirementTraceabilityReportsAPI(bodyoption))
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <React.Fragment>
      <Offcanvas
        isOpen={isModalOpen}
        direction="end"
        toggle={() => {
          setGlobalFilter("")
          toggle()
        }}
        style={{ width: "80%" }}
      >
        {/* <OffcanvasHeader toggle={toggle}>Filter Defects</OffcanvasHeader> */}
        <OffcanvasBody>
          <Container fluid>
            <Row>
              <Col lg="12">
                <div className="table-container">
                  <GeneratedContainer
                    columns={columns}
                    data={data}
                    downloadReport={downloadReport}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    totalCount={totalCount}
                    setPageIndex={setPageIndex}
                    setPageSize={setPageSize}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    isGlobalFilter={true}
                    isPagination={true}
                    SearchPlaceholder="Search..."
                    isCustomPageSize={true}
                    isAddDelete={true}
                    handleBack={handleBack}
                    resetFilter={resetFilter}
                    buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal ms-2 mb-2"
                    buttonName="Create"
                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                    theadClass="table-light"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    pagination="pagination"
                  />
                </div>
              </Col>
            </Row>
          </Container>
          <ToastContainer />
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  )
}

export default withRouter(GenerateReport)
