import React from "react";
import "./Loader.css";
import { useSelector } from "react-redux";
import { RotatingLines } from "react-loader-spinner";

const Loader = () => {

  const loading = useSelector((state) => state.Loading.isReLoading); 
    

  return (
    <div className={`loading-wrapper ${loading ? "visibal" : "notvisibal"}`}>
      <div className="loader">
        <RotatingLines
          animationDuration="1.50"
          strokeColor="rgb(22, 119, 255)"
          strokeWidth="2"
          visible
          width="90"
        />
      </div>
    </div>
  );
};
export default Loader;
