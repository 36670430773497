import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
  Form,
  Alert,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import "flatpickr/dist/themes/material_blue.css"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import Breadcrumb from "components/Common/Breadcrumb"
import { fetchAllProjectList } from "store/actions/ProjectActions"
import {
  createTagsAPI,
  EditTagAPI,
  getTagsStautusAPI,
} from "store/actions/TagsAction"
import { setEditTag } from "store/Slices/TagsSlice"
import { createOptionsDefectType, createTagsOptions } from "helpers/helper"
import { TypeOptions } from "./TagHelper"

const CreateEditTag = () => {
  const [projectOptionValue, setProjectOptionValues] = useState([])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const loading = useSelector(state => state?.Loading?.isLoading)

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const editTag = useSelector(state => state?.Tags?.editTag)

  const isEditMode = editTag?.isEdit
  const isEditModeData = editTag?.data

  const breadcrumbItems = [
    { label: "Tags", path: "/Settings/Tags" },
    {
      label: isEditMode ? "Edit Tag " : "Create New Tag",
      path: "/",
    },
  ]
  // Updated validationSchema to make meta_key, meta_value, and category mandatory
  const validationSchema = Yup.object({
    meta_key: Yup.string().required("Type is required"),
    category: Yup.string().required("Category is required"),
    meta_value: Yup.string().trim()
    .matches(/^.*\S.*$/, "Description cannot contain only spaces")
    .required("Description is required"),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      meta_key: isEditModeData?.meta_key || "",
      meta_value: isEditModeData?.meta_value || "",
      category: isEditModeData?.category || "",
      project_id: isEditModeData
        ? isEditModeData.project_id.map(item => ({
          id: item, // Assuming item is the ID in isEditModeData.project_id
          project_name:
            projectOptionValue.find(option => option.value === item)?.label ||
            "",
        }))
        : [],
    },
    validationSchema,
    onSubmit: values => {
      const formData = {
        ...values,
        project_id: values.project_id.map(item => item.id),
      }
      createTag(formData)
    },
  })

  const createTag = async formData => {
    const editFromValues = {
      ...formData,
      id: isEditModeData?.id,
    }

    if (!isEditModeData) {
      try {
        await dispatch(createTagsAPI(formData))
        navigate(-1) // Navigate back or handle success
      } catch (error) {
        toast.error("An error occurred while creating Tag", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
      }
    } else {
      try {
        await dispatch(EditTagAPI(editFromValues))
        navigate(-1) // Navigate back or handle success
        dispatch(setEditTag({ isEdit: false, data: null }))
      } catch (error) {
        console.error("Error updating tag:", error)
        toast.error("An error occurred while updating tag", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
      }
    }
  }

  useEffect(() => {
    const fetchOptions = async () => {
      let bodyoption = { project_id: "" }
      try {
        const responses = await Promise.all([
          dispatch(fetchAllProjectList(bodyoption)),
        ])

        const [projectResponse] = responses

        setProjectOptionValues(
          projectResponse?.map(entry => ({
            value: entry.id,
            label: entry.project_name,
          }))
        )
      } catch (error) {
        console.error("Failed to fetch options", error)
        toast.error("Failed to fetch options.")
      }
    }
    fetchOptions()
  }, [globalProject, dispatch])

  const [requirementStatusValue, setRequirementStatusValue] = useState([])
  const [defectStatusValue, setDefectStatusValue] = useState([])
  const [projectStatusValue, setProjectStatusValue] = useState([])
  const [testStatusValue, setTestStatusValue] = useState([])
  const [executionStatusValue, setExecutionStatusValue] = useState([])
  const [testTypeStatusValue, setTestTypeStatusValue] = useState([])
  const [PriorityStatusValue, setPriorityStatusValue] = useState([])
  const [defectPriorityStatusValue, setDefectPriorityStatusValue] = useState([])
  const [browserValue, setBrowserValue] = useState([])
  const [deviceValue, setDeviceValue] = useState([])
  const [severityOptionValues, setSeverityOptionValues] = useState([])
  const [executionTypeValue, setExecutionTypeValue] = useState([])
  const [requirementTypeValue, setRequirementTypeValue] = useState([])
  const [projectTypeValue, setProjectTypeValue] = useState([])
  const [defectTypeValue, setDefectTypeValue] = useState([])
  const [osValue, setOsValue] = useState([])

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const bodyOption = { project_id: "" }
        const metaDataResponse = await dispatch(getTagsStautusAPI(bodyOption))

        const {
          Requirement_Status,
          Defect_Status,
          Project_Status,
          Test_Status,
          Execution_Status,
          Testing_Type,
          Priority,
          Defect_Priority,
          Browser,
          Device,
          Severity,
          Execution_Type,
          Requirement_Type,
          Project_Type,
          OS,
          Defect_Type,
        } = metaDataResponse?.data || {}

        setDefectTypeValue(createOptionsDefectType(Defect_Type || []))
        setRequirementStatusValue(createTagsOptions(Requirement_Status || []))
        setDefectStatusValue(createTagsOptions(Defect_Status || []))
        setProjectStatusValue(createTagsOptions(Project_Status || []))
        setTestStatusValue(createTagsOptions(Test_Status || []))
        setExecutionStatusValue(createTagsOptions(Execution_Status || []))
        setTestTypeStatusValue(createTagsOptions(Testing_Type || []))
        setPriorityStatusValue(createTagsOptions(Priority || []))
        setDefectPriorityStatusValue(createTagsOptions(Defect_Priority || []))
        setBrowserValue(createTagsOptions(Browser || []))
        setSeverityOptionValues(createTagsOptions(Severity || []))
        setDeviceValue(createTagsOptions(Device || []))
        setExecutionTypeValue(createTagsOptions(Execution_Type || []))
        setRequirementTypeValue(createTagsOptions(Requirement_Type || []))
        setOsValue(createTagsOptions(OS || []))
        setProjectTypeValue(createTagsOptions(Project_Type || []))
      } catch (error) {
        console.log(error)
      }
    }

    fetchOptions()
  }, [globalProject])

  // Function to get options based on meta_key value
  const getOptions = metaKey => {
    switch (metaKey) {
      case "Test Case Status":
        return testStatusValue
      case "Project Status":
        return projectStatusValue
      case "Defect Status":
        return defectStatusValue
      case "Execution Status":
        return executionStatusValue
      case "Requirement Type":
        return requirementTypeValue
      case "Testing Type":
        return testTypeStatusValue
      case "Priority":
        return PriorityStatusValue
      case "Defect Priority":
        return defectPriorityStatusValue
      case "Severity":
        return severityOptionValues
      case "Device":
        return deviceValue
      case "Browser":
        return browserValue
      case "Execution Type":
        return executionTypeValue
      case "Project Type":
        return projectTypeValue
      case "Os":
        return osValue
      case "Requirement Status":
        return requirementStatusValue
      case "Defect Type":
        return defectTypeValue
      default:
        return []
    }
  }
  // Get current options based on meta_key
  const currentOptions = getOptions(formik.values.meta_key)

  console.log("formik.values.meta_key", formik.values.meta_key)
  const layoutModeType = useSelector(state => state.Layout.layoutModeType)

  const customStyle = {
    control: provided => ({
      ...provided,
      maxHeight: "150px", // Set the maximum height of the dropdown menu list
      overflowY: "auto",
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888",
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Ensure input text is visible
    }),
    multiValue: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#3a3f51" : "#e0e0e0", // Dark background for selected items
    }),
    // Customize multiValueLabel (selected item text color)
    multiValueLabel: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Adjust text color
    }),
    // Customize multiValueRemove (cross icon for removing selected items)
    multiValueRemove: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
      ":hover": {
        backgroundColor: layoutModeType === "dark" ? "#ff4949" : "#e0e0e0", // Hover effect for remove icon
        color: "#fff",
      },
    }),
  }

  console.log("formik.values.category", formik.values.category)

  return (
    <React.Fragment>
      <Container fluid>
        <Breadcrumb title="Tag" breadcrumbItems={breadcrumbItems} />

        <div className="mt-2 mx-3 d-flex justify-content-between">
          <div>
            <h3>{!isEditMode ? "Create New Tag" : "Edit Tag"}</h3>
            <p className="text-muted">
              Fill the form to {!isEditMode ? "create" : "edit"} a tag
            </p>
          </div>
          <div>
            <Button
              type="button"
              color="primary"
              className="btn-label"
              onClick={() => {
                navigate(-1)
                dispatch(setEditTag({ isEdit: false, data: null }))
              }}
            >
              <i className="bx bx-arrow-back label-icon"></i> Go Back
            </Button>
          </div>
        </div>

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label">
                      Type
                      <span className="text-danger">*</span>
                    </Label>
                    <Select
                      styles={customStyle}
                      className="select2-selection"
                      name="meta_key"
                      options={TypeOptions}
                      onChange={option => {
                        formik.setFieldValue("category", null)
                        formik.setFieldValue("meta_key", option?.value)
                      }}
                      placeholder="Select..."
                      onBlur={() => formik.setFieldTouched("meta_key", true)}
                      value={
                        TypeOptions.flatMap(group => group.options).find(
                          option => option.value === formik.values.meta_key
                        ) || null
                      }
                      getOptionLabel={option => option.label}
                      getOptionValue={option => option.value}
                      isClearable
                    />
                    {formik.errors.meta_key && (
                      <div className="text-danger">
                        {formik.errors.meta_key}
                      </div>
                    )}
                  </div>
                  {!isEditMode && (
                    <Alert color="info" role="alert">
                      Select a Type to See Available Categories
                    </Alert>
                  )}
                  <div className="mb-3">
                    <Label className="form-label">
                      Category
                      <span className="text-danger">*</span>
                    </Label>
                    {formik.values.meta_key === "Defect Type" ? (
                      <>
                        <Select
                          styles={customStyle}
                          className="select2-selection"
                          name="category"
                          options={currentOptions}
                          isClearable
                          placeholder={formik.values.category || "Select..."}
                          menuPortalTarget={document.body}
                          menuPlacement="auto"
                          menuPosition="fixed"
                          maxMenuHeight={200}
                          onChange={option => {
                            // Set the category value in Formik state
                            formik.setFieldValue(
                              "category",
                              option?.value || ""
                            ) // Reset to "" if no option is selected
                          }}
                          onBlur={() =>
                            formik.setFieldTouched("category", true)
                          }
                          value={currentOptions.find(
                            option => option.value === formik.values.category
                          )} // Ensure proper reset
                          isDisabled={
                            !formik.values.meta_key ||
                            formik.values.meta_key === ""
                          }
                        />
                      </>
                    ) : (
                      <Select
                        styles={customStyle}
                        className="select2-selection"
                        name="category"
                        options={currentOptions}
                        isClearable
                        menuPortalTarget={document.body}
                        menuPlacement="auto"
                        menuPosition="fixed"
                        maxMenuHeight={200}
                        onChange={option => {
                          // Set the category value in Formik state
                          formik.setFieldValue("category", option?.value || "") // Reset to "" if no option is selected
                        }}
                        onBlur={() => formik.setFieldTouched("category", true)}
                        value={
                          currentOptions.find(
                            option => option.value === formik.values.category
                          ) || null
                        } // Ensure proper reset
                        isDisabled={
                          !formik.values.meta_key ||
                          formik.values.meta_key === ""
                        }
                      />
                    )}

                    {formik.touched.category && formik.errors.category && (
                      <div className="text-danger">
                        {formik.errors.category}
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label">Project</Label>
                    <Select
                      styles={customStyle}
                      className="select2-selection"
                      name="project_id"
                      options={projectOptionValue}
                      isMulti
                      onChange={options => {
                        formik.setFieldValue(
                          "project_id",
                          options
                            ? options.map(option => ({
                              id: option.value,
                              user_name: option.label,
                            }))
                            : []
                        )
                      }}
                      onBlur={() => formik.setFieldTouched("project_id")}
                      value={projectOptionValue.filter(
                        option =>
                          Array.isArray(formik.values.project_id) &&
                          formik.values.project_id.some(
                            selected => selected.id === option.value
                          )
                      )}
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">
                      Description <span className="text-danger">*</span>
                    </Label>
                    <Input
                      name="meta_value"
                      type="text"
                      placeholder="Enter Description"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.meta_value}
                      className="custom-input"
                    />
                    {formik.touched.meta_value && formik.errors.meta_value && (
                      <div className="text-danger">
                        {formik.errors.meta_value}
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={12} className="mb-3">
              <Row>
                <Col md="12" className="text-end mt-3">
                  <Button color="primary" type="submit" disabled={loading}>
                    {loading ? "Loading..." : "Submit"}
                  </Button>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => {
                      formik.resetForm()
                      navigate(-1)
                      dispatch(setEditTag({ isEdit: false, data: null }))
                    }}
                    className="ms-2"
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </React.Fragment>
  )
}

export default CreateEditTag
