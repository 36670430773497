import React from "react"
import { Outlet, useLocation } from "react-router-dom"
import Breadcrumb from "./Header/Breadcrumb"
import NoData from "components/Common/NoData"

const Reports = () => {
  const location = useLocation()
  const locationPath = location.pathname
  const pathname = locationPath.split("/").filter(Boolean).pop()
  const pathArray = ["CreateScheduleReport"]
  const globalProjectValue = JSON.parse(localStorage.getItem("selectedProject"))

  return (
    <React.Fragment>
      {globalProjectValue ? (
        <>
          {" "}
          <div className="page-content">
            {!pathArray.includes(pathname) && (
              <Breadcrumb
                isImport={true}
                isDownload={true}
                isExport={true}
                isInfo={true}
                isProject={true}
              />
            )}
            <Outlet />
          </div>
        </>
      ) : (
        <>
          <NoData />
        </>
      )}
    </React.Fragment>
  )
}

export default Reports
