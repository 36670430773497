import React from "react"
import { Outlet, useLocation } from "react-router-dom"
import Breadcrumb from "./TestAutomation/Header/Breadcrumb"
import NoData from "components/Common/NoData"

const TestAutomation = () => {
  const location = useLocation()
  const locationPath = location.pathname
  const pathname = locationPath.split("/").filter(Boolean).pop()
  const pathArray = [
    "CreateTestData",
    "EditTestData",
    "ViewTestData",
    "CreateTestData",
    "ViewTestData",
    "ViewAutomationSuite",
    "CreateAutomationSuite",
    "EditAutomationSuite",
    "ViewAutomationScript",
    "CreateAutomationScript",
    "EditAutomationScript",
    "CreateDbIntegration",
    "EditDbIntegration",
    "CreateAgent",
    "UploadNewApp",
  ]
  const globalProjectValue = JSON.parse(localStorage.getItem("selectedProject"))
  const Permission = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission = Permission?.permissions[0]?.menu_item?.TestLab?.Testautomation
    

  return (
    <React.Fragment>
      {globalProjectValue ? (
        <>
          <div className="page-content">
            {!pathArray.includes(pathname) && <Breadcrumb isImport={rolePermission?.view} />}
            <Outlet />
          </div>
        </>
      ) : (
        <>
          <NoData />
        </>
      )}
    </React.Fragment>
  )
}

export default TestAutomation
