import React, { useEffect, useState, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import DeleteModal from "components/Common/DeleteModal"
import { useSelector, useDispatch } from "react-redux"
import { toast, ToastContainer } from "react-toastify"
import TableContainer from "components/Common/helper/TableContainer"
import {
  BulkEditTestCaseAPI,
  deleteTestCaseAPI,
  fetchNextCaseID,
  GetTestCaseByIdAPI,
  TestCaseCharts,
  TestCaseDetails,
} from "store/actions/TestCaseAction"
import { getStatusBackGroundColor } from "helpers/helper"
import moment from "moment"
import { clearFilters } from "store/Slices/TestCaseSlice"
import BulkApprovalTestPlan from "./BulkApprovalTestPlan"
import { setRequestPage } from "store/Slices/RequestPageSlice"
import {
  editAudit,
  NotificationListTP,
  NotificationListViewTP,
} from "store/actions/NotificationsActions"
import { GetTestPlanByIdAPI } from "store/actions/TestPlanActions"

const TestPlanRequest = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  // const allChartData = useSelector(state => state?.TestCase?.testCaseChart)
  // useEffect(() => {
  //   if (allTestScenario && allTestScenario.Data) {
  //     setData(allTestScenario)
  //   }
  // }, [allTestScenario])

  // const handleSingleEditClick = async id => {
  //   if (rolePermission?.testcase?.edit) {
  //     try {
  //       const res = await dispatch(GetTestCaseByIdAPI(id))
  //       dispatch(setEditTestCase({ isEdit: true, data: res }))
  //       navigate("/TestManagement/CreateEditTestCase")
  //     } catch (error) {
  //       console.error("Error fetching data", error)
  //     }
  //   } else {
  //     toast.warn("You don't have the permission to edit test case", {
  //       position: "top-right",
  //       id: " Error ",
  //       autoClose: 3000,
  //     })
  //   }
  // }

  const handleView = async id => {
    try {
      await dispatch(GetTestPlanByIdAPI(id))
      navigate("/TestPlan/ViewTestPlan")
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  const bodyOption = {
    project_id: globalProject?.value || selectedProject?.value,
  }

  const handleCreateClick = () => {
    dispatch(fetchNextCaseID(bodyOption))
    navigate("/TestManagement/CreateEditTestCase")
  }

  const [selectedRows, setSelectedRows] = useState([])
  // const handleCheckboxChange = id => {
  //   setSelectedRows(prevValues =>
  //     prevValues.includes(id)
  //       ? prevValues.filter(val => val !== id)
  //       : [...prevValues, id]
  //   )
  // }

  // const handleSelectAll = e => {
  //   const isChecked = e.target.checked
  //   if (isChecked) {
  //     const newSelectedRows = data.map(item => item.id)
  //     setSelectedRows(newSelectedRows)
  //   } else {
  //     setSelectedRows([])
  //   }
  // }

  const columns = useMemo(
    () => [
      // {
      //   header: (
      //     <input
      //       type="checkbox"
      //       onChange={handleSelectAll}
      //       style={{ cursor: "pointer" }}
      //       title="Select All"
      //       checked={
      //         Array.isArray(selectedRows) &&
      //         selectedRows.length === data?.length &&
      //         data?.length > 0
      //       }
      //     />
      //   ),
      //   accessorKey: "checkbox",
      //   cell: cellProps => {
      //     const jobData = cellProps.row.original.test_case_id

      //     const isChecked = () => {
      //       if (!Array.isArray(selectedRows)) {
      //         console.error("selectedRows is not an array")
      //         return false
      //       }
      //       return selectedRows.includes(jobData)
      //     }

      //     const handleCheckboxClick = () => {
      //       try {
      //         if (!Array.isArray(selectedRows)) {
      //           throw new Error("selectedRows is not an array")
      //         }
      //         handleCheckboxChange(jobData)
      //       } catch (error) {
      //         console.error(error.message)
      //       }
      //     }

      //     return (
      //       <input
      //         type="checkbox"
      //         style={{ cursor: "pointer" }}
      //         checked={isChecked()}
      //         onChange={handleCheckboxClick}
      //         onClick={handleCheckboxClick}
      //       />
      //     )
      //   },
      //   enableColumnFilter: false,
      //   enableSorting: false,
      // },
      {
        header: "Test Plan Id",
        accessorKey: "test_id",
        cell: cellProps => <div>{cellProps.row.original?.test_id}</div>,
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <div
              className="text-decoration-underline fw-bold"
              style={{ cursor: "pointer" }}
              onClick={() => {
                const data = cellProps.row.original
                handleView(data?.id)
              }}
            >
              {cellProps.row.original?.test_id}
            </div>
          )
        },
      },
      {
        header: "Test Plan Name",
        accessorKey: "test_name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return (
            <>
              <p
                className="font-size-14 mb-1"
                style={{
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  whiteSpace: "normal",
                  maxWidth: "250px", // Set the max-width to control the wrapping
                  minWidth: "100px", //, // Set the max-width to control the wrapping
                }}
              >
                {cell.getValue() || "-"}
              </p>
            </>
          )
        },
      },
      {
        header: "Approval Status",
        accessorKey: "test_status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const test_plan_status = cell.getValue()
          return (
            <>
              <Badge
                className={
                  "badge-soft-" +
                  `${getStatusBackGroundColor(test_plan_status)}`
                }
                style={{
                  fontSize: "11px", // Increase font size
                  padding: "0.5em", // Increase padding
                  borderRadius: "0.5em", // Adjust border radius
                }}
              >
                {test_plan_status || "-"}
              </Badge>
            </>
          )
        },
      },
      {
        header: "Assign To",
        accessorKey: "assign_users",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const assignTo = cell.getValue() // assuming this is an array of user objects
          const avatarGroupStyle = {
            display: "flex",
            flexWrap: "wrap", // Allows items to wrap to the next line
            maxWidth: "200px", // Adjust the max width as needed
          }

          // If no data, return a default value
          if (!assignTo || assignTo.length === 0) {
            return <span>-</span>
          }

          // CSS for limiting avatars per row
          const avatarItemStyle = {}

          return (
            <div style={avatarGroupStyle}>
              {assignTo.map(user => (
                <React.Fragment key={user.id}>
                  <div className="" style={avatarItemStyle}>
                    <Link to="#" className="" id={"member" + user.id}>
                      <div className="me-1">
                        <span
                        title={user}
                          className={`avatar-title rounded-circle bg-primary text-white text-align-center`}
                          style={{
                            width: "20px",
                            height: "20px",
                            fontSize: "14px",
                          }}
                        >
                          {user.charAt(0).toUpperCase()}
                        </span>
                      </div>
                    </Link>
                  </div>
                </React.Fragment>
              ))}
            </div>
          )
        },
      },
      {
        header: "Created Date",
        accessorKey: "created_date",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return <>{cell.getValue() ? moment(cell.getValue()).format("DD-MM-YYYY") : "-"}</>
        },
      },
      {
        header: "Updated Date",
        accessorKey: "updated_date",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return <>{cell.getValue() ? moment(cell.getValue()).format("DD-MM-YYYY"):'-'}</>
        },
      },
      {
        header: "Approved/Rejected",
        cell: cellProps => {
          const { test_status, id } = cellProps.row.original

          const isApproved =
            test_status === "Approved" || test_status === "Rejected"
          const isRejected =
            test_status === "Rejected" || test_status === "Approved"

          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              {/* Approve Button */}
              <li data-bs-toggle="tooltip" data-bs-placement="top">
                <span
                title="Approve"
                  className={`btn btn-sm ${
                    isApproved ? "btn-success" : "btn-soft-success"
                  }`}
                  onClick={() => {
                    if (!isApproved && !isRejected) handleSingleEdit(id, true)
                  }}
                  style={{
                    pointerEvents: isApproved ? "none" : "auto",
                    opacity: isApproved ? 0.5 : 1,
                  }}
                  id={`viewtooltip-${id}`}
                >
                  <i className="bx bx-check-circle" />
                </span>
              </li>

              {/* Reject Button */}
              <li>
                <span
                title="Reject"
                  className={`btn btn-sm ${
                    isRejected ? "btn-danger" : "btn-soft-danger"
                  }`}
                  onClick={() => {
                    if (!isApproved && !isRejected) handleSingleEdit(id, false)
                  }}
                  style={{
                    pointerEvents: isRejected ? "none" : "auto",
                    opacity: isRejected ? 0.5 : 1,
                  }}
                  id={`deletetooltip`}
                >
                  <i className="mdi mdi-close-circle" />
                </span>
              </li>
            </ul>
          )
        },
      },
    ],
    [data]
  )

  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [globalFilter, setGlobalFilter] = useState("")
  const [totalCount, setTotalCount] = useState(null)
  const [isFilterFormOpen, setIsFilterFormOpen] = useState(false)
  const [isOpen, setIsEditOpen] = useState(false)
  // Provide a default empty object for testCaseFilterFrom
  const { testCaseFilterFrom } = useSelector(state => state.TestCase)

  const handleClose = () => {
    setIsEditOpen(false)
  }

  const fetchTableData = async (page, size, search) => {
    let userId = localStorage.getItem("authUser")
    let userData = JSON.parse(userId)
    let bodyoption = {
      page: page + 1,
      page_size: size,
      search: search,
      models_name: "test_plan",
      receiver: userData.user_id,
      project_id: globalProject?.value || selectedProject?.value,
    }
    try {
      const data = await dispatch(NotificationListViewTP(bodyoption))
      setData(data.Data)
      setTotalCount(data.TotalCount)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  useEffect(() => {
    fetchTableData(pageIndex, pageSize, globalFilter)
  }, [pageIndex, pageSize, globalFilter])

  const handleFilterClick = () => {
    setIsFilterFormOpen(!isFilterFormOpen)
  }

  const handleSingleEdit = (id, data) => {
    setSelectedRows(id)
    dispatch(setRequestPage({ bulkApproval: false, approve: data }))
    setIsEditOpen(!isOpen)
  }

  const handleBulkEdit = async data => {
    setLoading(true)
    try {
      await dispatch(editAudit(data))
      setSelectedRows([])
      setIsEditOpen(false)
      await fetchTableData(pageIndex, pageSize, globalFilter)
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        id: "Error msg",
        autoClose: 3000,
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    return () => {
      dispatch(clearFilters())
    }
  }, [])

  return (
    <React.Fragment>
      <BulkApprovalTestPlan
        isOpen={isOpen}
        toggle={handleSingleEdit}
        handleBulkEdit={handleBulkEdit}
        setSelectedRows={setSelectedRows}
        handleClose={handleClose}
        selectedRows={selectedRows}
        loading={loading}
      />

      <Container fluid>
        <Row>
          <Col lg="12">
            <Card
              style={{
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
              }}
            >
              <CardBody className="pt-2">
                <div className="table-container">
                  <TableContainer
                    columns={columns}
                    data={data}
                    pageIndex={pageIndex}
                    setPageIndex={setPageIndex}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    totalCount={totalCount}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    isGlobalFilter={true}
                    isPagination={true}
                    isCustomPageSize={true}
                    isAddButton={false}
                    isAddDelete={false}
                    isAddEdit={false}
                    isAddFilter={false}
                    pagination="pagination"
                    SearchPlaceholder="Search..."
                    handleAddClick={handleCreateClick}
                    handleEditClick={handleSingleEdit}
                    handleFilterClick={handleFilterClick}
                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                    theadClass="table-light"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </React.Fragment>
  )
}

export default withRouter(TestPlanRequest)
