import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Container,
  Form,
  Input,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import "flatpickr/dist/themes/material_blue.css"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import Breadcrumb from "components/Common/Breadcrumb"
import { getTagsStautusAPI } from "store/actions/TagsAction"
import { createOptions } from "helpers/helper"
import { setEditDbIntegration } from "store/Slices/DbIntegrationSlice"
import Dropzone from "react-dropzone"
import BASE_URL from "services/apiConfig"
import { UploadNewAppBs } from "store/actions/UploadAppActions"
import { getAllIntegrationListAPI } from "store/actions/IntegrationAction"
import { setReLoading } from "store/Slices/LoadingSlice"

const UploadNewApp = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const editDbIntegration = useSelector(
    state => state?.DbIntegration?.editDbIntegration
  )
  const [selectedFiles, setSelectedFiles] = useState([])
  const [selectedDeleteFiles, setSelectedDeleteFiles] = useState([])
  const [error, setError] = useState(false)
  const [integrationId, setIntegrationId] = useState(null)
  const [filesError, setFilesError] = useState(false);
  const [ValidFile, setValidFile] = useState(false);

  const isEditMode = editDbIntegration?.isEdit
  const isEditModeData = editDbIntegration?.data
  const loading = useSelector(state => state?.Loading?.isLoading)
  const [selectedValue, setSelectedValue] = useState(isEditMode ? isEditModeData?.cycle_type : "Ios");


  const breadcrumbItems = [
    { label: "Test Lab / Test Automation", path: "/TestAutomation/UploadApp" },
    {
      label: "Upload New App",
      path: "/",
    },
  ]

  const validationSchema = Yup.object().shape({
    // version_info: Yup.string().required("Version info is required"),
    application_name: Yup.string().trim()
    .matches(/^.*\S.*$/, "Application Name cannot contain only spaces")
    .required("Application Name is required"),
  })

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
    formik.handleReset()
    setError(false)
    setValidFile(false)
    setFilesError(false)
  };

  const handleFile = () => {
    if (selectedFiles?.length == 0) {
      setError(true)
      return
    } else {
      setError(false)
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      project_id: globalProject?.value || "",
      // version_info: "",
      application_name: "",
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      const formData = {
        ...values,
        app_type: selectedValue,
        file: selectedFiles[0],
        intergration_id: integrationId
      }
      if (selectedFiles?.length == 0) {
        setError(true)
        return
      } else {
        setError(false)
        create(formData)
        resetForm()
      }
    },
  })

  const formatFilePath = path => {
    if (path?.includes("/media/media")) {
      return path.replace("media/media", "media")
    }
    return path
  }


  const create = async formData => {
    try {
        dispatch(setReLoading(true))
        await dispatch(UploadNewAppBs(formData))
        navigate("/TestAutomation/UploadApp")
      } catch (error) {
        toast.error(error?.message || "An error occurred while uploading app", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
    }
    dispatch(setReLoading(false))
  }

  const [databasetype, setDatabaseType] = useState([])
  const [databasetypeOptions, setDatabaseTypeOptions] = useState([])

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const { data } = await dispatch(
          getTagsStautusAPI({
            project_id: globalProject?.value,
          })
        )
        setDatabaseType(data?.Database_Type)
        let payload = { project_id: null }
        const integrationList = await dispatch(getAllIntegrationListAPI(payload));

        const intergrationId = integrationList?.filter(
          (project) => project.product === "BrowserStack"
        );
        setIntegrationId(intergrationId[0]?.id);
      } catch (error) {
        console.error(error)
      }
    }

    fetchOptions()

  }, [globalProject])

  useEffect(() => {
    setDatabaseTypeOptions(createOptions(databasetype))
  }, [databasetype])

  const handleAcceptedFiles = filesSelected => {

    // const newImages = files?.map(file => {
    //   return Object.assign(file, {
    //     priview: URL.createObjectURL(file),
    //   })
    // })
    // //
    // setSelectedFiles([...selectedFiles, ...newImages])
    // setSelectedDeleteFiles([...selectedFiles, ...newImages])
    const maxFileSize = 100 * 1024 * 1024;
    const selectedFile = filesSelected[0];

    if (filesSelected.length > 0) {
      if (selectedFile.size > maxFileSize) {
        setFilesError(true);
        setSelectedFiles([]);
        setSelectedDeleteFiles([]);
        setValidFile(false);
      } else {
        setSelectedFiles([...selectedFiles, ...filesSelected]);
        setSelectedDeleteFiles([...selectedFiles, ...filesSelected]);
        setFilesError(false);
        setValidFile(false);
      }

        let isValidFile = false;

        if (selectedValue === "Ios") {
          isValidFile = selectedFile.name.endsWith(".ipa");
        } else {
          isValidFile = selectedFile.name.endsWith(".apk");
        }

        if (!isValidFile) {
          setValidFile(true);
          setFilesError(true);
          setSelectedFiles([]);
          setSelectedDeleteFiles([]);
        } else {
          setSelectedFiles([...selectedFiles, ...filesSelected]);
          setSelectedDeleteFiles([...selectedFiles, ...filesSelected]);
          setFilesError(false);
          setValidFile(false);
        }
    }
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Breadcrumb title="Test Plan" breadcrumbItems={breadcrumbItems} />

        <div className="mt-2 mx-3 d-flex justify-content-between">
          <div>
            <h3>
              Upload New App
            </h3>
            <p className="text-muted">
              Fill the form to upload a new app
            </p>
          </div>
          <div>
            <Button
              type="button"
              color="primary"
              onClick={() => {
                navigate("/TestAutomation/UploadApp")
                dispatch(setEditDbIntegration({ isEdit: false, data: null }))
              }}
            >
              Go Back
            </Button>
          </div>
        </div>

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg={12}>
              <Card>
                <div className="p-3 d-flex gap-2">
                  <h5 className="font-size-14 mb-2">App Type  <span className="text-danger">*</span></h5>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios1"
                      value="Ios"
                      defaultChecked={selectedValue === "Ios"}
                      disabled={isEditMode && true}
                      onChange={handleRadioChange}
                    />
                    <label className="form-check-label" htmlFor="exampleRadios1">
                      IOS
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios2"
                      disabled={isEditMode && true}
                      value="Android"
                      defaultChecked={selectedValue === "Android"}
                      onChange={handleRadioChange}
                    />
                    <label className="form-check-label" htmlFor="exampleRadios2">
                      Android
                    </label>
                  </div>
                </div>
                <CardBody >
                  <div className="mb-3 col-4">
                    <Label className="form-label">Application Name <span className="text-danger">*</span></Label>
                    <Input
                      name="application_name"
                      type="text"
                      placeholder="Application Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.application_name}
                      invalid={
                        formik.touched.application_name &&
                        !!formik.errors.application_name
                      }
                      className="custom-input col-4"
                    />
                    {formik.touched.application_name &&
                      formik.errors.application_name && (
                        <FormFeedback>
                          {formik.errors.application_name}
                        </FormFeedback>
                      )}
                  </div>
                  {/* <div className="mb-3 col-4">
                    <Label className="form-label">Version Info <span className="text-danger">*</span></Label>
                    <Input
                      name="version_info"
                      type="text"
                      placeholder="Version Info"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.version_info}
                      invalid={
                        formik.touched.version_info &&
                        !!formik.errors.version_info
                      }
                      className="custom-input col-4"
                    />
                    {formik.touched.version_info &&
                      formik.errors.version_info && (
                        <FormFeedback>
                          {formik.errors.version_info}
                        </FormFeedback>
                      )}
                  </div> */}
                  <div className="mt-3">
                    <Label>File <span className="text-danger">*</span></Label>
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="dz-message needsclick">
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    {error &&
                      <span className="text-danger">File required <br /> </span>  }
                    {filesError && (
                      <span className="text-danger">
                        Maximum File Size : 100MB <br />
                      </span>
                    )}

                    {ValidFile && (
                      <span className="text-danger">
                        {selectedValue === "Ios"
                          ? "File Type should be .ipa"
                          : "File Type should be .apk"}
                      </span>
                    )}
                    <div
                      className="dropzone-previews mt-3"
                      id="file-previews"
                    >
                      {selectedDeleteFiles.map((file, index) => {
                        return (
                          <div className="border rounded" key={index}>
                            <div className="d-flex flex-justify-content-between gap-2 p-2">
                              <div className="flex-shrink-0 me-3">
                                <div className="avatar-xs bg-light rounded p-2">
                                  <i
                                    className="bx bx-file"
                                    style={{ fontSize: "23px" }}
                                  ></i>
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <div className="pt-2">
                                  <p className="fs-md mb-1" data-dz-name>
                                    {" "}
                                    <a
                                      style={{
                                        textDecoration: "none",
                                        color: "black",
                                      }}
                                      href={`${BASE_URL}${formatFilePath(
                                        file.path
                                      )}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {file.path}
                                    </a>
                                  </p>
                                  <strong
                                    className="error text-danger"
                                    data-dz-errormessage
                                  ></strong>
                                </div>
                              </div>
                              <div className="flex-shrink-0 ms-3 mt-1">
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => {
                                    const newImages = [...selectedFiles]
                                    newImages.splice(index, 1)
                                    setSelectedFiles(newImages)
                                    setSelectedDeleteFiles(newImages)
                                  }}
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={12}>
              <Row className="mb-3">
                <Col md="12" className="text-end mt-3">
                  <Button color="primary" type="submit" onClick={handleFile} disabled={loading}>
                    {loading ? "Loading..." : "Submit"}
                  </Button>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => {
                      formik.resetForm()
                      navigate(-1)
                      dispatch(
                        setEditDbIntegration({ isEdit: false, data: null })
                      )
                    }}
                    className="ms-2"
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </React.Fragment>
  )
}

export default UploadNewApp
