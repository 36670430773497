import { toast } from "react-toastify"
import httpInjectorService from "services/http-Injector.Service"
import { setNextDefectId, setSingleDefectData } from "store/Slices/DefectsSlice"
import { setLoading } from "store/Slices/LoadingSlice"

export const getAllDefectsListAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))

    // Ensure bodyoption is an object
    if (typeof bodyoption !== "object" || Array.isArray(bodyoption)) {
      dispatch(setLoading(false))
      throw new Error("Invalid parameter: bodyoption should be an object")
    }

    let queryString = ""

    // Build query string from bodyoption
    Object.entries(bodyoption).forEach(([key, value]) => {
      // Check if value is not null, undefined, empty string, or empty array
      if (
        value !== null &&
        value !== undefined &&
        value !== "" &&
        !(Array.isArray(value) && value.length === 0)
      ) {
        queryString += `${encodeURIComponent(key)}=${encodeURIComponent(
          value
        )}&`
      }
    })

    // Remove trailing '&' if present
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }

    // Only append '?' if queryString is not empty
    if (queryString) {
      queryString = "?" + queryString
    }

    try {
      const response = await httpInjectorService.getAllDefectsList(queryString)
      const data = response
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const getAllDefectsIDSListAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.getAllDefectsList(queryString)
      const data = response
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const deleteDefectAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.deleteDefect(bodyoption)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Defect Deleted successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      } else {
        toast.error(response.message, {
          position: "top-right",
          id: "Defect Deleted successfully",
          autoClose: 3000,
        })
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const BulkDeleteDefectsAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (bodyoption[key] !== undefined && bodyoption[key] !== null) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.BulkDeleteDefect(formData)

      console.log("response", response)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Defects Deleted successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      } else {
        toast.error(response.message, {
          position: "top-right",
          id: "Defect Deleted successfully",
          autoClose: 3000,
        })
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const BulkEditDefectsAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.BulkEditDefects(formData)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Defects updated successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const downloadDefectTemplateAPI = bodyOption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyOption) {
      if (
        bodyOption[key] !== undefined &&
        bodyOption[key] !== null &&
        bodyOption[key] !== ""
      ) {
        formData.append(key, bodyOption[key])
      }
    }
    try {
      const response = await httpInjectorService.downloadDefectTemplate(
        formData
      )
      const blob = response.data
      const downloadLink = document.createElement("a")
      downloadLink.href = window.URL.createObjectURL(blob)
      downloadLink.download = "Defect Template"
      document.body.appendChild(downloadLink)

      // Programmatically trigger the download
      downloadLink.click()

      // Clean up
      document.body.removeChild(downloadLink)
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      console.error("Download failed:", error.message || error)
    }
  }
}
export const ExportDefectAPI = bodyOption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyOption) {
      if (bodyOption[key] !== undefined && bodyOption[key] !== null) {
        formData.append(key, bodyOption[key])
      }
    }
    try {
      const response = await httpInjectorService.exportDefectData(formData)
      const blob = response.data
      const downloadLink = document.createElement("a")
      downloadLink.href = window.URL.createObjectURL(blob)
      downloadLink.download = "Defect List"
      document.body.appendChild(downloadLink)

      // Programmatically trigger the download
      downloadLink.click()

      // Clean up
      document.body.removeChild(downloadLink)
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      console.error("Download failed:", error.message || error)
    }
  }
}

export const uploadDefectsTemplateAPI = bodyOption => {
  return async dispatch => {
    dispatch(setLoading(true))

    try {
      const response = await httpInjectorService.uploadDefectTemplate(
        bodyOption
      )

      return response
    } catch (error) {
      console.error("Upload failed:", error.message || error)
      toast.error("Upload failed. Please try again later.", {
        position: "top-right",
        id: "Test",
        autoClose: 3000,
      })
    } finally {
      dispatch(setLoading(false))
    }
  }
}
export const GetDefectByIdAPI = id => {
  return async dispatch => {
    dispatch(setLoading(true))

    try {
      const response = await httpInjectorService.GetDefectById(id)
      const data = response
      dispatch(setSingleDefectData(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const CreateDefectAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null
      ) {
        formData.append(key, bodyoption[key])
      }
    }

    try {
      const response = await httpInjectorService.createDefect(formData)

      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Defect created successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
        return response
      } else {
        const errorData = response
        throw new Error(
          errorData?.errors?.test_scenario_name[0] ||
            errorData?.attachment ||
            errorData?.message ||
            errorData?.message?.summary
        )
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error)
    }
  }
}
export const EditDefectAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.editDefect(
        formData,
        bodyoption?.id
      )

      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Defect updated successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      } else {
        const errorData = await response.json()
        throw new Error(
          errorData?.errors?.test_scenario_name[0] ||
            errorData?.attachment ||
            errorData?.message ||
            errorData?.message?.summary
        )
      }
    } catch (error) {
      dispatch(setLoading(false))

      throw new Error(error)
    }
  }
}
export const fetchNextDefectID = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.generateDefectId(queryString)
      const data = response
      dispatch(setNextDefectId(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const MapDefectStatus = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }

    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.mapStatus(formData)
      const data = response
      dispatch(setNextDefectId(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const MapDefectPriority = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.mapPriority(formData)
      const data = response
      dispatch(setNextDefectId(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const SyncDefectPlane = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }

    try {
      const response = await httpInjectorService.syncPlane(queryString)

      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Defect created successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      } else {
        const errorData = response
        throw new Error(errorData?.message || errorData?.msg)
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error)
    }
  }
}
export const SyncDefectJira = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }

    try {
      const response = await httpInjectorService.syncJira(queryString)

      if (response?.status) {
        toast.success(response.message || response.msg, {
          position: "top-right",
          id: "Defect created successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      } else {
        const errorData = response
        throw new Error(errorData?.message || errorData?.msg)
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error)
    }
  }
}

export const FetchJiraUser = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }

    try {
      const response = await httpInjectorService.fetchJiraUsers(formData)

      if (response) {
        dispatch(setLoading(false))
        return response
      } else {
        const errorData = response
        throw new Error(errorData?.message || errorData?.msg)
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error)
    }
  }
}
