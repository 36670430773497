import React from "react"
import { useSelector } from "react-redux"
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap"

const TestCaseSummaryResults = ({ testPlanSignOffData }) => {
  const isDarkMode = useSelector(state => state.Layout.layoutModeType)

  const cardHeaderStyle = {
    backgroundColor: isDarkMode === "dark" ? "#32394e" : "#fff",
    // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    color: isDarkMode === "dark" ? "#fff" : "#000",
  }

  // Function to dynamically get the count based on the available fields
  const getTotalTestCount = item => {
    const countKey = Object.keys(item).find(key => key.includes("_count"))
    return item[countKey] || "-"
  }

  // Function to dynamically get the percentage based on the available fields
  const getPercentage = item => {
    const percentageKey = Object.keys(item).find(key =>
      key.includes("_percentage")
    )
    return item[percentageKey] !== undefined ? item[percentageKey] : "-"
  }

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader
            className="d-flex align-items-center justify-content-between"
            style={cardHeaderStyle}
          >
            <h5 className="mb-0">Test Case Summary Results</h5>
          </CardHeader>
          <CardBody>
            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
              <Table hover striped>
                <thead
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "#ffffff",
                    zIndex: 2,
                    borderCollapse: "collapse",
                  }}
                >
                  <tr>
                    <th>Summary Assessment</th>
                    <th>Total Number of Test Cases</th>
                    <th>% of Total Planned</th>
                  </tr>
                </thead>
                <tbody>
                  {testPlanSignOffData &&
                    testPlanSignOffData?.data?.map((item, index) => (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>{getTotalTestCount(item)}</td>
                        <td>{getPercentage(item)}%</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default TestCaseSummaryResults
