import { createSlice } from "@reduxjs/toolkit"

const TagsSlice = createSlice({
  name: "Tags",
  initialState: {
    tagsStatus: null,
    editTag: {
      isEdit: false,
      data: null,
    },
    singleTag: null,
  },

  reducers: {
    setTagsStatus: (state, action) => ({
      ...state,
      tagsStatus: action.payload,
    }),
    setEditTag: (state, action) => ({
      ...state,
      editTag: action.payload,
    }),
    setSingleTag: (state, action) => ({
      ...state,
      singleTag: action.payload,
    }),
  },
})

export const { setTagsStatus, setEditTag, setSingleTag } = TagsSlice.actions

export default TagsSlice.reducer
