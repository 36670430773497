const validate = {
  login: "login/",
  signup: "register/",
  logout: "logout/",
  forgotPassword: "forgot-password/",
  changePassword: "change-password/",
  defaultProject: "project-data/",
  refreshToken: "refresh-token/",
  userProfile: "change-user-password/",
  getUserProfile: "user-profile/",
}
const projectManagement = {
  project: {
    createProject: "project-create/",
    editProject: "project-update/",
    projectList: "project-details/",
    projectDelete: "project-delete/",
    getTeamMember: "teams-details/",
    getAvailableTeamMember: "teams-available/",
    deleteMember: "users-delete/",
    userCreate: "add-users/",
    viewProject: "project-details-view/",
  },
  requirement: {
    requirementData: "requirements-details/",
    createRequirement: "requirements-create/",
    editRequirement: "requirements-update/",
    deleteRequirement: "requirements-delete/",
    createProject: "project-details/",
    editProject: "project-details",
    projectList: "project-details/?",
    bulkDelete: "requirements-bulk-delete/",
    getRequirementId: "generate-requirement-id/?",
    bulkEditRequirement: "requirements-bulk-update/",
    viewRequirement: "requirements-retrieve/",
    importRequirement: "import-requirements/",
    downloadTemplate: "requirement-template/",
    exportTemplate: "export-requirement/",
  },
  builds: {
    builds: "builds-details/",
    create: "builds-create/",
    edit: "builds-update/",
    delete: "builds-delete/",
    genNextBuildId: "generate-build-id/",
    bulkDelete: "builds-bulk-delete/",
  },
  modules: {
    modules: "modules-details/",
    create: "modules-create/",
    edit: "modules-update/",
    delete: "modules-delete/",
    bulkModuleDelete: "modules-bulk-delete/",
    genNextModuleId: "generate-module-id/?",
    singleModule: "modules-retrieve",
  },
}

const testManagement = {
  testScenario: {
    createTestScenario: "test-scenario-create/",
    getNextTestScenarioId: "generate-test-scenario-id/",
    getAllTestScenario: "test-scenario-Details/",
    bulkEditTestScenario: "testscenario-bulk-edit/",
    editTestScenario: "test-scenario-update/",
    bulkDeleteTestScenario: "testscenario-bulk_delete/",
    deleteTestScenario: "test-scenario-delete/",
    exportTestScenario: "export-testscenarios/",
    downloadTemplateTestScenrio: "tempalet-testscenarios/",
    importTestScenario: "import-testScenarios/",
    GetSingleTestScenario: "testscenario-retrieve/",
  },
  testCase: {
    preCondition: {
      getPreCondition: "precondition-details/",
      createPrecondion: "precondition-create/",
      editPreCondition: "precondition-update/",
      deletePreCondition: "precondition-delete/",
      viewPreCondition: "precondition-retrieve/",
    },
    testCaseDashboard: "test-case-details/",
    createTestCase: "test-case-create/",
    editTestCase: "test-case-update/",
    deleteTestCase: "test-case-delete/",
    getTestCaseId: "generate-test-caseid/",
    retrieve: "test-case-view/",
    testCaseSummary: "test-case-summary/",
    bulkDeleteTestCase: "testcase-bulk-delete/",
    bulkEditTestCase: "testcase-bulk-edit/",
    exportTestCase: "export-testcase/",
    downloadTemplateTestCase: "tempalet-testCase/",
    importTestCase: "import-testcase/",
    auditTestCase: "request-details/",
  },
}

const defectManagement = {
  defectDetails: "defect-details/",
  createDefect: "defect-create/",
  getDefectId: "generate-defect-id/",
  editDefect: "defect-update/",
  bulkEditDefects: "defect-bulk-edit/",
  deleteDefect: "defects-delete/",
  bulkDeleteDefects: "defects-bulk-delete/",
  getJiraUser: "fetch-jira-users/",
  downloadDefectTemplate: "template-defect/",
  exportDefect: "export-defect/",
  importDefect: "import-defect/",
  getDefectbyId: "defect-retrieve/",
  availableStatus: "available_statuses/",
  availablePriority: "available_prority/",
  syncDefects: "plane-sync-update/",
  syncDefectsJira: "jira-sync-update/",
}

const testCycle = {
  getAllTestCycleData: "testcycle-details/",
  getTestCycleId: "generate-test-cycle-id/",
  createTestCycle: "testcycle-create/",
  editTestCycle: "testcycle-update/",
  deleteTestCycle: "testcycle-delete/",
  bulkDeleteTestCycle: "testcycle-bulk-delete/",
  viewTestCycle: "testcycle-view/",
  executeTestCycle: "test-execution-create/",
  getAllExecutionData: "test-execution/",
  testCycleSummary: "test-excution-summary/",
  updateExecute: "test-execution-update/",
  triggerWorkflow: "trigger-workflow/",
  bulkExecute: "test-execution/bulk-update/",
  fetchos: "fetch-devices-os/",
  fetchweb: "fetch-web-browsers/",
  executionMappings: "execution-mapping-details/",
  scriptStatus: "script-status-percentage/",
  executeRetrieve: "test-execution-retrieve/",
  execution_script_mappings: "execution-script-mapping/",
  testData: {
    getAllTestData: "testdata-view/",
    delete: "testdata-delete/",
    edit: "testdata-update/",
    create: "testdata-create/",
    bulkDelete: "testdata-bulk-delete/",
    importTestData: "import-testdata/",
    testDataById: "testdata-retrieve/",
  },
  dbConnections: {
    getdbConnections: "dbconnections-details/",
    createDbIntegrations: "dbconnection-create/",
    editDbIntegrations: "dbconnection-update/",
    deleteIntegrations: "dbconnection-delete/",
    BulkdeleteIntegrations: "dbconnection-bulk-delete/",
    retrieve: "dbconnection-retrieve/",
  },
  AutomationSuite: {
    getAllAutoSuite: "automationsuite-details/",
    getAutoSuiteID: "generate-automationsuite-id/",
    getAutomationSuiteById: "automationsuite-retrieve/",
    createAutoSuite: "automationsuite-create/",
    updateAutoSuite: "automationsuite-update/",
    delteAutoSuite: "automationsuite-delete/",
    bulkDelete: "automationsuite-bulk-delete/",
  },
  AutomationScript: {
    getAllAutomationScripts: "automationscript-details/",
    webActions: "webactions-details/",
    createAutomationScript: "add-automation-script/",
    updateAutomationScript: "automationscript-update/",
    deleteAutoScript: "automationscript-delete/",
    bulkDeleteAutoScript: "automationscript-bulk-delete/",
    getAutomationSciptId: "generate-automationscript-id/",
    viewAutomationScript: "automationscript-view/",
    dbConnections: "db-connection-autoscript/",
  },
  agentCreation: {
    getAgents: "agent-details/",
    createAgent: "agent-create/",
    editAgent: "agents/",
    deleteAgent: "agents/delete/",
    repoToken: "github-repo-runner/",
  },
}

const testPlan = {
  getALlTestData: "testplan-details/",
  create: "testplan-create/",
  delete: "testplan-delete/",
  getPlanById: "testplan-retrieve/",
  update: "testplan-update/",
  testPlanStatus: "testplan-cases-status/",
  generateTestPlanID: "generate-test-plan-id/",
  testPlanDefectStatus: "test-plan-defect-status/",
  testPlanDefectList: "test-plan-defect-list/",
  testPlanSignoff: "test-plan-signoff/",
  testPlanResponsibleResources: "test-plan-responsible-resources/",
  testPlanResult: "test-plan-result/",
  testPlanResultChart: "test-plan-result-chart/",
  testPlanSummaryReports: "testplan-summary-reports/",
  testPlanSummaryReportsAdd: "testplan-summary-reports-add/",
  testPlanSummaryReportsEdit: "testplan-summary-reports-edit/",
  testPlanSummaryReportsEdit: "testplan-summary-reports-edit/",
  testPlanPdf: "test-plan-pdf/",
  testPlanSummaryReportsHistory: "testplan-summary-reports-history/",
}
//meta
const meta = {
  meta: "meta-details/",
  createnew: "meta-create/",
  editmeta: "meta-update/",
  delete: "meta-delete/",
  getTags: "tag-status/",
  retrieve: "meta-retrieve/",
}

// users
const users = {
  users: "teams-details/",
  usersTeamList: "teams-details-users-list/",
  teamDetailsUser: "teams-details-user/",
  create: "teams-create/",
  edit: "teams-update/",
  delete: "teams-delete/",
  userDetailsList: "users-details-list/",
  teamsDetailsUserassignee: "teams-details-userassignee/",
  retrieve: "teams-retrieve/",
}

const notifications = {
  editAuditHistory: "request-editaudithistory/",
  bulkEditAuditHistory: "request-bulk-editaudithistory/",
  notifyBulk: "requestnotification-flag-bluk/",
  requestDetails: "request-details/",
  notificationDetailsTC: "requestnotification-details-testcase/",
  notificationDetailsTP: "requestnotification-details-testplan/",
  notificationDetailsViewTC: "requestnotification-view/",
  notificationDetailsViewTP: "requestnotification-testplan-view/",
}

const integration = {
  integration: "intergration-details/",
  create: "intergration-create/",
  update: "intergration-update/",
  delete: "intergration-delete/",
  integrationView: "intergration-view/",
  mapProjectDetails: "map-project-details/",
  mapProjectCreate: "map-project-create/",
  mapProjectDelete: "map-project-project-delete/",
  fetchJiraProjects: "fetch-jira-project/",
  gitRepo: "github-repositories/",
  fetchPlaneProject: "fetch-plane-project/",
  getBrowserStack: "browser-stact-details/",
  browserStackCreate: "browser-stact-create/",
  browserStackDelete: "browser-stact-delete/",
  browserStackUpdate: "browser-stact-update/",
  fetchCustomField: "fetch-jira-fields/",
  mapProjectUpdate: "map-project-update/",
  createRepo: "github-repo-creation/",
}

const roles = {
  roles: "roles-details/",
  create: "roles-create/",
  edit: "roles-update/",
  delete: "roles-delete/",
  createPermissions: "permissions-create/",
  retreive: "roles-retrieve/",
}

const dashboard = {
  globalDashboard: {
    getDefectsDetails: "globaldashboard-defects-type/",
    getDefectsPriority: "globaldashboard-defects-priority/",
    globaldashboardDefectsStatus: "globaldashboard-defects-status/",
    globalDashboardSeverity: "globaldashboard-defects-severity/",
    globalDashboardAllSeverity: "globaldashboard-allprojects-defects-severity/",
    getDefectsAllPriority: "globaldashboard-allprojects-defects-priority/",
    globaldashboardDefectsAllStatus:
      "globaldashboard-allprojects-defects-status/",
    summery: "globaldashboard-defects-summary/",
    globalDashboardPartitionUser: "globaldashboard-participation-user/",
    globalDashboardProjectBreakDown: "globaldashboard-project-type/",
    globalDashboardRequirementBreakDown: "globaldashboard-requirement-type/",
  },
  projectDashboard: {
    SeverityDefects: "projectdashboard-severity-defects/",
    StatusDefects: "projectdashboard-status-defects/",
    dashboardSummary: "project-dashboard-stats/",
    testCaseStatus: "projectdashboard-testcase-status/",
    testCaseExecute: "projectdashboard-testcase-executed/",
    UsersListForProjectDashbord: "projectdasboard-users-details-list/",

    ProjectDashboardTestCaseFail: "projectdashboard-test-cases-fail/",
    ProjectdashboardDefectAging: "projectdashboard-defect-aging/",
    ProjectdashboardActiveDefect: "projectdashboard-active-defect/",
    ProjectdashboardDefectRepened: "projectdashboard-defect-Repened/",
    ProjectdashboardDefectStatistics: "projectdashboard-defect-statistics/",
    ProjectdashboardDefectClosureEfficiency:
      "projectdashboard-defect-closure-efficiency/",
    ProjectdashboardDefectWeekWise: "projectdashboard-defect-weekwise/",
    ProjectdashboardDefectTypeCount: "projectdashboard-defect-type-count/",
    ProjectdashboardMilestoneTracking: "projectdashboard-milestone-tracking/",
  },
}

const reports = {
  reports: {
    getAllReportsTemplate: "report-details/",
    saveTemplate: "report-create-template/",
    deleteReportTemplate: "report-delete-template/",
    ReportTemplatesList: "report-template-list/",
    GetSingleReportTemplate: "report-template-retrieve/",
    bulkDelete: "report-bulk-delete-template/",
  },
  defectReports: {
    generateDefectReports: "generate-reports-defect/",
    download: "reports-defect/",
  },
  testExecutionReport: {
    generateTestExecutionReports: "generate-test-execution/",
    download: "reports-testexecution/",
  },
  testCaseReport: {
    generateTestCaseReports: "generate-reports-testcase/",
    download: "reports-testcase/",
  },
  requirementTraceability: {
    generateRequirementTraceabitlityReports: "generate-reports-requirement/",
    download: "reports-requirement-traceabitlity/",
  },
  scheduleReport: {
    get: "scheduled-report-details/",
    delete: "scheduled-report-delete/",
    create: "scheduled-report/",
  },
  Cycle: {
    testCycleResults: "projectdasboard-cycle-comparison/",
    cycleComparison: "projectdashboard-cycle-comparison-testcase-results/",
  },
}

export {
  validate,
  projectManagement,
  testManagement,
  defectManagement,
  meta,
  users,
  roles,
  dashboard,
  integration,
  testCycle,
  testPlan,
  reports,
  notifications,
}
