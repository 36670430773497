import { toast } from "react-toastify"
import httpInjectorService from "services/http-Injector.Service"
import { setLoading } from "store/Slices/LoadingSlice"

export const BulkDeleteReportsTemplatesAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (bodyoption[key] !== undefined && bodyoption[key] !== null) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.BulkDeleteReportsTemplates(
        formData
      )
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Reports Deleted successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      } else {
        toast.error(response.message, {
          position: "top-right",
          id: "error",
          autoClose: 3000,
        })
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const deleteSavedTemplateAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.deleteSavedTemplate(bodyoption)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Defect Deleted successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      } else {
        toast.error(response.message, {
          position: "top-right",
          id: "error",
          autoClose: 3000,
        })
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const saveAsTemplateAPI = bodyOptions => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    formData.append("template_name", bodyOptions.template_name)
    formData.append("columns_data", bodyOptions.columns_data)
    formData.append("project_id", bodyOptions.project_id)
    formData.append("report_type", bodyOptions.report_type)

    formData.append("filters_data", JSON.stringify(bodyOptions.filters_data))
    try {
      const response = await httpInjectorService.saveAsTemplate(formData)

      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Report created successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      } else {
        toast.error(response.message, {
          position: "top-right",
          id: "Report created successfully",
          autoClose: 3000,
        })
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const GetSingleReportTemplateAPI = id => {
  return async dispatch => {
    dispatch(setLoading(true))

    try {
      const response = await httpInjectorService.GetSingleReportTemplate(id)
      const data = response
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const fetchAllReportsTemplateAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.getAllReporstTemplate(
        queryString
      )
      const data = response
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const getDefectReportsDataAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.getDefectReportsData(
        queryString
      )
      const data = response
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const getTestCaseReportsDataAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.getTestCaseData(queryString)
      const data = response
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const getTestExecutionReportsDataAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.getTestExecutionData(
        queryString
      )
      const data = response
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const getRequirementTraceabilityReportsDataAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.getRequirementTraceabilityData(
        queryString
      )
      const data = response
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const downloadDefectReportsAPI = bodyOption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyOption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    try {
      // Assuming httpInjectorService.downloadDefectReports returns a promise
      const response = await httpInjectorService.downloadDefectReports(
        queryString
      )

      try {
        const blob = response.data
        if (!blob) {
          throw new Error("No file data available.")
        }

        const downloadLink = document.createElement("a")
        const url = window.URL.createObjectURL(blob)
        downloadLink.href = url
        downloadLink.download = "Defect Report" // Set the file name and extension
        document.body.appendChild(downloadLink)

        // Trigger the download
        downloadLink.click()

        // Clean up
        window.URL.revokeObjectURL(url) // Release the URL object
        document.body.removeChild(downloadLink)
      } catch (error) {
        console.error("Error handling file download:", error.message)
      }

      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      console.error("Error downloading the report:", error.message)
    }
  }
}
export const downloadTestCaseReportsAPI = bodyOption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyOption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    try {
      // Assuming httpInjectorService.downloadDefectReports returns a promise
      const response = await httpInjectorService.downloadTestCaseReports(
        queryString
      )

      try {
        const blob = response.data
        if (!blob) {
          throw new Error("No file data available.")
        }

        const downloadLink = document.createElement("a")
        const url = window.URL.createObjectURL(blob)
        downloadLink.href = url
        downloadLink.download = "Test Case Report" // Set the file name and extension
        document.body.appendChild(downloadLink)

        // Trigger the download
        downloadLink.click()

        // Clean up
        window.URL.revokeObjectURL(url) // Release the URL object
        document.body.removeChild(downloadLink)
      } catch (error) {
        console.error("Error handling file download:", error.message)
      }

      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      console.error("Error downloading the report:", error.message)
    }
  }
}
export const downloadTestExecutionReportsAPI = bodyOption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyOption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    try {
      // Assuming httpInjectorService.downloadDefectReports returns a promise
      const response = await httpInjectorService.downloadTestExecutionReports(
        queryString
      )

      try {
        const blob = response.data
        if (!blob) {
          throw new Error("No file data available.")
        }

        const downloadLink = document.createElement("a")
        const url = window.URL.createObjectURL(blob)
        downloadLink.href = url
        downloadLink.download = "Test execution report" // Set the file name and extension
        document.body.appendChild(downloadLink)

        // Trigger the download
        downloadLink.click()

        // Clean up
        window.URL.revokeObjectURL(url) // Release the URL object
        document.body.removeChild(downloadLink)
      } catch (error) {
        console.error("Error handling file download:", error.message)
      }

      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      console.error("Error downloading the report:", error.message)
    }
  }
}
export const downloadRequirementTraceabilityReportsAPI = bodyOption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyOption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    try {
      // Assuming httpInjectorService.downloadDefectReports returns a promise
      const response =
        await httpInjectorService.downloadRequirementTraceabilityReports(
          queryString
        )

      try {
        const blob = response.data
        if (!blob) {
          throw new Error("No file data available.")
        }

        const downloadLink = document.createElement("a")
        const url = window.URL.createObjectURL(blob)
        downloadLink.href = url
        downloadLink.download = "Requirement Traceability Report" // Set the file name and extension
        document.body.appendChild(downloadLink)

        // Trigger the download
        downloadLink.click()

        // Clean up
        window.URL.revokeObjectURL(url) // Release the URL object
        document.body.removeChild(downloadLink)
      } catch (error) {
        console.error("Error handling file download:", error.message)
      }

      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      console.error("Error downloading the report:", error.message)
    }
  }
}
export const fetchAllScheduleReportsList = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.getAllScheduleReportAPI(
        queryString
      )
      const data = response
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const deleteScheduleReportAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.deleteScheduleReport(
        bodyoption
      )
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Deleted successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      } else {
        toast.error(response.message, {
          position: "top-right",
          id: "error",
          autoClose: 3000,
        })
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const createScheduleReportAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.createScheduleReport(formData)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "created successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
