import React from "react"
import { FaFilePdf } from "react-icons/fa"
import {
  Table,
  Card,
  CardBody,
  CardHeader,
  UncontrolledTooltip,
} from "reactstrap"
import jsPDF from "jspdf"
import "jspdf-autotable"
// // Function to calculate percentage
// const calculatePercentage = (part, total) => {
//   return total > 0 ? ((part / total) * 100).toFixed(2) : "0.00"
// }

const CycleComparisonTable = ({ data }) => {
  const downloadPDFOfresults = () => {
    const doc = new jsPDF()

    // Custom header for every page
    const header = data => {
      doc.setFont("helvetica", "normal")
      doc.setFontSize(14)
      doc.setTextColor(40)
      doc.text("Test Cycle Comparison Report", data.settings.margin.left, 10)
    }

    const tableColumn = [
      "Cycle Status",
      "Total Cases",
      "Not Run",
      "Failed",
      "Passed",
    ]
    const tableRows = []

    data?.forEach(item => {
      const rowData = [
        item?.test_cycle_name,
        Math.round(item?.total_test_cases),
        Math.round(item?.not_run_test_cases),
        Math.round(item?.failed_test_cases),
        Math.round(item?.passed_test_cases),
      ]
      tableRows.push(rowData)
    })

    // Create the table in the PDF
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: {
        fontSize: 10,
        cellPadding: 3,
      },
      headStyles: {
        fillColor: [0, 0, 128],
        textColor: [255, 255, 255],
      },
      theme: "grid",
      showHead: "everyPage",
      didDrawPage: data => {
        // Call the header function on every page
        header(data)
      },
    })

    // Save the PDF with a filename
    doc.save("results_test_cycle.pdf")
  }

  return (
    <div style={{ marginTop: "40px" }}>
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <h4>Test Cycle Comparison Report</h4>
          <div
            onClick={downloadPDFOfresults}
            id="resultDownload"
            className="cursor-pointer"
          >
            {" "}
            <FaFilePdf
              className="export-icon pdf"
              style={{
                color: "#D13438",
                height: "1.5rem",
                width: "1.5rem",
                cursor: "pointer",
              }}
            />
          </div>
          <UncontrolledTooltip placement="top" target="resultDownload">
            Download as PDF
          </UncontrolledTooltip>
        </CardHeader>
        <CardBody>
          <div style={{ maxHeight: "300px", overflowY: "auto" }}>
            {data?.length > 0 ? (
              <Table hover striped>
                <thead
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "#ffffff",
                    zIndex: 2,
                    borderCollapse: "collapse",
                  }}
                >
                  <tr>
                    <th>Test Cycle Name</th>
                    <th>Total Test Cases</th>
                    <th>Passed Test Cases</th>
                    <th>Failed Test Cases</th>
                    <th>Not Run Test Cases</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(item => (
                    <tr key={item.test_cycle_id}>
                      <td>{item.test_cycle_name}</td>
                      <td>{item.total_test_cases}</td>
                      <td>
                        {item.passed_test_cases}
                        {/* (
                      {calculatePercentage(
                        item.passed_test_cases,
                        item.total_test_cases
                      )}
                      %) */}
                      </td>
                      <td>
                        {item.failed_test_cases}
                        {/* (
                      {calculatePercentage(
                        item.failed_test_cases,
                        item.total_test_cases
                      )}
                      %) */}
                      </td>
                      <td>
                        {item.not_run_test_cases}
                        {/* (
                      {calculatePercentage(
                        item.not_run_test_cases,
                        item.total_test_cases
                      )}
                      %) */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p className="text-center">No Record Found</p>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default CycleComparisonTable
