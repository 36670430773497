import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import "flatpickr/dist/themes/material_blue.css"
import JoditEditor from "jodit-react"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import { createOptions, joditConfig } from "../../../../helpers/helper"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import Breadcrumb from "components/Common/Breadcrumb"
import { fetchAllBuildsList } from "store/actions/BuildsActions"
import { fetchAllModulesList } from "store/actions/ModuleActions"
import { fetchAllRequirementsList } from "store/actions/RequirementActions"
import { fetchAllTestScenarioList } from "store/actions/TestScenarioActions"
import {
  CreateTestCase,
  EditTestCase,
  fetchAllPreConditon,
  fetchNextCaseID,
} from "store/actions/TestCaseAction"
import { setEditTestCase } from "store/Slices/TestCaseSlice"
import { getTagsStautusAPI } from "store/actions/TagsAction"
import {
  fetchAllTeamsDetailsUserAssigneeList,
  // fetchUsersList,
} from "store/actions/UserActions"

const CreateTestCaseForm = () => {
  const [buildOptionValue, setBuildOptionValues] = useState([])
  const [moduleOptionValue, setModuleOptionValues] = useState([])
  const [requirementOptionValue, setRequirementOptionValues] = useState([])
  const [testScenarioOptionValue, setTestScenarioOptionValues] = useState([])
  const [reqTSOptionValue, setReqTSOptionValues] = useState([])
  const [UserListOptionValue, setUserListOptionValues] = useState([])
  const [PreCondtionOptionValue, setPreCondtionOptionValues] = useState([])
  const currentUrl = window.location.href
  const parts = currentUrl.split("/")
  const [nextTestCase, setNextCaseId] = useState("")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const loading = useSelector(state => state?.Loading?.isLoading)

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const config = joditConfig()
  const stripHtmlTags = html => {
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent || ""
  }

  const editTestCase = useSelector(state => state?.TestCase?.editTestCase)

  const isEditMode = editTestCase?.isEdit
  const isEditModeData = editTestCase?.data

  const layoutModeType = useSelector(state => state.Layout.layoutModeType)

  const [summary, setDescription] = useState(
    isEditMode ? isEditModeData?.summary : null
  )
  const [post_condition, setPostCondition] = useState(
    isEditMode ? isEditModeData?.post_condition : null
  )
  const [new_pre_condition, setNewPreCondition] = useState(
    isEditMode ? isEditModeData?.new_pre_condition : null
  )
  const [detail_steps, setDetailSteps] = useState(
    isEditMode ? isEditModeData?.detail_steps : null
  )
  const [expected_result, setExpectedResult] = useState(
    isEditMode ? isEditModeData?.expected_result : null
  )
  const [comments, setComments] = useState(
    isEditMode ? isEditModeData?.comments : null
  )
  const [isDescriptionInvalid, setIsDescriptionInvalid] = useState(false)
  const [isDescriptionError, setIsDescriptionError] = useState("")
  const [selectedValues, setSelectedValues] = useState(null)
  const [selectedLabel, setSelectedLabel] = useState(
    isEditMode ? isEditModeData?.pre_condition : null
  )
  const [preConditionIdValues, setPreConditionIdValues] = useState([])

  const breadcrumbItems = [
    { label: "Test Case", path: "/TestManagement/TestCase" },
    {
      label: isEditMode ? "Edit Test Case " : "Create Test Case",
      path: "/",
    },
  ]

  const checkValidations = () => {
    const errors = {};

    const summaryText = stripHtmlTags(summary || "").trim();
    if (summaryText.length < 1) {
      errors.summary = "Summary is required";
    }

    const detailStepsText = stripHtmlTags(detail_steps || "").trim();
    if (detailStepsText.length < 1) {
      errors.detailStep = "Detail Steps are required";
    }

    const expectedResultText = stripHtmlTags(expected_result || "").trim();
    if (expectedResultText.length < 1) {
      errors.expectedResult = "Expected Result is required";
    }

    if (formik.values.test_status === "Pending Approval" && !formik.values.approval_from) {
      errors.approval_from = "Request Approval From is required";
    }

    return errors;
  };

  const handleAddPreConditionOnChnage = selectedOptions => {
    const updatedSelectedOptions = (selectedOptions || []).map(option => {
      const matchingPreCondition = PreCondtionOptionValue.find(
        item => item.value === option.value
      )

      return matchingPreCondition || option // Return the matching precondition or the original option if no match
    })
    setPreConditionIdValues(updatedSelectedOptions)
    setSelectedValues(updatedSelectedOptions.map(option => option.value))
    setSelectedLabel(updatedSelectedOptions.map(option => option.label))
  }
  const timeFormat = /^([01]\d|2[0-3]):([0-5]\d)$/;

  const validationSchema = Yup.object().shape({
    test_case_name: Yup.string().trim()
    .matches(/^.*\S.*$/, "Test Case Name cannot contain only spaces")
    .required("Test Case Name is required"),
    test_scenario_id: Yup.string().required("Test Scenario Id is required"),
    estimated_time: Yup.string()
    .notRequired() // Makes the field optional
    .matches(timeFormat, "Estimated time must be in HH:MM format")
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      project_id: globalProject?.value,
      test_case_id: isEditModeData
        ? isEditModeData?.test_case_id
        : nextTestCase,
      build_id: isEditModeData ? isEditModeData?.build_id || null : null,
      module_id: isEditModeData ? isEditModeData?.module_id || null : null,
      requirement_id: isEditModeData
        ? isEditModeData?.requirement_id || null
        : null,
      test_case_name: isEditModeData
        ? isEditModeData?.test_case_name || null
        : null,
      summary: isEditModeData ? isEditModeData?.summary || null : null,
      test_scenario_id: isEditModeData
        ? isEditModeData?.test_scenario_id || null
        : null,
      test_status: isEditModeData ? isEditModeData?.test_status || "New": "New",
      priority: isEditModeData ? isEditModeData?.priority || null : null,
      testing_type: isEditModeData ? isEditModeData?.testing_type || null : null,
      estimated_time: isEditModeData
        ? isEditModeData?.estimated_time || null
        : null,
      execution_type: isEditModeData
        ? isEditModeData?.execution_type || null
        : null,
      assign_to: isEditModeData ? isEditModeData?.assign_to || null : null,
      approval_from: isEditModeData ? isEditModeData?.approval_from || null : null,
    },
    validationSchema,
    onSubmit: values => {
      if (
        !stripHtmlTags(summary) ||
        !stripHtmlTags(detail_steps) ||
        !stripHtmlTags(expected_result) || (formik.values.test_status === "Pending Approval" && !formik.values.approval_from)
      ) {
        setIsDescriptionInvalid(true)
        const validationErrorMsg = checkValidations()
        setIsDescriptionError(validationErrorMsg)
        return
      } else {
        const formData = {
          ...values,
          test_status: formik.values.approval_from
            ? "Pending Approval"
            : formik.values.test_status?formik.values.test_status:'New',
          summary,
          pre_conditions_id: selectedValues?.length !== 0 ? selectedValues : [],
          pre_condition: selectedLabel?.length !== 0 ? selectedLabel : [],
          comments,
          detail_steps,
          expected_result,
          new_pre_condition,
          post_condition,
        }
        createTestScenario(formData)
      }
    },
  })

  const createTestScenario = async formData => {
    const editFromValues = {
      ...formData,
      id: isEditModeData?.id,
    }

    if (!isEditModeData) {
      try {
        await dispatch(CreateTestCase(formData))
        navigate(-1) // Navigate back or handle success
      } catch (error) {
        console.error("Error creating test case:", error)
        toast.error(
          error?.message || "An error occurred while creating Test Case",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          }
        )
      }
    } else {
      try {
        await dispatch(EditTestCase(editFromValues))
        navigate(-1) // Navigate back or handle success
        dispatch(setEditTestCase({ isEdit: false, data: null }))
      } catch (error) {
        console.error("Error updating test case:", error)
        toast.error(
          error?.message || "An error occurred while updating Test Case",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          }
        )
      }
    }
  }

  useEffect(() => {
    if (
      isEditMode &&
      isEditModeData?.pre_conditions_id &&
      PreCondtionOptionValue
    ) {
      const selectedPreConditionIds = isEditModeData.pre_conditions_id
      const selectedPreConditionValues = selectedPreConditionIds.map(id => {
        const matchingPreCondition = PreCondtionOptionValue.find(
          item => item.value === id
        )
        return {
          value: id,
          label: matchingPreCondition?.label,
          description: matchingPreCondition?.description,
        }
      })
      setPreConditionIdValues(selectedPreConditionValues)
    }
  }, [isEditModeData, PreCondtionOptionValue])
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const responses = await Promise.all([
          dispatch(
            fetchAllBuildsList({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          dispatch(
            fetchAllModulesList({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          dispatch(
            fetchAllRequirementsList({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          dispatch(
            fetchAllTestScenarioList({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          dispatch(
            fetchNextCaseID({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          // dispatch(fetchUsersList({ project_id: globalProject?.value || selectedProject?.value })),
          dispatch(
            fetchAllTeamsDetailsUserAssigneeList({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          dispatch(fetchAllPreConditon({ project_id: globalProject?.value })),
        ])

        const [
          buildResponse,
          moduleResponse,
          reqResponse,
          testScenarioResponse,
          nextIdResponse,
          userListResponse,
          preConditionResponse,
        ] = responses

        setBuildOptionValues(
          buildResponse?.map(entry => ({
            value: Math.round(entry.id),
            label: entry.build_id,
          }))
        )

        setModuleOptionValues(
          moduleResponse?.map(entry => ({
            value: entry.id,
            label: entry.module_name,
          }))
        )

        setRequirementOptionValues(
          reqResponse?.map(entry => ({
            value: entry.id,
            label: entry.requirement_title,
          }))
        )
        setUserListOptionValues(
          userListResponse?.results?.map(entry => ({
            value: entry.id,
            label: entry.user_name,
          }))
        )

        setPreCondtionOptionValues(
          preConditionResponse?.map(entry => ({
            value: entry.id,
            label: entry.condition_name,
            description: entry?.pre_condition_details,
          }))
        )

        await setNextCaseId(nextIdResponse?.next_test_case_id)
        setReqTSOptionValues(testScenarioResponse)
      } catch (error) {
        console.error("Failed to fetch options", error)
        toast.error("Failed to fetch options.")
      }
    }
    if (globalProject?.value) {
      fetchOptions()
    }
  }, [globalProject, dispatch])

  useEffect(() => {
    const scenarioOptions = []

    reqTSOptionValue.forEach(entry => {
      if (formik.values.requirement_id) {
        if (entry.requirement_id === formik.values.requirement_id) {
          scenarioOptions.push({
            value: entry.id,
            label: `${entry.test_scenario_id} - ${entry?.test_scenario_name}`,
          })
        }
      } else {
        scenarioOptions.push({
          value: entry.id,
          label: `${entry.test_scenario_id} - ${entry?.test_scenario_name}`,
        })
      }
    })
    setTestScenarioOptionValues(scenarioOptions)
  }, [formik.values.requirement_id, reqTSOptionValue])

  const handleTestScenarioId = option => {
    formik.setFieldValue("requirement_id", option?.value || "")
    formik.setFieldValue("test_scenario_id", "")
  }
  
  const submitData = () => {
    const isSummaryEmpty = !stripHtmlTags(summary || "").trim();
    const isDetailStepsEmpty = !stripHtmlTags(detail_steps || "").trim();
    const isExpectedResultEmpty = !stripHtmlTags(expected_result || "").trim();
    const isApprovalRequired = formik.values.test_status === "Pending Approval" && !formik.values.approval_from;

    if (isSummaryEmpty || isDetailStepsEmpty || isExpectedResultEmpty || isApprovalRequired) {
      setIsDescriptionInvalid(true);
      const validationErrorMsg = checkValidations();
      setIsDescriptionError(validationErrorMsg);
    }
  };


  useEffect(() => {
    if (isEditModeData) {
      setDescription(isEditModeData.summary || "")
    }
  }, [isEditModeData])

  const [testingTypeOptions, setTestingTypeOptions] = useState([])
  const [testingStatusOptions, setTestingStatusOptions] = useState([])
  const [priorityOptions, setPriorityOptions] = useState([])
  const [executionOptions, setExecutionOptions] = useState([])

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const bodyOption = { project_id: globalProject?.value }
        const metaDataResponse = await dispatch(getTagsStautusAPI(bodyOption))

        const { Testing_Type, Test_Status, Priority, Execution_Type } =
          metaDataResponse?.data || {}
        setTestingTypeOptions(createOptions(Testing_Type || []))
        setTestingStatusOptions(createOptions(Test_Status || []))
        setPriorityOptions(createOptions(Priority || []))
        setExecutionOptions(createOptions(Execution_Type || []))
      } catch (error) {
        console.log(error)
      }
    }

    fetchOptions()
  }, [])

  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888",
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Ensure input text is visible
    }),
    multiValue: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#3a3f51" : "#e0e0e0", // Dark background for selected items
    }),
    // Customize multiValueLabel (selected item text color)
    multiValueLabel: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Adjust text color
    }),
    // Customize multiValueRemove (cross icon for removing selected items)
    multiValueRemove: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
      ":hover": {
        backgroundColor: layoutModeType === "dark" ? "#ff4949" : "#e0e0e0", // Hover effect for remove icon
        color: "#fff",
      },
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150, // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }

  return (
    <React.Fragment>
      <div className={parts.includes("TestManagement") ? "" : "page-content"}>
        <Container fluid>
          <Breadcrumb title="Test Case" breadcrumbItems={breadcrumbItems} />

          <div className="mt-2 mx-3 d-flex justify-content-between">
            <div>
              <h3>{!isEditMode ? "Create New Test Case" : "Edit Test Case"}</h3>
              <p className="text-muted">
                Fill the form to {!isEditMode ? "create" : "edit"} a test case
              </p>
            </div>
            <div> 
              <Button
                type="button"
                color="primary"
                className="btn-label"
                onClick={() => {
                  navigate(-1)
                  dispatch(setEditTestCase({ isEdit: false, data: null }))
                }}              >
                <i className="bx bx-arrow-back label-icon"></i> Go Back
              </Button>
            </div>
          </div>

          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <div className="mb-3">
                      <Label className="form-label">Project</Label>
                      <Input
                        name="project_id"
                        type="text"
                        placeholder="Project"
                        value={globalProject?.label}
                        className="custom-input"
                        readOnly
                        disabled
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Test Case Id</Label>
                      <Input
                        name="test_case_id"
                        type="text"
                        placeholder="Test Case Id"
                        value={formik.values.test_case_id}
                        className="custom-input"
                        readOnly
                        disabled
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">
                        Test Case Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="test_case_name"
                        type="text"
                        placeholder="Test Case Name"
                        onChange={formik.handleChange}
                        onBlur={() => formik.setFieldTouched("test_case_name")}
                        value={formik.values.test_case_name}
                        invalid={
                          formik.touched.test_case_name &&
                          !!formik.errors.test_case_name
                        }
                        className="custom-input"
                      />
                      {formik.touched.test_case_name &&
                        formik.errors.test_case_name && (
                          <FormFeedback>
                            {formik.errors.test_case_name}
                          </FormFeedback>
                        )}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">
                        Status
                      </Label>
                      <Select
                        // isClearable={formik.values.test_status && true}
                        name="test_status"
                        className="select2-selection"
                        styles={customStyles}
                        options={testingStatusOptions}
                        onChange={option => 
                          formik.setFieldValue("test_status", option?.value || "")
                        }
                        onBlur={() =>
                          formik.setFieldTouched("test_status", true)
                        }
                        value={
                          formik.values.test_status
                            ? {
                                value: formik.values.test_status,
                                label: formik.values.test_status,
                              }
                            : {
                                value: "",
                                label: (
                                  <span className="text-muted mt-2">
                                    Select..
                                  </span>
                                ),
                              }
                        }
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <div className="mb-3">
                      <Label className="form-label">Build</Label>
                      <Select
                        isClearable={true}
                        name="build_id"
                        className="select2-selection"
                        options={buildOptionValue}
                        styles={customStyles}
                        onChange={option =>
                          formik.setFieldValue("build_id", option?.value || "")
                        }
                        onBlur={() => formik.setFieldTouched("build_id")}
                        value={buildOptionValue?.find(
                          option => option.value === formik.values.build_id
                        )}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Module</Label>
                      <Select
                        isClearable={true}
                        name="module_id"
                        options={moduleOptionValue}
                        className="select2-selection"
                        styles={customStyles}
                        onChange={option =>
                          formik.setFieldValue("module_id", option?.value || "")
                        }
                        onBlur={() => formik.setFieldTouched("module_id")}
                        value={moduleOptionValue?.find(
                          option => option.value === formik.values.module_id
                        )}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Requirement</Label>
                      <Select
                        isClearable={true}
                        name="requirement_id"
                        className="select2-selection"
                        styles={customStyles}
                        options={requirementOptionValue}
                        onChange={option => handleTestScenarioId(option)}
                        onBlur={() => formik.setFieldTouched("requirement_id")}
                        value={requirementOptionValue?.find(
                          option =>
                            option.value === formik.values.requirement_id
                        )}
                      />
                    </div>
                    <div
                      className="mb-3"
                      title={
                        formik.values.requirement_id &&
                        "Test Scenario Id options will be based on the Requirement selected"
                      }
                    >
                      <Label className="form-label">
                        Test Scenario Id <span className="text-danger">*</span>
                      </Label>
                      <Select
                        name="test_scenario_id"
                        className="select2-selection"
                        styles={customStyles}
                        options={testScenarioOptionValue}
                        onChange={option =>
                          formik.setFieldValue(
                            "test_scenario_id",
                            option?.value
                          )
                        }
                        onBlur={() =>
                          formik.setFieldTouched("test_scenario_id")
                        }
                        value={
                          testScenarioOptionValue?.find(
                            option =>
                              option.value === formik.values.test_scenario_id
                          ) || null
                        }
                        invalid={
                          formik.touched.test_scenario_id &&
                          !!formik.errors.test_scenario_id
                        }
                      />
                      {formik.touched.test_scenario_id &&
                        formik.errors.test_scenario_id && (
                          <div className="text-danger required-field">
                            {formik.errors.test_scenario_id}
                          </div>
                        )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Col md="12">
                      <Label>
                        Summary <span className="text-danger">*</span>
                      </Label>
                      <JoditEditor
                        value={summary}
                        config={joditConfig()}
                        onBlur={newContent => setDescription(newContent)}
                        // onBlur={() => setIsDescriptionInvalid(!summary)}
                      />
                      {isDescriptionInvalid && (
                        <div className="text-danger required-field">
                          {isDescriptionError?.summary}
                        </div>
                      )}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Col md="12">
                      <Label>
                        Detail Steps <span className="text-danger">*</span>
                      </Label>
                      <JoditEditor
                        value={detail_steps}
                        config={joditConfig()}
                        onBlur={newContent => setDetailSteps(newContent)}
                        // onBlur={() => setIsDescriptionInvalid(!detail_steps)}
                      />
                      {isDescriptionInvalid && (
                        <div className="text-danger required-field">
                          {isDescriptionError?.detailStep}
                        </div>
                      )}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Col md="12">
                      <Label>
                        Expected Result <span className="text-danger">*</span>
                      </Label>
                      <JoditEditor
                        value={expected_result}
                        config={joditConfig()}
                        onBlur={newContent => setExpectedResult(newContent)}
                        // onBlur={() => setIsDescriptionInvalid(!expected_result)}
                      />
                      {isDescriptionInvalid && (
                        <div className="text-danger required-field">
                          {isDescriptionError?.expectedResult}
                        </div>
                      )}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <div className="mb-3">
                      <Label className="form-label">Priority</Label>
                      <Select
                        isClearable={true}
                        name="priority"
                        className="select2-selection"
                        options={priorityOptions}
                        onChange={option =>
                          formik.setFieldValue("priority", option?.value || "")
                        }
                        onBlur={() => formik.setFieldTouched("priority")}
                        styles={customStyles}
                        value={priorityOptions?.find(
                          option => option.value === formik.values.priority
                        )}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Testing Type</Label>
                      <Select
                        isClearable={true}
                        name="testing_type"
                        className="select2-selection"
                        options={testingTypeOptions}
                        onChange={option =>
                          formik.setFieldValue("testing_type", option?.value || "")
                        }
                        styles={customStyles}
                        onBlur={() => formik.setFieldTouched("testing_type")}
                        value={testingTypeOptions?.find(
                          option => option.value === formik.values.testing_type
                        )}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Estimated Time</Label>
                      <Input
                        name="estimated_time"
                        type="text"
                        placeholder="HH:MM"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.estimated_time}
                        invalid={
                          formik.touched.estimated_time &&
                          !!formik.errors.estimated_time
                        }
                        className="custom-input"
                      />
                      {formik.errors.estimated_time && (
                          <FormFeedback>
                            {formik.errors.estimated_time}
                          </FormFeedback>
                        )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <div className="mb-3">
                      <Label className="form-label">Execution Type</Label>
                      <Select
                        isClearable={true}
                        name="execution_type"
                        className="select2-selection"
                        styles={customStyles}
                        options={executionOptions}
                        onChange={option =>
                          formik.setFieldValue("execution_type", option?.value || "")
                        }
                        onBlur={() => formik.setFieldTouched("execution_type")}
                        value={executionOptions?.find(
                          option =>
                            option.value === formik.values.execution_type
                        )}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">
                        Request Approval From {formik.values.test_status === "Pending Approval" &&<span className="text-danger">*</span>}
                      </Label>
                      <Select
                        isClearable={true}
                        name="approval_from"
                        className="select2-selection"
                        styles={customStyles}
                        options={UserListOptionValue}
                        onChange={option =>
                          formik.setFieldValue("approval_from", option?.value|| "")
                        }
                        onBlur={() => formik.setFieldTouched("approval_from")}
                        value={UserListOptionValue?.find(
                          option => option.value === formik.values.approval_from
                        )}
                        />
                        {isDescriptionInvalid && formik.values.test_status === "Pending Approval" && (
                        <div className="text-danger required-field">
                          {isDescriptionError?.approval_from}
                        </div>
                      )}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Assign To</Label>
                      <Select
                        isClearable={true}
                        name="assign_to"
                        className="select2-selection"
                        styles={customStyles}
                        options={UserListOptionValue}
                        onChange={option =>
                          formik.setFieldValue("assign_to", option?.value || "")
                        }
                        onBlur={() => formik.setFieldTouched("assign_to")}
                        value={UserListOptionValue?.find(
                          option => option.value === formik.values.assign_to
                        )}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="mb-3 col-5">
                      <Label className="form-label">Pre Condition</Label>
                      <Select
                        name="assign_to_id"
                        value={preConditionIdValues}
                        isMulti
                        closeMenuOnSelect={false}
                        label="Multi select"
                        onChange={handleAddPreConditionOnChnage}
                        options={PreCondtionOptionValue}
                        className="select2-selection"
                        styles={customStyles}
                      />
                    </div>
                    {preConditionIdValues
                      ? preConditionIdValues.map(item => (
                          <Col md="12" className="mb-2" key={item.value}>
                            <Label>{item?.label}</Label>
                            <JoditEditor
                              value={item?.description}
                              config={config}
                            />
                          </Col>
                        ))
                      : null}
                    <Col md="12">
                      <Label>Pre Condition</Label>
                      <JoditEditor
                        value={new_pre_condition}
                        config={config}
                        onBlur={newContent => setNewPreCondition(newContent)}
                      />
                    </Col>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Col md="12">
                      <Label>Post Condition</Label>
                      <JoditEditor
                        value={post_condition}
                        config={joditConfig()}
                        onBlur={newContent => setPostCondition(newContent)}
                      />
                    </Col>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Col md="12">
                      <Label>Comments</Label>
                      <JoditEditor
                        value={comments}
                        config={joditConfig()}
                        onBlur={newContent => setComments(newContent)}
                        // onBlur={() => setIsDescriptionInvalid(!comments)}
                      />
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="text-end mt-3">
                <Button color="primary" type="submit" onClick={submitData} disabled={loading}>
                  {loading ? "Loading..." : "Submit"}
                </Button>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => {
                    formik.resetForm()
                    navigate(-1)
                    dispatch(setEditTestCase({ isEdit: false, data: null }))
                  }}
                  className="ms-2"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreateTestCaseForm
