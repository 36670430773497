import httpInjectorService from "services/http-Injector.Service"
import { setLoading } from "store/Slices/LoadingSlice"

export const ExtendSessionAPI = bodyOption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    formData.append("email", bodyOption.email)
    formData.append("is_ad_user", bodyOption.is_ad_user)
    try {
      const response = await httpInjectorService.ExtendSession(formData)
      dispatch(setLoading(false))
      return response
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
