import React from "react"
import NoDataImage from "../../assets/images/NoData-image.svg"
const NoDataChart = () => {
  return (
    <div className="Container-NoData" style={{ height: "365px" }}>
      <div className="NoData-Image">
        <img src={NoDataImage} alt="No-Data" />
      </div>
      <div className="NoData-Statement">
        <p  >No data found</p>
        {/* <div className="NoData-Value">
          Please add <span style={{ fontWeight: '600', fontSize: '13px' }}>{data}</span>
        </div> */}
      </div>
    </div>
  )
}

export default NoDataChart
