import React from "react"
import ReactEcharts from "echarts-for-react"
import { useSelector } from "react-redux"

// Define the getChartColorsArray function directly in this file
const getChartColorsArray = dataColors => {
  return dataColors || ["#28a745", "#dc3545", "#ffc107", "#a82b22"]
}

const TestResultPieChart = ({ testPlanResultChartData }) => {
  // const testPlanResultChartData = [
  //   {
  //     test_case_total: 1000,
  //     ts_executed: 29,
  //     ts_passed: 90,
  //     ts_failed: 21,
  //     ts_not_run: 102,
  //     total_defect: 146,
  //   },
  // ]
  const dataColors = ["#24c221", "#e8f013", "#1a0ef0", "#b915d6", "#f70a31"] // Added a color for total defects

  // Access layout mode type from Redux to toggle between dark and light modes
  const layoutModeType = useSelector(state => state.Layout.layoutModeType)

  // Dynamic chart colors based on mode
  const isDarkMode = layoutModeType === "dark"

  const doughnutEChartColors = getChartColorsArray(dataColors)

  // Extract data from the response
  const {
    test_case_total,
    ts_passed,
    ts_failed,
    ts_not_run,
    total_defect,
    ts_executed,
  } = testPlanResultChartData[0] // Assuming data is an array with a single object

  const options = {
    backgroundColor: isDarkMode ? "#2a3042" : "#fff", // Dynamic background color
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
      backgroundColor: isDarkMode ? "#2a3042" : "#fff", // Tooltip background color
      textStyle: {
        color: isDarkMode ? "#ffffff" : "#000000", // Tooltip text color
      },
    },
    legend: {
      orient: "vertical",
      left: "left",
      bottom: "5%", // Reduce bottom margin for the legend
      data: ["Passed", "Failed", "Not Run", "Defects", "Executed"],
      textStyle: {
        color: isDarkMode ? "#ffffff" : "#8791af", // Legend text color
      },
      itemGap: 5, // Reduce gap between legend items
      itemWidth: 14,
      itemHeight: 14,
    },
    color: doughnutEChartColors,
    series: [
      {
        type: "pie",
        radius: ["0%", "40%"],
        label: {
          show: false, // Hide label for background
        },
      },
      {
        name: "Test Cases Results",
        type: "pie",
        radius: ["30%", "40%"],
        avoidLabelOverlap: false,
        label: {
          show: true,
          position: "outside",
          formatter: "{b}-\n{d}%",
          textStyle: {
            fontSize: "10",
            fontWeight: "normal",
            color: isDarkMode ? "#ffffff" : "#333", // Dynamic label color
          },
        },
        labelLine: {
          show: true,
          lineStyle: {
            color: isDarkMode ? "#ffffff" : "#333",
          },
        },
        data: [
          { value: ts_passed, name: "Passed" },
          { value: ts_failed, name: "Failed" },
          { value: ts_not_run, name: "Not Run" },
          { value: total_defect, name: "Defects" },
          { value: ts_executed, name: "Executed" },
        ],
        center: ["50%", "50%"],
        tooltip: {
          show: true, // Enable tooltip for this series
          formatter: "{b}: {c} ({d}%)", // Tooltip format
        },
      },
      {
        // Series for displaying total test cases in the center
        type: "pie",
        radius: ["100%", "100%"],
        tooltip: {
          show: false, // Disable tooltip for the total slice
        },
        label: {
          show: true,
          position: "center",
          formatter: `Total\n${test_case_total}`,
          textStyle: {
            fontSize: "16", // Font size for total
            fontWeight: "",
            color: isDarkMode ? "#ffffff" : "#000000",
          },
        },
        labelLine: {
          show: false, // No label line for total
        },
        data: [
          { value: 1, name: "Total", itemStyle: { color: "transparent" } },
        ],
      },
    ],
  }

  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={options} />
    </React.Fragment>
  )
}

export default TestResultPieChart
