// // utils/session.js

import { getToken } from "helpers/helper"

// import { jwtDecode } from "jwt-decode"

// // export const isSessionExpiringSoon = (bufferTimeInMinutes = 5) => {
// //   const loginDetails = JSON.parse(localStorage.getItem("authUser"))
// //   if (!loginDetails || !loginDetails.access_token) {
// //     return true
// //   }

// //   const decodedToken = jwtDecode(loginDetails.access_token)
// //   const tokenExpiry = decodedToken.exp * 1000 // Convert to milliseconds
// //   const currentTime = Date.now()
// //   const bufferTime = bufferTimeInMinutes * 60 * 1000 // Convert minutes to milliseconds

// //   return tokenExpiry - currentTime <= bufferTime
// // }

// // export const logoutUser = () => {
// //   localStorage.removeItem("authUser")
// //   // Additional cleanup if needed, e.g., clearing session storage or cookies
// // }
// // utils/session.js

// export const isSessionExpiringSoon = () => {
//   const expiryTime = getSessionExpiryTime() // Get the expiry time from the token or local storage
//   const warningTime = 5 * 60 * 1000 // 5 minutes in milliseconds
//   return expiryTime - Date.now() <= warningTime
// }

// export const getSessionExpiryTime = () => {
//   const authUser = JSON.parse(localStorage.getItem("authUser"))
//   const decodedToken = decodeJWT(authUser.access_token) // Decode JWT to get expiry time
//   return decodedToken.exp * 1000 // Convert to milliseconds
// }

// export const logoutUser = () => {
//   localStorage.removeItem("authUser")
//   // Any other cleanup actions like clearing Redux state
// }

// const decodeJWT = token => {
//   try {
//     const base64Url = token.split(".")[1]
//     const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
//     return JSON.parse(window.atob(base64))
//   } catch (e) {
//     return null
//   }
// }
// utils/session.js

// export const isSessionExpiringSoon = () => {
//   const expiryTime = getSessionExpiryTime()
//   const warningTime = 5 * 60 * 1000 // 5 minutes in milliseconds
//   return expiryTime - Date.now() <= warningTime
// }

// export const getSessionExpiryTime = () => {
//   const authUser = JSON.parse(localStorage.getItem("authUser"))
//   const decodedToken = decodeJWT(authUser?.access_token)
//   return decodedToken?.exp * 1000 // Convert to milliseconds
// }

// export const logoutUser = () => {
//   localStorage.removeItem("authUser")
//   // Any other cleanup actions like clearing Redux state
// }

// const decodeJWT = token => {
//   try {
//     const base64Url = token.split(".")[1]
//     const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
//     return JSON.parse(window.atob(base64))
//   } catch (e) {
//     return null
//   }
// }

// utils/session.js

// export const isSessionExpiringSoon = () => {
//   const expiryTime = getSessionExpiryTime()
//   const warningTime = 5 * 60 * 1000 // 5 minutes in milliseconds
//   return expiryTime - Date.now() <= warningTime
// }

// export const getSessionExpiryTime = () => {
//   const authUser = JSON.parse(localStorage.getItem("authUser"))
//   const decodedToken = decodeJWT(authUser?.access_token)
//   return decodedToken?.exp * 1000 // Convert to milliseconds
// }

// export const logoutUser = () => {
//   localStorage.removeItem("authUser")
//   // Any other cleanup actions like clearing Redux state
// }

// const decodeJWT = token => {
//   try {
//     const base64Url = token.split(".")[1]
//     const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
//     return JSON.parse(window.atob(base64))
//   } catch (e) {
//     return null
//   }
// }
// utils/session.js

export const isSessionExpiringSoon = () => {
  const expiryTime = getSessionExpiryTime()
  const warningTime = 5 * 60 * 1000 // 5 minutes in milliseconds
  return expiryTime - Date.now() <= warningTime
}

export const getSessionExpiryTime = () => {
  const authUser = getToken()
  const decodedToken = decodeJWT(authUser)
  return decodedToken?.exp * 1000 // Convert expiration time to milliseconds
}

export const logoutUser = () => {
  localStorage.removeItem("authUser")
  localStorage.removeItem("selectedProject")
  // Optionally clear Redux state, session storage, etc.
}

const decodeJWT = token => {
  try {
    const base64Url = token.split(".")[1]
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
    return JSON.parse(window.atob(base64))
  } catch (e) {
    return null
  }
}
