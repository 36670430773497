import React from "react"
import { FaFileExcel, FaFileCsv, FaFilePdf, FaFileWord } from "react-icons/fa"
import { Button, UncontrolledTooltip } from "reactstrap"

const ExportComponent = ({ downloadReport }) => {
  return (
    <div className="d-flex align-items-center mb-2 mt-3 mt-md-2">
      {/* <Button
        type="button"
        color="secondary"
        className="ms-2 me-2" // Small vertical and horizontal padding
        onClick={resetFilter}
      >
        Clear Filter
      </Button> */}

      <div className="d-flex align-items-center gap-3 ms-2">
        <FaFileExcel
          onClick={() => downloadReport("xlsx")}
          style={{ cursor: "pointer", fontSize: "24px", color: "#007bff" }}
          id="xlsx"
        />
        <UncontrolledTooltip placement="top" target="xlsx">
          Download as XLSX
        </UncontrolledTooltip>

        <FaFileCsv
          onClick={() => downloadReport("csv")}
          style={{ cursor: "pointer", fontSize: "24px", color: "#17a2b8" }}
          id="csv"
        />
        <UncontrolledTooltip placement="top" target="csv">
          Download as CSV
        </UncontrolledTooltip>

        <FaFilePdf
          onClick={() => downloadReport("pdf")}
          style={{ cursor: "pointer", fontSize: "24px", color: "#dc3545" }}
          id="pdf"
        />
        <UncontrolledTooltip placement="top" target="pdf">
          Download as PDF
        </UncontrolledTooltip>

        <FaFileWord
          onClick={() => downloadReport("doc")}
          style={{ cursor: "pointer", fontSize: "24px", color: "#ffc107" }}
          id="doc"
        />
        <UncontrolledTooltip placement="top" target="doc">
          Download as Word
        </UncontrolledTooltip>
      </div>
    </div>
  )
}

export default ExportComponent
