import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import NoDataChart from '../../../components/Common/NoDataChart';  

const ProjectBreakDown = () => {
  const globalDashboardProjectBreakdown = useSelector(
    state => state?.Dashboard?.GlobalDashboardProjectBreakdown?.project_details
  );

  const [chartState, setChartState] = useState({
    series: [
      {
        data: []
      }
    ],
    options: {
      legend: {
        show: false
      },
      chart: {
        height: 350,
        type: 'treemap',
        toolbar: {
          show: false  // Disabling the toolbar to remove the download option
        }
      },
      
      colors: ['#BCC5D2', '#E2E3A9', '#B2D4B7', '#F39A9B', '#D1D1D1', '#A5C1D8', '#B4C9A5', '#E0A97B', '#B9A1D9', '#D09BD6'],
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '40px',
          fontFamily: 'Arial, sans-serif',
          fontWeight: '600',
          color: '#000000',
        },
        formatter: function(text, opts) {
          const category = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].x;
          const count = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].y;
          return `${category}\n${count}`;
        }
      }
    },
  });

  useEffect(() => {
    if (globalDashboardProjectBreakdown?.length > 0) {
      const newSeries = globalDashboardProjectBreakdown.map(item => {
        const category = Object.keys(item)[0];
        const projects = item[category];
        return {
          x: category,  // Category name
          y: projects.length  // Size based on the number of projects
        };
      });

      setChartState(prevState => ({
        ...prevState,
        series: [{ data: newSeries }]
      }));
    }
  }, [globalDashboardProjectBreakdown]);

  if (!globalDashboardProjectBreakdown || globalDashboardProjectBreakdown.length === 0) {
    return <NoDataChart />;
  }

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={chartState.options}
          series={chartState.series}
          type="treemap"
          height={350}
        />
      </div>
    </div>
  );
};

export default ProjectBreakDown;
