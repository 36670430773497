import React, { useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

const Captcha = ({
  onCaptchaSuccess,
  numbers,
  setNumbers,
  isAscending,
  setIsAscending,
}) => { 

  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    let randomNumbers = [];
    do {
      randomNumbers = Array.from({ length: 3 }, () =>
        Math.floor(Math.random() * 100)
      );
    } while (isAscendingOrder(randomNumbers));

    setNumbers(randomNumbers);
    setIsAscending(false);
  };

  const isAscendingOrder = (arr) => {
    for (let i = 1; i < arr.length; i++) {
      if (arr[i] < arr[i - 1]) {
        return false;
      }
    }
    return true;
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(numbers);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setNumbers(items);

    // Check if numbers are in ascending order
    const sortedNumbers = [...items].sort((a, b) => a - b);
    setIsAscending(JSON.stringify(items) === JSON.stringify(sortedNumbers));
  };

  const handleSubmitLogin = (e) => {
    e.preventDefault();
    if (isAscending) {
      alert("Login successful!");
      if (onCaptchaSuccess) {
        onCaptchaSuccess();
      }
    } else {
      alert("Please arrange the numbers in ascending order to proceed.");
    }
  };

  const colors = ["#B4B4B8", "#E3E1D9", "#C7C8CC"]
  return (
    <>
      <form onSubmit={handleSubmitLogin}>
        <p
          style={{
            // fontSize: "0.9rem",
            // fontWeight: 500,
            // color: "#475467",
            marginBottom: "4px",
          }}
        >
          Sort numbers in ascending order to login
        </p>
        <div>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="numbers" direction="horizontal">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{ display: "flex" }}
                >
                  {numbers.map((number, index) => (
                    <Draggable
                      key={number.toString()}
                      draggableId={number.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            userSelect: "none",
                            minHeight: "40px",
                            backgroundColor: colors[index % colors.length],
                            color: "#000",
                            fontWeight: "500",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            textAlign: "center",
                            border: "1px solid #fff",
                            cursor: "move",
                            ...provided.draggableProps.style,
                          }}
                        >
                          {number}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </form>
    </>
  );
};

export default Captcha;
