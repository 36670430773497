import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Define the centerText plugin
const centerTextPlugin = {
  id: 'centerText',
  beforeDraw: function(chart) {
    const { width, height, ctx } = chart;
    ctx.restore();

    const centerX = width / 2;
    const centerY = height / 1.3; // Adjust this value to move the text above

    // Set font size and style for text1
    const fontSize1 = 30; // Font size for text1 in px
    ctx.font = `${fontSize1}px sans-serif`;
    ctx.textBaseline = "middle";
    ctx.fillStyle = chart.config.options.plugins.centerText.textColor; // Set text color from chart options

    const text1 = chart.config.options.plugins.centerText.totalCount;
    const textX1 = centerX - ctx.measureText(text1).width / 2;
    const textY1 = centerY - 20; // Adjust Y position to place text above the line
    ctx.fillText(text1, textX1, textY1);

    // Set font size and style for text2
    const fontSize2 = 16; // Font size for text2 in px
    ctx.font = `${fontSize2}px sans-serif`;

    const text2 = "TOTAL";
    const textX2 = centerX - ctx.measureText(text2).width / 2;
    const textY2 = centerY + 10; // Adjust Y position to place text below the first text
    ctx.fillText(text2, textX2, textY2);
    ctx.save();
  }
};

// Register chart.js plugins inside the component
Chart.register(...registerables, ChartDataLabels, centerTextPlugin);

const DountChart = ({ chartData, textColor, TotalCount }) => {
  const data = {
    labels: ["High", "Medium", "Low"], // You might want to make this dynamic as well
    datasets: [
      {
        data: chartData, // Use the chartData prop here
        backgroundColor: ["#007bff", "#28a745", "#fd7e14"], // Primary button color, green, orange
        hoverBackgroundColor: ["#0056b3", "#218838", "#e9690c"], // Darker shades for hover effect
        hoverBorderColor: ["#ffffff", "#ffffff", "#ffffff"],
      },
    ],
  };

  const options = {
    rotation: -90, // Start angle for semi-donut
    circumference: 180, // Sweep angle for semi-donut
    cutout: '70%', // Inner radius
    radius: '92%', // Outer radius
    plugins: {
      legend: {
        display: false, // Hide legend if needed
      },
      datalabels: {
        formatter: (value, context) => {
          let sum = 0;
          const dataArr = context.chart.data.datasets[0].data;
          dataArr.map(data => {
            sum += data;
            return sum;
          });
          const percentage = ((value * 100) / sum).toFixed(2) + "%";
          return percentage;
        },
        color: '#fff', // Color of the percentage text
        font: {
          weight: 'bold',
        }
      },
      centerText: {
        textColor: textColor,
        totalCount: TotalCount
      }
    },
  };

  return <Doughnut data={data} options={options} width={734} height={269} className="chartjs-chart" />;
};

export default DountChart;
