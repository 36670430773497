import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Col,
  Row,
  CardTitle,
  Spinner,
  Table,
  Button,
} from "reactstrap"
import Select from "react-select"
import "../GlobalDashboard/Dashboard.scss"

// Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  ProjectDashboardActiveDefect,
  ProjectDashboardDefectAging,
  ProjectDashboardDefectClosure,
  ProjectDashboardDefectReopen,
  ProjectdashboardDefectStatics,
  ProjectDashboardDefectTypeCount,
  ProjectDashboardDefectWeekWise,
  ProjectdashboardMilestoneTracking,
  ProjectDashboardSeverityDef,
  ProjectDashboardStats,
  ProjectDashboardStatusDef,
  ProjectDashboardTestCaseExecuted,
  ProjectDashboardTestCaseFail,
  ProjectDashboardTestCaseStatus,
  ProjectdashboardUserList,
} from "store/actions/DashboardAction"
import { useSelector, useDispatch } from "react-redux"
import PolarChart2 from "../Charts/PolarChart2"
import PieChart from "../Charts/PieChart"
import PolarChart from "../Charts/PolarChart"
import BarChart2 from "../Charts/BarChart2"
import TestCasesFailChart from "../Charts/TestCasesFailChart"
import ReopenedChart from "../Charts/ReopenedChart"
import DefectAging from "../Charts/DefectAging"
import ActiveDefects from "../Charts/ActiveDefects"
import DefectClosureEfficiency from "../Charts/DefectClosureEfficiency"
import DefectWeekWise from "../Charts/DefectWeekWiseChart"
import DefectsCountDonutChart from "../Charts/DefectsCountDonutChart"
import StackedBarChart from "../Charts/StackedBarChart"
import ReactApexChart from "react-apexcharts"
import NoProject from "components/Common/NoProject"
import NoDataChart from "components/Common/NoDataChart"
import ProjectDefectStatistics from "../Charts/ProjectDefectStatistics"
import NoData from "assets/images/No_permission.png"
import NoPermission from "components/Common/NoPermission"

const ProjectDashboard = () => {
  const breadcrumbItems = [{ label: "Project Dashboard", path: "" }]
  const [buttonLoading, setButtonLoading] = useState(false)

  const projectDashboardStat = useSelector(
    state => state?.Dashboard?.ProjDashboardStats?.data
  )
  const SeverityDefects = useSelector(
    state => state?.Dashboard?.ProjectDashboardSeverityDef?.data
  )
  const StatusDefects = useSelector(
    state => state?.Dashboard?.ProjectDashboardStatusDef?.data
  )
  const TestCaseStatus = useSelector(
    state => state?.Dashboard?.ProjectDashboardTestCaseStatus?.data
  )
  const TestCaseExecuted = useSelector(
    state => state?.Dashboard?.ProjectDashboardTestCaseExecuted?.data
  )
  const TestCaseFail = useSelector(
    state => state?.Dashboard?.ProjectDashboardTestCaseFail
  )

  const ProjectDashboardDefectReopenData = useSelector(
    state => state?.Dashboard?.ProjectDashboardDefectReopen
  )
  const ProjectDashboardDefectAgingData = useSelector(
    state => state?.Dashboard?.ProjectDashboardDefectAging
  )
  const ProjectDashboardActiveDefectData = useSelector(
    state => state?.Dashboard?.ProjectDashboardActiveDefect
  )
  const ProjectDashboardDefectClosureData = useSelector(
    state => state?.Dashboard?.ProjectDashboardDefectClosure
  )

  const GlobalSelectedProject = JSON.parse(
    localStorage.getItem("selectedProject")
  )

  const ProjectDashboardDefectWeekWiseData = useSelector(
    state => state?.Dashboard?.ProjectDashboardDefectWeekWise?.defect_details
  )

  const ProjectDashboardDefectTypeData = useSelector(
    state =>
      state?.Dashboard?.ProjectDashboardDefectTypeCount?.defect_counts_by_type
  )

  const ProjectDashboardMilestoneData = useSelector(
    state => state?.Dashboard?.ProjectdashboardMilestoneTracking
  )

  const userList = useSelector(
    state => state?.Dashboard?.ProjectdashboardUserList
  )
  const userOptions = userList.map(user => ({
    value: user.email,
    label: user.user_name,
  }))

  const [isProjectsPresent, setisProjectsPresent] = useState(false)
  const userData = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission =
    userData?.permissions?.[0]?.menu_item?.Dashboard.ProjectDashboard

  const [loading, setLoading] = useState(true)
  const [statusDataLoaded, setStatusDataLoaded] = useState(false) // New state
  const dispatch = useDispatch()
  const projectList = JSON.parse(localStorage.getItem("authUser"))
  const [users, setUsers] = useState([])

  const reports = [
    {
      title: "Test Scenario",
      iconClass: "bx-copy-alt",
      description:
        projectList?.projects?.length === 0
          ? 0
          : projectDashboardStat?.total_test_scenarios,
    },
    {
      title: "Test Case",
      iconClass: "bx bxs-briefcase-alt-2",
      description:
        projectList?.projects?.length === 0
          ? 0
          : projectDashboardStat?.total_test_cases,
    },
    {
      title: "Defects",
      iconClass: "bx-bug",
      description:
        projectList?.projects?.length === 0
          ? 0
          : projectDashboardStat?.total_defects,
    },
  ]

  useEffect(() => {
    const body = {
      project_id: GlobalSelectedProject?.value,
    }
    dispatch(ProjectDashboardStats(body))
  }, [dispatch, GlobalSelectedProject?.value])

  useEffect(() => {
    const checkProjectAndRunAPIs = () => {
      const GlobalSelectedProject = JSON.parse(
        localStorage.getItem("selectedProject")
      )
      if (
        GlobalSelectedProject?.value &&
        projectList &&
        projectList?.projects?.length > 0
      ) {
        const body = { project_id: GlobalSelectedProject.value }
        const userBody = { project_name_id: GlobalSelectedProject.value }
        const data = {
          project_id: GlobalSelectedProject.value,
          email: userData.email,
        }

        dispatch(ProjectDashboardSeverityDef(body))
        dispatch(ProjectDashboardStatusDef(body))
        dispatch(ProjectDashboardTestCaseStatus(body))
        dispatch(ProjectDashboardTestCaseExecuted(body))
        dispatch(ProjectDashboardTestCaseFail(body))
        dispatch(ProjectDashboardDefectReopen(body))
        dispatch(ProjectDashboardDefectAging(body))
        dispatch(ProjectDashboardActiveDefect(body))
        dispatch(ProjectDashboardDefectClosure(body))
        dispatch(ProjectDashboardDefectWeekWise(body))
        dispatch(ProjectdashboardMilestoneTracking(body))
        dispatch(ProjectDashboardDefectTypeCount(body))
        dispatch(ProjectdashboardDefectStatics(body))
        dispatch(ProjectdashboardUserList(userBody))

        setLoading(false)
        clearInterval(intervalId)
      } else if (projectList && projectList.projects.length === 0) {
        setLoading(false)
        setisProjectsPresent(true)
        clearInterval(intervalId)
      }
    }

    const intervalId = setInterval(checkProjectAndRunAPIs, 500)

    return () => clearInterval(intervalId)
  }, [dispatch])

  // Use effect to handle TestCaseStatus update
  useEffect(() => {
    if (TestCaseStatus && SeverityDefects) {
      setStatusDataLoaded(true)
    }
  }, [TestCaseStatus, SeverityDefects])

  const statusLabels = Object.keys(StatusDefects || {})
    .filter(key => key !== "total_test_defects")
    .map(key => key.replace("_percentage", "").replace("_", " "))

  const statusSeries = Object.keys(StatusDefects || {})
    .filter(key => key !== "total_test_defects")
    .map(key => {
      const value = parseFloat(StatusDefects[key]?.replace("%", "") || 0)
      return isNaN(value) ? 0 : value
    })

  const getChartOptions = index => {
    var options = {
      chart: { sparkline: { enabled: !0 } },
      dataLabels: { enabled: !1 },
      colors: ["#556ee6"],
      plotOptions: {
        radialBar: {
          hollow: { margin: 0, size: "60%" },
          track: { margin: 0 },
          dataLabels: { show: !1 },
        },
      },
    }
    switch (index) {
      case 1:
        options["colors"][0] = "#556ee6"
        break
      case 2:
        options["colors"][0] = "#34c38f"
        break
      case 3:
        options["colors"][0] = "#f46a6a"
        break
      default:
        break
    }

    return options
  }

  const handleChange = selectedOptions => {
    setUsers(selectedOptions)
  }

  const onClickSearch = async () => {
    setButtonLoading(true)
    const user = users?.map(item => item.value)
    const data = {
      project_id: GlobalSelectedProject.value,
      email: user,
    }

    dispatch(ProjectDashboardSeverityDef(data))
    dispatch(ProjectDashboardStatusDef(data))
    dispatch(ProjectDashboardTestCaseStatus(data))
    dispatch(ProjectDashboardTestCaseExecuted(data))
    dispatch(ProjectDashboardTestCaseFail(data))
    dispatch(ProjectDashboardDefectReopen(data))
    dispatch(ProjectDashboardDefectAging(data))
    dispatch(ProjectDashboardActiveDefect(data))
    dispatch(ProjectDashboardDefectClosure(data))
    dispatch(ProjectDashboardDefectWeekWise(data))
    dispatch(ProjectdashboardMilestoneTracking(data))
    dispatch(ProjectDashboardDefectTypeCount(data))
    dispatch(ProjectdashboardDefectStatics(data))
    setTimeout(() => {
      setButtonLoading(false)
    }, 1000)
  }

  const layoutModeType = useSelector(state => state.Layout.layoutModeType)
  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
      width: "270px",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888",
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    multiValue: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#3a3f51" : "#e0e0e0",
      maxWidth: "100%", // Ensure selected items stay within the container
    }),
    multiValueLabel: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    multiValueRemove: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
      ":hover": {
        backgroundColor: layoutModeType === "dark" ? "#ff4949" : "#e0e0e0",
        color: "#fff",
      },
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150,
      overflowY: "auto",
      zIndex: 1000,
    }),
    // Customize multiValueContainer to handle overflow when too many items are selected
    valueContainer: provided => ({
      ...provided,
      maxHeight: 50, // Set maximum height for the selected items container
      overflowY: "auto", // Add a scroll bar when the selected items overflow
    }),
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {rolePermission.view ? (
          <Container fluid>
            <Breadcrumbs
              title="Projects Dashboard"
              breadcrumbItems={breadcrumbItems}
            />
            {loading ? (
              <div className="text-center mt-5">
                <Spinner color="primary" />
                <p className="mt-2">Loading data...</p>
              </div>
            ) : (
              <div>
                <Card>
                  <CardBody className=" ">
                    <div
                      className="d-flex justify-content-end gap-2 flex-grow-1  align-items-center"
                      style={{ flexWrap: "wrap", justifyContent: "between" }}
                    >
                      <Select
                        isMulti
                        placeholder="Select user"
                        className="select2-selection"
                        styles={customStyles}
                        options={userOptions}
                        onChange={handleChange}
                        closeMenuOnSelect={false}
                      />
                      <Button
                        type="button"
                        color="primary"
                        onClick={onClickSearch}
                        disabled={buttonLoading || userOptions.length === 0}
                        style={{ height: "36px" }}
                      >
                        {buttonLoading ? "Searching..." : "Search"}
                      </Button>
                    </div>
                  </CardBody>
                </Card>
                <Row>
                  <Col xl="12">
                    <Row>
                      {(reports || [])?.map((report, key) => (
                        <Col md="4" key={"_col_" + key}>
                          <Card className="mini-stats-wid">
                            <CardBody>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <p className="text-muted fw-medium">
                                    {report.title}
                                  </p>
                                  <h4 className="mb-0">{report.description}</h4>
                                </div>
                                <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                  <span className="avatar-title rounded-circle bg-primary">
                                    <i
                                      className={
                                        "bx " +
                                        report.iconClass +
                                        " font-size-24"
                                      }
                                    ></i>
                                  </span>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>

                    <Row>
                      <Col sm={6} md={4}>
                        <Card>
                          <CardBody>
                            <CardTitle className="mb-4">
                              Defects by Status
                            </CardTitle>
                            {statusSeries.total_test_defects === 0 ? (
                              <NoDataChart />
                            ) : (
                              <PieChart
                                data={statusSeries}
                                labels={statusLabels}
                              />
                            )}
                          </CardBody>
                        </Card>
                      </Col>

                      <Col sm={6} md={4}>
                        <Card>
                          <CardBody>
                            <CardTitle>Risk Indicators</CardTitle>
                            <p className="mb-5">
                              (Impact the project timeline or quality, based on
                              the severity of defects or issues)
                            </p>
                            {/* {statusDataLoaded ? ( */}
                            <PolarChart2 data={SeverityDefects} />
                            {/* ) : (
                              <div className="text-center mt-5">
                                <Spinner color="primary" />
                                <p className="mt-2">
                                  Loading Defects Severity...
                                </p>
                              </div>
                            )} */}
                          </CardBody>
                        </Card>
                      </Col>
                      <Col sm={6} md={4}>
                        <Card>
                          <CardBody>
                            <CardTitle className="mb-4">
                              Defect Counts by Type{" "}
                            </CardTitle>
                            <DefectsCountDonutChart
                              defectCounts={ProjectDashboardDefectTypeData}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Card style={{ minHeight: "486px" }}>
                      <CardBody>
                        <CardTitle className="mb-2">Day Wise Defects</CardTitle>
                        {/* <DefectWeekWise /> */}
                        <Table
                          className="table align-middle mb-0"
                          style={{ fontSize: "0.875rem" }}
                        >
                          <tbody>
                            {(ProjectDashboardDefectWeekWiseData || []).map(
                              (data, key) => {
                                const options = getChartOptions(key + 1)
                                return (
                                  <tr key={key} style={{ height: "40px" }}>
                                    <td style={{ padding: "0.5rem" }}>
                                      <h5
                                        className="font-size-14 mb-1"
                                        style={{ margin: 0 }}
                                      >
                                        {data.day_name}
                                      </h5>
                                      <p
                                        className="text-muted mb-0"
                                        style={{ margin: 0 }}
                                      >
                                        Defect Count: {data.defect_count}
                                      </p>
                                    </td>
                                    <td style={{ padding: "0.5rem" }}>
                                      <div id={`radialchart-${key + 1}`}>
                                        <ReactApexChart
                                          options={options}
                                          series={[data.percentage]}
                                          type="radialBar"
                                          height={45} // Reduced height
                                          width={45} // Reduced width
                                          className="apex-charts"
                                        />
                                      </div>
                                    </td>
                                    <td style={{ padding: "0.5rem" }}>
                                      <p
                                        className="text-muted mb-1"
                                        style={{ margin: 0 }}
                                      >
                                        Defect Percentage
                                      </p>
                                      <h5
                                        className="mb-0"
                                        style={{ margin: 0 }}
                                      >
                                        {data.percentage} %
                                      </h5>
                                    </td>
                                  </tr>
                                )
                              }
                            )}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col sm={6}>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">
                          Milestones - Test Plans Tracker
                        </CardTitle>
                        <StackedBarChart data={ProjectDashboardMilestoneData} />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">Active Defects</CardTitle>
                        <ActiveDefects
                          data={ProjectDashboardActiveDefectData}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">
                          Defect Statistics
                        </CardTitle>
                        <ProjectDefectStatistics />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">
                          Defects Re-Opened
                        </CardTitle>
                        <ReopenedChart
                          data={ProjectDashboardDefectReopenData}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm={4}>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">Defects Aging</CardTitle>
                        <DefectAging data={ProjectDashboardDefectAgingData} />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm={4}>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">
                          Defects Closure Efficiency
                        </CardTitle>
                        <DefectClosureEfficiency
                          data={ProjectDashboardDefectClosureData}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col sm={6}>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">
                          Test Cases by status
                        </CardTitle>
                        <Row className="justify-content-center">
                          <Col sm={3}>
                            <div className="text-center">
                              <h5 className="mb-0">
                                {TestCaseStatus?.approved_percentage}
                              </h5>
                              <p className="text-muted text-truncate">
                                Approved
                              </p>
                            </div>
                          </Col>
                          <Col sm={3}>
                            <div className="text-center">
                              <h5 className="mb-0">
                                {TestCaseStatus?.rejected_percentage}
                              </h5>
                              <p className="text-muted text-truncate">
                                Rejected
                              </p>
                            </div>
                          </Col>
                          <Col sm={3}>
                            <div className="text-center">
                              <h5 className="mb-0">
                                {TestCaseStatus?.pending_percentage}
                              </h5>
                              <p className="text-muted text-truncate">
                                Pending
                              </p>
                            </div>
                          </Col>
                          <Col sm={3}>
                            <div className="text-center">
                              <h5 className="mb-0">
                                {TestCaseStatus?.new_percentage}
                              </h5>
                              <p className="text-muted text-truncate">New</p>
                            </div>
                          </Col>
                        </Row>
                        {/* {statusDataLoaded ? ( */}
                        <PolarChart data={TestCaseStatus} />
                        {/* ) : (
                          <div className="text-center mt-5">
                            <Spinner color="primary" />
                            <p className="mt-2">Loading Test Case Status...</p>
                          </div>
                        )} */}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm={6}>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">Test Cases Fail</CardTitle>
                        <TestCasesFailChart data={TestCaseFail} />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm={12}>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">
                          Test Cases Executed
                        </CardTitle>
                        <BarChart2 data={TestCaseExecuted} />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            )}
          </Container>
        ) : (
          // <NoProject />
          <NoPermission />
        )}
      </div>
    </React.Fragment>
  )
}

ProjectDashboard.propTypes = {
  projectDashboardStat: PropTypes.object,
  SeverityDefects: PropTypes.object,
  StatusDefects: PropTypes.object,
  TestCaseStatus: PropTypes.object,
  TestCaseExecuted: PropTypes.object,
  TestCaseFail: PropTypes.object,
}

export default ProjectDashboard
