import { toast } from "react-toastify"
import httpInjectorService from "services/http-Injector.Service"
import { setLoading } from "store/Slices/LoadingSlice"
import {
  setAllTestScenario,
  setNextTestScenarioId,
  setSingleTestScenarioData,
} from "store/Slices/TestScenarioSlice"

export const fetchAllTestScenarioList = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.getAllTestScenarioAPI(
        queryString
      )
      const data = response
      dispatch(setAllTestScenario(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const GetTestScenarioByIdAPI = id => {
  return async dispatch => {
    dispatch(setLoading(true))

    try {
      const response = await httpInjectorService.GetTestScenarioById(id)
      const data = response
      dispatch(setSingleTestScenarioData(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const fetchNextScenarioID = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.generateNextScenarioID(
        queryString
      )
      const data = response
      dispatch(setNextTestScenarioId(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const createTestScenarioAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.createTestScenario(formData)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Test Scenario created successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const downloadTestScenarioTemplateAPI = bodyOption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyOption) {
      if (
        bodyOption[key] !== undefined &&
        bodyOption[key] !== null &&
        bodyOption[key] !== ""
      ) {
        formData.append(key, bodyOption[key])
      }
    }
    try {
      const response = await httpInjectorService.downloadTestScenarioTemplate(
        formData
      )
      const blob = response.data
      const downloadLink = document.createElement("a")
      downloadLink.href = window.URL.createObjectURL(blob)
      downloadLink.download = "Test Scenario Template"
      document.body.appendChild(downloadLink)

      // Programmatically trigger the download
      downloadLink.click()

      // Clean up
      document.body.removeChild(downloadLink)
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      console.error("Download failed:", error.message || error)
    }
  }
}
export const ExportTestScenarioAPI = bodyOption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyOption) {
      if (
        bodyOption[key] !== undefined &&
        bodyOption[key] !== null &&
        bodyOption[key] !== ""
      ) {
        formData.append(key, bodyOption[key])
      }
    }
    try {
      const response = await httpInjectorService.exportTestScenarioData(
        formData
      )
      const blob = response.data
      const downloadLink = document.createElement("a")
      downloadLink.href = window.URL.createObjectURL(blob)
      downloadLink.download = "Test Scenario"
      document.body.appendChild(downloadLink)

      // Programmatically trigger the download
      downloadLink.click()

      // Clean up
      document.body.removeChild(downloadLink)
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      console.error("Download failed:", error.message || error)
    }
  }
}

export const uploadTestScenarioTemplateAPI = bodyOption => {
  return async dispatch => {
    dispatch(setLoading(true))

    try {
      const response = await httpInjectorService.uploadTestScenarioTemplate(
        bodyOption
      )
      return response
    } catch (error) {
      console.error("Upload failed:", error.message || error)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const EditTestScenarioAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))

    const formData = new FormData()

    // Append all fields from bodyoption to formData without any checks
    for (const key in bodyoption) {
      formData.append(key, bodyoption[key])
    }

    try {
      // Make the API request
      const response = await httpInjectorService.EditTestScenario(
        formData,
        bodyoption?.id // Assuming you're passing the ID separately
      )

      if (response?.status) {
        // Show success toast message
        toast.success(
          response.message || "Test Scenario updated successfully",
          {
            position: "top-right",
            id: "Test Scenario updated successfully",
            autoClose: 3000,
          }
        )
      }

      dispatch(setLoading(false))
    } catch (error) {
      // Handle errors
      dispatch(setLoading(false))
      console.error("Error updating Test Scenario:", error)
      throw new Error(error?.message || "Failed to update Test Scenario")
    }
  }
}

export const BulkEditTestScenarioAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.BulkEditTestScenario(formData)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Test Scenario updated successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const deleteTestScenarioAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.deleteTestScenario(bodyoption)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Test Scenario Deleted successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const BulkDeleteTestScenarioAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (bodyoption[key] !== undefined && bodyoption[key] !== null) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.BulkDeleteTestScenario(
        formData
      )
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Test Scenario Deleted successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
