import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap"
import TestResultPieChart from "./TestResultPieChart"

const TestResults = ({ testPlanResultsData, testPlanResultChartData }) => {
  const isDarkMode = useSelector(state => state.Layout.layoutModeType)

  const cardHeaderStyle = useMemo(
    () => ({
      backgroundColor: isDarkMode === "dark" ? "#32394e" : "#fff",
      borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
      color: isDarkMode === "dark" ? "#fff" : "#000",
    }),
    [isDarkMode]
  )

  const tableHeaderStyle = useMemo(
    () => ({
      position: "sticky",
      top: 0,
      backgroundColor: isDarkMode === "dark" ? "#444" : "#fff",
      color: isDarkMode === "dark" ? "#fff" : "#000",
      zIndex: 2,
      borderCollapse: "collapse",
    }),
    [isDarkMode]
  )

  const columnStyle = useMemo(
    () => ({
      whiteSpace: "nowrap",
      textAlign: "left",
    }),
    []
  )

  const headerStyle = useMemo(
    () => ({
      fontSize: "0.8rem",
      textAlign: "left",
      minWidth: "80px",
      maxWidth: "350px",
    }),
    []
  )

  const formatHeader = header => {
    if (header === "total_test_case") {
      return "Total"
    }
    if (header === "failed_count") {
      return "Failed"
    }
    if (header === "passed_count") {
      return "Passed"
    }
    if (header === "executed_count") {
      return "Executed"
    }
    if (header === "defect_count") {
      return "Defect"
    }
    if (header === "not_run_count") {
      return "Not Run"
    }
    return header
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/_/g, " ")
      .replace(/\b\w/g, char => char.toUpperCase())
  }

  const renderTableContent = () => {
    if (!testPlanResultsData || testPlanResultsData.length === 0) {
      return (
        <div className="text-center py-3">
          <b>No records found.</b>
        </div>
      )
    }

    const tableHeaders = Object.keys(testPlanResultsData[0])

    return (
      <div className="table-responsive">
        <Table hover striped style={{ width: "100%", tableLayout: "auto" }}>
          <thead style={tableHeaderStyle}>
            <tr>
              {tableHeaders.map((header, index) => (
                <th key={index} style={headerStyle}>
                  {formatHeader(header)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {testPlanResultsData.map((item, i) => (
              <tr key={i}>
                {tableHeaders.map((header, index) => (
                  <td key={index} style={columnStyle}>
                    {item[header] || "-"}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader
            className="d-flex justify-content-between align-items-center"
            style={cardHeaderStyle}
          >
            <h5 className="mb-0">Test Results</h5>
            <div id="resultDownload" className="cursor-pointer"></div>
          </CardHeader>
          <CardBody>
            <Row>
              <Col lg={4} xs={12} className="mb-3">
                <Card>
                  <CardBody>
                    <TestResultPieChart
                      testPlanResultChartData={testPlanResultChartData}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col lg={8} xs={12}>
                <Card>
                  <CardBody>
                    <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                      {renderTableContent()}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default TestResults
