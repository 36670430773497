import React, { useEffect, useState, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import DeleteModal from "components/Common/DeleteModal"
import { useSelector, useDispatch } from "react-redux"
import { ToastContainer } from "react-toastify"
import moment from "moment"
import TableContainer from "components/Common/helper/TableContainer"
import {
  deleteScheduleReportAPI,
  fetchAllScheduleReportsList,
} from "store/actions/ReportsAction"
import NoPermission from "components/Common/NoPermission"

const ScheduleReport = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const [data, setData] = useState([])

  const allTestScenario = useSelector(
    state => state?.TestScenario?.allTestScenario
  )
  useEffect(() => {
    if (allTestScenario && allTestScenario.Data) {
      setData(allTestScenario)
    }
  }, [allTestScenario])

  const handleCreateClick = id => {
    navigate("/Reports/CreateScheduleReport")
  }

  const [deleteModal, setDeleteModal] = useState(false)
  const [isDeleteId, setIsDeleteId] = useState("")

  const onClickDelete = data => {
    setIsDeleteId(data?.id)
    setDeleteModal(true)
  }

  const columns = useMemo(
    () => [
      {
        header: "Template Name",
        accessorKey: "template_name",
        cell: cell => <div>{cell.getValue()}</div>,
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return (
            <p
              className="font-size-14 mb-1"
              style={{
                wordWrap: "break-word",
                overflowWrap: "break-word",
                whiteSpace: "normal",
                maxWidth: "300px",
              }}
            >
              {cell.getValue()}
            </p>
          )
        },
      },
      {
        header: "Frequency",
        accessorKey: "frequency",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Report Format",
        accessorKey: "report_format",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Report Type",
        accessorKey: "report_type",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Report Time",
        accessorKey: "report_time",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Time Zone",
        accessorKey: "time_zone",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Created By",
        accessorKey: "created_by",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Created At",
        accessorKey: "created_at",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return <>{moment(cell.getValue()).format("DD-MM-YYYY")}</>
        },
      },

      {
        header: "Share With",
        accessorKey: "share_with",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const share_with = cell.getValue() // assuming this is an array of user objects
          const avatarGroupStyle = {
            display: "flex",
            flexWrap: "wrap", // Allows items to wrap to the next line
            maxWidth: "300px",
            minWidth: "50px", // Adjust the max width as needed
          }

          // CSS for limiting avatars per row
          const avatarItemStyle = {}

          return (
            <div style={avatarGroupStyle}>
              {share_with?.map(user => (
                <React.Fragment key={user.id}>
                  <div className="avatar-group-item" style={avatarItemStyle}>
                    <Link
                      to="#"
                      className="d-inline-block"
                      id={"member" + user.id}
                    >
                      <div className="avatar-xs">
                        <span
                          className={`avatar-title rounded-circle bg-primary text-white text-align-center`}
                          style={{
                            width: "24px",
                            height: "24px",
                            fontSize: "14px",
                          }}
                        >
                          {user.user_name.charAt(0).toUpperCase()}
                        </span>
                        <UncontrolledTooltip
                          placement="top"
                          target={"member" + user.id}
                          style={{ zIndex: 9999 }}
                        >
                          {user.user_name}
                        </UncontrolledTooltip>
                      </div>
                    </Link>
                  </div>
                </React.Fragment>
              ))}
            </div>
          )
        },
      },

      {
        header: "Action",
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li>
                <span
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const userData = cellProps.row.original
                    onClickDelete(userData)
                  }}
                  id={`deletetooltip-${cellProps.row.original.test_scenario_id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`deletetooltip-${cellProps.row.original.test_scenario_id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </span>
              </li>
            </ul>
          )
        },
      },
    ],
    [data]
  )

  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [globalFilter, setGlobalFilter] = useState("")
  const [totalCount, setTotalCount] = useState(null)

  const { testScenarioFilterFrom } = useSelector(state => state.TestScenario)
  const fetchTableData = async (page, size, search) => {
    let bodyoption = {
      page: page + 1,
      page_size: size,
      search: search,
      project_id: globalProject?.value || selectedProject?.value,
    }
    try {
      const data = await dispatch(fetchAllScheduleReportsList(bodyoption))
      setData(data.Data)
      setTotalCount(data.TotalCount)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  useEffect(() => {
    fetchTableData(pageIndex, pageSize, globalFilter)
  }, [
    pageIndex,
    pageSize,
    globalFilter,
    globalProject?.value,
    testScenarioFilterFrom,
  ])

  const handleDelete = async () => {
    try {
      await dispatch(deleteScheduleReportAPI(isDeleteId))
      setDeleteModal(false)
      await fetchTableData(pageIndex, pageSize, globalFilter)
      setIsDeleteId("")
    } catch (error) {
      console.error("Error deleting test scenario:", error)
    }
  }
  const permission = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission = permission?.permissions?.[0]?.menu_item?.Reports
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
        message="Schedule Report"
      />
      {rolePermission?.schedulereports?.create ? (
        <>
          {" "}
          <Container fluid>
            <Row>
              <Col lg="12">
                <Card
                  style={{
                    borderTopLeftRadius: "0px",
                    borderTopRightRadius: "0px",
                  }}
                >
                  <CardBody className="pt-2">
                    <div className="table-container">
                      <TableContainer
                        columns={columns}
                        data={data}
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        totalCount={totalCount}
                        setPageIndex={setPageIndex}
                        setPageSize={setPageSize}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        isGlobalFilter={true}
                        isPagination={true}
                        SearchPlaceholder="Search..."
                        isCustomPageSize={true}
                        isAddButton={true}
                        handleAddClick={handleCreateClick}
                        buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal ms-2 mb-2"
                        buttonName="Create"
                        tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                        theadClass="table-light"
                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                        pagination="pagination"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <NoPermission />
      )}

      <ToastContainer />
    </React.Fragment>
  )
}

export default withRouter(ScheduleReport)
