import Breadcrumb from "components/Common/Breadcrumb"
import moment from "moment"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"

const ViewTestScenario = () => {
  const navigate = useNavigate()
  const breadcrumbItems = [
    { label: "Test Scenario", path: "/TestManagement/TestScenario" },
    {
      label: "Test Scenario Details",
      path: "/",
    },
  ]
  const viewTestScenarioData = useSelector(
    state => state?.TestScenario?.singleTestScenarioData
  )

  useEffect(() => {
    if (!viewTestScenarioData) {
      navigate("/TestManagement/TestScenario")
    }
  })

  const onClickBack = () =>{
    navigate("/TestManagement/TestScenario")
  }
  return (
    <div className="">
      <Container fluid>
        <Breadcrumb
          title="Test Scenario Details"
          breadcrumbItems={breadcrumbItems}
        />
        <div className="d-flex justify-content-end mb-2">
          <Button
            type="button"
            color="primary"
            className="btn-label"
            onClick={onClickBack}
          >
            <i className="bx bx-arrow-back label-icon"></i> Go Back
          </Button>
        </div>
        <Card style={{ minHeight: "400px" }}>
          <CardBody>
            <div className="d-flex justify-content-between mb-4">
              <div className="d-flex">
                <div className="avatar-xs me-4">
                  <span
                    className="avatar-title bg-primary"
                    style={{ fontSize: "20px", borderRadius: "8px" }}
                  >
                    {viewTestScenarioData?.test_scenario_name
                      ?.slice(0, 2)
                      .toUpperCase()}
                  </span>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <h5 className="font-size-15 d-flex align-items-center">
                    <span
                      style={{
                        maxWidth: "500px",
                        // overflow: "hidden",
                        // textOverflow: "ellipsis",
                        // whiteSpace: "nowrap",
                      }}
                    >
                      <Link className="text-dark  mt-2">
                        {viewTestScenarioData?.test_scenario_name || "-"}
                      </Link>
                    </span>
                    {viewTestScenarioData?.test_scenario_id && (
                      <span className="ms-2">
                        ({viewTestScenarioData?.test_scenario_id})
                      </span>
                    )}
                  </h5>
                </div>
              </div> 
            </div>

            <div className="mb-4">
              <h6 style={{ fontWeight: "550" }}>Test Scenario Description</h6>
              <p
                className="text-muted mb-4"
                dangerouslySetInnerHTML={{
                  __html: viewTestScenarioData?.description
                    ? viewTestScenarioData?.description
                    : "-",
                }}
              ></p>
            </div>
            <div>
              <Row>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Project Name</h6>
                  <p className="text-muted ">
                    {viewTestScenarioData?.project_name
                      ? viewTestScenarioData?.project_name
                      : "-"}
                  </p>
                </Col>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Requirement Title</h6>
                  <p className="text-muted ">
                    {viewTestScenarioData?.requirement_title
                      ? viewTestScenarioData?.requirement_title
                      : "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Build</h6>
                  <p className="text-muted ">
                    {viewTestScenarioData?.build
                      ? viewTestScenarioData?.build
                      : "-"}
                  </p>
                </Col>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Module</h6>
                  <p className="text-muted ">
                    {viewTestScenarioData?.module_name
                      ? viewTestScenarioData?.module_name
                      : "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Created At</h6>
                  <p className="text-muted ">
                    {viewTestScenarioData?.created_at
                      ? moment(viewTestScenarioData?.created_at).format(
                          "DD-MM-YYYY"
                        )
                      : "-"}
                  </p>
                </Col>
                <Col sm={6}></Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default ViewTestScenario
