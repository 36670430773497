import React, { useState,useEffect } from "react"
import PropTypes from "prop-types"
import { Link, useLocation } from "react-router-dom"
import "../../../components/Common/Breadcrum.scss"
import { Row, Col, BreadcrumbItem, Button, Container, Card ,CardBody } from "reactstrap"
import PageNavigation from "../PageNavigation/PageNavigation"
import { useSelector } from "react-redux"

const Breadcrumb = props => {

  const location = useLocation()
  const locationPath = location.pathname
  const pathname = locationPath.split("/").filter(Boolean).pop()

  const { isImport, isDownload, isExport, isInfo } = props
  const [title, setTitle] = useState("Roles")

  const initialBreadcrumbItems = [
    { label: "Settings", path: "Roles" },
    { label: "Roles", path: "/" },
  ]
  const [breadcrumbItems, setBreadcrumbItems] = useState(initialBreadcrumbItems)
  const [activeButton, setActiveButton] = useState("Roles")
  const layoutModeType = useSelector(state => state.Layout.layoutModeType)

  useEffect(()=>{
    if(pathname){
      setActiveButton(pathname)
    }
  },[pathname])

  const handleUsers = () => {
    setActiveButton("Users")
    setTitle("Users")
    setBreadcrumbItems([
      { label: "Settings", path: "Users" },
      { label: "Users", path: "/Users" },
    ])
  }

  const handleIntegration = () => {
    setActiveButton("Integration")
    setTitle("Integration")
    setBreadcrumbItems([
      { label: "Settings", path: "Integration" },
      { label: "Integration", path: "/Integration" },
    ])
  }

  const handleTags = () => {
    setActiveButton("Tags")
    setTitle("Tags")
    setBreadcrumbItems([
      { label: "Settings", path: "Tags" },
      { label: "Tags", path: "/Tags" },
    ])
  }

  const handleRoles = () => {
    setActiveButton("Roles")
    setBreadcrumbItems(initialBreadcrumbItems)
    setTitle("Roles")
  }

  const onBreadcrumbClick = () => {
    setActiveButton("Roles")
    setBreadcrumbItems(initialBreadcrumbItems)
    setTitle("Roles")
  }

  return (
    <>
      <Row className="d-flex flex-column">
        <Col
          xs={12}
          md={3}
          className="d-flex w-100 align-items-center justify-content-between"
        >
          {/* <h4 className="mb-sm-0 font-size-18">{title}</h4> */}
          <div className="page-title-right"  style={{marginTop:"-25px" ,marginLeft:"-10px"}}>
            <ol className="breadcrumb m-0">
              {breadcrumbItems?.map((item, index) => (
                <BreadcrumbItem
                  key={index}
                  active={index === breadcrumbItems.length - 1}
                >
                  {index === breadcrumbItems.length - 1 ? (
                    item.label
                  ) : (
                    <Link to={item.path} onClick={() => onBreadcrumbClick()}>
                      {item.label}
                    </Link>
                  )}
                </BreadcrumbItem>
              ))}
            </ol>
          </div>
        </Col>
      </Row>
      <Container fluid>
        <Row>
          <Col lg="12">
            <Card  className="mb-0"  style={{borderBottomLeftRadius:"0px",borderBottomRightRadius:"0px"}}>
              <CardBody className="border-bottom pt-3 pb-0">
                <div className="d-flex justify-content-between ">
            <PageNavigation
              handleUsers={handleUsers}
              handleRoles={handleRoles}
              handleIntegration={handleIntegration}
              handleTags={handleTags}
              activeButton={activeButton}
              onBreadcrumbClick={onBreadcrumbClick}
            />
            <div className="d-flex flex-wrap align-items-center mt-auto">
              {isDownload && (
                <Button
                  color="secondary"
                  outline
                  className="d-flex align-items-center mx-2 mb-2"
                >
                  <i className="bx bx-download font-size-16"></i>
                  <span className="mx-2">Download template</span>
                </Button>
              )}
              {isImport && (
                <Button
                  color="secondary"
                  outline
                  className="d-flex align-items-center mx-2 mb-2"
                >
                  <i className="bx bx-import font-size-16"></i>
                  <span className="mx-2">Import</span>
                </Button>
              )}
              {isExport && (
                <Button
                  color="secondary"
                  outline
                  className="d-flex align-items-center mx-2 mb-2"
                >
                  <i className="bx bx-export font-size-16"></i>
                  <span className="mx-2">Export</span>
                </Button>
              )}
              {isInfo && (
                <Button
                  color="secondary"
                  outline
                  className="d-flex align-items-center mx-2 mb-2"
                >
                  <i className="bx bx-info-circle font-size-16"></i>
                  {/* <span className="mx-2">info</span> */}
                </Button>
              )}
              {/* {isProject && <GlobalSelectProject projectList={ProjectDetails} />} */}
              {/* <QuickAction /> */}
            </div>
            </div>
            </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

Breadcrumb.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
    })
  ).isRequired,
}

export default Breadcrumb
