import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  CardBody,
} from "reactstrap"
import Select from "react-select"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// Import assets and actions
import profile from "assets/icons/GenericUserProfile.svg"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { useDispatch } from "react-redux"
import { getUserProfile, updateUser } from "store/actions/UserProfileAction"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"
import Breadcrumbs from "components/Common/Breadcrumb"

const UserProfile = () => {
  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [passwordShow, setPasswordShow] = useState({
    currentPassword: false,
    newPassword: false,
    reNewPassword: false,
  })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const layoutModeType = useSelector(state => state.Layout.layoutModeType)
  const userDetails = useSelector(state => state?.User?.getUserProfile)
  const userData = JSON.parse(localStorage.getItem("authUser"))
  const breadcrumbItems = [
    { label: "Dashboard", path: "/dashboard" },
    { label: "User Profile", path: "" },
  ]

  const roleOptions = [
    { value: "admin", label: "Admin" },
    { value: "editor", label: "Editor" },
    { value: "viewer", label: "Viewer" },
  ]

  useEffect(() => {
    const id = userData.user_id
    dispatch(getUserProfile(id))
  }, [dispatch])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      roles: { value: userData.role_name, label: userData.role_name } || "-",
      username: userData?.user_name || "-",
      firstName: userDetails?.name || "-",
      lastName: userDetails?.last_name || "-",
      email: userData?.email || "-",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required("Please Enter Your UserName"),
      firstName: Yup.string()
        .required("Please Enter Your First Name")
        .matches(/\S/, "Enter Your First Name"),
      lastName: Yup.string()
        .required("Please Enter Your Last Name")
        .matches(/\S/, "Enter Your Last Name"),
      email: Yup.string()
        .email("Invalid email")
        .required("Please Enter Your Email"),
      currentPassword: Yup.string(),
      newPassword: Yup.string().when("currentPassword", {
        is: currentPassword => !!currentPassword,
        then: schema =>
          schema
            .min(8, "Password must be at least 8 characters")
            .matches(
              /[!@#$%^&*(),.?":{}|<>]/,
              "Password must contain at least one special character"
            )
            .required("Please Enter a New Password"),
        otherwise: schema => schema.notRequired(),
      }),
      reNewPassword: Yup.string().when("currentPassword", {
        is: currentPassword => !!currentPassword,
        then: schema =>
          schema
            .oneOf([Yup.ref("newPassword")], "Passwords must match")
            .required("Please Re-enter Your New Password"),
        otherwise: schema => schema.notRequired(),
      }),
    }),
    onSubmit: async values => {
      setLoading(true)
      if (
        values.currentPassword ||
        values.newPassword ||
        values.reNewPassword
      ) {
        if (!values.currentPassword) {
          toast.warning("Current password is required to update password.", {
            position: "top-right",
            autoClose: 3000,
          })
          return
        }

        const formData = new FormData()
        formData.append("current_password", values.currentPassword)
        formData.append("new_password", values.newPassword)
        formData.append("re_entered_password", values.reNewPassword)
        formData.append("name", values.firstName)
        formData.append("last_name", values.lastName)
        formData.append("user_id", userData.user_id)

        try {
          const response = await dispatch(
            updateUser(formData, userData.user_id)
          )
          if (response.status) {
            setLoading(false)
            navigate("/logout")
          }
        } catch (error) {
          setLoading(false)
          console.error("Failed to submit form:", error.message)
        }
      } else {
        // Handle case where no password fields are touched
        const formData = new FormData()
        formData.append("name", values.firstName)
        formData.append("last_name", values.lastName)
        formData.append("user_id", userData.user_id)

        try {
          const response = await dispatch(
            updateUser(formData, userData.user_id)
          )
          if (response.status) {
            setLoading(false)
            navigate("/dashboard")
          }
        } catch (error) {
          setLoading(false)
          console.error("Failed to submit form:", error.message)
        }
      }
    },
  })

  const isInvalid = (validation, fieldName) =>
    validation.touched[fieldName] && validation.errors[fieldName]

  const renderInput = (
    name,
    label,
    type = "text",
    placeholder = "",
    isDisabled = false
  ) => (
    <div className="mb-3">
      <Label className="form-label">{label}</Label>
      <Input
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values[name] || ""}
        invalid={isInvalid(validation, name)}
        disabled={isDisabled} // Use isDisabled to control field disabling
      />
      {validation.touched[name] && validation.errors[name] && (
        <FormFeedback>{validation.errors[name]}</FormFeedback>
      )}
    </div>
  )

  const renderPasswordInput = (name, label) => (
    <div className="mb-3">
      <Label className="form-label">{label}</Label>
      <div className="input-group auth-pass-inputgroup">
        <Input
          name={name}
          value={validation.values[name] || ""}
          type={passwordShow[name] ? "text" : "password"}
          placeholder={`Enter ${label}`}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          invalid={
            validation.touched[name] && validation.errors[name] ? true : false
          }
        />
        <button
          onClick={() =>
            setPasswordShow({ ...passwordShow, [name]: !passwordShow[name] })
          }
          className="btn btn-light"
          type="button"
          id={`password-addon-${name}`}
          style={{ border: "1px solid lightgray" }}
        >
          {passwordShow[name] ? (
            <i className="mdi mdi-eye-off-outline"></i>
          ) : (
            <i className="mdi mdi-eye-outline"></i>
          )}
        </button>

        {validation.touched[name] && validation.errors[name] && (
          <FormFeedback type="invalid">{validation.errors[name]}</FormFeedback>
        )}
      </div>
    </div>
  )

  const adUser = JSON.parse(localStorage.getItem("authUser"))

  const handleEditClick = () => {
    if (adUser.is_ad_user) {
      toast.error("Password update is restricted for internal users", {
        position: "top-right",
        autoClose: 3000,
      })
    } else {
      setEditMode(true)
    }
  }

  const onCancel = () => {
    setEditMode(true)
    navigate("/dashboard")
  }

  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#f8f8fb",
      color: layoutModeType === "dark" ? "#f8f8fb" : "#000",
      borderColor: layoutModeType === "dark" ? "#444" : "#ddd",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#f8f8fb",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#f8f8fb" : "#2a3042",
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150, // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="User Profile" breadcrumbItems={breadcrumbItems} />{" "}
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div
                      className="d-flex flex-column flex-md-row align-items-center justify-content-between mb-3"
                      style={{ backgroundColor: "lightblue" }}
                    >
                      <div className="d-flex align-items-center mb-3 mb-md-0">
                        <div className="ms-3 m-2">
                          <img
                            src={profile}
                            alt=""
                            className="avatar-lg rounded-circle img-thumbnail"
                          />
                        </div>
                        <div className="flex-grow-1 mx-3">
                          <div className="text-dark text-center text-md-start">
                            <h5 className="mb-0">{userData.user_name}</h5>
                            <p className="mb-0">{userData.role_name}</p>
                          </div>
                        </div>
                      </div>

                      {!editMode && (
                        <div className="mx-3 text-center text-md-end mb-2">
                          <Button
                            className="btn btn-light waves-effect"
                            onClick={handleEditClick}
                          >
                            <i className="mdi mdi-account-edit"></i> Edit
                            Profile
                          </Button>
                        </div>
                      )}
                    </div>

                    <Row>
                      <Col sm={4}>
                        <div className="mb-3">
                          <Label className="form-label">Role</Label>
                          <Select
                            name="role"
                            className="select2-selection"
                            value={validation.values.roles}
                            isDisabled={true}
                            styles={customStyles}
                          />
                        </div>
                      </Col>
                      <Col sm={4}>
                        {renderInput(
                          "firstName",
                          "First Name",
                          "text",
                          "",
                          !editMode
                        )}
                      </Col>
                      <Col sm={4}>
                        {renderInput(
                          "lastName",
                          "Last Name",
                          "text",
                          "",
                          !editMode
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4}>
                        {renderInput("email", "Email", "email", "", true)}
                      </Col>
                      <Col sm={4}>
                        {renderInput("username", "User Name", "text", "", true)}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {editMode && (
                  <Card>
                    <CardBody>
                      <Row>
                        <Col sm={4}>
                          {renderPasswordInput(
                            "currentPassword",
                            "Current Password"
                          )}
                        </Col>
                        <Col sm={4}>
                          {renderPasswordInput("newPassword", "New Password")}
                        </Col>
                        <Col sm={4}>
                          {renderPasswordInput(
                            "reNewPassword",
                            "Re-enter New Password"
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                )}
                {editMode && (
                  <div className="d-flex justify-content-end gap-2 mb-2 mt-4">
                    <Button type="button" color="secondary" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button type="submit" color="primary" disabled={loading}>
                      {loading ? "Updating..." : "Update"}
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserProfile
