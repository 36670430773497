import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { connect } from "react-redux"
import { Row, Col, Nav, NavItem } from "reactstrap"
import { Link, NavLink, useNavigate } from "react-router-dom"

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import megamenuImg from "../../assets/images/megamenu-img.png"

// import images
import github from "../../assets/images/brands/github.png"
import jira from "../../assets/images/brands/jira.png"
import browserStack from "../../assets/images/brands/browser stack.png"
import plane from "../../assets/images/brands/plane.png"
import mail_chimp from "../../assets/images/brands/mail_chimp.png"
import slack from "../../assets/images/brands/slack.png"

import logo from "../../assets/images/logo.svg"
import logoLightSvg from "../../assets/images/logo-light.svg"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"
import GlobalSelectProject from "components/GlobalSelectProject/GlobalSelectProject"
import QuickAction from "components/Quick Action/QuickAction"
import { useSelector } from "react-redux"
import SimpleBar from "simplebar-react"
import {
  bulkNotificationFlag,
  NotificationListTC,
  NotificationListTP,
} from "store/actions/NotificationsActions"
import { useDispatch } from "react-redux"
import logohalf from "../../assets/images/companies/forth-logo-half.png"
import { setEditIntegration } from "store/Slices/IntegrationSlice"

const Header = props => {
  const [search, setsearch] = useState(false)
  const [megaMenu, setmegaMenu] = useState(false)
  const [socialDrp, setsocialDrp] = useState(false)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [notification, setNotification] = useState(false)
  const ProjectDetails = useSelector(state => state?.Project?.allProjectList)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
      setIsFullscreen(true)
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
      setIsFullscreen(false)
    }
  }

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }
  const [activeTab, setActiveTab] = useState("testCases")

  const handleRequest = () => {
    setNotification(false)
    navigate("/RequestPage")
  }

  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const tcData = useSelector(state => state?.Request?.tcData)

  const tpData = useSelector(state => state?.Request?.tpData)

  const [notifyTCData, setNotifyTCData] = useState([])
  const [notifyTPData, setNotifyTPData] = useState([])
  const [noNofitification, setNoNotification] = useState(null)
  const [notifyData, setNotifyData] = useState([])
  const [bulkData, setBulkData] = useState([])

  const fetchNotifyData = async () => {
    let userId = localStorage.getItem("authUser")
    let userData = JSON.parse(userId)
    let bodyoption = {
      receiver: userData.user_id,
      models_name: "test_case",
      project_id: globalProject?.value || selectedProject?.value,
    }
    let payload = {
      receiver: userData.user_id,
      models_name: "test_plan",
      project_id: globalProject?.value || selectedProject?.value,
    }
    const dataTC = await dispatch(NotificationListTC(bodyoption))
    const dataTP = await dispatch(NotificationListTP(payload))
    setNotifyTCData(dataTC)
    setNotifyTPData(dataTP)
    setNotifyData(activeTab === "testCases" ? dataTC : dataTP)
    setNoNotification(dataTC?.length + dataTP?.length)
  }

  useEffect(() => {
    setNoNotification(notifyTCData?.length + notifyTPData?.length)
  }, [notifyTCData, notifyTPData])

  const handleBulkEdit = async (models_name, details) => {
    let ids
    if (models_name === "test_case") {
      ids = notifyData
        ?.filter(data => data.event_name === details)
        .map(item => item.id)
    } else if (models_name === "test_plan") {
      ids = notifyData
        ?.filter(data => data.event_name === details)
        .map(item => item.id)
    }

    let payload = {
      notification_id: ids,
    }
    try {
      await dispatch(bulkNotificationFlag(payload))
    } catch (error) {
      console.log(error)
    }
  }

  const layoutModeType = useSelector(state => state.Layout.layoutModeType)
  useEffect(() => {
    if (
      selectedProject?.value !== null &&
      selectedProject?.value !== undefined
    ) {
      fetchNotifyData()
      setNotifyData(activeTab === "testCases" ? notifyTCData : notifyTPData)
    }
  }, [activeTab, globalProject?.value, tpData, tcData, selectedProject?.value])

  const handleIntegrationClick = integrationName => {
    dispatch(setEditIntegration({ isEdit: false, data: null }))
    localStorage.removeItem("productIntegration")
    localStorage.setItem("productIntegration", integrationName)
    setsocialDrp(false)
  }

  const handleCloseNotification = () => {
    setNotification(false)
  }

  const handleModules = module => {
    setActiveTab(module)
  }

  const handleBulkNotify = (models_name, details) => {
    handleBulkEdit(models_name, details)
    fetchNotifyData()
    setNotification(false)
  }

  // const handleSummaryPage = async (models_name, details) => {
  //   if (models_name === "test_case") {
  //     const ids = await notifyData?.filter((data) => data.event_name === details).map(item => item.id)
  //     setBulkData(ids)
  //   } else if (models_name === "test_plan") {
  //     const ids = await notifyData?.filter((data) => data.event_name === details).map((item) => item.id)
  //     setBulkData(ids)
  //   }
  //   await handleBulkNotify()
  // }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img
                    src={logohalf}
                    alt=""
                    height="55"
                    style={{ marginLeft: "-15px" }}
                  />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img
                    src={logohalf}
                    alt=""
                    height="55"
                    style={{ marginLeft: "-15px" }}
                  />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
            <GlobalSelectProject />

            {/* <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder={props.t("Search") + "..."}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form> */}

            {/* <Dropdown
              className="dropdown-mega d-none d-lg-block ms-2"
              isOpen={megaMenu}
              toggle={() => {
                setmegaMenu(!megaMenu);
              }}
            >
              <DropdownToggle
                className="btn header-item "
                caret
                tag="button"
              >
                {" "}
                {props.t("Mega Menu")} <i className="mdi mdi-chevron-down" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-megamenu">
                <Row>
                  <Col sm={8}>
                    <Row>
                      <Col md={4}>
                        <h5 className="font-size-14 mt-0">
                          {props.t("UI Components")}
                        </h5>
                        <ul className="list-unstyled megamenu-list">
                          <li>
                            <Link to="#">{props.t("Lightbox")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Range Slider")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Sweet Alert")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Rating")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Forms")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Tables")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Charts")}</Link>
                          </li>
                        </ul>
                      </Col>

                      <Col md={4}>
                        <h5 className="font-size-14 mt-0">
                          {props.t("Applications")}
                        </h5>
                        <ul className="list-unstyled megamenu-list">
                          <li>
                            <Link to="#">{props.t("Ecommerce")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Calendar")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Email")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Projects")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Tasks")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Contacts")}</Link>
                          </li>
                        </ul>
                      </Col>

                      <Col md={4}>
                        <h5 className="font-size-14 mt-0">
                          {props.t("Extra Pages")}
                        </h5>
                        <ul className="list-unstyled megamenu-list">
                          <li>
                            <Link to="#">{props.t("Light Sidebar")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Compact Sidebar")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Horizontal layout")}</Link>
                          </li>
                          <li>
                            <Link to="#"> {props.t("Maintenance")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Coming Soon")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Timeline")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("FAQs")}</Link>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={4}>
                    <Row>
                      <Col sm={6}>
                        <h5 className="font-size-14 mt-0">
                          {props.t("UI Components")}
                        </h5>
                        <ul className="list-unstyled megamenu-list">
                          <li>
                            <Link to="#">{props.t("Lightbox")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Range Slider")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Sweet Alert")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Rating")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Forms")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Tables")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Charts")}</Link>
                          </li>
                        </ul>
                      </Col>

                      <Col sm={5}>
                        <div>
                          <img
                            src={megamenuImg}
                            alt=""
                            className="img-fluid mx-auto d-block"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </DropdownMenu>
            </Dropdown> */}
          </div>
          <div className="d-flex ">
            <Dropdown
              className="d-none d-lg-inline-block ms-1"
              isOpen={socialDrp}
              toggle={() => {
                setsocialDrp(!socialDrp)
              }}
            >
              <DropdownToggle
                className="btn header-item noti-icon "
                tag="button"
              >
                <i className="bx bx-customize" title="Integration Product" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
                <div className="px-lg-2">
                  <Row className="no-gutters">
                    <Col>
                      <Link
                        className="dropdown-icon-item"
                        to="/Settings/CreateIntegration"
                        onClick={() => handleIntegrationClick("GitHub")}
                      >
                        <img src={github} alt="GitHub" />
                        <span>GitHub</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link
                        className="dropdown-icon-item"
                        to="/Settings/CreateIntegration"
                        onClick={() => handleIntegrationClick("Jira")}
                      >
                        <img src={jira} alt="jira" />
                        <span>Jira</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link
                        className="dropdown-icon-item"
                        to="/Settings/CreateIntegration"
                        onClick={() => handleIntegrationClick("BrowserStack")}
                      >
                        <img src={browserStack} alt="browserStack" />
                        <span>Browser Stack</span>
                      </Link>
                    </Col>
                  </Row>

                  <Row className="no-gutters">
                    <Col>
                      <Link
                        className="dropdown-icon-item"
                        to="/Settings/CreateIntegration"
                        onClick={() => handleIntegrationClick("Plane")}
                      >
                        <img src={plane} alt="plane" />
                        <span>Plane</span>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </DropdownMenu>
            </Dropdown>

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i
                  className={
                    isFullscreen ? "bx bx-exit-fullscreen" : "bx bx-fullscreen"
                  }
                />{" "}
              </button>
            </div>
            <QuickAction />
            <div
              onClick={handleRequest}
              className="dropdown d-inline-block button-width-sm"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle"
                title="Request Page"
              >
                <i className="mdi mdi-comment-plus-outline" />
              </button>
            </div>

            <Dropdown
              className="d-inline-block ms-1 button-width-sm"
              isOpen={notification}
              toggle={() => {
                setNotification(!notification)
              }}
            >
              <DropdownToggle
                className="btn header-item noti-icon position-relative"
                tag="button"
                id="page-header-notifications-dropdown"
              >
                <i className="mdi mdi-bell-outline" title="Notifications" />
                {noNofitification > 0 && (
                  <span className="badge bg-danger rounded-pill">
                    {noNofitification}
                  </span>
                )}
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
                <div className="p-3">
                  <Row className="align-items-center">
                    <Col>
                      <h6 className="m-0 text-primary">Notification</h6>
                    </Col>
                    <div
                      style={{ cursor: "pointer" }}
                      className="col-auto"
                      onClick={handleCloseNotification}
                      title="Close"
                    >
                      x
                    </div>
                  </Row>
                </div>

                <div className="px-3">
                  <div className="d-flex bg-light align-items-center text-center rounded">
                    <div
                      className={`col-6 m-1 rounded ${
                        activeTab === "testCases"
                          ? layoutModeType === "dark"
                            ? "bg-secondary"
                            : "bg-white"
                          : "bg-light"
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleModules("testCases")}
                    >
                      <div className="p-2">
                        Test Cases{" - "}
                        <span>{notifyTCData.length}</span>
                      </div>
                    </div>

                    <div
                      className={`col-5 m-1 rounded ${
                        activeTab === "testPlan"
                          ? layoutModeType === "dark"
                            ? "bg-secondary"
                            : "bg-white"
                          : "bg-light"
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleModules("testPlan")}
                    >
                      <div className="p-2">
                        Test Plans{" - "}
                        <span>{notifyTPData.length}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <SimpleBar style={{ height: "230px" }}>
                  {Array?.isArray(notifyData) && notifyData?.length !== 0 ? (
                    notifyData?.map((data, index) => (
                      <Link
                        to={
                          data?.models_name === "test_case"
                            ? data?.event_name === "Test Case Approval Required"
                              ? "/RequestPage"
                              : "/TestManagement/TestCase"
                            : data?.is_signoff
                            ? "/TestPlan/ViewTestPlan"
                            : data?.event_name === "Test Plan Approval Required"
                            ? "/RequestPage/TestPlanRequest"
                            : "/TestPlan"
                        }
                        className="text-reset notification-item"
                        key={index}
                      >
                        <div
                          className="d-flex flex-column justify-content-between"
                          onClick={() => {
                            handleBulkNotify(
                              data?.models_name,
                              data?.event_name
                            )
                          }}
                        >
                          <div>
                            <h6 className="mt-0 mb-1">{data.message}</h6>
                          </div>
                          <div className="text-muted d-flex p-0 justify-content-between text-right">
                            <p className="small text-muted mb-0">
                              {data?.created_by_name}
                            </p>
                            <span className="small text-muted mb-0">
                              {data?.created_at
                                ? new Date(data.created_at).toLocaleDateString("en-GB", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                }) +
                                "-" +
                                new Date(data.created_at).toLocaleTimeString("en-GB", {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                  hour12: true,
                                })
                                : "-"}
                            </span>
                          </div>
                        </div>
                      </Link>
                    ))
                  ) : (
                    <div className="d-flex justify-content-center mt-4">No notifications available</div> // Fallback message
                  )}
                </SimpleBar>
              </DropdownMenu>
            </Dropdown>
            <div
              onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar)
              }}
              className="dropdown d-inline-block button-width-sm"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle"
              >
                <i className="bx bx-cog" title="Settings" />
              </button>
            </div>
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
