import { toast } from "react-toastify"
import httpInjectorService from "services/http-Injector.Service"
import { setAllDbIntegration } from "store/Slices/DbIntegrationSlice"
import { setLoading } from "store/Slices/LoadingSlice"

export const fetchAllDbIntegrationList = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.getAllDbIntegrationAPI(
        queryString
      )
      const data = response
      dispatch(setAllDbIntegration(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const GetDbIntegrationIdAPI = id => {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.GetDbIntegrationById(id)
      const data = response
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const deleteDbIntegrationAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.deleteDbIntegration(bodyoption)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "deleteDbIntegration Deleted successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const BulkDeleteDbIntegrationAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (bodyoption[key] !== undefined && bodyoption[key] !== null) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.BulkDeleteDbIntegration(
        formData
      )
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Test Scenario Deleted successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const createDbIntegrationAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.createDbIntegration(formData)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "created successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      } else {
        toast.error(response.db_connection_name[0] || response.message, {
          position: "top-right",
          id: "created successfully",
          autoClose: 3000,
        })
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const EditDbIntegrationAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.EditDbIntegration(
        formData,
        bodyoption?.id
      )

      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "updated successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      } else {
        toast.error(response.db_connection_name[0] || response.message, {
          position: "top-right",
          id: "created successfully",
          autoClose: 3000,
        })
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
