import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import { useSelector } from "react-redux";
import HighchartsMore from "highcharts/highcharts-more"; // Required for gauge charts

// Initialize the module for gauge chart
HighchartsMore(Highcharts);

const PolarChart2 = (props) => {
  const dataColors = ['#28a745', '#ffc107', '#dc3545'];
  const chartRef = useRef(null);
  const layoutModeType = useSelector((state) => state.Layout.layoutModeType);

  useEffect(() => {
    if (!props.data) {
      return;
    }

    // Extract data
    const totalTestDefects = parseInt(props.data.total_test_defects, 10);
    const Major_count = parseInt(props.data.Major_count, 10) || 0;
    const Minor_count = parseInt(props.data.Minor_count, 10) || 0;
    const Blocker_count = parseInt(props.data.Blocker_count, 10) || 0;

    // Calculate the weighted score only if totalTestDefects is not zero
    let weightedScore = 0;
    if (totalTestDefects > 0) {
      weightedScore = ((Major_count * 9) + (Minor_count * 8) + (Blocker_count * 3)) / totalTestDefects * 10;
    }

    // Format the score to two decimal places
    const formattedScore = weightedScore.toFixed(2);

    // Extract percentages for gauge display
    const Blocker_percentage = parseFloat(props.data.Blocker_percentage) || 0;
    const Major_percentage = parseFloat(props.data.Major_percentage) || 0;
    const Minor_percentage = parseFloat(props.data.Minor_percentage) || 0;

    const totalPercentage = Blocker_percentage + Major_percentage + Minor_percentage;

    const chartOptions = {
      chart: {
        type: "gauge",
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        backgroundColor: layoutModeType === "dark" ? "#2a3042" : "white",
        height: "60%",
      },
      title: {
        text: "", // Set title to an empty string to remove it
        style: {
          color: layoutModeType === "dark" ? "#ffffff" : "#000000",
        },
      },
      pane: {
        startAngle: -90,
        endAngle: 90,
        background: null,
        center: ['50%', '75%'],
        size: '100%',
      },
    
      yAxis: {
        min: 0,
        max: 100,
        tickPixelInterval: 72,
        tickPosition: 'inside',
        tickLength: 20,
        tickWidth: 2,
        labels: {
          distance: 20,
          style: {
            fontSize: "14px",
            color: layoutModeType === "dark" ? "#ffffff" : "#000000",
          },
        },
        lineWidth: 0,
        plotBands: totalPercentage === 0 ? [
          {
            from: 0,
            to: 100,
            color: dataColors[0], // Green for 0% risk
            thickness: 20,
          }
        ] : [
          {
            from: 0,
            to: Minor_percentage,
            color: dataColors[0], // Green for Minor
            thickness: 20,
          },
          {
            from: Minor_percentage,
            to: Minor_percentage + Major_percentage,
            color: dataColors[1], // Yellow for Major
            thickness: 20,
          },
          {
            from: Minor_percentage + Major_percentage,
            to: 100,
            color: dataColors[2], // Red for Blocker
            thickness: 20,
          },
        ],
      },
    
      series: [
        {
          name: "Defects",
          data: [totalTestDefects > 0 ? parseFloat(formattedScore) : 0], // Show 0 if all counts are zero
          tooltip: {
            valueSuffix: " %",
            pointFormatter: function() {
              return formattedScore === "0.00"
                ? "<strong>No risks detected</strong>"
                : ` 
                  <strong>Minor: ${Minor_count}</strong><br/>
                  <strong>Major: ${Major_count}</strong><br/>
                  <strong>Blocker: ${Blocker_count}</strong><br/>
                  <strong>Risk: ${formattedScore} %</strong>
                `;
            }
          },
          dataLabels: {
            format: "{y} %",
            borderWidth: 0,
            color: layoutModeType === "dark" ? "#ffffff" : "#333333",
            style: {
              fontSize: "16px",
            },
          },
          dial: {
            radius: "80%",
            backgroundColor: layoutModeType === "dark" ? "#ffffff" : "#000000",
            baseWidth: 20,
            baseLength: "0%",
            rearLength: "0%",
          },
          pivot: {
            backgroundColor: layoutModeType === "dark" ? "#ffffff" : "gray",
            radius: 6,
          },
        },
      ],
      credits: {
        enabled: false,  
      },
    };

    // Initialize chart
    chartRef.current = Highcharts.chart("speedometer-chart", chartOptions);

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [props.data, layoutModeType]);

  return (
    <div>
      <div
        id="speedometer-chart"
        style={{
          height: "295px",
          borderRadius: "10px",
        }}
      />
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
          <span style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: dataColors[0], borderRadius: '50%', marginRight: '5px' }}></span>
          <span>Minor</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
          <span style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: dataColors[1], borderRadius: '50%', marginRight: '5px' }}></span>
          <span>Major</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: dataColors[2], borderRadius: '50%', marginRight: '5px' }}></span>
          <span>Blocker</span>
        </div>
      </div>
    </div>
  );
};

export default PolarChart2;
