import { createSlice } from "@reduxjs/toolkit"

const ActionSuiteSlice = createSlice({
    name: "ActionSuite",
    initialState: {
        allActionSuiteList: "",
        NextAutomationSuiteId: [],
        editAutomationSuitData:[],
        singleAutomationSuiteData:[]
    },

    reducers: {
        setAllActionSuiteList: (state, action) => ({
            ...state,
            allActionSuiteList: action.payload,
        }),
        setNextAutomationSuiteId: (state, action) => ({
            ...state,
            NextAutomationSuiteId: action.payload,
        }),
        setEditAutomationSuite: (state, action) => ({
            ...state,
            editAutomationSuitData: action.payload,
        }),
        setSingleAutomationSuite: (state, action) => ({
            ...state,
            singleAutomationSuiteData: action.payload,
        }),
    },
})

export const { setAllActionSuiteList, setSingleAutomationSuite ,setNextAutomationSuiteId , setEditAutomationSuite} = ActionSuiteSlice.actions

export default ActionSuiteSlice.reducer
