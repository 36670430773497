import React, { useEffect, useState, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import DeleteModal from "components/Common/DeleteModal"
import { useSelector, useDispatch } from "react-redux"
import { toast, ToastContainer } from "react-toastify"
import {
  deleteTestPlanAPI,
  fetchAllTestPlanList,
  fetchNextTestPlanIdAPI,
  GetTestPlanByIdAPI,
} from "store/actions/TestPlanActions"
import moment from "moment"
import { getStatusBackGroundColor } from "helpers/helper"
import { setEditTestPlan } from "store/Slices/TestPlanSlice"
import TableContainer from "components/Common/helper/TableContainer"
import { setTpList } from "store/Slices/RequestPageSlice"
import NoPermission from "components/Common/NoPermission"

const TestPlanMain = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))

  const Permission = JSON.parse(localStorage.getItem("authUser"))
  const testPlanPermission =
    Permission?.permissions[0]?.menu_item?.TestLab?.Testplan

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const [data, setData] = useState([])

  const allTestScenario = useSelector(
    state => state?.TestScenario?.allTestScenario
  )
  useEffect(() => {
    if (allTestScenario && allTestScenario.Data) {
      setData(allTestScenario)
    }
  }, [allTestScenario])

  const handleSingleEditClick = async id => {
    if (testPlanPermission?.edit) {
      try {
        const res = await dispatch(GetTestPlanByIdAPI(id))
        if (res?.approval_status === "Pending Approval") {
          toast.error("Pending Approval test plan cannot be edited", {
            position: "top-right",
            id: " Error ",
            autoClose: 3000,
          })
        } else {
          dispatch(setEditTestPlan({ isEdit: true, data: res }))
          navigate(`/TestPlan/EditTestPlan`)
        }
      } catch (error) {
        console.error("Error fetching data", error)
      }
    } else {
      toast.warn("You don't have permission to edit Test Plan", {
        position: "top-right",
        autoClose: 3000,
      })
    }
  }

  const [viewData, setViewData] = useState(null)
  const handleView = async id => {
    if (testPlanPermission?.view) {
      try {
        const res = await dispatch(GetTestPlanByIdAPI(id))
        setViewData(res)
      } catch (error) {
        console.error("Error fetching data", error)
      }
    } else {
      toast.warn("You don't have permission to view details of Test Plan", {
        position: "top-right",
        autoClose: 3000,
      })
    }
  }
  useEffect(() => {
    if (viewData) {
      navigate("/TestPlan/ViewTestPlan")
    }
  }, [viewData])

  const bodyOption = {
    project_id: globalProject?.value || selectedProject?.value,
  }

  const handleCreateClick = () => {
    if (testPlanPermission?.create) {
      dispatch(fetchNextTestPlanIdAPI(bodyOption))
      navigate("CreateTestPlan")
    } else {
      toast.warn("You don't have permission to create Test Plan", {
        position: "top-right",
        autoClose: 3000,
      })
    }
  }

  const [deleteModal, setDeleteModal] = useState(false)
  const [isDeleteId, setIsDeleteId] = useState("")

  const onClickDelete = data => {
    // First, check if the user has delete permissions
    if (testPlanPermission?.delete) {
      // If delete permission is granted, then check approval status
      if (data?.approval_status === "Pending Approval") {
        toast.error("Pending Approval test plan cannot be deleted", {
          position: "top-right",
          autoClose: 3000,
        })
      } else {
        // If approval status is not "Pending Approval", proceed with deletion
        setIsDeleteId(data?.id)
        setDeleteModal(true)
      }
    } else {
      // If no delete permission, show a warning
      toast.warn("You don't have permission to delete Test Plan", {
        position: "top-right",
        autoClose: 3000,
      })
    }
  }

  const columns = useMemo(
    () => [
      {
        header: "Test Plan Id",
        accessorKey: "test_plan_id",
        cell: cell => <div>{cell.getValue()}</div>,
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return <>{cell.getValue()}</>
        },
      },
      {
        header: "Test Plan Name",
        accessorKey: "test_plan_name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return (
            <>
              <p
                className="font-size-14 mb-1"
                style={{
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  whiteSpace: "normal",
                  maxWidth: "300px", // Set the max-width to control the wrapping
                }}
              >
                {cell.getValue()}
              </p>
            </>
          )
        },
      },
      {
        header: "Start Date",
        accessorKey: "start_date",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const dateValue = cell.getValue()
          return <>{dateValue ? moment(dateValue).format("DD-MM-YYYY") : "-"}</>
        },
      },
      {
        header: "End Date",
        accessorKey: "end_date",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const dateValue = cell.getValue()
          return <>{dateValue ? moment(dateValue).format("DD-MM-YYYY") : "-"}</>
        },
      },
      {
        header: "Assign To",
        accessorKey: "assign_to_id",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const assignTo = cell.getValue() // This is the array of user objects
          const maxAvatarsToShow = 5 // Number of avatars to display before showing "+ more"
          const projectName = "ProjectName" // Replace with your actual project name variable

          // If no data, return a default value
          if (!assignTo || assignTo.length === 0) {
            return <span>-</span>
          }

          const usersToShow = assignTo.slice(0, maxAvatarsToShow)
          const remainingCount = assignTo.length - maxAvatarsToShow

          return (
            <td>
              <div style={{ display: "flex", alignItems: "center" }}>
                {usersToShow.map((user, userIndex) => {
                  // Generate a unique ID for each avatar based on the user ID and project name
                  const id = `member-${projectName.replace(
                    /[^a-zA-Z0-9]/g,
                    ""
                  )}-${user.id}`

                  return (
                    <div
                      key={user.id}
                      style={{ display: "inline-block", marginRight: "-8px" }}
                    >
                      <div className="avatar-group">
                        <div className="avatar-group-item">
                          <Link to="#" className="d-inline-block" id={id}>
                            <div className="avatar-xxs">
                              <span className="avatar-title rounded-circle bg-primary text-light font-size-14">
                                {user.user_name.charAt(0).toUpperCase()}
                              </span>
                            </div>
                          </Link>
                        </div>
                      </div>
                      {/* Tooltip correctly bound to the unique ID */}
                      <UncontrolledTooltip placement="top" target={id}>
                        {user.user_name}
                      </UncontrolledTooltip>
                    </div>
                  )
                })}
                {remainingCount > 0 && (
                  <div className="avatar-group">
                    <div className="avatar-group-item">
                      <Link
                        to="#"
                        className="d-inline-block"
                        id={`member-${projectName.replace(
                          /[^a-zA-Z0-9]/g,
                          ""
                        )}-more`}
                      >
                        <div className="avatar-xxs">
                          <span className="avatar-title rounded-circle bg-primary text-light font-size-14">
                            {remainingCount}+
                          </span>
                        </div>
                      </Link>
                    </div>
                    <UncontrolledTooltip
                      placement="top"
                      target={`member-${projectName.replace(
                        /[^a-zA-Z0-9]/g,
                        ""
                      )}-more`}
                    >
                      {remainingCount} more
                    </UncontrolledTooltip>
                  </div>
                )}
              </div>
            </td>
          )
        },
      },
      {
        header: "Test Plan Status",
        accessorKey: "test_plan_status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const test_plan_status = cell.getValue()
          return (
            <>
              <Badge
                className={
                  "badge-soft-" +
                  `${getStatusBackGroundColor(test_plan_status)}`
                }
                style={{
                  fontSize: "11px", // Increase font size
                  padding: "0.5em", // Increase padding
                  borderRadius: "0.5em", // Adjust border radius
                }}
              >
                {test_plan_status || "-"}
              </Badge>
            </>
          )
        },
      },
      {
        header: "Approval Status",
        accessorKey: "approval_status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const approval_status = cell.getValue()
          return (
            <>
              <Badge
                className={
                  "badge-soft-" + `${getStatusBackGroundColor(approval_status)}`
                }
                style={{
                  fontSize: "11px", // Increase font size
                  padding: "0.5em", // Increase padding
                  borderRadius: "0.5em", // Adjust border radius
                }}
              >
                {approval_status || "-"}
              </Badge>
            </>
          )
        },
      },
      {
        header: "Action",
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top">
                <span
                  // to="/"
                  className="btn btn-sm btn-soft-primary"
                  onClick={() => {
                    const viewItem = cellProps.row.original
                    handleView(viewItem?.id)
                  }}
                  id={`viewtooltip-${cellProps.row.original.test_scenario_id}`}
                >
                  <i className="mdi mdi-eye-outline" />
                </span>
              </li>
              <UncontrolledTooltip
                placement="top"
                target={`viewtooltip-${cellProps.row.original.test_scenario_id}`}
              >
                View
              </UncontrolledTooltip>

              <li>
                <span
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    const editItem = cellProps.row.original
                    handleSingleEditClick(editItem?.id)
                  }}
                  id={`edittooltip-${cellProps.row.original.test_scenario_id}`}
                >
                  <i className="mdi mdi-pencil-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edittooltip-${cellProps.row.original.test_scenario_id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </span>
              </li>

              <li>
                <span
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const userData = cellProps.row.original
                    onClickDelete(userData)
                  }}
                  id={`deletetooltip-${cellProps.row.original.test_scenario_id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`deletetooltip-${cellProps.row.original.test_scenario_id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </span>
              </li>
            </ul>
          )
        },
      },
    ],
    [data]
  )

  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [globalFilter, setGlobalFilter] = useState("")
  const [totalCount, setTotalCount] = useState(null)

  const { testScenarioFilterFrom } = useSelector(state => state.TestScenario)
  const fetchTableData = async (page, size, search) => {
    let userId = localStorage.getItem("authUser")
    let userData = JSON.parse(userId)
    let bodyoption = {
      page: page + 1,
      page_size: size,
      search: search,
      project_id: globalProject?.value || selectedProject?.value,
    }
    let payload = {
      receiver: userData.user_id,
      models_name: "test_plan",
      project_id: globalProject?.value || selectedProject?.value,
    }
    try {
      const data = await dispatch(fetchAllTestPlanList(bodyoption))
      dispatch(setTpList({ tpData: true }))
      setData(data.Data)
      setTotalCount(data.TotalCount)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  useEffect(() => {
    fetchTableData(pageIndex, pageSize, globalFilter)
  }, [
    pageIndex,
    pageSize,
    globalFilter,
    globalProject?.value,
    testScenarioFilterFrom,
  ])

  const handleDelete = async () => {
    try {
      await dispatch(deleteTestPlanAPI(isDeleteId))
      setDeleteModal(false)
      await fetchTableData(pageIndex, pageSize, globalFilter)
      setIsDeleteId("")
    } catch (error) {
      console.error("Error deleting test scenario:", error)
    }
  }

  return (
    <React.Fragment>
      {testPlanPermission?.view ? 
      <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
        message="Test Plan"
      />

      <Container fluid>
        <Row>
          <Col lg="12">
            <Card
              style={{
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
              }}
            >
              <CardBody className="pt-2">
                <div className="table-container">
                  <TableContainer
                    columns={columns}
                    data={data}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    totalCount={totalCount}
                    setPageIndex={setPageIndex}
                    setPageSize={setPageSize}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    isGlobalFilter={true}
                    isPagination={true}
                    SearchPlaceholder="Search..."
                    isCustomPageSize={true}
                    isAddButton={true}
                    handleAddClick={handleCreateClick}
                    buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal ms-2 mb-2"
                    buttonName="Create"
                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                    theadClass="table-light"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    pagination="pagination"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

          <ToastContainer /></>
          : <NoPermission />}
    </React.Fragment>
  )
}

export default withRouter(TestPlanMain)
