import React, { useEffect, useState, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import DeleteModal from "components/Common/DeleteModal"
import { useSelector, useDispatch } from "react-redux"
import { toast, ToastContainer } from "react-toastify"
import TableContainer from "components/Common/TableContainer"
import BulkDeleteModal from "components/Common/BulkDeleteModal"
import moment from "moment"
import {
  AutomationSuiteDetails,
  BulkDeleteAutomationSuite,
  DeleteAutomationSuite,
  GetAutomationSuiteByIdAPI,
  setEditAutomationSuitData,
} from "store/actions/AutomationSuiteAction"
import NoPermission from "components/Common/NoPermission"

const AutomationSuite = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const Permission = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission =
    Permission?.permissions[0]?.menu_item?.TestLab?.Testautomation

  const [data, setData] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [bulkDeleteModal, setBulkDeleteModal] = useState(false)
  const [isDeleteId, setIsDeleteId] = useState("")
  const [selectedRows, setSelectedRows] = useState([])

  const handleSingleEditClick = async data => {
    if (rolePermission.edit) {
      dispatch(setEditAutomationSuitData(data))
      navigate("/TestAutomation/AutomationSuite/EditAutomationSuite")
    } else {
      toast.warn("You don't have the permission to edit automation suite", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const handleViewAutomation = async id => {
    if (rolePermission.view) {
      navigate("/TestAutomation/AutomationSuite/ViewAutomationSuite")
      try {
        const res = await dispatch(GetAutomationSuiteByIdAPI(id))
      } catch (error) {
        console.error("Error fetching data", error)
      }
    } else {
      toast.warn("You don't have the permission to view automation suite", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const handleCreateClick = id => {
    if (rolePermission.create) {
      navigate("/TestAutomation/AutomationSuite/CreateAutomationSuite")
    } else {
      toast.warn("You don't have the permission to create automation suite", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const onClickDelete = automationSuit => {
    if (rolePermission.delete) {
      setIsDeleteId(automationSuit?.id)
      setDeleteModal(true)
    } else {
      toast.warn("You don't have the permission to delete automation suite's", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const handleCheckboxChange = id => {
    setSelectedRows(prevValues =>
      prevValues.includes(id)
        ? prevValues.filter(val => val !== id)
        : [...prevValues, id]
    )
  }

  const handleSelectAll = e => {
    const isChecked = e.target.checked
    if (isChecked) {
      const newSelectedRows = data.map(item => item?.automation_suite_id)
      setSelectedRows(newSelectedRows)
    } else {
      setSelectedRows([])
    }
  }

  const columns = useMemo(
    () => [
      {
        header: (
          <input
            type="checkbox"
            onChange={handleSelectAll}
            style={{ cursor: "pointer" }}
            title="Select All"
            checked={
              Array.isArray(selectedRows) &&
              selectedRows.length === data?.length &&
              data?.length > 0
            }
          />
        ),
        accessorKey: "checkbox",
        cell: cellProps => {
          const jobData = cellProps.row.original?.automation_suite_id

          const isChecked = () => {
            if (!Array.isArray(selectedRows)) {
              console.error("selectedRows is not an array")
              return false
            }
            return selectedRows.includes(jobData)
          }

          const handleCheckboxClick = () => {
            try {
              if (!Array.isArray(selectedRows)) {
                throw new Error("selectedRows is not an array")
              }
              handleCheckboxChange(jobData)
            } catch (error) {
              console.error(error.message)
            }
          }

          return (
            <input
              type="checkbox"
              style={{ cursor: "pointer" }}
              checked={isChecked()}
              onChange={handleCheckboxClick}
              onClick={handleCheckboxClick}
            />
          )
        },
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Automation Suite Id	",
        accessorKey: "automation_suite_id",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Automation Suite Name	",
        accessorKey: "automation_suite_name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const automation_suite = cell.getValue()
          return (
            <>
              <p
                className="font-size-14 mb-1"
                style={{
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  whiteSpace: "normal",
                  maxWidth: "300px", // Set the max-width to control the wrapping
                }}
              >
                {automation_suite ? automation_suite : "-"}
              </p>
            </>
          )
        },
      },
      {
        header: "Build",
        accessorKey: "build",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const build = cell.getValue()
          return (
            <>
              <p
                className="font-size-14 mb-1" 
              >
                {build ? build : "-"}
              </p>
            </>
          )
        },
      },
      {
        header: "Application Type	",
        accessorKey: "automation_suit_type",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Created By	",
        accessorKey: "created_by_name.name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Created Date	",
        accessorKey: "created_at",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return (
            <>
              <p className="mb-2">
                {moment(cell.getValue()).format("DD-MM-YYYY")}
              </p>
            </>
          )
        },
      },
      {
        header: "Action",
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top">
                <span
                  // to="/"
                  className="btn btn-sm btn-soft-primary"
                  onClick={() => {
                    const AutomationSuite = cellProps.row.original
                    handleViewAutomation(AutomationSuite?.id)
                  }}
                  id={`viewtooltip-${cellProps.row.original?.id}`}
                >
                  <i className="mdi mdi-eye-outline" />
                </span>
              </li>
              <UncontrolledTooltip
                placement="top"
                target={`viewtooltip-${cellProps.row.original?.id}`}
              >
                View
              </UncontrolledTooltip>

              <li>
                <span
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    const data = cellProps.row.original
                    handleSingleEditClick(data)
                  }}
                  id={`edittooltip-${cellProps.row.original?.id}`}
                >
                  <i className="mdi mdi-pencil-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edittooltip-${cellProps.row.original?.id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </span>
              </li>

              <li>
                <span
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const userData = cellProps.row.original
                    onClickDelete(userData)
                  }}
                  id={`deletetooltip-${cellProps.row.original?.id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`deletetooltip-${cellProps.row.original?.id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </span>
              </li>
            </ul>
          )
        },
      },
    ],
    [selectedRows, data]
  )

  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [globalFilter, setGlobalFilter] = useState("")
  const [totalCount, setTotalCount] = useState(null)

  const fetchTableData = async (page, size, search) => {
    let bodyoption = {
      page: page + 1,
      page_size: size,
      search: search,
      project_id: globalProject?.value || selectedProject?.value,
    }
    try {
      const data = await dispatch(AutomationSuiteDetails(bodyoption))
      setData(data.Data)
      setTotalCount(data.TotalCount)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  useEffect(() => {
    fetchTableData(pageIndex, pageSize, globalFilter)
  }, [pageIndex, pageSize, globalFilter, globalProject?.value])

  const handleDelete = async () => {
    console.log("in")
    try {
      await dispatch(DeleteAutomationSuite(isDeleteId))
      setDeleteModal(false)
      await fetchTableData(pageIndex, pageSize, globalFilter)
      setIsDeleteId("")
    } catch (error) {
      console.error("Error deleting test scenario:", error)
    }
  }

  const handleDeleteClick = async () => {
    if (rolePermission.delete) {
      try {
        if (selectedRows?.length > 0) {
          setBulkDeleteModal(true)
        } else {
          toast.warn(
            "Please select a suite before attempting to bulk delete.",
            {
              position: "top-right",
              id: "selectBeforeDelete",
              autoClose: 3000,
            }
          )
        }
      } catch (error) {
        console.error("Error deleting test data:", error)
      }
    } else {
      toast.warn("You don't have the permission to delete automation suite", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }
  const handleBulkDelete = async () => {
    const bodyoption = {
      project_id: globalProject?.value || selectedProject?.value,
      automation_suite_id: selectedRows,
    }
    try {
      await dispatch(BulkDeleteAutomationSuite(bodyoption))
      setSelectedRows([])
      setBulkDeleteModal(false)
      await fetchTableData(pageIndex, pageSize, globalFilter)
    } catch (error) {
      console.error("Error deleting test data:", error)
    }
  }

  return (
    <React.Fragment>
      {rolePermission.view ? 
      <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
        message="Automation Suite"
      />
      <BulkDeleteModal
        show={bulkDeleteModal}
        onDeleteClick={handleBulkDelete}
        onCloseClick={() => {
          setBulkDeleteModal(false)
          setSelectedRows([])
        }}
        message="Automation Suites"
      />

      <Container fluid>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody style={{ marginTop: "-12px" }}>
                <div className="table-container">
                  <TableContainer
                    columns={columns}
                    data={data}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    totalCount={totalCount}
                    setPageIndex={setPageIndex}
                    setPageSize={setPageSize}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    isGlobalFilter={true}
                    isPagination={true}
                    SearchPlaceholder="Search..."
                    isCustomPageSize={true}
                    isAddButton={true}
                    isAddDelete={true}
                    isAddEdit={false}
                    isAddFilter={false}
                    handleAddClick={handleCreateClick}
                    handleDeleteClick={handleDeleteClick}
                    buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal ms-2 mb-2"
                    buttonName="Create"
                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                    theadClass="table-light"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    pagination="pagination"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

        <ToastContainer />
      </> : <NoPermission />}
    </React.Fragment>
  )
}

export default withRouter(AutomationSuite)
