import React from "react";
import { useDispatch,useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from "reactstrap";
import { setSyncPopup } from "store/Slices/DefectsSlice";
import { FaUserTag } from "react-icons/fa6";

const SyncViewPage = () => {

    const dispatch = useDispatch()
    const syncPopup = useSelector(
        state => state?.Defects?.syncPopup
      )
    
    const handleData = syncPopup?.data?.data
    console.log("syncPopupData",syncPopup?.data);
    
    const handleToggle = () =>{
        dispatch(setSyncPopup({ popupOpen: false, data: null }))
    }

    return (
        <Modal isOpen={syncPopup?.popupOpen} toggle={handleToggle} centered size="md">
            <ModalHeader toggle={handleToggle}><FaUserTag size={25} /></ModalHeader>
            <ModalBody>
                <div>Click here <a
            style={{marginLeft:'1.5%',marginRight:'1.5%'}}
            href={handleData?.plane_url ? handleData?.plane_url : handleData?.jira_url} 
            target="_blank"
            rel="noopener noreferrer">
            {handleData?.plane_id ? handleData?.plane_id :handleData?.jira_id}</a> 
              to assign the ticket or to add attachments</div>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
                <Button color="primary" onClick={handleToggle}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default SyncViewPage;
