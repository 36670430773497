import React, { useEffect, useState, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { toast, ToastContainer } from "react-toastify"
import BulkDeleteModal from "components/Common/BulkDeleteModal"
import FilterDefects from "./Filter Defects/FilterDefects"
import BulkEditDefects from "./Edit Defect/BulkEditDefects"
import { getStatusBackGroundColor } from "helpers/helper"
import moment from "moment"
import {
  BulkDeleteDefectsAPI,
  BulkEditDefectsAPI,
  deleteDefectAPI,
  fetchNextDefectID,
  getAllDefectsListAPI,
  GetDefectByIdAPI,
} from "store/actions/DefectsActions"
import DeleteModal from "components/Common/DeleteModal"
import { clearFilters, setEditDefect } from "store/Slices/DefectsSlice"
import TableContainer from "components/Common/helper/TableContainer"
import { getAllMapProject } from "store/actions/IntegrationAction"
import SyncViewPage from "./SyncViewPage/SyncViewPage"
import NoPermission from "components/Common/NoPermission"

const Defects = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))
  const { defectsFilterFrom } = useSelector(state => state.Defects)

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const permission = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission =
    permission?.permissions?.[0]?.menu_item?.DefectManagement
  const [data, setData] = useState([])

  const allTestScenario = useSelector(
    state => state?.TestScenario?.allTestScenario
  )
  const syncPopup = useSelector(state => state?.Defects?.syncPopup)

  useEffect(() => {
    if (allTestScenario && allTestScenario.Data) {
      setData(allTestScenario)
    }
  }, [allTestScenario])

  const handleSingleEditClick = async id => {
    if (rolePermission.defectmanagement.edit) {
      try {
        const res = await dispatch(GetDefectByIdAPI(id))
        dispatch(setEditDefect({ isEdit: true, data: res }))
        navigate("/DefectManagement/CreateEditDefect")
      } catch (error) {
        console.error("Error fetching data", error)
      }
    } else {
      toast.warn("You don't have the permission to edit defect", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const [viewData, setViewData] = useState(null)

  const handleViewTestScenario = async id => {
    if (rolePermission.defectmanagement.view) {
      try {
        const res = await dispatch(GetDefectByIdAPI(id))
        setViewData(res)
      } catch (error) {
        console.error("Error fetching data", error)
      }
    } else {
      toast.warn("You don't have the permission to view defect", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }
  useEffect(() => {
    if (viewData) {
      navigate("/DefectManagement/ViewDefect")
    } else {
      navigate("/DefectManagement")
    }
  }, [viewData])

  const bodyOption = {
    project_id: globalProject?.value || selectedProject?.value,
  }

  const handleCreateClick = () => {
    if (rolePermission.defectmanagement.create) {
      dispatch(fetchNextDefectID(bodyOption))
      dispatch(setEditDefect({ isEdit: false, data: null }))
      navigate("/DefectManagement/CreateEditDefect")
    } else {
      toast.warn("You don't have the permission to create defect", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const [deleteModal, setDeleteModal] = useState(false)
  const [bulkDeleteModal, setBulkDeleteModal] = useState(false)
  const [isDeleteId, setIsDeleteId] = useState("")

  const onClickDelete = data => {
    if (rolePermission.defectmanagement.delete) {
      setIsDeleteId(data?.id)
      setDeleteModal(true)
    } else {
      toast.warn("You don't have the permission to delete defect", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const [selectedRows, setSelectedRows] = useState([])
  const handleCheckboxChange = defect_id => {
    setSelectedRows(prevValues =>
      prevValues.includes(defect_id)
        ? prevValues.filter(val => val !== defect_id)
        : [...prevValues, defect_id]
    )
  }

  const handleSelectAll = e => {
    const isChecked = e.target.checked
    if (isChecked) {
      const newSelectedRows = data.map(item => item.defect_id)
      setSelectedRows(newSelectedRows)
    } else {
      setSelectedRows([])
    }
  }

  const columns = useMemo(
    () => [
      {
        header: (
          <input
            type="checkbox"
            onChange={handleSelectAll}
            style={{ cursor: "pointer" }}
            title="Select All"
            checked={
              Array.isArray(selectedRows) &&
              selectedRows.length === data?.length &&
              data?.length > 0
            }
          />
        ),
        accessorKey: "checkbox",
        cell: cellProps => {
          const jobData = cellProps.row.original.defect_id

          const isChecked = () => {
            if (!Array.isArray(selectedRows)) {
              console.error("selectedRows is not an array")
              return false
            }
            return selectedRows.includes(jobData)
          }

          const handleCheckboxClick = () => {
            try {
              if (!Array.isArray(selectedRows)) {
                throw new Error("selectedRows is not an array")
              }
              handleCheckboxChange(jobData)
            } catch (error) {
              console.error(error.message)
            }
          }

          return (
            <input
              type="checkbox"
              style={{ cursor: "pointer" }}
              checked={isChecked()}
              onChange={handleCheckboxClick}
              onClick={handleCheckboxClick}
            />
          )
        },
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Defect Id",
        accessorKey: "defect_id",
        cell: cell => <div>{cell.getValue()}</div>,
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return (
            <>
              <h5 className="font-size-12 mb-1">{cell.getValue()}</h5>
            </>
          )
        },
      },
      {
        header: "Defect Summary",
        accessorKey: "defect_name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const defect_name = cell.getValue()
          return (
            <div
              style={{
                wordWrap: "break-word",
                overflowWrap: "break-word",
                whiteSpace: "normal",
                maxWidth: "300px", // Set the max-width to control the wrapping
              }}
            >
              {defect_name ? defect_name : "-"}
            </div>
          )
        },
      },
      {
        header: "Test Case",
        accessorKey: "test_case_name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const test_case_name = cell.getValue()
          return <div style={{
            wordWrap: "break-word",
            overflowWrap: "break-word",
            whiteSpace: "normal",
            maxWidth: "300px",// Set the max-width to control the wrapping
          }}>{test_case_name ? test_case_name : "-"}</div>
        },
      },
      {
        header: "Severity",
        accessorKey: "severity",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const severity = cell.getValue()
          return <>{severity ? severity : "-"}</>
        },
      },
      {
        header: "Priority",
        accessorKey: "defect_priority",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const Priority = cell.getValue()
          return <>{Priority ? Priority : "-"}</>
        },
      },
      {
        header: "Status",
        accessorKey: "defect_status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const defect_status = cell.getValue()
          return (
            <>
              <Badge
                className={
                  "badge-soft-" + `${getStatusBackGroundColor(defect_status)}`
                }
                style={{
                  fontSize: "12px", // Increase font size
                  padding: "0.5em", // Increase padding
                  borderRadius: "0.5em", // Adjust border radius
                }}
              >
                {defect_status || "-"}
              </Badge>
            </>
          )
        },
      },
      {
        header: "Defect Type",
        accessorKey: "defect_type",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const defect_type = cell.getValue()
          return <>{defect_type ? defect_type : "-"}</>
        },
      },
      {
        header: "Created At",
        accessorKey: "created_at",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const dateValue = cell.getValue()
          return <>{dateValue ? moment(dateValue).format("DD-MM-YYYY") : "-"}</>
        },
      },
      {
        header: "Action",
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top">
                <span
                  // to="/"
                  className="btn btn-sm btn-soft-primary"
                  onClick={() => {
                    const TestScenarioData = cellProps.row.original
                    handleViewTestScenario(TestScenarioData?.id)
                  }}
                  id={`viewtooltip-${cellProps.row.original.defect_id}`}
                >
                  <i className="mdi mdi-eye-outline" />
                </span>
              </li>
              <UncontrolledTooltip
                placement="top"
                target={`viewtooltip-${cellProps.row.original.defect_id}`}
              >
                View
              </UncontrolledTooltip>

              <li>
                <span
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    const data = cellProps.row.original
                    handleSingleEditClick(data?.id)
                  }}
                  id={`edittooltip-${cellProps.row.original.defect_id}`}
                >
                  <i className="mdi mdi-pencil-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edittooltip-${cellProps.row.original.defect_id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </span>
              </li>

              <li>
                <span
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const userData = cellProps.row.original
                    onClickDelete(userData)
                  }}
                  id={`deletetooltip-${cellProps.row.original.defect_id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`deletetooltip-${cellProps.row.original.defect_id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </span>
              </li>
            </ul>
          )
        },
      },
    ],
    [selectedRows, data]
  )

  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [globalFilter, setGlobalFilter] = useState("")
  const [totalCount, setTotalCount] = useState(null)
  const [isFilterFormOpen, setIsFilterFormOpen] = useState(false)
  const [isEditFormOpen, setIsEditFormOpen] = useState(false)

  const fetchTableData = async (page, size, search) => {
    const {
      filter_defect_id,
      filter_build,
      filter_module,
      filter_requirement,
      filter_browsers,
      filter_os,
      filter_devices,
      filter_severity,
      filter_defect_status,
      filter_assign_to,
      filter_defect_type,
      filter_defect_viewers,
      filter_created_by,
      filter_from_date,
      filter_to_date,
    } = defectsFilterFrom
    let bodyoption = {
      page: page + 1,
      page_size: size,
      search: search,
      project_id: globalProject?.value || selectedProject?.value,
      filter_defect_id,
      filter_build,
      filter_module,
      filter_requirement,
      filter_browsers,
      filter_os,
      filter_devices,
      filter_severity,
      filter_defect_status,
      filter_assign_to,
      filter_defect_type,
      filter_defect_viewers,
      filter_created_by,
      filter_from_date,
      filter_to_date,
    }
    try {
      const data = await dispatch(getAllDefectsListAPI(bodyoption))
      setData(data.Data)
      setTotalCount(data.TotalCount)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  const fetchMapList = async () => {
    let bodyOption = {
      integration_id: null,
    }
    await dispatch(getAllMapProject(bodyOption))
  }
  useEffect(() => {
    fetchTableData(pageIndex, pageSize, globalFilter)
  }, [
    pageIndex,
    pageSize,
    globalFilter,
    globalProject?.value,
    defectsFilterFrom,
  ])

  const handleFilterSubmit = async values => {
    const {
      filter_defect_id,
      filter_build,
      filter_module,
      filter_requirement,
      filter_browsers,
      filter_os,
      filter_devices,
      filter_severity,
      filter_defect_status,
      filter_assign_to,
      filter_defect_type,
      filter_defect_viewers,
      filter_created_by,
      filter_from_date,
      filter_to_date,
    } = values
    setPageIndex(0)
    let bodyoption = {
      page: 0 + 1,
      page_size: pageSize,
      search: globalFilter,
      project_id: globalProject?.value || selectedProject?.value,
      filter_defect_id,
      filter_build,
      filter_module,
      filter_requirement,
      filter_browsers,
      filter_os,
      filter_devices,
      filter_severity,
      filter_defect_status,
      filter_assign_to,
      filter_defect_type,
      filter_defect_viewers,
      filter_created_by,
      filter_from_date,
      filter_to_date,
    }
    try {
      const data = await dispatch(getAllDefectsListAPI(bodyoption))
      setData(data.Data)
      setTotalCount(data.TotalCount)
      setIsFilterFormOpen(false)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  const handleDelete = async () => {
    try {
      await dispatch(deleteDefectAPI(isDeleteId))
      setDeleteModal(false)
      await fetchTableData(pageIndex, pageSize, globalFilter)
      setIsDeleteId("")
    } catch (error) {
      console.error("Error deleting Defect:", error)
    }
  }

  const handleFilterClick = () => {
    setIsFilterFormOpen(!isFilterFormOpen)
  }

  const handleEditClick = () => {
    if (rolePermission.defectmanagement.edit) {
      try {
        if (selectedRows?.length > 0) {
          setIsEditFormOpen(!isEditFormOpen)
        } else {
          toast.warn(
            "Please select a Defects before attempting to bulk edit.",
            {
              position: "top-right",
              id: "selectBeforeDelete",
              autoClose: 3000,
            }
          )
        }
      } catch (error) {
        console.error("Error deleting Defects:", error)
      }
    } else {
      toast.warn("You don't have the permission to edit defect", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }
  const handleDeleteClick = async () => {
    if (rolePermission.defectmanagement.delete) {
      try {
        if (selectedRows?.length > 0) {
          setBulkDeleteModal(true)
        } else {
          toast.warn(
            "Please select a Defects before attempting to bulk delete.",
            {
              position: "top-right",
              id: "selectBeforeDelete",
              autoClose: 3000,
            }
          )
        }
      } catch (error) {
        console.error("Error deleting Defects:", error)
      }
    } else {
      toast.warn("You don't have the permission to delete defect", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const handleBulkDelete = async () => {
    const bodyoption = {
      project_id: globalProject?.value || selectedProject?.value,
      defect_id: selectedRows,
    }
    try {
      await dispatch(BulkDeleteDefectsAPI(bodyoption))
      setBulkDeleteModal(false)
      await fetchTableData(pageIndex, pageSize, globalFilter)
      setSelectedRows([])
    } catch (error) {
      console.error("Error deleting Defects:", error)
    }
  }
  const handleBulkEdit = async data => {
    const bodyOption = {
      ...data,
      project_id: globalProject?.value,
      defect_id: selectedRows,
    }
    try {
      await dispatch(BulkEditDefectsAPI(bodyOption))
      setSelectedRows([])
      setIsEditFormOpen(false)
      await fetchTableData(pageIndex, pageSize, globalFilter)
    } catch (error) {
      console.error("Error updating defect:", error)
    }
  }
  useEffect(() => {
    return () => {
      dispatch(clearFilters())
    }
  }, [])

  useEffect(() => {
    fetchMapList()
  }, [])
  return (
    <React.Fragment>
      {rolePermission.defectmanagement.view ? <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
        message="Defect"
      />
      <BulkDeleteModal
        show={bulkDeleteModal}
        onDeleteClick={handleBulkDelete}
        onCloseClick={() => {
          setBulkDeleteModal(false)
          setSelectedRows([])
        }}
        message="Defects"
      />

      <FilterDefects
        isFilterFormOpen={isFilterFormOpen}
        toggle={handleFilterClick}
        onSubmitFilterFrom={handleFilterSubmit}
      />
      <BulkEditDefects
        isOpen={isEditFormOpen}
        toggle={handleEditClick}
        handleBulkEdit={handleBulkEdit}
        setSelectedRows={setSelectedRows}
      />
      {syncPopup?.popupOpen && <SyncViewPage />}
      <Container fluid>
        <Row>
          <Col lg="12">
            <Card
              style={{
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
              }}
            >
              <CardBody className="pt-2">
                <div className="table-container">
                  <TableContainer
                    columns={columns}
                    data={data}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    totalCount={totalCount}
                    setPageIndex={setPageIndex}
                    setPageSize={setPageSize}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    isGlobalFilter={true}
                    isPagination={true}
                    SearchPlaceholder="Search..."
                    isCustomPageSize={true}
                    isAddButton={true}
                    isAddDelete={true}
                    isAddEdit={true}
                    isAddFilter={true}
                    handleAddClick={handleCreateClick}
                    handleDeleteClick={handleDeleteClick}
                    handleEditClick={handleEditClick}
                    handleFilterClick={handleFilterClick}
                    buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal ms-2 mb-2"
                    buttonName="Create"
                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                    theadClass="table-light"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    pagination="pagination"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

        <ToastContainer /></> : <NoPermission />}
    </React.Fragment>
  )
}

export default withRouter(Defects)
