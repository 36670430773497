import React, { Fragment, useEffect, useState } from "react"
import {
  Row,
  Table,
  Button,
  Input,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap"
import { Link } from "react-router-dom"

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table"

import { rankItem } from "@tanstack/match-sorter-utils"
import NoData from "components/Common/NoData"

// Column Filter
const Filter = ({ column }) => {
  const columnFilterValue = column.getFilterValue()

  return (
    <>
      <DebouncedInput
        type="text"
        value={columnFilterValue ?? ""}
        onChange={value => column.setFilterValue(value)}
        placeholder="Search..."
        className="w-36 border shadow rounded"
        list={column.id + "list"}
      />
      <div className="h-1" />
    </>
  )
}

// Global Filter
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [debounce, onChange, value])

  return (
    <React.Fragment>
      <Col sm={4} className="d-flex align-items-center">
        <InputGroup className="w-100" style={{ height: "36px" }}>
          <InputGroupText style={{ height: "100%", padding: "0 12px" }}>
            <i className="bx bx-search-alt search-icon"></i>
          </InputGroupText>
          <Input
            {...props}
            value={value}
            onChange={e => setValue(e.target.value)}
            style={{ height: "100%" }}
          />
        </InputGroup>
      </Col>
    </React.Fragment>
  )
}

const TableContainer = ({
  columns,
  data,
  tableClass,
  theadClass,
  divClassName,
  isBordered,
  isPagination,
  isGlobalFilter,
  paginationWrapper,
  SearchPlaceholder,
  pagination,
  isAddDelete,
  isCustomPageSize,
  handleDeleteClick,
  handleBack,
  pageIndex,
  pageSize,
  globalFilter,
  totalCount,
  setPageIndex,
  setPageSize,
  setGlobalFilter,
}) => {
  const [columnFilters, setColumnFilters] = useState([])
  const fuzzyFilter = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value)
    addMeta({ itemRank })
    return itemRank.passed
  }

  const table = useReactTable({
    columns,
    data,
    filterFns: { fuzzy: fuzzyFilter },
    state: { columnFilters, globalFilter, pagination: { pageIndex, pageSize } },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(totalCount / pageSize),
  })

  const {
    getHeaderGroups,
    getCanPreviousPage,
    getCanNextPage,
    getPageOptions,
    getState,
  } = table
  const handlePageSizeChange = e => {
    // Reset to page 1 when page size changes
    setPageIndex(0)

    // Ensure setPageIndex is applied before updating page size
    setPageSize(Number(e.target.value))
  }

  return (
    <Fragment>
      <Row className="mb-2 align-items-center">
        <Col lg="6">
          <h4 className="font-size-18">Saved Templates</h4>
        </Col>
        <Col lg="6">
          <div className="d-flex justify-content-end">
            <div className="text-sm-end me-2">
              <Button
                type="button"
                color="primary"
                className="btn-label me-2"
                onClick={handleBack}
              >
                <i className="bx bx-arrow-back label-icon"></i> Go Back
              </Button>
            </div>

            {isAddDelete && (
              <div className="text-sm-end me-2">
                <Button
                  type="button"
                  color="danger"
                  className="btn-label"
                  onClick={handleDeleteClick}
                >
                  <i className="mdi mdi-delete label-icon"></i> Delete
                </Button>
              </div>
            )}
            {isGlobalFilter && (
              <DebouncedInput
                value={globalFilter ?? ""}
                onChange={value => setGlobalFilter(String(value))}
                className="form-control search-box mb-2 d-inline-block"
                placeholder={SearchPlaceholder}
              />
            )}
          </div>
        </Col>
      </Row>


      {data?.length > 0 ? (
        <>
          <div
            className={divClassName ? divClassName : "table-responsive"}
            style={{ minHeight: "350px" }}
          >
            <Table hover className={tableClass} bordered={isBordered}>
              <thead className={theadClass}>
                {getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          className={`${header.column.columnDef.enableSorting
                            ? "sorting sorting_desc"
                            : ""
                            }`}
                        >
                          {header.isPlaceholder ? null : (
                            <React.Fragment>
                              <div
                                {...{
                                  className: header.column.getCanSort()
                                    ? "cursor-pointer select-none"
                                    : "",
                                  onClick:
                                    header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: "",
                                  desc: "",
                                }[header.column.getIsSorted()] ?? null}
                              </div>
                              {header.column.getCanFilter() ? (
                                <div>
                                  <Filter
                                    column={header.column}
                                    table={table}
                                  />
                                </div>
                              ) : null}
                            </React.Fragment>
                          )}
                        </th>
                      )
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getCoreRowModel().rows?.map(row => (
                  <tr key={row.id}>
                    {row.getVisibleCells()?.map(cell => (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {isPagination && (
            <Row>
              <Col sm={12} md={5} className="d-flex">
                {isCustomPageSize && (
                  <div style={{ width: "125px" }}>
                    <select
                      className="form-select pageSize mb-2"
                      value={table.getState().pagination.pageSize}
                      onChange={handlePageSizeChange}
                    >
                      {[10, 20, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <div className="dataTables_info mx-2 mt-2">
                  {/* Showing {getState().pagination.pageSize} of {totalCount}{" "}
                  Results */}
                  Showing data of {totalCount}{" "}
                  entries
                </div>
              </Col>
              <Col sm={12} md={7}>
                <div className={paginationWrapper}>
                  <ul className={pagination}>
                    <li
                      className={`paginate_button page-item previous ${!getCanPreviousPage() ? "disabled" : ""
                        }`}
                    >
                      <Link
                        to="#"
                        className="page-link"
                        onClick={() => {
                          if (getCanPreviousPage()) {
                            setPageIndex(getState().pagination.pageIndex - 1)
                          }
                        }}
                      >
                        <i className="mdi mdi-chevron-left"></i>
                      </Link>
                    </li>
                    {getPageOptions().map((item, key) => (
                      <li
                        key={key}
                        className={`paginate_button page-item ${getState().pagination.pageIndex === item
                          ? "active"
                          : ""
                          }`}
                      >
                        <Link
                          to="#"
                          className="page-link"
                          onClick={() => setPageIndex(item)}
                        >
                          {item + 1} {/* Display page number as 1-based */}
                        </Link>
                      </li>
                    ))}
                    <li
                      className={`paginate_button page-item next ${!getCanNextPage() ? "disabled" : ""
                        }`}
                    >
                      <Link
                        to="#"
                        className="page-link"
                        onClick={() => {
                          if (getCanNextPage()) {
                            setPageIndex(getState().pagination.pageIndex + 1)
                          }
                        }}
                      >
                        <i className="mdi mdi-chevron-right"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          )}
        </>
      ) : (
        <NoData />
      )}
    </Fragment>
  )
}

export default TableContainer
