import React, { useState } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Alert,
  OffcanvasHeader,
  Offcanvas,
  OffcanvasBody,
} from "reactstrap"
import Dropzone from "react-dropzone"
import { useLocation } from "react-router-dom" // For getting pathname
import { useSelector, useDispatch } from "react-redux"
import { uploadTestScenarioTemplateAPI } from "store/actions/TestScenarioActions"
import { uploadRequirementTemplateAPI } from "store/actions/RequirementActions"
import { uploadTestDataTemplateAPI } from "store/actions/TestDataActions"
import { toast } from "react-toastify"
import { uploadDefectsTemplateAPI } from "store/actions/DefectsActions"
import { uploadTestCaseTemplateAPI } from "store/actions/TestCaseAction"

const FileUploadModal = ({ isOpen, toggle }) => {
  const dispatch = useDispatch()
  const [selectedFiles, setSelectedFiles] = useState([])
  const [error, setError] = useState(null)
  const location = useLocation()
  const pathname = location.pathname.split("/").filter(Boolean).pop()
  const Requirementpathname = location.pathname.split("/")
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))
  const ReqselectedProject = JSON.parse(
    localStorage.getItem("selectedProjectData")
  )

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectedProject
  )

  const handleAcceptedFiles = acceptedFiles => {
    const file = acceptedFiles[0] // Assuming single file upload

    if (pathname === "TestData") {
      // Validate file format and name
      const fileNameRegex = /^[a-zA-Z0-9_]+(?:\.[a-zA-Z0-9]+)?$/ // Letters, numbers, and underscores allowed, with optional extension

      if (!file.name.endsWith(".json")) {
        setError("File must be in JSON format")
        setSelectedFiles([]) // Clear selected files
        return
      }
      if (!fileNameRegex.test(file.name)) {
        setError(
          "File name can have underscores and should not contain spaces or special characters"
        )
        setSelectedFiles([]) // Clear selected files
        return
      }
    }

    // If validation passes
    setError(null)
    setSelectedFiles(acceptedFiles)
  }

  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      setError("Please select a file before uploading")
      return
    }

    if (Requirementpathname.includes("Requirement")) {
      try {
        const ReqformData = new FormData();
    
        // Append selected files to FormData
        selectedFiles.forEach(file => {
          ReqformData.append("file", file);
        });
    
        // Append project ID to FormData
        ReqformData.append("project_id", ReqselectedProject?.id);
    
        // Dispatch the API call and await the response
        const response = await dispatch(uploadRequirementTemplateAPI(ReqformData));
    
        // Check if the response indicates success
        if (
          response === "Data imported successfully" || 
          response?.message === "Data imported successfully"
        ) {
          toast.success("Data imported successfully.", {
            position: "top-right",
            id: "Test",
            autoClose: 3000,
          });
    
          // Reset selected files and toggle modal
          setSelectedFiles([]);
          toggle();
    
          // Optional: reload page after a delay
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else {
          // Handle different error messages
          const errorMessage = response?.errors || response?.error;
    
          if (errorMessage) {
            if (Array.isArray(errorMessage)) {
              const formattedErrors = errorMessage
                .map(err => `<li>${err.trim()}</li>`)
                .join("");
              const errorList = `<ul>${formattedErrors}</ul>`;
    
              toast.warn(
                <div
                  className="scrollable-toast"
                  dangerouslySetInnerHTML={{ __html: errorList }}
                />,
                {
                  position: "top-right",
                  autoClose: 3000,
                }
              );
            } else if (typeof errorMessage === "string") {
              toast.warn(
                <div
                  className="scrollable-toast"
                  dangerouslySetInnerHTML={{
                    __html: `<ul><li>${errorMessage}</li></ul>`,
                  }}
                />,
                {
                  position: "top-right",
                  autoClose: 3000,
                }
              );
            } else {
              toast.warn("An unexpected error occurred.", {
                position: "top-right",
                autoClose: 3000,
              });
            }
          } else {
            toast.warn("Unsupported File Format", {
              position: "top-right",
              autoClose: 3000,
            });
          }
        }
      } catch (error) {
        console.error("Upload Error:", error);
        // toast.error("Failed to upload the file. Please try again later.", {
        //   position: "top-right",
        //   autoClose: 3000,
        // });
      }
    }
    

    try {
      let formData = new FormData()
      selectedFiles.forEach(file => formData.append("file", file))

      formData.append(
        "project_id",
        globalProject?.value || selectedProject?.value
      )

      const uploadAction =
        pathname === "TestScenario"
          ? uploadTestScenarioTemplateAPI
          : pathname === "TestData"
          ? uploadTestDataTemplateAPI
          : pathname === "DefectManagement"
          ? uploadDefectsTemplateAPI
          : pathname === "TestCase"
          ? uploadTestCaseTemplateAPI
          : null

      if (!uploadAction) {
        throw new Error("No upload action found for the current path.")
      }

      const response = await dispatch(uploadAction(formData))
 

      // Check if response contains success message
      if (response === "Data imported successfully") {
        toast.success("Data imported successfully.", {
          position: "top-right",
          id: "Test",
          autoClose: 3000,
        })
        setSelectedFiles([])
        toggle()
        // Optional: reload page after a delay
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      } else {
        // Handle error messages
        const errorMessage = response?.errors || response?.error

        if (errorMessage) {
          if (Array.isArray(errorMessage)) {
            const errors = errorMessage.map(err => err.trim())
            const formattedErrors = errors
              .map(err => `<li>${err}</li>`)
              .join("")
            const errorList = `<ul>${formattedErrors}</ul>`

            toast.warn(
              <div
                className="scrollable-toast"
                dangerouslySetInnerHTML={{ __html: errorList }}
              />,
              {
                position: "top-right",
                autoClose: 3000,
              }
            )
          } else if (typeof errorMessage === "string") {
            toast.warn(
              <div
                className="scrollable-toast"
                dangerouslySetInnerHTML={{
                  __html: `<ul><li>${errorMessage}</li></ul>`,
                }}
              />,
              {
                position: "top-right",
                autoClose: 3000,
              }
            )
          } else {
            toast.warn("An unexpected error occurred", {
              position: "top-right",
              autoClose: 3000,
            })
          }
        } else {
          toast.warn("something went wrong! please try again later", {
            position: "top-right",
            autoClose: 3000,
          })
        }
      }
    } catch (error) {
      console.error("Upload Error:", error)
    }
  }

  return (
    <Offcanvas
      isOpen={isOpen}
      toggle={() => {
        toggle()
        setSelectedFiles([])
        setError(null)
      }}
      direction="end"
      scrollable-
    >
      <OffcanvasHeader
        toggle={() => {
          toggle()
          setSelectedFiles([])
          setError(null)
        }}
      >
        Upload Files
      </OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <FormGroup>
            <Label>Attached Files</Label>
            <Dropzone
              onDrop={handleAcceptedFiles}
              multiple={false} // Set to true if multiple file uploads are needed
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="dz-message needsclick">
                    <div className="mb-3">
                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                    </div>
                    <h4>Drop files here or click to upload.</h4>
                  </div>
                </div>
              )}
            </Dropzone>
            <div className="dropzone-previews mt-3" id="file-previews">
              {selectedFiles.map((file, index) => (
                <div className="border rounded mb-2" key={index}>
                  <div className="d-flex flex-wrap gap-2 p-2">
                    <div className="flex-grow-1">
                      <h5 className="fs-md mb-1">{file.name}</h5>
                    </div>
                    <div className="flex-shrink-0 ms-3">
                      <Button
                        color="danger"
                        size="sm"
                        onClick={() => {
                          const newFiles = [...selectedFiles]
                          newFiles.splice(index, 1)
                          setSelectedFiles(newFiles)
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {error && <Alert color="danger">{error}</Alert>}
          </FormGroup>
        </Form>
      </OffcanvasBody>
      <div className="d-flex justify-content-between p-3">
        <Button
          color="primary"
          onClick={handleUpload}
          disabled={selectedFiles.length === 0}
        >
          Upload
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            toggle()
            setSelectedFiles([])
            setError(null)
          }}
        >
          Cancel
        </Button>
      </div>
    </Offcanvas>
  )
}

export default FileUploadModal
