import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import "../../../components/Common/Breadcrum.scss"
import {
  Row,
  Col,
  BreadcrumbItem,
  Button,
  Container,
  Card,
  CardBody,
} from "reactstrap"
import DownloadTemplate from "components/Download Template/DownloadTemplate"
import FileUploadModal from "components/Import/FileUpload"
import ExportModal from "components/Export/ExportModal"
import { useSelector } from "react-redux"
import PageNavigation from "../PageNavigation/PageNavigation"
import InfoTable from "components/Common/Info/Info"
import { GoSync } from "react-icons/go"
import SyncDefect from "../SyncDefect/SyncDefect"
import { toast } from "react-toastify"

const Breadcrumb = props => {
  const { isImport, isDownload, isExport, isInfo, isSync } = props
  const [title, setTitle] = useState("Defects")

  const initialBreadcrumbItems = [
    { label: "Defect Management", path: "/DefectManagement" },
    { label: "Defects", path: "/" },
  ]
  const [breadcrumbItems, setBreadcrumbItems] = useState(initialBreadcrumbItems)
  const [activeButton, setActiveButton] = useState("")

  const handleDefectClick = () => {
    setActiveButton("Defects")
    setBreadcrumbItems(initialBreadcrumbItems)
    setTitle("Defects")
  }
  const onBreadcrumbClick = () => {
    setActiveButton("Defects")
    setBreadcrumbItems(initialBreadcrumbItems)
    setTitle("Defects")
  }
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSyncOpen, setIsSyncOpen] = useState(false)

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
    // setIsModalOpen(false)
  }

  const toggleSync = () => {
    setIsSyncOpen(!isSyncOpen)
    // setIsModalOpen(false)
  }

  const handleSyncClose = () => {
    setIsSyncOpen(false)
    // setIsModalOpen(false)
  }

  const permission = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission =
    permission?.permissions?.[0]?.menu_item?.DefectManagement

  const [modalOpenFile, setModalOpenFile] = useState(false)

  const toggleFileModal = () => { 
    if (rolePermission.defectmanagement.create) {      
    setModalOpenFile(!modalOpenFile) 
    } else {
      toast.warn("You don't have the permission to import new defect", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  return (
    <>
      <div
        className="page-title-right"
        style={{ marginTop: "-25px", marginLeft: "-10px" }}
      >
        <ol className="breadcrumb m-0">
          {breadcrumbItems?.map((item, index) => (
            <BreadcrumbItem
              key={index}
              active={index === breadcrumbItems.length - 1}
            >
              {index === breadcrumbItems.length - 1 ? (
                item.label
              ) : (
                <Link to={item.path} onClick={() => onBreadcrumbClick()}>
                  {item.label}
                </Link>
              )}
            </BreadcrumbItem>
          ))}
        </ol>
      </div>
      <Container fluid>
        <Row>
          <Col lg="12">
            <Card
              className="mb-0"
              style={{
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
              }}
            >
              <CardBody className="border-bottom pt-3 pb-0">
                <div className="d-flex flex-column flex-md-row justify-content-between">
                  {/* Optional title component */}
                  {/* <h4 className="font-size-18">{title}</h4> */}

                  <PageNavigation
                    handleDefectClick={handleDefectClick}
                    activeButton={activeButton}
                    onBreadcrumbClick={onBreadcrumbClick}
                  />

                  <div className="d-flex flex-column flex-md-row align-items-start mt-3 mt-md-0">
                    <div className="d-flex gap-2 flex-wrap">
                      <span className="hide-content">
                        {isDownload && <DownloadTemplate />}
                      </span>
                      <span className="hide-content">
                        {isImport && (
                          <Button
                            color="secondary"
                            outline
                            className="d-flex align-items-center   mb-2 import-btn"
                            onClick={toggleFileModal}
                          >
                            <i className="bx bx-import font-size-16"></i>
                            <span className="mx-2 hide-content">Import</span>
                          </Button>
                        )}
                      </span>
                      <span className="hide-content">
                        {isExport && <ExportModal />}
                      </span>

                      <span className="hide-content">
                        {isInfo && (
                          <Button
                            color="secondary"
                            outline
                            className="d-flex align-items-center"
                            onClick={toggleModal}
                            style={{ height: "36px" }}
                          >
                            <i className="bx bx-info-circle font-size-16"></i>
                          </Button>
                        )}
                      </span>
                      {isSync && (
                        <Button
                          color="secondary"
                          outline
                          className="d-flex align-items-center mb-2"
                          onClick={toggleSync}
                          title="Please sync to update the defects"
                        >
                          <GoSync className="font-size-15" />
                        </Button>
                      )}
                    </div>

                    <InfoTable isOpen={isModalOpen} toggle={toggleModal} />
                    <SyncDefect
                      isOpen={isSyncOpen}
                      toggle={toggleSync}
                      onClose={handleSyncClose}
                    />
                    <FileUploadModal
                      isOpen={modalOpenFile}
                      toggle={toggleFileModal}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

Breadcrumb.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
    })
  ).isRequired,
}

export default Breadcrumb
