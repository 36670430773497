import React, { useEffect, useState } from "react";
import { Alert, Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { LuLink } from "react-icons/lu";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { customFieldUpdate, getCustomFields } from "store/actions/IntegrationAction";

const ViewCustomMapProject = props => {
    const { isEditFormOpen, toggle, product, fetchMapData, handleClose } = props;
    const dispatch = useDispatch()
    const viewIntegrationCustom = useSelector(state => state?.Integration?.viewIntegrationforCustom);

    const [customOptions, setCustomOptions] = useState([]);
    const [selectedJiraField, setSelectedJiraField] = useState({});
    const [loading, setLoading] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState({
        defect_name: null,
        browser: null,
        devices: null,
        os: null,
        stepsToReproduce: null,
        actualDescription: null,
        expectedDescription: null,
    });

    const customStyles = {
        menu: provided => ({
            ...provided,
            backgroundColor: "white",
        }),
        menuList: provided => ({
            ...provided,
            maxHeight: 150, 
            backgroundColor:"white",// Set max height for dropdown
            overflowY: "auto", // Enable vertical scroll
            zIndex: 1000, // Ensure dropdown appears above other elements
          }),
    }


    const labelMapping = {
        defect_name: "Defect Summary",
        stepsToReproduce: "Steps to Reproduce",
        os: "OS",
        browser: "Browser",
        devices: "Devices",
        actualDescription: "Actual Result Description",
        expectedDescription: "Expected Result Description",
    };

    useEffect(() => {
        const updatedFields = {};

        if (selectedOptions?.stepsToReproduce) {
            updatedFields.steps_to_reproduce = selectedOptions.stepsToReproduce.value;
        }
        if (selectedOptions?.os) {
            updatedFields.os = selectedOptions.os.value;
        }
        if (selectedOptions?.browser) {
            updatedFields.browser = selectedOptions.browser.value;
        }
        if (selectedOptions?.devices) {
            updatedFields.devices = selectedOptions.devices.value;
        }
        if (selectedOptions?.defect_name) {
            updatedFields.defect_name = selectedOptions.defect_name.value;
        }
        if (selectedOptions?.actualDescription) {
            updatedFields.actual_result_description = selectedOptions.actualDescription.value;
        }
        if (selectedOptions?.expectedDescription) {
            updatedFields.expected_result_description = selectedOptions.expectedDescription.value;
        }

        setSelectedJiraField(updatedFields);
    }, [selectedOptions]);

    useEffect(() => {
        if (viewIntegrationCustom?.data?.jira_map_fields) {
            const jira_map_fields = viewIntegrationCustom?.data?.jira_map_fields;
            const reversedFields = Object.fromEntries(
                Object.entries(jira_map_fields).map(([key, value]) => [value, key])
            );

            const updatedOptions = {
                defect_name: customOptions.find(option => option.value === reversedFields.defect_name) || null,
                browser: customOptions.find(option => option.value === reversedFields.browser) || null,
                devices: customOptions.find(option => option.value === reversedFields.devices) || null,
                os: customOptions.find(option => option.value === reversedFields.os) || null,
                stepsToReproduce: customOptions.find(option => option.value === reversedFields.steps_to_reproduce) || null,
                actualDescription: customOptions.find(option => option.value === reversedFields.actual_result_description) || null,
                expectedDescription: customOptions.find(option => option.value === reversedFields.expected_result_description) || null,
            };

            setSelectedOptions(updatedOptions);
        }
    }, [viewIntegrationCustom, customOptions]);

    const getFieldDetails = async () => {
        try {
            // Check if viewIntegrationCustom has a valid ID before proceeding
            if (viewIntegrationCustom?.data?.id) {
                let payload = {
                    map_id: viewIntegrationCustom?.data?.id,
                };
    
                // Dispatch the action to get custom fields
                const response = await dispatch(getCustomFields(payload));
    
                // Check if response is valid and has data
                if (response && response.data) {
                    const OptionValue = Object.entries(response.data).map(
                        ([key, value]) => ({
                            label: key,
                            value: value,
                        })
                    );
                    setCustomOptions(OptionValue);
                } else {
                    toast.error("Failed to fetch Jira fields", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    })
                    setCustomOptions([]); // Provide an empty option list if data is invalid
                }
            }
        } catch (error) {
            console.log(error);            
        }
    };
    
    useEffect(() => {
        if(isEditFormOpen){
        getFieldDetails()
        }
    }, [viewIntegrationCustom,isEditFormOpen]);

    const handleOptionChange = (field, selectedOption) => {
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [field]: selectedOption,
        }));
    };

    const filterOptions = (field) => {
        return customOptions.filter(
            (option) =>
                !Object.keys(selectedOptions)
                    .filter((key) => key !== field)
                    .map((key) => selectedOptions[key]?.value)
                    .includes(option.value)
        );
    };

    const handleSave = async (event) => {
        event.preventDefault();
        setLoading(true);
        const reversedFields = Object.fromEntries(
            Object.entries(selectedJiraField).map(([key, value]) => [value, key])
        );
        let payload = {
            jira_map_fields: reversedFields,
            id: viewIntegrationCustom?.data?.id,
            is_map: Object.keys(reversedFields).length === 0 ? false : true
        };
        try {
            let response = await dispatch(customFieldUpdate(payload));
            if (response?.status) {
                toast.success(response.message, {
                    position: "top-right",
                    id: "build created successfully",
                    autoClose: 3000,
                });
            }
            fetchMapData()
            handleClose();
        } catch (error) {
            toast.warn(error?.message || "An error occurred. Please try again.", {
                position: "top-right",
                id: "module create Error",
                autoClose: 3000,
            });
        }
        setLoading(false);
    };

    return (
        <Offcanvas isOpen={isEditFormOpen} direction="end" toggle={toggle} style={{ width: '42%' }}>
            <OffcanvasHeader toggle={toggle}>Custom Integration Mappings</OffcanvasHeader>
            <OffcanvasBody>
                {product === "Jira" &&
                    <form>
                        <Alert color="primary">Please ensure that Issue Type "Bug" is present in Jira</Alert>
                        <div className="mb-2 col-12 d-flex">
                            <div className="col-5">
                                <Label className="form-label">
                                    TestOps
                                </Label>
                                <Input
                                    type="text"
                                    className="custom-input"
                                    disabled
                                    value="Issue Type"
                                />
                            </div>
                            <div className="col-2 mt-4 text-center">
                                <LuLink />
                            </div>

                            <div className="col-5">
                                <Label className="form-label">
                                    Jira
                                </Label>
                                <Input
                                    type="text"
                                    className="custom-input"
                                    disabled
                                    value="Bug"
                                />
                            </div>
                        </div>
                        {Object.keys(selectedOptions).map((field) => (
                            <div key={field} className="mb-2 col-12 d-flex">
                                <div className="col-5">
                                    <Input
                                        type="text"
                                        className="custom-input"
                                        disabled
                                        value={labelMapping[field] || field}
                                    />
                                </div>
                                <div className="col-2 mt-2 text-center">
                                    <LuLink />
                                </div>

                                <div className="col-5">
                                    <Select
                                        options={filterOptions(field)}
                                        value={selectedOptions[field]}
                                        onChange={(selectedOption) => handleOptionChange(field, selectedOption)}
                                        isClearable={true}
                                        styles={customStyles}
                                        maxMenuHeight={200}
                                        className="select2-selection"
                                    />
                                </div>
                            </div>
                        ))}
                         <div className="mb-2 col-12 d-flex">
                            <div className="col-5">
                                <Input
                                    type="text"
                                    className="custom-input"
                                    disabled
                                    value="Status"
                                />
                            </div>
                            <div className="col-2 mt-2 text-center">
                                <LuLink />
                            </div>

                            <div className="col-5">
                                <Input
                                    type="text"
                                    className="custom-input"
                                    disabled
                                    value="Status"
                                />
                            </div>
                        </div>
                         <div className="mb-2 col-12 d-flex">
                            <div className="col-5">
                                <Input
                                    type="text"
                                    className="custom-input"
                                    disabled
                                    value="Priority"
                                />
                            </div>
                            <div className="col-2 mt-2 text-center">
                                <LuLink />
                            </div>

                            <div className="col-5">
                                <Input
                                    type="text"
                                    className="custom-input"
                                    disabled
                                    value="Priority"
                                />
                            </div>
                        </div>
                         <div className="mb-2 col-12 d-flex">
                            <div className="col-5">
                                <Input
                                    type="text"
                                    className="custom-input"
                                    disabled
                                    value="Comments"
                                />
                            </div>
                            <div className="col-2 mt-2 text-center">
                                <LuLink />
                            </div>

                            <div className="col-5">
                                <Input
                                    type="text"
                                    className="custom-input"
                                    disabled
                                    value="Comments"
                                />
                            </div>
                        </div>
                         <div className="mb-2 col-12 d-flex">
                            <div className="col-5">
                                <Input
                                    type="text"
                                    className="custom-input"
                                    disabled
                                    value="Attachment"
                                />
                            </div>
                            <div className="col-2 mt-2 text-center">
                                <LuLink />
                            </div>

                            <div className="col-5">
                                <Input
                                    type="text"
                                    className="custom-input"
                                    disabled
                                    value="Attachment"
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mt-2">
                            <button type="submit" className="btn btn-primary" onClick={handleSave}>
                                {loading ? 'Saving...' : 'Save'}
                            </button>
                        </div>
                    </form>}
            </OffcanvasBody>
        </Offcanvas>
    );
};

export default ViewCustomMapProject;
