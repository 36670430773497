import React from "react"
import { FaFilePdf } from "react-icons/fa"
import {
  Table,
  Card,
  CardBody,
  CardHeader,
  UncontrolledTooltip,
} from "reactstrap"
import jsPDF from "jspdf"
import "jspdf-autotable"
const TestCasesResultsTable = ({ testcaseresults }) => {
  const downloadPDFTestCaseResult = () => {
    const doc = new jsPDF()

    // Define columns for the table
    const tableColumn = [
      "Cycle Name",
      "Test Case Name",
      "Test Case Summary",
      "Status",
    ]
    const tableRows = []

    const stripHtmlTags = html => {
      const tempDiv = document.createElement("div")
      tempDiv.innerHTML = html
      return tempDiv.textContent || tempDiv.innerText || ""
    }

    if (!Array.isArray(testcaseresults)) {
      console.error("No data available")
      return
    }

    // Prepare table rows
    testcaseresults?.forEach(item => {
      const rowData = [
        item.cycle_name || "-",
        item.test_case_name || "-",
        stripHtmlTags(item.test_case_summary) || "-", // Parse HTML tags
        item.Ex_status || "-",
      ]
      tableRows.push(rowData)
    })

    // Define the header that will appear on every page
    const header = data => {
      doc.setFont("helvetica", "normal")
      doc.setFontSize(14)
      doc.setTextColor(40)
      doc.text("Test Cycle Test Case Report", data.settings.margin.left, 10)
    }

    // Create the table in the PDF
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: {
        fontSize: 10,
        cellPadding: 3,
      },
      headStyles: {
        fillColor: [0, 0, 128],
        textColor: [255, 255, 255],
      },
      theme: "grid",
      showHead: "everyPage",
      didDrawPage: data => {
        header(data) // Add the custom header to every page
      },
    })

    // Generate the PDF file with a unique name
    const fileName = `testCycleTestCase_${
      new Date().toISOString().split("T")[0]
    }.pdf`
    doc.save(fileName)
  }
  return (
    <div style={{ marginTop: "20px" }}>
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <h4>Test Cycle Test Case Report</h4>
          <div
            onClick={downloadPDFTestCaseResult}
            id="resultDownload"
            className="cursor-pointer"
          >
            {" "}
            <FaFilePdf
              className="export-icon pdf"
              style={{
                color: "#D13438",
                height: "1.5rem",
                width: "1.5rem",
                cursor: "pointer",
              }}
            />
          </div>
          <UncontrolledTooltip placement="top" target="resultDownload">
            Download as PDF
          </UncontrolledTooltip>
        </CardHeader>
        <CardBody>
          <div
            style={{
              maxHeight: "300px",
              overflowY: "auto",
              position: "relative",
            }}
          >
            {testcaseresults.length > 0 ? (
              <Table hover striped>
                <thead
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "#ffffff",
                    zIndex: 1,
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <tr>
                    <th>Cycle Name</th>
                    <th>Test Case Name</th>
                    <th>Test Case Summary</th>
                    <th>Execution Status</th>
                  </tr>
                </thead>
                <tbody>
                  {testcaseresults.map((item, index) => (
                    <tr key={index}>
                      <td>{item.cycle_name ? item.cycle_name : "-"}</td>
                      <td>{item.test_case_name ? item.test_case_name : "-"}</td>
                      <td>
                        <p
                          className="text-muted mb-4"
                          dangerouslySetInnerHTML={{
                            __html: item.test_case_summary
                              ? item.test_case_summary
                              : "-",
                          }}
                        ></p>
                      </td>
                      <td>{item.Ex_status ? item.Ex_status : "-"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p className="text-center">No Record Found</p>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default TestCasesResultsTable
