import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
  Form,
  InputGroup,
  FormGroup,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Breadcrumbs from "../../Common/Breadcrumb"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { useSelector, useDispatch } from "react-redux"
import JoditEditor from "jodit-react"
import { BuildsDetails } from "../../../store/ProjectManagement/actions"
import { useNavigate } from "react-router-dom"
import { joditConfig } from "helpers/helper"
import {
  createBuildAPI,
  EditBuildAPI,
  GenerateBuildId,
} from "store/actions/BuildsActions"

const CreateBuild = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let config = joditConfig()
  const editBuildData = useSelector(state => state?.Build?.buildEditDetails)
  const selectedProject = JSON.parse(
    localStorage.getItem("selectedProjectData")
  )
  const [description, setDescription] = useState("")
  const [isdescription, setIsDescription] = useState(false)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [selProject, setselProject] = useState("")
  const currentUrl = window.location.href
  const parts = currentUrl.split("/")
  const [id, setId] = useState("")
  const GlobalSelectedProject = JSON.parse(
    localStorage.getItem("selectedProject")
  )
  const loading = useSelector(state => state?.Loading?.isLoading)
  const isCreateMode = currentUrl.includes("Create-Build")
  const isEditMode = currentUrl.includes("Edit-Build")
  const isNew = currentUrl.includes("Create_Build")
  const buildId = useSelector(state => state?.Build?.nextBuildId?.next_build_id)
  const breadcrumbItems = [
    { label: selProject, path: "/ProjectManagement" },
    { label: "Build", path: `/ProjectManagement/Build/${id}` },
    { label: !isEditMode ? "Create Build" : "Edit Build", path: "/" },
  ]
  const [isEndDateGreater, setisEndDateGreater] = useState(false)

  const stripHtmlTags = html => {
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent || ""
  }
 // First useEffect to set the `id`
useEffect(() => {
  if (parts.includes("ProjectManagement")) {
    setId(parts[parts.length - 1]);
    setselProject(selectedProject.project_name)
  } else {
    setId(GlobalSelectedProject.value);
    setselProject(GlobalSelectedProject.label)

  }

  // if (description) {
  //   setIsDescription(false);
  // }
}, [GlobalSelectedProject, parts ,description ,dispatch]);

 

useEffect(() => {
  if (id) {
    const projId = `?project_id=${id}`;   
    const pIds = `?project_name_id=${id}`
    dispatch(BuildsDetails(projId));
    dispatch(GenerateBuildId(pIds));
  }
}, [id, dispatch]);

  const formatDate = date => {
    if (!date) return ""
    const [year, month, day] = [
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate(),
    ]
    return `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`
  }

  useEffect(() => {
    if (isEditMode && editBuildData) {
      validationType.setValues({
        projectName: selectedProject.project_name,
        buildId: editBuildData.build_id,
      })
      setDescription(editBuildData.build_description)
      setStartDate(editBuildData.start_date)
      setEndDate(editBuildData.end_date)
    } else if (isCreateMode && buildId) {
      validationType.setFieldValue("buildId", buildId)
    }
  }, [isEditMode, editBuildData, isCreateMode, buildId])

  const validationType = useFormik({
    enableReinitialize: true,
    initialValues: {
      projectName: isNew ? GlobalSelectedProject?.label : isCreateMode 
           ? selectedProject?.project_name : isEditMode ? selectedProject?.project_name : '' ,
      buildId: isEditMode ? editBuildData.build_id : buildId,
      startDate: isEditMode ? editBuildData.actual_start_date : " ",
      endDate: isEditMode ? editBuildData.actual_end_date : " ",
    },
    validationSchema: Yup.object().shape({
      projectName: Yup.string().required("Project name is required"),
      buildId: Yup.string().required("Build ID is required"), // Added validation for Build ID
    }),
    onSubmit: async (values, { setTouched }) => {
      let isValid = true
      const cleanDescription = stripHtmlTags(description).trim()
      // Custom validation logic
      if (!cleanDescription) {
        setIsDescription(true)
        isValid = false
      } else {
        // setIsDescription(false)
      }

      if (startDate && endDate && new Date(endDate) <= new Date(startDate)) {
        setisEndDateGreater(true)
        isValid = false
      } else {
        setisEndDateGreater(false)
      }

      // Set all fields as touched to show error messages
      setTouched({
        projectName: true,
        buildId: true,
        startDate: true,
        endDate: true,
      })
      if (isValid && (isCreateMode || isNew)) {
        const body = {
          project_name_id: id,
          start_date: startDate,
          end_date: endDate,
          build_description: description,
        }
        try {
          const response = await dispatch(createBuildAPI(body))
          if (response?.status) {
            if (parts.includes("ProjectManagement")) { 
              navigate(`/ProjectManagement/Build/${id}`)
            } else {
              navigate("/ProjectManagement")          
            }          }
        } catch (error) {
          console.error("Failed to submit form:", error.message)
        }
      }
      if (isValid && isEditMode) {
        const body = {
          id: editBuildData.id,
          project_name_id: id,
          start_date: startDate,
          end_date: endDate,
          build_description: description,
        }
         try {
          const response = await dispatch(EditBuildAPI(editBuildData.id, body))
          if (response?.status) { 
              navigate(`/ProjectManagement/Build/${id}`) 
          }
        } catch (error) {
          console.error("Failed to submit form:", error.message)
        }
      }
    },
  })

  const onCancel = () => {
    validationType.resetForm()
    setIsDescription(false)
    navigate(`/ProjectManagement/Build/${id}`)
  }

  const onGoBack = () =>{
    if(isNew){
      navigate("/ProjectManagement")
    }else{
      navigate(`/ProjectManagement/Build/${id}`)
    }
  }

  const renderInput = (
    name,
    label,
    type = "text",
    placeholder = "",
    isInvalid,
    isRequired = true
  ) => (
    <div className="mb-3">
      <Label className="form-label">
        {label} {isRequired && <span className="text-danger">*</span>}
      </Label>
      <Input
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={e => {
          let value = e.target.value
          if (name === "projShortCode") {
            value = value.toUpperCase() // Convert to uppercase
          }
          validationType.setFieldValue(name, value) // Set the value in form state
        }}
        onBlur={validationType.handleBlur}
        value={validationType.values[name] || ""}
        invalid={isInvalid(validationType, name)}
        className="custom-input"
        disabled
      />
      {validationType.touched[name] && validationType.errors[name] && (
        <span className="custom-error-message">
          {validationType.errors[name]}
        </span>
      )}
    </div>
  )

  const isInvalid = (validationType, name) => {
    return validationType.touched[name] && validationType.errors[name]
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={
              currentUrl.includes("Create-Build")
                ? "Create Build"
                : "Edit Build"
            }
            breadcrumbItems={breadcrumbItems}
          />
          <div className="mt-2 mx-1 col-12 d-flex justify-content-between">
            <div>
              <h3>{!isEditMode ? "Create Build" : "Edit Build"}</h3>
              <p className="text-muted">
                Fill the form to {!isEditMode ? "create" : "edit"} a build
              </p>
            </div>
            <div>
            <Button
                type="button"
                color="primary"
                className="btn-label"
                onClick={onGoBack}
              >
                <i className="bx bx-arrow-back label-icon"></i> Go Back
              </Button>
            </div>
          </div>
          <Form
            onSubmit={e => {
              e.preventDefault()
              validationType.handleSubmit()
            }}
          >
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    {renderInput(
                      "projectName",
                      "Project Name",
                      "text",
                      "Project",
                      isInvalid,
                      true
                    )}
                    {renderInput(
                      "buildId",
                      "Build Id",
                      "text",
                      "Build Id",
                      isInvalid,
                      true
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <FormGroup className="mb-3">
                      <Label>Start Date</Label>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="dd/mm/yyyy"
                          options={{
                            altInput: true,
                            altFormat: "d/m/Y",
                            dateFormat: "Y-m-d",
                          }}
                          value={startDate}
                          onChange={date => {
                            const formattedDate = formatDate(date[0])
                            setStartDate(formattedDate)
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Label>End Date</Label>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="dd/mm/yyyy"
                          options={{
                            altInput: true,
                            altFormat: "d/m/Y",
                            dateFormat: "Y-m-d",
                          }}
                          value={endDate}
                          onChange={date => {
                            const formattedDate = formatDate(date[0])
                            setEndDate(formattedDate)
                          }}
                        />
                      </InputGroup>
                      {isEndDateGreater && (
                        <span className="custom-error-message">
                          end date should be greater than start date.
                        </span>
                      )}
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Label>
                      Build Description <span className="text-danger">*</span>
                    </Label>
                    <JoditEditor
                      value={description}
                      config={config}
                      tabIndex={1} // tabIndex of textarea
                      onBlur={newContent => setDescription(newContent)}
                    />
                    {isdescription && (
                      <div className="custom-error-message">
                        Build description is required
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div className="text-end mb-2">
              <Button type="submit" color="primary" disabled={loading}>
              {loading?"Loading...":"Submit"}
              </Button>
              <Button color="secondary" className="mx-2" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreateBuild
