import React, { useEffect, useState } from "react"
import { Outlet, useLocation } from "react-router-dom"
import Breadcrumb from "./Header/Breadcrumb"
import { useSelector } from "react-redux"
import NoData from "components/Common/NoData"

const DefectManagement = () => {
  const location = useLocation()
  const locationPath = location.pathname
  const pathname = locationPath.split("/").filter(Boolean).pop()
  const pathArray = ["CreateEditDefect", "EditDefect", "ViewDefect"]

  const mapProjectList = useSelector(state => state?.Integration?.mapList)

  const [HasJiraId, setHasJiraId] = useState(false)
  const [HasPlaneId, setHasPlaneId] = useState(false)

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )
  useEffect(() => {
    const filteredProjectList = mapProjectList?.filter(
      project => project.product === "Jira"
    )

    const hasJiraId = filteredProjectList?.some(
      defect => defect?.frothtestops_project === globalProject?.label
    )
    setHasJiraId(hasJiraId)

    const filteredPlaneList = mapProjectList?.filter(
      project => project.product === "Plane"
    )
    const hasPlanId = filteredPlaneList?.some(
      defect => defect?.frothtestops_project === globalProject?.label
    )
    setHasPlaneId(hasPlanId)
  }, [mapProjectList])

  const sync = HasJiraId || HasPlaneId
  const permission = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission =
    permission?.permissions?.[0]?.menu_item?.DefectManagement

  const globalProjectValue = JSON.parse(localStorage.getItem("selectedProject"))

  return (
    <React.Fragment>
      {globalProjectValue ? (
        <>
          {" "}
          <div className="page-content">
            {!pathArray.includes(pathname) && (
              <Breadcrumb
                isImport={rolePermission.defectmanagement.view}
                isDownload={rolePermission.defectmanagement.view}
                isExport={rolePermission.defectmanagement.view}
                isInfo={rolePermission.defectmanagement.view}
                isProject={rolePermission.defectmanagement.view}
                isSync={rolePermission.defectmanagement.view && sync}
              />
            )}
            <Outlet />
          </div>
        </>
      ) : (
        <>
          <NoData />
        </>
      )}
    </React.Fragment>
  )
}

export default DefectManagement
