import React, { useState } from "react"
import { Container, Row, Button, Col, Card, CardBody } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ProjectRequirement from "./Requirement/ProjectRequirement"
import { useNavigate } from "react-router-dom"
import { Outlet } from "react-router-dom"
import ProjectModule from "./ProjectModule/ProjectModule"
import FileUploadModal from "components/Import/FileUpload"
import RequirementInfoTable from "./Requirement/Info/Info"
import DownloadTemplate from "components/Download Template/DownloadTemplate"
import ExportModal from "components/Export/ExportModal"
import ProjectBuild from "./ProjectBuild/ProjectBuild"
import { toast } from "react-toastify"

const Project = () => {
  const [modalOpenFile, setModalOpenFile] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const navigate = useNavigate()

  const selectedProject = JSON.parse(
    localStorage.getItem("selectedProjectData")
  )
  const currentUrl = window.location.href
  const parts = currentUrl.split("/")

  // Determine which part of the URL is active
  const currentView = parts.includes("Requirement")
    ? "Requirement"
    : parts.includes("Module")
    ? "Module"
    : "Build"

  const breadcrumbItems = [
    { label: "Projects", path: "/ProjectManagement" },
    { label: selectedProject?.project_name, path: "/ProjectManagement" },
    {
      label: currentView,
      path: `/ProjectManagement/${currentView}`,
    },
  ]

  const permission = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission =
    permission?.permissions?.[0]?.menu_item?.ProjectManagement
    
  const toggleFileModal = () => {
    if (rolePermission.requirement.create) {      
      setModalOpenFile(!modalOpenFile)
      } else {
        toast.warn("You don't have the permission to import new requirement", {
          position: "top-right",
          id: " Error ",
          autoClose: 3000,
        })
      }
  }

  const toggleModal = () => setIsModalOpen(!isModalOpen)

  const handleViewChange = view => {
    navigate(`/ProjectManagement/${view}/${selectedProject.id}`)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs
          title={currentView}
          breadcrumbItems={breadcrumbItems}
          isImport={true}
          isDownload={true}
          isExport={true}
          isInfo={false}
          isProject={true}
        />
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom pt-3 pb-0">
                  <div className="d-flex flex-column flex-lg-row justify-content-between">
                    <div className="btn-group d-flex flex-wrap" role="group">
                      {/* Your existing radio buttons */}
                      <div className="col-12 col-md-auto mb-2">
                        <input
                          type="radio"
                          className="btn-check"
                          id="btnradio1"
                          name="btnradio"
                          autoComplete="off"
                          checked={currentView === "Requirement"}
                          onClick={() => handleViewChange("Requirement")}
                          onChange={() => handleViewChange("Requirement")}
                        />
                        <label
                          className="btn btn-outline-primary w-100"
                          htmlFor="btnradio1"
                        >
                          Requirement
                        </label>
                      </div>
                      <div className="col-12 col-md-auto mb-2">
                        <input
                          type="radio"
                          className="btn-check"
                          id="btnradio2"
                          name="btnradio"
                          autoComplete="off"
                          checked={currentView === "Module"}
                          onClick={() => handleViewChange("Module")}
                          onChange={() => handleViewChange("Module")}
                        />
                        <label
                          className="btn btn-outline-primary w-100"
                          htmlFor="btnradio2"
                        >
                          Module
                        </label>
                      </div>
                      <div className="col-12 col-md-auto mb-2">
                        <input
                          type="radio"
                          className="btn-check"
                          id="btnradio3"
                          name="btnradio"
                          autoComplete="off"
                          checked={currentView === "Build"}
                          onClick={() => handleViewChange("Build")}
                          onChange={() => handleViewChange("Build")}
                        />
                        <label
                          className="btn btn-outline-primary w-100"
                          htmlFor="btnradio3"
                        >
                          Build
                        </label>
                      </div>
                    </div>

                    {/* Buttons for all screens, changing layout based on screen size */}
                    <div className="hide-content ">
                      {parts.includes("Requirement") && rolePermission.requirement.view && (
                        <div className="d-flex  mt-2">
                          <DownloadTemplate />
                          <Button
                            color="secondary"
                            outline
                            className="d-flex align-items-center mx-2 mb-2 import-btn"
                            onClick={toggleFileModal}
                          >
                            <i className="bx bx-import font-size-16"></i>
                            <span className="mx-2 hide-content">Import</span>
                          </Button>
                          <ExportModal />
                          <Button
                            color="secondary"
                            outline
                            className="d-flex align-items-center  mx-2 mb-2"
                            onClick={toggleModal}
                          >
                            <i className="bx bx-info-circle font-size-16"></i>
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </CardBody>

                <CardBody style={{ minHeight: "400px" }} className="pt-2">
                  {currentView === "Requirement" && <ProjectRequirement />}
                  {currentView === "Module" && <ProjectModule />}
                  {currentView === "Build" && <ProjectBuild />}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <RequirementInfoTable isOpen={isModalOpen} toggle={toggleModal} />
        <FileUploadModal isOpen={modalOpenFile} toggle={toggleFileModal} />
        <Outlet />
      </div>
    </React.Fragment>
  )
}

export default Project
