import { toast } from "react-toastify"
import httpInjectorService from "services/http-Injector.Service"
import { setGlobalDashboardDefectsPriority, setGlobalDashboardDefectsSummary, setGlobalDashboardDefectStatusAPI, setGlobalDashboardDefectsType, setGlobalDashboardPartitionUser, setGlobalDashboardPriority, setGlobalDashboardProjectBreakdown,
   setGlobalDashboardReported, setGlobalDashboardRequirementBreakDown, setGlobalDashboardSeverity, setProjDashboardStats,
    setProjectDashboardActiveDefect, setProjectDashboardDefectAging, setProjectDashboardDefectClosure, 
    setProjectDashboardDefectReopen, setProjectdashboardDefectStatics, setProjectDashboardDefectTypeCount, setProjectDashboardDefectWeekWise, setProjectdashboardMilestoneTracking, setProjectDashboardSeverityDef, setProjectDashboardStatusDef, setProjectDashboardTestCaseExecuted, 
    setProjectDashboardTestCaseFail, setProjectDashboardTestCaseStatus, 
    setProjectdashboardUserList} from "store/Slices/DashboardSlice"
import { setLoading } from "store/Slices/LoadingSlice"

export const ProjectDashboardStats = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      let queryString = "?"
      Object.entries(bodyoption).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (queryString.charAt(queryString.length - 1) === "&") {
        queryString = queryString.slice(0, -1)
      }
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.ProjectDashboardStats(
          queryString
        )
        const data = response
        dispatch(setProjDashboardStats(data))
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }


  export const GlobalDashboardDefSeverity = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      let queryString = "?"
      Object.entries(bodyoption).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (queryString.charAt(queryString.length - 1) === "&") {
        queryString = queryString.slice(0, -1)
      }
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.GlobalDashboardDefSeverity(
          queryString
        )
        const data = response
        dispatch(setGlobalDashboardSeverity(data))
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }

  export const GlobalDashboardDefPriority = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      let queryString = "?"
      Object.entries(bodyoption).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (queryString.charAt(queryString.length - 1) === "&") {
        queryString = queryString.slice(0, -1)
      }
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.GlobalDashboardDefPriority(
          queryString
        )
        const data = response
        dispatch(setGlobalDashboardPriority(data))
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }


  
  export const GlobalDashboardDefRepoerted = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      let queryString = "?"
      Object.entries(bodyoption).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (queryString.charAt(queryString.length - 1) === "&") {
        queryString = queryString.slice(0, -1)
      }
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.GlobalDashboardDefRepoerted(
          queryString
        )
        const data = response
        dispatch(setGlobalDashboardReported(data))
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }


  export const GlobalDashboardPartitionUser = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      let queryString = "?"
      Object.entries(bodyoption).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (queryString.charAt(queryString.length - 1) === "&") {
        queryString = queryString.slice(0, -1)
      }
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.GlobalDashboardPartitionUser(
          queryString
        )
        const data = response
        dispatch(setGlobalDashboardPartitionUser(data))
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }


  export const GlobalDashboardProjectBreakDown = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      let queryString = "?"
      Object.entries(bodyoption).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (queryString.charAt(queryString.length - 1) === "&") {
        queryString = queryString.slice(0, -1)
      }
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.GlobalDashboardProjectBreakDown(
          queryString
        )
        const data = response
        dispatch(setGlobalDashboardProjectBreakdown(data))
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }


  export const GlobalDashboardRequirementBreakDown = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      let queryString = "?"
      Object.entries(bodyoption).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (queryString.charAt(queryString.length - 1) === "&") {
        queryString = queryString.slice(0, -1)
      }
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.GlobalDashboardRequirementBreakDown(
          queryString
        )
        const data = response
        dispatch(setGlobalDashboardRequirementBreakDown(data))
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }

  export const GlobalDashboardDefectsType = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      let queryString = "?"
      Object.entries(bodyoption).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (queryString.charAt(queryString.length - 1) === "&") {
        queryString = queryString.slice(0, -1)
      }
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.GlobalDashboardDefectsType(
          queryString
        )
        const data = response
        dispatch(setGlobalDashboardDefectsType(data))
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }

  export const GlobalDashboardDefectsPriority = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      let queryString = "?"
      Object.entries(bodyoption).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (queryString.charAt(queryString.length - 1) === "&") {
        queryString = queryString.slice(0, -1)
      }
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.GlobalDashboardDefectsPriority(
          queryString
        )
        const data = response
        dispatch(setGlobalDashboardDefectsPriority(data))
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }

  export const GlobalDashboardDefectsSummary = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      let queryString = "?"
      Object.entries(bodyoption).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (queryString.charAt(queryString.length - 1) === "&") {
        queryString = queryString.slice(0, -1)
      }
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.GlobalDashboardDefectsSummary(
          queryString
        )
        const data = response
        dispatch(setGlobalDashboardDefectsSummary(data))
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }

  export const GlobalDashboardDefectStatusAPI = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      let queryString = "?"
      Object.entries(bodyoption).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (queryString.charAt(queryString.length - 1) === "&") {
        queryString = queryString.slice(0, -1)
      }
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.globalDashboardDefectStatusAPI(
          queryString
        )
        const data = response
        dispatch(setGlobalDashboardDefectStatusAPI(data))
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }

   
  export const ProjectDashboardSeverityDef = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      let queryString = "?"
      Object.entries(bodyoption).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (queryString.charAt(queryString.length - 1) === "&") {
        queryString = queryString.slice(0, -1)
      }
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.ProjectDashboardSeverityDef(
          queryString
        )
        const data = response
        dispatch(setProjectDashboardSeverityDef(data))
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }

  
  export const ProjectDashboardStatusDef = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      let queryString = "?"
      Object.entries(bodyoption).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (queryString.charAt(queryString.length - 1) === "&") {
        queryString = queryString.slice(0, -1)
      }
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.ProjectDashboardStatusDef(
          queryString
        )
        const data = response
        dispatch(setProjectDashboardStatusDef(data))
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }


  export const ProjectDashboardTestCaseStatus = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      let queryString = "?"
      Object.entries(bodyoption).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (queryString.charAt(queryString.length - 1) === "&") {
        queryString = queryString.slice(0, -1)
      }
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.ProjectDashboardTestCaseStatus(
          queryString
        )
        const data = response
        dispatch(setProjectDashboardTestCaseStatus(data))
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }

  export const ProjectDashboardTestCaseExecuted = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      let queryString = "?"
      Object.entries(bodyoption).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (queryString.charAt(queryString.length - 1) === "&") {
        queryString = queryString.slice(0, -1)
      }
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.ProjectDashboardTestCaseExecuted(
          queryString
        )
        const data = response
        dispatch(setProjectDashboardTestCaseExecuted(data))
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }


  export const ProjectDashboardTestCaseFail = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      let queryString = "?"
      Object.entries(bodyoption).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (queryString.charAt(queryString.length - 1) === "&") {
        queryString = queryString.slice(0, -1)
      }
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.ProjectDashboardTestCaseFail(
          queryString
        )
        const data = response
        dispatch(setProjectDashboardTestCaseFail(data))
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }


  export const ProjectDashboardDefectReopen = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      let queryString = "?"
      Object.entries(bodyoption).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (queryString.charAt(queryString.length - 1) === "&") {
        queryString = queryString.slice(0, -1)
      }
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.ProjectDashboardDefectReopen(
          queryString
        )
        const data = response
        dispatch(setProjectDashboardDefectReopen(data))
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }

  export const ProjectDashboardDefectAging = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      let queryString = "?"
      Object.entries(bodyoption).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (queryString.charAt(queryString.length - 1) === "&") {
        queryString = queryString.slice(0, -1)
      }
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.ProjectDashboardDefectAging(
          queryString
        )
        const data = response
        dispatch(setProjectDashboardDefectAging(data))
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }

  export const ProjectDashboardActiveDefect = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      let queryString = "?"
      Object.entries(bodyoption).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (queryString.charAt(queryString.length - 1) === "&") {
        queryString = queryString.slice(0, -1)
      }
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.ProjectDashboardActiveDefect(
          queryString
        )
        const data = response
        dispatch(setProjectDashboardActiveDefect(data))
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }

  export const ProjectDashboardDefectClosure = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      let queryString = "?"
      Object.entries(bodyoption).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (queryString.charAt(queryString.length - 1) === "&") {
        queryString = queryString.slice(0, -1)
      }
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.ProjectDashboardDefectClosure(
          queryString
        )
        const data = response
        dispatch(setProjectDashboardDefectClosure(data))
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }

  
  
export const ProjectDashboardDefectWeekWise = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.ProjectDashboardDefectWeekWise(formData)
        const data = response
        dispatch(setProjectDashboardDefectWeekWise(data))
        dispatch(setLoading(false))
     
      return response
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}


export const ProjectdashboardMilestoneTracking = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.ProjectdashboardMilestoneTracking(
        queryString
      )
      const data = response
      dispatch(setProjectdashboardMilestoneTracking(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}


export const ProjectDashboardDefectTypeCount = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.ProjectDashboardDefectTypeCount(
        queryString
      )
      const data = response
      dispatch(setProjectDashboardDefectTypeCount(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const ProjectdashboardUserList = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.ProjectdashboardUserList(
        queryString
      )
      const data = response
      dispatch(setProjectdashboardUserList(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}


export const ProjectdashboardDefectStatics = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.ProjectdashboardDefectStatics(
        queryString
      )
      const data = response
      dispatch(setProjectdashboardDefectStatics(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}