import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  UncontrolledTooltip,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "flatpickr/dist/themes/material_blue.css"
import JoditEditor from "jodit-react"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import { joditConfig, createOptions } from "../../../helpers/helper"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import {
  BuildsDetails,
  RequirementDetails,
  TagStatus,
  ModuleDetails,
  GenerateRequirementId,
  TeamsDetails,
} from "../../../store/ProjectManagement/actions"
import { updateRequirement } from "store/actions/RequirementActions"
import httpInjectorService from "services/http-Injector.Service"
import Dropzone from "react-dropzone"
import { fetchAllTeamsDetailsUserAssigneeList } from "store/actions/UserActions"
import BASE_URL from "../../../services/apiConfig"

const NewRequirement = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let config = joditConfig()
  const currentUrl = window.location.href
  const parts = currentUrl.split("/")
  const [id, setId] = useState("")
   const GlobalSelectedProject = JSON.parse(
    localStorage.getItem("selectedProject")
  )
  const [description, setDescription] = useState("")
  const [buildValues, setBuildValues] = useState([])
  const [moduleValues, setModuleValues] = useState([])
  const [parentRequirement, setParentRequirement] = useState([])
  const [assignTo, setAssignTo] = useState([])
  const [requirementType, setRequirementType] = useState([])
  const [reqTypeOptions, setReqTypeOptions] = useState([])
  const tagDetails = useSelector(state => state.Project?.tagStatus.data)
  const buildDetails = useSelector(state => state.Project?.buildDetails)
  const moduleDetails = useSelector(state => state.Project?.moduleDetails)
  const layoutModeType = useSelector(state => state.Layout.layoutModeType)
  // const teamDetails = useSelector(state => state.Project?.teamDetails)
  const requirementDetails = useSelector(
    state => state.Project?.requirementDetails
  )
  const nextRequirementId = useSelector(state => state?.Project?.requirementId)
  const selectedProject = JSON.parse(
    localStorage.getItem("selectedProjectData")
  )
  const selectedProjectReqData = useSelector(
    state => state?.Requirement?.editProjectRequirementData
  )
  const loading = useSelector(state => state?.Loading?.isLoading)
  const [isdescription, setIsDescription] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [selectedDeleteFiles, setSelectedDeleteFiles] = useState([]) 
  const isCreateMode = currentUrl.includes("Create-Requirement")
  const isEditMode = currentUrl.includes("Edit-Requirement")
  const isNew = currentUrl.includes("New-Requirement")
  const [selProject, setselProject] = useState("")
  const stripHtmlTags = html => {
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent || ""
  }
  const breadcrumbItems = [
    { label: selProject, path: "/ProjectManagement" },
    { label: "Requirements", path: `/ProjectManagement/Requirement/${id}` },
    {
      label: isCreateMode || isNew ? "Create Requirement" : "Edit Requirement",
      path: "/",
    },
  ]

  const validationSchema = Yup.object().shape({
    projectName: Yup.string().required("Project name is required"),
    requirementTitle: Yup.string()
      .trim()
      .min(1, "Requirement title cannot contain only spaces")
      .required("Requirement title is required")
      .max(250, "Requirement title cannot exceed 250 characters"),
  })

  useEffect(() => {
    setRequirementType(createOptions(reqTypeOptions))
  }, [reqTypeOptions])

  useEffect(() => {
    if (parts.includes("ProjectManagement")) {
      setId(parts[parts.length - 1])
      setselProject(selectedProject.project_name)
    } else {
      setId(GlobalSelectedProject.value)
      setselProject(GlobalSelectedProject.label)
    }
  }, [GlobalSelectedProject, parts])

  useEffect(() => {
    if (id) {
      const pId = `project_id=${id}`
      const projId = `?project_id=${id}`
      const formData = new FormData()
      formData.append("project_id", pId)

      dispatch(RequirementDetails(projId))
      dispatch(TagStatus(projId, formData))
      dispatch(ModuleDetails(projId))
      dispatch(TeamsDetails(projId))
      dispatch(BuildsDetails(projId))
    }
  }, [id, dispatch])

  useEffect(() => {
    const buldData = []
    // const teamsData = []
    const requirementData = []
    const moduleData = []

    // Ensure the variables are arrays before using forEach
    if (Array.isArray(buildDetails)) {
      buildDetails.forEach(entry => {
        buldData.push({
          value: Math.round(entry.id),
          label: entry.build_id,
        })
      })
    }
    if (Array.isArray(moduleDetails)) {
      moduleDetails.forEach(entry => {
        moduleData.push({
          value: entry.id,
          label: entry.module_name,
        })
      })
    }

    if (isEditMode) {
      if (Array.isArray(requirementDetails)) {
        requirementDetails.forEach(entry => {
          if (
            entry.requirement_title !==
            selectedProjectReqData?.requirement_title
          ) {
            requirementData.push({
              value: entry.requirement_title,
              label: entry.requirement_title,
            })
          }
        })
      }
    } else {
      if (Array.isArray(requirementDetails)) {
        requirementDetails.forEach(entry => {
          requirementData.push({
            value: entry.requirement_title,
            label: entry.requirement_title,
          })
        })
      }
    }

    setBuildValues(buldData)
    setModuleValues(moduleData)
    setParentRequirement(requirementData)
    // setAssignTo(teamsData)
    setReqTypeOptions(tagDetails?.Requirement_Type)
  }, [buildDetails, requirementDetails, moduleDetails ,tagDetails])

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const userListResponse = await dispatch(
          fetchAllTeamsDetailsUserAssigneeList({
            project_id: selectedProject?.id,
          })
        )

        const assignOptions = userListResponse?.results?.map(entry => ({
          value: entry.id,
          label: entry.user_name,
        }))

        setAssignTo(assignOptions)
      } catch (error) {
        console.error(error)
      }
    }

    fetchOptions()
  }, [selectedProjectReqData, dispatch])
  useEffect(() => {
    if (isEditMode && selectedProjectReqData) {
      formik.setValues({
        projectName: selectedProjectReqData.project_name,
        build: selectedProjectReqData.build_id,
        parentRequirement: selectedProjectReqData.parent_requirement,
        module: selectedProjectReqData.module_id,
        requirementTitle: selectedProjectReqData.requirement_title,
        requirementSource: selectedProjectReqData.requirement_source,
        assignTo: selectedProjectReqData.assign_to_id,
        RequirementType: selectedProjectReqData.requirement_type,
        description: selectedProjectReqData.requirement_description,
      })
      setDescription(selectedProjectReqData.requirement_description)
      setIsDescription(false)
    }
  }, [isEditMode, selectedProjectReqData])

  const generateReqId = () => {
    const pId = `project_id=${id}`
    dispatch(GenerateRequirementId(pId))
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      projectName: isNew
        ? GlobalSelectedProject.label
        : isEditMode
        ? selectedProjectReqData?.project_name
        : selectedProject?.project_name,
      build: isEditMode ? selectedProjectReqData?.build_id : "",
      parentRequirement: isEditMode
        ? selectedProjectReqData?.parent_requirement
        : "",
      module: isEditMode ? selectedProjectReqData?.module_id : "",
      requirementTitle: isEditMode
        ? selectedProjectReqData?.requirement_title
        : "",
      requirementSource: isEditMode
        ? selectedProjectReqData?.requirement_source
        : "",
      assignTo: isEditMode ? selectedProjectReqData?.assign_to_id : "",
      RequirementType: isEditMode
        ? selectedProjectReqData?.requirement_type
        : "",
      description: isEditMode
        ? selectedProjectReqData?.requirement_description
        : "",
    },
    validationSchema,
    validate: values => {
      const errors = {}
      //  const cleanDescription = stripHtmlTags(description).trim()
      // if (!cleanDescription) {
      //   setIsDescription(true)
      // } else {
      //   setIsDescription(false)
      // }

      return errors
    },
    onSubmit: async values => {
      const cleanDescription = stripHtmlTags(description).trim()
      if (!cleanDescription) {
        setIsDescription(true)
      }
      if (cleanDescription) {
        if (isEditMode && selectedProjectReqData) {
          try {
            const bodyoptions = {
              project_name_id: id,
              id: selectedProjectReqData?.id,
              requirement_id: selectedProjectReqData?.requirement_id,
              parent_requirement: values.parentRequirement,
              requirement_title: values.requirementTitle,
              assign_to_id: values.assignTo,
              build_id: values.build,
              module_id: values.module,
              requirement_description: description,
              requirement_type: values.RequirementType,
              requirement_source: values.requirementSource,
              attachment: selectedFiles[0],
              clear_attachments:
              selectedDeleteFiles?.length === 0 ? true : false,
              updated_at: "",
            } 

            const response = await dispatch(
              updateRequirement(selectedProjectReqData?.id, bodyoptions)
            )
             if (response?.status) {
              navigate(`/ProjectManagement/Requirement/${id}`)
            }
          } catch (error) {
            console.error("Failed to submit form:", error.message)
          }
        } else {
          try {
            generateReqId()
            const bodyoptions = {
              project_name_id: id,
              id: nextRequirementId?.project_id,
              requirement_id: nextRequirementId?.next_requirement_id,
              parent_requirement: values.parentRequirement,
              requirement_title: values.requirementTitle,
              assign_to_id: values.assignTo,
              build_id: values.build,
              module_id: values.module,
              requirement_description: description,
              requirement_type: values.RequirementType,
              requirement_source: values.requirementSource,
              attachment: selectedFiles[0],
            }
          

            const response = await createProjectRequirement(bodyoptions)

            // Check response status to navigate only on success
            if (response?.status) {
              if (parts.includes("ProjectManagement")) {
                navigate(`/ProjectManagement/Requirement/${id}`)
              } else {
                navigate("/ProjectManagement")
              }
            }
          } catch (error) {
            console.error("Failed to submit form:", error.message)
          }
        }
      }
    },
  })

  const createProjectRequirement = async bodyoption => {
    const formData = new FormData()
    for (const key in bodyoption) {
      if (bodyoption[key] !== undefined && bodyoption[key] !== null) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.CreateRequirement(formData)

      if (response?.status) {
        toast.success(response.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
      } else {
        toast.warning(response.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
      }

      return response
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      })
      throw new Error(error.message)
    }
  }

  useEffect(() => {
    if (isEditMode && selectedProjectReqData) {
      // Initialize selectedFiles with existing attachments
      if (
        selectedProjectReqData.attachment?.length > 0 &&
        !(
          selectedProjectReqData.attachment.length === 1 &&
          selectedProjectReqData.attachment[0] === ""
        )
      ) {
        const existingFiles = selectedProjectReqData.attachment.map(
          (file, index) => ({
            name: `Existing File ${index + 1}`,
            path: file, // Assuming this is the file path
            isExisting: true,
          })
        )
        setSelectedDeleteFiles(existingFiles)
      }
    }
  }, [isEditMode, selectedProjectReqData])

  const handleAcceptedFiles = files => {
    const newImages = files?.map(file => {
      return Object.assign(file, {
        priview: URL.createObjectURL(file),
      })
    })
    setSelectedFiles([...selectedFiles, ...newImages])
    setSelectedDeleteFiles([...selectedFiles, ...newImages])
  }

  // const submitData = () => {
  //     if (!description) {
  //         setIsDescription(true);
  //     }
  // };

  const onCancel = () => {
    formik.handleReset()
    setIsDescription(false)
    navigate(`/ProjectManagement/Requirement/${id}`)
  }

  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888",
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Ensure input text is visible
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150, // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }

  const renderSelect = (
    name,
    label,
    placeholder,
    options,
    isRequired = true,
    customProps = {}
  ) => (
    <div className="mb-3">
      <Label className="form-label">
        {label} {isRequired && <span className="text-danger">*</span>}
        {/* Conditionally render the info icon for parentRequirement */}
        {name === "parentRequirement" && (
          <>
            <span id={`view-${name}`} className="info-icon">
              <i className="bx bx-info-circle text-info"></i>
            </span>
            <UncontrolledTooltip placement="top" target={`view-${name}`}>
              Create sub-requirements within your requirement
            </UncontrolledTooltip>
          </>
        )}
      </Label>
      <Select
        name={name}
        placeholder={placeholder}
        onChange={option => formik.setFieldValue(name, option?.value || "")}
        onBlur={() => formik.setFieldTouched(name)}
        options={options}
        className={`select2-selection ${
          formik.touched[name] && formik.errors[name] ? "is-invalid" : ""
        }`}
        {...customProps}
        maxMenuHeight={200}
        value={options.find(option => option.value === formik.values[name])}
        isClearable={true}
        styles={customStyles}
      />
      {formik.touched[name] && formik.errors[name] && (
        <FormFeedback className="custom-error-message">
          {formik.errors[name]}
        </FormFeedback>
      )}
    </div>
  )

  const renderInput = (
    name,
    label,
    type = "text",
    placeholder = "",
    isRequired = true,
    disabled = false
  ) => (
    <div className="mb-3">
      <Label className="form-label">
        {label} {isRequired && <span className="text-danger">*</span>}
      </Label>
      <Input
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values[name] || ""}
        invalid={formik.touched[name] && formik.errors[name]}
        className="custom-input"
        disabled={disabled}
      />
      {formik.touched[name] && formik.errors[name] && (
        <FormFeedback className="custom-error-message">
          {formik.errors[name]}
        </FormFeedback>
      )}
    </div>
  )

  const onSave = () => {
    const cleanDescription = stripHtmlTags(description).trim()
    if (!cleanDescription) {
      setIsDescription(true)
    }
  }

  const onGoBack = () =>{
    if(isNew){
      navigate("/ProjectManagement")
    }else{
      navigate(`/ProjectManagement/Requirement/${id}`)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={
              currentUrl.includes("Create-Requirement")
                ? "Create Requirement"
                : "Edit Requirement"
            }
            breadcrumbItems={breadcrumbItems}
          />
          <div className="mt-2 mx-1 col-12 d-flex justify-content-between">
            <div>
              <h3>{!isEditMode ? "Create Requirement" : "Edit Requirement"}</h3>
              <p className="text-muted">
                Fill the form to {!isEditMode ? "create" : "edit"} a requirement
              </p>
            </div>
            <div> 
              <Button
                type="button"
                color="primary"
                className="btn-label"
                onClick={onGoBack}
              >
                <i className="bx bx-arrow-back label-icon"></i> Go Back
              </Button>
            </div>
          </div>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    {renderInput(
                      "projectName",
                      "Project Name",
                      "text",
                      "Enter project name",
                      true,
                      true
                    )}
                    {renderSelect(
                      "assignTo",
                      "Assign To",
                      "Select Assignee",
                      assignTo,
                      false
                    )}
                    {renderSelect(
                      "build",
                      "Build",
                      "Select Build",
                      buildValues,
                      false
                    )}
                    {renderSelect(
                      "module",
                      "Module",
                      "Select Module",
                      moduleValues,
                      false
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    {renderInput(
                      "requirementTitle",
                      "Requirement Title",
                      "text",
                      "Enter requirement title",
                      true,
                      false
                    )}
                    {renderInput(
                      "requirementSource",
                      "Requirement Source",
                      "text",
                      "Enter requirement source",
                      false,
                      false
                    )}
                    {renderSelect(
                      "parentRequirement",
                      "Parent Requirement",
                      "Select Parent Requirement",
                      parentRequirement,
                      false
                    )}
                    {renderSelect(
                      "RequirementType",
                      "Requirement Type",
                      "Select Requirement Type",
                      requirementType,
                      false
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Label>
                      Requirement Description{" "}
                      <span className="text-danger">*</span>
                    </Label>
                    <JoditEditor
                      value={description}
                      config={config}
                      onBlur={newContent => setDescription(newContent)}
                    />
                    {isdescription && (
                      <div className="mt-1 font-size-10 text-danger">
                        Requirement description is required
                      </div>
                    )}

                    <div className="mt-3">
                      <Label>Attached Files</Label>
                      <Dropzone
                        onDrop={acceptedFiles => {
                          handleAcceptedFiles(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="dz-message needsclick">
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {selectedDeleteFiles.map((file, index) => {
                          return (
                            <div className="border rounded" key={index}>
                              <div className="d-flex flex-justify-content-between gap-2 p-2">
                                <div className="flex-shrink-0 me-3">
                                  <div className="avatar-xs bg-light rounded p-2">
                                    <i
                                      className="bx bx-file"
                                      style={{ fontSize: "23px" }}
                                    ></i>
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <div className="pt-2">
                                    {/* <p className="fs-md mb-1" data-dz-name>
                                      {file.path}
                                    </p> */}
                                    <a
                                      href={`${BASE_URL}/${file.path}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {file.path}
                                    </a>
                                    <strong
                                      className="error text-danger"
                                      data-dz-errormessage
                                    ></strong>
                                  </div>
                                </div>
                                <div className="flex-shrink-0 ms-3 mt-1">
                                  <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={() => {
                                      const newImages = [...selectedFiles]
                                      newImages.splice(index, 1)
                                      setSelectedFiles(newImages)
                                      setSelectedDeleteFiles(newImages)
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div className="d-flex justify-content-end flex-wrap gap-2 m-2 mb-2">
              <Button type="submit" color="primary" onClick={onSave} disabled={loading}>
                {loading?"Loading...":"Submit"}
              </Button>
              <Button type="reset" color="secondary" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default NewRequirement
