import { createSlice } from "@reduxjs/toolkit"

const ReportsSlice = createSlice({
  name: "Reports",
  initialState: {
    selectedFormValues: null,
    selectedTestCaseFormValues: null,
    selectedTestExecutionFormValues: null,
    selectedReqTraceabilityFormValues: null,
  },

  reducers: {
    setSelectedFormValues: (state, action) => ({
      ...state,
      selectedFormValues: action.payload,
    }),
    setSelectedTestCaseFormValues: (state, action) => ({
      ...state,
      selectedTestCaseFormValues: action.payload,
    }),
    setSelectedTestExecutionformValues: (state, action) => ({
      ...state,
      selectedTestExecutionFormValues: action.payload,
    }),
    setSelectedReqTraceabilityformValues: (state, action) => ({
      ...state,
      selectedReqTraceabilityFormValues: action.payload,
    }),
  },
})

export const {
  setSelectedFormValues,
  setSelectedTestCaseFormValues,
  setSelectedTestExecutionformValues,
  setSelectedReqTraceabilityformValues,
} = ReportsSlice.actions

export default ReportsSlice.reducer
