import { createSlice } from "@reduxjs/toolkit"

const DbIntegrationSlice = createSlice({
  name: "DbIntegration",
  initialState: {
    allDbIntegration: [],
    editDbIntegration: {
      isEdit: false,
      data: null,
    },
  },

  reducers: {
    setAllDbIntegration: (state, action) => ({
      ...state,
      allDbIntegration: action.payload,
    }),
    setEditDbIntegration: (state, action) => ({
      ...state,
      editDbIntegration: action.payload,
    }),
  },
})

export const { setAllDbIntegration, setEditDbIntegration } =
  DbIntegrationSlice.actions

export default DbIntegrationSlice.reducer
