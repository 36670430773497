import { createSlice } from "@reduxjs/toolkit"

const ModuleSlice = createSlice({
  name: "Module",
  initialState: {
    allModules: "",
    nextModuleId: null,
    moduleDetails:[],
    editModuleDetails:[]
  },

  reducers: {
    setAllModules: (state, action) => ({
      ...state,
      allModules: action.payload,
    }),
    setNextModuleId: (state, action) => ({
      ...state,
      nextModuleId: action.payload,
    }),
    setModulesDetails: (state, action) => ({
      ...state,
      moduleDetails: action.payload,
    }),
    setEditModulesDetails: (state, action) => ({
      ...state,
      editModuleDetails: action.payload,
    }),
  },
})

export const { setAllModules, setNextModuleId ,setModulesDetails ,setEditModulesDetails} = ModuleSlice.actions

export default ModuleSlice.reducer
