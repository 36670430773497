import React from "react"
import { Outlet, useLocation } from "react-router-dom"
import Breadcrumb from "./Header/Breadcrumb"
import NoData from "components/Common/NoData"

const TestManagement = () => {
  const location = useLocation()
  const locationPath = location.pathname
  const pathname = locationPath.split("/").filter(Boolean).pop()
  const pathArray = [
    "CreateTestScenario",
    "EditTestScenario",
    "ViewTestScenario",
    "ViewTestCase",
    "CreateEditTestCase",
    "CreateEditPreCondition",
  ]

  const hideContent = pathname == "PreCondition" ? false : true

  const permission = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission = permission?.permissions?.[0]?.menu_item?.TestManagement
  const checkPermission = pathname == "TestScenario" ? rolePermission?.testscenario?.view : rolePermission?.testcase?.view

  const globalProjectValue = JSON.parse(localStorage.getItem("selectedProject"))

  return (
    <React.Fragment>
      <div className="page-content">
        {globalProjectValue ? (
          <>
            {!pathArray.includes(pathname) && (
              <Breadcrumb
                isImport={hideContent && checkPermission}
                isDownload={hideContent && checkPermission}
                isExport={hideContent && checkPermission}
                isInfo={hideContent && checkPermission}
                isProject={hideContent && checkPermission}
              />
            )}
            <Outlet />
          </>
        ) : (
          <>
            <NoData />
          </>
        )}
      </div>
    </React.Fragment>
  )
}

export default TestManagement
