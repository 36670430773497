import React, { useEffect, useState } from "react"
import { Label, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { CreateMapProject, getAllGitList, getAllJiraList, getAllPlaneList } from "store/actions/IntegrationAction"
import { toast } from "react-toastify"
import NewRepoCreation from "./NewRepoCreation"
import { setReLoading } from "store/Slices/LoadingSlice"


const MapProject = props => {
    const { isFilterFormOpen, toggle, product, mapId, handleClose, fetchMapData } = props
    const dispatch = useDispatch()

    const [validationErrMsg, setValidationErrMsg] = useState("");

    const [integration, setIntegration] = useState(
        null
    );
    const [sync, setSync] = useState(
        null
    );
    const [module, setModule] = useState(
        null
    );
    const [jiraProject, setJiraProject] = useState(
        null
    );
    const [testopsProject, setTestopsProject] = useState(
        null
    );

    const [integrationPlane, setIntegrationPlane] = useState(
        null
    );
    const [syncPlane, setSyncPlane] = useState(
        null
    );
    const [modulePlane, setModulePlane] = useState(
        null
    );
    const [planeProject, setPlaneProject] = useState(
        null
    );
    const [testopsProjectPlane, setTestopsProjectPlane] = useState(
        null
    );

    const [integrationGit, setIntegrationGit] = useState(
        null
    );
    const [orgGit, setOrgGit] = useState(
        null
    );
    const [repoGit, setRepoGit] = useState(
        null
    );
    const [moduleGit, setModuleGit] = useState(
        null
    );
    const [testopsProjectGit, setTestopsProjectGit] = useState(
        null
    );

    useEffect(() => {
        setIntegration(null);
        setSync(null);
        setModule(null);
        setJiraProject(null);
        setTestopsProject(null);

        setIntegrationPlane(null);
        setSyncPlane(null);
        setModulePlane(null);
        setPlaneProject(null);
        setTestopsProjectPlane(null);

        setIntegrationGit(null);
        setOrgGit(null);
        setRepoGit(null);
        setModuleGit(null);
        setTestopsProjectGit(null);
        setValidationErrMsg("")
    }, []);


    const integrationOptions = [
        { value: "Basic", label: "Basic" },
        ...(product === "Jira" ? [{ value: "Custom", label: "Custom" }] : [])
    ];

    const syncOptions = [
        { value: "One Way", label: "One Way" },
        { value: "Two Way", label: "Two Way" },
    ];
    const moduleOptions = [{ value: "Defect", label: "Defect" }];

    const gitModule = [{ value: "Repo", label: "Repo" }];

    const customStyles = {
        menu: provided => ({
            ...provided,
            backgroundColor: "white",
        }),
        menuList: provided => ({
            ...provided,
            maxHeight: 150, 
            backgroundColor:"white",// Set max height for dropdown
            overflowY: "auto", // Enable vertical scroll
            zIndex: 1000, // Ensure dropdown appears above other elements
          }),
    }


    const handleMapFormClose = () => {
        setValidationErrMsg("")
        handleClose()
    }

    const togglePage = ()=>{
        setValidationErrMsg("")
        toggle()
        setIntegration(null);
        setSync(null);
        setModule(null);
        setJiraProject(null);
        setTestopsProject(null);

        setIntegrationPlane(null);
        setSyncPlane(null);
        setModulePlane(null);
        setPlaneProject(null);
        setTestopsProjectPlane(null);

        setIntegrationGit(null);
        setOrgGit(null);
        setRepoGit(null);
        setModuleGit(null);
        setTestopsProjectGit(null);
        setValidationErrMsg("")
    }

    const handleCreateRepo = () => {
        handleMapFormClose()
        setIsRepoFormOpen(true)
    }

    const checkValidations = () => {
        const errors = {};
        // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (product === "Jira") {
            if (!jiraProject) errors.jiraProject = "Jira Project is required";
            if (!sync) errors.sync = "Synchronization is required";
            if (!integration) errors.integration = "Integration is required";
            if (!testopsProject?.value)
                errors.testopsProject = "TestOps Project is required";
            if (!module) errors.module = "Module is required";
        } else if (product === "GitHub") {
            if (!repoGit) errors.repoGit = "GitHub Repository is required";
            if (!orgGit) errors.orgGit = "Organization is required";
            if (!integrationGit) errors.integrationGit = "Integration is required";
            if (!testopsProjectGit?.value)
                errors.testopsProjectGit = "TestOps Project is required";
            if (!moduleGit) errors.moduleGit = "Module is required";
        }
        // else if (product === "GitLab") {
        //     if (!repoGitLab) errors.repoGitLab = "GitLab Repository is required";
        //     if (!orgGitLab) errors.orgGitLab = "Organization is required";
        //     if (!integrationGitLab) errors.integrationGitLab = "Integration is required";
        //     if (!testopsProjectGitLab)
        //         errors.testopsProjectGitLab = "TestOps Project is required";
        //     if (!moduleGitLab) errors.moduleGitLab = "Module is required";
        // }
        else if (product === "Plane") {
            if (!planeProject) errors.planeProject = "Plane Project is required";
            if (!syncPlane) errors.syncPlane = "Synchronization is required";
            if (!integrationPlane)
                errors.integrationPlane = "Integration is required";
            if (!testopsProjectPlane?.value)
                errors.testopsProjectPlane = "TestOps Project is required";
            if (!modulePlane) errors.modulePlane = "Module is required";
        }
        return errors;
    };

    const handleSave = async (event) => {
        event.preventDefault()

        const validationErrorMsg = checkValidations();
        setValidationErrMsg(validationErrorMsg);
        // setLoading(true);
        if (Object.keys(validationErrorMsg)?.length === 0) {
            dispatch(setReLoading(true))
            let bodyoptions;
            if (product === "Jira") {
                bodyoptions = {
                    intergration_type: integration,
                    Syncronization: sync,
                    module: module,
                    jira_project: jiraProject,
                    frothtestops_project: testopsProject?.label,
                    project_id: testopsProject?.value,
                    intergration_id: mapId,
                };
            } else if (product === "GitHub") {
                bodyoptions = {
                    intergration_type: integrationGit,
                    organizations: orgGit,
                    module: moduleGit,
                    frothtestops_project: testopsProjectGit?.label,
                    project_id: testopsProjectGit?.value,
                    intergration_id: mapId,
                    GitHub_Repository: repoGit,
                };
            }
            // else if (product === "GitLab") {
            //     bodyoptions = {
            //         intergration_type: integrationGitLab,
            //         organizations: orgGitLab,
            //         module: moduleGitLab,
            //         frothtestops_project: testopsProjectGitLab?.label,
            //         project_id: testopsProjectGitLab?.value,
            //         intergration_id: mapId,
            //         GitLab_Repository: repoGitLab,
            //     };
            // }
            else if (product === "Plane") {
                bodyoptions = {
                    intergration_type: integrationPlane,
                    Syncronization: syncPlane,
                    module: modulePlane,
                    plane_project: planeProject,
                    frothtestops_project: testopsProjectPlane?.label,
                    project_id: testopsProjectPlane?.value,
                    intergration_id: mapId,
                };
            } else if (product === "BrowserStack") {
                bodyoptions = {
                    app_type: appType,
                    version_info: descriptionBS,
                    file: files[0],
                    // project_id: ?.value,
                    intergration_id: mapId,
                };
            }

            try {
                await dispatch(CreateMapProject(bodyoptions));
                fetchMapData()
                handleMapFormClose()
                dispatch(setReLoading(false))
            } catch (error) {
                toast.error(error?.message || "An error occurred while creating ", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                })
            }
            dispatch(setReLoading(false))
        }
    };

    const [jiraOptionsValues, setJiraOptionsValues] = useState([]);
    const [projectOptions, setProjectOptions] = useState([]);
    const [gitRepoOptions, setGitRepoOptions] = useState([]);
    const [planeProjectOptions, setPlaneProjectOptions] = useState([]);
    const [gitOrg, setGitOrg] = useState([]);
    const [gitRepoResponse, setGitRepoResponse] = useState([]);
    const ProjectDetails = useSelector(state => state?.Project?.allProjectList)

    const fetchOptions = async () => {
        const fetchJiraBodyOption = {
            intergration_id: mapId,
        };
    
        // Set project options based on ProjectDetails, with a fallback for safety
        const projectOptionValue = ProjectDetails?.map((entry) => ({
            value: entry.id,
            label: entry.project_name,
        })) || [];
        setProjectOptions(projectOptionValue);
    
        try {
            if (product === "GitHub") {
                // Fetch GitHub list
                const gitResponse = await dispatch(getAllGitList(fetchJiraBodyOption));
                if (gitResponse) {
                    setGitRepoResponse(gitResponse);
    
                    // Extract unique GitHub repositories
                    const uniqueGitRepoOptions = [
                        ...new Set(gitResponse?.map((entry) => entry?.GitHub_Repository)),
                    ]
                        .filter(Boolean) // Filter out any null or undefined values
                        .map((value) => ({
                            value,
                            label: value,
                        }));
    
                    // Extract unique GitHub organizations
                    const uniqueGitOrgOptions = [
                        ...new Set(gitResponse?.map((entry) => entry?.organizations)),
                    ]
                        .filter(Boolean) // Filter out any null or undefined values
                        .map((value) => ({
                            value,
                            label: value,
                        }));
    
                    setGitRepoOptions(uniqueGitRepoOptions);
                    setGitOrg(uniqueGitOrgOptions);
                } else {
                    toast.error("Failed to fetch GitHub response", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                    setGitRepoOptions([]);
                    setGitOrg([]);
                }
            } else if (product === "Jira") {
                // Fetch Jira list
                const jiraResponse = await dispatch(getAllJiraList(fetchJiraBodyOption));
                if (jiraResponse && jiraResponse.projects) {
                    const uniqueJiraProjectOptions = jiraResponse.projects.map((entry) => ({
                        value: entry,
                        label: entry,
                    }));
                    setJiraOptionsValues(uniqueJiraProjectOptions);
                } else {
                    toast.error("Failed to fetch Jira Projects", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                    setJiraOptionsValues([]);
                }
            } else if (product === "Plane") {
                // Fetch Plane list
                const planeResponse = await dispatch(getAllPlaneList(fetchJiraBodyOption));
                if (planeResponse && planeResponse.project) {
                    const planeOptions = planeResponse.project.map((entry) => ({
                        value: entry,
                        label: entry,
                    }));
                    setPlaneProjectOptions(planeOptions);
                } else {
                    toast.error("Failed to fetch Plane Projects", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                    setPlaneProjectOptions([]);
                }
            }
        } catch (error) {
            // Log the error for debugging
            console.error("Error occurred during fetchOptions:", error);
        }
    };
    
    useEffect(() => {
        if(isFilterFormOpen){
        fetchOptions();
        }
    }, [product,isFilterFormOpen]);
    

    useEffect(() => {
        const uniqueGitRepoOptions = [
            ...new Set(gitRepoResponse?.filter((entry) => entry?.organizations === orgGit)?.map((entry) => entry?.GitHub_Repository)),
        ].map((value) => ({
            value,
            label: value,
        }));

        setGitRepoOptions(uniqueGitRepoOptions);
    }, [gitRepoResponse, orgGit]);

    const [isRepoFormOpen, setIsRepoFormOpen] = useState(false)

    const handleRepoClick = () => {
        setIsRepoFormOpen(!isRepoFormOpen)
    }

    const handleCloseRepoClick = () => {
        setIsRepoFormOpen(false)
        handleMapFormClose();
    }

    return (
        <div>
            <NewRepoCreation
                isFormOpen={isRepoFormOpen}
                toggle={handleRepoClick}
                integrationID={mapId}
                handleCloseRepo={handleCloseRepoClick}
                fetchOrgList={gitOrg}
                fetchOptions={fetchOptions}
            />
            <Offcanvas isOpen={isFilterFormOpen} direction="end" toggle={togglePage}>
                <OffcanvasHeader toggle={togglePage}>Map Project</OffcanvasHeader>
                <OffcanvasBody>
                    {product === "Jira" &&
                        <form>
                            <div className="mb-2">
                                <Label>Integration Type<span className="text-danger">*</span></Label>
                                <Select
                                    defaultValue={
                                        integration ? { label: integration, value: integration } : ""
                                    }
                                    label="Single select"
                                    onChange={(e) => setIntegration(e ? e?.value : null)}
                                    options={integrationOptions}
                                    isClearable={true}
                                    styles={customStyles}
                                    maxMenuHeight={200}
                                    className="select2-selection"
                                />
                                {validationErrMsg?.integration && (
                                    <span className="text-danger">
                                        {validationErrMsg?.integration}
                                    </span>
                                )}
                            </div>
                            <div className="mb-2">
                                <Label>Synchronization<span className="text-danger">*</span></Label>
                                <Select
                                    defaultValue={
                                        sync ? { label: sync, value: sync } : ""
                                    }
                                    label="Single select"
                                    onChange={(e) => setSync(e ? e?.value : null)}
                                    options={syncOptions}
                                    isClearable={true}
                                    styles={customStyles}
                                    maxMenuHeight={200}
                                    className="select2-selection"
                                />
                                {validationErrMsg?.sync && (
                                    <span className="text-danger">
                                        {validationErrMsg?.sync}
                                    </span>
                                )}
                            </div>
                            <div className="mb-2">
                                <Label>Module<span className="text-danger">*</span></Label>
                                <Select
                                    defaultValue={
                                        module ? { label: module, value: module } : ""
                                    }
                                    label="Single select"
                                    onChange={(e) => setModule(e ? e?.value : null)}
                                    options={moduleOptions}
                                    isClearable={true}
                                    styles={customStyles}
                                    maxMenuHeight={200}
                                    className="select2-selection"
                                />
                                {validationErrMsg?.module && (
                                    <span className="text-danger">
                                        {validationErrMsg?.module}
                                    </span>
                                )}
                            </div>
                            <div className="mb-2">
                                <Label>Jira Project<span className="text-danger">*</span></Label>
                                <Select
                                    defaultValue={
                                        jiraProject ? { label: jiraProject, value: jiraProject } : ""
                                    }
                                    label="Single select"
                                    onChange={(e) => setJiraProject(e ? e?.value : null)}
                                    options={jiraOptionsValues}
                                    isClearable={true}
                                    styles={customStyles}
                                    maxMenuHeight={200}
                                    className="select2-selection"
                                />
                                {validationErrMsg?.jiraProject && (
                                    <span className="text-danger">
                                        {validationErrMsg?.jiraProject}
                                    </span>
                                )}
                            </div>
                            <div className="mb-1">
                                <Label>TestOps Project<span className="text-danger">*</span></Label>
                                <Select
                                    defaultValue={
                                        testopsProject ? { label: testopsProject?.label, value: testopsProject?.value } : ""
                                    }
                                    label="Single select"
                                    onChange={(e) => setTestopsProject(e ? e : null)}
                                    options={projectOptions}
                                    isClearable={true}
                                    styles={customStyles}
                                    maxMenuHeight={115}
                                    className="select2-selection"
                                />
                                {validationErrMsg?.testopsProject && (
                                    <span className="text-danger">
                                        {validationErrMsg?.testopsProject}
                                    </span>
                                )}
                            </div>
                        </form>}
                    {product === "Plane" &&
                        <form>
                            <div className="mb-2">
                                <Label>Integration Type<span className="text-danger">*</span></Label>
                                <Select
                                    defaultValue={
                                        integrationPlane ? { label: integrationPlane, value: integrationPlane } : ""
                                    }
                                    label="Single select"
                                    onChange={(e) => setIntegrationPlane(e ? e?.value : null)}
                                    options={integrationOptions}
                                    isClearable={true}
                                    styles={customStyles}
                                    maxMenuHeight={200}
                                    className="select2-selection"
                                />
                                {validationErrMsg?.integrationPlane && (
                                    <span className="text-danger">
                                        {validationErrMsg?.integrationPlane}
                                    </span>
                                )}
                            </div>
                            <div className="mb-2">
                                <Label>Synchronization<span className="text-danger">*</span></Label>
                                <Select
                                    defaultValue={
                                        syncPlane ? { label: syncPlane, value: syncPlane } : ""
                                    }
                                    label="Single select"
                                    onChange={(e) => setSyncPlane(e ? e?.value : null)}
                                    options={syncOptions}
                                    isClearable={true}
                                    styles={customStyles}
                                    maxMenuHeight={200}
                                    className="select2-selection"
                                />
                                {validationErrMsg?.syncPlane && (
                                    <span className="text-danger">
                                        {validationErrMsg?.syncPlane}
                                    </span>
                                )}
                            </div>
                            <div className="mb-2">
                                <Label>Module<span className="text-danger">*</span></Label>
                                <Select
                                    defaultValue={
                                        modulePlane ? { label: modulePlane, value: modulePlane } : ""
                                    }
                                    label="Single select"
                                    onChange={(e) => setModulePlane(e ? e?.value : null)}
                                    options={moduleOptions}
                                    isClearable={true}
                                    styles={customStyles}
                                    maxMenuHeight={200}
                                    className="select2-selection"
                                />
                                {validationErrMsg?.modulePlane && (
                                    <span className="text-danger">
                                        {validationErrMsg?.modulePlane}
                                    </span>
                                )}
                            </div>
                            <div className="mb-2">
                                <Label>Plane Project<span className="text-danger">*</span></Label>
                                <Select
                                    defaultValue={
                                        planeProject ? { label: planeProject, value: planeProject } : ""
                                    }
                                    label="Single select"
                                    onChange={(e) => setPlaneProject(e ? e?.value : null)}
                                    options={planeProjectOptions}
                                    isClearable={true}
                                    styles={customStyles}
                                    maxMenuHeight={200}
                                    className="select2-selection"
                                />
                                {validationErrMsg?.planeProject && (
                                    <span className="text-danger">
                                        {validationErrMsg?.planeProject}
                                    </span>
                                )}
                            </div>
                            <div className="mb-1">
                                <Label>TestOps Project<span className="text-danger">*</span></Label>
                                <Select
                                    defaultValue={
                                        testopsProjectPlane ? { label: testopsProjectPlane?.label, value: testopsProjectPlane?.value} : ""
                                    }
                                    label="Single select"
                                    onChange={(e) => setTestopsProjectPlane(e ? e : null)}
                                    options={projectOptions}
                                    isClearable={true}
                                    styles={customStyles}
                                    maxMenuHeight={115}
                                    className="select2-selection"
                                />
                                {validationErrMsg?.testopsProjectPlane && (
                                    <span className="text-danger">
                                        {validationErrMsg?.testopsProjectPlane}
                                    </span>
                                )}
                            </div>
                        </form>}
                    {product === "GitHub" &&
                        <form>
                            <div className="mb-2">
                                <Label>Integration Type<span className="text-danger">*</span></Label>
                                <Select
                                    defaultValue={
                                        integrationGit ? { label: integrationGit, value: integrationGit } : ""
                                    }
                                    label="Single select"
                                    onChange={(e) => setIntegrationGit(e ? e?.value : null)}
                                    options={integrationOptions}
                                    isClearable={true}
                                    styles={customStyles}
                                    maxMenuHeight={200}
                                    className="select2-selection"
                                />
                                {validationErrMsg?.integrationGit && (
                                    <span className="text-danger">
                                        {validationErrMsg?.integrationGit}
                                    </span>
                                )}
                            </div>
                            <div className="mb-2">
                                <Label>Organization<span className="text-danger">*</span></Label>
                                <Select
                                    defaultValue={
                                        orgGit ? { label: orgGit, value: orgGit } : ""
                                    }
                                    label="Single select"
                                    onChange={(e) => setOrgGit(e ? e?.value : null)}
                                    options={gitOrg}
                                    isClearable={true}
                                    styles={customStyles}
                                    maxMenuHeight={200}
                                    className="select2-selection"
                                />
                                {validationErrMsg?.orgGit && (
                                    <span className="text-danger">
                                        {validationErrMsg?.orgGit}
                                    </span>
                                )}
                            </div>
                            <div className="mb-2">
                                <Label>GitHub Repository<span className="text-danger">*</span></Label>
                                <Select
                                    defaultValue={
                                        repoGit ? { label: repoGit, value: repoGit } : ""
                                    }
                                    label="Single select"
                                    onChange={(e) => setRepoGit(e ? e?.value : null)}
                                    options={gitRepoOptions}
                                    isClearable={true}
                                    styles={customStyles}
                                    maxMenuHeight={200}
                                    className="select2-selection"
                                />
                                <Label
                                    className="form-label col-12 mt-2 text-primary"
                                    style={{ cursor: "pointer" }}
                                    title="Click here to create a new repository"
                                    onClick={handleCreateRepo}
                                >
                                    + Create Repository
                                </Label>
                                {validationErrMsg?.repoGit && (
                                    <span className="text-danger col-12">
                                        {validationErrMsg?.repoGit}
                                    </span>
                                )}
                            </div>
                            <div className="mb-2">
                                <Label>Module<span className="text-danger">*</span></Label>
                                <Select
                                    defaultValue={
                                        moduleGit ? { label: moduleGit, value: moduleGit } : ""
                                    }
                                    label="Single select"
                                    onChange={(e) => setModuleGit(e ? e?.value : null)}
                                    options={gitModule}
                                    isClearable={true}
                                    styles={customStyles}
                                    maxMenuHeight={200}
                                    className="select2-selection"
                                />
                                {validationErrMsg?.moduleGit && (
                                    <span className="text-danger">
                                        {validationErrMsg?.moduleGit}
                                    </span>
                                )}
                            </div>
                            <div className="mb-1">
                                <Label>TestOps Project<span className="text-danger">*</span></Label>
                                <Select
                                    defaultValue={
                                        testopsProjectGit ? { label: testopsProjectGit?.label, value: testopsProjectGit?.value} : ""
                                    }
                                    label="Single select"
                                    onChange={(e) => setTestopsProjectGit(e ? e : null)}
                                    options={projectOptions}
                                    isClearable={true}
                                    styles={customStyles}
                                    maxMenuHeight={115}
                                    className="select2-selection"
                                />
                                {validationErrMsg?.testopsProjectGit && (
                                    <span className="text-danger">
                                        {validationErrMsg?.testopsProjectGit}
                                    </span>
                                )}
                            </div>
                        </form>}
                    <div className="d-flex justify-content-between mt-2">
                        <button type="submit" className="btn btn-primary" onClick={(e) => {
                            handleSave(e);
                        }}>
                            Submit
                        </button>
                    </div>
                </OffcanvasBody>
            </Offcanvas>
        </div>
    )
}

export default MapProject
