import React, { useEffect, useState } from "react"
import {
  InputGroup,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { fetchAllBuildsList } from "store/actions/BuildsActions"
import { fetchAllModulesList } from "store/actions/ModuleActions"
import { fetchAllRequirementsList } from "store/actions/RequirementActions"
import Flatpickr from "react-flatpickr"
import { useFormik } from "formik"
import { fetchAllTeamsDetailsUserAssigneeList } from "store/actions/UserActions"
import { getAllDefectsIDSListAPI, MapDefectStatus } from "store/actions/DefectsActions"
import { getTagsStautusAPI } from "store/actions/TagsAction"
import { createOptions, createOptionsDefectType } from "helpers/helper"
import { clearFilters, setDefectsFilterFrom } from "store/Slices/DefectsSlice"
import moment from "moment"

const FilterTestScenario = props => {
  const { isFilterFormOpen, toggle, onSubmitFilterFrom } = props
  const dispatch = useDispatch()
  const { defectsFilterFrom } = useSelector(state => state.Defects)
  const globalProject = useSelector(
    state => state.globalProjectSelect.selectetProject
  )

  const formik = useFormik({
    initialValues: defectsFilterFrom,
    enableReinitialize: true,
    onSubmit: async values => {
      try {
        if (onSubmitFilterFrom) {
          await onSubmitFilterFrom(values)
        }
        dispatch(setDefectsFilterFrom(values))
      } catch (error) {
        console.error("Error during form submission:", error)
      }
    },
  })

  const [defectIdsValue, setDefectIdsValue] = useState([])

  const [buildOptions, setBuildOptions] = useState([])
  const [moduleOptions, setModuleOptions] = useState([])
  const [requirementOptions, setRequirementOptions] = useState([])
  const [createdByValue, setCreatedByValue] = useState([])
  const [defectViewerValue, setDefectViewerValue] = useState([])
  const [assignToValue, setAssignToValue] = useState([])
  const layoutModeType = useSelector(state => state.Layout.layoutModeType)
  const [HasJiraId, setHasJiraId] = useState(false)
  const [HasPlaneId, setHasPlaneId] = useState(false)

  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888",
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    // Customize multiValue (selected item background)
    multiValue: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#3a3f51" : "#e0e0e0", // Dark background for selected items
    }),
    // Customize multiValueLabel (selected item text color)
    multiValueLabel: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Adjust text color
    }),
    // Customize multiValueRemove (cross icon for removing selected items)
    multiValueRemove: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
      ":hover": {
        backgroundColor: layoutModeType === "dark" ? "#ff4949" : "#e0e0e0", // Hover effect for remove icon
        color: "#fff",
      },
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150, // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }


  const mapProjectList = useSelector(state => state?.Integration?.mapList)

  useEffect(() => {
    const filteredProjectList = mapProjectList?.filter(
      project => project.product === "Jira"
    )

    const hasJiraId = filteredProjectList?.some(
      defect => defect?.frothtestops_project === globalProject?.label
    )
    setHasJiraId(hasJiraId)

    const filteredPlaneList = mapProjectList?.filter(
      project => project.product === "Plane"
    )
    const hasPlanId = filteredPlaneList?.some(
      defect => defect?.frothtestops_project === globalProject?.label
    )
    setHasPlaneId(hasPlanId)

    if(hasPlanId || hasJiraId){
      fetchMapStatus()
    }
  }, [mapProjectList,isFilterFormOpen,globalProject?.value])

  const handleClearFilters = () => {
    formik.resetForm()
    dispatch(clearFilters())
  }

  const sync = HasJiraId || HasPlaneId
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        if (!globalProject?.value) return

        const [
          defectsIdsResponse,
          buildResponse,
          moduleResponse,
          reqResponse,
          userListResponse,
          // userDetailsListResponse,
        ] = await Promise.all([
          dispatch(
            getAllDefectsIDSListAPI({
              project_id: globalProject.value || selectedProject?.value,
            })
          ),
          dispatch(
            fetchAllBuildsList({
              project_id: globalProject.value || selectedProject?.value,
            })
          ),
          dispatch(
            fetchAllModulesList({
              project_id: globalProject.value || selectedProject?.value,
            })
          ),
          dispatch(
            fetchAllRequirementsList({
              project_id: globalProject.value || selectedProject?.value,
            })
          ),
          dispatch(
            fetchAllTeamsDetailsUserAssigneeList({
              project_id: globalProject.value || selectedProject?.value,
            })
          ),
          // dispatch(
          //   fetchAllUsersDetailsList({ project_id: globalProject.value || selectedProject?.value })
          // ),
          // dispatch(fetchUsersList({ project_id: globalProject.value || selectedProject?.value })),
        ])

        const mapToOptions = (data, valueKey, labelKey) =>
          data?.map(entry => ({
            value: entry[valueKey],
            label: entry[labelKey],
          })) || []

        setDefectIdsValue(
          mapToOptions(defectsIdsResponse, "defect_id", "defect_id")
        )
        setBuildOptions(mapToOptions(buildResponse, "id", "build_id"))
        setModuleOptions(mapToOptions(moduleResponse, "id", "module_name"))
        setRequirementOptions(
          mapToOptions(reqResponse, "id", "requirement_title")
        )

        // const userOptions = mapToOptions(userListResponse, "id", "user_name")
        setCreatedByValue(
          userListResponse?.results?.map(entry => ({
            value: entry.id,
            label: entry.user_name,
          }))
        )
        setAssignToValue(
          userListResponse?.results?.map(entry => ({
            value: entry.id,
            label: entry.user_name,
          }))
        )
        setDefectViewerValue(
          userListResponse?.results?.map(entry => ({
            value: entry.id,
            label: entry.user_name,
          }))
        )
      } catch (error) {
        console.error("Error fetching options:", error)
      }
    }

    fetchOptions()
  }, [isFilterFormOpen, globalProject?.value, dispatch])

  const [defectTypeValue, setDefectTypeValue] = useState([])
  const [severityOptionValues, setSeverityOptionValues] = useState([])
  const [statusOptionValues, setStatusOptionValues] = useState([])
  const [osValue, setOsValue] = useState([])
  const [browserValue, setBrowserValue] = useState([])
  const [deviceValue, setDeviceValue] = useState([])
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const bodyOption = { project_id: globalProject?.value }
        const metaDataResponse = await dispatch(getTagsStautusAPI(bodyOption))

        const { Defect_Status, Severity, Device, OS, Browser, Defect_Type } =
          metaDataResponse?.data || {}

        setDefectTypeValue(createOptionsDefectType(Defect_Type || []))
        setSeverityOptionValues(createOptions(Severity || []))
        if(!sync){
        setStatusOptionValues(createOptions(Defect_Status || []))
        }
        setOsValue(createOptions(OS || []))
        setBrowserValue(createOptions(Browser || []))
        setDeviceValue(createOptions(Device || []))
      } catch (error) {
        console.error(error)
      }
    }

    fetchOptions()
  }, [isFilterFormOpen,globalProject?.value,dispatch,sync])

  const fetchMapStatus = async () => {
    try {
      const responses = await Promise.all([
        dispatch(MapDefectStatus({ project_id: globalProject?.value })),
      ])

      const [statusResponse] = responses

      setStatusOptionValues(
        statusResponse?.statuses?.map(entry => ({
          value: entry,
          label: entry,
        }))
      )
    } catch (error) {
      console.error("Failed to fetch options", error)
      toast.error("Failed to fetch options.")
    }
  }
  
  return (
    <Offcanvas isOpen={isFilterFormOpen} direction="end" toggle={toggle}>
      <OffcanvasHeader toggle={toggle}>Filter Defects</OffcanvasHeader>
      <OffcanvasBody>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-3">
            <Label>Defect Id</Label>
            <Select
              value={
                defectIdsValue.find(
                  option => option.value === formik.values.filter_defect_id
                ) || null
              }
              onChange={option =>
                formik.setFieldValue(
                  "filter_defect_id",
                  option ? option.value : null
                )
              }
              options={defectIdsValue}
              styles={customStyles}
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="mb-3">
            <Label>Build</Label>
            <Select
              value={
                buildOptions.find(
                  option => option.value === formik.values.filter_build
                ) || null
              }
              onChange={option =>
                formik.setFieldValue(
                  "filter_build",
                  option ? option.value : null
                )
              }
              options={buildOptions}
              styles={customStyles}
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="mb-3">
            <Label>Module</Label>
            <Select
              value={
                moduleOptions.find(
                  option => option.value === formik.values.filter_module
                ) || null
              }
              onChange={option =>
                formik.setFieldValue(
                  "filter_module",
                  option ? option.value : null
                )
              }
              options={moduleOptions}
              styles={customStyles}
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="mb-3">
            <Label>Requirement</Label>
            <Select
              value={
                requirementOptions.find(
                  option => option.value === formik.values.filter_requirement
                ) || null
              }
              onChange={option =>
                formik.setFieldValue(
                  "filter_requirement",
                  option ? option.value : null
                )
              }
              options={requirementOptions}
              styles={customStyles}
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="mb-3">
            <Label>Browsers</Label>
            <Select
              isMulti
              value={
                formik.values.filter_browsers?.map(value =>
                  browserValue.find(option => option.value === value)
                ) || []
              }
              onChange={options =>
                formik.setFieldValue(
                  "filter_browsers",
                  options ? options.map(option => option.value) : []
                )
              }
              options={browserValue}
              styles={customStyles}
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="mb-3">
            <Label>Os</Label>
            <Select
              isMulti
              value={
                formik.values.filter_os?.map(value =>
                  osValue.find(option => option.value === value)
                ) || []
              }
              onChange={options =>
                formik.setFieldValue(
                  "filter_os",
                  options ? options.map(option => option.value) : []
                )
              }
              options={osValue}
              styles={customStyles}
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="mb-3">
            <Label>Devices</Label>
            <Select
              isMulti
              value={
                formik.values.filter_devices?.map(value =>
                  deviceValue.find(option => option.value === value)
                ) || []
              }
              onChange={options =>
                formik.setFieldValue(
                  "filter_devices",
                  options ? options.map(option => option.value) : []
                )
              }
              options={deviceValue}
              styles={customStyles}
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="mb-3">
            <Label>Severity</Label>
            <Select
              isMulti
              value={
                formik.values.filter_severity?.map(value =>
                  severityOptionValues.find(option => option.value === value)
                ) || []
              }
              onChange={options =>
                formik.setFieldValue(
                  "filter_severity",
                  options ? options.map(option => option.value) : []
                )
              }
              options={severityOptionValues}
              styles={customStyles}
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="mb-3">
            <Label>Status</Label>
            <Select
              isMulti
              value={
                formik.values.filter_defect_status?.map(value =>
                  statusOptionValues.find(option => option.value === value)
                ) || []
              }
              onChange={options =>
                formik.setFieldValue(
                  "filter_defect_status",
                  options ? options.map(option => option.value) : []
                )
              }
              options={statusOptionValues}
              styles={customStyles}
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="mb-3">
            <Label>Assign To</Label>
            <Select
              isMulti
              value={
                formik.values.filter_assign_to?.map(value =>
                  assignToValue.find(option => option.value === value)
                ) || []
              }
              onChange={options =>
                formik.setFieldValue(
                  "filter_assign_to",
                  options ? options.map(option => option.value) : []
                )
              }
              options={assignToValue}
              styles={customStyles}
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="mb-3">
            <Label>Defect Type</Label>
            <Select
              isMulti
              value={defectTypeValue
                .flatMap(group => group.options)
                .filter(option =>
                  formik.values.filter_defect_type.includes(option.value)
                )}
              onChange={options => {
                const selectedValues = options
                  ? options.map(option => option.value)
                  : []
                formik.setFieldValue("filter_defect_type", selectedValues)
              }}
              options={defectTypeValue}
              styles={customStyles}
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>

          <div className="mb-3">
            <Label>Defect Viewers</Label>
            <Select
              isMulti
              value={
                formik.values.filter_defect_viewers?.map(value =>
                  defectViewerValue.find(option => option.value === value)
                ) || []
              }
              onChange={options =>
                formik.setFieldValue(
                  "filter_defect_viewers",
                  options ? options.map(option => option.value) : []
                )
              }
              options={defectViewerValue}
              styles={customStyles}
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="mb-3">
            <Label>Created By</Label>
            <Select
              isMulti
              value={
                formik.values.filter_created_by?.map(value =>
                  createdByValue.find(option => option.value === value)
                ) || []
              }
              onChange={options =>
                formik.setFieldValue(
                  "filter_created_by",
                  options ? options.map(option => option.value) : []
                )
              }
              options={createdByValue}
              styles={customStyles}
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="mb-3">
            <Label>From</Label>
            <InputGroup>
              <Flatpickr
                className="form-control d-block"
                placeholder="dd-mm-yyyy"
                options={{
                  altInput: true,
                  altFormat: "d-m-Y",
                  dateFormat: "Y-m-d",
                }}
                value={formik.values.filter_from_date}
                onChange={date => {
                  const formattedDate = moment(date[0]).format("YYYY-MM-DD")
                  formik.setFieldValue("filter_from_date", formattedDate)
                }}
              />
            </InputGroup>
          </div>
          <div className="mb-3">
            <Label>To</Label>
            <InputGroup>
              <Flatpickr
                className="form-control d-block"
                placeholder="dd-mm-yyyy"
                options={{
                  altInput: true,
                  altFormat: "d-m-Y", // Display format
                  dateFormat: "Y-m-d", // Internal format for storing values
                }}
                value={formik.values.filter_to_date}
                onChange={date => {
                  const formattedDate = moment(date[0]).format("YYYY-MM-DD")
                  formik.setFieldValue("filter_to_date", formattedDate)
                }}
              />
            </InputGroup>
          </div>
          <div className="d-flex justify-content-between mt-5">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleClearFilters}
            >
              Clear All
            </button>
            <button type="submit" className="btn btn-primary">
              Apply
            </button>
          </div>
        </form>
      </OffcanvasBody>
    </Offcanvas>
  )
}

export default FilterTestScenario
