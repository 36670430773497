import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import "../../../components/Common/Breadcrum.scss"
import { Row, Col, BreadcrumbItem, Container, Card, CardBody } from "reactstrap"
import PageNavigation from "../PageNavigation/PageNavigation"
import { useSelector } from "react-redux"

const Breadcrumb = () => {
  const [title, setTitle] = useState("Defect Reports")

  const initialBreadcrumbItems = [
    { label: "Reports", path: "/Reports" },
    { label: "Defect Reports", path: "/" },
  ]
  const [breadcrumbItems, setBreadcrumbItems] = useState(initialBreadcrumbItems)
  const [activeButton, setActiveButton] = useState("")

  const handleReqTraReportClick = () => {
    setActiveButton("RequirementTraceabilityReports")
    setTitle("Requirement Traceability Reports")
    setBreadcrumbItems([
      { label: "Reports", path: "/Reports" },
      { label: "Requirement Traceability Reports", path: "/" },
    ])
  }
  const handleCycleComPReportClick = () => {
    setActiveButton("CycleComparison")
    setTitle("Cycle Comparison Reports")
    setBreadcrumbItems([
      { label: "Reports", path: "/Reports" },
      { label: "Cycle Comparison Reports", path: "/" },
    ])
  }
  const handleDefectsClick = () => {
    setActiveButton("DefectReports")
    setBreadcrumbItems(initialBreadcrumbItems)
    setTitle("Defect Reports")
  }
  const handleTestCaseClick = event => {
    setActiveButton("TestCase")
    setBreadcrumbItems([
      { label: "Reports", path: "/Reports" },
      { label: "Test Case Reports", path: "/" },
    ])
    setTitle("Test Case Reports")
  }
  const handleTestExecutionClick = () => {
    setActiveButton("TestExecution")
    setBreadcrumbItems([
      { label: "Reports", path: "/Reports" },
      { label: "Test Case Execution Reports", path: "/" },
    ])
    setTitle("Test Case Execution Reports")
  }
  const handleTestScheduleClick = () => {
    setActiveButton("Schedule")
    setBreadcrumbItems([
      { label: "Reports", path: "/Reports" },
      { label: "Schedule Reports", path: "/" },
    ])
    setTitle("Schedule Reports")
  }
  const onBreadcrumbClick = () => {
    setActiveButton("DefectReports")
    setBreadcrumbItems(initialBreadcrumbItems)
    setTitle("Defect Reports")
  }

  const layoutModeType = useSelector(state => state.Layout.layoutModeType)

  return (
    <>
      <div
        className="page-title-right"
        style={{ marginTop: "-25px", marginLeft: "-10px" }}
      >
        <ol className="breadcrumb m-0">
          {breadcrumbItems?.map((item, index) => (
            <BreadcrumbItem
              key={index}
              active={index === breadcrumbItems.length - 1}
            >
              {index === breadcrumbItems.length - 1 ? (
                item.label
              ) : (
                <Link to={item.path} onClick={() => onBreadcrumbClick()}>
                  {item.label}
                </Link>
              )}
            </BreadcrumbItem>
          ))}
        </ol>
      </div>

      <Container fluid>
        <Row>
          <Col lg="12">
            <Card
              className="mb-0"
              style={{
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
              }}
            >
              <CardBody className="border-bottom pt-3 pb-0">
                <div className="d-flex justify-content-between ">
                  {/* <h4 className="font-size-18">{title}</h4> */}
                  <PageNavigation
                    handleReqTraReportClick={handleReqTraReportClick}
                    handleCycleComPReportClick={handleCycleComPReportClick}
                    handleDefectsClick={handleDefectsClick}
                    handleTestScheduleClick={handleTestScheduleClick}
                    handleTestCaseClick={handleTestCaseClick}
                    handleTestExecutionClick={handleTestExecutionClick}
                    activeButton={activeButton}
                    onBreadcrumbClick={onBreadcrumbClick}
                  />
                  <div className="d-flex flex-wrap align-items-end mt-auto flex-column">
                    <div className="d-flex gap-2"></div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

Breadcrumb.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
    })
  ).isRequired,
}

export default Breadcrumb
