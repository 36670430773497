import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import Breadcrumb from "components/Common/Breadcrumb"
import "../../Roles/Create Roles/CreateTestScenarioForm.scss"
// import { Link } from "react-router-dom";
import { fetchAllRolesList } from "store/actions/RolesActions"
// import PermissionComponent from "./PermissionComponent";
import Select from "react-select"
import { setEditUsers } from "store/Slices/UserSlice"
import { createUsers, EditUsers } from "store/actions/UserActions"
import { fetchProjectForSelectProject } from "store/actions"

const CreateEditUsers = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const editRef = useRef(null);
  const currentUrl = window.location.href
  const parts = currentUrl.split("/")
  const loading = useSelector(state => state?.Loading?.isLoading)

  const editUsers = useSelector(state => state?.User?.editUsers)

  const isEditMode = editUsers?.isEdit
  const isEditModeData = editUsers?.data

  const [projectOptions, setProjectOptions] = useState([])
  const [adUser, setAdUser] = useState(
    isEditModeData ? isEditModeData.is_ad_user : true
  )

  const [roleOptionValue, setRoleOptionValue] = useState([])

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        let bodyOptions = { project_id: null }

        const data = await dispatch(fetchAllRolesList(bodyOptions))

        setRoleOptionValue(
          data.map(entry => ({
            value: Math.round(entry.id),
            label: entry.role_name,
          }))
        )
      } catch (error) {
        console.error("Failed to fetch options", error)
        toast.error("Failed to fetch options.")
      }
    }

    fetchOptions()
  }, [])

  const ProjectDetails = useSelector(state => state?.Project?.allProjectList)

  useEffect(() => {
    const projectList = ProjectDetails?.map(entry => ({
      value: Math.round(entry?.id),
      label: entry.project_name,
    }))
    setProjectOptions(projectList)
  }, [ProjectDetails])

  const breadcrumbItems = [
    { label: "Settings", path: "/Settings/Users" },
    {
      label: isEditMode ? "Edit Users " : "Create Users",
      path: "/",
    },
  ]

  const validationSchema = Yup.object().shape({
    role_id: Yup.string().required("Role is required"),
    first_name: Yup.string().trim()
    .matches(/^.*\S.*$/, "First Name cannot contain only spaces")
    .required("First Name is required"),
    last_name: Yup.string().trim()
    .matches(/^.*\S.*$/, "Last Name cannot contain only spaces")
    .required("Last Name is required"),
    user_name: Yup.string().trim()
    .matches(/^.*\S.*$/, "Username cannot contain only spaces")
    .required("Username is required"),
    email: Yup.string()
        .test(
          "email",
          function (value) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            return emailRegex.test(value)
          }
        )
        .required("Valid email required"),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      role_id: isEditModeData ? isEditModeData?.role_id || "" : "",
      first_name: isEditModeData ? isEditModeData?.first_name || "" : "",
      last_name: isEditModeData ? isEditModeData?.last_name || "" : "",
      user_name: isEditModeData ? isEditModeData?.user_name || "" : "",
      email: isEditModeData ? isEditModeData?.email || "" : "",
      project_name_id: isEditModeData
        ? isEditModeData?.project_name_id || []
        : "",
    },
    validationSchema,
    onSubmit: values => {
      const formData = {
        ...values,
        is_ad_user: adUser,
      }
      createEditRoles(formData)
    },
  })


  const createEditRoles = async formData => {
    const editFromValues = {
      ...formData,
      id: isEditModeData?.id,
    }

    if (!isEditModeData) {
      try {
        await dispatch(createUsers(formData))
        await localStorage.removeItem("selectedProject")
        await dispatch(fetchProjectForSelectProject())
        navigate(-1)
      } catch (error) {
        console.error("Error creating:", error)
        toast.error(error?.message || "An error occurred while creating user", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
      }
    } else {
      try {
        await dispatch(EditUsers(editFromValues))
        dispatch(setEditUsers({ isEdit: false, data: null }))
        await localStorage.removeItem("selectedProject")
        await dispatch(fetchProjectForSelectProject())
        navigate(-1)
      } catch (error) {
        console.error("Error updating:", error)
        console.log(error)

        toast.error(error?.message || "An error occurred while updating user", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
      }
    }
  }
  const layoutModeType = useSelector(state => state.Layout.layoutModeType)

  const customStyle = {
    control: provided => ({
      ...provided,
      maxHeight: "150px", // Set the maximum height of the dropdown menu list
      overflowY: "auto",
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888",
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Ensure input text is visible
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#3a3f51" : "#e0e0e0", // Dark background for selected items
    }),
    // Customize multiValueLabel (selected item text color)
    multiValueLabel: (provided) => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Adjust text color
    }),
    // Customize multiValueRemove (cross icon for removing selected items)
    multiValueRemove: (provided) => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
      ':hover': {
        backgroundColor: layoutModeType === "dark" ? "#ff4949" : "#e0e0e0", // Hover effect for remove icon
        color: "#fff",
      },
    }),
  }

  return (
    <React.Fragment>
      <div className={parts.includes("Settings") ? "" : "page-content"}>
        <Container fluid>
          <Breadcrumb title="Roles" breadcrumbItems={breadcrumbItems} />

          <div className="mt-2 mx-1 col-12 d-flex justify-content-between">
            <div>
              <h3>{!isEditMode ? "Create Users" : "Edit Users"}</h3>
              <p className="text-muted">
                Fill the form to {!isEditMode ? "create" : "edit"} a user
              </p>
            </div>
            <div> 
              <Button
                type="button"
                color="primary"
                className="btn-label"
                onClick={() => {
                  navigate("/Settings/Users")
                  dispatch(setEditUsers({ isEdit: false, data: null }))
                }}
              >
                <i className="bx bx-arrow-back label-icon"></i> Go Back
              </Button>
            </div>
          </div>

          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="mb-3 col-12 d-flex flex-column flex-md-row gap-3">
                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          Role<span className="text-danger">*</span>
                        </Label>
                        <Select
                          styles={customStyle}
                          isClearable={true}
                          name="role_id"
                          options={roleOptionValue}
                          onChange={option =>
                            formik.setFieldValue("role_id", option?.value)
                          }
                          onBlur={() => formik.setFieldTouched("role_id")}
                          value={roleOptionValue.find(
                            option => option.value === formik.values.role_id
                          )}
                          className="select2-selection"
                        />
                        {formik.touched.role_id && formik.errors.role_id && (
                          <div className="text-danger required-field">
                            {formik.errors.role_id}
                          </div>
                        )}
                      </div>
                      <div className="col-12 col-md-5">
                        <Label className="form-label">Project</Label>
                        <Select
                          styles={customStyle}
                          name="project_name_id"
                          options={projectOptions}
                          closeMenuOnSelect={false}
                          isMulti
                          className="select2-selection"
                          onChange={options =>
                            formik.setFieldValue(
                              "project_name_id",
                              options ? options.map(option => option.value) : []
                            )
                          }
                          value={projectOptions.filter(option =>
                            formik.values.project_name_id.includes(option.value)
                          )}
                        />
                      </div>
                    </div>

                    <div className="mb-3 col-12 d-flex flex-column flex-md-row gap-3">
                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          First Name<span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="first_name"
                          type="text"
                          placeholder="First Name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.first_name}
                          invalid={formik.touched.first_name && !!formik.errors.first_name}
                          className="custom-input"
                        />
                        {formik.touched.first_name && formik.errors.first_name && (
                          <FormFeedback>{formik.errors.first_name}</FormFeedback>
                        )}
                      </div>
                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          Last Name<span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="last_name"
                          type="text"
                          placeholder="Last Name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.last_name}
                          invalid={formik.touched.last_name && !!formik.errors.last_name}
                          className="custom-input"
                        />
                        {formik.touched.last_name && formik.errors.last_name && (
                          <FormFeedback>{formik.errors.last_name}</FormFeedback>
                        )}
                      </div>
                    </div>

                    <div className="mb-3 col-12 d-flex flex-column flex-md-row gap-3">
                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          Email<span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="email"
                          type="type"
                          placeholder="Email"
                          disabled={isEditMode}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          invalid={formik.touched.email && !!formik.errors.email}
                          className="custom-input"
                        />
                        {formik.touched.email && formik.errors.email && (
                          <FormFeedback>{formik.errors.email}</FormFeedback>
                        )}
                      </div>
                      <div className="col-12 col-md-5">
                        <Label className="form-label">
                          Username<span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="user_name"
                          type="text"
                          disabled={isEditMode}
                          placeholder="Username"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.user_name}
                          invalid={formik.touched.user_name && !!formik.errors.user_name}
                          className="custom-input"
                        />
                        {formik.touched.user_name && formik.errors.user_name && (
                          <FormFeedback>{formik.errors.user_name}</FormFeedback>
                        )}
                      </div>
                    </div>

                    <div className="d-flex align-items-center gap-3">
                      <Label>Is External user?</Label>
                      <div className="square-switch">
                        <input
                          type="checkbox"
                          id="square-switch3"
                          className="switch switch-bool"
                          defaultChecked={!adUser}
                          onChange={() => setAdUser(!adUser)}
                        />
                        <label
                          htmlFor="square-switch3"
                          data-on-label="Yes"
                          data-off-label="No"
                        />
                      </div>
                    </div>
                  </CardBody>

                </Card>
              </Col>

              <Col lg={12}>
                <Row>
                  <Col md="12" className="text-end mt-3 mb-2">
                    <Button color="primary" type="submit" disabled={loading}>
                      {loading ? "Loading..." : "Submit"}
                    </Button>
                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => {
                        formik.resetForm()
                        navigate("/Settings/Users")
                        dispatch(setEditUsers({ isEdit: false, data: null }))
                      }}
                      className="ms-2"
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreateEditUsers
