import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { getAllIntegrationListAPI, getAllMapProject } from "store/actions/IntegrationAction"

const PageNavigation = props => {
  const {
    // handleDbIntegrationClick,
    handleTestDataClick,
    handleAutomationClick,
    handleAutomationScript,
    handleAgentClick,
    handleUploadApp,
    activeButton
    // onBreadcrumbClick,
  } = props

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const locationPath = location.pathname
  const pathname = locationPath.split("/").filter(Boolean).pop()

  const [gitHub, setGitHub] = useState(false)
  const [browserStack, setBrowserStack] = useState(false)
  const [BSList, setBSList] = useState([])
  const mapProjectList = useSelector(
    (state) => state?.Integration?.mapList
  )
  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  useEffect(() => {
    const filteredProjectList = mapProjectList?.filter(
      (project) => project.product === "GitHub"
    );

    const filteredProject = BSList?.some(
      (project) => project.product === "BrowserStack"
    );


    const isGitHub = filteredProjectList?.some(
      (defect) => defect?.frothtestops_project === globalProject?.label
    );

    setGitHub(isGitHub);
    setBrowserStack(filteredProject);

  }, [mapProjectList, BSList]);

  useEffect(() => {
    if ((pathname == "AgentCreation" && !gitHub) || (pathname == "UploadApp" && !browserStack)) {
      navigate('/TestAutomation/TestData')
    }
  }, [globalProject])

  const handleTestData = () => {
    navigate("TestData")
    handleTestDataClick()
  }

  // const handleDbIntegration = () => {
  //   navigate("DbIntegration")
  //   handleDbIntegrationClick()
  // }

  useEffect(() => {
    const mapData = async () => {
      let payload = { project_id: null }
      try {
        await dispatch(getAllMapProject(payload))
        const data = await dispatch(getAllIntegrationListAPI(payload))
        setBSList(data)
      } catch (error) {
        console.log(error);

      }
    }
    mapData()
  }, [])
  const handleAutomationSuite = () => {
    navigate("AutomationSuite")
    handleAutomationClick()
  }

  const handleAutomationScriptClick = () => {
    navigate("AutomationScript")
    handleAutomationScript()
  }

  const handleCreateAgent = () => {
    navigate("AgentCreation")
    handleAgentClick()
  }

  const handleAppUpload = () => {
    navigate("UploadApp")
    handleUploadApp()
  }

  // useEffect(() => {
  //   if (
  //     ![
  //       "DbIntegration",
  //       "AutomationSuite",
  //       "TestData",
  //       "AutomationScript",
  //     ].includes(pathname)
  //   ) {
  //     handleTestData() // Call handleTestData by default
  //   }
  // }, [pathname])

  // useEffect(() => {
  //   if (pathname === "TestData" || pathname === "TestAutomation") {
  //     onBreadcrumbClick()
  //   }
  // }, [pathname])

  useEffect(() => {
    if (pathname == "TestData" || pathname === "TestAutomation") {
      handleTestData()
    } else if (pathname == "AutomationSuite") {
      handleAutomationSuite()
    } else if (pathname == "AutomationScript") {
      handleAutomationScriptClick()
    } else if (pathname == "AgentCreation") {
      handleCreateAgent()
    } else if (pathname == "UploadApp") {
      handleAppUpload()
    }
  }, [pathname])

  return (
    <>
      <div className="">
        <div className="btn-group d-flex flex-wrap" role="group">
          <div className="col-12 col-md-auto mb-2">
            <input
              type="radio"
              className="btn-check"
              id="btnradio1"
              name="btnradio"
              autoComplete="off"
              checked={activeButton === "TestData"} // Sync with active state
            />
            <label
              className="btn btn-outline-primary w-100"
              htmlFor="btnradio1"
              onClick={handleTestData}
            >
              Test Data
            </label> 
            </div>

          {/* <input
            type="radio"
            className="btn-check"
            id="btnradio2"
            name="btnradio"
            autoComplete="off"
            checked={activeButton === "DbIntegration"} // Sync with active state
          />
          <label
            className="btn btn-outline-primary w-100"
            htmlFor="btnradio2"
            onClick={handleDbIntegration}
          >
            DB Integration
          </label> */}
          <div className="col-12 col-md-auto mb-2">
            <input
              type="radio"
              className="btn-check"
              id="btnradio3"
              name="btnradio"
              autoComplete="off"
              checked={activeButton === "AutomationSuite"}
            />
            <label
              className="btn btn-outline-primary w-100"
              htmlFor="btnradio3"
              onClick={handleAutomationSuite}
            >
              Automation Suite
            </label> </div>
          <div className="col-12 col-md-auto mb-2">
          <input
            type="radio"
            className="btn-check"
            id="btnradio4"
            name="btnradio"
            autoComplete="off"
            checked={activeButton === "AutomationScript"}
          />
          <label
            className="btn btn-outline-primary w-100"
            htmlFor="btnradi43"
            onClick={handleAutomationScriptClick}
          >
            Automation Script
          </label> </div>
        {gitHub &&
          <div className="col-12 col-md-auto mb-2">
            <input
              type="radio"
              className="btn-check"
              id="btnradio4"
              name="btnradio"
              autoComplete="off"
              checked={activeButton === "AgentCreation"}
            />
            <label
              className="btn btn-outline-primary w-100"
              htmlFor="btnradi43"
              onClick={handleCreateAgent}
            >
              Agent Creation
            </label>
          </div>}
        {browserStack &&
          <div className="col-12 col-md-auto mb-2">
            <input
              type="radio"
              className="btn-check"
              id="btnradio4"
              name="btnradio"
              autoComplete="off"
              checked={activeButton === "UploadApp"}
            />
            <label
              className="btn btn-outline-primary w-100"
              htmlFor="btnradi43"
              onClick={handleAppUpload}
            >
              Upload App
            </label>
          </div>}
      </div>
      </div>
    </>
  )
}

export default PageNavigation
