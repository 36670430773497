import httpInjectorService from "services/http-Injector.Service"
import { setLoading } from "store/Slices/LoadingSlice"

export const fetchProjectdasboardCycleComparison = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length === 0) {
        return // Skip empty arrays
      }
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${encodeURIComponent(value)}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response =
        await httpInjectorService.ProjectdasboardCycleComparisonAPI(queryString)
      const data = response
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const fetchCycleComparisonTestCaseResult = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length === 0) {
        return // Skip empty arrays
      }
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${encodeURIComponent(value)}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))

    dispatch(setLoading(true))
    try {
      const response =
        await httpInjectorService.CycleComparisonTestCaseResultAPI(queryString)
      const data = response
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
