import React, { useEffect, useState, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import DeleteModal from "components/Common/DeleteModal"
import { useSelector, useDispatch } from "react-redux"
import { toast, ToastContainer } from "react-toastify"
import BulkDeleteModal from "components/Common/BulkDeleteModal"
import {
  BulkDeleteTestDataAPI,
  deleteTestDataAPI,
  fetchAllTestDataList,
  GetTestDataByIdAPI,
} from "store/actions/TestDataActions"
import moment from "moment"
import { setEditTestData } from "store/Slices/TestDataSlice"
import TableContainer from "components/Common/helper/TableContainer"
import NoPermission from "components/Common/NoPermission"

const TestData = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const Permission = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission =
    Permission?.permissions[0]?.menu_item?.TestLab?.Testautomation

  const [data, setData] = useState([])

  const handleSingleEditClick = async id => {
    if (rolePermission.edit) {
      try {
        const res = await dispatch(GetTestDataByIdAPI(id))
        dispatch(setEditTestData({ isEdit: true, data: res }))
        navigate("/TestAutomation/TestData/EditTestData")
      } catch (error) {
        console.error("Error fetching data", error)
      }
    } else {
      toast.warn("You don't have the permission to edit test data", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }
  const [viewTestData, setViewTestData] = useState(null)

  const handleViewTestScenario = async id => {
    if (rolePermission.view) {
      try {
        const res = await dispatch(GetTestDataByIdAPI(id))
        setViewTestData(res)
      } catch (error) {
        console.error("Error fetching data", error)
      }
    } else {
      toast.warn("You don't have the permission to view test data", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }
  useEffect(() => {
    if (viewTestData) {
      navigate("/TestAutomation/TestData/ViewTestData")
    }
  }, [viewTestData])

  const handleCreateClick = () => {
    if (rolePermission.create) {
      navigate("/TestAutomation/TestData/CreateTestData")
    } else {
      toast.warn("You don't have the permission to create test data", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const [deleteModal, setDeleteModal] = useState(false)
  const [bulkDeleteModal, setBulkDeleteModal] = useState(false)
  const [isDeleteId, setIsDeleteId] = useState("")

  const onClickDelete = testscenario => {
    if (rolePermission.delete) {
      setIsDeleteId(testscenario?.id)
      setDeleteModal(true)
    } else {
      toast.warn("You don't have the permission to delete test data", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const [selectedRows, setSelectedRows] = useState([])
  const handleCheckboxChange = id => {
    setSelectedRows(prevValues =>
      prevValues.includes(id)
        ? prevValues.filter(val => val !== id)
        : [...prevValues, id]
    )
  }

  const handleSelectAll = e => {
    const isChecked = e.target.checked
    if (isChecked) {
      const newSelectedRows = data.map(item => item?.id)
      setSelectedRows(newSelectedRows)
    } else {
      setSelectedRows([])
    }
  }
  const handleDownloadJSON = row => {
    const jsonData = JSON.stringify(row.key_value_data)
    const blob = new Blob([jsonData], { type: "application/json" })
    const url = URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = `${row.file_name}_key_value_data.json`
    document.body.appendChild(a)
    a.click()
    URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }
  const columns = useMemo(
    () => [
      {
        header: (
          <input
            type="checkbox"
            onChange={handleSelectAll}
            style={{ cursor: "pointer" }}
            title="Select All"
            checked={
              Array.isArray(selectedRows) &&
              selectedRows.length === data?.length &&
              data?.length > 0
            }
          />
        ),
        accessorKey: "checkbox",
        cell: cellProps => {
          const jobData = cellProps.row.original?.id

          const isChecked = () => {
            if (!Array.isArray(selectedRows)) {
              console.error("selectedRows is not an array")
              return false
            }
            return selectedRows.includes(jobData)
          }

          const handleCheckboxClick = () => {
            try {
              if (!Array.isArray(selectedRows)) {
                throw new Error("selectedRows is not an array")
              }
              handleCheckboxChange(jobData)
            } catch (error) {
              console.error(error.message)
            }
          }

          return (
            <input
              type="checkbox"
              style={{ cursor: "pointer" }}
              checked={isChecked()}
              onChange={handleCheckboxClick}
              onClick={handleCheckboxClick}
            />
          )
        },
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "File Name",
        accessorKey: "file_name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const rowData = cell.row.original // Access the entire row data

          const handleRowClick = () => {
            handleDownloadJSON(rowData) // Call your function here
          }

          return (
            <p
              onClick={handleRowClick}
              className="font-size-14 mb-1"
              style={{
                wordWrap: "break-word",
                textDecoration: "underline",
                cursor: "pointer",
                overflowWrap: "break-word",
                whiteSpace: "normal",
                maxWidth: "300px", // Set the max-width to control the wrapping
              }}
            >
              <a>{cell.getValue()}</a>
            </p>
          )
        },
      },
      {
        header: "Created By",
        accessorKey: "created_by_name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const test_scenario_name = cell.getValue()
          return <>{test_scenario_name ? test_scenario_name?.name : "-"}</>
        },
      },
      {
        header: "Created Date",
        accessorKey: "created_at",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return <>{moment(cell.getValue()).format("DD-MM-YYYY")}</>
        },
      },
      {
        header: "Action",
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top">
                <span
                  // to="/"
                  className="btn btn-sm btn-soft-primary"
                  onClick={() => {
                    const TestScenarioData = cellProps.row.original
                    handleViewTestScenario(TestScenarioData?.id)
                  }}
                  id={`viewtooltip-${cellProps.row.original?.id}`}
                >
                  <i className="mdi mdi-eye-outline" />
                </span>
              </li>
              <UncontrolledTooltip
                placement="top"
                target={`viewtooltip-${cellProps.row.original?.id}`}
              >
                View
              </UncontrolledTooltip>

              <li>
                <span
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    const data = cellProps.row.original
                    handleSingleEditClick(data?.id)
                  }}
                  id={`edittooltip-${cellProps.row.original?.id}`}
                >
                  <i className="mdi mdi-pencil-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edittooltip-${cellProps.row.original?.id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </span>
              </li>

              <li>
                <span
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const userData = cellProps.row.original
                    onClickDelete(userData)
                  }}
                  id={`deletetooltip-${cellProps.row.original?.id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`deletetooltip-${cellProps.row.original?.id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </span>
              </li>
            </ul>
          )
        },
      },
    ],
    [selectedRows, data]
  )

  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [globalFilter, setGlobalFilter] = useState("")
  const [totalCount, setTotalCount] = useState(null)

  const fetchTableData = async (page, size, search) => {
    let bodyoption = {
      page: page + 1,
      page_size: size,
      search: search,
      project_id: globalProject?.value || selectedProject?.value,
    }
    try {
      const data = await dispatch(fetchAllTestDataList(bodyoption))
      setData(data.Data)
      setTotalCount(data.TotalCount)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  useEffect(() => {
    fetchTableData(pageIndex, pageSize, globalFilter)
  }, [pageIndex, pageSize, globalFilter, globalProject?.value])

  const handleDelete = async () => {
    try {
      await dispatch(deleteTestDataAPI(isDeleteId))
      setDeleteModal(false)
      await fetchTableData(pageIndex, pageSize, globalFilter)
      setIsDeleteId("")
    } catch (error) {
      console.error("Error deleting test scenario:", error)
    }
  }

  const handleDeleteClick = async () => {
    if (rolePermission.delete) {
      try {
        if (selectedRows?.length > 0) {
          setBulkDeleteModal(true)
        } else {
          toast.warn(
            "Please select a test data before attempting to bulk delete.",
            {
              position: "top-right",
              id: "selectBeforeDelete",
              autoClose: 3000,
            }
          )
        }
      } catch (error) {
        console.error("Error deleting test data:", error)
      }
    } else {
      toast.warn("You don't have the permission to delete test data's", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }
  const handleBulkDelete = async () => {
    const bodyoption = {
      project_id: globalProject?.value || selectedProject?.value,
      pkid_list: selectedRows,
    }
    try {
      await dispatch(BulkDeleteTestDataAPI(bodyoption))
      setSelectedRows([])
      setBulkDeleteModal(false)
      await fetchTableData(pageIndex, pageSize, globalFilter)
    } catch (error) {
      console.error("Error deleting test data:", error)
    }
  }

  return (
    <React.Fragment>
      {rolePermission.view ? <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
        message="Test data"
      />
      <BulkDeleteModal
        show={bulkDeleteModal}
        onDeleteClick={handleBulkDelete}
        onCloseClick={() => {
          setBulkDeleteModal(false)
          setSelectedRows([])
        }}
        message="Test data's"
      />

      <Container fluid>
        <Row>
          <Col lg="12">
            <Card
              style={{
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
              }}
            >
              <CardBody className="pt-2">
                <div className="table-container">
                  <TableContainer
                    columns={columns}
                    data={data}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    totalCount={totalCount}
                    setPageIndex={setPageIndex}
                    setPageSize={setPageSize}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    isGlobalFilter={true}
                    isPagination={true}
                    SearchPlaceholder="Search..."
                    isCustomPageSize={true}
                    isAddButton={true}
                    isAddDelete={true}
                    isAddEdit={false}
                    isAddFilter={false}
                    handleAddClick={handleCreateClick}
                    handleDeleteClick={handleDeleteClick}
                    buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal ms-2 mb-2"
                    buttonName="Create"
                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                    theadClass="table-light"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    pagination="pagination"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

        <ToastContainer /></> : <NoPermission />}
    </React.Fragment>
  )
}

export default withRouter(TestData)
