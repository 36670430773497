import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import { useSelector } from 'react-redux'; // Assuming you're using Redux to get layoutModeType

const DefectReports = ({ data }) => {
  const defectsChartRef = useRef(null); // Create a ref for the chart container
  const layoutModeType = useSelector(state => state.Layout.layoutModeType); // Get the layout mode from Redux
  const isDarkMode = layoutModeType === 'dark'; // Check if it's dark mode

  useEffect(() => {
    if (defectsChartRef.current && data) {
      try {
        // Filter projects with valid defect counts
        const filteredDefectsData = data?.filter(
          project =>
            project?.status_counts &&
            Object.values(project.status_counts)?.some(count => count !== 0)
        );

        // Check if there are any projects with valid defect counts
        if (filteredDefectsData?.length === 0) {
          console.warn("No valid projects with defect counts found.");
          return;
        }

        // Extract categories (project names)
        const categories = filteredDefectsData?.map(
          project => project?.project_name
        );

        // Get all unique defect types
        const defectTypes = [
          "High", "Medium", "Low", "Urgent", "Critical", "Major", 
          "Blocker", "Minor", "Lowest", "Trivial", "Highest", "Undefined"
        ];

        // Prepare data series for each defect type
        const seriesData = defectTypes.map(defectType => {
          const dataPoints = filteredDefectsData?.map(project => {
            const count = project.status_counts[defectType];
            return count !== undefined ? count : 0; // Use 0 if count is undefined
          });

          // If all counts are zero, return null
          if (dataPoints.every(count => count === 0)) {
            return null;
          }

          return {
            name: defectType === "Undefined" ? "Other" : defectType, // Rename "Undefined" to "Other"
            data: dataPoints,
            color: getColorForDefect(defectType),
          };
        }).filter(Boolean); // Remove null entries (series with all zeros)

        // Configure chart options with dark mode support
        const stackedGroupedOptions = {
          chart: {
            type: "column",
            backgroundColor: isDarkMode ? "#2a3042" : "#FFFFFF", // Background color based on mode
          },
          title: {
            text: "",
            align: "left",
            style: {
              color: isDarkMode ? "#FFFFFF" : "#000000", // Title color based on mode
            },
          },
          xAxis: {
            categories: categories,
            labels: {
              style: {
                color: isDarkMode ? "#FFFFFF" : "#000000", // X-axis label color
              },
            },
          },
          credits: {
            enabled: false,
          },
          yAxis: {
            min: 0,
            title: {
              text: "No. of Defects",
              style: {
                color: isDarkMode ? "#FFFFFF" : "#000000", // Y-axis title color
              },
            },
            labels: {
              style: {
                color: isDarkMode ? "#FFFFFF" : "#000000", // Y-axis label color
              },
            },
            stackLabels: {
              enabled: true,
              style: {
                color: isDarkMode ? "#FFFFFF" : "black", // Stack label color
              },
            },
          },
          legend: {
            align: "center",
            verticalAlign: "bottom",
            layout: "horizontal",
            itemStyle: {
              color: isDarkMode ? "#FFFFFF" : "#000000", // Legend text color
            },
          },
          tooltip: {
            headerFormat: "<b>{point.x}</b><br/>",
            pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
            backgroundColor: isDarkMode ? "#000000" : "#ffffff",
            style: {
              color: isDarkMode ? "#f0f0f0" : "#000000",
            },
          },
          plotOptions: {
            column: {
              stacking: "normal",
              dataLabels: {
                enabled: true,
                color: isDarkMode ? "#FFFFFF" : "black", // Data label color
              },
            },
          },
          series: seriesData,
        };

        // Render the chart using Highcharts
        Highcharts.chart(defectsChartRef.current, stackedGroupedOptions);
      } catch (error) {
        console.log("Defects chart:", error.message);
      }
    }
  }, [data, layoutModeType]); // Add layoutModeType as a dependency

// Function to get color for defects by type
function getColorForDefect(defectType) {
  switch (defectType) {
      case "Blocker":
          return "#FF4C4C"; // Bright Red for Blocker
      case "Critical":
          return "#FF9F00"; // Orange for Critical
      case "Major":
          return "#FFEB3B"; // Bright Yellow for Major
      case "Minor":
          return "#66BB6A"; // Green for Minor
      case "High":
          return "#fc966d"; // Dark Orange for High
      case "Medium":
          return "#42A5F5"; // Blue for Medium
      case "Low":
          return "#761afc"; // Cyan for Low
      case "Urgent":
          return "#D50000"; // Deep Red for Urgent
      case "Trivial":
          return "#AB47BC"; // Purple for Trivial
      case "Highest":
          return "#fae744"; // Gold for Highest
      case "Undefined":
          return "#B0BEC5"; // Grey for Undefined
      default:
          return "#4CAF50"; // Default light gray for any unspecified types
  }
  }
  
  

  // Example random light color generator
  function getRandomLightColor() {
    const letters = "BCDEF"; // Using only lighter hex characters
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  }

  return <div ref={defectsChartRef} style={{ width: "100%", height: "440px", borderRadius: "10px" }}></div>; // Chart container
};

export default DefectReports;
