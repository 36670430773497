import Breadcrumb from "components/Common/Breadcrumb"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import SimpleBar from "simplebar-react"
import Doughnut from "../Chart/DoughnutChart"
import {
  GetTestPlanStatusAPI,
  testPlanSummaryReportsHistoryAPI,
} from "store/actions/TestPlanActions"
import { useDispatch } from "react-redux"
import TestPlanSummaryHistory from "./TestPlanSummaryHistory"
import { getStatusBackGroundColor } from "helpers/helper"

const ViewTestPlan = () => {
  const [data, setData] = useState([])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const breadcrumbItems = [
    { label: "Test Lab", path: "/TestPlan" },
    {
      label: "Test Plan Details",
      path: "/",
    },
  ]
  const viewData = useSelector(state => state?.TestPlan?.singleTestPlanData)

  useEffect(() => {
    if (!viewData) {
      navigate("/TestPlan")
    }
  })

  useEffect(() => {
    const fetchData = async () => {
      const bodyOption = {
        project_id: viewData?.project_id,
        test_plan_id: viewData?.id,
      }

      try {
        const res = await dispatch(GetTestPlanStatusAPI(bodyOption))
        setData(res?.data) // Assuming the result is in a suitable format
      } catch (error) {
        console.error("Failed to fetch test plan status:", error)
        // Handle the error (e.g., show a message to the user)
      }
    }

    if (viewData) {
      fetchData()
    }
  }, [dispatch, viewData])

  const [summaryHistoryData, setSummaryHistoryData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const bodyOption = {
        project_id: viewData?.project_id,
        testplan_id: viewData?.id,
      }

      try {
        const res = await dispatch(testPlanSummaryReportsHistoryAPI(bodyOption))
        setSummaryHistoryData(res) // Assuming the result is in a suitable format
      } catch (error) {
        console.error("Failed to fetch test plan status:", error)
        // Handle the error (e.g., show a message to the user)
      }
    }

    if (viewData) {
      fetchData()
    }
  }, [dispatch, viewData])

  const avatarGroupStyle = {
    display: "flex",
    flexWrap: "wrap", // Allows items to wrap to the next line
  }

  const handleTestPlanSummary = () => {
    navigate("/TestPlan/TestPlanSummary", {
      state: { viewData: viewData },
    })
  }

  const onClickBack = () => {
    navigate("/TestPlan")
  }

  return (
    <div className="">
      <Container fluid>
        <Breadcrumb
          title="Test Plan Details"
          breadcrumbItems={breadcrumbItems}
        />
        <div className="d-flex justify-content-end mb-2">
          <Button
            type="button"
            color="primary"
            className="btn-label"
            onClick={onClickBack}
          >
            <i className="bx bx-arrow-back label-icon"></i> Go Back
          </Button>
        </div>
        <Card style={{ minHeight: "400px" }}>
          <CardBody>
            <div className="d-flex flex-column flex-md-row justify-content-between mb-4">
              <div className="d-flex mb-3 mb-md-0">
                <div className="avatar-xs me-3">
                  <span
                    className="avatar-title bg-primary"
                    style={{ fontSize: "20px", borderRadius: "8px" }}
                  >
                    {viewData?.test_plan_name?.slice(0, 2).toUpperCase()}
                  </span>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <h5 className="font-size-15 d-flex align-items-center">
                    <span
                      style={{
                        maxWidth: "135px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Link className="text-dark mt-2">
                        {viewData?.test_plan_name || "-"}
                      </Link>
                    </span>
                    {viewData?.test_plan_id && (
                      <span className="ms-2">({viewData?.test_plan_id})</span>
                    )}
                  </h5>
                </div>
              </div>
              <div onClick={handleTestPlanSummary} className="text-sm-end">
                <Button
                  type="button"
                  color="primary"
                  className="btn-label ms-md-2 w-100 w-md-auto"
                >
                  <i className="bx bxs-report label-icon"></i> Generate Test
                  Summary
                </Button>
              </div>
            </div>

            <div className="mb-4">
              <h6 className="card-title">Test Plan Description</h6>
              <p
                className="text-muted mb-4"
                dangerouslySetInnerHTML={{
                  __html: viewData?.description ? viewData?.description : "-",
                }}
              ></p>
            </div>
            <div>
              <Row>
                {/* Left Side - Test Case Results */}
                <Col md="6">
                  <Card>
                    <CardBody>
                      <Container>
                        <h4>Test Case Results</h4>
                        <Doughnut data={data} />
                      </Container>
                    </CardBody>
                  </Card>
                </Col>

                {/* Right Side - Detailed Data */}
                <Col md="6">
                  <Card>
                    <CardBody>
                      <SimpleBar>
                        <div className="data-section">
                          <Row>
                            <Col sm={6}>
                              <h6 className="card-title">Project Name</h6>
                              <p className="text-muted">
                                {viewData?.project ? viewData?.project : "-"}
                              </p>
                            </Col>
                            <Col sm={6}>
                              <h6 className="card-title">Test Plan</h6>
                              <p className="text-muted">
                                {viewData?.test_plan_id
                                  ? viewData?.test_plan_id
                                  : "-"}
                              </p>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm={6}>
                              {" "}
                              <h6 className="card-title">Test Plan Name</h6>
                              <p className="text-muted">
                                {viewData?.test_plan_name
                                  ? viewData?.test_plan_name
                                  : "-"}
                              </p>
                            </Col>
                            <Col sm={6}>
                              <h6 className="card-title">Build</h6>
                              <p className="text-muted">
                                {viewData?.build ? viewData?.build : "-"}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <h6 className="card-title">Start Date</h6>
                              <p className="text-muted">
                                {viewData?.start_date
                                  ? moment(viewData?.start_date).format(
                                      "DD-MM-YYYY"
                                    )
                                  : "-"}
                              </p>
                            </Col>
                            <Col sm={6}>
                              {" "}
                              <h6 className="card-title">End Date</h6>
                              <p className="text-muted">
                                {viewData?.end_date
                                  ? moment(viewData?.end_date).format(
                                      "DD-MM-YYYY"
                                    )
                                  : "-"}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col sm={6}>
                              <h6 className="card-title">Test Plan Status</h6>
                              <>
                                <Badge
                                  className={
                                    "badge-soft-" +
                                    `${getStatusBackGroundColor(
                                      viewData?.test_plan_status
                                    )}`
                                  }
                                  style={{
                                    fontSize: "11px", // Increase font size
                                    padding: "0.5em", // Increase padding
                                    borderRadius: "0.5em", // Adjust border radius
                                  }}
                                >
                                  {viewData?.test_plan_status || "-"}
                                </Badge>
                              </>
                            </Col>
                            <Col sm={6}>
                              <h6 className="card-title">Approval Status</h6>
                              <>
                                <Badge
                                  className={
                                    "badge-soft-" +
                                    `${getStatusBackGroundColor(
                                      viewData?.approval_status
                                    )}`
                                  }
                                  style={{
                                    fontSize: "11px", // Increase font size
                                    padding: "0.5em", // Increase padding
                                    borderRadius: "0.5em", // Adjust border radius
                                  }}
                                >
                                  {viewData?.approval_status || "-"}
                                </Badge>
                              </>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <h6 className="card-title">Assign To</h6>
                              <p className="text-muted">
                                <div style={avatarGroupStyle}>
                                  {viewData?.assign_to_id?.map(user => (
                                    <React.Fragment key={user.id}>
                                      <div className="avatar-group-item">
                                        <Link
                                          to="#"
                                          className="d-inline-block"
                                          id={"member" + user.id}
                                        >
                                          <div className="avatar-xs">
                                            <span
                                              className={`avatar-title rounded-circle bg-primary text-white text-align-center`}
                                              style={{
                                                width: "24px",
                                                height: "24px",
                                                fontSize: "14px",
                                              }}
                                            >
                                              {user.user_name
                                                .charAt(0)
                                                .toUpperCase()}
                                            </span>
                                            <UncontrolledTooltip
                                              placement="top"
                                              target={"member" + user.id}
                                              style={{ zIndex: 9999 }}
                                            >
                                              {user.user_name}
                                            </UncontrolledTooltip>
                                          </div>
                                        </Link>
                                      </div>
                                    </React.Fragment>
                                  ))}
                                </div>
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <h6 className="card-title">Approval From</h6>
                              <p className="text-muted">
                                {viewData?.approval_from_details?.name ? (
                                  <div style={avatarGroupStyle}>
                                    <div className="avatar-xs">
                                      <span
                                        className={`avatar-title rounded-circle bg-primary text-white text-align-center`}
                                        style={{
                                          width: "24px",
                                          height: "24px",
                                          fontSize: "14px",
                                        }}
                                        id="member"
                                      >
                                        {viewData?.approval_from_details?.name
                                          .charAt(0)
                                          .toUpperCase()}
                                      </span>
                                      <UncontrolledTooltip
                                        placement="top"
                                        target={"member"}
                                        style={{ zIndex: 9999 }}
                                      >
                                        {viewData?.approval_from_details?.name}
                                      </UncontrolledTooltip>
                                    </div>
                                  </div>
                                ) : (
                                  "-"
                                )}
                              </p>
                            </Col>
                          </Row>

                          {viewData?.test_cycle_id_info?.map((item, i) => {
                            let isAutomationScriptIDShown = i === 0
                            return (
                              <div key={i}>
                                {isAutomationScriptIDShown && (
                                  <h6 className="card-title">
                                    Test Cycle Details
                                  </h6>
                                )}
                                <p className="text-muted">
                                  {item.test_cycle_id} - {item.name}
                                </p>
                              </div>
                            )
                          })}

                          <h6 className="card-title">Environment</h6>
                          <p
                            className="text-muted"
                            dangerouslySetInnerHTML={{
                              __html: viewData?.environment
                                ? viewData?.environment
                                : "-",
                            }}
                          />

                          <h6 className="card-title">Milestone</h6>
                          <p
                            className="text-muted"
                            dangerouslySetInnerHTML={{
                              __html: viewData?.milestone_description
                                ? viewData?.milestone_description
                                : "-",
                            }}
                          />

                          <h6 className="card-title">Deliverables</h6>
                          <p
                            className="text-muted"
                            dangerouslySetInnerHTML={{
                              __html: viewData?.deliverables
                                ? viewData?.deliverables
                                : "-",
                            }}
                          />

                          <h6 className="card-title">Description</h6>
                          <p
                            className="text-muted"
                            dangerouslySetInnerHTML={{
                              __html: viewData?.description
                                ? viewData?.description
                                : "-",
                            }}
                          />
                        </div>
                      </SimpleBar>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>

        <TestPlanSummaryHistory
          summaryHistoryData={summaryHistoryData}
          viewData={viewData}
        />
      </Container>
    </div>
  )
}

export default ViewTestPlan
