import React, { useEffect } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { logoutUser } from "../../store/actions"

//redux
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setSelectetProject } from "store/Slices/GlobalSlice"
import { useMsal } from "@azure/msal-react"
import { setAllProjectList } from "store/Slices/ProjectsSlice"

const Logout = () => {
  const history = useNavigate()
  const dispatch = useDispatch()
  const { instance } = useMsal();

  const ProfileDetails = JSON.parse(localStorage.getItem("authUser"))
  useEffect(() => {
    if (ProfileDetails?.is_ad_user) {
      instance.logout();
    }
    dispatch(logoutUser(history))
    localStorage.removeItem("selectedProject")
    localStorage.removeItem("activeTab")
    localStorage.removeItem("projectIDForTeam")
    localStorage.removeItem("authUser")
    localStorage.removeItem("selectedProject")
    dispatch(setSelectetProject(""))
    dispatch(setAllProjectList([]))
    window.location.reload();

  }, [dispatch, history,useMsal])

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Logout)

// import PropTypes from "prop-types";
// import React, { useState } from "react";
// import { Row, Col, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
// import httpInjectorService from "services/http-Injector.Service";

// // import images
// import logodark from "../../assets/icons/title-logo.png";
// import logolight from "../../assets/icons/Froth_Logo.png";
// import CarouselPage from "./CarouselPage";

// // redux
// import { useSelector, useDispatch } from "react-redux";
// import { createSelector } from "reselect";
// import { Link, useNavigate } from "react-router-dom";

// // Formik validation
// import * as Yup from "yup";
// import { useFormik } from "formik";

// const Login = (props) => {
//   const dispatch = useDispatch();
//   const [passwordShow, setPasswordShow] = useState(false);
//   const [rememberMe, setRememberMe] = useState(false);
//   const navigate = useNavigate();

//   const handleRememberMeChange = () => {
//     setRememberMe(!rememberMe);
//   };

//   const validation = useFormik({
//     enableReinitialize: true,

//     initialValues: {
//       email: "",
//       password: "",
//     },
//     validationSchema: Yup.object({
//       email: Yup.string().email("Invalid email format").required("Please Enter Your Email"),
//       password: Yup.string().required("Please Enter Your Password"),
//     }),

//     onSubmit: async (values) => {
//       console.log(values);
//       const formData = new FormData();
//       formData.append("email_or_username", values.email);
//       formData.append("password", values.password);
//       formData.append("remember_me", rememberMe);
//       formData.append("is_ad_user", false);

//       try {
//         const response = await httpInjectorService.login(formData);
//         console.log(response);

//         if (response) {
//           localStorage.setItem("rememberMeToken", JSON.stringify(response));
//           navigate("/dashboard");
//         }
//       } catch (error) {
//         console.error("Login failed:", error);
//       }
//     },
//   });

//   const LoginProperties = createSelector(
//     (state) => state.Login,
//     (login) => ({
//       error: login.error,
//     })
//   );

//   const { error } = useSelector(LoginProperties);

//   return (
//     <React.Fragment>
//       <div>
//         <Container fluid className="p-0">
//           <Row className="g-0">
//             <Col xl={4}>
//               <div className="auth-full-page-content p-md-5 p-4">
//                 <div className="w-100">
//                   <div className="d-flex flex-column h-100">
//                     <div className="mb-4 mb-md-5">
//                       <Link to="/dashboard" className="d-block card-logo">
//                         <img src={logolight} alt="" height="18" className="logo-dark-element" />
//                         <img src={logolight} alt="" height="18" className="logo-light-element" />
//                       </Link>
//                     </div>
//                     <div className="my-auto">
//                       <div>
//                         <h5 className="text-primary">Welcome Back!</h5>
//                         <p className="text-muted">Log in to continue with us.</p>
//                       </div>

//                       <div className="mt-4">
//                         <Form
//                           className="form-horizontal"
//                           onSubmit={(e) => {
//                             e.preventDefault();
//                             validation.handleSubmit();
//                           }}
//                         >
//                           {error ? <Alert color="danger">{error}</Alert> : null}

//                           <div className="mb-3">
//                             <Label className="form-label">Email</Label>
//                             <Input
//                               name="email"
//                               className="form-control"
//                               placeholder="Enter email"
//                               type="email"
//                               onChange={validation.handleChange}
//                               onBlur={validation.handleBlur}
//                               value={validation.values.email || ""}
//                               invalid={validation.touched.email && validation.errors.email ? true : false}
//                             />
//                             {validation.touched.email && validation.errors.email ? (
//                               <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
//                             ) : null}
//                           </div>

//                           <div className="mb-3">
//                             <Label className="form-label">Password</Label>
//                             <div className="input-group auth-pass-inputgroup">
//                               <Input
//                                 name="password"
//                                 value={validation.values.password || ""}
//                                 type={passwordShow ? "text" : "password"}
//                                 placeholder="Enter Password"
//                                 onChange={validation.handleChange}
//                                 onBlur={validation.handleBlur}
//                                 invalid={validation.touched.password && validation.errors.password ? true : false}
//                               />
//                               <button
//                                 onClick={() => setPasswordShow(!passwordShow)}
//                                 className="btn btn-light "
//                                 type="button"
//                                 id="password-addon"
//                               >
//                                 <i className="mdi mdi-eye-outline"></i>
//                               </button>
//                             </div>
//                             {validation.touched.password && validation.errors.password ? (
//                               <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
//                             ) : null}
//                           </div>

//                           <div className="form-check">
//                             <Input
//                               type="checkbox"
//                               checked={rememberMe}
//                               onChange={handleRememberMeChange}
//                               className="form-check-input"
//                             />
//                             <label className="form-check-label" htmlFor="auth-remember-check">
//                               Keep me logged in
//                             </label>
//                           </div>

//                           <div className="mt-3 d-grid">
//                             <button className="btn btn-primary btn-block " type="submit">
//                               Log In
//                             </button>
//                           </div>
//                         </Form>

//                         <div className="mt-4 text-center">
//                           <Link to="/forgot-password" className="text-muted">
//                             <i className="mdi mdi-lock me-1" />
//                             Forgot your password?
//                           </Link>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </Col>
//             <CarouselPage />
//           </Row>
//         </Container>
//       </div>
//     </React.Fragment>
//   );
// };

// export default Login;

// import PropTypes from "prop-types";
// import React, { useState } from "react";
// import { Row, Col, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
// import httpInjectorService from "services/http-Injector.Service";

// // import images
// import logodark from "../../assets/icons/title-logo.png";
// import logolight from "../../assets/icons/Froth_Logo.png";
// import CarouselPage from "./CarouselPage";

// // redux
// import { useSelector, useDispatch } from "react-redux";
// import { createSelector } from "reselect";
// import { Link, useNavigate } from "react-router-dom";

// import {loginUser} from "../../store/auth/login/actions"

// // Formik validation
// import * as Yup from "yup";
// import { useFormik } from "formik";

// const Login = (props) => {
//   const dispatch = useDispatch();
//   const [passwordShow, setPasswordShow] = useState(false);
//   const [rememberMe, setRememberMe] = useState(false);
//   const navigate = useNavigate();

//   const handleRememberMeChange = () => {
//     setRememberMe(!rememberMe);
//   };

//   const validation = useFormik({
//     enableReinitialize: true,

//     initialValues: {
//       email: "admin@themesbrand.com" || '',
//       password: "123456" || '',
//     },
//     validationSchema: Yup.object({
//       email: Yup.string().required("Please Enter Your Email"),
//       password: Yup.string().required("Please Enter Your Password"),
//     }),

//     onSubmit: (values) => {
//       console.log(values)

//       dispatch(loginUser(values, props.router.navigate));

//       try {
//         const response =  httpInjectorService.login(values);
//          if (response) {
//           console.log(response)
//             localStorage.setItem("rememberMeToken", JSON.stringify(response));

//             navigate("/Dashboard");
//         }
//       } catch (error) {
//           console.log(error)
//       }
//     }
//   });

//   const LoginProperties = createSelector(
//     (state) => state.Login,
//     (login) => ({
//       error: login.error
//     })
//   );

//   // const validation = useFormik({
//   //   enableReinitialize: true,

//   //   initialValues: {
//   //     email: "",
//   //     password: "",
//   //   },
//   //   validationSchema: Yup.object({
//   //     email: Yup.string().email("Invalid email format").required("Please Enter Your Email"),
//   //     password: Yup.string().required("Please Enter Your Password"),
//   //   }),

//   //   onSubmit: async (values) => {
//   //     console.log(values);
//   //     const formData = new FormData();
//   //     formData.append("email_or_username", values.email);
//   //     formData.append("password", values.password);
//   //     formData.append("remember_me", rememberMe);
//   //     formData.append("is_ad_user", false);

//   //     try {
//   //       const response = await httpInjectorService.login(formData);
//   //       console.log(response);

//   //       if (response) {
//   //         localStorage.setItem("rememberMeToken", JSON.stringify(response));
//   //         navigate("/dashboard");
//   //       }
//   //     } catch (error) {
//   //       console.error("Login failed:", error);
//   //     }
//   //   },
//   // });

//   // const LoginProperties = createSelector(
//   //   (state) => state.Login,
//   //   (login) => ({
//   //     error: login.error,
//   //   })
//   // );

//   const { error } = useSelector(LoginProperties);

//   return (
//     <React.Fragment>
//       <div>
//         <Container fluid className="p-0">
//           <Row className="g-0">
//             <Col xl={4}>
//               <div className="auth-full-page-content p-md-5 p-4">
//                 <div className="w-100">
//                   <div className="d-flex flex-column h-100">
//                     <div className="mb-4 mb-md-5">
//                       <Link to="/dashboard" className="d-block card-logo">
//                         <img src={logolight} alt="" height="18" className="logo-dark-element" />
//                         <img src={logolight} alt="" height="18" className="logo-light-element" />
//                       </Link>
//                     </div>
//                     <div className="my-auto">
//                       <div>
//                         <h5 className="text-primary">Welcome Back!</h5>
//                         <p className="text-muted">Log in to continue with us.</p>
//                       </div>

//                       <div className="mt-4">
//                         <Form
//                           className="form-horizontal"
//                           onSubmit={(e) => {
//                             e.preventDefault();
//                             validation.handleSubmit();
//                           }}
//                         >
//                           {error ? <Alert color="danger">{error}</Alert> : null}

//                           <div className="mb-3">
//                             <Label className="form-label">Email</Label>
//                             <Input
//                               name="email"
//                               className="form-control"
//                               placeholder="Enter email"
//                               type="email"
//                               onChange={validation.handleChange}
//                               onBlur={validation.handleBlur}
//                               value={validation.values.email || ""}
//                               invalid={validation.touched.email && validation.errors.email ? true : false}
//                             />
//                             {validation.touched.email && validation.errors.email ? (
//                               <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
//                             ) : null}
//                           </div>

//                           <div className="mb-3">
//                             <Label className="form-label">Password</Label>
//                             <div className="input-group auth-pass-inputgroup">
//                               <Input
//                                 name="password"
//                                 value={validation.values.password || ""}
//                                 type={passwordShow ? "text" : "password"}
//                                 placeholder="Enter Password"
//                                 onChange={validation.handleChange}
//                                 onBlur={validation.handleBlur}
//                                 invalid={validation.touched.password && validation.errors.password ? true : false}
//                               />
//                               <button
//                                 onClick={() => setPasswordShow(!passwordShow)}
//                                 className="btn btn-light "
//                                 type="button"
//                                 id="password-addon"
//                               >
//                                 <i className="mdi mdi-eye-outline"></i>
//                               </button>
//                             </div>
//                             {validation.touched.password && validation.errors.password ? (
//                               <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
//                             ) : null}
//                           </div>

//                           <div className="form-check">
//                             <Input
//                               type="checkbox"
//                               checked={rememberMe}
//                               onChange={handleRememberMeChange}
//                               className="form-check-input"
//                             />
//                             <label className="form-check-label" htmlFor="auth-remember-check">
//                               Keep me logged in
//                             </label>
//                           </div>

//                           <div className="mt-3 d-grid">
//                             <button className="btn btn-primary btn-block " type="submit">
//                               Log In
//                             </button>
//                           </div>
//                         </Form>

//                         <div className="mt-4 text-center">
//                           <Link to="/forgot-password" className="text-muted">
//                             <i className="mdi mdi-lock me-1" />
//                             Forgot your password?
//                           </Link>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </Col>
//             <CarouselPage />
//           </Row>
//         </Container>
//       </div>
//     </React.Fragment>
//   );
// };

// export default Login;
