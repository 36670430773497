import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector } from "react-redux";

const ActiveDefects = ({ data }) => {
  const layoutModeType = useSelector((state) => state.Layout.layoutModeType); // Get layout mode type from Redux

  const days = data?.map((entry) => entry.day);
  const totals = data?.map((entry) => entry.total);

  // Prepare an array to store data points with their respective dates
  const seriesData = days?.map((day, index) => {
    const dateTime = new Date(day).getTime();
    const value = totals[index];
    return [dateTime, value];
  });

  // Prepare an array to store all dates with null value for missing data points
  const allDatesData = [];
  days?.forEach((day) => {
    const dateTime = new Date(day).getTime();
    allDatesData.push([dateTime, null]);
  });

  const isDarkMode = layoutModeType === "dark"; // Check if dark mode is enabled

  const options = {
    chart: {
      type: "area", // Change to area chart
      backgroundColor: isDarkMode ? "#2a3042" : "white", // Set background color based on mode
    },
    title: {
      text: " ",
      align: "left",
      style: {
        color: isDarkMode ? "#ffffff" : "#000000", // Dynamic title color
      },
    },
    xAxis: {
      type: "datetime",
      tickInterval: 24 * 3600 * 1000, // One day interval
      labels: {
        formatter: function () {
          return Highcharts.dateFormat("%d-%m-%Y", this.value); // Change date format to DD-MM-YYYY
        },
        style: {
          color: isDarkMode ? "#ffffff" : "#000000", // Dynamic label color
        },
      },
      lineColor: isDarkMode ? "#ffffff" : "#000000", // X-axis line color
      tickColor: isDarkMode ? "#ffffff" : "#000000", // Tick marks color
    },
    yAxis: {
      title: {
        text: "Number of Active Defects",
        style: {
          color: isDarkMode ? "#ffffff" : "#000000", // Y-axis title color
        },
      },
      labels: {
        style: {
          color: isDarkMode ? "#ffffff" : "#000000", // Y-axis labels color
        },
      },
      lineColor: isDarkMode ? "#ffffff" : "#000000", // Y-axis line color
      tickColor: isDarkMode ? "#ffffff" : "#000000", // Tick marks color
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        const date = Highcharts.dateFormat("%d-%m-%Y", new Date(this.x)); // Change tooltip date format to DD-MM-YYYY
        const value = this.y;
        return `<b>Date:</b> ${date}<br/><b>Unresolved Defects:</b> ${value}`;
      },
      style: {
        color: isDarkMode ? "#ffffff" : "#000000", // Tooltip text color
      },
      backgroundColor: isDarkMode ? "#2a3042" : "white", // Tooltip background color
    },
    legend: {
      enabled: false, // Disable the legend completely
    },
    series: [
      {
        name: "Active Defects",
        data: seriesData,
        color: isDarkMode ? "#ff6347" : "#4caf50", // Change to your desired colors
        fillOpacity: 0.3, // Set fill opacity for area chart
      },
      {
        name: "All Dates",
        data: allDatesData,
        color: "rgba(0,0,0,0)", // Invisible line for all dates, used for showing all dates on X-axis
        enableMouseTracking: false, // Disable hover events for the invisible line
        showInLegend: false, // Hide from legend (although legend itself is disabled)
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default ActiveDefects;
