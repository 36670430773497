import React, { Fragment, useEffect, useState } from "react"
import {
  Row,
  Table,
  Button,
  Input,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap"
import { Link } from "react-router-dom"

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table"

import { rankItem } from "@tanstack/match-sorter-utils"
import NoData from "components/Common/NoData"
import ExportComponent from "./ExportComponent"

// Column Filter
const Filter = ({ column }) => {
  const columnFilterValue = column.getFilterValue()

  return (
    <>
      <DebouncedInput
        type="text"
        value={columnFilterValue ?? ""}
        onChange={value => column.setFilterValue(value)}
        placeholder="Search..."
        className="w-36 border shadow rounded"
        list={column.id + "list"}
      />
      <div className="h-1" />
    </>
  )
}

// Global Filter
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    

    return () => clearTimeout(timeout)
  }, [debounce, onChange, value])

  return (
    <React.Fragment>
      <Col sm={4} className="d-flex align-items-center">
        <InputGroup className="w-100" style={{ height: "36px" }}>
          <InputGroupText style={{ height: "100%", padding: "0 12px" }}>
            <i className="bx bx-search-alt search-icon"></i>
          </InputGroupText>
          <Input
            {...props}
            value={value}
            onChange={e => setValue(e.target.value)}
            style={{ height: "100%" }}
          />
        </InputGroup>
      </Col>
    </React.Fragment>
  )
}

const GeneratedContainer = ({
  columns,
  data,
  tableClass,
  theadClass,
  divClassName,
  isBordered,
  isPagination,
  isGlobalFilter,
  paginationWrapper,
  SearchPlaceholder,
  pagination,
  isCustomPageSize,
  handleBack,
  pageIndex,
  pageSize,
  globalFilter,
  totalCount,
  downloadReport,
  setPageIndex,
  setPageSize,
  setGlobalFilter,
  // resetFilter,
}) => {
  const [columnFilters, setColumnFilters] = useState([])
  const fuzzyFilter = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value)
    addMeta({ itemRank })
    return itemRank.passed
  }

  const table = useReactTable({
    columns,
    data,
    filterFns: { fuzzy: fuzzyFilter },
    state: { columnFilters, globalFilter, pagination: { pageIndex, pageSize } },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(totalCount / pageSize),
  })

  const {
    getHeaderGroups,
    getRowModel,
    getCanPreviousPage,
    getCanNextPage,
    getPageOptions,
    getState,
  } = table
  const handlePageSizeChange = e => {
    // Reset to page 1 when page size changes
    setPageIndex(0)

    // Ensure setPageIndex is applied before updating page size
    setPageSize(Number(e.target.value))
  }
  // Helper function to get the visible page numbers
  const getVisiblePageNumbers = (currentPage, totalPageCount) => {
    const maxVisiblePages = 4 // Set the maximum number of pages to display
    let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 0)
    let endPage = startPage + maxVisiblePages - 1

    if (endPage >= totalPageCount) {
      endPage = totalPageCount - 1
      startPage = Math.max(endPage - maxVisiblePages + 1, 0)
    }

    return { startPage, endPage }
  }

  const { startPage, endPage } = getVisiblePageNumbers(
    getState().pagination.pageIndex,
    getPageOptions().length
  )

  // Separate useEffect to reset pageIndex only when search term changes
  useEffect(() => {
    if (globalFilter) {
      setPageIndex(0) // Reset to the first page only if globalFilter changes
    }
  }, [globalFilter]) // Depend only on globalFilter

  // Function to handle global filter change
  const handleGlobalFilterChange = value => {
    setGlobalFilter(String(value)) // Set the global filter
    // No need to reset pageIndex here; it's handled by useEffect
  }

  const handlePageChange = newPageIndex => {
    setPageIndex(newPageIndex) // Update the page index state
    // Add any additional logic if needed
  }

  const resetFilter = () => {
    setGlobalFilter(""); // Clear the global search box
    setColumnFilters([]); // Clear the column filters
  };
  return (
    <Fragment>
      <Row className="mb-2 align-items-center">
        <Col xs="12" lg="3">
          <h4 className="font-size-18">Generated Report</h4>
        </Col>
        <Col xs="12" lg="9">
          <div className="d-flex flex-column flex-lg-row justify-content-end align-items-start align-items-lg-center">
            <div className="text-sm-end me-2 mb-2 mb-lg-0">
              <Button
                type="button"
                color="primary"
                className="btn-label me-2"
                onClick={() => {
                  handleBack()
                  setGlobalFilter("")
                }}
              >
                <i className="bx bx-arrow-back label-icon"></i> Go Back
              </Button>
            </div>
            {isGlobalFilter && (
              <DebouncedInput
                value={globalFilter ?? ""}
                onChange={value => setGlobalFilter(String(value))}
                className="form-control search-box mb-2"
                placeholder={SearchPlaceholder}
              />
            )}
            <ExportComponent
              downloadReport={downloadReport}
              resetFilter={resetFilter}
            />
          </div>
        </Col>
      </Row>

      {data?.length > 0 ? (
        <>
          <div
            style={{
              borderRadius: "6px",
              overflowX: "auto", // Ensure horizontal scrolling
              width: "100%", // Full width
              height:"75vh"
            }}
          >
            <Table
              hover
              style={{
                tableLayout: "auto", // Allow automatic column width adjustment
                width: "100%", // Full width of the table
              }}
            >
              <thead>
                {getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          whiteSpace: "nowrap", // Prevent text wrapping
                          overflow: "hidden", // Prevent overflowing
                          textOverflow: "ellipsis", // Add ellipsis for overflowing text
                          maxWidth: "none", // Allow unlimited width
                        }}
                      >
                        {header.isPlaceholder ? null : (
                          <React.Fragment>
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? "cursor-pointer select-none"
                                  : "",
                                onClick:
                                  header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: "",
                                desc: "",
                              }[header.column.getIsSorted()] ?? null}
                            </div>
                            {header.column.getCanFilter() ? (
                              <div>
                                <Filter column={header.column} table={table} />
                              </div>
                            ) : null}
                          </React.Fragment>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {getRowModel().rows.map(row => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => (
                      <td
                        key={cell.id}
                        style={{
                          whiteSpace: "nowrap", // Prevent text wrapping
                          overflow: "hidden", // Prevent overflowing
                          textOverflow: "ellipsis", // Add ellipsis for overflowing text
                          maxWidth: "none", // Allow unlimited width
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {/* Your existing pagination and no data handling */}
          {isPagination && (
            <Row>
              <Col sm={12} md={5} className="d-flex">
                {isCustomPageSize && (
                  <div style={{ width: "125px" }}>
                    <select
                      className="form-select pageSize mb-2"
                      value={table.getState().pagination.pageSize}
                      onChange={handlePageSizeChange}
                    >
                      {[10, 20, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <div className="dataTables_info mx-2 mt-2">
                  {/* Showing {getState().pagination.pageSize} of {totalCount}{" "}
                  Results */}
                  Showing data of {totalCount} entries
                </div>
              </Col>
              <Col sm={12} md={7}>
                <div className={paginationWrapper}>
                  <ul className={pagination}>
                    <li
                      className={`paginate_button page-item previous ${
                        !getCanPreviousPage() ? "disabled" : ""
                      }`}
                    >
                      <Link
                        to="#"
                        className="page-link"
                        onClick={() => {
                          if (getCanPreviousPage()) {
                            handlePageChange(
                              getState().pagination.pageIndex - 1
                            ) // Use handlePageChange here
                          }
                        }}
                      >
                        <i className="mdi mdi-chevron-left"></i>
                      </Link>
                    </li>

                    {startPage > 0 && (
                      <>
                        <li className="paginate_button page-item">
                          <Link
                            to="#"
                            className="page-link"
                            onClick={() => handlePageChange(0)} // Use handlePageChange here
                          >
                            1
                          </Link>
                        </li>
                        {startPage > 1 && (
                          <li className="paginate_button page-item disabled">
                            <span className="page-link">...</span>
                          </li>
                        )}
                      </>
                    )}

                    {getPageOptions()
                      .slice(startPage, endPage + 1)
                      .map((item, key) => (
                        <li
                          key={key}
                          className={`paginate_button page-item ${
                            getState().pagination.pageIndex === item
                              ? "active"
                              : ""
                          }`}
                        >
                          <Link
                            to="#"
                            className="page-link"
                            onClick={() => handlePageChange(item)} // Use handlePageChange here
                          >
                            {item + 1}
                          </Link>
                        </li>
                      ))}

                    {endPage < getPageOptions().length - 1 && (
                      <>
                        {endPage < getPageOptions().length - 2 && (
                          <li className="paginate_button page-item disabled">
                            <span className="page-link">...</span>
                          </li>
                        )}
                        <li className="paginate_button page-item">
                          <Link
                            to="#"
                            className="page-link"
                            onClick={
                              () =>
                                handlePageChange(getPageOptions().length - 1) // Use handlePageChange here
                            }
                          >
                            {getPageOptions().length}
                          </Link>
                        </li>
                      </>
                    )}

                    <li
                      className={`paginate_button page-item next ${
                        !getCanNextPage() ? "disabled" : ""
                      }`}
                    >
                      <Link
                        to="#"
                        className="page-link"
                        onClick={() => {
                          if (getCanNextPage()) {
                            handlePageChange(
                              getState().pagination.pageIndex + 1
                            ) // Use handlePageChange here
                          }
                        }}
                      >
                        <i className="mdi mdi-chevron-right"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          )}
        </>
      ) : (
        <NoData />
      )}
    </Fragment>
  )
}

export default GeneratedContainer
