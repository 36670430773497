import {
  NewProject,
  NewBuild,
  NewModule,
  NewTestScenario,
  NewTestCase,
  NewDefect,
  NewUser,
  NewRequirement,
} from "../../assets/icons/Quick Action"
import { QuickActionImg } from "../../assets/icons/Quick Action"

const quickActionsOptions = [
  { value: "New Project", label: "New Project", icon: NewProject },
  { value: "New Build", label: "New Build", icon: NewBuild },
  { value: "New Module", label: "New Module", icon: NewModule },
  {
    value: "New Requirement",
    label: "New Requirement",
    icon: NewRequirement,
  },
  {
    value: "New Test Scenario",
    label: "New Test Scenario",
    icon: NewTestScenario,
  },
  { value: "New Test Case", label: "New Test Case", icon: NewTestCase },
  { value: "New Defect", label: "New Defect", icon: NewDefect },
  { value: "New User", label: "New User", icon: NewUser },
]

export { quickActionsOptions, QuickActionImg }
