import { getStatusBackGroundColor } from "helpers/helper"
import React from "react"
import { useSelector } from "react-redux"
import { Badge, Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap"

const TestPlanDefectList = ({ testPlanDefectListData }) => {
  const isDarkMode = useSelector(state => state.Layout.layoutModeType)

  const cardHeaderStyle = {
    backgroundColor: isDarkMode === "dark" ? "#32394e" : "#fff",
    // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    color: isDarkMode === "dark" ? "#fff" : "#000",
  }

  const tableHeaderStyle = {
    position: "sticky",
    top: 0,
    backgroundColor: isDarkMode === "dark" ? "#444" : "#fff",
    color: isDarkMode === "dark" ? "#fff" : "#000",
    zIndex: 2,
    borderCollapse: "collapse",
  }

  // Extract headers dynamically from the first item in the list
  const headers =
    testPlanDefectListData.length > 0
      ? Object.keys(testPlanDefectListData[0])
      : []

  // Function to convert camelCase to "Camel Case"
  const camelCaseToWords = text => {
    return text
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/_/g, " ")
      .replace(/\b\w/g, char => char.toUpperCase()) // Capitalize first letter of each word
  }
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader
            className="d-flex justify-content-between align-items-center"
            style={cardHeaderStyle}
          >
            <h5 className="mb-0">Defect List</h5>
            <div id="resultDownload" className="cursor-pointer"></div>
          </CardHeader>
          <CardBody>
            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
              <Table hover striped>
                <thead style={tableHeaderStyle}>
                  <tr>
                    {headers.map(header => (
                      <th key={header}>
                        {header === "defect_name"
                          ? "Defect Summary"
                          : camelCaseToWords(header)}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {testPlanDefectListData.length > 0 ? (
                    testPlanDefectListData.map(item => (
                      <tr key={item.id}>
                        {headers.map(header => (
                          <td key={header}>
                            {header === "comments" ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item[header] || "-",
                                }}
                              />
                            ) : header === "defect_status" ? (
                              <Badge
                                color={getStatusBackGroundColor(item[header])}
                              >
                                {item[header] || "-"}
                              </Badge>
                            ) : (
                              item[header] || "-"
                            )}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <div className="text-center py-3">
                      <b>No records found.</b>
                    </div>
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default TestPlanDefectList
