import { createSlice } from "@reduxjs/toolkit"

const LoadingSlice = createSlice({
  name: "Loading",
  initialState: {
    isLoading: false,
    isReLoading: false,
  },
  reducers: {
    setLoading: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),
    setReLoading: (state, action) => ({
      ...state,
      isReLoading: action.payload,
    }),
  },
})

export const { setLoading,setReLoading } = LoadingSlice.actions
export default LoadingSlice.reducer
