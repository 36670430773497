import React from "react"
import { useSelector } from "react-redux"
import { Alert, Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap"

const TestIncidentSummaryResults = ({ testplanDefectStatusData }) => {
  const isDarkMode = useSelector(state => state.Layout.layoutModeType)

  const cardHeaderStyle = {
    backgroundColor: isDarkMode === "dark" ? "#32394e" : "#fff",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    color: isDarkMode === "dark" ? "#fff" : "#000",
  }

  const tableHeaderStyle = {
    position: "sticky",
    top: 0,
    backgroundColor: isDarkMode === "dark" ? "#444" : "#fff",
    zIndex: 2,
    color: isDarkMode === "dark" ? "#fff" : "#000",
    borderCollapse: "collapse",
  }

  const priorityCounts = testplanDefectStatusData?.data?.priority_counts || {}

  // Getting all unique status keys dynamically
  const dynamicStatusKeys = priorityCounts
    ? Array.from(
        new Set(
          Object.values(priorityCounts).flatMap(item => Object.keys(item))
        )
      )
    : []

  const hasData = Object.keys(priorityCounts).length > 0

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader
            className="d-flex align-items-center justify-content-between"
            style={cardHeaderStyle}
          >
            <h5 className="mb-0">Defect Status by Priority</h5>
          </CardHeader>
          <Alert color="info">
            <b>Note: </b>This summary presents the defect status by priority for
            the test plan. See the sections below for detailed results and
            explanations.
          </Alert>
          <CardBody>
            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
              {hasData ? (
                <Table hover striped>
                  <thead style={tableHeaderStyle}>
                    <tr>
                      <th>Impact/Severity</th>
                      {dynamicStatusKeys.map((status, index) => (
                        <th key={index}>{status.replace(/_/g, " ")}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(priorityCounts).map(
                      ([priority, counts], idx) => (
                        <tr key={idx}>
                          <td>{priority}</td>
                          {dynamicStatusKeys.map((status, index) => (
                            <td key={index}>
                              {counts[status] !== undefined
                                ? counts[status]
                                : "-"}
                            </td>
                          ))}
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              ) : (
                <div className="text-center py-3">
                  <b>No records found.</b>
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default TestIncidentSummaryResults
