import { createSlice } from "@reduxjs/toolkit"

const RequirementSlice = createSlice({
  name: "Requirement",
  initialState: {
    allRequirement: "",
    requirementDetails: "",
    requirementFilterFrom: {
      filter_build_id: null,
      filter_module_id: null,
      filter_Requirement_Source: null,
      filter_requirement_type: null,
      filter_assign_to: null
    },
     editProjectRequirementData:"",
    requirementView : "",
  },

  reducers: {
    setAllRequirement: (state, action) => ({
      ...state,
      allRequirement: action.payload,
    }),
    setAllRequirementDetails: (state, action) => ({
      ...state,
      requirementDetails: action.payload,
    }), 
    setEditProjectReqData: (state, action) => ({
      ...state,
      editProjectRequirementData: action.payload,
    }),
    setRequirementView: (state, action) => ({
      ...state,
      requirementView: action.payload,
    }),
    setRequirementFilterFrom: (state, action) => {
      state.requirementFilterFrom = {
        ...state.requirementFilterFrom,
        ...action.payload,
      }
    },
    clearFilters: state => {
      state.requirementFilterFrom = {
        filter_build_id: null,
        filter_module_id: null,
        filter_Requirement_Source: null,
        filter_requirement_type: null,
        filter_assign_to: null
      }
    },
  },
})

export const { setAllRequirement, setAllRequirementDetails,clearFilters,setRequirementFilterFrom,
  setSelectedProject ,setEditProjectReqData , setRequirementView,
 } = RequirementSlice.actions

export default RequirementSlice.reducer
