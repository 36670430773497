import { createSlice } from "@reduxjs/toolkit"

const AutomationScriptSlice = createSlice({
    name: "AutomationScript",
    initialState: {
        allActionSuiteList: [], 
        NextAutomationScriptId:[],
        editAutomationScriptData:[],
        singleAutomationScriptData:[],
        scriptCode:[],
        dbIntegrationData:[]
    },

    reducers: {
        setAllActionScriptList: (state, action) => ({
            ...state,
            allActionScriptList: action.payload,
        }) ,
        setNextAutomationScriptId: (state, action) => ({
            ...state,
            NextAutomationScriptId: action.payload,
        }),
        setEditAutomationScript: (state, action) => ({
            ...state,
            editAutomationScriptData: action.payload,
        }),
        setSingleAutomationScript: (state, action) => ({
            ...state,
            singleAutomationScriptData: action.payload,
        }),
        setScriptCode: (state, action) => ({
            ...state,
            scriptCode: action.payload,
        }),
        setDbIntegrationData: (state, action) => ({
            ...state,
            dbIntegrationData: action.payload,
        }),
    },
})  

export const { setAllActionScriptList ,setNextAutomationScriptId,setEditAutomationScript,setSingleAutomationScript ,setScriptCode , setDbIntegrationData} = AutomationScriptSlice.actions

export default AutomationScriptSlice.reducer
