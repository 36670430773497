import { createSlice } from "@reduxjs/toolkit"

const RequestPageSlice = createSlice({
  name: "Request",
  initialState: {

    bulkApprovalReject: {
      bulkApproval: true,
      approve: false,
    },
    reportList:"",
    tcData:false,
    tpData:false,
    notificationListTC:"",
    notificationListTP:"",
  },

  reducers: {
    setRequestPage: (state, action) => ({
      ...state,
      bulkApprovalReject: action.payload,
    }),
    setRequestList: (state, action) => ({
      ...state,
      reportList: action.payload,
    }),
    setTcList: (state, action) => ({
      ...state,
      tcData: action.payload,
    }),
    setTpList: (state, action) => ({
      ...state,
      tpData: action.payload,
    }),
    setNotificationListTC: (state, action) => ({
      ...state,
      notificationListTC: action.payload,
    }),
    setNotificationListTP: (state, action) => ({
      ...state,
      notificationListTP: action.payload,
    }),
  },
})

export const { setRequestPage,setRequestList,setNotificationListTC,setNotificationListTP,setTcList,setTpList } =
  RequestPageSlice.actions

export default RequestPageSlice.reducer
