import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, useLocation } from "react-router-dom"
import "../../../../components/Common/Breadcrum.scss"
import { Row, Col, BreadcrumbItem, Container ,Card, CardBody } from "reactstrap"
import PageNavigation from "./PageNavigation"
import { useSelector } from "react-redux"

const Breadcrumb = () => {
  const initialBreadcrumbItems = [
    { label: "Test Lab", path: "/TestCycle" },
    { label: "Test Cycle", path: "/" },
  ]

  const location = useLocation()
  const locationPath = location.pathname
  const pathname = locationPath.split("/").filter(Boolean).pop()
  const pathArray = [
    "ViewTestCycleAutomation","ViewTestCycleManual","ExecuteTestCase","ExecuteTestSuite"
  ]

  const [breadcrumbItems, setBreadcrumbItems] = useState(initialBreadcrumbItems)
  const [activeButton, setActiveButton] = useState("")

  const handleTestCycleClick = () => {
    setActiveButton("TestCycle")
    setBreadcrumbItems(initialBreadcrumbItems)
  }
  const onBreadcrumbClick = () => {
    setActiveButton("TestCycle")
    setBreadcrumbItems(initialBreadcrumbItems)
  }
  const layoutModeType = useSelector(state => state.Layout.layoutModeType)
  return (
    <>
      <div className="page-title-right" style={{ marginTop: "-25px", marginLeft: "-10px" }}>
        <ol className="breadcrumb m-0">
          {breadcrumbItems?.map((item, index) => (
            <BreadcrumbItem
              key={index}
              active={index === breadcrumbItems.length - 1}
            >
              {index === breadcrumbItems.length - 1 ? (
                item.label
              ) : (
                <Link
                  to={item.path}
                  onClick={() => onBreadcrumbClick()}
                >
                  {item.label}
                </Link>
              )}
            </BreadcrumbItem>
          ))}
        </ol>
      </div>
      {!pathArray.includes(pathname) && (
      <Container fluid>
        <Row>
          <Col lg="12">
            <Card className="mb-0" style={{ borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px" }}>
              <CardBody className="border-bottom pt-3 pb-0">
                  <PageNavigation
                    handleTestCycleClick={handleTestCycleClick}
                    activeButton={activeButton}
                    onBreadcrumbClick={onBreadcrumbClick}
                  />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>)}
    </>
  )
}

Breadcrumb.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
    })
  ).isRequired,
}

export default Breadcrumb
