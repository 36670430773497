import BASE_URL from "./apiConfig"
import { getToken } from "helpers/helper"

const PostToAPIQuery = async (url, headersObj = {}) => {
  url = `${BASE_URL}/api/${url}`

  // Ensure token is not null or undefined
  let tokenid = getToken()

  // Add Authorization header if token exists
  const headers = tokenid
    ? { ...headersObj, Authorization: `Bearer ${tokenid}` }
    : headersObj

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
    })
    if (!response.ok) {
      const errorData = await response.json()
      throw new Error(errorData.message || "An unknown error occurred")
    }
    return await response.json()
  } catch (error) {
    // Handle error appropriately
    return { error: error.message || "An unknown error occurred" }
  }
}

export default { PostToAPIQuery }
