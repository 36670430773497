import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Progress } from "reactstrap";

const SuitePageChart = ({ reload }) => {
  const navigate = useNavigate()
  // Extract values from chartData
  const chartData = useSelector(
    state => state?.TestCycle?.testCycleChart
  )
  const approvedPercentage = parseFloat(chartData?.data?.passed_percentage);
  const pendingPercentage = parseFloat(chartData?.data?.notrun_percentage);
  const rejectedPercentage = parseFloat(chartData?.data?.failed_percentage);
  const totalTestCases = chartData?.data?.total_count;

  const totalPercentage = approvedPercentage + pendingPercentage + rejectedPercentage;

  const handleReload = () => {
    reload()
  }

  return (
    <Card className="mb-2 rounded-0">
      <CardBody className="d-flex flex-column flex-md-row justify-content-between p-2 align-items-center border-bottom"> 
      <div className="col-12 col-md-5 d-flex">
          {/* Total Test Cases */}
          <Col className="border-end border-dark text-center">
            <h6>TOTAL SCRIPTS</h6>
            <h4>{totalTestCases}</h4>
          </Col>

          {/* Approved */}
          <Col className="border-end border-dark text-center">
            <h6>PASSED</h6>
            <h4>{approvedPercentage}%</h4>
          </Col>

          {/* Pending Approval */}
          <Col className="text-center border-end border-dark me-2">
            <h6>NOT RUN</h6>
            <h4>{pendingPercentage}%</h4>
          </Col>
        </div>



        {/* Progress Bar */}
        <div className="col-12 col-md-5">
          <Col
            className="pt-2 mb-0"
            style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}
          >
            <Progress multi style={{ height: "0.8rem" }}>
              <Progress bar color="success" value={(approvedPercentage / totalPercentage) * 100}>
                {approvedPercentage}%
              </Progress>
              <Progress bar color="danger" value={(rejectedPercentage / totalPercentage) * 100}>
                {rejectedPercentage}%
              </Progress>
              <Progress bar color="warning" value={(pendingPercentage / totalPercentage) * 100}>
                {pendingPercentage}%
              </Progress>
            </Progress>

            <div className="d-flex justify-content-around mt-3">
              <div className="d-flex align-items-center">
                <div className="progress-legend bg-success"></div>
                <span>PASSED</span>
              </div>
              <div className="d-flex align-items-center">
                <div className="progress-legend bg-danger"></div>
                <span>FAILED</span>
              </div>
              <div className="d-flex align-items-center">
                <div className="progress-legend bg-warning"></div>
                <span>NOT RUN</span>
              </div>
            </div>
          </Col>
        </div>
        <div className="d-flex justify-content-center justify-content-md-end mt-3 mt-md-0">
          <Col className="text-center text-secondary border fs-2" style={{ cursor: 'pointer' }} onClick={handleReload}>
            <i className="bx bx-sync"></i>
          </Col>
        </div>
      </CardBody>

    </Card>
  );
};

export default SuitePageChart;
