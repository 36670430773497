import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  fetchCycleComparisonTestCaseResult,
  fetchProjectdasboardCycleComparison,
} from "store/actions/ProjectDashboardActions"
import CycleComparisonTable from "./CycleComparisonTable"
import CycleComparisonGraph from "./CycleComparisonGraph"
import { Col, Container, Row, Spinner } from "reactstrap"
import TestCasesResultsTable from "./TestCasesResultsTable"
import { fetchTestCycleDetailsAPI } from "store/actions/TestCycleActions"
import Select from "react-select"
import { toast } from "react-toastify"
import _ from "lodash" // For debouncing
import NoPermission from "components/Common/NoPermission"

const CycleComparisonReport = () => {
  const dispatch = useDispatch()
  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )
  // const globalProject = JSON.parse(localStorage.getItem("selectedProject"))

  const [data, setData] = useState([])
  const [testcaseresults, setTestcaseresults] = useState([])
  const [TestCycleOptionValue, setTestCycleOptionValues] = useState([])
  const [search, setSearch] = useState([])
  const [loadingComparison, setLoadingComparison] = useState(false)
  console.log("loadingComparison", loadingComparison)
  const handleCycleSearch = values => {
    setSearch(values)
  }

  const cycle_values_only = search?.map(project => project.value)

  // Fetch test cycles and comparison data
  const fetchData = _.debounce(async () => {
    if (!globalProject?.value) {
      return // Prevent fetching if globalProject is not available
    }

    const bodyOptions = {
      project_id: globalProject?.value,
      cycle_ids: cycle_values_only,
    }

    try {
      const [cycleComparison, cycleComparisonTestCaseResults] =
        await Promise.all([
          dispatch(fetchCycleComparisonTestCaseResult(bodyOptions)),
          dispatch(fetchProjectdasboardCycleComparison(bodyOptions)),
        ])

      setData(cycleComparison?.data || [])
      setTestcaseresults(cycleComparisonTestCaseResults?.data || [])
    } catch (error) {
      console.error("Failed to fetch comparison data", error)
      toast.error("Failed to fetch comparison data.")
    } finally {
      setLoadingComparison(false)
    }
  }, 500) // Debounce by 500ms

  useEffect(() => {
    fetchData()
  }, [search]) // Re-run when search or globalProject changes

  useEffect(() => {
    if (!globalProject?.value) {
      return // Prevent fetching if globalProject is not available
    }

    const bodyOptions = {
      project_id: globalProject?.value,
    }

    const fetchOptions = async () => {
      try {
        const [
          cycleComparison,
          cycleComparisonTestCaseResults,
          TestCycleResponse,
        ] = await Promise.all([
          dispatch(fetchCycleComparisonTestCaseResult(bodyOptions)),
          dispatch(fetchProjectdasboardCycleComparison(bodyOptions)),
          dispatch(fetchTestCycleDetailsAPI(bodyOptions)),
        ])

        setData(cycleComparison?.data)
        setTestcaseresults(cycleComparisonTestCaseResults?.data)
        setTestCycleOptionValues(
          TestCycleResponse?.map(entry => ({
            value: Math.round(entry.id),
            label: entry.test_cycle_id,
          }))
        )
      } catch (error) {
        console.error("Failed to fetch options", error)
        toast.error("Failed to fetch options.")
      }
    }

    fetchOptions()
  }, [globalProject, dispatch])

  const selectStyles = {
    container: provided => ({
      ...provided,
      width: "300px", // Adjust the width as needed
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: "150px",
      overflowY: "auto",
      background: "#fff",
    }),
    control: provided => ({
      ...provided,
      width: "100%", // Ensure control takes the full width of the container
      minHeight: "38px", // Fixed height for the select input
    }),
    valueContainer: provided => ({
      ...provided,
      maxHeight: "38px", // Limit height to prevent growth
      overflowY: "auto", // Add scroll when it exceeds the height
    }),
    multiValue: provided => ({
      ...provided,
      margin: "0", // Remove extra margin to keep height minimal
    }),
    multiValueLabel: provided => ({
      ...provided,
      overflow: "hidden", // Prevent text overflow
      textOverflow: "ellipsis",
    }),
  }
  const permission = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission = permission?.permissions?.[0]?.menu_item?.Reports
  return (
    <Container fluid>
      {rolePermission?.cyclecomparisonreports?.create ? (
        <>
          {" "}
          <Row>
            <Col>
              <div className="table-container">
                <CycleComparisonGraph
                  data={data}
                  selectStyles={selectStyles}
                  TestCycleOptionValue={TestCycleOptionValue}
                  search={search}
                  handleCycleSearch={handleCycleSearch}
                />
                <CycleComparisonTable data={data} />
                <TestCasesResultsTable testcaseresults={testcaseresults} />
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <NoPermission />
      )}
    </Container>
  )
}

export default CycleComparisonReport
