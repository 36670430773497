import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'; 
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Input, Label } from 'reactstrap';
import { DbConnectionAutoscript, DbConnectionDetails } from 'store/actions/AutomationScriptAction';
import { setDbIntegrationData } from 'store/Slices/AutomationScriptSlice';

const ManageDbIntegration = (props) => {
  const { setDbPopup, setCopyPopup ,onClose} = props;
    const dispatch = useDispatch()
    const [validationErrMsg, setValidationErrMsg] = useState("");
    const [loading, setLoading] = useState(false); 
    const [query, setQuery] = useState(null);

    const [queryType, setQueryType] = useState("select");

    const [dbOptions, setDbOptions] = useState(null);
    const [dbName, setDbName] = useState(null);
    const currentUrl = window.location.href;
    const isCreateMode = currentUrl.includes("CreateAutomationScript");
    const isEditMode = currentUrl.includes("EditAutomationScript");

    console.log(dbName);
    const checkValidations = () => {
        const errors = {};
        if (dbName === null) errors.dbName = "Please select DataBase";
        if (!query) errors.query = "Please enter Query";
        return errors;
    };

    const globalPropject = JSON.parse(localStorage.getItem("selectedProject"))

    useEffect(() => {
        const fetchDbConnectionDetails = async () => {
            try {
                let bodyoption = {
                    project_id: globalPropject?.value,
                };
                const response = await dispatch(DbConnectionDetails(bodyoption));
    
                if (response) {
                    const dbList = response.map((res) => ({
                        label: res?.db_connection_name,
                        value: res?.id,
                    }));
                    setDbOptions(dbList);
                }
            } catch (error) {
                console.error("Failed to fetch DB connection details:", error);
            }
        };
    
        if (globalPropject?.value) {
            fetchDbConnectionDetails();
        }
    }, [globalPropject?.value, dispatch]);
    

    const handleCreate = async () => {
        const validationErrorMsg = checkValidations();
        setValidationErrMsg(validationErrorMsg);
        setLoading(true);
        let bodyoptions = {
            id: dbName?.value,
            db_connection_name: dbName?.label,
            database_type: "MySQL",
            query_type: queryType,
            query: query,
        };

        if (Object.keys(validationErrorMsg)?.length === 0) {
            try {
                let response = await dispatch(DbConnectionAutoscript(bodyoptions));
                if (response?.message) {
                    toast.success(response.message, {
                        position: "top-right",
                        autoClose: 3000,
                    });
                } 
                handleClose();
                handleCopyCode(bodyoptions);
            } catch (error) {
                setLoading(false);
                setValidationErrMsg({ form: error.message });
                // toast.warn(error?.message || "An error occurred. Please try again.", {
                //     position: "top-right",
                //     id: "module create Error ",
                //     autoClose: 3000,
                // });
            }
        } else {
            setLoading(false);
        }
    };

    const handleClose = () => {
        onClose()
    };

    const handleCopyCode = (data) => {
        const dbdata = {
            code_descriptions: `const result = await Util.F.connectToDB(${data.id}, '${data.query_type}', '${data.query}');
      console.log("Total number of record is:" + result.totalNumberOfRecords);
      console.log("Result of the query :" + result.records[0].id);`,
        };
        // setCopyPopup({ click: true, data: dbdata });
        dispatch(setDbIntegrationData(dbdata))
        setDbPopup()
    };

    return (
        <>
            <div className=" ">
                <div className=" ">
                    <Label>DataBase<span className="text-danger">*</span></Label>
                    <Select
                        placeholder="Select Automation Script ID"
                        value={dbName}
                        onChange={(selectedOption) => setDbName(selectedOption)}
                        options={dbOptions}
                        className="select2-selection"
                        isClearable={true}
                        styles={{
                            menu: (provided) => ({
                                ...provided,
                                backgroundColor: 'white',
                            }),
                        }}
                    />
                    {validationErrMsg?.dbName && (
                        <span className="text-danger">
                            {validationErrMsg?.dbName}
                        </span>
                    )}
                </div>
                <div className="mt-2" >
                    <Label >
                        Query Type<span className="text-danger">*</span>
                    </Label>
                    <div className='mt-1' style={{ display: "flex" }}>
                        <div className="form-check form-radio-outline form-radio-primary mx-2 mb-3">
                            <input
                                type="radio"
                                id="customRadiooutlinecolor1"
                                name="customRadiooutlinecolor1"
                                className="form-check-input"
                                checked={queryType === "select"}
                                onChange={() => setQueryType("select")}
                                onClick={() => setQueryType("select")}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="customRadiooutlinecolor1"
                            >
                                Select
                            </label>
                        </div>

                        <div className="form-check form-radio-outline form-radio-primary mx-2 mb-3">
                            <input
                                type="radio"
                                id="customRadiooutlinecolor2"
                                name="customRadiooutlinecolor2"
                                className="form-check-input"
                                checked={queryType === "insert"}
                                onChange={() => setQueryType("insert")}
                                onClick={() => setQueryType("insert")}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="customRadiooutlinecolor2"
                            >
                                Insert
                            </label>
                        </div>

                        <div className="form-check form-radio-outline form-radio-primary mb-3">
                            <input
                                type="radio"
                                id="customRadiooutlinecolor3"
                                name="customRadiooutlinecolor3"
                                className="form-check-input"
                                checked={queryType === "update"}
                                onChange={() => setQueryType("update")}
                                onClick={() => setQueryType("update")}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="customRadiooutlinecolor3"
                            >
                                Update
                            </label>
                        </div>
                    </div>
                </div>

                <div className=" "> 
                        <Label >
                            Query<span className="text-danger">*</span>
                        </Label> 
                        <Input
                            type="textarea"
                            id="textarea"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)} 
                            placeholder="Query."
                        />
                        {validationErrMsg?.query && (
                            <span className="text-danger">
                                {validationErrMsg?.query}
                            </span>
                        )}
                    </div>
                </div> 

            <div className="d-flex justify-content-end gap-2 mt-2"
            >
                <Button type="button" color="secondary" onClick={handleClose}>CANCEL</Button>
                <Button type="submit" color="primary" onClick={handleCreate}>{isEditMode
                        ? loading
                            ? "Loading..."
                            : "UPDATE"
                        : loading
                            ? "Loading..."
                            : "SAVE"}</Button>
                                        
            </div>
        </>
    )
}

export default ManageDbIntegration
