import { toast } from "react-toastify"
import httpInjectorService from "services/http-Injector.Service"
import { setLoading } from "store/Slices/LoadingSlice"
import { setAllRequirement, setAllRequirementDetails ,
  setEditProjectReqData,
  setRequirementView
} from "store/Slices/RequirementSlice"

export const fetchAllRequirementsList = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.getAllRequirementsAPI(
        queryString
      )
      const data = response
      dispatch(setAllRequirement(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}


export const fetchRequirementDetails = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.getRequirements(
        queryString
      )
      const data = response
      dispatch(setAllRequirementDetails(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

 

export const setProjEditRequirementData = data => { 
  return async (dispatch) => {
    dispatch( setEditProjectReqData(data))
  }
}

export const updateRequirement = (id ,bodyoption) => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null 
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.EditRequirement(id ,formData)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Requirement updated successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }else{
        dispatch(setLoading(false))
        toast.warning(response.message, {
          position: "top-right", 
          autoClose: 3000,
        })
      }
      return response
    } catch (error) {
      dispatch(setLoading(false))
      throw error; 
    }
  }
}


export const RequirementView = (id ) => {
  return async dispatch => {
    dispatch(setLoading(true)) 
    try {
      const response = await httpInjectorService.RequirementView(id )
      dispatch(setRequirementView(response)) 
      if (response?.status) {
        // toast.success(response.message, {
        //   position: "top-right", 
        //   autoClose: 3000,
        // })
        dispatch(setLoading(false))
      }else{
        toast.warning(response.message, {
          position: "top-right", 
          autoClose: 3000,
        })
      }
      return response
    } catch (error) {
      dispatch(setLoading(false))
      throw error; 
    }
  }
}

export const downloadRequirementTemplateAPI = bodyOption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyOption) {
      if (
        bodyOption[key] !== undefined &&
        bodyOption[key] !== null &&
        bodyOption[key] !== ""
      ) {
        formData.append(key, bodyOption[key])
      }
    }
    try {
      const response = await httpInjectorService.downloadRequirementTemplate(
        formData
      )
      const blob = response.data
      const downloadLink = document.createElement("a")
      downloadLink.href = window.URL.createObjectURL(blob)
      downloadLink.download = "Requirement Template"
      document.body.appendChild(downloadLink)

      // Programmatically trigger the download
      downloadLink.click()

      // Clean up
      document.body.removeChild(downloadLink)
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      console.error("Download failed:", error.message || error)
    }
  }
}


export const ExportRequirementAPI = bodyOption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyOption) {
      if (
        bodyOption[key] !== undefined &&
        bodyOption[key] !== null &&
        bodyOption[key] !== ""
      ) {
        formData.append(key, bodyOption[key])
      }
    }
    try {
      const response = await httpInjectorService.exportRequirementData(
        formData
      )
      const blob = response.data
      const downloadLink = document.createElement("a")
      downloadLink.href = window.URL.createObjectURL(blob)
      downloadLink.download = "Requirement"
      document.body.appendChild(downloadLink)

      // Programmatically trigger the download
      downloadLink.click()

      // Clean up
      document.body.removeChild(downloadLink)
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      console.error("Download failed:", error.message || error)
    }
  }
}

export const uploadRequirementTemplateAPI = bodyOption => {
  return async dispatch => {
    dispatch(setLoading(true))

    try {
      const response = await httpInjectorService.uploadRequirementTemplate(
        bodyOption
      ) 

      // Initialize an array to collect error messages
      // let errorMessages = []

      // // Function to recursively collect error messages
      // const collectErrors = data => {
      //   if (Array.isArray(data)) {
      //     data.forEach(item => collectErrors(item))
      //   } else if (typeof data === "object" && data !== null) {
      //     for (let key in data) {
      //       if (Array.isArray(data[key])) {
      //         data[key].forEach(item => collectErrors(item))
      //       } else if (typeof data[key] === "string") {
      //         const trimmedMessage = data[key].trim()
      //         if (trimmedMessage) {
      //           errorMessages.push(trimmedMessage)
      //         }
      //       } else if (typeof data[key] === "object") {
      //         collectErrors(data[key])
      //       }
      //     }
      //   }
      // }

      // // Collect errors from the response
      // collectErrors(response)

      // // Join all collected messages with a single space and ensure no extra spaces
      // const unifiedErrorMessage =
      //   errorMessages.join(" ").trim() || "Something went wrong"

      // if (unifiedErrorMessage !== "Something went wrong") {
      //   toast.error(unifiedErrorMessage, {
      //     position: "top-right",
      //     id: "Test",
      //     autoClose: 3000,
      //   })
      // }
      return response;
    } catch (error) {
      console.error("Upload failed:", error.message || error)
      // toast.error("Upload failed. Please try again later.", {
      //   position: "top-right",
      //   id: "Test",
      //   autoClose: 3000,
      // })
    } finally {
      dispatch(setLoading(false))
    }
  }
}