import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Progress } from "reactstrap";

const TestCaseChart = ({ chartData }) => {
  const navigate = useNavigate()
  // Extract values from chartData
  const approvedPercentage = parseFloat(chartData.approved_percentage);
  const pendingPercentage = parseFloat(chartData.pending_percentage);
  const rejectedPercentage = parseFloat(chartData.rejected_percentage);
  const newPercentage = parseFloat(chartData.new_percentage);
  const totalTestCases = chartData.total_test_cases;

  const totalPercentage = approvedPercentage + pendingPercentage + rejectedPercentage +newPercentage;

  const handlePreCondition = () => {
    navigate("/TestManagement/PreCondition")
  }

  return (
    <Container fluid>
      <Card className="mb-0 rounded-0">
        <CardBody className="d-flex flex-column flex-md-row justify-content-between p-2 align-items-center border-bottom">
          <div className="col-12 col-lg-5 d-flex flex-wrap justify-content-between">
            {/* Total Test Cases */}
            <Col xs="4" className="text-center p-1">
              <h6 className="mb-1 text-sm">TOTAL TEST CASES</h6>
              <h4 className="mb-0">{totalTestCases}</h4>
            </Col>

            {/* Approved */}
            <Col xs="4" className="text-center p-1">
              <h6 className="mb-1 text-sm">APPROVED</h6>
              <h4 className="mb-0">{approvedPercentage}%</h4>
            </Col>

            {/* Pending Approval */}
            <Col xs="4" className="text-center p-1">
              <h6 className="mb-1 text-sm">PENDING APPROVAL</h6>
              <h4 className="mb-0">{pendingPercentage}%</h4>
            </Col>
          </div>

          {/* Progress Bar */}
          <div className="col-12 col-lg-5 mt-3 mt-md-0">
            <Col
              className="pt-2 mb-0"
              style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}
            >
              <Progress multi style={{ height: "0.8rem" }}>
                <Progress bar color="success" value={(approvedPercentage / totalPercentage) * 100}>
                  {approvedPercentage}%
                </Progress>
                <Progress bar color="primary" value={(newPercentage / totalPercentage) * 100}>
                  {newPercentage}%
                </Progress>
                <Progress bar color="danger" value={(rejectedPercentage / totalPercentage) * 100}>
                  {rejectedPercentage}%
                </Progress>
                <Progress bar color="warning" value={(pendingPercentage / totalPercentage) * 100}>
                  {pendingPercentage}%
                </Progress>
              </Progress>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  flexWrap: "wrap",
                }}
              >
                {[
                  { color: "rgb(52 195 143)", label: "APPROVED" },
                  { color: "blue", label: "NEW" },
                  { color: "red", label: "REJECTED" },
                  { color: "orange", label: "PENDING APPROVAL" },
                ].map(({ color, label }) => (
                  <div key={label} style={{ display: "flex", alignItems: "center", margin: "5px 0" }}>
                    <div
                      style={{
                        backgroundColor: color,
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        marginRight: "5px",
                      }}
                    ></div>
                    <span>{label}</span>
                  </div>
                ))}
              </div>
            </Col>
          </div>

          <div className="col-12 col-lg-2 d-flex mt-3 mt-md-0">
            {/* Precondition */}
            <Col className="ms-2 text-center" style={{ cursor: 'pointer' }} onClick={handlePreCondition}>
              <h6 className="p-3 border rounded border-dark">PRECONDITION</h6>
            </Col>
          </div>
        </CardBody>

      </Card>
    </Container>
  );
};

export default TestCaseChart;
