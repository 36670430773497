import React from "react"
import ReactEcharts from "echarts-for-react"
import { useSelector } from "react-redux"

// Define the getChartColorsArray function directly in this file
const getChartColorsArray = dataColors => {
  return dataColors || ["#28a745", "#dc3545", "#ffc107"]
}

const Doughnut = ({ data }) => {
  const dataColors = ["#28a745", "#dc3545", "#ffc107"]

  // Access layout mode type from Redux to toggle between dark and light modes
  const layoutModeType = useSelector((state) => state.Layout.layoutModeType)

  // Dynamic chart colors based on mode
  const isDarkMode = layoutModeType === "dark"

  const doughnutEChartColors = getChartColorsArray(dataColors)

  const options = {
    backgroundColor: isDarkMode ? "#2a3042" : "#fff", // Dynamic background color
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
      backgroundColor: isDarkMode ? "#2a3042" : "#fff", // Tooltip background color
      textStyle: {
        color: isDarkMode ? "#ffffff" : "#000000", // Tooltip text color
      },
    },
    legend: {
      orient: "vertical",
      left: "left",
      data: ["Passed", "Failed", "Not Run"],
      textStyle: {
        color: isDarkMode ? "#ffffff" : "#8791af", // Legend text color
      },
    },
    color: doughnutEChartColors,
    series: [
      {
        // Background circle for total_test_plan
        type: "pie",
        radius: ["0%", "40%"], // Inner circle radius
        label: {
          show: false, // Hide the label for this series
        },
        data: [
          { value: 1, name: "Background", itemStyle: { color: isDarkMode ? "#383e56" : "#f0f0f0" } }, // Dynamic background color
        ],
      },
      {
        name: "Test Cases Results",
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: true,
          position: "outside",
          formatter: "{b}-\n{d}%",
          textStyle: {
            fontSize: "12",
            fontWeight: "normal",
            color: isDarkMode ? "#ffffff" : "#333", // Dynamic label color
          },
        },
        labelLine: {
          show: true,
          lineStyle: {
            color: isDarkMode ? "#ffffff" : "#333", // Dynamic label line color
          },
        },
        data: [
          { value: data.PASSED_Count, name: "Passed" },
          { value: data.FAILED_Count, name: "Failed" },
          { value: data.NOTRUN_Count, name: "Not Run" },
        ],
        center: ["50%", "50%"],
      },
      {
        // Series to display total_test_plan in the center
        type: "pie",
        radius: ["0%", "40%"], // Match the radius of the background circle
        tooltip: {
          show: false, // Disable tooltip for this series
        },
        label: {
          show: true,
          position: "center",
          formatter: `Total\n${data.total_test_plan}`,
          textStyle: {
            fontSize: "20",
            fontWeight: "bold",
            color: isDarkMode ? "#ffffff" : "#333", // Dynamic text color in the center
          },
        },
        labelLine: {
          show: false, // Hide the label line for the center label
        },
        data: [
          { value: 1, name: "Total", itemStyle: { color: "transparent" } }, // Transparent color to avoid any overlap
        ],
      },
    ],
  }

  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={options} />
    </React.Fragment>
  )
}

export default Doughnut
