const localhostClientId = "b9403d8c-1f57-4d1b-851b-f8baf97d18e7";
const devClientId = "795d31eb-8d63-4ac1-88ad-f863c4431a9f";
const rdClientId = "951e2d3f-56ed-4a44-962b-783af39d3638"; // Add the client ID for rd.testops.com
const ytlcClientId = "1b22bd60-9da4-4c40-a4f8-7d2e9f9d07aa"; // Add the client ID for rd.testops.com
const localRedirectUri = "http://localhost:3000/Dashboard";
const devRedirectUri = "https://uatrvrd.frothtestops.com/Dashboard";
const rdRedirectUri = "https://rd.frothtestops.com/Dashboard"; // Add the redirect URI for rd.testops.com
const ytlcRedirectUri = "https://ytlc.frothtestops.com/Dashboard"; // Add the redirect URI for rd.testops.com
const nsRedirectUri = "https://ns.frothtestops.com/Dashboard"; // Add the redirect URI for rd.testops.com
const nsClientId = "fd207397-7b53-4fb3-9c84-a4f1b9648b50"; 

const hostname = window.location.hostname;
let clientId, redirectUri,authority

if (hostname === "localhost") {
  clientId = localhostClientId;
  redirectUri = localRedirectUri;
} else if (hostname === "uatrvrd.frothtestops.com") {
  clientId = devClientId;
  redirectUri = devRedirectUri;
} else if (hostname === "rd.frothtestops.com") {
  clientId = rdClientId;
  redirectUri = rdRedirectUri;
} else if (hostname === "ytlc.frothtestops.com") {
  clientId = ytlcClientId;
  redirectUri = ytlcRedirectUri;
}else if (hostname === "ns.frothtestops.com") {
  clientId = nsClientId;
  redirectUri = nsRedirectUri;
}

if (hostname === "ytlc.frothtestops.com") {
  authority =
    "https://login.microsoftonline.com/258fe6e1-c7f2-468c-b5c0-14704710889d";
} else if(hostname === "ns.frothtestops.com") {
  authority =
    "https://login.microsoftonline.com/258fe6e1-c7f2-468c-b5c0-14704710889d";
} else {
  authority =
    "https://login.microsoftonline.com/16c00da5-32e1-4bab-8472-99f12ee8b808";
}

const msalConfig = {
  auth: {
    clientId: clientId,
    authority:authority,
    redirectUri: redirectUri,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you're having issues on IE11 or Edge
  },
};

const loginRequest = {
  scopes: ["User.Read"], // Add the scopes you want to request
};

export { msalConfig, loginRequest };
