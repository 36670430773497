import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import { useSelector } from "react-redux"; // Importing useSelector

const SeverityDefectsChart = ({ severityDefects }) => {
  const stackedGroupedChartRef2 = useRef(null);
  const layoutModeType = useSelector(state => state.Layout.layoutModeType); // Get layout mode from Redux

  useEffect(() => {
    if (stackedGroupedChartRef2.current && severityDefects?.data) {
      try {
        const filteredSeverityDefects = severityDefects?.data?.filter(
          project =>
            project?.severity_defects &&
            Object.values(project.severity_defects)?.some(count => count !== 0)
        );

        if (filteredSeverityDefects?.length === 0) {
          console.warn("No valid projects with severity defects found.");
          return;
        }

        const categories = filteredSeverityDefects?.map(
          project => project?.project_name
        );

        const severityTypes = [
          ...new Set(
            filteredSeverityDefects?.flatMap(project =>
              Object.keys(project.severity_defects)
            )
          ),
        ];

        const seriesData = severityTypes?.map(severity => ({
          name: severity,
          data: filteredSeverityDefects?.map(project =>
            project.severity_defects[severity] !== undefined
              ? project.severity_defects[severity]
              : null
          ),
          color: getColorForSeverity(severity),
        }));

        // Configure chart options with dark mode support
        const stackedGroupedOptions = {
          chart: {
            type: "column",
            backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#FFFFFF", // Dark mode background
          },
          title: {
            text: "",
            align: "left",
            style: {
              color: layoutModeType === "dark" ? "#FFFFFF" : "#000000", // Title color
            },
          },
          xAxis: {
            categories: categories,
            labels: {
              style: {
                color: layoutModeType === "dark" ? "#FFFFFF" : "#000000", // X-axis label color
              },
            },
          },
          credits: {
            enabled: false,
          },
          yAxis: {
            min: 0,
            title: {
              text: "No. of Defects",
              style: {
                color: layoutModeType === "dark" ? "#FFFFFF" : "#000000", // Y-axis title color
              },
            },
            labels: {
              style: {
                color: layoutModeType === "dark" ? "#FFFFFF" : "#000000", // Y-axis label color
              },
            },
            stackLabels: {
              enabled: true,
              style: {
                color: "white", // Stack label color
              },
            },
          },
          legend: {
            align: "center",
            verticalAlign: "bottom",
            layout: "horizontal",
            itemStyle: {
              color: layoutModeType === "dark" ? "#FFFFFF" : "#000000", // Legend text color
            },
          },
          tooltip: {
            headerFormat: "<b>{point.x}</b><br/>",
            pointFormat:
              "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
            backgroundColor: layoutModeType === "dark" ? "#000000" : "#ffffff",
            style: {
              color: layoutModeType === "dark" ? "#f0f0f0" : "#000000", // Tooltip text color
            },
          },
          plotOptions: {
            column: {
              stacking: "normal",
              dataLabels: {
                enabled: true,
                color: layoutModeType === "dark" ? "#FFFFFF" : "black", // Data label color
                style: {
                  textOutline: "none",
                },
              },
            },
          },
          series: seriesData,
        };

        // Render the chart using Highcharts
        Highcharts.chart(stackedGroupedChartRef2.current, stackedGroupedOptions);
      } catch (error) {
        console.log("Severity defects chart:", error.message);
      }
    }
  }, [severityDefects, layoutModeType]); // Add layoutModeType as a dependency

  function getColorForSeverity(severity) {
    switch (severity) {
      case "Blocker":
        return "#FFB3A7"; // Light color for Blocker
      case "Critical":
        return "#FFCC99"; // Light color for Critical
      case "Major":
        return "#A3B8FF"; // Light color for Major
      case "Minor":
        return "#A2FFC3"; // Light color for Minor
      default:
        return getRandomLightColor(); // Random light color for undefined severity
    }
  }

  function getRandomLightColor() {
    const letters = "BCDEF"; // Using only lighter hex characters
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  }

  return <div ref={stackedGroupedChartRef2}></div>;
};

export default SeverityDefectsChart;
