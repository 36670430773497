import React, { useEffect, useMemo, useState } from "react"
import { UncontrolledTooltip } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import DeleteModal from "components/Common/DeleteModal"
import httpInjectorService from "services/http-Injector.Service"
import { toast } from "react-toastify"
import TableContainer from "components/Common/TableContainer"
import moment from "moment"
import {
  BulkDeleteRequirement,
  setSelectedRequirement,
  RequirementDetails,
  BuildsDetails,
  ModuleDetails,
  TagStatus,
  TeamsDetails,
} from "../../../store/ProjectManagement/actions"
import {
  fetchRequirementDetails,
  setProjEditRequirementData,
  RequirementView,
} from "store/actions/RequirementActions"
import { useDispatch } from "react-redux"
import RequirementFilter from "./Filter/RequirementFilter"
import BulkEditRequirments from "./Edit/BulkEditRequirments"
import NoPermission from "components/Common/NoPermission"

const ProjectRequirement = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const RequirementsData = useSelector(
    state => state.Requirement.requirementDetails
  )
  const requirementFilterFrom = useSelector(
    state => state.Requirement.requirementFilterFrom
  )
  const [selectedRows, setSelectedRows] = useState([])
  const [Requirements, setRequirements] = useState([])

  const [requirementId, setReqirementTd] = useState()
  const [deleteModal, setDeleteModal] = useState(false)
  const [bulkdeleteModal, setBulkDeleteModal] = useState(false)
  const [isFilterFormOpen, setIsFilterFormOpen] = useState(false)
  const [isEditFormOpen, setIsEditFormOpen] = useState(false)
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [totalCount, setTotalCount] = useState(null)
  const [globalFilter, setGlobalFilter] = useState("")
  const currentUrl = window.location.href
  const parts = currentUrl.split("/")
  const id = parts[parts.length - 1]

  const permission = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission =
    permission?.permissions?.[0]?.menu_item?.ProjectManagement

  useEffect(() => {
    if (RequirementsData && RequirementsData.Data) {
      setRequirements(RequirementsData.Data)
    }
  }, [RequirementsData])

  useEffect(() => {
    dispatch(setSelectedRequirement(selectedRows))
  }, [selectedRows])

  useEffect(() => {
    fetchRequirementsData(pageIndex, pageSize, globalFilter)
  }, [pageIndex, pageSize, globalFilter])

  const fetchRequirementsData = async (page, size, search) => {
    let bodyoption = {
      page: page + 1,
      page_size: size,
      search: search,
      project_id: id,
      filter_build_id: requirementFilterFrom.filter_build_id,
      filter_module_id: requirementFilterFrom.filter_module_id,
      filter_Requirement_Source:
        requirementFilterFrom.filter_Requirement_Source,
      filter_requirement_type: requirementFilterFrom.filter_requirement_type,
      filter_assign_to: requirementFilterFrom.filter_assign_to,
      _timestamp: new Date().getTime(),
    }
    try {
      const data = await dispatch(fetchRequirementDetails(bodyoption))
      setRequirements(data.Data)
      setTotalCount(data.TotalCount)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  // Handle checkbox change for individual rows
  const handleCheckboxChange = requirement_id => {
    setSelectedRows(prevValues =>
      prevValues.includes(requirement_id)
        ? prevValues.filter(val => val !== requirement_id)
        : [...prevValues, requirement_id]
    )
  }

  // Handle select/deselect all checkboxes
  const handleSelectAll = e => {
    const isChecked = e.target.checked
    if (isChecked) {
      const newSelectedRows = Requirements.map(item => item.requirement_id)
      setSelectedRows(newSelectedRows)
    } else {
      setSelectedRows([])
    }
  }

  const onClickDelete = users => {
    if (rolePermission?.requirement?.delete) {
      setReqirementTd(users.id)
      setDeleteModal(true)
    } else {
      toast.warn("You don't have the permission to delete requirement", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const handleBulkDelete = async () => {
    const bodyoption = {
      project_id: id,
      requirement_ids: selectedRows,
    }
    try {
      dispatch(BulkDeleteRequirement(bodyoption))
      setSelectedRows([])
      setBulkDeleteModal(false)
      setTimeout(() => {
       fetchRequirementsData(pageIndex, pageSize, globalFilter)
      }, 1000);
    } catch (error) {
      console.error("Error deleting Requirement:", error)
    }
  }

  const onClickView = users => {
    if (rolePermission?.requirement?.view) {
      dispatch(RequirementView(users.id))
      navigate(`/ProjectManagement/View-Requirement/${id}`)
    } else {
      toast.warn("You don't have the permission to view requirement", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const onClickEdit = requirement => {
    if (rolePermission?.requirement?.edit) {
      dispatch(setProjEditRequirementData(requirement))
      navigate(`/ProjectManagement/Edit-Requirement/${id}`)
    } else {
      toast.warn("You don't have the permission to edit requirement", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const handleAddClick = () => {
    if (rolePermission?.requirement?.create) {
      navigate(`/ProjectManagement/Create-Requirement/${id}`)
    } else {
      toast.warn("You don't have the permission to create requirement", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const handleEditClick = () => {
    if (rolePermission?.requirement?.edit) {
      try {
        if (selectedRows?.length > 0) {
          const pId = `project_id=${id}`
          const pIds = `?project_id=${id}`
          const formData = new FormData()
          formData.append("project_id", pId)
          dispatch(BuildsDetails(pIds))
          dispatch(TagStatus(pIds, formData))
          dispatch(ModuleDetails(pIds))
          setIsEditFormOpen(!isEditFormOpen)
        } else {
          toast.warn(
            "Please select a project requirements before attempting to edit.",
            {
              position: "top-right",
              id: "selectBeforeDelete",
              autoClose: 3000,
            }
          )
        }
      } catch (error) {
        console.error("Error deleting project requirements:", error)
      }
    } else {
      toast.warn("You don't have the permission to edit requirement's", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const handleDeleteClick = async () => {
    if (rolePermission?.requirement?.delete) {
      try {
        if (selectedRows?.length > 0) {
          setBulkDeleteModal(true)
        } else {
          toast.warn(
            "Please select a project requirements before attempting to delete.",
            {
              position: "top-right",
              id: "selectBeforeDelete",
              autoClose: 3000,
            }
          )
        }
      } catch (error) {
        console.error("Error deleting project requirements:", error)
      }
    } else {
      toast.warn("You don't have the permission to delete requirement's", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const handleFilterClick = () => {
    setIsFilterFormOpen(!isFilterFormOpen)
    const pId = `project_id=${id}`
    const pIds = `?project_id=${id}`
    const formData = new FormData()
    formData.append("project_id", pId)
    dispatch(BuildsDetails(pIds))
    dispatch(RequirementDetails(pIds))
    dispatch(TagStatus(pIds, formData))
    dispatch(ModuleDetails(pIds))
    dispatch(TeamsDetails(pIds))
  }

  const handleDeleteUser = () => {
    if (requirementId) {
      deleteRequirement()
      fetchRequirementsData(pageIndex, pageSize, globalFilter)
    }
    setDeleteModal(false)
  }

  const deleteRequirement = async () => {
    try {
      const response = await httpInjectorService.deleteRequirement(
        requirementId
      )
      console.log(response)
      if (response.status) {
        fetchRequirementsData(pageIndex, pageSize, globalFilter)
        const errorMessage =
          typeof response.message === "string"
            ? response.message
            : response.message.join(", ")
        toast.success(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
      }
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      })
    }
  }

  const handleFilterSubmit = async values => {
    setPageIndex(0)
    let bodyoption = {
      page: 0 + 1,
      page_size: pageSize,
      search: globalFilter,
      project_id: id,
      filter_build_id: requirementFilterFrom?.filter_build_id,
      filter_module_id: requirementFilterFrom?.filter_module_id,
      filter_Requirement_Source:
        requirementFilterFrom?.filter_Requirement_Source,
      filter_requirement_type: requirementFilterFrom?.filter_requirement_type,
      filter_assign_to: requirementFilterFrom?.filter_assign_to,
    }
    try {
      const data = await dispatch(fetchRequirementDetails(bodyoption))
      setRequirements(data.Data)
      setTotalCount(data.TotalCount)
      setIsFilterFormOpen(false)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  const columns = useMemo(
    () => [
      {
        header: (
          <input
            type="checkbox"
            onChange={handleSelectAll}
            style={{ cursor: "pointer" }}
            title="Select All"
            checked={
              selectedRows.length === Requirements?.length &&
              Requirements?.length > 0
            }
          />
        ),
        accessorKey: "checkbox",
        cell: cellProps => (
          <input
            type="checkbox"
            style={{ cursor: "pointer" }}
            checked={selectedRows.includes(
              cellProps.row.original.requirement_id
            )}
            onChange={() => {
              const jobData = cellProps.row.original.requirement_id
              handleCheckboxChange(jobData)
            }}
            onClick={() => {
              const jobData = cellProps.row.original.requirement_id
              handleCheckboxChange(jobData)
            }}
          />
        ),
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Requirement Id",
        accessorKey: "requirement_id",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Requirement Title",
        accessorKey: "requirement_title",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
            const requirement_title = cell.getValue()
            return (
              <>
                <p
                  className="font-size-14 mb-1"
                  style={{
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                    whiteSpace: "normal",
                    maxWidth: "300px", // Set the max-width to control the wrapping
                  }}
                >
                  {requirement_title ? requirement_title : "-"}
                </p>
              </>
            )
          },
      },
      {
        header: "Parent Requirement",
        accessorKey: "parent_requirement",
        enableColumnFilter: false,
        enableSorting: true, 
        cell: cell => {
            const parent_requirement = cell.getValue()
            return (
              <>
                <p
                  className="font-size-14 mb-1"
                  style={{
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                    whiteSpace: "normal",
                    maxWidth: "300px", // Set the max-width to control the wrapping
                  }}
                >
                  {parent_requirement ? parent_requirement : "-"}
                </p>
              </>
            )
          },
      },
      {
        header: "Created Date",
        accessorKey: "created_at",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ getValue }) => {
          const value = getValue()
          return value ? moment(value).format("DD-MM-YYYY") : "-"
        },
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => (
          <ul className="list-unstyled hstack gap-1 mb-0">
            <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
              <span
                // to="/"
                className="btn btn-sm btn-soft-primary"
                onClick={() => {
                  const jobData = cellProps.row.original
                  onClickView(jobData)
                }}
                id={"viewtooltip"}
              >
                <i className="mdi mdi-eye-outline" />
              </span>
            </li>
            <UncontrolledTooltip placement="top" target={"viewtooltip"}>
              View
            </UncontrolledTooltip>

            <li>
              <span
                className="btn btn-sm btn-soft-info"
                onClick={() => {
                  const jobData = cellProps.row.original
                  onClickEdit(jobData)
                }}
                id={"edittooltip"}
              >
                <i className="mdi mdi-pencil-outline" />
                <UncontrolledTooltip placement="top" target={"edittooltip"}>
                  Edit
                </UncontrolledTooltip>
              </span>
            </li>

            <li>
              <Link
                to="#"
                className="btn btn-sm btn-soft-danger"
                onClick={() => {
                  const jobData = cellProps.row.original
                  onClickDelete(jobData)
                }}
                id={"deletetooltip"}
              >
                <i className="mdi mdi-delete-outline" />
                <UncontrolledTooltip placement="top" target={"deletetooltip"}>
                  Delete
                </UncontrolledTooltip>
              </Link>
            </li>
          </ul>
        ),
      },
    ],
    [selectedRows, Requirements]
  )

  const onSubmitEditData = () => {
    setSelectedRows([])
    setTimeout(() => {
      fetchRequirementsData(pageIndex, pageSize, globalFilter)
     }, 1000);
  }

  const onCancelFilter = () => {
    setSelectedRows([])
  }

  const onCancelBulkDelete = () => {
    setSelectedRows([])
    setBulkDeleteModal(false)
  }

  return (
    <React.Fragment>
      {rolePermission?.requirement?.view ? <>
      {/* {Requirements?.length === 0 ? (
                <NoData />
            ) : ( */}
      <TableContainer
        columns={columns}
        data={Requirements || []}
        pageIndex={pageIndex}
        pageSize={pageSize}
        totalCount={totalCount}
        setPageIndex={setPageIndex}
        setPageSize={setPageSize}
        isCustomPageSize={true}
        isGlobalFilter={true}
        isJobListGlobalFilter={false}
        globalFilter={globalFilter}
        isPagination={true}
        isAddDelete={true}
        isAddEdit={true}
        isAddFilter={true}
        isAddButton={true}
        setGlobalFilter={setGlobalFilter}
        handleDeleteClick={handleDeleteClick}
        handleAddClick={handleAddClick}
        handleEditClick={handleEditClick}
        handleFilterClick={handleFilterClick}
        SearchPlaceholder="Search..."
        tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
        theadClass="table-light"
        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
        pagination="pagination"
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
      {/* )} */}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
        message={"Requirement"}
      />

      <DeleteModal
        show={bulkdeleteModal}
        onDeleteClick={handleBulkDelete}
        onCloseClick={onCancelBulkDelete}
        message={"Requirements"}
      />

      <RequirementFilter
        isFilterFormOpen={isFilterFormOpen}
        toggle={handleFilterClick}
        onSubmitFilterFrom={handleFilterSubmit}
        onCancelFilterFrom={onCancelFilter}
      />
      <BulkEditRequirments
        isOpen={isEditFormOpen}
        toggle={handleEditClick}
        onSubmitEdit={onSubmitEditData}
      />
      </> : <NoPermission />}
    </React.Fragment>
  )
}

export default ProjectRequirement
