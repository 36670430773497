import React, { useEffect, useState } from "react"
import { Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap"
import Select from "react-select"
import { useDispatch } from "react-redux"
import { CreateNewRepo } from "store/actions/IntegrationAction"
import { toast } from "react-toastify"
import { setReLoading } from "store/Slices/LoadingSlice"


const NewRepoCreation = props => {
    const { isFormOpen, toggle, integrationID, handleCloseRepo, fetchOrgList,fetchOptions } = props
    const dispatch = useDispatch()
    const [validationErrMsg, setValidationErrMsg] = useState("");
    const [loading, setLoading] = useState(false);

    const [organization, setOrganization] = useState(
        null
    );
    const [gitRepo, setGitRepo] = useState(
        null
    );

    useEffect(()=>{
        setValidationErrMsg("")
        setOrganization(null);
        setGitRepo(null)
    },[])

    const handleClose = () =>{
        setValidationErrMsg("")
        setOrganization(null);
        setGitRepo(null)
        handleCloseRepo()
        setLoading(false)
    }
    const customStyles = {
        menu: provided => ({
            ...provided,
            backgroundColor: "white",
        }),
        menuList: provided => ({
            ...provided,
            maxHeight: 150, 
            backgroundColor:"white",// Set max height for dropdown
            overflowY: "auto", // Enable vertical scroll
            zIndex: 1000, // Ensure dropdown appears above other elements
          }),
    }

    const checkValidations = () => {
        const errors = {};
            if (!gitRepo) errors.gitRepo = "Repository Name is required";
            if (!organization) errors.organization = "Organization is required";
        return errors;
    };

    const togglePage = ()=>{
        setValidationErrMsg("")
        setOrganization(null);
        setGitRepo(null)
        toggle()
    }

    

    const handleSave = async (event) => {
        event.preventDefault()

        const validationErrorMsg = checkValidations();
        setValidationErrMsg(validationErrorMsg);
        if (Object.keys(validationErrorMsg)?.length === 0) {
            setLoading(true)
            dispatch(setReLoading(true))
            let bodyoptions;
            bodyoptions = {
                org_name: organization,
                repo_name: gitRepo,
                intergration_id:integrationID
            };

            try {
                await dispatch(CreateNewRepo(bodyoptions));
                handleClose()
                dispatch(setReLoading(false))
                fetchOptions()
            } catch (error) {
                toast.error(error?.message || "An error occurred while creating repo ", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                })
            }
            dispatch(setReLoading(false))
            setLoading(false)
        }
    };

    return (
        <Offcanvas isOpen={isFormOpen} direction="end" toggle={togglePage}>
            <OffcanvasHeader toggle={togglePage}>Create Repository</OffcanvasHeader>
            <OffcanvasBody>
                <Label>Make sure your token has the following permissions <span className="text-danger">*</span></Label>
                <ol>
                    <li>admin:org</li>
                    <li>admin:repo_hook</li>
                    <li>repo</li>
                    <li>workflow</li>
                </ol>
                <form>
                    <div className="mb-2">
                        <Label>Organization <span className="text-danger">*</span></Label>
                        <Select
                            defaultValue={
                                organization ? { label: organization, value: organization } : ""
                            }
                            label="Single select"
                            onChange={(e) => setOrganization(e ? e?.value : null)}
                            options={fetchOrgList}
                            isClearable={true}
                            styles={customStyles}
                            maxMenuHeight={200}
                            className="select2-selection"
                        />
                        {validationErrMsg?.organization && (
                            <span className="text-danger">
                                {validationErrMsg?.organization}
                            </span>
                        )}
                    </div>
                    <div className="mb-2">
                        <Label>Repository Name <span className="text-danger">*</span></Label>
                        <Input
                             value={gitRepo}
                             placeholder="Repository Name"
                             onChange={(e) => {
                              const value = e.target.value;
                              const repoNameRegex = /^[a-zA-Z0-9_-]*$/;
                              if (repoNameRegex.test(value)) {
                                  setGitRepo(value);
                                  setValidationErrMsg({ ...validationErrMsg, gitRepo: "" });
                              } else {
                                  setValidationErrMsg({
                                      ...validationErrMsg,
                                      gitRepo: "Repository name can only include hyphen and underscore"
                                  });
                              }
                          }}
                        />
                        {validationErrMsg?.gitRepo && (
                            <span className="text-danger">
                                {validationErrMsg?.gitRepo}
                            </span>
                        )}
                    </div>
                </form>
                <div className="d-flex justify-content-between mt-2">
                    <button type="submit" className="btn btn-primary" onClick={(e) => {
                        handleSave(e);
                    }}>
                        {loading?"Loading...":"Submit"}
                    </button>
                </div>
            </OffcanvasBody>
        </Offcanvas>
    )
}

export default NewRepoCreation
