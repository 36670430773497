import { toast } from "react-toastify"
import httpInjectorService from "services/http-Injector.Service"
import { setLoading } from "store/Slices/LoadingSlice"
import { setNotificationListTC,setNotificationListTP, setRequestList } from "store/Slices/RequestPageSlice"

export const RequestList = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.getRequestList(
        queryString
      )
      const data = response
      dispatch(setRequestList(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const NotificationListTC = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.getNotificationListTC(
        queryString
      )
      const data = response
      dispatch(setNotificationListTC(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const NotificationListTP = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.getNotificationListTP(
        queryString
      )
      const data = response
      dispatch(setNotificationListTP(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const editAudit = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
     if (
          bodyoption[key] !== undefined &&
          bodyoption[key] !== null &&
          bodyoption[key] !== ""
        ) {
          formData.append(key, bodyoption[key])
        }
    }
    try {
      const response = await httpInjectorService.updateAudit(
        formData,
        bodyoption?.id
      )
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Role updated successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      } else {
        const errorData = response
        throw new Error(
            errorData?.errors ||
            errorData?.message ||
            "Something went wrong"
        )
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error)
    }
  }
}

export const bulkEditAudit = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
     if (
          bodyoption[key] !== undefined &&
          bodyoption[key] !== null &&
          bodyoption[key] !== ""
        ) {
          formData.append(key, bodyoption[key])
        }
    }
    try {
      const response = await httpInjectorService.BulkUpdateAudit(
        formData
      )
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Role updated successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      } else {
        const errorData = response
        throw new Error(
            errorData?.errors ||
            errorData?.message ||
            "Something went wrong"
        )
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error)
    }
  }
}

export const NotificationListViewTC = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.getNotificationListViewTC(
        queryString
      )
      const data = response
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const NotificationListViewTP = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.getNotificationListViewTP(
        queryString
      )
      const data = response
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}


export const bulkNotificationFlag = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.bulkNotify(formData)
      if (response?.status) {
        dispatch(setLoading(false))
      } else {
        throw new Error(response.errors)
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}