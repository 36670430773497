import React, { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

const PageNavigation = props => {
  const { handleTestCycleClick, activeButton, onBreadcrumbClick } = props
  const navigate = useNavigate()

  const location = useLocation()
  const locationPath = location.pathname
  const pathname = locationPath.split("/").filter(Boolean).pop()

  const handleTestCycle = () => {
    navigate("/TestCycle")
    handleTestCycleClick()
  }

  useEffect(()=>{
    if (pathname == "ViewTestCycleManual" || pathname == "ViewTestCycleAutomation") {
      handleTestCycleClick()
    }
  },[pathname])

  useEffect(() => {
    if (pathname == "TestCycle") {
      onBreadcrumbClick()
    }
  }, [pathname])
  return (
    <>
      <div className="">
        <div
          className="btn-group"
          role="group"
          style={{ whiteSpace: "nowrap" }}
        >
          <input
            type="radio"
            className="btn-check"
            id="btnradio1"
            name="btnradio"
            autoComplete="off"
            checked={activeButton === "TestCycle"} // Sync with active state
          />
          <label
            className="btn btn-outline-primary"
            htmlFor="btnradio1"
            onClick={handleTestCycle}
          >
            Test Cycle
          </label>
        </div>
      </div>
    </>
  )
}

export default PageNavigation
