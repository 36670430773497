import React, { useEffect, useState, useMemo } from "react"
import withRouter from "components/Common/withRouter"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import DeleteModal from "components/Common/DeleteModal"
import { useSelector, useDispatch } from "react-redux"
import { toast, ToastContainer } from "react-toastify"
import TableContainer from "../common/TableContainer"
import BulkDeleteModal from "components/Common/BulkDeleteModal"
import {
  BulkDeleteReportsTemplatesAPI,
  deleteSavedTemplateAPI,
  fetchAllReportsTemplateAPI,
} from "store/actions/ReportsAction"
import { reports } from "../helper/config"
import { useNavigate } from "react-router-dom"
import moment from "moment"

const TestCaseSavedTemplateTable = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const [data, setData] = useState([])

  const [deleteModal, setDeleteModal] = useState(false)
  const [bulkDeleteModal, setBulkDeleteModal] = useState(false)
  const [isDeleteId, setIsDeleteId] = useState("")

  const onClickDelete = data => {
    setIsDeleteId(data?.id)
    setDeleteModal(true)
  }

  const [selectedRows, setSelectedRows] = useState([])
  const handleCheckboxChange = id => {
    setSelectedRows(prevValues =>
      prevValues.includes(id)
        ? prevValues.filter(val => val !== id)
        : [...prevValues, id]
    )
  }

  const handleSelectAll = e => {
    const isChecked = e.target.checked
    if (isChecked) {
      const newSelectedRows = data.map(item => item.id)
      setSelectedRows(newSelectedRows)
    } else {
      setSelectedRows([])
    }
  }

  const columns = useMemo(
    () => [
      {
        header: (
          <input
            type="checkbox"
            onChange={handleSelectAll}
            style={{ cursor: "pointer" }}
            title="Select All"
            checked={
              Array.isArray(selectedRows) &&
              selectedRows.length === data?.length &&
              data?.length > 0
            }
          />
        ),
        accessorKey: "checkbox",
        cell: cellProps => {
          const jobData = cellProps.row.original.id

          const isChecked = () => {
            if (!Array.isArray(selectedRows)) {
              console.error("selectedRows is not an array")
              return false
            }
            return selectedRows.includes(jobData)
          }

          const handleCheckboxClick = () => {
            try {
              if (!Array.isArray(selectedRows)) {
                throw new Error("selectedRows is not an array")
              }
              handleCheckboxChange(jobData)
            } catch (error) {
              console.error(error.message)
            }
          }

          return (
            <input
              type="checkbox"
              style={{ cursor: "pointer" }}
              checked={isChecked()}
              onChange={handleCheckboxClick}
              onClick={handleCheckboxClick}
            />
          )
        },
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Name",
        accessorKey: "template_name",
        cell: cell => <div>{cell.getValue()}</div>,
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return (
            <p
              style={{
                wordWrap: "break-word",
                overflowWrap: "break-word",
                whiteSpace: "normal",
                maxWidth: "300px", // Set the max-width to control the wrapping
              }}
            >
              {cell.getValue()}
            </p>
          )
        },
      },

      {
        header: "Report Type",
        accessorKey: "report_type",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const build = cell.getValue()
          return <>{build ? build : "-"}</>
        },
      },
      {
        header: "Created By",
        accessorKey: "created_by_name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const created_by_name = cell.getValue()
          return <>{created_by_name ? created_by_name?.name : "-"}</>
        },
      },
      {
        header: "Created At",
        accessorKey: "created_at",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return <>{moment(cell.getValue()).format("DD-MM-YYYY")}</>
        },
      },
      {
        header: "Action",
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li>
                <span
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const userData = cellProps.row.original
                    onClickDelete(userData)
                  }}
                  id={`deletetooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`deletetooltip-${cellProps.row.original.id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </span>
              </li>
            </ul>
          )
        },
      },
    ],
    [selectedRows, data]
  )

  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [globalFilter, setGlobalFilter] = useState("")
  const [totalCount, setTotalCount] = useState(null)

  const fetchTableData = async (page, size, search) => {
    let bodyoption = {
      page: page + 1,
      page_size: size,
      search: search,
      project_id: globalProject?.value || selectedProject?.value,
      report_type: reports?.report_type?.testCase,
    }
    try {
      const data = await dispatch(fetchAllReportsTemplateAPI(bodyoption))
      setData(data.Data)
      setTotalCount(data.TotalCount)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  useEffect(() => {
    fetchTableData(pageIndex, pageSize, globalFilter)
  }, [pageIndex, pageSize, globalFilter, globalProject?.value])

  const handleDelete = async () => {
    try {
      await dispatch(deleteSavedTemplateAPI(isDeleteId))
      setDeleteModal(false)
      await fetchTableData(pageIndex, pageSize, globalFilter)
      setIsDeleteId("")
    } catch (error) {
      console.error("Error deleting test case reports templates:", error)
    }
  }

  const handleDeleteClick = async () => {
    try {
      if (selectedRows?.length > 0) {
        setBulkDeleteModal(true)
      } else {
        toast.warn(
          "Please select a test case reports templates before attempting to bulk delete.",
          {
            position: "top-right",
            id: "selectBeforeDelete",
            autoClose: 3000,
          }
        )
      }
    } catch (error) {
      console.error("Error deleting test scenario:", error)
    }
  }

  const handleBulkDelete = async () => {
    const bodyoption = {
      project_id: globalProject?.value || selectedProject?.value,
      report_ids: selectedRows,
    }

    console.log("bodyoption", bodyoption)
    try {
      await dispatch(BulkDeleteReportsTemplatesAPI(bodyoption))
      setSelectedRows([])
      setBulkDeleteModal(false)
      await fetchTableData(pageIndex, pageSize, globalFilter)
    } catch (error) {
      console.error("Error deleting test scenario:", error)
    }
  }
  const handleBack = () => {
    navigate(-1)
  }
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
        message="Test Case Report Template"
      />
      <BulkDeleteModal
        show={bulkDeleteModal}
        onDeleteClick={handleBulkDelete}
        onCloseClick={() => {
          setBulkDeleteModal(false)
          setSelectedRows([])
        }}
        message="Templates"
      />

      <Container fluid>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <div className="table-container">
                  <TableContainer
                    columns={columns}
                    data={data}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    totalCount={totalCount}
                    setPageIndex={setPageIndex}
                    setPageSize={setPageSize}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    isGlobalFilter={true}
                    isPagination={true}
                    SearchPlaceholder="Search..."
                    isCustomPageSize={true}
                    isAddDelete={true}
                    handleBack={handleBack}
                    handleDeleteClick={handleDeleteClick}
                    buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal ms-2 mb-2"
                    buttonName="Create"
                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                    theadClass="table-light"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    pagination="pagination"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <ToastContainer />
    </React.Fragment>
  )
}

export default withRouter(TestCaseSavedTemplateTable)
