import React, { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

const PageNavigation = props => {
  const {
    handleUsers,
    handleRoles,
    handleIntegration,
    handleTags,
    activeButton,
    onBreadcrumbClick,
  } = props
  const navigate = useNavigate()

  const location = useLocation()
  const locationPath = location.pathname
  const pathname = locationPath.split("/").filter(Boolean).pop()

  const handleRole = () => {
    navigate("Roles")
    handleRoles()
  }

  const handleUser = () => {
    navigate("Users")
    handleUsers()
  }

  const handleIntegrations = () => {
    navigate("Integration")
    handleIntegration()
  }

  const handleTag= () => {
    navigate("Tags")
    handleTags()
  }

  useEffect(() => {
    if(pathname == "Settings"){
      handleRole()
    }
    else if(pathname =="Users"){
      handleUser()
    }
    else if(pathname =="Integration"){
      handleIntegrations()
    }
    else if(pathname =="Tags"){
      handleTag()
    }
  }, [pathname])

  // useEffect(() => {
  //   if (pathname == "Roles") {
  //     onBreadcrumbClick()
  //   }
  // }, [pathname])
  return (
    <>
      <div>
        <div
          className="btn-group"
          role="group"
          style={{ whiteSpace: "nowrap" }}
        >
          <input
            type="radio"
            className="btn-check"
            id="btnradio1"
            name="btnradio"
            autoComplete="off"
            checked={activeButton === "Roles"} // Sync with active state
          />
          <label
            className="btn btn-outline-primary"
            htmlFor="btnradio1"
            onClick={handleRole}
          >
            Roles
          </label>

          <input
            type="radio"
            className="btn-check"
            id="btnradio2"
            name="btnradio"
            autoComplete="off"
            checked={activeButton === "Users"} // Sync with active state
          />
          <label
            className="btn btn-outline-primary"
            htmlFor="btnradio2"
            onClick={handleUser}
          >
            Users
          </label>
          <input
            type="radio"
            className="btn-check"
            id="btnradio3"
            name="btnradio"
            autoComplete="off"
            checked={activeButton === "Integration"} // Sync with active state
          />
          <label
            className="btn btn-outline-primary"
            htmlFor="btnradio3"
            onClick={handleIntegrations}
          >
            Integration
          </label>

          <input
            type="radio"
            className="btn-check"
            id="btnradio4"
            name="btnradio"
            autoComplete="off"
            checked={activeButton === "Tags"} // Sync with active state
          />
          <label
            className="btn btn-outline-primary"
            htmlFor="btnradio4"
            onClick={handleTag}
          >
            Tags
          </label>
        </div>
      </div>
    </>
  )
}

export default PageNavigation
