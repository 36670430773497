import React, { useState, useEffect } from "react";
import style from "./CopyPopUp.module.scss";
import { MdOutlineClose } from "react-icons/md";
import { FaRegCopy } from "react-icons/fa6";
import Editor1 from "../Editor/Editor1";

export default function CopyPopUp({ setCopyPopup, codeData, onCopy }) {
  const [copied, setCopied] = useState(false);

  const codeText =
    typeof codeData === "string" ? codeData : codeData?.code_descriptions || "";

  const handleClose = () => {
    setCopyPopup({ click: false, data: null });
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(codeText);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
      if (onCopy) onCopy(); // Call the onCopy prop if provided
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  // Call the parent onCopy method if triggered from the external button
  useEffect(() => {
    if (onCopy) onCopy(handleCopy);
  }, [onCopy]);

  return (
    <div className={style.PopUpContainer}>
      <div className={style.PopUpEditor}>
        <div
          className={style.CopyButton}
          onClick={handleCopy}
        >
          <FaRegCopy
            color="#fff"
            size={20}
            style={{
              position: "relative",
              right: "0.5rem",
              border: "1px solid rgba(118, 119, 123, 1)",
              padding: "5px",
              borderRadius: "3px",
            }}
          />
        </div>
        <div className={style.Editor}>
          <Editor1 type={"readOnly"} editorCode={codeText} />
        </div>
      </div>
    </div>
  );
}
