// // components/SessionTimeoutHandler.js
// import React from "react"
// import { useEffect, useState } from "react"
// import { useLocation, useNavigate } from "react-router-dom"
// import { toast } from "react-toastify"
// import { isSessionExpiringSoon, logoutUser } from "./session"
// import SessionWarningModal from "./SessionWarningModal"

// const SessionTimeoutHandler = ({ children }) => {
//   const navigate = useNavigate()
//   const location = useLocation()
//   const [isModalOpen, setIsModalOpen] = useState(false)

//   const handleSessionExpiryCheck = () => {
//     // Skip session checks on login and logout pages
//     if (location.pathname === "/login" || location.pathname === "/logout") {
//       return
//     }

//     if (isSessionExpiringSoon()) {
//       setIsModalOpen(true)
//     }
//   }

//   const handleCancel = () => {
//     setIsModalOpen(false)
//     // Ensure session isn't expired immediately
//     setTimeout(() => {
//       if (isSessionExpiringSoon()) {
//         logoutUser()
//         toast.error("Session timeout, login again", {
//           position: "top-right",
//           autoClose: 3000,
//         })
//         navigate("/login")
//       }
//     }, 5 * 60 * 1000) // 5 minutes grace period
//   }

//   useEffect(() => {
//     // Check session status when the component mounts
//     handleSessionExpiryCheck()

//     // Set an interval to check the session status every minute
//     const interval = setInterval(handleSessionExpiryCheck, 60000)

//     // Cleanup interval on component unmount
//     return () => {
//       clearInterval(interval)
//     }
//   }, [location.pathname])

//   return (
//     <>
//       {isModalOpen && (
//         <SessionWarningModal isOpen={isModalOpen} onClose={handleCancel} />
//       )}
//       {children}
//     </>
//   )
// }

// export default SessionTimeoutHandler

// components/SessionTimeoutHandler.js

// components/SessionTimeoutHandler.js
// import React from "react"
// import { useEffect, useState } from "react"
// import { useLocation, useNavigate } from "react-router-dom"
// import { toast } from "react-toastify"
// import {
//   getSessionExpiryTime,
//   isSessionExpiringSoon,
//   logoutUser,
// } from "./session"
// import SessionWarningModal from "./SessionWarningModal"

// const SessionTimeoutHandler = ({ children }) => {
//   const navigate = useNavigate()
//   const location = useLocation()
//   const [isModalOpen, setIsModalOpen] = useState(false)
//   const [isModalDismissed, setIsModalDismissed] = useState(false)

//   const handleSessionExpiryCheck = () => {
//     if (location.pathname === "/login" || location.pathname === "/logout") {
//       return
//     }

//     const expiryTime = getSessionExpiryTime() // Assuming this function returns the session expiry timestamp

//     if (!isModalDismissed && isSessionExpiringSoon()) {
//       setIsModalOpen(true)
//     }

//     if (isModalDismissed && Date.now() >= expiryTime) {
//       logoutUser()
//       toast.error("Session timeout, login again", {
//         position: "top-right",
//         autoClose: 3000,
//       })
//       navigate("/login")
//     }
//   }

//   const handleCancel = () => {
//     setIsModalDismissed(true) // Mark modal as dismissed
//     setIsModalOpen(false)
//   }

//   useEffect(() => {
//     handleSessionExpiryCheck()

//     const interval = setInterval(handleSessionExpiryCheck, 60000) // Check every minute

//     return () => {
//       clearInterval(interval)
//     }
//   }, [location.pathname, isModalDismissed])

//   return (
//     <>
//       {isModalOpen && (
//         <SessionWarningModal isOpen={isModalOpen} onClose={handleCancel} />
//       )}
//       {children}
//     </>
//   )
// }

// export default SessionTimeoutHandler

// components/SessionTimeoutHandler.js
// import React from "react"
// import { useEffect, useState } from "react"
// import { useLocation, useNavigate } from "react-router-dom"
// import { toast } from "react-toastify"
// import {
//   isSessionExpiringSoon,
//   logoutUser,
//   getSessionExpiryTime,
// } from "./session"
// import SessionWarningModal from "./SessionWarningModal"

// const SessionTimeoutHandler = ({ children }) => {
//   const navigate = useNavigate()
//   const location = useLocation()
//   const [isModalOpen, setIsModalOpen] = useState(false)
//   const [isModalDismissed, setIsModalDismissed] = useState(false)

//   const checkSessionStatus = () => {
//     const expiryTime = getSessionExpiryTime() // Assuming this function returns the session expiry timestamp

//     if (!isModalDismissed && isSessionExpiringSoon()) {
//       setIsModalOpen(true)
//     } else if (isModalDismissed && Date.now() >= expiryTime) {
//       logoutUser()
//       toast.error("Session timeout, login again", {
//         position: "top-right",
//         autoClose: 3000,
//       })
//       navigate("/login")
//     }
//   }

//   const handleCancel = () => {
//     setIsModalDismissed(true) // Mark modal as dismissed
//     setIsModalOpen(false)
//   }

//   useEffect(() => {
//     checkSessionStatus()

//     const interval = setInterval(checkSessionStatus, 60000) // Check every minute

//     return () => {
//       clearInterval(interval)
//     }
//   }, [location.pathname, isModalDismissed])

//   return (
//     <>
//       {isModalOpen && (
//         <SessionWarningModal isOpen={isModalOpen} onClose={handleCancel} />
//       )}
//       {children}
//     </>
//   )
// }

// export default SessionTimeoutHandler

// components/SessionTimeoutHandler.js
import React from "react"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import {
  isSessionExpiringSoon,
  logoutUser,
  getSessionExpiryTime,
} from "./session"
import SessionWarningModal from "./SessionWarningModal"
import { setSelectetProject } from "store/Slices/GlobalSlice"
import { useDispatch } from "react-redux"
import { setAllProjectList } from "store/Slices/ProjectsSlice"

const SessionTimeoutHandler = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalDismissed, setIsModalDismissed] = useState(false) // This will keep track if the user cancels the modal

  // This function checks the session status and triggers actions based on time
  const checkSessionStatus = () => {
    const expiryTime = getSessionExpiryTime() // Get the session expiry timestamp

    if (!isModalDismissed && isSessionExpiringSoon()) {
      // Show the modal if the session is expiring soon and the modal is not yet dismissed
      setIsModalOpen(true)
    } else if (Date.now() >= expiryTime) {
      // Logout the user if the session has expired
      logoutUser()
      // dispatch(setSelectetProject(null))

      toast.error("Session timeout, login again", {
        position: "top-right",
        autoClose: 3000,
      })
      localStorage.removeItem("authUser")
      localStorage.removeItem("selectedProject")
      // dispatch(setSelectetProject(null))

      localStorage.clear() // Clear local storage
      navigate("/login")
    }
  }

  const handleCancel = () => {
    setIsModalDismissed(true) // Mark the modal as dismissed when the user clicks "Cancel"
    setIsModalOpen(false) // Close the modal
  }

  useEffect(() => {
    checkSessionStatus() // Initial check for session status

    const interval = setInterval(checkSessionStatus, 60000) // Check every minute

    return () => {
      clearInterval(interval) // Cleanup on unmount
    }
  }, [location.pathname, isModalDismissed])

  return (
    <>
      {isModalOpen && (
        <SessionWarningModal isOpen={isModalOpen} onClose={handleCancel} />
      )}
      {children}
    </>
  )
}

export default SessionTimeoutHandler
