import axios from "axios"
import BASE_URL from "./apiConfig"
import { getToken } from "helpers/helper"

const PostToDownloadAPI = async (url, paramsObj, headersObj = {}) => {
  url = `${BASE_URL}/api/${url}`

  // Retrieve and parse the token
  let tokenid = getToken()

  // Add Authorization header if token exists
  const headers = tokenid
    ? { ...headersObj, Authorization: `Bearer ${tokenid}` }
    : headersObj

  try {
    // Set the responseType to 'blob' to ensure the response is treated as binary data
    const response = await axios.post(url, paramsObj, {
      headers,
      responseType: "blob",
    })
    return response // Return the entire response, not just response.data
  } catch (error) {
    // Handle error appropriately
    return error.response
      ? error.response
      : { error: "An unknown error occurred" }
  }
}

export default { PostToDownloadAPI }
