import React from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap"
import { MdDone } from "react-icons/md"
import { RxCross2 } from "react-icons/rx"
import { useLocation } from "react-router-dom"


const RequirementInfoTable = ({ isOpen, toggle }) => {
  const location = useLocation()
  const locationPath = location.pathname
  const pathname = locationPath.split("/")
  const tableDataForTestScenario = [
    {
      Filed_Name: "Project Name",
      Mandatory: true,
      Description:
        "Enter the project name, case sensitive and mandatory. Ex: TestOps",
    },
    {
      Filed_Name: "Build",
      Mandatory: false,
      Description:
        "Enter which Build ID to be mapped, Optional. Ex: TOP-Build1",
    },
    {
      Filed_Name: "Module",
      Mandatory: false,
      Description:
        "Enter which Module Name to be mapped, Case sensitive and Optional. Ex: Login and Signup",
    },
    {
      Filed_Name: "Requirement Title",
      Mandatory: true,
      Description:
        "Enter the requirement title to be mapped, Case sensitive and mandatory. Ex: Login to testops",
    },
    {
      Filed_Name: "Parent Requirement",
      Mandatory: false,
      Description: "Enter Parent Requirement to be mapped, Optional. Ex:TOP-Build",
    },
    {
      Filed_Name: "Requirement Type",
      Mandatory: false,
      Description: "Enter Requirement Type to be mapped, Case sensitive and Optional.  Ex: Functional",
    },
    {
      Filed_Name: "Requirement Source",
      Mandatory: false,
      Description: "Enter Requirement Source",
    },
    {
      Filed_Name: "Requirement Description",
      Mandatory: true,
      Description: "Enter the Requirement Description",
    },
  ]

  return (
    <Offcanvas isOpen={isOpen} toggle={toggle} centered size="lg" direction="end">
      <OffcanvasHeader toggle={toggle}>Bulk Upload Field Instructions</OffcanvasHeader>
      <OffcanvasBody style={{ height: "380px", overflowY: "auto" }}>
        <Table responsive bordered className="w-100">
          <thead>
            <tr style={{ fontSize: "14px" }}>
              <th>Field Name</th>
              <th>Mandatory</th>
              <th>Description</th>
            </tr>
          </thead>
          {pathname.includes("Requirement") && (
            <tbody>
              {tableDataForTestScenario.map((row, index) => (
                <tr key={index}>
                  <td className="bold">
                    <strong>{row.Filed_Name}</strong>
                  </td>
                  <td>
                    {row.Mandatory ? (
                      <MdDone size={18} style={{ color: "blue" }} />
                    ) : (
                      <RxCross2 size={18} style={{ color: "red" }} />
                    )}
                  </td>
                  <td style={{ fontSize: "13px" }}>{row.Description}</td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      </OffcanvasBody>
      {/* <ModalFooter className="d-flex justify-content-end">
        <Button color="primary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter> */}
    </Offcanvas>
  )
}

export default RequirementInfoTable
