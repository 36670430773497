import React from "react"
import { Offcanvas, OffcanvasBody, OffcanvasHeader, Table } from "reactstrap"

const ViewPreCondition = props => {
  const { isOpen, toggle, data } = props

  return (
    <Offcanvas isOpen={isOpen} direction="end" toggle={toggle}>
      <OffcanvasHeader toggle={toggle}>
        Test Case Pre-Defined Condition
      </OffcanvasHeader>
      <OffcanvasBody>
        <Table bordered>
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 ? (
              data.map(item => (
                <tr key={item.id}>
                  <td>{item.condition_name}</td>
                  <td
                    dangerouslySetInnerHTML={{
                      __html: item.pre_condition_details,
                    }}
                  />
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">No data available</td>
              </tr>
            )}
          </tbody>
        </Table>
      </OffcanvasBody>
    </Offcanvas>
  )
}

export default ViewPreCondition
