import React from "react"
import NoDataImage from "../../assets/images/NoData-image.svg"
const NoProject = () => {
  return (
    <div className="Container-NoData" >
      <div className="NoData-Image">
        <img src={NoDataImage} alt="No-Data" />
      </div>
      <div className="NoData-Statement">
        <p>No Projects found</p> 
      </div>
    </div>
  )
}

export default NoProject
