import React, { useState } from "react"
import { useSelector } from "react-redux"
import {
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap"

const SaveAsTemplate = ({ show, handleClose, handleSave }) => {
  const [description, setDescription] = useState("")
  const [validationErrMsg, setValidationErrMsg] = useState("")
  const { loading } = useSelector(state => state.Loading)

  const handleDescriptionChange = e => {
    setDescription(e.target.value)
  }
  const handleCheckName = name => {
    if (!name.trim()) {
      // Check if name is empty or contains only spaces
      setValidationErrMsg(
        "Template Name is required and should not contain only spaces"
      )
      return
    }

    if (description) {
      handleSave(name.trim()) // Save the trimmed name
      setDescription("") // Clear the description
      setValidationErrMsg("") // Clear any validation error message
    } else {
      setValidationErrMsg("Description is required")
    }
  }

  return (
    <Offcanvas
      isOpen={show}
      toggle={() => {
        handleClose()
        setDescription("")
        setValidationErrMsg("")
      }}
      direction="end"
    >
      <OffcanvasHeader
        toggle={() => {
          handleClose()
          setDescription("")
          setValidationErrMsg("")
        }}
      >
        SAVE AS TEMPLATE
      </OffcanvasHeader>
      <OffcanvasBody>
        <FormGroup>
          <Label for="templateName">
            Template Name <span className="text-danger">*</span>
          </Label>
          <Input
            type="textarea"
            id="templateName"
            value={description}
            onChange={handleDescriptionChange}
            invalid={!!validationErrMsg?.templateName}
          />
          {validationErrMsg && (
            <div className="text-danger">{validationErrMsg}</div>
          )}
        </FormGroup>
        <div className="d-flex justify-content-between">
          <Button
            color="secondary"
            onClick={() => {
              handleClose()
              setDescription("")
              setValidationErrMsg("")
            }}
          >
            CANCEL
          </Button>
          <Button
            color="primary"
            onClick={() => {
              handleCheckName(description)
            }}
            disabled={loading}
          >
            {loading ? <Spinner size="sm" /> : "SAVE"}
          </Button>
        </div>
      </OffcanvasBody>
    </Offcanvas>
  )
}

export default SaveAsTemplate
