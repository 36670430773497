import React, { useEffect, useRef, useCallback } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
   const ref = useRef()
  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()
  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      let parts = pathName.split("/")
      let desiredPath = "/" + parts[1]

      if (desiredPath === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboards")}</span>
              </Link>
              <ul className="sub-menu">
                <li
                  onClick={() => {
                    if (window.innerWidth <= 998) {
                      tToggle()
                    }
                  }}
                >
                  <Link to="/dashboard">{props.t("Global Dashboard")}</Link>
                </li>
                <li
                  onClick={() => {
                    if (window.innerWidth <= 998) {
                      tToggle()
                    }
                  }}
                >
                  <Link to="/ProjectDashboard">
                    {props.t("Project Dashboard")}
                  </Link>
                </li>
              </ul>
            </li>
            <li
              onClick={() => {
                if (window.innerWidth <= 998) {
                  tToggle()
                }
              }}
            >
              <Link to="/ProjectManagement">
                <i className="bx bx-bar-chart-square"></i>
                <span>{props.t("Projects")}</span>
              </Link>
            </li>

            <li
              onClick={() => {
                if (window.innerWidth <= 998) {
                  tToggle()
                }
              }}
            >
              <Link to="/TestManagement">
                <i className="bx bxs-layer"></i>
                <span>{props.t("Test Management")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-select-multiple"></i>
                <span>{props.t("Test Lab")}</span>
              </Link>
              <ul className="sub-menu">
                <li
                  onClick={() => {
                    if (window.innerWidth <= 998) {
                      tToggle()
                    }
                  }}
                >
                  <Link to="/TestPlan">{props.t("Test Plan")}</Link>
                </li>
                <li
                  onClick={() => {
                    if (window.innerWidth <= 998) {
                      tToggle()
                    }
                  }}
                >
                  <Link to="/TestCycle">{props.t("Test Cycle")}</Link>
                </li>
                <li
                  onClick={() => {
                    if (window.innerWidth <= 998) {
                      tToggle()
                    }
                  }}
                >
                  <Link to="/TestAutomation">{props.t("Test Automation")}</Link>
                </li>
              </ul>
            </li>
            <li
              onClick={() => {
                if (window.innerWidth <= 998) {
                  tToggle()
                }
              }}
            >
              <Link to="/DefectManagement">
                <i className="bx bxs-pie-chart-alt-2"></i>
                <span>{props.t("Defect Management")}</span>
              </Link>
            </li>

            <li
              className="d-none d-sm-block"
              onClick={() => {
                if (window.innerWidth <= 998) {
                  tToggle()
                }
              }}
            >
              <Link to="/Reports">
                <i className="bx bx-file"></i>
                <span>{props.t("Reports")}</span>
              </Link>
            </li>

            <li
              onClick={() => {
                if (window.innerWidth <= 998) {
                  tToggle()
                }
              }}
            >
              <Link to="/Settings">
                <i className="bx bx-cog"></i>
                <span>{props.t("Settings")}</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-star-half"></i>
                <span>{props.t("Quick Actions")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/CreateProject">{props.t("New Project")}</Link>
                </li>
                <li>
                  <Link to="/Create-Build">{props.t("New Build")}</Link>
                </li>
                <li>
                  <Link to="/Create-Module">{props.t("New Module")}</Link>
                </li>
                <li>
                  <Link to="/New-Requirement">
                    {props.t("New Requirement")}
                  </Link>
                </li>
                <li>
                  <Link to="/CreateTestScenario">
                    {props.t("New Test Scenario")}
                  </Link>
                </li>
                <li>
                  <Link to="/CreateTestCase">{props.t("New Test Case")}</Link>
                </li>
                <li>
                  <Link to="/CreateDefect">{props.t("New Defect")}</Link>
                </li>
                <li>
                  <Link to="/NewUser">{props.t("New User")}</Link>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
