import Breadcrumb from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap"
import BASE_URL from "services/apiConfig"
import httpInjectorService from "services/http-Injector.Service"

const RequirementView = () => {
  const currentUrl = window.location.href
  const navigate = useNavigate()
  const parts = currentUrl.split("/")
  const id = parts[parts.length - 1]
  const selectedProject = JSON.parse(
    localStorage.getItem("selectedProjectData")
  )
  const breadcrumbItems = [
    { label: selectedProject.project_name, path: "/ProjectManagement" },
    { label: "Requirements", path: `/ProjectManagement/Requirement/${id}` },
    { label: "Requirement view", path: "/" },
  ]
  const data = useSelector(state => state.Requirement?.requirementView)
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickEdit = () => {
    navigate(`/ProjectManagement/Edit-Requirement/${id}`)
  }

  const onClickDelete = () => {
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (data.id) {
      deleteRequirement()
    }
    setDeleteModal(false)
  }

  const deleteRequirement = async () => {
    try {
      const response = await httpInjectorService.deleteRequirement(data.id)
      console.log(response)
      if (response.status) {
        const errorMessage =
          typeof response.message === "string"
            ? response.message
            : response.message.join(", ")
        toast.success(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
        navigate(`/ProjectManagement/Requirement/${id}`)
      }
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      })
    }
  }

  const onClickBack = () => {
    navigate(`/ProjectManagement/Requirement/${id}`)
  }

  return (
    <div className="page-content">
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
        message={"Requirement"}
      />
      <Container fluid>
        <Breadcrumb
          title="Requirement view"
          breadcrumbItems={breadcrumbItems}
        />
        <div className="d-flex justify-content-end mb-2">
          <Button
            type="button"
            color="primary"
            className="btn-label"
            onClick={onClickBack}
          >
            <i className="bx bx-arrow-back label-icon"></i> Go Back
          </Button>
        </div>
        <Card style={{ minHeight: "400px" }}>
          <CardBody>
            <div className="d-flex justify-content-between mb-4">
              <div className="d-flex">
                <div className="avatar-xs me-4">
                  <span
                    className="avatar-title bg-primary"
                    style={{ fontSize: "20px", borderRadius: "8px" }}
                  >
                    {data?.requirement_title?.slice(0, 2).toUpperCase()}
                  </span>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <h5 className="font-size-15 d-flex align-items-center">
                    {/* <span style={{ maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}> */}
                    <span>
                      <Link className="text-dark  mt-2">
                        {data.requirement_title || "-"}
                      </Link>
                    </span>
                    {data.requirement_id && (
                      <span className="ms-2">({data.requirement_id})</span>
                    )}
                  </h5>
                </div>
              </div>
            </div>

            <div className="mb-4">
              <h6 style={{ fontWeight: "550" }}>Requirement Description</h6>
              <p
                className="text-muted mb-4"
                dangerouslySetInnerHTML={{
                  __html: data.requirement_description
                    ? data.requirement_description
                    : "-",
                }}
              ></p>
            </div>
            <div>
              <Row>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Build</h6>
                  <p className="text-muted ">{data.build ? data.build : "-"}</p>
                </Col>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}> Requirement Source</h6>
                  <p className="text-muted ">
                    {data.requirement_source ? data.requirement_source : "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Module</h6>
                  <p className="text-muted ">
                    {data.module_name ? data.module_name : "-"}
                  </p>
                </Col>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Requirement Type</h6>
                  <p className="text-muted ">
                    {data.requirement_type ? data.requirement_type : "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}> Created By</h6>
                  <p className="text-muted ">
                    {data.created_by ? data.created_by : "-"}
                  </p>
                </Col>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Parent Requirement </h6>
                  <p className="text-muted ">
                    {data.parent_requirement ? data.parent_requirement : "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}> Assign To</h6>
                  <p className="text-muted ">
                    {data.assign_to_id_name ? data.assign_to_id_name : "-"}{" "}
                  </p>
                </Col>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Attachment</h6>
                  {data?.attachment && data?.attachment[0] ? (
                    <a
                      href={`${BASE_URL}/${data?.attachment[0]}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {data?.attachment[0]}
                    </a>
                  ) : (
                    "-"
                  )}
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default RequirementView
