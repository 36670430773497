import { toast } from "react-toastify"
import httpInjectorService from "services/http-Injector.Service"
import { setAllActionSuiteList, setEditAutomationSuite, setNextAutomationSuiteId, setSingleAutomationSuite } from "store/Slices/ActionSuiteSlice"
import { setLoading } from "store/Slices/LoadingSlice"



export const AutomationSuiteDetails = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      let queryString = "?"
      Object.entries(bodyoption).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (queryString.charAt(queryString.length - 1) === "&") {
        queryString = queryString.slice(0, -1)
      }
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.AutomationSuiteDetails(
          queryString
        )
        const data = response
        dispatch(setAllActionSuiteList(data))
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }

  
export const DeleteAutomationSuite = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      try {
        const response = await httpInjectorService.DeleteAutomationSuite(bodyoption)
        if (response?.status) {
          toast.success(response.message, {
            position: "top-right",
            id: "Module Deleted successfully",
            autoClose: 3000,
          })
          dispatch(setLoading(false))
        }
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }
  
  
  export const UpdateAutomationSuite = (id ,bodyoption) => {
    return async dispatch => {
      dispatch(setLoading(true))
      const formData = new FormData()
      for (const key in bodyoption) {
        if (
          bodyoption[key] !== undefined &&
          bodyoption[key] !== null 
        ) {
          formData.append(key, bodyoption[key])
        }
      }
      try {
        const response = await httpInjectorService.UpdateAutomationSuite(id , formData)
        console.log(response)
        if (response?.status) {
          toast.success(response.message, {
            position: "top-right",
            autoClose: 3000,
          })
          dispatch(setLoading(false))
        }
        else if (response?.error) {
          toast.error(response.error, {
            position: "top-right",
            autoClose: 3000,
          })
          dispatch(setLoading(false))
        }
        else{
          toast.warning(response.message, {
            position: "top-right", 
            autoClose: 3000,
          })
        }
        return response;
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }
  
  export const GenerateAutomationSuiteId = id => {
    return async dispatch => {
      dispatch(setLoading(true)) 
      try {
        const response = await httpInjectorService.GenerateAutomationSuiteId(id)
        if (response?.status) {
          toast.success(response.message, {
            position: "top-right",
            autoClose: 3000,
          })
          dispatch(setNextAutomationSuiteId(response))
          dispatch(setLoading(false))
        }
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }
  
  
  export const BulkDeleteAutomationSuite = bodyoption => {
    return async dispatch => {
      dispatch(setLoading(true))
      const formData = new FormData()
      for (const key in bodyoption) {
        if (bodyoption[key] !== undefined && bodyoption[key] !== null) {
          formData.append(key, bodyoption[key])
        }
      }
      try {
        const response = await httpInjectorService.BulkDeleteAutomationSuite(
          formData
        )
        if (response?.status) {
          toast.success(response.message, {
            position: "top-right",
            id: "Module Deleted successfully",
            autoClose: 3000,
          })
          dispatch(setLoading(false))
        }
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }
  
  export const CreateAutomationSuites = (bodyoption) => {
    return async (dispatch) => {
      dispatch(setLoading(true))
      const formData = new FormData()
  
      // Add form data if not null
      for (const key in bodyoption) {
        if (
          bodyoption[key] !== undefined &&
          bodyoption[key] !== null &&
          bodyoption[key] !== ""
        ) {
          formData.append(key, bodyoption[key])
        }
      }
  
      try {
        const response = await httpInjectorService.CreateAutomationSuite(formData)
  
        if (response?.status) {
          toast.success(response.message, {
            position: "top-right",
            autoClose: 3000,
          })
          dispatch(setLoading(false))
        } else {
          toast.warning(response.message || response.error || "Something went wrong", {
            position: "top-right",
            autoClose: 3000,
          })
          dispatch(setLoading(false))
        }
        return response
  
      } catch (error) {
        // Handle error message from API
        const errorMessage = error.response?.data?.error || error.message || "An error occurred"
        
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
        
        throw new Error(errorMessage)
      }
    }
  }
  

  export const GetAutomationSuiteByIdAPI = id => {
    return async dispatch => {
      dispatch(setLoading(true))
  
      try {
        const response = await httpInjectorService.GetAutomationSuiteView(id)
        const data = response
        dispatch(setSingleAutomationSuite(data))
        dispatch(setLoading(false))
        return data
      } catch (error) {
        dispatch(setLoading(false))
        throw new Error(error.message)
      }
    }
  }
  
  
  export const setEditAutomationSuitData = data => { 
    return async (dispatch) => {
      dispatch( setEditAutomationSuite(data))
    }
  }