import React, { useEffect, useState, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import DeleteModal from "components/Common/DeleteModal"
import { useSelector, useDispatch } from "react-redux"
import { toast, ToastContainer } from "react-toastify"
import TableContainer from "./TableContainer"
import { deleteRoles, fetchAllRolesList, rolesRetrieveApi } from "store/actions/RolesActions"
import { setEditRoles } from "store/Slices/RolesSlice"
import NoPermission from "components/Common/NoPermission"

const Roles = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const Permission = JSON.parse(localStorage.getItem("authUser"));
  const rolePermission = Permission?.permissions[0]?.menu_item?.Settings?.role

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const [data, setData] = useState([])

  // const allTestScenario = useSelector(
  //   state => state?.TestScenario?.allTestScenario
  // )

  // useEffect(() => {
  //   if (allTestScenario && allTestScenario.Data) {
  //     setData(allTestScenario)
  //   }
  // }, [allTestScenario])


  const handleSingleEditClick = async id => {
    
    if (rolePermission?.edit) {
      if (id?.is_removable === false && Permission?.role_name !== "super_admin") {
        toast.error("This role cannot be edited", {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        try {
          const res = await dispatch(rolesRetrieveApi(id?.id))
          dispatch(setEditRoles({ isEdit: true, data: res }))
          navigate("/Settings/CreateEditRoles")
        } catch (error) {
          console.error("Error fetching data", error)
        }
      }
    } else {
      toast.warn("You don't have permissions to Edit Role", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  }

  const handleCreateClick = id => {
    if (rolePermission?.create) {
      navigate("/Settings/CreateEditRoles")
    }
    else {
      toast.warn("You don't have permissions to Create Role", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  }

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const [deleteModal, setDeleteModal] = useState(false)
  const [isDeleteId, setIsDeleteId] = useState("")

  const onClickDelete = testscenario => {
    if (rolePermission?.delete) {

      if (testscenario?.is_removable === false && Permission?.role_name !== "super_admin") {
        toast.error("This role cannot be removable", {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        setIsDeleteId(testscenario?.id)
        setDeleteModal(true)
      }
    } else {
      toast.warn("You don't have permissions to Delete Role", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  }


  const columns = useMemo(
    () => [
      {
        header: "Roles",
        accessorKey: "role_name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const role_name = cell.getValue()
          return (
            <>
              <Link className="text-dark">{role_name ? <p
                className="font-size-14 mb-1"
                style={{
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  whiteSpace: "normal",
                  maxWidth: "290px", // Set the max-width to control the wrapping
                }}
              >{role_name}</p>: "-"}</Link>
            </>
          )
        },
      },
      {
        header: "Description",
        accessorKey: "role_description",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const role_description = cell.getValue()
          return (
            <>
              <Link className="text-dark">
                {stripHtmlTags(role_description).trim() ? 
                  <p
                    className="font-size-14 mb-1"
                    style={{
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      whiteSpace: "normal",
                      maxWidth: "290px", // Set the max-width to control the wrapping
                    }}
                  >{stripHtmlTags(role_description).trim()}</p> : "-"}
              </Link>
            </>
          )
        },
      },
      {
        header: "Usage - %",
        accessorKey: "user_role_percentage",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const user_role_percentage = cell.getValue()
          return (
            <>
              <Link className="text-dark">
                {user_role_percentage ? user_role_percentage + "%" : "-"}
              </Link>
            </>
          )
        },
      },
      {
        header: "Action",
        cell: cellProps => {
          const role = cellProps.row.original;
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li>
                {role?.role_name === "Admin" ||
                  role.role_name === "admin" ?
                  <span
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    style={{ opacity: 0.5, cursor: 'not-allowed' }}
                  >
                    <i className="mdi mdi-pencil-outline" />
                  </span> :
                  <span
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      const TestScenarioData = cellProps.row.original
                      handleSingleEditClick(TestScenarioData)
                    }}
                    id={`edittooltip-${cellProps.row.original.id}`}
                  >
                    <i className="mdi mdi-pencil-outline" />
                    <UncontrolledTooltip
                      placement="top"
                      target={`edittooltip-${cellProps.row.original.id}`}
                    >
                      Edit
                    </UncontrolledTooltip>
                  </span>
                }
              </li>

              <li>
              {role?.role_name === "Admin" ||
                  role.role_name === "admin" ?
                <span
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  style={{ opacity: 0.5, cursor: 'not-allowed' }}
                >
                  <i className="mdi mdi-delete-outline" />
                </span>:
                <span
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const userData = cellProps.row.original
                    onClickDelete(userData)
                  }}
                  id={`deletetooltip1-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`deletetooltip1-${cellProps.row.original.id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </span>}
              </li>
            </ul>
          )
        },
      },
    ],
    [data]
  )

  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [globalFilter, setGlobalFilter] = useState("")
  const [totalCount, setTotalCount] = useState(null)
  const { testScenarioFilterFrom } = useSelector(state => state.TestScenario)
  const fetchTableData = async (page, size, search) => {
    let bodyoption = {
      page: page + 1,
      page_size: size,
      search: search,
      // project_id: globalProject?.value || selectedProject?.value,
      filter_build_id: testScenarioFilterFrom?.build_id,
      filter_requirement_id: testScenarioFilterFrom?.test_scenario_id,
      filter_module_id: testScenarioFilterFrom?.module_id,
      created_by: testScenarioFilterFrom?.created_by,
    }
    try {
      const data = await dispatch(fetchAllRolesList(bodyoption))
      setData(data.Data)
      setTotalCount(data.TotalCount)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  useEffect(() => {
    fetchTableData(pageIndex, pageSize, globalFilter)
  }, [
    pageIndex,
    pageSize,
    globalFilter,
    globalProject?.value,
    testScenarioFilterFrom,
  ])

  const handleDelete = async () => {
    try {
      await dispatch(deleteRoles(isDeleteId))
      setDeleteModal(false)
      await fetchTableData(pageIndex, pageSize, globalFilter)
      setIsDeleteId("")
    } catch (error) {
      console.error("Error deleting test scenario:", error)
    }
  }

  return (
    <React.Fragment>
      {rolePermission?.view ? <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
        message="Role"
      />
      <Container fluid>
        <Row>
          <Col lg="12">
            <Card style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}>
              <CardBody>
                <div className="table-container">
                  <TableContainer
                    columns={columns}
                    data={data}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    totalCount={totalCount}
                    setPageIndex={setPageIndex}
                    setPageSize={setPageSize}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    isGlobalFilter={true}
                    isPagination={true}
                    SearchPlaceholder="Search..."
                    isCustomPageSize={true}
                    isAddButton={true}
                    isAddDelete={true}
                    isAddEdit={true}
                    isAddFilter={true}
                    handleCreateClick={handleCreateClick}
                    buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal ms-2 mb-2"
                    buttonName="Create"
                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                    theadClass="table-light"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    pagination="pagination"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

        <ToastContainer /></> : <NoPermission />}
    </React.Fragment>
  )
}

export default withRouter(Roles)
