import { toast } from "react-toastify"
import httpInjectorService from "services/http-Injector.Service"
import { setLoading, setReLoading } from "store/Slices/LoadingSlice"
import { setSingleTestCycle, setTestCycleChart, setNextCycleId, setTestCycleList, setExecutePage } from "store/Slices/TestCycleSlice"

export const fetchTestCycleDetailsAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.getTestCycleDetails(
        queryString
      )
      const data = response
      dispatch(setLoading(false))
      dispatch(setTestCycleList(data))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const deleteTestCycleAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.deleteCycle(bodyoption)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Test Cycle Deleted successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const BulkDeleteTestCycleAPI = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (bodyoption[key] !== undefined && bodyoption[key] !== null) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.BulkDeleteCycle(
        formData
      )
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Test Cycle Deleted successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const GetTestCycleByIdAPI = id => {
  return async dispatch => {
    dispatch(setLoading(true))

    try {
      const response = await httpInjectorService.GetTestCycleById(id)
      const data = response
      dispatch(setSingleTestCycle(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const GetTestCycleExecuteByIdAPI = id => {
  return async dispatch => {
    dispatch(setLoading(true))

    try {
      const response = await httpInjectorService.GetTestCycldExecuteById(id)
      const data = response
      dispatch(setExecutePage(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const fetchExecutionDetails = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.getExecutionDetails(
        queryString
      )
      const data = response
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const fetchExecutionChart = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.getExecutionChart(
        queryString
      )
      const data = response
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const BulkExecuteStatus = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.bulkExecute(
        formData,
      )
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Test Plan updated successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
        return response
      }else{
        throw new Error(response.error ||response?.testexcution_status || response?.test_excution_ids[0]);
      }
    } catch (error) {
      dispatch(setLoading(false))
      toast.error(error.message, {
        position: "top-right",
        id: "Test Plan updated successfully",
        autoClose: 3000,
      })
      throw new Error(error.message)
    }
  }
}
export const updateExecute = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.updateExecute(
        formData,
        bodyoption?.id
      )
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Test Scenario updated successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      } else {
        throw new Error(response?.message || response?.errors || response?.attachment[0]);
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const SuitePageChartData = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.TestSuiteChart(queryString)
      const data = response
      dispatch(setTestCycleChart(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const ExecuteScriptMappings = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.executeMappings(queryString)
      const data = response
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const UpdateScriptStatus = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.updateSuiteStatus(
        formData,
        bodyoption?.id
      )
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Test Scenario updated successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      } else {
        throw new Error(response?.message || response?.errors || response?.attachment);
      }
    } catch (error) {
      dispatch(setLoading(false))
      toast.error(error.message, {
        position: "top-right",
        id: "Test Scenario updated successfully",
        autoClose: 3000,
      })
      throw new Error(error.message)
    }
  }
}
export const TriggerWorkFlow = bodyoption => {
  return async dispatch => {
    dispatch(setReLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.trigger(formData)

      if (response?.error) {
        throw new Error(response?.error)
      } else {
        toast.success(response.message, {
          position: "top-right",
          id: "Test Scenario created successfully",
          autoClose: 3000,
        })
        dispatch(setReLoading(false))
        return response
      }
    } catch (error) {
      dispatch(setReLoading(false))

      throw new Error(error.message)
    }
  }
}
export const fetchNextCycleID = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.getNextCycleId(
        queryString
      )
      const data = response
      dispatch(setNextCycleId(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}
export const CreateTestCycle = bodyoptions => {
    
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoptions) {
      if (key !== 'test_case_id' && key !== 'automation_suite_id'&& key!=="assign_to_id" ) {
        if (bodyoptions[key] !== null && bodyoptions[key] !== undefined && !(Array.isArray(bodyoptions[key]))) {
          formData.append(key, bodyoptions[key]);
        }
      }
    }
    if (bodyoptions?.cycle_type === "Manual") {
      if (Array.isArray(bodyoptions?.test_case_id)) {
        formData.append("test_case_id", bodyoptions.test_case_id);
      }
    } else {
      if (Array.isArray(bodyoptions?.automation_suite_id)) {
        formData.append("automation_suite_id", bodyoptions.automation_suite_id);
      }
    }

    if (bodyoptions?.assign_to_id !==null && bodyoptions?.assign_to_id !==undefined  ) {
      formData.append("assign_to_id", bodyoptions.assign_to_id);
    }
    try {
      const response = await httpInjectorService.createCycle(formData)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Test Scenario created successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
        return response;
      } else {
        throw new Error(
          response?.message || response?.attachment || response?.cycle_status[0]
        );
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error?.message)
    }
  }
}
export const EditTestCycle = bodyoptions => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoptions) {
      if (key !== 'test_case_id' && key !== 'automation_suite_id'&& key!=="assign_to_id" ) {
        if (bodyoptions[key] !== null && bodyoptions[key] !== undefined && !(Array.isArray(bodyoptions[key]))) {
          formData.append(key, bodyoptions[key]);
        }
      }
    }

    if (bodyoptions?.cycle_type === "Manual") {
      if (Array.isArray(bodyoptions?.test_case_id)) {
        formData.append("test_case_id", bodyoptions.test_case_id);
      } else {
        formData.append("test_case_id", JSON.stringify([]));
      }
    } else {
      if (Array.isArray(bodyoptions?.automation_suite_id)) {
        formData.append("automation_suite_id", bodyoptions.automation_suite_id);
      } else {
        formData.append("automation_suite_id", JSON.stringify([]));
      }
    }

    if (bodyoptions?.assign_to_id !==null) {
      formData.append("assign_to_id", bodyoptions.assign_to_id);
    } else {        
      formData.append("assign_to_id", JSON.stringify([]));
    }
    
    try {
      const response = await httpInjectorService.editCycle(
        formData,
        bodyoptions?.id
      )
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Test Scenario updated successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      } else {
        throw new Error(
          response?.message || response?.attachment || response?.cycle_status[0]
        );
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error?.message)
    }
  }
}
export const FetchOs = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.osApi(formData)
      dispatch(setLoading(false))
      return response
    } catch (error) {
      dispatch(setLoading(false))
      console.log(error);
      
    }
  }
}
export const FetchWeb = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.webApi(formData)
      dispatch(setLoading(false))
      return response
    } catch (error) {
      dispatch(setLoading(false))
      console.log(error);
      
    }
  }
}