import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Button, Col, Container, Row, Spinner } from "reactstrap"
import TestCaseSummaryResults from "./TestCaseSummaryResults"
import TestIncidentSummaryResults from "./TestIncidentSummaryResults"
import TestResults from "./TestResults"
import TestPlanDefectList from "./TestPlanDefectList"
import ResourceResponsibleForTesting from "./ResourceResponsibleForTesting"
import TestPlanApprovals from "./TestPlanApprovals"
import TestPlanApprovedBy from "./TestPlanApprovedBy"
import Comments from "./Comments"
import {
  getTestPlanDefectListAPI,
  getTestPlanDefectStatusAPI,
  getTestPlanResponsibleResourcesAPI,
  getTestPlanResultAPI,
  testPlanPdfAPI,
  testPlanResultChartAPI,
  testPlanSignoffAPI,
  testPlanSummaryReportsAPI,
} from "store/actions/TestPlanActions"
import { useSelector, useDispatch } from "react-redux"
import { toast } from "react-toastify"
// import jsPDF from "jspdf"
// import html2canvas from "html2canvas"
import TestPlanInfo from "./TestPlanInfo"

const TestPlanSummary = () => {
  const navigate = useNavigate()
  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )
  const userDetails = JSON.parse(localStorage.getItem("authUser"))
  const user_id = userDetails?.user_id

  const [refresh, setRefresh] = useState(1)
  const [testplanDefectStatusData, setTestplanDefectStatusData] = useState([])
  const [testPlanDefectListData, setTestPlanDefectListData] = useState([])
  const [testPlanResultsData, setTestPlanResultsData] = useState([])
  const [testPlanSignOffData, setTestPlanSignOffData] = useState([])
  const [testPlanResultChartData, setTestPlanResultChartData] = useState([])
  const [testPlanApprovalReportsData, setTestPlanApprovalReportsData] =
    useState([])
  const [testPlanResponsibleResourceData, setTestPlanResponsibleResourceData] =
    useState([])
  const [isUserApproverOrRequester, setIsUserApproverOrRequester] = useState({
    isApprover: false,
    isRequester: false,
    status: "",
  })
  const [pendingApprovalId, setPendingApprovalId] = useState(null)
  const [loading, setLoading] = useState(true)

  const location = useLocation()
  const { viewData } = location.state || {}
  const dispatch = useDispatch()
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))
  useEffect(() => {
    const bodyoption = {
      project_id: selectedProject?.value || globalProject?.value,
      testplan_id: viewData?.id,
    }

    const fetchOptions = async () => {
      setLoading(true)
      try {
        const responses = await Promise.allSettled([
          dispatch(getTestPlanDefectStatusAPI(bodyoption)),
          dispatch(getTestPlanDefectListAPI(bodyoption)),
          dispatch(getTestPlanResponsibleResourcesAPI(bodyoption)),
          dispatch(getTestPlanResultAPI(bodyoption)),
          dispatch(testPlanSignoffAPI(bodyoption)),
          dispatch(testPlanSummaryReportsAPI(bodyoption)),
          dispatch(testPlanResultChartAPI(bodyoption)),
        ])

        responses.forEach((response, index) => {
          if (response.status === "fulfilled") {
            switch (index) {
              case 0:
                setTestplanDefectStatusData(response.value)
                break
              case 1:
                setTestPlanDefectListData(response.value)
                break
              case 2:
                setTestPlanResponsibleResourceData(response.value)
                break
              case 3:
                setTestPlanResultsData(response.value)
                break
              case 4:
                setTestPlanSignOffData(response.value)
                break
              case 5:
                const approvalData = response.value

                if (approvalData && approvalData.length > 0) {
                  let isApprover = false
                  let isRequester = false

                  const matchingPendingApproval = approvalData.find(
                    approval =>
                      approval.status === "SignOff Pending" &&
                      String(approval.approvals_by) === String(user_id)
                  )

                  if (matchingPendingApproval) {
                    setPendingApprovalId(matchingPendingApproval.id)
                  } else {
                    setPendingApprovalId(null)
                  }

                  approvalData.forEach(approval => {
                    const currentApprover = String(approval.approvals_by)
                    const currentRequester = String(approval.requester)

                    if (currentApprover === String(user_id)) {
                      isApprover = true
                    }

                    if (currentRequester === String(user_id)) {
                      isRequester = true
                    }
                  })

                  setIsUserApproverOrRequester(prevState => ({
                    ...prevState,
                    isApprover,
                    isRequester,
                  }))
                }

                setTestPlanApprovalReportsData(approvalData)
                break
              case 6:
                setTestPlanResultChartData(response.value)
                break
              default:
                break
            }
          } else {
            toast.error(
              `Failed to fetch data: ${
                response.reason.message || "Unknown error"
              }`
            )
          }
        })
      } catch (error) {
        console.error("Failed to fetch options", error)
        toast.error("Failed to fetch options.")
      } finally {
        setLoading(false)
      }
    }

    fetchOptions()
  }, [globalProject, dispatch, refresh, viewData, user_id])

  // // PDF download logic
  // const downloadPDF = async () => {
  //   const doc = new jsPDF("p", "mm", "a4")
  //   const elements = document.getElementsByClassName("pdf-content")

  //   for (let i = 0; i < elements.length; i++) {
  //     const element = elements[i]
  //     const canvas = await html2canvas(element)
  //     const imgData = canvas.toDataURL("image/png")
  //     const imgWidth = 190
  //     const pageHeight = 285
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width
  //     let position = 10

  //     doc.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight)
  //     if (i < elements.length - 1) doc.addPage() // add a new page if not last element
  //   }
  //   doc.save(`TestPlanSummary_${testPlanID}.pdf`)
  // }

  const downloadPDF = async () => {
    const bodyoption = {
      project_id: globalProject?.value || selectedProject?.value,
      testplan_id: viewData?.id,
    }
    await dispatch(testPlanPdfAPI(bodyoption))
  }

  if (loading) {
    return (
      <div className="text-center">
        <Spinner /> Loading...
      </div>
    )
  }

  const isRejected = testPlanApprovalReportsData.some(
    approval =>
      approval.status === "SignOff Rejected" && approval.requester === user_id
  )

  const showTestPlanApprovedBy =
    testPlanApprovalReportsData.length === 0 ||
    (isRejected &&
      !testPlanApprovalReportsData.some(
        approval => approval.status === "SignOff Pending"
      ))

  return (
    <Container fluid>
      <div className="mb-3 d-flex flex-column flex-md-row justify-content-between align-items-center">
        <div className="mb-2 mb-md-0">
          <div
            className="btn-group"
            role="group"
            style={{ whiteSpace: "nowrap" }}
          >
            <input
              type="radio"
              className="btn-check"
              id="btnradio1"
              name="btnradio"
              autoComplete="off"
              checked
            />
            <label className="btn btn-outline-primary" htmlFor="btnradio1">
              Test Summary Report
            </label>
          </div>
        </div>
        <div className="d-flex">
          <Button
            type="button"
            color="primary"
            className="btn-label me-1"
            onClick={() => navigate(-1)}
          >
            <i className="bx bx-arrow-back label-icon"></i> Go Back
          </Button>

          <Button
            type="button"
            color="secondary hide-content"
            onClick={downloadPDF}
            className="ms-md-2"
          >
            Download Summary
          </Button>
        </div>
      </div>

      <TestPlanInfo viewData={viewData} />
      <Row>
        <Col>
          <div className="table-container">
            <div className="pdf-content">
              <TestCaseSummaryResults
                testPlanSignOffData={testPlanSignOffData}
              />
              <TestIncidentSummaryResults
                testplanDefectStatusData={testplanDefectStatusData}
              />
              <TestResults
                testPlanResultsData={testPlanResultsData}
                testPlanResultChartData={testPlanResultChartData}
              />
              <TestPlanDefectList
                testPlanDefectListData={testPlanDefectListData}
              />
              <ResourceResponsibleForTesting
                testPlanResponsibleResourceData={
                  testPlanResponsibleResourceData
                }
              />
              <TestPlanApprovals
                testPlanApprovalReportsData={testPlanApprovalReportsData}
              />
            </div>
            {showTestPlanApprovedBy && (
              <TestPlanApprovedBy
                testPlanID={viewData?.id}
                setRefresh={setRefresh}
                refresh={refresh}
              />
            )}
            {isUserApproverOrRequester.isApprover && pendingApprovalId && (
              <Comments
                testPlanID={viewData?.id}
                refresh={refresh}
                setRefresh={setRefresh}
                pendingApprovalId={pendingApprovalId}
              />
            )}
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default TestPlanSummary
