import { createSlice } from "@reduxjs/toolkit"

const TestDataSlice = createSlice({
  name: "TestData",
  initialState: {
    singleTestData: null,
    editTestData: {
      isEdit: false,
      data: null,
      TestData:[],
    },
  },

  reducers: {
    setSingleTestData: (state, action) => ({
      ...state,
      singleTestData: action.payload,
    }),

    setEditTestData: (state, action) => ({
      ...state,
      editTestData: action.payload,
    }),
    setTestData: (state, action) => ({
      ...state,
      TestData: action.payload,
    }),
  },
})

export const { setSingleTestData, setEditTestData ,setTestData} = TestDataSlice.actions

export default TestDataSlice.reducer
