import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector } from "react-redux";

const DefectAging = ({ data }) => {
  const layoutModeType = useSelector((state) => state.Layout.layoutModeType); // Get layout mode type from Redux

  // Modify categories to replace "< 30" with "> 30"
  const categories = Object.keys(data).map((category) =>
    /<\s*30\s*Days/.test(category) ? "> 30 Days" : category
  );
  
  
  const values = Object.values(data);

  const isDarkMode = layoutModeType === "dark"; // Check if dark mode is enabled

  const options = {
    chart: {
      type: "line",
      backgroundColor: isDarkMode ? "#2a3042" : "white", // Set background color based on mode
      events: {
        render() {
          const chart = this;
          const labelText = "";

          if (!chart.customLabel) {
            const labelWidth = chart.renderer
              .text(labelText, 0, 0)
              .css({
                fontSize: "12px",
              })
              .getBBox().width;

            const labelX =
              chart.plotLeft + chart.plotWidth / 2 - labelWidth / 2; // Horizontal centering
            const labelY =
              chart.plotTop + chart.plotHeight + chart.marginBottom - 20; // Adjust vertical position as needed

            chart.customLabel = chart.renderer
              .text(labelText, labelX, labelY)
              .attr({
                zIndex: 5,
              })
              .css({
                color: isDarkMode ? "#ffffff" : "#666666", // Dynamic label color
                fontSize: "12px",
              })
              .add();
          } else {
            // Update the position of the custom label if it already exists
            const labelX =
              chart.plotLeft +
              chart.plotWidth / 2 - chart.customLabel.getBBox().width / 2; // Horizontal centering
            const labelY =
              chart.plotTop + chart.plotHeight + chart.marginBottom - 20; // Adjust vertical position as needed

            chart.customLabel.attr({
              x: labelX,
              y: labelY,
            });
          }
        },
      },
    },
    title: {
      text: "",
      align: "left",
      style: {
        color: isDarkMode ? "#ffffff" : "#000000", // Dynamic title color
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false, // Disable the legend to hide the "Defects Aging" label
    },
    xAxis: {
      categories: categories,
      title: {
        text: "Days Range",
        style: {
          color: isDarkMode ? "#ffffff" : "#000000", // X-axis title color
        },
      },
      labels: {
        style: {
          color: isDarkMode ? "#ffffff" : "#000000", // X-axis labels color
        },
      },
      lineColor: isDarkMode ? "#ffffff" : "#000000", // X-axis line color
      tickColor: isDarkMode ? "#ffffff" : "#000000", // Tick marks color
    },
    tooltip: {
      backgroundColor: isDarkMode ? "#000000" : "#ffffff",
      style: {
        color: isDarkMode ? "#f0f0f0" : "#000000",
      },
    },
    yAxis: {
      title: {
        text: "No. of Defects (Open)",
        style: {
          color: isDarkMode ? "#ffffff" : "#000000", // Y-axis title color
        },
      },
      labels: {
        style: {
          color: isDarkMode ? "#ffffff" : "#000000", // Y-axis labels color
        },
      },
      lineColor: isDarkMode ? "#ffffff" : "#000000", // Y-axis line color
      tickColor: isDarkMode ? "#ffffff" : "#000000", // Tick marks color
      allowDecimals: false,
    },
    series: [
      {
        name: "Defects Aging",
        data: values,
        color: "skyblue", 
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default DefectAging;
