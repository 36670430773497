import axios from "axios"
import BASE_URL from "./apiConfig"
import { getToken } from "helpers/helper"

const deleteDataFromAPI = async (url, data = {}, headersObj = {}) => {
  url = `${BASE_URL}/api/${url}`

  // Retrieve and parse the token
  console.log(getToken());
  
  let tokenid = getToken()

  // Add Authorization header if token exists
  const headers = tokenid
    ? { ...headersObj, Authorization: `Bearer ${tokenid}` }
    : headersObj

  try {
    const response = await axios.delete(url, {
      headers,
      data, // Include data here
    })
    return response.data
  } catch (error) {
    // Handle error appropriately
    return error.response
      ? error.response.data
      : { error: "An unknown error occurred" }
  }
}

export default { deleteDataFromAPI }
