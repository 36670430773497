import { createSlice } from "@reduxjs/toolkit"

const ProjectSlice = createSlice({
  name: "Global",
  initialState: {
    selectetProject: "",
    projectListForSelect: [],
  },

  reducers: {
    setSelectetProject: (state, action) => ({
      ...state,
      selectetProject: action.payload,
    }),
    setProjectListForSelect: (state, action) => ({
      ...state,
      projectListForSelect: action.payload,
    }),
  },
})

export const { setSelectetProject, setProjectListForSelect } =
  ProjectSlice.actions

export default ProjectSlice.reducer
