// src/apiConfig.js
const hostname = window.location.hostname

let BASE_URL

if (
  hostname === "localhost" ||
  hostname === "devrd.frothtestops.com" ||
  hostname === "newdevrd.frothtestops.com" ||
  hostname === "uatrvrd.frothtestops.com"
) {
  BASE_URL = process.env.REACT_APP_DEV_API_URL
} else if (hostname === "rd.frothtestops.com") {
  BASE_URL = process.env.REACT_APP_PROD_API_URL
} else if (hostname === "ytlc.frothtestops.com") {
  BASE_URL = process.env.REACT_APP_YTLC_API_URL
} else if (hostname === "ns.frothtestops.com") {
  BASE_URL = process.env.REACT_APP_NS_API_URL
} else {
  BASE_URL = process.env.REACT_APP_PROD_API_URL // Default to production
}

export default BASE_URL
