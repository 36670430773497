import React, { useEffect, useState } from "react";
import AceEditor from "react-ace";
import { useDispatch } from "react-redux";
import styles from "./editor.module.scss";

// AceEditor related imports
import "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/theme-monokai"; // Correct theme
import "ace-builds/src-noconflict/mode-javascript"; // Correct mode
import beautify from "js-beautify"; 
import { setScriptCode } from "store/Slices/AutomationScriptSlice";

let defaultCode = {
  defaultcode: `
const Util = require('../config/dataHandler.js');

describe('<enter scenario name>', () => {

    it('<Enter test case details>', async () => {

        //Please paste the recorded script over here

    });
});
`,
};

const Editor = props => {
  const dispatch = useDispatch();
  const { editmode, data } = props;

  const [code, setCode] = useState(!editmode ? defaultCode?.defaultcode : data);

  const handleFormatCode = () => {
    const formattedCode = beautify(code, {
      indent_size: 2,
      space_in_empty_paren: true,
      end_with_newline: true,
      indent_inner_html: true,
      preserve_newlines: true, // Preserve newlines, including those within comments
      jslint_happy: true, // Ensure comments are properly formatted
      wrap_line_length: 0,
    });
    setCode(formattedCode);
  };

  useEffect(() => {
    dispatch(setScriptCode(code));
  }, [code]);

  return (
    <div className={styles.EditorContainer}>
      <AceEditor
        mode="javascript"
        theme="monokai" // Use monokai theme
        onChange={setCode}
        value={code}
        name="editor"
        width="100%"
        height="100%"
        editorProps={{ $blockScrolling: true }}
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
          useWorker: false,
          printMargin: false,
        }}
      />
      <div className={styles.EditorActionButtons}>
        <div className={styles.Formate} onClick={handleFormatCode}>
          Format
        </div>
      </div>
    </div>
  );
};

export default Editor;
