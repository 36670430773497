import { getStatusBackGroundColor } from "helpers/helper"
import React from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import {
  Alert,
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
} from "reactstrap"
import { testPlanPdfAPI } from "store/actions/TestPlanActions"

const TestPlanSummaryHistory = ({ viewData, summaryHistoryData }) => {
  const dispatch = useDispatch()
  const isDarkMode = useSelector(state => state.Layout.layoutModeType)
  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))

  const downloadPDF = async () => {
    const bodyoption = {
      project_id: globalProject?.value || selectedProject?.value,
      testplan_id: viewData.id,
    }
    await dispatch(testPlanPdfAPI(bodyoption))
  }
  const cardHeaderStyle = {
    backgroundColor: isDarkMode === "dark" ? "#32394e" : "#fff",
    // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    color: isDarkMode === "dark" ? "#fff" : "#000",
  }

  const tableHeaderStyle = {
    position: "sticky",
    top: 0,
    backgroundColor: isDarkMode === "dark" ? "#444" : "#fff",
    color: isDarkMode === "dark" ? "#fff" : "#000",
    zIndex: 2,
    borderCollapse: "collapse",
  }

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader
            className="d-flex justify-content-between align-items-center"
            style={cardHeaderStyle}
          >
            <h5 className="mb-0">Test Plan Summary History</h5>
            <div id="resultDownload" className="cursor-pointer"></div>
          </CardHeader>

          <CardBody>
            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
              {summaryHistoryData?.length > 0 ? (
                <Table hover striped>
                  <thead style={tableHeaderStyle}>
                    <tr>
                      <th>Requesters</th>
                      <th>Approvals by</th>
                      <th>Designation</th>
                      <th>Date & Updated Time</th>
                      <th>Status</th>
                      <th>Attachment</th>
                    </tr>
                  </thead>
                  <tbody>
                    {summaryHistoryData &&
                      summaryHistoryData?.map(item => (
                        <tr key={item?.id}>
                          <td>{item?.requester_details?.name}</td>
                          <td>{item?.approvals_by_details?.name}</td>
                          <td>{item?.designation.role_name}</td>
                          <td>
                            {item?.updated_at
                              ? new Date(item.updated_at).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              }) +
                              " " +
                              new Date(item.updated_at).toLocaleTimeString("en-GB", {
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                hour12: true,
                              })
                              : "-"}
                          </td>
                          <td>
                            <Badge
                              color={getStatusBackGroundColor(item?.status)}
                            >
                              {item?.status}
                            </Badge>
                          </td>
                          <td onClick={downloadPDF}>
                            <a href="#">View Attachement</a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              ) : (
                <div className="text-center py-3">
                  <b>No records found.</b>
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default TestPlanSummaryHistory
