import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Col, Card, CardBody, Row } from "reactstrap";
import './Login.scss';
// img  
import DountChart from "components/Charts/Chart";
//swiper 
import "../../../node_modules/swiper/swiper.scss";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const CarouselPage = () => {
  const layoutModeType = useSelector(state => state.Layout.layoutModeType)
  const priorityTotalCount = 1008;
  const sevierityTotalCount = 1500;
  const ReportedTotalCount = 1278;
  const prioritydata = [200, 90, 120];
  const sevieritydata = [50, 110, 220];
  const Reporteddata = [300, 60, 120];

  const [textColor, setTextColor] = useState("black");

  useEffect(() => {
    if (layoutModeType === 'dark') {
      setTextColor("white");
    } else if (layoutModeType === 'light') {
      setTextColor("black");
    }
  }, [layoutModeType]);

  const onClickSeeAll = () => {
    toast.warn("Please log in to view the data.", {
      position: "top-right",
      autoClose: 3000,
    });
  }

  const customRenderIndicator = (onClickHandler, isSelected, index, label) => {
    return (
      <li
        className={`dot ${isSelected ? 'selected' : ''}`}
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        value={index}
        key={index}
        role="button"
        tabIndex={0}
        aria-label={`${label} ${index + 1}`}
      />
    );
  };

  return (
    <React.Fragment>
        <div className="p-2" style={{
          backgroundColor: layoutModeType !== "dark" ? "rgb(237, 240, 255)" : "rgb(72, 76, 94)"
        }}>
          <div className="w-100">
            <div className="d-flex h-100 flex-column">
              <div className="mt-auto">
                <div className="row justify-content-center">
                  <div className="col-lg-7">
                    <div className="text-center">
                      <div dir="ltr"> 
                        <Carousel 
                          className="owl-carousel owl-theme auth-review-carousel slider_css" 
                          id="auth-review-carousel"
                          showThumbs={false}
                          autoPlay={true} 
                          interval={2000} 
                          infiniteLoop={true} 
                          showStatus={false} 
                          renderIndicator={customRenderIndicator} // Custom indicator
                        >
                          <div className="carousel-item active" data-bs-interval="3000">
                            <div className="item centered-container">
                              <Col xl={10}>
                                <Card className="centered-content" style={{ borderRadius: "15px" }}>
                                  <CardBody>
                                    <div className="d-flex justify-content-between align-items-center" style={{ borderBottom: "2px solid #dfdfdf", paddingBottom: "10px" }}>
                                      <i className="bx bx-time text-muted" style={{ fontSize: "32px" }}></i>
                                      <p style={{ paddingTop: "7px", fontSize: "15px" }}>Defect Reported by Priority</p>
                                      <button type="button" style={{ width: "68px" }} className="btn btn-outline-dark " onClick={onClickSeeAll}>
                                        <span>See All</span>
                                      </button>
                                    </div>
                                    <DountChart
                                      dataColors='["#007bff", "#28a745", "#fd7e14"]'
                                      textColor={textColor}
                                      TotalCount={priorityTotalCount}
                                      chartData={prioritydata}
                                    />
                                    <Row className="justify-content-center">
                                      <Col sm={4}>
                                        <div className="text-center">
                                          <span className="dots dot-primary"></span>
                                          <span className=" text-truncate">High</span>
                                        </div>
                                      </Col>
                                      <Col sm={4}>
                                        <div className="text-center">
                                          <span className="dots dot-success"></span>
                                          <span className=" text-truncate">Medium</span>
                                        </div>
                                      </Col>
                                      <Col sm={4}>
                                        <div className="text-center">
                                          <span className="dots dot-warning"></span>
                                          <span className=" text-truncate">Low</span>
                                        </div>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Col>
                              <div className="">
                                <h4>Your Time Your Way</h4>
                                <h6 className="text-muted">Track, request, relax. It’s that simple.</h6>
                              </div>
                            </div>
                          </div> 
                          <div className="carousel-item active" data-bs-interval="3000">
                            <div className="item centered-container">
                              <Col xl={10}>
                                <Card className="centered-content" style={{ borderRadius: "15px" }}>
                                  <CardBody>
                                    <div className="d-flex justify-content-between align-items-center" style={{ borderBottom: "2px solid #dfdfdf", paddingBottom: "10px" }}>
                                      <i className="bx bx-time text-muted" style={{ fontSize: "32px" }}></i>
                                      <h5 style={{ paddingTop: "7px", fontSize: "15px" }}>Defect Reported by Severity</h5>
                                      <button type="button" style={{ width: "68px" }} className="btn btn-outline-dark " onClick={onClickSeeAll}>
                                        <span>See All</span>
                                      </button>
                                    </div>
                                    <DountChart
                                      dataColors='["#007bff", "#28a745", "#fd7e14"]'
                                      textColor={textColor}
                                      TotalCount={sevierityTotalCount} 
                                      chartData={sevieritydata}
                                    />
                                    <Row className="justify-content-center">
                                      <Col sm={4}>
                                        <div className="text-center">
                                          <span className="dots dot-primary"></span>
                                          <span className=" text-truncate">Major</span>
                                        </div>
                                      </Col>
                                      <Col sm={4}>
                                        <div className="text-center">
                                          <span className="dots dot-success"></span>
                                          <span className=" text-truncate">Minor</span>
                                        </div>
                                      </Col>
                                      <Col sm={4}>
                                        <div className="text-center">
                                          <span className="dots dot-warning"></span>
                                          <span className=" text-truncate">Blocker</span>
                                        </div>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Col>
                              <div>
                                <h4>Stay in control of your time Off</h4>
                                <h6 className="text-muted">Track your progress software tester and manage requests with the Project
                                  ensuring a stress-free experience.</h6>
                              </div>
                            </div>
                          </div> 
                          <div className="carousel-item active" data-bs-interval="3000">
                            <div className="item centered-container">
                              <Col xl={10}>
                                <Card className="centered-content" style={{ borderRadius: "15px" }}>
                                  <CardBody>
                                    <div className="d-flex justify-content-between align-items-center" style={{ borderBottom: "2px solid #dfdfdf", paddingBottom: "10px" }}>
                                      <i className="bx bx-time text-muted" style={{ fontSize: "32px" }}></i>
                                      <h5 style={{ paddingTop: "7px", fontSize: "15px" }}>Overview Defect Reported</h5>
                                      <button type="button" style={{ width: "68px" }} className="btn btn-outline-dark " onClick={onClickSeeAll}>
                                        <span>See All</span>
                                      </button>
                                    </div>
                                    <DountChart
                                      dataColors='["#007bff", "#28a745", "#fd7e14"]'
                                      textColor={textColor}
                                      TotalCount={ReportedTotalCount}
                                      chartData={Reporteddata}
                                    />
                                    <Row className="justify-content-center">
                                      <Col sm={4}>
                                        <div className="text-center">
                                          <span className="dots dot-primary"></span>
                                          <span className=" text-truncate">Open</span>
                                        </div>
                                      </Col>
                                      <Col sm={4}>
                                        <div className="text-center">
                                          <span className="dots dot-success"></span>
                                          <span className=" text-truncate">To Do</span>
                                        </div>
                                      </Col>
                                      <Col sm={4}>
                                        <div className="text-center">
                                          <span className="dots dot-warning"></span>
                                          <span className=" text-truncate">Closed</span>
                                        </div>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Col>
                              <div>
                                <h4>Stay in control of your time Off</h4>
                                <h6 className="text-muted">Track your progress software tester and manage requests with the Project
                                  ensuring a stress-free experience.</h6>
                              </div>
                            </div>
                          </div> 
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </React.Fragment>
  );
};

export default CarouselPage;
