import React, { useEffect, useMemo, useState } from "react"
import { Modal, UncontrolledTooltip } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import TableContainer from "components/Common/TableContainer"
import DeleteModal from "components/Common/DeleteModal"
import { toast } from "react-toastify"
import moment from "moment"
import {
  fetchBuildDetails,
  BulkDeleteBuild,
  deleteBuildAPI,
  setEditBuildData,
} from "store/actions/BuildsActions"
import NoPermission from "components/Common/NoPermission"

const ProjectBuild = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [deleteModal, setDeleteModal] = useState(false)
  const [bulkdeleteModal, setBulkDeleteModal] = useState(false)
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [totalCount, setTotalCount] = useState(null)
  const [globalFilter, setGlobalFilter] = useState("")
  const [BuildData, setBuildData] = useState("")
  const currentUrl = window.location.href
  const parts = currentUrl.split("/")
  const id = parts[parts.length - 1]
  const [selectedRows, setSelectedRows] = useState([])
  const [buildID, setBuildId] = useState()

  const permission = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission =
    permission?.permissions?.[0]?.menu_item?.ProjectManagement

  useEffect(() => {
    fetchBuildData(pageIndex, pageSize, globalFilter)
  }, [pageIndex, pageSize, globalFilter])

  const fetchBuildData = async (page, size, search) => {
    let bodyoption = {
      page: page + 1,
      page_size: size,
      project_id: id,
      search: search,
      _timestamp: new Date().getTime(),
    }
    try {
      const data = await dispatch(fetchBuildDetails(bodyoption))
      setBuildData(data.Data)
      setTotalCount(data.TotalCount)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  // Handle checkbox change for individual rows
  const handleCheckboxChange = build_id => {
    console.log(build_id)
    setSelectedRows(prevValues =>
      prevValues.includes(build_id)
        ? prevValues.filter(val => val !== build_id)
        : [...prevValues, build_id]
    )
  }

  // Handle select/deselect all checkboxes
  const handleSelectAll = e => {
    const isChecked = e.target.checked
    if (isChecked) {
      const newSelectedRows = BuildData.map(item => item.build_id)
      setSelectedRows(newSelectedRows)
    } else {
      setSelectedRows([])
    }
  }

  const onClickEdit = build => {
    if (rolePermission?.Build?.edit) {
      dispatch(setEditBuildData(build))
      navigate(`/ProjectManagement/Edit-Build/${id}`)
    } else {
      toast.warn("You don't have the permission to edit build", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const onClickDelete = users => {
    if (rolePermission?.Build?.delete) {
      setBuildId(users.id)
      setDeleteModal(true)
    } else {
      toast.warn("You don't have the permission to delete build", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const handleDeleteUser = () => {
    dispatch(deleteBuildAPI(buildID))
      .then(() => {
        fetchBuildData(pageIndex, pageSize, globalFilter)
      })
      .catch(error => {
        console.error("Failed to delete build:", error)
      })
    setDeleteModal(false)
  }

  const handleBulkDelete = async () => {
    const bodyoption = {
      project_id: id,
      build_ids: selectedRows,
    }
    try {
      dispatch(BulkDeleteBuild(bodyoption))
      setSelectedRows([])
      setBulkDeleteModal(false)
      setTimeout(() => {
      fetchBuildData(pageIndex, pageSize, globalFilter)
    }, 1000);
    } catch (error) {
      console.error("Error deleting Requirement:", error)
    }
  }

  const handleDeleteClick = async () => {
    if (rolePermission?.Build?.delete) {
      try {
        if (selectedRows?.length > 0) {
          setBulkDeleteModal(true)
        } else {
          toast.warn(
            "Please select a project build before attempting to delete.",
            {
              position: "top-right",
              id: "selectBeforeDelete",
              autoClose: 3000,
            }
          )
        }
      } catch (error) {
        console.error("Error deleting project build:", error)
      }
    } else {
      toast.warn("You don't have the permission to delete build", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const handleAddClick = () => {
    if (rolePermission?.Build?.create) {
      navigate(`/ProjectManagement/Create-Build/${id}`)
    } else {
      toast.warn("You don't have the permission to create build", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const columns = useMemo(
    () => [
      {
        header: (
          <input
            type="checkbox"
            onChange={handleSelectAll}
            style={{ cursor: "pointer" }}
            title="Select All"
            checked={
              selectedRows.length === BuildData?.length && BuildData?.length > 0
            }
          />
        ),
        accessorKey: "checkbox",
        cell: cellProps => (
          <input
            type="checkbox"
            style={{ cursor: "pointer" }}
            checked={selectedRows.includes(cellProps.row.original.build_id)}
            onChange={() => {
              const jobData = cellProps.row.original.build_id
              handleCheckboxChange(jobData)
            }}
            onClick={() => {
              const jobData = cellProps.row.original.build_id
              handleCheckboxChange(jobData)
            }}
          />
        ),
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Build Id",
        accessorKey: "build_id",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Created Date",
        accessorKey: "created_at",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ getValue }) => {
          const value = getValue()
          return value ? moment(value).format("DD-MM-YYYY") : "-"
        },
      },
      {
        header: "Start Date",
        accessorKey: "start_date",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ getValue }) => {
          const value = getValue()
          return value ? moment(value).format("DD-MM-YYYY") : "-"
        },
      },
      {
        header: "End Date",
        accessorKey: "end_date",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ getValue }) => {
          const value = getValue()
          return value ? moment(value).format("DD-MM-YYYY") : "-"
        },
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => (
          <ul className="list-unstyled hstack gap-1 mb-0">
            <li>
              <span
                className="btn btn-sm btn-soft-info"
                onClick={() => {
                  const jobData = cellProps.row.original
                  onClickEdit(jobData)
                }}
                id={"edittooltip"}
              >
                <i className="mdi mdi-pencil-outline" />
                <UncontrolledTooltip placement="top" target={"edittooltip"}>
                  Edit
                </UncontrolledTooltip>
              </span>
            </li>

            <li>
              <Link
                to="#"
                className="btn btn-sm btn-soft-danger"
                onClick={() => {
                  const jobData = cellProps.row.original
                  onClickDelete(jobData)
                }}
                id={"deletetooltip"}
              >
                <i className="mdi mdi-delete-outline" />
                <UncontrolledTooltip placement="top" target={"deletetooltip"}>
                  Delete
                </UncontrolledTooltip>
              </Link>
            </li>
          </ul>
        ),
      },
    ],
    [selectedRows, BuildData]
  )

  const onCancelBulkDelete = () => {
    setSelectedRows([])
    setBulkDeleteModal(false)
  }

  return (
    <React.Fragment>
      {rolePermission?.Build?.view ? <>
      <TableContainer
        columns={columns}
        data={BuildData || []}
        pageIndex={pageIndex}
        pageSize={pageSize}
        totalCount={totalCount}
        setPageIndex={setPageIndex}
        setPageSize={setPageSize}
        isCustomPageSize={true}
        isGlobalFilter={true}
        isJobListGlobalFilter={false}
        globalFilter={globalFilter}
        isPagination={true}
        isAddDelete={true}
        isAddEdit={false}
        isAddFilter={false}
        isAddButton={true}
        setGlobalFilter={setGlobalFilter}
        handleDeleteClick={handleDeleteClick}
        handleAddClick={handleAddClick}
        SearchPlaceholder="Search..."
        tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
        theadClass="table-light"
        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
        pagination="pagination"
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
        message={"Build"}
      />

      <DeleteModal
        show={bulkdeleteModal}
        onDeleteClick={handleBulkDelete}
        onCloseClick={onCancelBulkDelete}
        message={"Builds"}
        /></> : <NoPermission />}
    </React.Fragment>
  )
}

export default ProjectBuild
