import React from "react"
import { Alert, Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap"
import { LuLink } from "react-icons/lu";


const ViewBasicMapProject = props => {
    const { isFilterFormOpen, toggle, product } = props
   

    return (
        <Offcanvas isOpen={isFilterFormOpen} direction="end" toggle={toggle} style={{ width: '42%' }}>
            <OffcanvasHeader toggle={toggle}>Basic Integration Mappings</OffcanvasHeader>
            <OffcanvasBody>
                {product === "Jira" &&
                <form>
                     <Alert color="primary">Please ensure that Issue Type "Bug" is present in Jira</Alert>
                    <div className="mb-2 col-12 d-flex">
                        <div className="col-5">
                            <Label className="form-label">
                                TestOps
                            </Label>
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Defect Summary"
                            />
                        </div>
                        <div className="col-2 mt-4 text-center">
                            <Label className="form-label">
                            </Label>
                            <LuLink />
                        </div>

                        <div className="col-5">
                            <Label className="form-label">
                                Jira
                            </Label>
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Summary"
                            />
                        </div>
                    </div>
                    <div className="mb-2 col-12 d-flex">
                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Steps to reproduce"
                            />
                        </div>
                        <div className="col-2 mt-2 text-center">
                            <LuLink />
                        </div>

                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Description"
                            />
                        </div>
                    </div>
                    <div className="mb-2 col-12 d-flex">
                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="OS, Browser, Devices"
                            />
                        </div>
                        <div className="col-2 mt-2 text-center">
                            <LuLink />
                        </div>

                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Environment"
                            />
                        </div>
                    </div>
                    <div className="mb-2 col-12 d-flex">
                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Priority"
                            />
                        </div>
                        <div className="col-2 mt-2 text-center">
                            <LuLink />
                        </div>

                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Priority"
                            />
                        </div>
                    </div>
                    <div className="mb-2 col-12 d-flex">
                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Issue Type"
                            />
                        </div>
                        <div className="col-2 mt-2 text-center">
                            <LuLink />
                        </div>

                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Bug"
                            />
                        </div>
                    </div>
                    <div className="mb-2 col-12 d-flex">
                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Status"
                            />
                        </div>
                        <div className="col-2 mt-2 text-center">
                            <LuLink />
                        </div>

                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Status"
                            />
                        </div>
                    </div>
                    <div className="mb-2 col-12 d-flex">
                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Comments"
                            />
                        </div>
                        <div className="col-2 mt-2 text-center">
                            <LuLink />
                        </div>

                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Comments"
                            />
                        </div>
                    </div>
                    <div className="mb-2 col-12 d-flex">
                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Attachment"
                            />
                        </div>
                        <div className="col-2 mt-2 text-center">
                            <LuLink />
                        </div>

                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Attachment"
                            />
                        </div>
                    </div>
                    <div className="mb-2 col-12 d-flex">
                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Actual Result Description"
                            />
                        </div>
                        <div className="col-2 mt-2 text-center">
                            <LuLink />
                        </div>

                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Actual Result Description"
                            />
                        </div>
                    </div>
                    <div className="mb-2 col-12 d-flex">
                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Expected Result Description"
                            />
                        </div>
                        <div className="col-2 mt-2 text-center">
                            <LuLink />
                        </div>

                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Expected Result Description"
                            />
                        </div>
                    </div>
                </form>}
                {product === "Plane" &&
                <form>
                    <div className="mb-3 col-12 d-flex">
                        <div className="col-5">
                            <Label className="form-label">
                                TestOps
                            </Label>
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Defect Summary"
                            />
                        </div>
                        <div className="col-2 mt-5 text-center">
                            <Label className="form-label">
                            </Label>
                            <LuLink />
                        </div>

                        <div className="col-5">
                            <Label className="form-label">
                            Plane
                            </Label>
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Title"
                            />
                        </div>
                    </div>
                    <div className="mb-3 col-12 d-flex">
                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Steps to reproduce"
                            />
                        </div>
                        <div className="col-2 mt-2 text-center">
                            <LuLink />
                        </div>

                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Description"
                            />
                        </div>
                    </div>
                    <div className="mb-3 col-12 d-flex">
                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Defect Status"
                            />
                        </div>
                        <div className="col-2 mt-2 text-center">
                            <LuLink />
                        </div>

                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Status"
                            />
                        </div>
                    </div>
                    <div className="mb-3 col-12 d-flex">
                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Priority"
                            />
                        </div>
                        <div className="col-2 mt-2 text-center">
                            <LuLink />
                        </div>

                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Priority"
                            />
                        </div>
                    </div>
                    <div className="mb-3 col-12 d-flex">
                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Issue Type"
                            />
                        </div>
                        <div className="col-2 mt-2 text-center">
                            <LuLink />
                        </div>

                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Bug"
                            />
                        </div>
                    </div>
                    <div className="mb-3 col-12 d-flex">
                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Comments"
                            />
                        </div>
                        <div className="col-2 mt-2 text-center">
                            <LuLink />
                        </div>

                        <div className="col-5">
                            <Input
                                type="text"
                                placeholder="Jira Email"
                                className="custom-input"
                                disabled
                                value="Comments"
                            />
                        </div>
                    </div>
                </form>}
            </OffcanvasBody>
        </Offcanvas>
    )
}

export default ViewBasicMapProject
