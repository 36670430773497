import React, { useEffect, useState, useMemo } from "react"
import withRouter from "components/Common/withRouter"
import { Offcanvas, OffcanvasBody } from "reactstrap"
import { Col, Container, Row } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { ToastContainer } from "react-toastify"
import {
  downloadDefectReportsAPI,
  getDefectReportsDataAPI,
} from "store/actions/ReportsAction"
import GeneratedContainer from "../common/GeneratedTable"
import BASE_URL from "services/apiConfig"

const GenerateReport = props => {
  const { isModalOpen, toggle, resetFilter } = props

  const dispatch = useDispatch()
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))
  const selectedFormValues = useSelector(
    state => state?.Reports?.selectedFormValues
  )
  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  // Mapping of formState keys to payload keys
  const fieldMappings = {
    DefectId: "defect_id",
    DefectSummary: "defect_name",
    DefectType: "defect_type",
    Build: "build",
    Module: "module_name",
    RequirementTitle: "requirement_title",
    Status: "defect_status",
    OS: "os",
    Browsers: "browsers",
    AssignTo: "assign_to_name",
    Attachment: "attachment",
    ExpectedResultDescription: "expected_result_description",
    StepsToReproduce: "steps_to_reproduce",
    ActualResultDescription: "actual_result_description",
    Comments: "comments",
    Severity: "severity",
    Priority: "defect_priority",
    CreatedAt: "created_at",
  }

  const selectedFields = selectedFormValues?.selectedFields || {}

  // Create an array of selected field keys based on the mapping and values being true
  const selectedFieldKeys = Object.keys(selectedFields)
    .filter(key => selectedFields[key])
    .map(key => fieldMappings[key])
    .filter(Boolean) // Remove undefined entries

  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [globalFilter, setGlobalFilter] = useState("")
  const [totalCount, setTotalCount] = useState(null)

  const fetchTableData = async (page, size, search) => {
    let bodyoption = {
      project_id: globalProject?.value || selectedProject?.value,
      page: page + 1,
      page_size: size,
      search: search,
      filter_build_id: selectedFormValues?.build || null,
      filter_module_id: selectedFormValues?.module || null,
      filter_requirement_id: selectedFormValues?.requirement || null,
      filter_defect_status: selectedFormValues?.status || null,
      filter_os: selectedFormValues?.os || null,
      filter_browsers: selectedFormValues?.browser || null,
      filter_assign_to: selectedFormValues?.assignedTo || null,
      filter_severity: selectedFormValues?.severity || null,
      filter_defect_type: selectedFormValues?.defectType || null, // Corrected this filter key
      filter_defect_priority: selectedFormValues?.priority || null,
      filter_from_date: selectedFormValues?.fromDate || null,
      filter_to_date: selectedFormValues?.toDate || null,
      selected_fields: selectedFieldKeys,
    }

    try {
      const response = await dispatch(getDefectReportsDataAPI(bodyoption))
      setData(response.Data)
      setTotalCount(response.TotalCount)

      const dynamicColumns = Object.keys(response.Data[0] || {}).map(key => ({
        header: key.replace(/_/g, " ").toUpperCase(), // Generate readable header
        accessorKey: key,
        enableColumnFilter: false, // Set to true if filtering is needed
        enableSorting: true, // Sorting enabled
        cell: cell => {
          let value = cell.getValue()

          // Check for specific empty HTML patterns and replace with "-"
          const emptyHTMLPattern = /^<p><br><\/p>$/i // Case-insensitive match

          if (typeof value === "string" && emptyHTMLPattern.test(value)) {
            value = "-" // Replace empty HTML with "-"
          }

          // Special case for "attachment" key
          if (key === "attachment") {
            if (value) {
              const mediaCount = (value.match(/\/media/g) || []).length
              const filePath =
                mediaCount > 1 ? value.replace("/media", "") : value
              const fileUrl = `${BASE_URL}${filePath}`

              return (
                <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                  View Attachment
                </a>
              )
            }
            return "-" // No attachment case
          }

          // Default case for other keys
          return (
            <p
              className="font-size-14 mb-1"
              style={{
                wordWrap: "break-word",
                overflowWrap: "break-word",
                whiteSpace: "normal",
                minWidth: "150px",
                maxWidth: "350px", // Controls text wrapping
              }}
              dangerouslySetInnerHTML={{
                __html: value || "-", // Display default value if empty
              }}
            ></p>
          )
        },
      }))

      setColumns(dynamicColumns)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  useEffect(() => {
    fetchTableData(pageIndex, pageSize, globalFilter)
  }, [
    pageIndex,
    pageSize,
    globalFilter,
    globalProject?.value,
    selectedFormValues,
  ])

  const handleBack = () => {
    toggle()
  }
  const downloadReport = async filetype => {
    let bodyoption = {
      project_id: globalProject?.value || selectedProject?.value,
      filter_build_id: selectedFormValues?.build || null,
      filter_module_id: selectedFormValues?.module || null,
      filter_requirement_id: selectedFormValues?.requirement || null,
      filter_defect_status: selectedFormValues?.status || null,
      filter_os: selectedFormValues?.os || null,
      filter_browsers: selectedFormValues?.browser || null,
      filter_assign_to: selectedFormValues?.assignedTo || null,
      filter_severity: selectedFormValues?.severity || null,
      filter_defect_type: selectedFormValues?.defectType || null, // Corrected this filter key
      filter_defect_priority: selectedFormValues?.priority || null,
      filter_from_date: selectedFormValues?.fromDate || null,
      filter_to_date: selectedFormValues?.toDate || null,
      selected_fields: selectedFieldKeys,
      File_type: filetype,
    }

    try {
      dispatch(downloadDefectReportsAPI(bodyoption))
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <React.Fragment>
      <Offcanvas
        isOpen={isModalOpen}
        direction="end"
        toggle={() => {
          setGlobalFilter("")
          toggle()
        }}
        style={{ width: "80%" }}
      >
        {/* <OffcanvasHeader toggle={toggle}>Filter Defects</OffcanvasHeader> */}
        <OffcanvasBody>
          <Container fluid>
            <Row>
              <Col lg="12">
                <div className="table-container">
                  <GeneratedContainer
                    columns={columns}
                    data={data}
                    downloadReport={downloadReport}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    totalCount={totalCount}
                    setPageIndex={setPageIndex}
                    setPageSize={setPageSize}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    isGlobalFilter={true}
                    isPagination={true}
                    SearchPlaceholder="Search..."
                    isCustomPageSize={true}
                    isAddDelete={true}
                    handleBack={handleBack}
                    resetFilter={resetFilter}
                    buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal ms-2 mb-2"
                    buttonName="Create"
                    tableClass="table table-striped table-bordered align-middle table-nowrap dt-responsive nowrap w-100 table-borderless"
                    theadClass="table-light"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    pagination="pagination"
                  />
                </div>
              </Col>
            </Row>
          </Container>
          <ToastContainer />
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  )
}

export default withRouter(GenerateReport)
