import { toast } from "react-toastify";
import httpInjectorService from "services/http-Injector.Service";
import { setLoading } from "store/Slices/LoadingSlice";
import { setGetUserProfile } from "store/Slices/UserSlice";

export const updateUser = (bodyoption, id) => {
  return async dispatch => {
    dispatch(setLoading(true));
    try {
      const response = await httpInjectorService.userProfile(bodyoption, id);
      const data = response;

      // Check if response has a status indicating success
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        // Handle non-field errors
        if (response?.non_field_errors && response.non_field_errors.length > 0) {
          response.non_field_errors.forEach(error => {
            toast.error(error, {
              position: "top-right",
              autoClose: 3000,
            });
          });
        } else {
          // Handle other warning messages
          toast.warning(response.message, {
            position: "top-right",
            autoClose: 3000,
          });
        }
      }
      dispatch(setLoading(false));
      return data;
    } catch (error) {
      dispatch(setLoading(false));
      // Display error message if any other error occurs
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
      });
      throw new Error(error.message);
    }
  };
};


export const getUserProfile = (id) => {
  return async dispatch => {
    dispatch(setLoading(true));
    try {
      const response = await httpInjectorService.getUserProfile(id);
      const data = response;
      dispatch(setGetUserProfile(data))
      dispatch(setLoading(false));
      return data;
    } catch (error) {
      dispatch(setLoading(false)); 
      throw new Error(error.message);
    }
  };
};
