import {
  quickActionsOptions,
  QuickActionImg,
} from "helpers/Quick Actions/QuickAction"
import React, { useState } from "react"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import { Link } from "react-router-dom" // Import Link from react-router-dom

const QuickAction = () => {
  const [quickActions, setQuickActions] = useState(false)

  // Define route paths based on value
  const getRoutePath = (value) => {
    switch (value) {
      case "New Project":
        return "/CreateProject"
      case "New Build":
        return "/Create_Build"
      case "New Module":
        return "/Create-Module"
      case "New Requirement":
        return "/New-Requirement"
      case "New Test Scenario":
        return "/CreateTestScenario"
      case "New Test Case":
        return "/CreateTestCase"
      case "New Defect":
        return "/CreateDefect"
      case "New User":
        return "/CreateUsers"
      default:
        return "/"
    }
  }

  return (
    <>
      <Dropdown
        isOpen={quickActions}
        className="d-none d-lg-flex align-items-center mb-1"
        style={{ color: "inherit" }}
        toggle={() => setQuickActions(!quickActions)}
      >
        <DropdownToggle
          style={{
            backgroundColor: "transparent",
            color: "inherit",
            border: "none", // Remove border
          }}
        >
          <span className="d-none d-xl-inline-block">
            <i className="bx bxs-star-half" style={{ fontSize: "21px", marginTop: "2px" }} title="Quick Actions"></i>
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-end"
          style={{ maxHeight: "200px", overflowY: "auto" }}
        >
          {quickActionsOptions?.map((option) => (
            <DropdownItem key={option?.value}>
              <Link to={getRoutePath(option?.value)} className="d-flex text-dark align-items-center">
                <img
                  src={option?.icon}
                  alt={option?.label}
                  className="iconActions mx-2"
                />
                {option?.label}
              </Link>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default QuickAction
