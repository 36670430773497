import Breadcrumb from "components/Common/Breadcrumb"
import { getStatusBackGroundColor, joditConfig } from "helpers/helper"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap"
import { GetPreConditionByIdAPI } from "store/actions/TestCaseAction"
import ViewPreCondition from "../../../Test Management/Test Case/View Pre Condition/ViewPreCondition"
import Select from "react-select"
import {
  getAllDefectsListAPI,
  GetDefectByIdAPI,
} from "store/actions/DefectsActions"
import JoditEditor from "jodit-react"
import { updateExecute } from "store/actions/TestCycleActions"
import { toast } from "react-toastify"
import { setEditDefect } from "store/Slices/DefectsSlice"
import Dropzone from "react-dropzone"
import BASE_URL from "services/apiConfig"

const ExecuteTestCase = () => {
  const options = [
    { value: "NOTRUN", label: "NOT RUN" },
    { value: "PASSED", label: "PASSED" },
    { value: "FAILED", label: "FAILED" },
  ]

  const projectLocalId = JSON.parse(localStorage.getItem("selectedProject"))
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const viewTestCaseData = useSelector(
    state => state?.TestCase?.singleTestCaseData
  )

  const Permission = JSON.parse(localStorage.getItem("authUser"));
  const defectPermission = Permission?.permissions[0]?.menu_item?.DefectManagement
    ?.defectmanagement

  const executePageContent = useSelector(state => state?.TestCycle?.executePage)

  const [time, setTime] = useState("")
  const [comments, setComments] = useState("")
  const [defectOptions, setDefectOptions] = useState([])

  const [defects, setDefects] = useState(null)
  const [status, setStatus] = useState(null)
  const [defectDetails, setDefectDetails] = useState([])
  const [defectDetailSelected, setDefectDetailSelected] = useState([])
  const [validationErrMsg, setValidationErrMsg] = useState({})
  const [preCondition, setPreCondition] = useState(null)
  const [defectArray, setDefectArray] = useState(null)
  const [loading, setLoading] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [selectedDeleteFiles, setSelectedDeleteFiles] = useState([])

  useEffect(() => {
    if (executePageContent) {
      setTime(executePageContent.excution_time || "")
      setComments(executePageContent.comments || "")
      if (executePageContent?.attachment) {
        const defectArrays = Array.isArray(executePageContent?.attachment)
          ? executePageContent?.attachment
          : [executePageContent?.attachment]
        const existingFiles = defectArrays?.map((file, index) => ({
          name: `Existing File ${index + 1}`,
          path: file, // Assuming this is the file path
          isExisting: true,
        }))
        setSelectedDeleteFiles(existingFiles)
        setSelectedFiles(existingFiles)
      }
      setDefects(
        executePageContent.defect_names
          ? executePageContent.defect_names.map(defect => ({
              label: defect,
              value: defectOptions?.find(option => option?.label == defect)
                ?.value,
            }))
          : []
      )
      setStatus(
        executePageContent.excution_status === "NOTRUN"
          ? {
              label: "NOT RUN",
              value: executePageContent?.excution_status,
            }
          : {
              label: executePageContent?.excution_status,
              value: executePageContent?.excution_status,
            }
      )
      // if (executePageContent.attachment) {
      //   const existingFileName = executePageContent.attachment;
      //   setFileNames([existingFileName]);
      //   // setFile(matchedItem?.attachment);
      // }
    }
  }, [defectOptions, executePageContent])

  useEffect(() => {
    const defectArrays = Array.isArray(defects) ? defects : [defects]

    setDefectArray(defectArrays)
    const selectedDetails = defectArrays?.map(defect => {
      return defectDetails.find(detail => detail.id === defect.value)
    })
    setDefectDetailSelected(selectedDetails)
  }, [defects])

  const fetchPreConditionDetails = async preconditionId => {
    let bodyOptions = { project_id: viewTestCaseData?.project_id }
    try {
      const res = await dispatch(GetPreConditionByIdAPI(bodyOptions))
      const data = res?.filter((id) =>
        id?.id === preconditionId?.id
      )
      setPreCondition(data)
      toggle()
    } catch (error) { }
  }
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    if (!viewTestCaseData) {
      handleClose()
    }
  }, [])

  const handleClose = () => {
    navigate("/TestCycle/ViewTestCycleManual")
  }

  const checkValidations = () => {
    const timeFormat = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/
    const errors = {}
    if (!status) errors.status = "Status required"
    if (time) {
      if (!timeFormat.test(time)) errors.time = "Valid Time Format : HH:MM:SS"
    }
    return errors
  }

  const hanldeSubmit = async () => {
    const validationErrors = checkValidations()
    if (Object.keys(validationErrors).length > 0) {
      setValidationErrMsg(validationErrors)
      return
    }

    setLoading(true)
    let bodyoptions
    bodyoptions = {
      project_id: projectLocalId?.value,
      excution_status: status.value,
      comments: comments,
      excution_time: time,
      test_case_id: executePageContent?.test_case_id,
      defect_id:
        defectArray?.length !== 0
          ? defectArray?.map(defect => defect.value)
          : undefined,
      id: executePageContent?.id,
      attachment: selectedFiles[0],
      clear_attachments: selectedFiles?.length === 0 ? true : false,
    }

    try {
      await dispatch(updateExecute(bodyoptions))
      setLoading(false)
      handleClose()
    } catch (error) {
      toast.warn(error?.message, {
        position: "top-right",
        autoClose: 3000,
      })
      console.log(validationErrMsg)
      setLoading(false)
      setValidationErrMsg({ form: error.message })
    }
  }

  const viewDefect = async id => {
    if (defectPermission?.view) {
      try {
        const res = await dispatch(GetDefectByIdAPI(id))
        if (res) {
          navigate("/DefectManagement/ViewDefect")
        }
      } catch (error) {
        console.error("Error fetching data", error)
      }
    } else {
      toast.warn("You don't have permission to view defect", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      })
    }
  }

  const hanldeCreateDefect = () => {
    if (defectPermission?.create) {
      dispatch(setEditDefect({ isEdit: false, data: null }))
      navigate("/DefectManagement/CreateEditDefect")
    } else {
      toast.warn("You don't have permission to create defect", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      })
    }
  }

  const fetchDefectData = async () => {
    let bodyOption = { project_id: projectLocalId?.value }

    const defectData = await dispatch(getAllDefectsListAPI(bodyOption))
    const defectOptionValues = defectData?.map(entry => ({
      value: entry.id,
      label: entry.defect_name,
    }))
    setDefectOptions(defectOptionValues)
    setDefectDetails(defectData)
  }

  useEffect(() => {
    fetchDefectData()
  }, [projectLocalId?.value])

  const handleAcceptedFiles = files => {
    const newImages = files?.map(file => {
      return Object.assign(file, {
        priview: URL.createObjectURL(file),
      })
    })
    setSelectedFiles([...selectedFiles, ...newImages])
    setSelectedDeleteFiles([...selectedFiles, ...newImages])
  }

  const formatFilePath = path => {
    if (path?.includes("/media/media")) {
      return path.replace("media/media", "media")
    }
    return path
  }

  return (
    <div className="">
      <ViewPreCondition isOpen={isOpen} toggle={toggle} data={preCondition} />
      <Container fluid>
        <div className="d-flex justify-content-between  mb-2">
          <div className="d-flex">
            <div className="flex-grow-1 overflow-hidden">
              <h5 className="font-size-15 d-flex mt-4">
                <i
                  className="mdi mdi-arrow-left label-icon me-2 fs-4"
                  style={{ cursor: "pointer" }}
                  onClick={handleClose}
                  title="Back"
                ></i>
                <span
                  style={{
                    maxWidth: "500px",
                  }}
                >
                  <Link className="text-dark">
                    <h5>
                      {viewTestCaseData?.test_case_name || "-"}
                      {" ("}
                      {viewTestCaseData?.test_case_id}
                      {")"}
                    </h5>
                  </Link>
                </span>
              </h5>
            </div>
          </div>
        </div>
        <div>
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody style={{overflow:"auto"}}>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Build</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.build ? viewTestCaseData?.build : "-"}
                    </p>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Module</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.module_name
                        ? viewTestCaseData?.module_name
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Requirement Title</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.requirement_title
                        ? viewTestCaseData?.requirement_title
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Test Scenario</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.test_scenario_id
                        ? `${viewTestCaseData?.test_scenario} -${viewTestCaseData?.test_scenario_name}`
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Status</h6>
                    <p className="text-muted ">
                      <Badge
                        className={
                          "badge-soft-" +
                          `${getStatusBackGroundColor(
                            viewTestCaseData?.test_status
                          )}`
                        }
                        style={{
                          fontSize: "12px", // Increase font size
                          padding: "0.5em", // Increase padding
                          borderRadius: "0.5em", // Adjust border radius
                        }}
                      >
                        {viewTestCaseData?.test_status || "-"}
                      </Badge>
                    </p>
                  </div>
                  <div className="mb-4">
                    <h6 style={{ fontWeight: "550" }}>Summary</h6>
                    <p
                      className="text-muted mb-4" 
                      dangerouslySetInnerHTML={{
                        __html: viewTestCaseData?.summary
                          ? viewTestCaseData?.summary
                          : "-",
                      }}
                    ></p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm={6}>
              <Card>
                <CardBody>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Testing Type</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.testing_type
                        ? viewTestCaseData?.testing_type
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Priority</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.priority
                        ? viewTestCaseData?.priority
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Created At</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.created_at
                        ? moment(viewTestCaseData?.created_at).format(
                          "DD-MM-YYYY"
                        )
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Execution Type</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.execution_type
                        ? viewTestCaseData?.execution_type
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Estimated Time</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.estimated_time
                        ? viewTestCaseData?.estimated_time
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Assign To</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.assign_to_name
                        ? viewTestCaseData?.assign_to_name
                        : "-"}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={6}></Col>
            <Col sm={6}></Col>
          </Row>
          <Card>
            <CardBody>
              <Row>
                <Col sm={12}>
                  <h6 style={{ fontWeight: "550" }}>Pre Condition</h6>
                  <p>
                    {Array.isArray(viewTestCaseData?.pre_conditions_details) ? (
                      viewTestCaseData.pre_conditions_details.length > 0 ? (
                        viewTestCaseData.pre_conditions_details.map(
                          (item, index) => (
                            <Button
                              key={item.id || index} // Use item.id if available for better key management
                              color="secondary"
                              size="sm"
                              className="me-1 mb-1"
                              style={{ fontSize: "12px" }}
                              onClick={() => fetchPreConditionDetails(item)}
                              aria-label={`Pre-condition ${index + 1}`}
                            >
                              {/* Access condition_name instead of name */}
                              {item.condition_name ? item.condition_name : item}
                            </Button>
                          )
                        )
                      ) : (
                        "-"
                      )
                    ) : viewTestCaseData?.pre_conditions_details ? (
                      <Button
                        color="secondary"
                        size="sm"
                        style={{ fontSize: "12px" }}
                      >
                        {viewTestCaseData.pre_conditions_details}
                      </Button>
                    ) : (
                      "-"
                    )}
                  </p>
                </Col>

                <Col sm={12}>
                  <h6 style={{ fontWeight: "550" }}>New Pre Condition</h6>
                  <p
                    className="text-muted mb-4"
                    dangerouslySetInnerHTML={{
                      __html: viewTestCaseData?.new_pre_condition
                        ? viewTestCaseData?.new_pre_condition
                        : "-",
                    }}
                  ></p>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row>
                <Col sm={12}>
                  <h6 style={{ fontWeight: "550" }}>Expected Result</h6>
                  <p
                    className="text-muted mb-4"
                    dangerouslySetInnerHTML={{
                      __html: viewTestCaseData?.expected_result
                        ? viewTestCaseData?.expected_result
                        : "-",
                    }}
                  ></p>
                </Col>

                <Col sm={12}>
                  <h6 style={{ fontWeight: "550" }}>Post Condition</h6>
                  <p
                    className="text-muted mb-4"
                    dangerouslySetInnerHTML={{
                      __html: viewTestCaseData?.post_condition
                        ? viewTestCaseData?.post_condition
                        : "-",
                    }}
                  ></p>
                </Col>

                <Col sm={12}>
                  <h6 style={{ fontWeight: "550" }}>Detail Steps</h6>
                  <p
                    className="text-muted mb-4"
                    dangerouslySetInnerHTML={{
                      __html: viewTestCaseData?.detail_steps
                        ? viewTestCaseData?.detail_steps
                        : "-",
                    }}
                  ></p>
                </Col>

                <Col sm={12}>
                  <h6 style={{ fontWeight: "550" }}>Comments</h6>
                  <p
                    className="text-muted mb-4"
                    dangerouslySetInnerHTML={{
                      __html: viewTestCaseData?.comments
                        ? viewTestCaseData?.comments
                        : "-",
                    }}
                  ></p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody className="d-flex flex-column flex-md-row gap-2">
                <div className="mb-3 col-12 col-md-4">
                  <Label className="form-label">
                    Status<span className="text-danger">*</span>
                  </Label>
                  <Select
                    className="select2-selection col-12"
                    value={status}
                    label="Single select"
                    onChange={e => setStatus(e)}
                    options={options}
                  />
                  {validationErrMsg.status && (
                    <span className="text-danger required-field">
                      {validationErrMsg.status}
                    </span>
                  )}
                </div>

                <div className="mb-3 col-12 col-md-4">
                  <Label className="form-label">Time</Label>
                  <Input
                    style={{ width: "100%" }} // Change to 100% for better responsiveness
                    value={time}
                    onChange={e => {
                      setTime(e.target.value.replace(/[^:\d]/g, "")); // Restrict to digits and colon
                    }}
                    placeholder="HH:MM:SS"
                    type="text"
                    name="approval_from"
                    className="col-12" // Change to col-12 for better responsiveness
                  />
                  {validationErrMsg.time && (
                    <span className="text-danger required-field">
                      {validationErrMsg.time}
                    </span>
                  )}
                </div>

                <div className="mb-3 col-12 col-md-4">
                  <Label className="form-label">Defects</Label>
                  <Select
                    className="select2-selection multiSelect col-12"
                    value={defects}
                    label="Multi select"
                    onChange={e => setDefects(e)}
                    options={defectOptions}
                    isMulti
                    closeMenuOnSelect={false}
                  />
                  <Label
                    className="form-label mt-2 text-primary"
                    style={{ cursor: "pointer" }}
                    title="Click here to create a new defect"
                    onClick={hanldeCreateDefect}
                  >
                    + Create Defects
                  </Label>
                </div>
              </CardBody>

              <CardBody className="d-flex gap-2">
                <div className="mb-3 col-12">
                  <Label className="form-label">Comments</Label>
                  <JoditEditor
                    value={comments}
                    config={joditConfig()}
                    onBlur={newContent => setComments(newContent)}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={12}>
            <Card>
              <CardBody>
                <div className="mt-3">
                  <Label>Attached Files</Label>
                  <Dropzone
                    onDrop={acceptedFiles => {
                      handleAcceptedFiles(acceptedFiles)
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="dz-message needsclick">
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className="dropzone-previews mt-3" id="file-previews">
                    {selectedDeleteFiles.map((file, index) => {
                      return (
                        <div className="border rounded" key={index}>
                          <div className="d-flex flex-justify-content-between gap-2 p-2">
                            <div className="flex-shrink-0 me-3">
                              <div className="avatar-xs bg-light rounded p-2">
                                <i
                                  className="bx bx-file"
                                  style={{ fontSize: "23px" }}
                                ></i>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <div className="pt-2">
                                <p className="fs-md mb-1" data-dz-name>
                                  {" "}
                                  <a
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                    }}
                                    href={`${BASE_URL}${formatFilePath(
                                      file.path
                                    )}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {file.path}
                                  </a>
                                </p>
                                <strong
                                  className="error text-danger"
                                  data-dz-errormessage
                                ></strong>
                              </div>
                            </div>
                            <div className="flex-shrink-0 ms-3 mt-1">
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() => {
                                  const newImages = [...selectedFiles]
                                  newImages.splice(index, 1)
                                  setSelectedFiles(newImages)
                                  setSelectedDeleteFiles(newImages)
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          {defectDetailSelected?.length !== 0 ? (
            <>
              <div className="testCaseMap">
                <div className="TestSuiteDefect-Header">
                  <h5 className="text-primary">DEFECTS MAPPED</h5>
                </div>
                <Table hover className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Order No</th>
                      <th>Defect Id</th>
                      <th>Defect Name</th>
                    </tr>
                  </thead>
                  <tbody className="TestSuiteExecute-TableBody">
                    {defectDetailSelected?.map((script, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "left",
                              cursor: "pointer",
                              alignItems: "center",
                              gap: "2px",
                              fontWeight: "600",
                              textDecoration: "underline",
                            }}
                            onClick={() => {
                              viewDefect(script?.id)
                            }}
                            title="View Defect Details"
                          >
                            {script?.defect_id}
                            <i
                              className="mdi mdi-near-me me-2"
                              style={{ cursor: "pointer" }}
                            ></i>
                          </div>
                        </td>
                        <td>{script?.defect_name}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          ) : null}
          <Col md="12" className="text-end mt-3">
            <Button color="primary" type="submit" onClick={hanldeSubmit} disabled={loading}>
              {loading ? "Loading.." : "Submit"}
            </Button>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                navigate(-1)
              }}
              className="ms-2"
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ExecuteTestCase
