import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";

const Paginations = ({ perPageData, data, currentPage, setCurrentPage, isShowingPageLength, paginationDiv, paginationClass }) => {
    // Calculate the total number of pages
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(data?.length / perPageData); i++) {
        pageNumbers.push(i);
    }

    // Handle page click
    const handleClick = (e) => {
        setCurrentPage(Number(e.target.id));
    };

    // Handle previous page click
    const handleprevPage = () => {
        let prevPage = currentPage - 1;
        setCurrentPage(prevPage);
    };

    // Handle next page click and prevent navigating past the last page
    const handlenextPage = (event) => {
        event.preventDefault();
        if (currentPage < pageNumbers.length) {
            let nextPage = currentPage + 1;
            setCurrentPage(nextPage);
        }
    };

    // If the current page exceeds the total pages, reset it to the last page
    useEffect(() => {
        if (pageNumbers.length && pageNumbers.length < currentPage) {
            setCurrentPage(pageNumbers.length);
        }
    }, [pageNumbers.length, currentPage, setCurrentPage]);

    return (
        <React.Fragment>
            <Row className="justify-content-between align-items-center">
                {isShowingPageLength && (
                    <Col sm={12} md={5}>
                        <div className="text-muted dataTables_info">
                            {/* Showing {perPageData} of {data?.length} entries */}
                            Showing data of {data?.length} entries
                        </div>
                    </Col>
                )}
                <div className={paginationDiv}>
                    <ul className={paginationClass}>
                        {/* Previous Page Button */}
                        <li className={`page-item ${currentPage <= 1 ? "disabled" : ""}`}>
                            <Link className="page-link" to="#" onClick={handleprevPage}>
                                <i className="mdi mdi-chevron-left"></i>
                            </Link>
                        </li>

                        {/* Page Numbers */}
                        {(pageNumbers || []).map((item, index) => (
                            <li className={currentPage === item ? "page-item active" : "page-item"} key={index}>
                                <Link className="page-link" to="#" id={item} onClick={handleClick}>
                                    {item}
                                </Link>
                            </li>
                        ))}

                        {/* Next Page Button */}
                        <li className={`page-item ${currentPage >= pageNumbers.length ? "disabled" : ""}`}>
                            <Link className="page-link" to="#" onClick={handlenextPage}>
                                <i className="mdi mdi-chevron-right"></i>
                            </Link>
                        </li>
                    </ul>
                </div>
            </Row>
        </React.Fragment>
    );
};

export default Paginations;
