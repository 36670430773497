import React, { useEffect, useRef, useState } from "react"
import TableContainer from "./TableContainer"
import { deleteTagAPI, fetchAllTagsList } from "store/actions/TagsAction"
import { useDispatch } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import NoPermission from "components/Common/NoPermission"

const Tags = () => {
  const [globalFilter, setGlobalFilter] = useState("")
  const [data, setData] = useState([])
  const [isDeleteId, setIsDeleteId] = useState("")
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [deleteModal, setDeleteModal] = useState(false)

  const searchInputRef = useRef(null)

  const Permission = JSON.parse(localStorage.getItem("authUser"))
  const tagPermission = Permission?.permissions[0]?.menu_item?.Settings?.meta

  const fetchTableData = async search => {
    let bodyoption = {
      search: search,
    }
    try {
      const data = await dispatch(fetchAllTagsList(bodyoption))
      setData(data)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }
  useEffect(() => {
    fetchTableData(globalFilter)
  }, [])

  useEffect(() => {
    fetchTableData(globalFilter)
  }, [globalFilter])

  const handleDelete = async () => {
    try {
      await dispatch(deleteTagAPI(isDeleteId))
      setDeleteModal(false)
      await fetchTableData(globalFilter)
      setIsDeleteId("")
    } catch (error) {
      console.log("Error deleting tags:", error)
    }
  }

  const DebouncedInput = ({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
  }) => {
    const [value, setValue] = useState(initialValue)

    useEffect(() => {
      setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
      const timeout = setTimeout(() => {
        onChange(value)
      }, debounce)

      return () => clearTimeout(timeout)
    }, [debounce, onChange, value])

    return (
      <InputGroup style={{ height: "36px", width: "280px" }}>
        <InputGroupText style={{ height: "100%", padding: "0 12px" }}>
          <i className="bx bx-search-alt search-icon"></i>
        </InputGroupText>
        <input
          {...props}
          value={value}
          onChange={e => setValue(e.target.value)}
          style={{ height: "100%" }}
          ref={searchInputRef}
        />
      </InputGroup>
    )
  }
  const handleGlobalFilterChange = value => {
    setGlobalFilter(String(value))
    if (searchInputRef.current) {
      searchInputRef.current.focus() // Focus the input after the API call completes
    }
  }
  const handleAddClick = () => {
    if (tagPermission?.create) {
      navigate("/Settings/CreateTag")
    } else {
      toast.warn("You don't have permission to Create Tag", {
        position: "top-right",
        autoClose: 3000,
      })
    }
  }
  return (
    <div>
      <Container fluid>
        {tagPermission?.view ? <>
        <Row>
          <Col lg="12">
            <Card
              style={{
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
              }}
            >
              <CardBody>
                <div className="row align-items-start">
                  <div className="col-12 col-sm-8 mb-2">
                    <DebouncedInput
                      value={globalFilter ?? ""}
                      onChange={handleGlobalFilterChange}
                      className="form-control search-box"
                      placeholder="Search..."
                      style={{ width: "100%" }} // Full width on all screens
                    />
                  </div>
                  <div className="col-12 col-sm-4 text-left text-sm-end">
                    <Button
                      type="button"
                      color="success"
                      className="btn-label"
                      onClick={handleAddClick}
                    >
                      <i className="mdi mdi-plus label-icon"></i> Create
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <TableContainer
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          data={data}
          setIsDeleteId={setIsDeleteId}
          handleDelete={handleDelete}
          fetchTableData={fetchTableData}
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          /></> : <NoPermission />}
      </Container>
    </div>
  )
}

export default Tags
