import { createSlice } from "@reduxjs/toolkit"

const TestPlanSlice = createSlice({
  name: "TestPlan",
  initialState: {
    singleTestPlanData: null,
    nextTestPlanId: null,
    editTestPlan: {
      isEdit: false,
      data: null,
    },
  },

  reducers: {
    setSingleTestPlanData: (state, action) => ({
      ...state,
      singleTestPlanData: action.payload,
    }),
    setNextTestPlanId: (state, action) => ({
      ...state,
      nextTestPlanId: action.payload,
    }),
    setEditTestPlan: (state, action) => ({
      ...state,
      editTestPlan: action.payload,
    }),
  },
})

export const { setSingleTestPlanData, setNextTestPlanId, setEditTestPlan } =
  TestPlanSlice.actions

export default TestPlanSlice.reducer
