import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
// import user1 from "../../../assets/images/users/user2.gif"
import { setSelectetProject } from "store/Slices/GlobalSlice"
import { useDispatch } from "react-redux"
import { setAllProjectList } from "store/Slices/ProjectsSlice"

// users
// import user1 from "../../../assets/images/users/avatar-1.jpg";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const dispatch = useDispatch()
  const user = JSON.parse(localStorage.getItem("authUser"))

  // Function to get the initial or handle special characters
const getUserInitial = userName => {
  const firstChar = userName.charAt(0)
  
  // Check if the first character is a letter (A-Z or a-z)
  if (/^[A-Za-z]$/.test(firstChar)) {
    return firstChar.toUpperCase();
  }
  
  // Return a default letter if it's a special character
  return firstChar;
};


  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item d-flex align-items-center "
          id="page-header-user-dropdown"
          tag="button"
        >
          <div
              className="rounded-circle header-profile-user"
              style={{
                width: "25px",
                height: "25px",
                backgroundColor: "grey", // Placeholder background color
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff", // Text color for the initial
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              {getUserInitial(user.user_name)}
            </div>
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {user.user_name}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem>
            <Link
              to="/profile"
              className="dropdown-item"
              style={{ marginLeft: "-22px" }}
            >
              <i className="bx bx-user font-size-16 align-middle me-1" />
              {props.t("Profile")}
            </Link>
          </DropdownItem>
          {/* <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle me-1" />
            {props.t("My Wallet")}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Settings")}
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem> */}
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
