import React, { useEffect, useState } from "react"
import { Label, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { fetchAllBuildsList } from "store/actions/BuildsActions"
import { fetchAllModulesList } from "store/actions/ModuleActions"
import { fetchAllRequirementsList } from "store/actions/RequirementActions"
import { useFormik } from "formik"
import {
  clearFilters,
  setTestScenarioFilterFrom,
} from "store/Slices/TestScenarioSlice"
import {
  fetchAllTeamsDetailsUserAssigneeList,
  // fetchAllUsersDetailsList,
} from "store/actions/UserActions"

const FilterTestScenario = props => {
  const { isFilterFormOpen, toggle, onSubmitFilterFrom } = props
  const dispatch = useDispatch()
  const { testScenarioFilterFrom } = useSelector(state => state.TestScenario)
  const globalProject = useSelector(
    state => state.globalProjectSelect.selectetProject
  )

  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))

  const formik = useFormik({
    initialValues: testScenarioFilterFrom,
    enableReinitialize: true,
    onSubmit: async values => {
      try {
        if (onSubmitFilterFrom) {
          await onSubmitFilterFrom(values)
        }
        dispatch(setTestScenarioFilterFrom(values))
      } catch (error) {
        console.error("Error during form submission:", error)
      }
    },
  })

  const [buildOptions, setBuildOptions] = useState([])
  const [moduleOptions, setModuleOptions] = useState([])
  const [requirementOptions, setRequirementOptions] = useState([])
  const [createdByValue, setCreatedByValue] = useState([])
  const layoutModeType = useSelector((state) => state.Layout.layoutModeType);  

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: layoutModeType === 'dark' ? '#2a3042' : '#fff',
      color: layoutModeType === 'dark' ? '#fff' : '#000',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: layoutModeType === 'dark' ? '#2a3042' : '#fff',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: layoutModeType === 'dark' ? '#fff' : '#000',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: layoutModeType === 'dark' ? '#888' : '#888',
    }),
    input: (provided) => ({
      ...provided,
      color: layoutModeType === 'dark' ? '#fff' : '#000', // Ensure input text is visible
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150, // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }

  const handleClearFilters = () => {
    formik.resetForm()
    dispatch(clearFilters())
  }

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const responses = await Promise.all([
          dispatch(
            fetchAllBuildsList({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          dispatch(
            fetchAllModulesList({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          dispatch(
            fetchAllRequirementsList({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          dispatch(
            fetchAllTeamsDetailsUserAssigneeList({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          // dispatch(
          //   fetchAllUsersDetailsList({
          //     project_id: globalProject?.value || selectedProject?.value,
          //   })
          // ),
        ])

        const [buildResponse, moduleResponse, reqResponse, userListResponse] =
          responses

        const buildOptions = buildResponse?.map(entry => ({
          value: Math.round(entry.id),
          label: entry.build_id,
        }))

        const moduleOptions = moduleResponse?.map(entry => ({
          value: entry.id,
          label: entry.module_name,
        }))

        const reqOptions = reqResponse?.map(entry => ({
          value: entry.id,
          label: entry.requirement_title,
        }))
        const createdByOptions = userListResponse?.results?.map(entry => ({
          value: entry.id,
          label: entry.user_name,
        }))
        // userListResponse?.forEach(entry => {
        //   createdByOptions.push({
        //     value: entry.id,
        //     label: entry.name,
        //   })
        // })
        setBuildOptions(buildOptions)
        setModuleOptions(moduleOptions)
        setRequirementOptions(reqOptions)
        setCreatedByValue(createdByOptions)
      } catch (error) {
        console.error(error)
      }
    }

    fetchOptions()
  }, [isFilterFormOpen, globalProject, dispatch])

  return (
    <Offcanvas isOpen={isFilterFormOpen} direction="end" toggle={toggle}>
      <OffcanvasHeader toggle={toggle}>Filter Test Scenario</OffcanvasHeader>
      <OffcanvasBody>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-3">
            <Label>Build</Label>
            <Select
              value={
                buildOptions.find(
                  option => option.value === formik.values.build_id
                ) || null
              }
              onChange={option =>
                formik.setFieldValue("build_id", option ? option.value : null)
              }
              options={buildOptions}
              styles={customStyles}
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="mb-3">
            <Label>Module</Label>
            <Select
              value={
                moduleOptions.find(
                  option => option.value === formik.values.module_id
                ) || null
              }
              onChange={option =>
                formik.setFieldValue("module_id", option ? option.value : null)
              }
              options={moduleOptions}
              styles={customStyles}
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="mb-3">
            <Label>Requirement</Label>
            <Select
              value={
                requirementOptions.find(
                  option => option.value === formik.values.requirement_id
                ) || null
              }
              onChange={option =>
                formik.setFieldValue(
                  "requirement_id",
                  option ? option.value : null
                )
              }
              options={requirementOptions}
              styles={customStyles}
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="mb-3">
            <Label>Created By</Label>
            <Select
              value={
                createdByValue.find(
                  option => option.value === formik.values.created_by
                ) || null
              }
              onChange={option =>
                formik.setFieldValue("created_by", option ? option.value : null)
              }
              options={createdByValue}
              styles={customStyles}
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="d-flex justify-content-between mt-5">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleClearFilters}
            >
              Clear All
            </button>
            <button type="submit" className="btn btn-primary">
              Apply
            </button>
          </div>
        </form>
      </OffcanvasBody>
    </Offcanvas>
  )
}

export default FilterTestScenario
