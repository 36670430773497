import Breadcrumb from "components/Common/Breadcrumb"
import { getStatusBackGroundColor } from "helpers/helper"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { Badge, Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import { GetPreConditionByIdAPI } from "store/actions/TestCaseAction"
import ViewPreCondition from "../../Test Management/Test Case/View Pre Condition/ViewPreCondition"

const ViewTestCaseRequest = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const breadcrumbItems = [
    { label: "Test Case", path: "/RequestPage" },
    {
      label: "Test Case Details",
      path: "/",
    },
  ]
  const viewTestCaseData = useSelector(
    state => state?.TestCase?.singleTestCaseData
  )

  useEffect(() => {
    if (!viewTestCaseData) {
      navigate("/RequestPage")
    }
  })

  const [preCondition, setPreCondition] = useState(null)

  const fetchPreConditionDetails = async preconditionId => {

    let bodyOptions = { project_id: viewTestCaseData?.project_id }
    try {
      const res = await dispatch(GetPreConditionByIdAPI(bodyOptions))
      const data = res?.filter((id) =>
        id?.id === preconditionId?.id
      )
      setPreCondition(data)
      toggle()
    } catch (error) { }
  }
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <div className="">
      <ViewPreCondition isOpen={isOpen} toggle={toggle} data={preCondition} />
      <Container fluid>
        <Breadcrumb
          title="Test Case Details"
          breadcrumbItems={breadcrumbItems}
        />
        <div className="d-flex justify-content-between mb-2">
          <div className="d-flex ms-2">
            <div className="flex-grow-1 overflow-hidden">
              <h5 className="font-size-15 d-flex">
                <span
                  style={{
                    maxWidth: "500px",
                    // overflow: "hidden",
                    // textOverflow: "ellipsis",
                    // whiteSpace: "nowrap",
                  }}
                >
                  <Link className="text-dark">
                    <h5>
                      {viewTestCaseData?.test_case_name || "-"}
                      {" ("}
                      {viewTestCaseData?.test_case_id}
                      {")"}
                    </h5>
                  </Link>
                </span>
              </h5>
            </div>
          </div>
        </div>

        <div>
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Build</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.build ? viewTestCaseData?.build : "-"}
                    </p>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Module</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.module_name
                        ? viewTestCaseData?.module_name
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Requirement Title</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.requirement_title
                        ? viewTestCaseData?.requirement_title
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Test Scenario</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.test_scenario_id
                        ? `${viewTestCaseData?.test_scenario} -${viewTestCaseData?.test_scenario_name}`
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Execution Type</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.execution_type
                        ? viewTestCaseData?.execution_type
                        : "-"}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm={6}>
              <Card>
                <CardBody>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Testing Type</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.testing_type
                        ? viewTestCaseData?.testing_type
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Priority</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.priority
                        ? viewTestCaseData?.priority
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Created At</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.created_at
                        ? moment(viewTestCaseData?.created_at).format(
                          "DD-MM-YYYY"
                        )
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Estimated Time</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.estimated_time
                        ? viewTestCaseData?.estimated_time
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Assign To</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.assign_to_name
                        ? viewTestCaseData?.assign_to_name
                        : "-"}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={6}></Col>
            <Col sm={6}></Col>
          </Row>
          <Card>
            <CardBody>
              <Row>
                <Col sm={12}>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Status</h6>
                    <p className="text-muted ">
                      <Badge
                        className={
                          "badge-soft-" +
                          `${getStatusBackGroundColor(viewTestCaseData?.test_status)}`
                        }
                        style={{
                          fontSize: "12px", // Increase font size
                          padding: "0.5em", // Increase padding
                          borderRadius: "0.5em", // Adjust border radius
                        }}
                      >
                        {viewTestCaseData?.test_status || "-"}
                      </Badge>
                    </p>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Summary</h6>
                    <p
                      className="text-muted mb-4"
                      dangerouslySetInnerHTML={{
                        __html: viewTestCaseData?.summary
                          ? viewTestCaseData?.summary
                          : "-",
                      }}
                    ></p>
                  </div>
                  <h6 style={{ fontWeight: "550" }}>Pre Condition</h6>
                  <p>
                    {Array.isArray(viewTestCaseData?.pre_conditions_details) ? (
                      viewTestCaseData.pre_conditions_details.length > 0 ? (
                        viewTestCaseData.pre_conditions_details.map(
                          (item, index) => (
                            <Button
                              key={item.id || index} // Use item.id if available for better key management
                              color="secondary"
                              size="sm"
                              className="me-1 mb-1"
                              style={{ fontSize: "12px" }}
                              onClick={() => fetchPreConditionDetails(item)}
                              aria-label={`Pre-condition ${index + 1}`}
                            >
                              {/* Access condition_name instead of name */}
                              {item.condition_name ? item.condition_name : item}
                            </Button>
                          )
                        )
                      ) : (
                        "-"
                      )
                    ) : viewTestCaseData?.pre_conditions_details ? (
                      <Button
                        color="secondary"
                        size="sm"
                        style={{ fontSize: "12px" }}
                      >
                        {viewTestCaseData.pre_conditions_details}
                      </Button>
                    ) : (
                      "-"
                    )}
                  </p>
                </Col>

                <Col sm={12}>
                  <h6 style={{ fontWeight: "550" }}>New Pre Condition</h6>
                  <p
                    className="text-muted mb-4"
                    dangerouslySetInnerHTML={{
                      __html: viewTestCaseData?.new_pre_condition
                        ? viewTestCaseData?.new_pre_condition
                        : "-",
                    }}
                  ></p>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row>
                <Col sm={12}>
                  <h6 style={{ fontWeight: "550" }}>Expected Result</h6>
                  <p
                    className="text-muted mb-4"
                    dangerouslySetInnerHTML={{
                      __html: viewTestCaseData?.expected_result
                        ? viewTestCaseData?.expected_result
                        : "-",
                    }}
                  ></p>
                </Col>

                <Col sm={12}>
                  <h6 style={{ fontWeight: "550" }}>Post Condition</h6>
                  <p
                    className="text-muted mb-4"
                    dangerouslySetInnerHTML={{
                      __html: viewTestCaseData?.post_condition
                        ? viewTestCaseData?.post_condition
                        : "-",
                    }}
                  ></p>
                </Col>

                <Col sm={12}>
                  <h6 style={{ fontWeight: "550" }}>Detail Steps</h6>
                  <p
                    className="text-muted mb-4"
                    dangerouslySetInnerHTML={{
                      __html: viewTestCaseData?.detail_steps
                        ? viewTestCaseData?.detail_steps
                        : "-",
                    }}
                  ></p>
                </Col>

                <Col sm={12}>
                  <h6 style={{ fontWeight: "550" }}>Comments</h6>
                  <p
                    className="text-muted mb-4"
                    dangerouslySetInnerHTML={{
                      __html: viewTestCaseData?.comments
                        ? viewTestCaseData?.comments
                        : "-",
                    }}
                  ></p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Container>
    </div>
  )
}

export default ViewTestCaseRequest
