import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
import ProjectManagement from "./ProjectManagement/reducer"
import LoadingSlice from "./Slices/LoadingSlice"
import ProjectsSlice from "./Slices/ProjectsSlice"
import GlobalSlice from "./Slices/GlobalSlice"
import TestScenarioSlice from "./Slices/TestScenarioSlice"
import ModuleSlice from "./Slices/ModuleSlice"
import BuildSlice from "./Slices/BuildSlice"
import RequirementSlice from "./Slices/RequirementSlice"
import UserSlice from "./Slices/UserSlice"
import TestPlanSlice from "./Slices/TestPlanSlice"
import TagsSlice from "./Slices/TagsSlice"
import TestDataSlice from "./Slices/TestDataSlice"
import DefectsSlice from "./Slices/DefectsSlice"
import RolesSlice from "./Slices/RolesSlice"
import ReportsSlice from "./Slices/ReportsSlice"
import ActionSuite from "./Slices/ActionSuiteSlice"
import AutomationScriptSlice from "./Slices/AutomationScriptSlice"
import TestCaseSlice from "./Slices/TestCaseSlice"
import IntegrationSlice from "./Slices/IntegrationSlice"
import DbIntegrationSlice from "./Slices/DbIntegrationSlice"
import TestCycleSlice from "./Slices/TestCycleSlice"
import DashboardSlice from "./Slices/DashboardSlice"
import RequestPageSlice from "./Slices/RequestPageSlice"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ProjectManagement,
  Loading: LoadingSlice,
  Project: ProjectsSlice,
  globalProjectSelect: GlobalSlice,
  TestScenario: TestScenarioSlice,
  TestPlan: TestPlanSlice,
  Module: ModuleSlice,
  Build: BuildSlice,
  Requirement: RequirementSlice,
  User: UserSlice,
  Integration: IntegrationSlice,
  Tags: TagsSlice,
  TestData: TestDataSlice,
  Defects: DefectsSlice,
  Roles: RolesSlice,
  Reports: ReportsSlice,
  ActionSuite: ActionSuite,
  AutomationScript: AutomationScriptSlice,
  TestCase: TestCaseSlice,
  DbIntegration: DbIntegrationSlice,
  TestCycle:TestCycleSlice,
  Dashboard:DashboardSlice,
  Request:RequestPageSlice
})

export default rootReducer
