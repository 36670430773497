import { toast } from "react-toastify"
import httpInjectorService from "services/http-Injector.Service"
import { setLoading } from "store/Slices/LoadingSlice"

export const FetchAllUploadApp = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.appUploadList(
        queryString
      )
      const data = response
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}


export const deleteApp = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))

    try {
      const response = await httpInjectorService.deleteAppUpload(bodyoption)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "User Deleted successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }else{
        throw new Error(response.message || response.errors || 'Something went wrong');
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const UploadNewAppBs = bodyoption => {
  
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.uploadApp(formData)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "created successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      } else {
        throw new Error(response.message || response.errors || 'Something went wrong');
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}


export const UpdataApp = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.editApp(
        formData,
        bodyoption?.id
      )
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Role updated successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
        return response
      }else {
        const errorData = response;
        throw new Error(errorData.errors.non_field_errors || errorData.errors || 'Something went wrong');
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error)
    }
  }
}