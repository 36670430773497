import { createSlice } from "@reduxjs/toolkit"

const BuildSlice = createSlice({
  name: "Build",
  initialState: {
    allBuilds: "",
    nextBuildId: null,
    buildDetails:[],
    buildEditDetails:[]
  },

  reducers: {
    setAllBuilds: (state, action) => ({
      ...state,
      allBuilds: action.payload,
    }),
    setNextBuildId: (state, action) => ({
      ...state,
      nextBuildId: action.payload,
    }),
    setBuildDetails: (state, action) => ({
      ...state,
      buildDetails: action.payload,
    }),
    setEditBuildDetails: (state, action) => ({
      ...state,
      buildEditDetails: action.payload,
    }),
  },
})

export const { setAllBuilds, setNextBuildId , setBuildDetails,setEditBuildDetails} = BuildSlice.actions

export default BuildSlice.reducer
