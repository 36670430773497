import React, { Fragment, useEffect, useState } from "react"
import {
  Row,
  Table,
  Button,
  Input,
  InputGroupText,
  InputGroup,
  Col,
  Spinner,
} from "reactstrap"
import { Link } from "react-router-dom"

import {
  // Column,
  // Table as ReactTable,
  // ColumnFiltersState,
  // FilterFn,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table"

import { rankItem } from "@tanstack/match-sorter-utils"
import NoData from "components/Common/NoData"

// Column Filter
const Filter = ({ column }) => {
  const columnFilterValue = column.getFilterValue()

  return (
    <>
      <DebouncedInput
        type="text"
        value={columnFilterValue ?? ""}
        onChange={value => column.setFilterValue(value)}
        placeholder="Search..."
        className="w-36 border shadow rounded"
        list={column.id + "list"}
      />
      <div className="h-1" />
    </>
  )
}

// Global Filter
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [debounce, onChange, value])

  return (
    <React.Fragment>
      <Col sm={4} className="d-flex align-items-center mb-2">
        <InputGroup className="w-100" style={{ height: "36px" }}>
          <InputGroupText style={{ height: "100%", padding: "0 12px" }}>
            <i className="bx bx-search-alt search-icon"></i>
          </InputGroupText>
          <Input
            {...props}
            value={value}
            onChange={e => setValue(e.target.value)}
            style={{ height: "100%" }}
          />
        </InputGroup>
      </Col>
    </React.Fragment>
  )
}

const TableContainer = ({
  columns,
  data,
  pageIndex,
  setPageIndex,
  pageSize,
  setPageSize,
  totalCount,
  globalFilter,
  setGlobalFilter,
  isGlobalFilter,
  isPagination,
  isCustomPageSize,
  isAddButton,
  isAddDelete,
  isAddEdit,
  isAddFilter,
  SearchPlaceholder,
  pagination,
  handleAddClick,
  handleDeleteClick,
  handleEditClick,
  handleFilterClick,
  tableClass,
  theadClass,
  paginationWrapper,
  divClassName,
  isUploadButton,
}) => {
  const [columnFilters, setColumnFilters] = useState([])
  const [loading, setLoading] = useState(true)
  const currentUrl = window.location.href
  const parts = currentUrl.split("/")
  const fuzzyFilter = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value)
    addMeta({ itemRank })
    return itemRank.passed
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
    const fetchDatas = async () => {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    }
    if (parts.includes("DefectManagement")) {
      fetchDatas()
    } else {
      fetchData()
    }
  }, [])

  const table = useReactTable({
    columns,
    data,
    filterFns: { fuzzy: fuzzyFilter },
    state: { columnFilters, globalFilter, pagination: { pageIndex, pageSize } },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(totalCount / pageSize),
  })

  const {
    getHeaderGroups,
    getCanPreviousPage,
    getCanNextPage,
    getPageOptions,
    getState,
  } = table
  const handlePageSizeChange = e => {
    // Reset to page 1 when page size changes
    setPageIndex(0)
    // Ensure setPageIndex is applied before updating page size
    setPageSize(Number(e.target.value))
  }
  // Helper function to get the visible page numbers
  const getVisiblePageNumbers = (currentPage, totalPageCount) => {
    const maxVisiblePages = 4 // Set the maximum number of pages to display
    let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 0)
    let endPage = startPage + maxVisiblePages - 1

    if (endPage >= totalPageCount) {
      endPage = totalPageCount - 1
      startPage = Math.max(endPage - maxVisiblePages + 1, 0)
    }

    return { startPage, endPage }
  }

  const { startPage, endPage } = getVisiblePageNumbers(
    getState().pagination.pageIndex,
    getPageOptions().length
  )

  // Separate useEffect to reset pageIndex only when search term changes
  useEffect(() => {
    if (globalFilter) {
      setPageIndex(0) // Reset to the first page only if globalFilter changes
    }
  }, [globalFilter]) // Depend only on globalFilter

  // Function to handle global filter change
  const handleGlobalFilterChange = value => {
    setGlobalFilter(String(value)) // Set the global filter
    // No need to reset pageIndex here; it's handled by useEffect
  }

  const handlePageChange = newPageIndex => {
    setPageIndex(newPageIndex) // Update the page index state
    // Add any additional logic if needed
  }

  return (
    <Fragment>
      {loading ? (
        <div className="text-center mt-5">
          <Spinner color="primary" />
          <p className="mt-2">Loading data...</p>
        </div>
      ) : (
        <>
          <Row className="mb-2 mt-3 mt-sm-0">
            {" "}
            {/* Top margin for mobile */}
            <div className="d-flex flex-column flex-sm-row justify-content-between">
              {isGlobalFilter && (
                <DebouncedInput
                  value={globalFilter ?? ""}
                  onChange={handleGlobalFilterChange}
                  className="form-control search-box me-2 mb-2 mb-sm-0"
                  placeholder={SearchPlaceholder}
                />
              )}
              <div className="d-flex justify-content-between align-items-baseline flex-wrap">
                {isAddButton && (
                  <div className="text-sm-end me-1 mb-2 mb-sm-0 mt-sm-0">
                    {" "}
                    {/* Margin top for mobile */}
                    <Button
                      type="button"
                      color="success"
                      className="btn-label"
                      onClick={handleAddClick}
                    >
                      <i className="mdi mdi-plus label-icon"></i> Create
                    </Button>
                  </div>
                )}
                {isUploadButton && (
                  <div className="text-sm-end me-1 mb-2 mb-sm-0">
                    <Button
                      type="button"
                      color="success"
                      className="btn-label"
                      onClick={handleAddClick}
                    >
                      <i className="mdi mdi-upload label-icon"></i> Upload App
                    </Button>
                  </div>
                )}
                {isAddEdit && (
                  <div className="text-sm-end me-1 mb-2 mb-sm-0 mt-2 mt-sm-0">
                    {" "}
                    {/* Margin top for mobile */}
                    <Button
                      type="button"
                      color="primary"
                      className="btn-label"
                      onClick={handleEditClick}
                    >
                      <i className="mdi mdi-pencil label-icon"></i> Edit
                    </Button>
                  </div>
                )}
                {isAddDelete && (
                  <div className="text-sm-end me-1 mb-2 mb-sm-0">
                    <Button
                      type="button"
                      color="danger"
                      className="btn-label"
                      onClick={handleDeleteClick}
                    >
                      <i className="mdi mdi-delete label-icon"></i> Delete
                    </Button>
                  </div>
                )}
                {isAddFilter && (
                  <div className="text-sm-end me-1 mb-2">
                    <Button
                      type="button"
                      color="light"
                      className="btn-label"
                      onClick={handleFilterClick}
                    >
                      <i className="mdi mdi-filter label-icon"></i> Filter
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Row>

          {data?.length > 0 ? (
            <>
              <div
                className={divClassName ? divClassName : "table-responsive"}
                style={{ minHeight: "60vh", borderRadius: "6px" }}
              >
                <Table hover className={tableClass}>
                  <thead className={theadClass}>
                    {getHeaderGroups().map(headerGroup => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => {
                          return (
                            <th
                              key={header.id}
                              colSpan={header.colSpan}
                              className={`${
                                header.column.columnDef.enableSorting
                                  ? "sorting sorting_desc"
                                  : ""
                              }`}
                            >
                              {header.isPlaceholder ? null : (
                                <React.Fragment>
                                  <div
                                    {...{
                                      className: header.column.getCanSort()
                                        ? "cursor-pointer select-none"
                                        : "",
                                      onClick:
                                        header.column.getToggleSortingHandler(),
                                    }}
                                  >
                                    {flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                                    {{
                                      asc: "",
                                      desc: "",
                                    }[header.column.getIsSorted()] ?? null}
                                  </div>
                                  {header.column.getCanFilter() ? (
                                    <div>
                                      <Filter
                                        column={header.column}
                                        table={table}
                                      />
                                    </div>
                                  ) : null}
                                </React.Fragment>
                              )}
                            </th>
                          )
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getCoreRowModel().rows.map(row => (
                      <tr key={row.id}>
                        {row.getVisibleCells().map(cell => (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              {isPagination && (
                <Row className="mt-2" style={{ marginBottom: "-12px" }}>
                  <Col sm={12} md={5} className="d-flex">
                    {isCustomPageSize && (
                      <div style={{ width: "125px" }}>
                        <select
                          className="form-select pageSize mb-2"
                          value={table.getState().pagination.pageSize}
                          onChange={handlePageSizeChange}
                        >
                          {[10, 20, 50, 100].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                              Show {pageSize}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    <div className="dataTables_info mx-2 mt-2">
                      Showing data of {totalCount} entries
                    </div>
                  </Col>
                  <Col sm={12} md={7}>
                    <div className={paginationWrapper}>
                      <ul className={pagination}>
                        <li
                          className={`paginate_button page-item previous ${
                            !getCanPreviousPage() ? "disabled" : ""
                          }`}
                        >
                          <Link
                            to="#"
                            className="page-link"
                            onClick={() => {
                              if (getCanPreviousPage()) {
                                handlePageChange(
                                  getState().pagination.pageIndex - 1
                                ) // Use handlePageChange here
                              }
                            }}
                          >
                            <i className="mdi mdi-chevron-left"></i>
                          </Link>
                        </li>

                        {startPage > 0 && (
                          <>
                            <li className="paginate_button page-item">
                              <Link
                                to="#"
                                className="page-link"
                                onClick={() => handlePageChange(0)} // Use handlePageChange here
                              >
                                1
                              </Link>
                            </li>
                            {startPage > 1 && (
                              <li className="paginate_button page-item disabled">
                                <span className="page-link">...</span>
                              </li>
                            )}
                          </>
                        )}

                        {getPageOptions()
                          .slice(startPage, endPage + 1)
                          .map((item, key) => (
                            <li
                              key={key}
                              className={`paginate_button page-item ${
                                getState().pagination.pageIndex === item
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <Link
                                to="#"
                                className="page-link"
                                onClick={() => handlePageChange(item)} // Use handlePageChange here
                              >
                                {item + 1}
                              </Link>
                            </li>
                          ))}

                        {endPage < getPageOptions().length - 1 && (
                          <>
                            {endPage < getPageOptions().length - 2 && (
                              <li className="paginate_button page-item disabled">
                                <span className="page-link">...</span>
                              </li>
                            )}
                            <li className="paginate_button page-item">
                              <Link
                                to="#"
                                className="page-link"
                                onClick={
                                  () =>
                                    handlePageChange(
                                      getPageOptions().length - 1
                                    ) // Use handlePageChange here
                                }
                              >
                                {getPageOptions().length}
                              </Link>
                            </li>
                          </>
                        )}

                        <li
                          className={`paginate_button page-item next ${
                            !getCanNextPage() ? "disabled" : ""
                          }`}
                        >
                          <Link
                            to="#"
                            className="page-link"
                            onClick={() => {
                              if (getCanNextPage()) {
                                handlePageChange(
                                  getState().pagination.pageIndex + 1
                                ) // Use handlePageChange here
                              }
                            }}
                          >
                            <i className="mdi mdi-chevron-right"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              )}
            </>
          ) : (
            <NoData />
          )}
        </>
      )}
    </Fragment>
  )
}

export default TableContainer
