import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Highcharts3D from "highcharts/highcharts-3d";
import { useSelector } from "react-redux";

// Initialize the 3D module
Highcharts3D(Highcharts);

const DefectsCountDonutChart = ({ defectCounts }) => {
  const layoutModeType = useSelector((state) => state.Layout.layoutModeType);

  const colors = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#9966FF",
    "#F67019",
    "#B37CFF",
    "#2E93fA",
    "#66DA26",
    "#546E7A"
  ];

  // Dark mode settings
  const isDarkMode = layoutModeType === "dark";

  const options = {
    chart: {
      type: 'pie',
      options3d: {
        enabled: true,
        alpha: 45,
        beta: 0,
        depth: 250,
      },
      backgroundColor: isDarkMode ? "#2a3042" : "white",
    },
    title: {
      text: '',
    },
    plotOptions: {
      pie: {
        innerSize: '60%',
        depth: 45,
        dataLabels: {
          enabled: true,
          formatter: function () {
            return `<span style="color: ${this.point.color};"> ${this.y}</span>`;
          },
          style: {
            textOutline: 'none',
            color: isDarkMode ? "#ffffff" : "black",
          },
        },
        showInLegend: true, // Show legend
      },
    },
    legend: {
      itemStyle: {
        color: isDarkMode ? "#ffffff" : "#000000",
      },
    },
    series: [{
      name: 'Defect Count',
      data: defectCounts?.map((item, index) => ({
        name: item?.defect_type,
        y: item?.defect_count,
        color: colors[index % colors.length],
      })),
    }],
    credits: {
      enabled: false, // Disable the highcharts.com credit
    },
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
};

export default DefectsCountDonutChart;
