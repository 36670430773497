import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const Breadcrumb = ({ title, breadcrumbItems }) => {
  return (
    <Row style={{marginTop:"-10px",marginLeft:"-5px",marginRight:"-5px"}}>
      <Col xs={12}>
        <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-2">
          {/* <h4 className="mb-sm-0 font-size-18">{title}</h4> */}
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {breadcrumbItems?.map((item, index) => (
                <BreadcrumbItem
                  key={index}
                  active={index === breadcrumbItems.length - 1}
                >
                  {index === breadcrumbItems.length - 1 ? (
                    item.label
                  ) : (
                    <Link to={item.path}>{item.label}</Link>
                  )}
                </BreadcrumbItem>
              ))}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
    })
  ).isRequired,
}

export default Breadcrumb

// import React from "react"
// import PropTypes from "prop-types"
// import { Link } from "react-router-dom"
// import "./Breadcrum.scss"
// import { Row, Col, BreadcrumbItem, Button } from "reactstrap"
// import PageNavigation from "containers/Test Management/PageNavigation/PageNavigation"

// const Breadcrumb = props => {
//   const {
//     title,
//     breadcrumbItems,
//     isImport,
//     isDownload,
//     isExport,
//     isInfo,
//     // isProject,
//     onBreadcrumbClick,
//     handleTestCaseClick,
//     handleTestScenarioClick,
//     activeButton,
//   } = props
//   return (
//     <Row>
//       <Col
//         xs={12}
//         md={3}
//         className="d-flex flex-column justify-content-between"
//       >
//         <h4 className="mb-sm-0 font-size-18 ms-2">{title}</h4>
//         <PageNavigation
//           handleTestCaseClick={handleTestCaseClick}
//           handleTestScenarioClick={handleTestScenarioClick}
//           activeButton={activeButton}
//         />
//       </Col>
//       <Col xs={12} md={9} className="d-flex flex-column align-items-end">
//         <div className="page-title-right mb-2">
//           <ol className="breadcrumb m-0">
//             {breadcrumbItems?.map((item, index) => (
//               <BreadcrumbItem
//                 key={index}
//                 active={index === breadcrumbItems.length - 1}
//               >
//                 {index === breadcrumbItems.length - 1 ? (
//                   item.label
//                 ) : (
//                   <Link to={item.path} onClick={() => onBreadcrumbClick()}>
//                     {item.label}
//                   </Link>
//                 )}
//               </BreadcrumbItem>
//             ))}
//           </ol>
//         </div>
//         <div className="d-flex flex-wrap align-items-center mt-auto">
//           {isDownload && (
//             <Button
//               color="secondary"
//               outline
//               className="d-flex align-items-center mx-2 mb-2"
//             >
//               <i className="bx bx-download font-size-16"></i>
//               <span className="mx-2">Download template</span>
//             </Button>
//           )}
//           {isImport && (
//             <Button
//               color="secondary"
//               outline
//               className="d-flex align-items-center mx-2 mb-2"
//             >
//               <i className="bx bx-download font-size-16"></i>
//               <span className="mx-2">Import</span>
//             </Button>
//           )}
//           {isExport && (
//             <Button
//               color="secondary"
//               outline
//               className="d-flex align-items-center mx-2 mb-2"
//             >
//               <i className="bx bx-export font-size-16"></i>
//               <span className="mx-2">Export</span>
//             </Button>
//           )}
//           {isInfo && (
//             <Button
//               color="secondary"
//               outline
//               className="d-flex align-items-center mx-2 mb-2"
//             >
//               <i className="bx bx-info-circle font-size-16"></i>
//               {/* <span className="mx-2">info</span> */}
//             </Button>
//           )}
//           {/* {isProject && <GlobalSelectProject projectList={ProjectDetails} />} */}
//           {/* <QuickAction /> */}
//         </div>
//       </Col>
//     </Row>
//   );
// };

// Breadcrumb.propTypes = {
//   title: PropTypes.string.isRequired,
//   breadcrumbItems: PropTypes.arrayOf(
//     PropTypes.shape({
//       label: PropTypes.string.isRequired,
//       path: PropTypes.string
//     })
//   ).isRequired
// };

// export default Breadcrumb;
