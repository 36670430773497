import {
  FETCH_PROJECT_DETAILS,
  FETCH_PROJECT_DETAILS_FAILURE,
  FETCH_PROJECT_DETAILS_SUCCESS,
  TAG_STATUS,
  TAG_STATUS_DETAILS_FAILURE,
  TAG_STATUS_DETAILS_SUCCESS,
  CREATE_PROJECT,
  CREATE_PROJECT_FAILURE,
  CREATE_PROJECT_SUCCESS,
} from "./actionTypes"
import httpInjectorService from "services/http-Injector.Service"
import axios from "axios"
import { setLoading } from "store/Slices/LoadingSlice"
import {
  setAllProjectList,
  setBuildsDetails,
  setGeneratedReqId,
  setModuleDetails,
  setProjectDetailsView,
  setRequirementDetails,
  setTagStatus,
  setTeamsDetails,
  setSelectedRequirements,
  setTeamsUserDetails,
} from "store/Slices/ProjectsSlice"
import { toast } from "react-toastify"
import { setProjectListForSelect } from "store/Slices/GlobalSlice"

export const fetchProjectDetails = () => {
  return dispatch => {
    dispatch({ type: FETCH_PROJECT_DETAILS })
    httpInjectorService
      .projectDetails()
      .then(response => {
        const data = response
        dispatch({ type: FETCH_PROJECT_DETAILS_SUCCESS, payload: data })
      })
      .catch(error => {
        dispatch({ type: FETCH_PROJECT_DETAILS_FAILURE, payload: error })
      })
  }
}

export const fetchProjectDetails1 = () => {
  return async dispatch => {
    dispatch(setLoading(true)) // Set loading to true before the API call
    try {
      const response = await httpInjectorService.projectDetails()
      const data = response
      dispatch(setAllProjectList(data))
      dispatch(setLoading(false)) // Set loading to false after successful data fetch
    } catch (error) {
      dispatch(setLoading(false)) // Set loading to false on error
      throw new Error(error.message)
    }
  }
}
export const fetchProjectForSelectProject = () => {
  return async dispatch => {
    dispatch(setLoading(true)) // Set loading to true before the API call
    try {
      const response = await httpInjectorService.projectDetails()
      const data = response
      dispatch(setProjectListForSelect(data))
      dispatch(setAllProjectList(data))
      dispatch(setLoading(false)) // Set loading to false after successful data fetch
    } catch (error) {
      dispatch(setLoading(false)) // Set loading to false on error
      throw new Error(error.message)
    }
  }
}

export const tagStatus = data => {
  return dispatch => {
    dispatch({ type: TAG_STATUS })
    httpInjectorService
      .tagStatus(data)
      .then(response => {
        const data = response
        dispatch({ type: TAG_STATUS_DETAILS_SUCCESS, payload: data })
      })
      .catch(error => {
        dispatch({ type: TAG_STATUS_DETAILS_FAILURE, payload: error })
      })
  }
}

export const createProject = data => {
  return dispatch => {
    dispatch({ type: CREATE_PROJECT })
    httpInjectorService
      .createProject(data)
      .then(response => {
        const data = response
        dispatch({ type: CREATE_PROJECT_SUCCESS, payload: data })
      })
      .catch(error => {
        dispatch({ type: CREATE_PROJECT_FAILURE, payload: error })
      })
  }
}

export const fetchCountries = () => async dispatch => {
  try {
    const response = await axios.get("https://restcountries.com/v3.1/all")
    const countryOptions = response.data.map(country => {
      const currency = country.currencies
        ? Object.keys(country.currencies)[0]
        : "N/A"
      const currencyDetails = country.currencies
        ? country.currencies[currency]
        : {}
      const currencySymbol = currencyDetails.symbol || "N/A"
      return {
        value: currency,
        label: `${currency} ${currencySymbol}`,
        flag: country.flags.png,
        countryName: country.name.common,
        currency: currency,
      }
    })
    dispatch(setCountries(countryOptions))
  } catch (error) {
    console.error("Error fetching countries:", error)
  }
}

const setCountries = countries => ({
  type: "SET_COUNTRIES",
  payload: countries,
})

export const ProjectDetailsView = id => {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.ProjectDetailsView(id)
      const data = response
      dispatch(setProjectDetailsView(data))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const BulkDeleteRequirement = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    for (const key in bodyoption) {
      if (bodyoption[key] !== undefined && bodyoption[key] !== null) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.deleteBulkRequirement(formData)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Requirement Deleted successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const BuildsDetails = (id, pId) => {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.BuildsDetails(id, pId)
      const data = response
      dispatch(setBuildsDetails(data))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const TeamsDetails = id => {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.TeamsDetails(id)
      const data = response
      dispatch(setTeamsDetails(data))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const ModuleDetails = id => {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.ModuleDetails(id)
      const data = response
      dispatch(setModuleDetails(data))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const RequirementDetails = id => {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.RequirementDetails(id)
      const data = response
      dispatch(setRequirementDetails(data))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const TagStatus = id => {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.TagStatus(id)
      const data = response
      dispatch(setTagStatus(data))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const GenerateRequirementId = id => {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.generateRequirement(id)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Test Scenario created successfully",
          autoClose: 3000,
        })
        dispatch(setGeneratedReqId(response))
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const setSelectedRequirement = data => {
  return async dispatch => {
    dispatch(setSelectedRequirements(data))
  }
}
